import React from "react"
import styles from "./index.module.scss"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import AccountSidebarNav from "../../components/AccountSidebarNav"
import AccountSettingsContainer from "../../containers/AccountSettingsContainer"
import services from "../../services"
import Loading from "../../components/Loading/Loading"

const AccountSettingsPage = () => {
  const [state, setState] = React.useState({ isLoading: false })

  React.useEffect(() => {
    setState({ isLoading: true })
    services.accountServices.getAccountSettings().then(({ data }) => {
      setState({
        data,
        isLoading: false,
      })
    })
  }, [])

  const setSettings = (data) =>
    new Promise((res, rej) =>
      services.accountServices
        .setAccountSettings(data)
        .then(({ data }) => {
          setState({
            data,
            isLoading: false,
          })
          res(data)
        })
        .catch(({ response }) => rej(response.data)),
    )

  return (
    <div className={styles.pageWrap}>
      <ContentWithSidebars topSidebar={() => <AccountSidebarNav />}>
        {state.isLoading ? (
          <Loading />
        ) : (
          <>
            {state.data && (
              <AccountSettingsContainer
                data={state.data}
                setSettings={setSettings}
              />
            )}
          </>
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default AccountSettingsPage
