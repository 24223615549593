import React, { useEffect } from "react"
import styles from "./OpportunityArticle.module.scss"
import P from "prop-types"
import { Grid } from "@material-ui/core"
import draftToHtml from "draftjs-to-html"
import copy from "copy-to-clipboard"

const OpportunityArticle = ({ data }) => {
  let div = document.createElement("div")
  div.innerHTML = data ?  draftToHtml(JSON.parse(data)) : ""

  if(div.getElementsByTagName("a").length){
    div.getElementsByTagName("a").forEach((link) => {
      link.classList.add("goldLink")
      link.classList.add("contactLink")
    })
  }

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("contactLink")) {
        e.preventDefault()
        copy(e.target.innerHTML)
        e.target.classList.add(styles.copied)
        const timeout = setTimeout(() => {
          e.target.classList.remove(styles.copied)
        }, 1500)
      }
    })
  }, [])

  return (
    <Grid
      component="div"
      container
      direction="column"
      className={styles.article}
    >
      <h5 className={styles.articleTitle}>Description</h5>

      <div
        className={styles.articleText}
        dangerouslySetInnerHTML={{
          __html: div.outerHTML,
        }}
      />
    </Grid>
  )
}

OpportunityArticle.propTypes = {
  title: P.string,
  text: P.string,
}

OpportunityArticle.defaultProps = {
  title: "",
  text: "",
}

export default OpportunityArticle
