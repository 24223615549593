import React from "react"
import P from "prop-types"
import classnames from "classnames"

import styles from "./SimilarOpportunity.module.scss"
import {
  makeCutDottedString,
  opportunutiOptimizeText,
} from "../../../../helpers/textHelpers"

import { useHistory } from "react-router-dom"

const maxSimbolsToShowText = 89
const maxSimbolsToShowTitle = 24

const SimilarOpportunity = ({ title, text, id, className }) => {
  const history = useHistory()
  return (
    <div
      className={classnames(styles.wrapper, className)}
      onClick={() => {
        history.push(`/opportunity?id=${id}`)
      }}
    >
      <h6 className={styles.title}>
        {makeCutDottedString(title, maxSimbolsToShowTitle)}
      </h6>
      <div className={classnames(styles.text, "note16r")}>
        {opportunutiOptimizeText(text, maxSimbolsToShowText)}
      </div>
    </div>
  )
}

SimilarOpportunity.propTypes = {
  id: P.string.isRequired,
  title: P.string.isRequired,
  text: P.string.isRequired,
}

SimilarOpportunity.defaultProps = {
  title: "",
  text: "",
  url: "",
  className: "",
}

export default SimilarOpportunity
