import React from "react"
import styles from "./Tag.module.scss"
import P from "prop-types"
import classNames from "classnames"
import { ReactComponent as DeleteIcon } from "../../../images/icons/delete-filled.svg"

const Tag = ({ name, title, onClick, onDelete }) => (
  <div className={styles.wrapper}>
    <span className={classNames("text16r", styles.title)} onClick={onClick}>
      {title}
    </span>
    <DeleteIcon className={styles.deleteIcon} onClick={() => onDelete(name)} />
  </div>
)

Tag.propTypes = {
  name: P.string.isRequired,
  title: P.string,
  onClick: P.func,
  onDelete: P.func,
}

export default Tag
