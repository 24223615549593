import React, { createContext, useState } from "react";
import { createPortal } from "react-dom";

import Popup from "../components/Popup/Popup";

const ModalContext = createContext({
  openModalId: "",
  toggleModal: () => {},
  closeModal: () => {},
});

export const ModalProvider = ({ children }) => {
  const [openModalId, setOpenModalId] = useState("");
  const toggleModal = (id) => setOpenModalId(id);
  const closeModal = () => setOpenModalId("");

  return (
    <ModalContext.Provider
      value={{
        openModalId,
        toggleModal,
        closeModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const Modal = (props) => {
  return (
    <ModalContext.Consumer>
      {({ closeModal, openModalId }) => {
        if (openModalId === props.id) {
          return createPortal(
            <Popup {...props} closeAction={closeModal} />,
            document.getElementById("modal-root")
          );
        }

        return null;
      }}
    </ModalContext.Consumer>
  );
};

export const withModal = (WrappedComponent) => (props) => (
  <ModalContext.Consumer>
    {(state) => <WrappedComponent {...props} context={state} />}
  </ModalContext.Consumer>
);
