import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { ReactComponent as Union } from "../../images/icons/Union.svg"
import { ReactComponent as Positive } from "../../images/icons/positive.svg"
import styles from "./LikeDislike.module.scss"
import { thousandToK } from "../../helpers/textHelpers"

const LikeDislike = ({
  classes,
  likeCount,
  dislikeCount,
  likeClick,
  dislikeClick,
  withoutPercent,
  clickedAction,
}) => {
  let percentLikeCount = 0
  if (likeCount + dislikeCount === 0) {
    percentLikeCount = 50
  } else {
    percentLikeCount = (likeCount * 100) / (likeCount + dislikeCount)
  }

  return null

  return (
    <div className={classNames(styles.emojiWrap, classes)}>
      <div className={styles.wrap}>
        <div className={styles.likeCount} onClick={likeClick}>
          <span
            className={classNames(clickedAction === "up" && styles.activeEmoji)}
          >
            <Positive />
          </span>
          <p>{thousandToK(likeCount)}</p>
        </div>
        <div className={styles.likeCount} onClick={dislikeClick}>
          <span
            className={classNames(
              clickedAction === "down" && styles.activeEmoji,
            )}
          >
            <Union />
          </span>
          <p>{thousandToK(dislikeCount)}</p>
        </div>
      </div>
      {!withoutPercent && (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: `${percentLikeCount}%`,
              background: "#FFFFFF",
              height: "2px",
            }}
          />
          <div
            style={{
              width: `${100 - percentLikeCount}%`,
              background: "rgba(255, 255, 255, 0.7)",
              height: "2px",
            }}
          />
        </div>
      )}
    </div>
  )
}
LikeDislike.propTypes = {
  /** number of dislike */
  dislikeCount: PropTypes.number.isRequired,
  /** number of likes */
  likeCount: PropTypes.number.isRequired,
  /** increase the number of likes per unit */
  likeClick: PropTypes.func,
  /** increase the number of dislikes per unit */
  dislikeClick: PropTypes.func,
  /** disable percent line */
  withoutPercent: PropTypes.bool,
  clickedAction: PropTypes.string,
}
LikeDislike.defaultProps = {
  likeCount: 0,
  dislikeCount: 0,
  likeClick: () => {},
  dislikeClick: () => {},
  withoutPercent: false,
  clickedAction: null,
}
export default LikeDislike

const optimizeCount = (number) => {
  if (number > 999) {
    return number.toString().slice(0, -3) + " k"
  }

  return number
}
