import React from "react"
import { Grid } from "@material-ui/core"
import styles from "./OpportunityShowItem.module.scss"
import OpportunityHeader from "./components/OpportunityHeader/OpportunityHeader"
import OpportunityParams from "./components/OpportunityParams/OpportunityParams"
import OpportunityArticle from "./components/OpportunityArticle/OpportunityArticle"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import services from "../../services"
import ConfirmCancelPopup from "../Popup/ConfirmCancelPopup"
import { toast } from "react-toastify"
import ToastInfo from "../ToastContents/ToastInfo"

const OpportunityShowItem = ({
  data,
  onEdit,
  isEditable,
  setDeletePopupOpened,
  hasApplyButton,
  handleApplyOpportunity,
  handleRefuseOpportunity,
}) => {
  const [confirmPopupOpened, setConfirmPopupOpened] = React.useState(false)
  const [favorite, setFavorite] = React.useState(false)

  React.useEffect(() => {
    setFavorite(data.favorite)
  }, [data])

  const addToFavorites = () => {
    if (data && data.uuid) {
      services.favoriteServices
        .addRemoveToList({
          type: "opportunity",
          uuid: data.uuid,
          favorite: !favorite,
        })
        .then((response) => {
          setFavorite(!favorite)

          if (!favorite) {
            toast.info(
              <ToastInfo>
                The <span className={styles.highlighted}>{data.name}</span>{" "}
                opportunity was successfully added to your Favorites
              </ToastInfo>,
              {
                position: toast.POSITION.TOP_RIGHT,
              },
            )
          }
        })
    }
  }
  return (
    <Grid component="div" container className={styles.Wrapper}>
      {confirmPopupOpened && (
        <ConfirmCancelPopup
          cancelMessage="Are you sure you want to delete opportunity from favorites?"
          setIsPopupOpened={setConfirmPopupOpened}
          handleCancel={() => {
            addToFavorites()
            setConfirmPopupOpened(false)
          }}
        />
      )}

      <OpportunityHeader
        hasApplyButton={hasApplyButton}
        setDeletePopupOpened={setDeletePopupOpened}
        buttonText={"Edit Opportunity"}
        data={data}
        onEdit={onEdit}
        isEditable={isEditable}
        applyStatus={data["prospect_status"]}
        handleApplyOpportunity={handleApplyOpportunity}
        handleRefuseOpportunity={handleRefuseOpportunity}
        isFavorite={favorite}
        onLikeClick={() => {
          if (!favorite) {
            addToFavorites()
          } else {
            setConfirmPopupOpened(true)
          }
        }}
      />
      <OpportunityParams data={data} title="job" />
      <Grid item xs={12} className={styles.videoPlayer}>
        {data?.video?.path && <VideoPlayer source={data.video.path} />}
        {data?.photo?.path && (
          <img src={data?.photo?.path} alt="" className={styles.photo} />
        )}
      </Grid>
      <OpportunityArticle data={data["description"]} />
    </Grid>
  )
}

export default OpportunityShowItem
