import { paths } from "../../../routes/paths"

export const navLinks = [
  {
    path: "/",
    name: "Home",
    exact: true,
  },
  {
    path: paths.opportunitySearch(),
    name: "Opportunities",
    exact: true,
  },
  {
    path: paths.resourceSearch(),
    name: "Resources",
    exact: true,
  },
  {
    name: "Community",
    path: paths.questionSearch(),
    exact: true,
  },
  {
    name: "Institute",
    path: paths.instituteSearch(),
    exact: true,
  },
  {
    name: "Inspiration",
    path: paths.inspirationSearch(),
    exact: true,
  },
]
