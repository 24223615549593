export const options = [
  {
    id: "",
    value: "All Types",
  },
  {
    id: "group_chats",
    value: "Group Chats",
  },
  {
    id: "network",
    value: "Network",
  },
  {
    id: "peer_circles",
    value: "Peer Circles",
  },
  {
    id: "referrals",
    value: "Referrals",
  },
  {
    id: "the_vent",
    value: "Let’s Connect",
  },
]
