import axios from "axios"
import { save, load, remove } from "../helpers/localStorage"

const CancelToken = axios.CancelToken
export let source = CancelToken.source()

let isRefreshing = false
let failedQueue = []

const accessToken = load("accessToken")

// const baseURL =
//   process.env.REACT_APP_MODE === "prod"
//     ? "https://api-b2t-demo.coltranehyde.com"
//     : "https://api-b2t-qa.leansquad.net"
//PROD
const baseURL = "https://private-api.theb2t.com"
//SANDBOX
// const baseURL = "https://private-api.theb2t.com"
//DEV

//const baseURL = "http://localhost:3001"

export const addAccessTokenToRequestsHeader = (agent, accessToken) => {
  if (accessToken) {
    agent.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  }
}

const agent = axios.create({
  baseURL,
})

if (accessToken) {
  addAccessTokenToRequestsHeader(agent, accessToken)
}

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

agent.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const originalRequest = error.config

    source = CancelToken.source()

    if (
      error.response?.status === 401 &&
      originalRequest.url !== "sessions/refresh" &&
      originalRequest.url !== "sessions"
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise((resolve, reject) => {
        agent
          .post("sessions/refresh")
          .then((res) => {
            const accessToken = res.data["access_token"]
            addAccessTokenToRequestsHeader(agent, accessToken)
            save("accessToken", accessToken)
            originalRequest.headers["Authorization"] = "Bearer " + accessToken
            processQueue(null, accessToken)
            resolve(axios(originalRequest))
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
          })
          .then(() => {
            isRefreshing = false
          })
      })
    } else if (
      error.response?.status === 401 &&
      originalRequest.url === "sessions/refresh" &&
      !(
        window.location.pathname === "/login" ||
        window.location.pathname === "/password-recovery"
      )
    ) {
      remove("currentUser")
      remove("accessToken")
      window.location.reload()
      throw error
    } else if (
      error.response?.status === 404 &&
      !(
        window.location.pathname === "/login" ||
        window.location.pathname === "/password-recovery"
      )
    ) {
      window.location.replace("/not-found")
      return
    }
    return Promise.reject(error)
  },
)

export default agent
