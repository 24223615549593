import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { Link, Redirect, useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { useSelector } from "react-redux"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import queryString from "query-string"
import Typography from "@material-ui/core/Typography"

import services from "../../services/"
import SimilarOpportunity from "../../components/Opportunity/components/SimilarOpportunity/SimilarOpportunity"
import OpportunityShowItem from "../../components/Opportunity/OpportunityShowItem"

import styles from "./OpportunityContainer.module.scss"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"
import QuoteCard from "../../components/QuoteCard/QuoteCard"
import { COMPANY_SEEKER, INDIVIDUAL } from "../../helpers/userRoles"
import { paths } from "../../routes/paths"

const OpportunityContainer = ({ uuid, from }) => {
  const [loading, setLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [data, setData] = useState({})
  const [isEditable, setIsEditable] = useState(false)
  const [deletePopupOpened, setDeletePopupOpened] = useState(false)
  const lastLocation = useLastLocation()
  const history = useHistory()

  const userCompanyId = useSelector((state) => state.user.company.id)
  const companyUuid = useSelector((state) => state.user.company.uuid)
  const userRole = useSelector((state) => state.user.role)
  const companyType = useSelector((state) => state.user.company.type)

  const onDeleteOpportunity = () => {
    services.opportunityServices
      .deleteOpportunityByUuid(uuid)
      .then(() => {
        history.push(`/opportunities/${companyUuid}`)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const hasApplyButton =
    userRole === INDIVIDUAL ||
    (companyType === COMPANY_SEEKER &&
      data?.company?.id !== userCompanyId &&
      data["opportunity_type"] !== "careers_executive")

  const handleRefuseOpportunity = () => {
    services.prospectsServices
      .refuseOpportunity(uuid)
      .then(() => setData({ ...data, prospect_status: "rejected" }))
      .catch((e) => {
        console.log(e)
      })
  }

  const handleApplyOpportunity = () => {
    services.prospectsServices
      .applyOpportunity(uuid)
      .then(() => setData({ ...data, prospect_status: "applied" }))
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    setLoading(true)
    services.opportunityServices
      .getOpportunity(uuid)
      .then(({ data }) => {
        setLoading(false)
        setData({
          ...data,
          company_name: data.company.name,
        })
        setIsEditable(data.company.id === userCompanyId)
      })
      .catch(() => {
        // history.push("/not-found")
      })
  }, [history, userCompanyId, uuid])

  useEffect(() => {
    setLoading(true)
    services.opportunityServices
      .getOpportunity(uuid)
      .then(({ data }) => {
        setLoading(false)
        setData({
          ...data,
          company_name: data.company.name,
        })
        setIsEditable(data.company.id === userCompanyId)
      })
      .catch(() => {
        history.push("/not-found")
      })
  }, [history, userCompanyId, uuid])

  const getLastPageByHistory = (history) => {
    const location = history?.pathname.split("/")[1]
    const opportunityUuid = data?.company?.uuid

    const params = queryString.parse(history?.search)
    switch (location) {
      case "prospects":
        return (
          <>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link
                to={`/prospects?type=${params.type}`}
                className={styles.link}
              >
                <p>{params.type}</p>
              </Link>
              <Typography
                className={classnames(styles.link, styles.currentLink)}
              >
                <p>{data.name}</p>
              </Typography>
            </Breadcrumbs>
          </>
        )
      case "company":
      case "company-profile":
        return (
          <>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link
                to={`/company/${data?.company?.uuid}`}
                className={styles.link}
              >
                <p>{data.company_name}</p>
              </Link>
              <Typography
                className={classnames(styles.link, styles.currentLink)}
              >
                <p>{data.name}</p>
              </Typography>
            </Breadcrumbs>
          </>
        )
      case "opportunity":
        return (
          <>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link
                to={`/company/${data?.company?.uuid}`}
                className={styles.link}
              >
                <p>{data.company_name}</p>
              </Link>
              <Link
                to={`/opportunities/${data?.company?.uuid}`}
                className={styles.link}
              >
                <p>{data.company_name} opportunities</p>
              </Link>
              <Typography
                className={classnames(styles.link, styles.currentLink)}
              >
                <p>{data.name}</p>
              </Typography>
            </Breadcrumbs>
          </>
        )
      case "opportunities":
        return (
          <>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link
                to={`/company/${data?.company?.uuid}`}
                className={styles.link}
              >
                <p>{data.company_name}</p>
              </Link>
              <Link
                to={`/opportunities/${data?.company?.uuid}${data.is_private ? "?private=true" : ""}`}
                className={styles.link}
              >
                <p>
                  {opportunityUuid === companyUuid
                    ? (data.is_private ? "My Private Opportunities" : "My Opportunities")
                    : `${data.company_name} Opportunities`}
                </p>
              </Link>
              <Typography
                className={classnames(styles.link, styles.currentLink)}
              >
                <p>{data.name}</p>
              </Typography>
            </Breadcrumbs>
          </>
        )
      default:
        return (
          <>
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link
                className={styles.link}
                to={paths.opportunitySearch()}
              >
                <p>Opportunities</p>
              </Link>

              {params && params.query && (
                <Link
                  className={styles.link}
                  to={lastLocation.pathname + lastLocation.search}
                >
                  <p>Search</p>
                </Link>
              )}

              <Typography
                className={classnames(styles.link, styles.currentLink)}
              >
                <p>{data.name}</p>
              </Typography>
            </Breadcrumbs>
          </>
        )
    }
  }

  if (!uuid) {
    return <Redirect to={"/"} />
  }

  if (loading) return null

  if (isEdit) return <Redirect to={`/opportunity-edit?id=${data.uuid}`} />

  return (
    <div
      style={{
        width: "100%",
        background: "linear-gradient(180deg, #494949 0%, #272727 100%)",
      }}
    >
      <div className={styles.opportunityShowWrapper}>
        {deletePopupOpened && (
          <Popup
            dialog
            subtitle="Do you want to delete this opportunity?"
            button={
              <Button buttonStyle="black" onClick={onDeleteOpportunity}>
                Yes
              </Button>
            }
            acceptButton={
              <Button
                buttonStyle="outlinedBlack"
                onClick={() => {
                  setDeletePopupOpened(false)
                }}
              >
                No
              </Button>
            }
            closeAction={() => {
              setDeletePopupOpened(false)
            }}
            isCrossIcon={true}
          />
        )}
        <div className={classnames("container", "fullWidth", styles.Wrapper)}>
          {getLastPageByHistory(lastLocation, data)}

          <div className={styles.contentWrapper}>
            <div className={styles.formWrapper}>
              <OpportunityShowItem
                hasApplyButton={hasApplyButton}
                setDeletePopupOpened={setDeletePopupOpened}
                onEdit={() => {
                  setIsEdit(true)
                }}
                isEditable={isEditable}
                data={data}
                handleApplyOpportunity={handleApplyOpportunity}
                handleRefuseOpportunity={handleRefuseOpportunity}
              />
            </div>
            {data["similar_opportunities"]?.length > 0 && (
              <>
                <div className={styles.similarWrapper}>
                  <p className={styles.title}>Similar Opportunities</p>
                  {data["similar_opportunities"]?.map((opportunity) => (
                    <SimilarOpportunity
                      key={opportunity.uuid}
                      id={opportunity.uuid}
                      text={opportunity.description}
                      title={opportunity.name}
                      className={styles.oneSimilar}
                    />
                  ))}

                  <Link
                    to={paths.opportunitySearch({
                      opportunity_type: data.opportunity_type,
                    })}
                    className={classnames("goldLink", styles.moreButton)}
                  >
                    SHOW MORE
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <QuoteCard />
    </div>
  )
}

export default OpportunityContainer
