import React from "react"
import { MarketSelectorView } from "../../../../MarketSelectorView"
import { setCompanyMarket, setMarket } from "../../useReducer/actionCreators"

const CompanyMarketTypeDropdown = ({ externalDispatch, selectedType, searchType }) => {
  return (
    <MarketSelectorView
      market={selectedType}
      onChange={(market) => externalDispatch(searchType === "company" ? setCompanyMarket(market) : setMarket(market))}
    />
  )
}
export default CompanyMarketTypeDropdown
