import {
  SET_REQUEST_RESULTS,
  SET_ACTIVE_CHAT,
  SET_OPEN_CHAT,
  SET_FIRST_MESSAGE,
  SET_LOADING,
  SET_BLOCKED,
  SET_LOADING_OLD_MESSAGE,
  SET_SEARCH,
} from "./reducer"

export const setRequestResult = (data) => ({
  type: SET_REQUEST_RESULTS,
  payload: { data },
})

export const setActiveChat = (data) => ({
  type: SET_ACTIVE_CHAT,
  payload: { data },
})

export const setSearch = (search) => ({
  type: SET_SEARCH,
  payload: { search },
})

export const setOpenChat = (data) => ({
  type: SET_OPEN_CHAT,
  payload: { data },
})

export const setFirstMessage = (data) => ({
  type: SET_FIRST_MESSAGE,
  payload: { data },
})

export const setLoading = (data) => ({
  type: SET_LOADING,
  payload: { data },
})

export const setBlocked = (data) => ({
  type: SET_BLOCKED,
  payload: { data },
})

export const setLoadingOldMessage = (data) => ({
  type: SET_LOADING_OLD_MESSAGE,
  payload: { data },
})
