import React from "react"
import { Link, useHistory } from "react-router-dom"
import moment from "moment"
import classnames from "classnames"
import LinesEllipsis from "react-lines-ellipsis"

import styles from "./index.module.scss"
import CardWithBadge from "../../elements/CardWithBadge"
import UserAvatar from "../../elements/UserAvatar"
import { userTypes } from "../../../helpers/helpData"
import defaultAvatar from "./../../../images/defaultUserAvatar.png"
import CopyToClipboard from "../../CopyToClipboard"

const ReportCard = ({
  title,
  toUserName,
  toUserPhoto,
  toUserLink,
  onUserName,
  onUserPhoto,
  onUserLink,
  body,
  createdAt,
  inSidebar,
  email,
  onUserType,
  reportLink,
}) => {
  const history = useHistory()

  const redirect = () => {
    if (inSidebar) {
      history.push(reportLink)
    }
  }
  const date = moment(createdAt).format("MM/DD/YY H:mm a")

  return (
    <div
      className={classnames(
        styles.cardWrap,
        inSidebar && styles.cardWrapWithLink,
      )}
      onClick={redirect}
    >
      <CardWithBadge {...getUserType(onUserType)}>
        <div className={styles.cardContent}>
          <div className={styles.cardContentRight}>
            <div
              className={classnames(
                styles.cardContentTo,
                inSidebar && styles.cardContentToSidebar,
              )}
            >
              <div className={styles.cardContentHeader}>
                <div className={styles.toAvatar}>
                  <Link to={toUserLink}>
                    <UserAvatar src={toUserPhoto} size={40} alt={toUserName} />
                  </Link>
                </div>

                <div>
                  <div className={styles.postedBy}>
                    Reported by
                    <Link to={toUserLink}>{toUserName}</Link>
                  </div>

                  <div className={styles.publishedDate}>{date}</div>
                </div>
              </div>

              <div className={styles.reportedBlock}>
                <div className={styles.onInfo}>
                  <div
                    className={classnames(styles.postedBy, styles.postedByOn)}
                  >
                    Reported on
                    <Link to={onUserLink}>{onUserName}</Link>
                    <div className={styles.onEmail}>
                      <CopyToClipboard text={email} />
                    </div>
                  </div>

                  <div className={styles.onAvatar}>
                    <Link to={onUserLink}>
                      <UserAvatar
                        src={onUserPhoto}
                        size={30}
                        alt={onUserName}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <h4
              className={classnames(
                styles.questionTitle,
                inSidebar && styles.inSidebar,
              )}
            >
              {title}
            </h4>

            <div
              className={classnames(
                styles.questionDescription,
                inSidebar && styles.inSidebar,
              )}
            >
              {inSidebar ? (
                <LinesEllipsis text={body} maxLine="2" ellipsis="..." />
              ) : (
                body
              )}
            </div>
          </div>
        </div>
      </CardWithBadge>
    </div>
  )
}

ReportCard.defaultProps = {
  inSidebar: false,
  toUserPhoto: defaultAvatar,
  onUserPhoto: defaultAvatar,
}

ReportCard.propTypes = {}

export default ReportCard

const getUserType = (userType) =>
  userTypes.find((item) => item.userType === userType)
