export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "entry",
    value: "Entry",
  },
  {
    id: "mid",
    value: "Mid",
  },
  {
    id: "senior",
    value: "Senior",
  },
  {
    id: "executive",
    value: "Executive",
  },
  {
    id: "expert_specialist",
    value: "Expert/Specialist",
  },
  {
    id: "board_member",
    value: "Board Member",
  },
]