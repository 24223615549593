export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "academia",
    value: "Academia",
  },
  {
    id: "agriculture_environmental_services",
    value: "Agriculture & Environmental Services",
  },
  {
    id: "business_finance",
    value: "Business & Finance",
  },
  {
    id: "construction",
    value: "Construction",
  },
  {
    id: "creative_arts_design",
    value: "Creative Arts & Design",
  },
  {
    id: "education_training",
    value: "Education & Training",
  },
  {
    id: "engineering_technology",
    value: "Engineering & Technology",
  },
  {
    id: "entertainment_film",
    value: "Entertainment & Film",
  },
  {
    id: "government_public_admin",
    value: "Government & Public Administration",
  },
  {
    id: "health_medicine",
    value: "Health & Medicine",
  },
  {
    id: "healthcare",
    value: "Healthcare",
  },
  {
    id: "hospitality_tourism",
    value: "Hospitality & Tourism",
  },
  {
    id: "law_legal_professions",
    value: "Law & Legal Professions",
  },
  {
    id: "law_enforcement",
    value: "Law Enforcement",
  },
  {
    id: "manufacturing_production",
    value: "Manufacturing & Production",
  },
  {
    id: ":media_communications",
    value: "Media & Communications",
  },
  {
    id: "public_safety_defense",
    value: "Public Safety & Defense",
  },
  {
    id: "sales_customer_service",
    value: "Sales & Customer Service",
  },
  {
    id: "science_research",
    value: "Science & Research",
  },
  {
    id: "sports_recreation",
    value: "Sports & Recreation",
  },
  {
    id: "transportation_logistics",
    value: "Transportation & Logistics",
  }
]