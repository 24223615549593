import React from "react"
import classNames from "classnames"
import Grid from "@material-ui/core/Grid"
import styles from "./PhotoGallery.module.scss"
import PhotoGalleryItem from "./PhotoGalleryItem/PhotoGalleryItem"
import Button from "../Button/Button"

const FullGallery = ({
  setUploadPopup,
  galleryData,
  setError,
  setPhotoId,
  setDeletePopup,
  className,
  deletePopupRef,
  editable,
  onClick,
  onClose,
}) => {
  const emptyGallery = (
    <span className={classNames("text16r", styles.emptyFullGallery)}>
      You have not added any photos yet
    </span>
  )
  const galleryTitle = (
    <div className={styles.titleWrap}>
      <h5>Photos</h5>
      {editable !== false && (
        <Button
          className={styles.addPhotoButton}
          onClick={() => {
            setUploadPopup(true)
            if (galleryData?.length >= 12)
              setError("Sorry, you can`t add more than 12 photos.")
          }}
        >
          Add photo
        </Button>
      )}
    </div>
  )
  const gallery = galleryData?.map(({ id, path, name, uuid }) => (
    <Grid key={id} item sm={3} className={styles.galleryItem}>
      <PhotoGalleryItem
        path={path}
        name={name}
        className={styles.photoGalleryItem}
        onDelete={() => {
          setPhotoId(uuid)
          setDeletePopup(true)
        }}
        deletePopupRef={deletePopupRef}
        editable={editable}
        onClick={() => {
          onClick(uuid)
        }}
        onClose={onClose}
      />
    </Grid>
  ))
  return (
    <div
      className={classNames(
        styles.photoGalleryWrap,
        styles.fullPhotoGallery,
        {
          [styles.emptyFullPhotoGallery]: galleryData?.length === 0,
        },
        className,
      )}
    >
      {galleryTitle}
      <Grid container spacing={3} className={styles.photoGallery}>
        {galleryData?.length ? gallery : emptyGallery}
      </Grid>
    </div>
  )
}

export default FullGallery
