import React from "react"
import AuxPortal from "../../AuxPortal/AuxPortal"
import AutoCompleteField from "../../AutoCompleteField/AutoCompleteField"
import Dropdown from "../../Dropdown/Dropdown"
import { OpportunityOptions, UsersRoleOptions } from "../../Opportunity/heplers"
import classNames from "classnames"
import DatePicker from "../../DatePicker/DatePicker"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import GrayCheckbox from "../../Checkbox/GrayCheckBox"
import Button from "../../Button/Button"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"

import styles from "./ProspectsFilter.module.scss"

const useStyles = makeStyles({
  paper: {
    borderRadius: "2px",
  },
})

const ProspectsFilter = ({
  activeFilters,
  namesList,
  namesListLoading,
  setActiveFilters,
  handleChangeNameFilter,
  handleChangeFilter,
  locationsList,
  handleClearFilter,
  handleApplyFilter,
  handleChangeLocation,
  locationsListLoading,
  handleChangeDateFilter,
}) => {
  const { width } = useWindowHeightWidth()

  const getUserRoleDropDownValue = () => {
    return (
      UsersRoleOptions.find((item) => item.dbName === activeFilters.role) ||
      UsersRoleOptions[0]
    )
  }

  const getOpportunityTypeDropDownValue = () => {
    return (
      OpportunityOptions.find(
        (item) => item.dbName === activeFilters.opportunity_type,
      ) || OpportunityOptions[0]
    )
  }
  const classes = useStyles()
  return (
    <AuxPortal initialOpen={false}>
      <div className={styles.firstSection}>
        <div className={styles.filterItem}>
          <AutoCompleteField
            value={{ name: activeFilters.name }}
            options={namesList}
            classes={classes}
            maxLength={32}
            getOptionLabel={(option) => option.name || ""}
            fieldStyle={"dark"}
            loading={namesListLoading}
            label={"Prospect Name"}
            placeholder={"Enter prospect name"}
            onInputChange={(e, value) => {
              setActiveFilters({
                ...activeFilters,
                name: value ? value : null,
                seeker_type: true,
                seeker_uuid: true,
              })
            }}
            onChange={handleChangeNameFilter}
          />
        </div>
        <div className={styles.filterItem}>
          <Dropdown
            id={"1"}
            withoutError
            fieldStyle="gray"
            label="Applied Opportunity Type"
            options={OpportunityOptions}
            onBlur={() => {}}
            value={getOpportunityTypeDropDownValue()}
            onChange={(value) => {
              handleChangeFilter({
                target: { name: "opportunity_type", value: value.dbName },
              })
            }}
          />
        </div>
      </div>
      <div className={styles.firstSection}>
        <div className={styles.filterItem}>
          <Dropdown
            id={"2"}
            withoutError
            fieldStyle="gray"
            label="Prospects Role"
            options={UsersRoleOptions}
            onBlur={() => {}}
            value={getUserRoleDropDownValue()}
            onChange={(value) => {
              handleChangeFilter({
                target: { name: "role", value: value.dbName },
              })
            }}
          />
        </div>
        <div className={classNames(styles.filterItem)}>
          <DatePicker
            withoutError
            calendarWidth={width > 1440 ? "400px" : "328px"}
            onChange={handleChangeDateFilter}
            initialFrom={activeFilters.from}
            initialTo={activeFilters.to}
          />
        </div>
      </div>

      <div className={styles.filterItem}>
        <AutoCompleteField
          value={activeFilters?.location || null}
          options={locationsList}
          placeholder={"Enter location"}
          maxLength={64}
          getOptionLabel={(option) => option}
          classes={classes}
          fieldStyle={"dark"}
          loading={locationsListLoading}
          label={"Applied Opportunity Location"}
          onInputChange={() => {
            handleChangeLocation(null, null)
          }}
          onChange={handleChangeLocation}
        />
      </div>
      <div className={styles.checkBoxSection}>
        <div className={styles.filterItem}>
          <FormControlLabel
            className={styles.checkBoxWrap}
            control={
              <GrayCheckbox
                color={"default"}
                className={styles.checkBox}
                name="is_full_profile"
                onChange={handleChangeFilter}
                checked={activeFilters["is_full_profile"] || false}
              />
            }
            label="Completed Profile"
          />
        </div>
        <div className={styles.filterItem}>
          <FormControlLabel
            className={styles.checkBoxWrap}
            control={
              <GrayCheckbox
                color={"default"}
                className={styles.checkBox}
                name="is_educated"
                onChange={handleChangeFilter}
                checked={activeFilters["is_educated"] || false}
              />
            }
            label="Prospect`s Higher Education Only"
          />
        </div>
        <div className={styles.filterItem}>
          <FormControlLabel
            className={styles.checkBoxWrap}
            control={
              <GrayCheckbox
                color={"default"}
                className={styles.checkBox}
                name="accepted"
                onChange={handleChangeFilter}
                checked={activeFilters.accepted || false}
              />
            }
            label="Accepted"
          />
        </div>
      </div>
      <div className={styles.filterButtonsWrap}>
        <div className={styles.filterButtonWrap}>
          <Button
            buttonStyle="outlined"
            className={styles.filterButton}
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </div>
        <div className={styles.filterButtonWrap}>
          <Button
            buttonStyle="contained"
            className={styles.filterButton}
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
        </div>
      </div>
    </AuxPortal>
  )
}

export default ProspectsFilter
