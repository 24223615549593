import { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import userServices from "../../../services/userServices"
import { source } from "../../../services/axios"
import { readFile } from "../../../helpers/imageHelpers"

const usePhotoUpload = () => {
  const [uploadedPhotos, setUploadedPhotos] = useState([])
  const [uploadedPhotosFiles, setUploadedPhotosFiles] = useState([])

  const [loader, setLoader] = useState(false)
  const [error, setError] = useState()

  const clearUploadPhotos = () => {
    setError()
    setUploadedPhotos([])
    setUploadedPhotosFiles([])
  }

  const handleUploadPhoto = async (data, { target }) => {
    setError()
    const newPhotos = Array.from(target.files)
    const localPhotos = []
    const localPhotosFiles = []
    const validPhotos = newPhotos.filter((photo) => {
      const photoSize = photo.size / (1024 * 1024).toFixed(2)
      if (photoSize >= 10) {
        setError(
          "Sorry, there was a problem while uploading the photo. Please, try again.",
        )
      } else if (data.length + newPhotos.length + uploadedPhotos.length > 12) {
        setError("Sorry, you can`t add more than 12 photos.")
      }
      return (
        photoSize < 10 &&
        data.length + newPhotos.length + uploadedPhotos.length <= 12
      )
    })

    validPhotos.forEach(async (photo) => {
      const id = uuidv4()
      const newPhoto = { id, name: photo.name, path: await readFile(photo) }
      localPhotos.push(newPhoto)
      localPhotosFiles.push({ photo, id })
      setUploadedPhotosFiles([...uploadedPhotosFiles, ...localPhotosFiles])
      setUploadedPhotos([...uploadedPhotos, ...localPhotos])
    })

    target.value = ""
  }

  const handlePopupDelete = (id) => {
    const newImagesData = uploadedPhotos.filter((image) => image.id !== id)
    const newImageFiles = uploadedPhotosFiles.filter((file) => file.id !== id)
    setUploadedPhotos(newImagesData)
    setUploadedPhotosFiles(newImageFiles)
  }

  const handleUpload = async (onAddPhotos) => {
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percentage = Math.floor((loaded * 100) / total)
        setLoader(percentage)
      },
      cancelToken: source.token,
    }
    const photosFiles = uploadedPhotosFiles.map((file) => file.photo)
    const result = await userServices.addPhotoGallery(photosFiles, options)
    onAddPhotos(result.data)
    clearUploadPhotos()
    setLoader(false)
  }

  return {
    uploadedPhotos,
    uploadedPhotosFiles,
    loader,
    error,
    setError,
    setLoader,
    setUploadedPhotos,
    setUploadedPhotosFiles,
    handleUploadPhoto,
    handlePopupDelete,
    handleUpload,
    clearUploadPhotos,
  }
}

export default usePhotoUpload
