export const resourceTypes = [
  {
    title: "Advice",
    type: "advice",
  },
  {
    title: "Etiquette",
    type: "etiquette",
  },
  {
    title: "Funding",
    type: "funding",
  },
  {
    title: "Meetup",
    type: "meetup",
  },
  {
    title: "Unspoken Rules",
    type: "unspoken_rules",
  },
]
export const eventTypes = [
  {
    title: "Meeting",
    type: "meeting",
  },
  {
    title: "Lunch",
    type: "lunch",
  },
  {
    title: "Breakfast",
    type: "breakfast",
  },
  {
    title: "Webinar",
    type: "webinar",
  },
  {
    title: "Virtual",
    type: "virtual",
  },
]
export const accessTypes = [
  {
    title: "Public",
    type: "public",
  },
  {
    title: "Private",
    type: "private",
  },
]
