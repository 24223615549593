import React from "react"
import pt from "prop-types"
import classNames from "classnames"

import styles from "./InstituteHomePageCard.module.scss"
import defaultImage from "./../../../images/home/cards/cardImage.jpg"
import Label from "../../elements/Label"
import googleImage from "./../../../images/home/cards/googleImage.png"
import LikeDislike from "../../LikeDislike/LikeDislike"
import PublishingTerm from "../../elements/PublishingTerm"
import services from "../../../services"

const InstituteHomePageCard = ({
  uuid,
  classes,
  onClick,
  photo,
  title,
  body,
  type,
  company,
  time,
  companyPhoto,
  reputation,
  articleType,
}) => {
  const [rating, setRating] = React.useState(reputation)

  const voteFn = (vote) => {
    const requestType =
      articleType === "inspiration"
        ? "voteForInspirationArticle"
        : "voteForInstitudeArticle"
    services.articleServices[requestType]({ uuid, vote }).then(({ data }) =>
      setRating({
        ...data,
        type: data.type !== rating.type ? data.type : null,
      }),
    )
  }

  return (
    <div className={classNames(styles.wrapper, classes)}>
      <Label type={type} classes={styles.label} subject={"article"} />
      <div className={styles.content}>
        <div
          style={{ backgroundImage: `url(${photo})`, backgroundSize: "cover" }}
          className={styles.image}
          onClick={onClick}
        />
        <div className={styles.articleContent}>
          <div className={styles.contentText} onClick={onClick}>
            <h1 className={styles.title}>{title}</h1>
            <div className={styles.body}>{body}</div>
          </div>
          <div className={styles.footer}>
            <div className={styles.companyInfo}>
              <img src={companyPhoto} alt={""} className={styles.photo} />
              <div className={styles.infoWrap}>
                <div className={styles.name}>{company}</div>
                <div className={styles.time}>
                  <PublishingTerm publishTimestamp={time} />
                </div>
              </div>
            </div>

            <LikeDislike
              withoutPercent
              classes={styles.likeCounter}
              clickedAction={rating.type}
              likeCount={rating.positive}
              dislikeCount={rating.negative}
              likeClick={(e) => {
                e.preventDefault()
                voteFn("up")
              }}
              dislikeClick={(e) => {
                e.preventDefault()
                voteFn("down")
                if (rating.type === " down") setRating({ ...rating, type: "" })
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

InstituteHomePageCard.propTypes = {
  classes: pt.string,
}

InstituteHomePageCard.defaultProps = {
  photo: defaultImage,
  title: "Banking: The business of exclusion",
  body:
    "Globally impact progressive imperatives for intermandated total linkage." +
    " Efficiently transform highly efficient scenarios vis-a-vis cross" +
    " functional relationships. ",
  type: "bridge_talks",
  company: "Google",
  time: "1h ago",
  companyPhoto: googleImage,
}

export default InstituteHomePageCard
