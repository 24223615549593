import React, { useState } from "react"
import ReportPopup from "../../containers/ReportsContainer/popups/ReportPopup"
import classNames from "classnames"

import FlagIcon from "@material-ui/icons/Flag"
import { FlagOutlined } from "@material-ui/icons"
import BlockIcon from "@material-ui/icons/Block"
import { ADMIN } from "../../helpers/userRoles"
import ConfirmCancelPopup from "../Popup/ConfirmCancelPopup"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const ReportButton = ({
  onReport,
  anotherUser,
  isReported,
  className,
  isBlocked,
  onBlock,
  onUnblock,
  userRole,
}) => {
  const [isReportPopupOpen, setIsReportPopupOpen] = useState(false)
  const [isBlockPopupOpen, setIsBlockPopupOpen] = useState(false)

  const handleIconClick = () => {
    if (userRole === ADMIN) {
      if (isBlocked) {
        onUnblock()
      } else {
        setIsBlockPopupOpen(true)
      }
    } else {
      if (!isReported) {
        setIsReportPopupOpen(true)
      }
    }
  }

  const renderIcon = () => {
    if (userRole === ADMIN) {
      if (isBlocked) {
        return <BlockIcon style={{ color: "#FF0C3E" }} />
      } else {
        return <BlockIcon style={{ color: "#C0C0C1" }} />
      }
    } else {
      if (!isReported) {
        return (
          <FlagOutlined
            style={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
          />
        )
      } else {
        return (
          <CustomTooltip
            text={"You have already flagged this profile as inappropriate"}
            children={<FlagIcon style={{ color: "#F4C00D" }} />}
          />
        )
      }
    }
  }

  return (
    <>
      {isReportPopupOpen && (
        <ReportPopup
          onSubmit={onReport}
          closeAction={() => setIsReportPopupOpen(false)}
        />
      )}
      {isBlockPopupOpen && (
        <ConfirmCancelPopup
          cancelMessage={"Are you sure you want to turn down this account?"}
          setIsPopupOpened={setIsBlockPopupOpen}
          handleCancel={() => {
            onBlock()
            setIsBlockPopupOpen(false)
          }}
        />
      )}
      {anotherUser && (
        <div
          className={classNames(className, !isReported && "iconWrap")}
          onClick={handleIconClick}
        >
          {renderIcon()}
        </div>
      )}
    </>
  )
}

export default ReportButton
