import React, { useEffect, useState } from "react"
import styles from "./ArticleContainer.module.scss"
import Label from "../../components/elements/Label"
import LikeDislike from "../../components/LikeDislike/LikeDislike"
import TrendingCard from "../../components/TrendingCard/TrendingCard"
import { Link, useParams, useHistory } from "react-router-dom"
import QuoteCard from "../../components/QuoteCard/QuoteCard"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

import { useSelector } from "react-redux"
import services from "../../services"
import moment from "moment"
import copy from "copy-to-clipboard"
import CustomTooltip from "../../components/CompanyUserInfo/CustomTooltip"
import articleHints from "./helpers/articleHints"
import { ReactComponent as Twitter } from "../../images/icons/twitterMin.svg"
import { ReactComponent as Instagram } from "../../images/icons/instMin.svg"
import { ReactComponent as Facebook } from "../../images/icons/facebookMin.svg"
import Button from "../../components/Button/Button"
import { paths } from "../../routes/paths"
import Comments from "../../components/Comments"
import { formatComments } from "../../helpers/comments"
import Share from "../../components/Share"
import Breadcrumbs from "../../components/elements/Breadcrumbs"

const ArticleContainer = () => {
  const { width } = useWindowHeightWidth()
  const { uuid } = useParams()
  const { location } = useHistory()
  const userInfo = useSelector((state) => state.user)
  const history = useHistory()

  const [state, setState] = useState({})
  const [comments, setComments] = useState([])
  const isInstituteArticle = location.pathname.includes(
    paths.specificInstituteArticle(""),
  )
  const isInspirationArticle = location.pathname.includes(
    paths.specificInspirationArticle(""),
  )
  const fullUrl = `${window.location.origin}${
    isInstituteArticle
      ? paths.specificInstituteArticle(uuid)
      : paths.specificInspirationArticle(uuid)
  }`

  useEffect(() => {
    if (isInstituteArticle) {
      services.articleServices
        .getSpecificInstituteArticle(uuid)
        .then((result) => {
          setState(result.data)

          setComments(formatComments(result.data.comments, userInfo))
        })
        .catch((e) => console.log(e))
    } else if (isInspirationArticle) {
      services.articleServices
        .getSpecificInspirationArticle(uuid)
        .then((result) => {
          setState(result.data)
          setComments(formatComments(result.data.comments, userInfo))
        })
        .catch((e) => console.log(e))
    }
  }, [isInspirationArticle, isInstituteArticle, userInfo, uuid])

  useEffect(() => {
    const linksNode = document.querySelectorAll(`.${styles.bodyText} a`)
    const linksArray = Array.from(linksNode)
    let timeout
    const clickHandler = (e) => {
      if (
        e.target.classList.contains("goldLink") ||
        e.target.parentNode.classList.contains("goldLink")
      ) {
        e.preventDefault()
        if (e.target.nodeName === "A") {
          copy(e.target.href)
        } else {
          copy(e.target.parentNode.href)
        }
        e.target.classList.add(styles.copied)
        timeout = setTimeout(() => {
          e.target.classList.remove(styles.copied)
        }, 1500)
      }
    }

    if (linksArray.length > 0) {
      linksArray.forEach((link) => link.classList.add("goldLink"))

      document.addEventListener("click", clickHandler)
    }

    return () => {
      document.removeEventListener("click", clickHandler)
      clearTimeout(timeout)
    }
  }, [state.full_content])

  const onPostComment = (text, uuid = null) => {
    const articleType = isInstituteArticle
      ? "InstituteArticle"
      : "InspirationArticle"
    if (!uuid || Number.isInteger(uuid)) {
      services.commentsServices
        .postComment(text, state.id, articleType, uuid)
        .then((result) => {
          setState({ ...state, comments_count: state.comments_count + 1 })
          setComments(formatComments(result.data, userInfo))
        })
        .catch((e) => console.log(e))
    } else {
      services.commentsServices
        .editComment(text, uuid)
        .then((result) => {
          setState({ ...state, comments_count: state.comments_count + 1 })
          setComments(formatComments(result.data, userInfo))
        })
        .catch((e) => console.log(e))
    }
  }

  const onDeleteComment = (uuid) => {
    services.commentsServices
      .deleteComment(uuid)
      .then((result) => {
        setComments(formatComments(result.data, userInfo))
        setState({ ...state, comments_count: result.data.length })
      })
      .catch((e) => console.log(e))
  }

  const voteFn = (vote) => {
    services.articleServices[
      isInstituteArticle
        ? "voteForInstitudeArticle"
        : "voteForInspirationArticle"
    ]({ uuid, vote }).then(({ data }) => {
      setState({
        ...state,
        reputation: {
          ...data,
          type: data.type !== state.reputation.type ? data.type : null,
        },
      })
    })
  }

  return (
    <>
      <div className="container">
        <Breadcrumbs
          chain={[
            isInstituteArticle
              ? {
                  text: "Institute",
                  link: paths.instituteSearch(),
                }
              : {
                  text: "Inspiration",
                  link: paths.inspirationSearch(),
                },
            {
              text: state.title,
            },
          ]}
        />
      </div>
      <div className={styles.articleContainer}>
        <main>
          <div className={styles.mainInfo}>
            <div className={styles.articleHeader}>
              <h1 className={styles.title}>{state.title}</h1>

              <CustomTooltip text={articleHints[state.article_type]}>
                <span className={styles.labelWrapper}>
                  <Label subject="article" type={state.article_type} />
                </span>
              </CustomTooltip>
            </div>
            <div>
              <div className={styles.metaData}>
                {state.title && (
                  <Share
                    title={state.title}
                    description={state.content}
                    url={fullUrl}
                    type="outlined"
                  />
                )}
              </div>
              <img
                src={state.image_url}
                className={styles.image}
                alt={"article"}
              />
              <div className={styles.dateInfoBar}>
                <p className={styles.publishDate}>
                  PUBLISHED:{" "}
                  {moment(state.created_at).format("MMM DD, YYYY h:mm a")}
                </p>

                {state.reputation && (
                  <LikeDislike
                    clickedAction={state.reputation.type}
                    likeCount={state.reputation.positive}
                    dislikeCount={state.reputation.negative}
                    likeClick={(e) => {
                      voteFn("up")
                    }}
                    dislikeClick={(e) => {
                      voteFn("down")
                    }}
                  />
                )}
              </div>
              <p
                className={styles.bodyText}
                dangerouslySetInnerHTML={{ __html: state.full_content }}
              />
              <div className={styles.shareBlock}>
                <h3>share</h3>
                {state.title && (
                  <Share
                    title={state.title}
                    description={state.content}
                    url={fullUrl}
                    type="outlined"
                  />
                )}
              </div>

              <div className={styles.responses}>
                <Comments
                  data={comments}
                  userInfo={userInfo}
                  onCommentPost={onPostComment}
                  handleEditComment={onPostComment}
                  handleDeleteComment={onDeleteComment}
                  commentsCount={state.comments_count || 0}
                />
              </div>
            </div>
          </div>
        </main>

        {state.trends && (
          <div className={styles.trendings}>
            <h2>Trending Topics</h2>
            <div className={styles.trendingsList}>
              {state.trends.slice(0, width > 768 ? 4 : 2).map((item) => (
                <TrendingCard
                  title={item.title}
                  text={item.content}
                  imgUrl={item.image_url}
                  onClick={() =>
                    history.push(
                      isInstituteArticle
                        ? paths.specificInstituteArticle(item.uuid)
                        : paths.specificInspirationArticle(item.uuid),
                    )
                  }
                />
              ))}
            </div>
            <Link
              to={
                isInspirationArticle
                  ? paths.inspirationSearch()
                  : paths.instituteSearch()
              }
            >
              SHOW ALL NEWS
            </Link>
          </div>
        )}
      </div>
      <div className={styles.quotesList}>
        <QuoteCard />
      </div>
    </>
  )
}

export default ArticleContainer
