import queryString from "query-string"
import { useHistory, useLocation } from "react-router-dom"
import React from "react"

import styles from "./index.module.scss"
import Users from "./card-lists/users"
import Companies from "./card-lists/companies"
import Opportunities from "./card-lists/opportunities"
import Pagination from "../../components/elements/Pagination"
import NoDataPlaceholder from "../../components/elements/NoDataPlaceholder"
import Freelancers from "./card-lists/freelancers";

const FavoritesContainer = ({ data, type, currentPage }) => {
  const [list, setList] = React.useState(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)

  React.useEffect(() => {
    switch (type) {
      case "user":
        return setList(data.users)

      case "company":
        return setList(data.companies)

      case "opportunity":
        return setList(data.opportunities)

      case "freelancer":
        return setList(data.freelancers)

      default:
        return setList(data.users)
    }
  }, [data, type])

  React.useEffect(() => {
    if (list?.length === 0 && currentPage > 1) {
      history.push(
        `${location.pathname}?${queryString.stringify({
          ...queryParams,
          page: currentPage - 1,
        })}`,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  const removeFromFavorites = (uuid) => {
    setList(list.filter((item) => item.uuid !== uuid))
  }

  return (
    <React.Fragment>
      {list && data?.users && type === "user" && (
        <Users data={list} removeFromFavorites={removeFromFavorites} />
      )}
      {list && data?.companies && type === "company" && (
        <Companies data={list} removeFromFavorites={removeFromFavorites} />
      )}
      {list && data?.opportunities && type === "opportunity" && (
        <Opportunities data={list} removeFromFavorites={removeFromFavorites} />
      )}
      {list && data?.freelancers && type === "freelancer" && (
          <Freelancers data={list} removeFromFavorites={removeFromFavorites} />
      )}

      {(!list || list.length === 0) && (
        <NoDataPlaceholder>No favorites yet</NoDataPlaceholder>
      )}

      {getPageCount(data.total_count) > 1 && (
        <div className={styles.pagination}>
          <Pagination
            currentPageNumber={Number(currentPage)}
            totalPageCount={getPageCount(data.total_count)}
            onSelect={(page) =>
              history.push(
                `${location.pathname}?${queryString.stringify({
                  ...queryParams,
                  page,
                })}`,
              )
            }
          />
        </div>
      )}
    </React.Fragment>
  )
}

export default FavoritesContainer

const getPageCount = (totalCount, perPage = 6) =>
  Math.ceil(totalCount / perPage) || 1
