import React, { useState, useEffect, forwardRef } from "react"
import styles from "./HigherLearningItem.module.scss"
import classNames from "classnames"
import HigherLearningStaticItem from "./HigherLearningStaticItem"
import Input from "../../../../Input/Input"
import Dropdown from "../../../../Dropdown/Dropdown"
import { months, years } from "../../../../../helpers/helpData"
import { objectDeepCopy, objectComparison } from "../../../../../helpers"
import useValidation from "../hooks/useValidation"

const HigherLearningItem = ({
  isEditing,
  data,
  handleChangeInstitute,
  handleError,
  isLast,
  addInstitution,
  handleDeleteSchool,
  isAbleToDelete,
  id,
}) => {
  const [currentData, setCurrentData] = useState(objectDeepCopy(data))
  const yearsOptions = years(1950, 2030)
  const {
    handleBlur,
    handleChange,
    handeFocus,
    mapNewState,
    errors,
    touched,
  } = useValidation()

  useEffect(() => {
    if (!objectComparison(data, currentData)) {
      handleChangeInstitute(id, currentData)
    }
  }, [currentData])

  useEffect(() => {
    setCurrentData(objectDeepCopy(data))
    mapNewState(data, id)
  }, [isEditing])

  useEffect(() => {
    handleError(errors, id)
  }, [errors])

  const onChangeHandle = (e) => {
    const name = e.target.name
    const subName = name.substr(0, name.indexOf("-"))
    setCurrentData({ ...currentData, [subName]: e.target.value })
    handleChange(e.target.name, e.target.value)
  }

  const handleDropDown = (status, period, name, value) => {
    handleChange(name, value, currentData, id)
    setCurrentData({
      ...currentData,
      [status]: { ...currentData[status], [period]: value },
    })
  }

  if (!isEditing) {
    return HigherLearningStaticItem(data)
  }

  return (
    <div className={styles.educationWrapper}>
      <div className={styles.itemWrapper}>
        <p>School or University:</p>
        <div className={styles.formWrapper}>
          <Input
            name={`name-${id}`}
            onChange={onChangeHandle}
            value={currentData.name}
            error={errors[`name-${id}`] && touched[`name-${id}`]}
            errorMessage={errors[`name-${id}`]}
            onBlur={handleBlur}
            onFocus={handeFocus}
            autoFocus
            placeholder="Enter school or university title"
          />
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <p>Degree:</p>
        <div className={styles.formWrapper}>
          <Input
            name={`degree-${id}`}
            onChange={onChangeHandle}
            value={currentData.degree}
            error={errors[`degree-${id}`] && touched[`degree-${id}`]}
            errorMessage={errors[`degree-${id}`]}
            onBlur={handleBlur}
            onFocus={handeFocus}
            placeholder="Enter degree title"
          />
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <p>Date period:</p>
        <div className={classNames(styles.formWrapper, styles.periodWrapper)}>
          <div className={styles.period}>
            <span className={styles.mobileLabel}>Date period. From:</span>
            <Dropdown
              id={`StartMonth-${id}`}
              name={`StartMonth-${id}`}
              className={styles.dropdownMonth}
              placeholder="Month"
              options={months}
              value={currentData.start.month}
              onChange={(name, value) => {
                handleDropDown("start", "month", name, value)
              }}
              error={errors[`StartMonth-${id}`] && touched[`StartMonth-${id}`]}
              errorMessage={errors[`StartMonth-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
            <Dropdown
              id={`StartYear-${id}`}
              name={`StartYear-${id}`}
              className={styles.dropdownYear}
              placeholder="Year"
              options={yearsOptions}
              value={currentData.start.year}
              onChange={(name, value) => {
                handleDropDown("start", "year", name, value)
              }}
              error={errors[`StartYear-${id}`] && touched[`StartYear-${id}`]}
              errorMessage={errors[`StartYear-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
            <span className={styles.mobileLabel}>Date period. To:</span>
            <Dropdown
              id={`FinishMonth-${id}`}
              name={`FinishMonth-${id}`}
              className={classNames(styles.dropdownMonth, styles.tablet)}
              placeholder="Month"
              options={months}
              value={currentData.finish.month}
              onChange={(name, value) => {
                handleDropDown("finish", "month", name, value)
              }}
              error={
                errors[`FinishMonth-${id}`] && touched[`FinishMonth-${id}`]
              }
              errorMessage={errors[`FinishMonth-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
          </div>
          <span className={styles.devider}>–</span>
          <div className={styles.period}>
            <Dropdown
              id={`FinishMonth-${id}`}
              name={`FinishMonth-${id}`}
              className={styles.dropdownMonth}
              placeholder="Month"
              options={months}
              value={currentData.finish.month}
              onChange={(name, value) => {
                handleDropDown("finish", "month", name, value)
              }}
              error={
                errors[`FinishMonth-${id}`] && touched[`FinishMonth-${id}`]
              }
              errorMessage={errors[`FinishMonth-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
            <Dropdown
              id={`StartYear-${id}`}
              name={`StartYear-${id}`}
              className={classNames(
                styles.dropdownYear,
                styles.tablet,
                styles.mobile,
              )}
              placeholder="Year"
              options={yearsOptions}
              value={currentData.start.year}
              onChange={(name, value) => {
                handleDropDown("start", "year", name, value)
              }}
              error={errors[`StartYear-${id}`] && touched[`StartYear-${id}`]}
              errorMessage={errors[`StartYear-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
            <Dropdown
              id={`FinishYear-${id}`}
              name={`FinishYear-${id}`}
              className={styles.dropdownYear}
              placeholder="Year"
              options={yearsOptions}
              value={currentData.finish.year}
              onChange={(name, value) => {
                handleDropDown("finish", "year", name, value)
              }}
              error={errors[`FinishYear-${id}`] && touched[`FinishYear-${id}`]}
              errorMessage={errors[`FinishYear-${id}`]}
              onBlur={handleBlur}
              onFocus={handeFocus}
            />
          </div>
        </div>
      </div>
      <div className={styles.btns}>
        {isAbleToDelete && (
          <p
            className={classNames(
              "text16r",
              styles.addNewBtn,
              "goldLink",
              styles.deleteBtn,
            )}
            onClick={() => {
              handleDeleteSchool(id)
            }}
          >
            Delete
          </p>
        )}
        {isLast && (
          <p className={classNames("text16r", "goldLink", styles.addNewBtn)}>
            <span onClick={addInstitution}>+ Add new school</span>
          </p>
        )}
      </div>
    </div>
  )
}

export default HigherLearningItem
