import React, { useState, useEffect } from "react"
import styles from "./CompanyEmployeesContainer.module.scss"
import { useSelector } from "react-redux"
import { Breadcrumbs } from "@material-ui/core"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { paths } from "../../routes/paths"
import Button from "../../components/Button/Button"
import CompanyEmployeeItem from "../../components/elements/CompanyEmployeeItem/CompanyEmployeeItem"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import CompanyEmployeeCreatorPopup from "./popups/CompanyEmployeeCreatorPopup"
import CompanyEmployeeDeletePopup from "./popups/CompanyEmployeeDeletePopup"
import CustomTooltip from "../../components/CompanyUserInfo/CustomTooltip"
import services from "../../services"
import { COMPANY_ADMIN } from "../../helpers/userRoles"
import CompanyEmployeeInvitedPopup from "./popups/CompanyEmployeeInvitedPopup"
import Typography from "@material-ui/core/Typography"
import { isHaveBadWords } from "../../helpers/badWords"

const CompanyEmployeesContainer = () => {
  const userInfo = useSelector((state) => state.user)
  const { width } = useWindowHeightWidth()
  const [visiblePopup, setVisiblePopup] = useState("")
  const [selectedEmployee, setSelectedEmployee] = useState("")
  const [team, setTeam] = useState([])
  const [companyProfile, setCompanyProfile] = useState({})

  useEffect(() => {
    services.employeeServices
      .getEmployees()
      .then((result) => setTeam(result.data.team))
      .catch((e) => console.log(e))
    services.companyServices
        .getSelfCompanyInfo()
        .then(({ data }) => {
          const companyProfile = data
          setCompanyProfile(companyProfile)
          return companyProfile
        })
  }, [])

  const closePopup = () => {
    setVisiblePopup("")
    setSelectedEmployee("")
  }

  const handleSubmit = ({ email, fullName: full_name }) => {
    return services.employeeServices
      .createEmployee({ email, full_name })
      .then((result) => {
        setTeam((team) => [...team, result.data])
      })
      .catch((e) => {
        throw e
      })
  }

  const handleDelete = () => {
    const { uuid } = selectedEmployee
    services.employeeServices.deleteEmployee(uuid).then(() => {
      const newTeam = team.filter((member) => member.user.uuid !== uuid)
      setTeam(newTeam)
      closePopup()
    })
  }

  return (
    <div>
      <div className={styles.employeesContainer}>
        <Breadcrumbs className={styles.breadcrumbs}>
          <Link
            to={paths.selectedCopmanyProfile(userInfo.company.uuid)}
            className={styles.link}
          >
            {userInfo.company.name}
          </Link>
          <Typography
            // to={paths.departments(params.uuid)}
            className={classNames(styles.link, styles.currentLink)}
          >
            My Team
          </Typography>
        </Breadcrumbs>

        <div className={styles.employeesBox}>
          <div className={styles.topInfo}>
            <h5>My Team</h5>
            {userInfo.role === COMPANY_ADMIN && (
              <CustomTooltip
                text={
                  companyProfile?.max_seats_allowed
                    ? `You use ${team.length} of ${companyProfile?.max_seats_allowed} available seats`
                    : null
                }
              >
                <span>
                  <Button
                    onClick={() => {
                      setVisiblePopup("new")
                    }}
                    width={width < 451 ? "64px" : "186px"}
                    height="40px"
                    disabled={companyProfile?.max_seats_allowed !== null && (team.length >= companyProfile?.max_seats_allowed)}
                  >
                    {width < 451 ? "Add" : "Add Employee"}
                  </Button>
                </span>
              </CustomTooltip>
            )}
          </div>
          <>
            {team.length > 0 ? (
              team.map((employee) => (
                <CompanyEmployeeItem
                  employeeInfo={employee.user}
                  status={getStatus(employee.status)}
                  withMenu={userInfo.role === COMPANY_ADMIN}
                  onDelete={(employeeInfo) => {
                    setSelectedEmployee(employeeInfo)
                    setVisiblePopup("delete")
                  }}
                />
              ))
            ) : (
              <div className={styles.emptyPage}>
                <p>There are no company members yet.</p>
              </div>
            )}
          </>
        </div>
      </div>
      {visiblePopup === "new" && (
        <CompanyEmployeeCreatorPopup
          data={selectedEmployee}
          closeAction={closePopup}
          onSubmit={handleSubmit}
          setVisiblePopup={setVisiblePopup}
        />
      )}
      {visiblePopup === "delete" && (
        <CompanyEmployeeDeletePopup
          closeAction={closePopup}
          onDelete={handleDelete}
        />
      )}
      {visiblePopup === "invited" && (
        <CompanyEmployeeInvitedPopup
          closeAction={closePopup}
          onDelete={handleDelete}
          data={team[team.length - 1]?.user?.email}
        />
      )}
    </div>
  )
}

const getStatus = (status) => {
  switch (status) {
    case "invite_sent":
      return "Invite sent"
    default:
      return null
  }
}

export default CompanyEmployeesContainer
