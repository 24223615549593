import React from "react"
import { ReactComponent as Arrow } from "../../../../images/icons/arrow-top.svg"
import styles from "../index.module.scss"

const printArrow = (direction, currentPageNumber, totalPageCount, onSelect) => {
  switch (direction) {
    case "left": {
      const selectedPageNumber =
        currentPageNumber === 1 ? totalPageCount : currentPageNumber - 1
      return (
        <Arrow
          className={styles.arrowLeft}
          onClick={() => onSelect(selectedPageNumber)}
        />
      )
    }
    case "right": {
      const selectedPageNumber =
        currentPageNumber === totalPageCount ? 1 : currentPageNumber + 1
      return (
        <Arrow
          className={styles.arrowRight}
          onClick={() => onSelect(selectedPageNumber)}
        />
      )
    }
    default:
      new Error("Unknown direction type")
  }
}
export default printArrow
