import React from "react"
import NotificationsContainer from "../../containers/NotificationsContainer/NotificationsContainer"
import styles from "./NotificationsPage.module.scss"

const NotificationsPage = () => {
  return (
    <div className={styles.page}>
      <NotificationsContainer />
    </div>
  )
}

export default NotificationsPage
