import { useLastLocation } from "react-router-last-location"
import { useSelector } from "react-redux"

const useBreadcrumbs = ({ companyInfo, resourceName }) => {
  const lastLocation = useLastLocation()
  const userInfo = useSelector((state) => state.user)

  let paths = []

  if (resourceName) {
    paths = [
      {
        text: resourceName,
      },
    ]
  }

  if (lastLocation?.pathname.includes("resource-search")) {
    if (lastLocation?.search.includes("query=")) {
      paths = [
        {
          link: lastLocation.pathname + lastLocation.search,
          text: "Search",
        },
        ...paths,
      ]
    }

    paths = [
      {
        link: "/resource-search",
        text: "Resources",
      },
      ...paths,
    ]
  } else if (lastLocation?.pathname.includes("my-events")) {
    paths = [
      {
        link: "/resources/my-events",
        text: "My events",
      },
      ...paths,
    ]
  } else if (companyInfo) {
    paths = [
      {
        link: `/company/${companyInfo.uuid}`,
        text: companyInfo.name,
      },
      ...paths,
    ]

    if (userInfo.company?.uuid && userInfo.company.uuid === companyInfo.uuid) {
      if (lastLocation?.pathname.includes(companyInfo.uuid)) {
        paths.splice(1, 0, {
          link: `/company/${companyInfo.uuid}/resources`,
          text: "My Resources",
        })
      }
    }
  }

  return paths
}

export default useBreadcrumbs
