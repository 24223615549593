export const opportunities = [
  {
    title: "All Types",
    type: "",
  },
  {
    title: "Diverse Company Opportunity",
    type: "diverse_company",
  },
  {
    title: "Board of Directors Opportunity",
    type: "board_of_directors",
  },
  {
    title: "Capital/Funding Opportunity",
    type: "funding",
  },
  {
    title: "Executive Opportunity",
    type: "careers_executive",
  },
  {
    title: "Mentors Opportunity",
    type: "mentoring",
  },
  {
    title: "Projects Opportunity",
    type: "project",
  },
  {
    title: "Sponsors Opportunity",
    type: "sponsorship",
  },
  {
    title: "Professional Opportunity",
    type: "careers_professional",
  },
]
