import React from "react"
import styles from "./CompanyEmployeeItem.module.scss"
import classNames from "classnames"
import PropTypes from "prop-types"
import ThreeDotsMenu from "../../ThreeDotsMenu/ThreeDotsMenu"
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip"

const CompanyEmployeeItem = ({ employeeInfo, status, onDelete, withMenu }) => {
  const menu = [
    {
      name: "Delete",
      click: () => {
        onDelete(employeeInfo)
      },
    },
  ]

  return (
    <div className={styles.employeeWrapper}>
      {employeeInfo.full_name?.length > 27 ? (
        <CustomTooltip name={employeeInfo.full_name}>
          <p className={styles.name}>{employeeInfo.full_name}</p>
        </CustomTooltip>
      ) : (
        <p className={styles.name}>{employeeInfo.full_name}</p>
      )}
      <p className={styles.email}>{employeeInfo.email}</p>
      <div className={styles.statusWrapper}>
        <p
          className={classNames(styles.status, {
            [styles.hidden]: !status,
          })}
        >
          {status}
        </p>
        {withMenu && (
          <div className={styles.threeDots}>
            <ThreeDotsMenu actions={menu} />
          </div>
        )}
      </div>
    </div>
  )
}

CompanyEmployeeItem.propTypes = {
  departmentInfo: PropTypes.shape({
    name: PropTypes.string,
    employees_count: PropTypes.number,
    open_opportunities: PropTypes.number,
  }),
}

export default CompanyEmployeeItem
