import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import CommunityTopicEditContainer from "../../containers/CommunityTopicEditContainer/CommunityTopicEditContainer"

const CommunityTopicEditPage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)
  return <CommunityTopicEditContainer id={values.id} />
}

export default CommunityTopicEditPage
