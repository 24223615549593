import React from "react"
import classnames from "classnames"

import styles from "./index.module.scss"
import Button from "../../components/Button/Button"
import * as wizardStepsData from "./wizardStepsData"

const SignUpWizard = ({ userType }) => {
  const [currentStep, changeStep] = React.useState(0)
  const [wizardVisibility, setWizardVisibility] = React.useState(true)
  const [stepsData, setStepsData] = React.useState(null)

  React.useEffect(() => {
    if (userType === "company_seeker") {
      setStepsData(wizardStepsData.companySeekerSteps)
    } else if (userType === "company_provider") {
      setStepsData(wizardStepsData.companyProviderSteps)
    } else {
      setStepsData(wizardStepsData.individualSteps)
    }
  }, [userType])

  const closeWizard = () => {
    setWizardVisibility(false)
  }

  return stepsData && wizardVisibility ? (
    <div className={classnames(styles.wizardWrap, "container")}>
      <div className={styles.wizardStep}>
        <h2 className={styles.wizardStepTitle}>
          {currentStep + 1}. {stepsData[currentStep].title}
        </h2>

        <div className={styles.wizardStepDescription}>
          {stepsData[currentStep].description}
        </div>

        <div className={styles.wizardStepImage}>
          {React.createElement(stepsData[currentStep].image)}
        </div>
      </div>

      <div className={styles.wizardDots}>
        {stepsData.map((_, index) => (
          <div
            key={index}
            className={classnames(
              styles.wizardDotsItem,
              index === currentStep && styles.wizardDotsItemActive,
            )}
            onClick={() => changeStep(index)}
          />
        ))}
      </div>

      {currentStep + 1 === stepsData.length ? (
        <Button onClick={closeWizard}>Let’s start</Button>
      ) : (
        <Button onClick={() => changeStep(currentStep + 1)}>Next</Button>
      )}

      <div className={styles.wizardSkipButtonHandler}>
        {currentStep + 1 < stepsData.length && (
          <div className={styles.wizardSkipButton} onClick={closeWizard}>
            Skip
          </div>
        )}
      </div>
    </div>
  ) : (
    ""
  )
}

export default SignUpWizard
