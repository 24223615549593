import React from "react"
import { withFormik } from "formik"
import { object, string } from "yup"
import { useHistory } from "react-router-dom"

import styles from "./index.module.scss"
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"

const ResetPassword = ({
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  isSubmitting,
}) => {
  const history = useHistory()

  return (
    <div className={styles.formWrap}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <div className={styles.formItem}>
          <Input
            type="text"
            name="email"
            value={values.email}
            placeholder="Enter your email"
            label="Email"
            autoComplete="off"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email}
            errorMessage={errors.email && touched.email && errors.email}
          />
        </div>

        <div className={styles.formBottom}>
          <div className={styles.formBottomItem}>
            <Button
              type="button"
              onClick={() => history.push("/login")}
              buttonStyle="outlined"
              disabled={isSubmitting}
              width="100%"
            >
              Cancel
            </Button>
          </div>

          <div className={styles.formBottomItem}>
            <Button type="submit" disabled={isSubmitting} width="100%">
              Send Request
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

const ResetPasswordForm = withFormik({
  mapPropsToValues() {
    return {
      email: "",
    }
  },

  validationSchema: () =>
    object().shape({
      email: string()
        .required(
          "Please enter your email address in format yourname@example.com",
        )
        .email("Please enter your email address in format yourname@example.com")
        .max(
          200,
          "Please enter a valid email address that consists of no more than 200 characters",
        ),
    }),

  handleSubmit(values, { setSubmitting, setFieldError, props: { onConfirm } }) {
    const res = {
      email: values.email,
    }

    onConfirm(res)
      .catch((data) => {
        if (data.error) {
          let errorMsg = data.error

          if (data.error === "User with current email not found") {
            errorMsg =
              "This email address doesn’t belong to any registered user."
          } else if (data.error === "Only 3 attempts per 1 hour") {
            errorMsg =
              "You requested password reset 3 times already. Please try again in 24 hours."
          }

          setFieldError("email", errorMsg)
        }
      })
      .finally(() => setSubmitting(false))
  },
})(ResetPassword)

export default ResetPasswordForm
