import React from "react"
import classNames from "classnames"
import Breadcrumbs from "../../../../components/elements/Breadcrumbs"
import { paths } from "../../../../routes/paths"
import styles from "./index.module.scss"

const CaptionWrapper = ({ state }) => {
  const { itemCount, query } = state.requestResultGroup
  return !query ? (
    <div className={styles.topCaption}>
      <div className={styles.container}>
        <span>
          Popular Topics
          <span className={classNames(styles.line, styles.toLeft)}></span>
          <span className={classNames(styles.line, styles.toRight)}></span>
        </span>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.searchCaption}>
        <Breadcrumbs
          classes={styles.breadcrumb}
          chain={[
            {
              link: paths.instituteSearch(),
              text: "Institute",
            },
            { text: "Search results" },
          ]}
        />
        {!!itemCount && (
          <p className={styles.resultCount}>
            {query
              ? `We found ${itemCount} results for “${query}”`
              : "No articles yet"}
          </p>
        )}
      </div>
    </div>
  )
}
export default CaptionWrapper
