import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import classNames from "classnames"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import styles from "./CompanyDepartmentsContainer.module.scss"
import DepartmentItem from "../../components/CompanyDepartments/DepartmentItem/DepartmentItem"
import DepartmentCreatorPopup from "../../components/CompanyDepartments/DepartmentCreatorPopup/DepartmentCreatorPopup"
import Button from "../../components/Button/Button"
import { paths } from "../../routes/paths"
import services from "../../services"
import Popup from "../../components/Popup/Popup"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

const CompanyDepartmentsContainer = () => {
  const [departments, setDepartments] = useState([])
  const [companyName, setCompanyName] = useState("")
  const [selectedDepartmentId, setSelectedDepartmentId] = useState()
  const [deletePopup, setDeletePopup] = useState(false)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const { width } = useWindowHeightWidth()
  const lastLocation = useLastLocation()

  const userInfo = useSelector((state) => state.user)
  const params = useParams()

  const isAbleToEdit = userInfo.company.uuid === params.uuid

  useEffect(() => {
    services.departmentsServices
      .getCopmanyDepartments(params.uuid)
      .then((result) => {
        setDepartments(result.data.departments)
        setCompanyName(result.data.company_name)
      })
  }, [params.uuid])

  const saveNewDeparment = async ({ departmentName, employees }) => {
    try {
      const formattedData = {
        name: departmentName,
        employees_count: employees,
      }

      const result = await services.departmentsServices.createDepartment(
        userInfo.company.uuid,
        formattedData,
      )
      setDepartments([result.data, ...departments])
    } catch (e) {
      throw e
    }
  }

  const saveEditedDepartment = async ({ departmentName, employees }) => {
    try {
      const formattedData = {
        name: departmentName,
        employees_count: employees,
      }

      const newDepartments = [...departments]

      const indexOfEditedItem = newDepartments.findIndex(
        (d) => d.uuid === selectedDepartmentId,
      )

      if (selectedDepartmentId) {
        const result = await services.departmentsServices.editCompanyDepartment(
          {
            companyUuid: userInfo.company.uuid,
            departmentUuid: selectedDepartmentId,
            data: formattedData,
          },
        )

        newDepartments.splice(indexOfEditedItem, 1, {
          ...newDepartments[indexOfEditedItem],
          ...result.data,
        })
        setDepartments([...newDepartments])
        setSelectedDepartmentId()
      }
    } catch (e) {
      throw e
    }
  }

  const triggerDeletePopup = (id) => {
    const selectedDepartment = departments.find((d) => d.uuid === id)
    if (selectedDepartment.open_opportunities === 0) {
      setSelectedDepartmentId(id)
      setDeletePopup(true)
    }
  }

  const triggerEditPopup = (id) => {
    setSelectedDepartmentId(id)
    setVisiblePopup(true)
  }

  const onDeleteDepartment = async () => {
    services.departmentsServices
      .deleteCompanyDepartment(userInfo.company.uuid, selectedDepartmentId)
      .then(() => {
        setDepartments(
          departments.filter(
            (department) => department.uuid !== selectedDepartmentId,
          ),
        )
        setDeletePopup(false)
        setSelectedDepartmentId()
      })
  }

  const pageTitle = lastLocation?.pathname.includes("company-profile")
    ? "My"
    : "Company/Organization"

  return (
    <div className={styles.departmentsContainer}>
      <Breadcrumbs className={styles.breadcrumbs}>
        <Link
          to={paths.selectedCopmanyProfile(params.uuid)}
          className={styles.link}
        >
          {companyName}
        </Link>
        <Link
          to={paths.departments(params.uuid)}
          className={classNames(styles.link, styles.currentLink)}
        >
          {pageTitle} Departments
        </Link>
      </Breadcrumbs>

      <div className={styles.departmentsBox}>
        <div className={styles.topInfo}>
          <h5>{pageTitle} Departments</h5>

          {isAbleToEdit && (
            <Button
              onClick={() => {
                setVisiblePopup(true)
              }}
              width={width < 451 ? "64px" : "186px"}
              height="40px"
            >
              {width < 451 ? "Add" : "Add Department"}
            </Button>
          )}
        </div>
        {departments.length > 0 ? (
          <>
            {departments.map((department) => (
              <DepartmentItem
                key={department.id}
                departmentInfo={department}
                onDelete={triggerDeletePopup}
                onEdit={triggerEditPopup}
                withMenu={isAbleToEdit}
              />
            ))}
          </>
        ) : (
          <div className={styles.emptyPage}>
            <p>You have not added any departments yet</p>
          </div>
        )}
      </div>

      {/* Popups   */}

      <DepartmentCreatorPopup
        title={selectedDepartmentId ? "Edit department" : "Add Department"}
        visible={visiblePopup}
        closeAction={() => {
          setVisiblePopup(false)
          setSelectedDepartmentId()
        }}
        maxNumber={departments.length >= 12}
        onSave={selectedDepartmentId ? saveEditedDepartment : saveNewDeparment}
        departmentInfo={
          selectedDepartmentId
            ? departments.find(
                (department) => department.uuid === selectedDepartmentId,
              )
            : null
        }
      />

      {deletePopup && (
        <Popup
          dialog
          subtitle="Do you want to delete this department?"
          button={
            <Button buttonStyle="black" onClick={onDeleteDepartment}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setDeletePopup(false)
                setSelectedDepartmentId()
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setDeletePopup(false)
            setSelectedDepartmentId()
          }}
          isCrossIcon={true}
        />
      )}
    </div>
  )
}

export default CompanyDepartmentsContainer
