import React from "react"
import { useHistory } from "react-router-dom"
import classNames from "classnames"
import { toast } from "react-toastify"

import { ReactComponent as ChatIcon } from "../../../images/icons/Vector.svg"
import { ReactComponent as HeartIcon } from "../../../images/icons/heartIcon.svg"
import { ReactComponent as HeartFilledIcon } from "../../../images/icons/heart-filled.svg"
import styles from "./index.module.scss"
import services from "../../../services"
import ConfirmCancelPopup from "../../Popup/ConfirmCancelPopup"
import { options, raceOptions, professionOptions, experienceOptions } from "../../UserInfo/helpers"
import ToastInfo from "../../ToastContents/ToastInfo"
import { paths } from "../../../routes/paths"

type IndividualCardProps = {
  /** External styles */
  classes?: any
  /** Individual Avatar */
  individualAvatar: string
  /** Individual Name */
  individualName: string
  /** Individual Uuid */
  userUuid: string
  /** Chat Uuid */
  chatUuid: string
  /** Opportunity description */
  /** Individual Role */
  individualRole: string
  /** Individual Activity */
  individualActivity: string
  /** Individual Profession */
  individualProfession: string
  /** Individual Experience */
  individualExperience: string
  /** Description */
  description: string
  /** Card shows up for owner */
  isOwned: boolean
  /** Callback for click on the avatar & name */
  onClick: (e: React.MouseEvent) => void
  /** Callback for click on the chat icon */
  onChatOpen: (chatUuid: string, userUuid: string, userType: string) => void
  removeFromFavorites?: (uuid: string) => void
  isFavorite?: Boolean
  race: string
  gender: string
}
const IndividualCard = ({
  classes,
  individualAvatar,
  individualName,
  individualRole,
  individualActivity,
  individualProfession,
  individualExperience,
  description,
  isOwned,
  onClick,
  onChatOpen,
  userUuid,
  chatUuid,
  removeFromFavorites = () => {},
  isFavorite = false,
  race,
  gender,
}: IndividualCardProps) => {
  const roleObject = individualRole
    ? options.find((option) => option.databaseName === individualRole)
    : null
  const role = roleObject?.value || null

  const activityObject =
    individualActivity && roleObject
      ? roleObject.subValue.find(
          (value) => value.databaseName === individualActivity,
        )
      : null
  const activity = activityObject?.value || null

  const professionObject = individualProfession
  ? professionOptions.find((option) => option.databaseName === individualProfession)
  : null
  const profession = professionObject?.value || null

  const experienceObject = individualExperience
  ? experienceOptions.find((option) => option.databaseName === individualExperience)
  : null
  const experience = experienceObject?.value || null

  const raceObj =
    raceOptions.find((raceOption) => raceOption.databaseName === race)?.value ||
    null

  const [favorite, setFavorite] = React.useState(isFavorite)
  const history = useHistory()

  const addToFavorites = () => {
    services.favoriteServices
      .addRemoveToList({
        type: "user",
        uuid: userUuid,
        favorite: !favorite,
      })
      .then(() => {
        setFavorite(!favorite)

        if (favorite) {
          removeFromFavorites(userUuid)
        } else {
          toast.info(
            <ToastInfo>
              User <span className={styles.highlighted}>{individualName}</span>{" "}
              was successfully added to your favorites
            </ToastInfo>,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
          )
        }
      })
  }

  const openChat = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()

    if (chatUuid) {
      history.push(paths.chats(chatUuid))
    } else {
      services.chatServices
        .createChatRoom({ type: "user", uuid: userUuid })
        .then(({ data }) => {
          history.push(paths.chats(data.uuid))
        })
    }
  }

  const [confirmPopupOpened, setConfirmPopupOpened] = React.useState(false)

  return (
    <React.Fragment>
      {confirmPopupOpened && (
        <ConfirmCancelPopup
          cancelMessage="Are you sure you want to delete user from favorites?"
          setIsPopupOpened={setConfirmPopupOpened}
          handleCancel={() => {
            addToFavorites()
            setConfirmPopupOpened(false)
          }}
        />
      )}

      <div className={classNames(styles.card, classes)} onClick={onClick}>
        <div className={styles.block}>
          <div
            className={styles.individualAvatar}
            style={{
              ...(individualAvatar && {
                backgroundImage: `url(${individualAvatar})`,
              }),
            }}
          />
          <span className={styles.individualName}>{individualName}</span>
        </div>
        {profession && experience && (
          <p className={styles.individualType}>{`${profession} · ${experience}`}</p>
        )}
        {!isOwned && (
          <>
            <ChatIcon className={styles.chatButton} onClick={openChat} />

            <div
              className={classNames(
                styles.heartButton,
                favorite && styles.favorite,
              )}
              onClick={(e) => {
                e.stopPropagation()

                if (!favorite) {
                  addToFavorites()
                } else {
                  setConfirmPopupOpened(true)
                }
              }}
            >
              {favorite ? <HeartFilledIcon /> : <HeartIcon />}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
export default IndividualCard
