export const options = [
  {
    id: "",
    value: "All Types",
  },
  {
    id: "business_news",
    value: "Business News",
  },
  {
    id: "cryptocurrency",
    value: "Cryptocurrency",
  },
  {
    id: "cybersecurity",
    value: "Cybersecurity",
  },
  {
    id: "energy",
    value: "Energy",
  },
  {
    id: "financial_services",
    value: "Financial Services",
  },
  {
    id: "healthcare",
    value: "Healthcare",
  },
  {
    id: "industrial",
    value: "Industrial",
  },
  {
    id: "publications",
    value: "Publications",
  },
  {
    id: "technology",
    value: "Technology",
  },
  {
    id: "telecom",
    value: "Telecom",
  },
]
