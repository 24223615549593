import React from "react"
import Pagination from "../../../../components/elements/Pagination"
import { setSearchPage } from "../../useSearch/actionCreators"
import QuestionCard from "../../../../components/cards/QuestionCard"
import { checkIsFilterUsed } from "./helpers"
import { CircularProgress } from "@material-ui/core"
import styles from "./index.module.scss"

const MainWrapper = ({ state, dispatch }) => {
  const {
    itemList,
    itemCount,
    pageCount,
    isFetching,
  } = state.requestResultGroup
  const queryParams = state.searchParamGroup

  if (isFetching)
    return (
      <div className={styles.loader}>
        <CircularProgress />
      </div>
    )
  const isFilterUsed = checkIsFilterUsed(state)
  return (
    <>
      {!!itemCount && (
        <>
          <div className={styles.itemList}>
            {itemList.map((item) => (
              <QuestionCard key={item.id} data={item} />
            ))}
          </div>
          {pageCount > 1 && (
            <div className={styles.pagination}>
              <Pagination
                currentPageNumber={parseInt(state.searchParamGroup.page)}
                totalPageCount={pageCount}
                onSelect={(pageNumber) => dispatch(setSearchPage(pageNumber))}
              />
            </div>
          )}
        </>
      )}
      {!itemCount && !queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Nothing here yet.
          <br />
          Be the first one to contribute.
        </p>
      )}
      {!itemCount && !!queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Sorry, we found 0 matches.
          <br />
          Please use other keywords or filters.
        </p>
      )}
      {!itemCount && isFilterUsed && (
        <p className={styles.notFoundBlock}>
          There are no results that match your search.
          <br />
          Please try adjusting your filters.
        </p>
      )}
    </>
  )
}
export default MainWrapper
