import React, {useEffect, useState} from "react"
import styles from "./OpportunityShareContainer.module.scss"
import userServices from "../../services";
import classNames from "classnames";
import OpportunityShareForm from "../../forms/OpportunityShareForm/OpportunityShareForm";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import {Link, useHistory} from "react-router-dom";
import opportunityServices from "../../services/opportunityServices";

const OpportunityShareContainer = ({id}) => {
    const [opportunity, setOpportunity] = useState({})
    const history = useHistory()

    console.log("Opportunity set data:")
    console.log(id)

    const onSave = (newData) => {
        console.log("Sharing private opportunity")
        console.log(id)
        console.log(newData)
        opportunityServices
            .sharePrivateOpportunity(id, newData)
            .then(() => {
                history.push(`/opportunity?id=${id}`)
            })
    }

    useEffect(() => {
        if (id) {
            console.log("Fetching opportunity with id:", id);
            userServices.opportunityServices
                .getOpportunity(id)
                .then(({data}) => {
                    console.log("Opportunity set data:", data);
                    setOpportunity(data);
                })
                .catch(error => {
                    console.error("Failed to fetch opportunity:", error);
                });
        }
    }, [id]);

    return (
        <div className={styles.opportunityShareWrapper}>
            <div className={classNames("container", "fullWidth", styles.wrapper)}>
                <div className={styles.formWrapper}>
                    <div className={styles.breadcrumbs}>
                        <Breadcrumbs className={styles.breadcrumbs} aria-label="breadcrumb">
                            <Link className={styles.link} to={`/opportunity/${id}`}>Opportunity</Link>
                            <Link className={styles.link} to={`/opportunity/${id}/share`}>Share</Link>
                        </Breadcrumbs>
                    </div>
                    <OpportunityShareForm
                        onSave={onSave}
                        //onDelete={() => setDeletePopupOpened(true)}
                        data={opportunity}
                    />
                </div>
            </div>
        </div>
    )
}

export default OpportunityShareContainer
