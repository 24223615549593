import React, { useEffect, useRef, useState } from "react"

import Input from "../Input/Input"
import CalendarComponent from "../Calendar/Calendar"
import useOutsideClicker from "../../hooks/useOutsideClicker"
import styles from "./DatePicker.module.scss"
import classNames from "classnames"
const DatePicker = ({
  initialFrom,
  initialTo,
  onChange,
  calendarWidth,
  withoutError,
}) => {
  const [calendarOpened, setCalendarOpened] = useState(0)
  const [from, setFrom] = useState(initialFrom || null)
  const [to, setTo] = useState(initialTo || null)

  useEffect(() => {
    if (from || to) onChange({ from, to })
  }, [from, to])

  useEffect(() => {
    setFrom(initialFrom)
    setTo(initialTo)
  }, [initialFrom, initialTo])

  const CalendarRef = useRef(null)

  useOutsideClicker(CalendarRef, () => {
    setCalendarOpened(0)
  })

  const getSelectedDate = () => {
    switch (calendarOpened) {
      case 1: {
        return from
      }
      case 2: {
        return to
      }
    }
  }

  const getMaxDate = () => {
    switch (calendarOpened) {
      case 1: {
        if (to) {
          return new Date(to) || new Date()
        } else return new Date()
      }
      case 2: {
        return new Date()
      }
    }
  }

  const handleChangeCalendar = (type, value) => {
    switch (type) {
      case 1: {
        setFrom(
          new Date(value).toLocaleDateString("en-US").replace(/[^ -~]/g, ""),
        )
        setCalendarOpened(0)
        break
      }
      case 2: {
        setTo(
          new Date(value).toLocaleDateString("en-US").replace(/[^ -~]/g, ""),
        )
        if (new Date(from) > new Date(value)) {
          setFrom(null)
        }
        setCalendarOpened(0)
        break
      }
      default: {
        return null
      }
    }
  }

  return (
    <div
      className={classNames(
        styles.calendar,
        withoutError && styles.withoutError,
      )}
    >
      <div className={styles.calendarInputsWrap}>
        <div className={styles.calendarInput}>
          <Input
            name={"from"}
            label={"Application Date"}
            value={from}
            onChange={() => {}}
            placeholder={"From"}
            onClick={() => {
              setCalendarOpened(1)
            }}
          />
        </div>
        <span className={styles.dot}>-</span>
        <div className={styles.calendarInput}>
          <Input
            name={"to"}
            value={to}
            label={" "}
            onChange={() => {}}
            placeholder={"To"}
            onClick={() => {
              setCalendarOpened(2)
            }}
          />
        </div>
      </div>
      {Boolean(calendarOpened) && (
        <div ref={CalendarRef} className={styles.calendarElement}>
          <CalendarComponent
            minDate={new Date("January 1, 2020 03:24:00")}
            width={calendarWidth}
            selected={getSelectedDate()}
            maxDate={getMaxDate()}
            onChange={(value) => handleChangeCalendar(calendarOpened, value)}
          />
        </div>
      )}
    </div>
  )
}

DatePicker.propTypes = {}

export default DatePicker
