import React from "react"
import { useHistory } from "react-router-dom"
import classNames from "classnames"
import { toast } from "react-toastify"

import { ReactComponent as ChatIcon } from "../../../images/icons/Vector.svg"
import { ReactComponent as HeartIcon } from "../../../images/icons/heartIcon.svg"
import { ReactComponent as HeartFilledIcon } from "../../../images/icons/heart-filled.svg"
import styles from "./index.module.scss"
import services from "../../../services"
import ConfirmCancelPopup from "../../Popup/ConfirmCancelPopup"
import { industryOptions } from "../../CompanyUserInfo/options/industryOptions"
import { roleOptions } from "../../CompanyUserInfo/options/roleOptions"
import ToastInfo from "../../ToastContents/ToastInfo"
import { paths } from "../../../routes/paths"

type CompanyCardProps = {
  /** External styles */
  classes?: any
  /** Company Avatar */
  companyAvatar: string
  /** Company Name */
  companyName: string
  /** Company Uuid */
  companyUuid: string
  /** Chat Uuid */
  chatUuid: string
  /** Opportunity description */
  /** Company Role */
  companyType: string
  /** Company Role */
  companyRole: string
  /** Company Activity */
  companyActivity: string
  /** Description */
  description: string
  /** Card shows up for owner */
  isOwned: boolean
  /** Callback for click on the card */
  onClick: (e: React.MouseEvent) => void
  /** Callback for click on the chat icon */
  onChatOpen: (chatUuid: string, userUuid: string, userType: string) => void
  /** individual cont chat with company*/
  isHaveChatButton: boolean
  removeFromFavorites?: (uuid: string) => void
  isFavorite?: Boolean,
  industry: string,
}
const CompanyCard = ({
  classes,
  companyAvatar,
  companyName,
  companyType,
  companyRole,
  companyActivity,
  description,
  isOwned,
  onClick,
  companyUuid,
  chatUuid,
  onChatOpen,
  isHaveChatButton,
  removeFromFavorites = () => {},
  isFavorite = false,
  industry
}: CompanyCardProps) => {
  const roleObject = companyRole
    ? roleOptions.find((item) => item.companyType === companyType && item.databaseName === companyRole)
    : null
  const role = roleObject?.value || null

  const industryObject = industry ? industryOptions.find(item => item.databaseName === industry) : null
  const industr = industryObject?.value || null

  const activityObject =
    companyActivity && roleObject
      ? roleObject.subValue.find(
          (value) => value.databaseName === companyActivity,
        )
      : null
  const activity = activityObject?.value || null

  const [favorite, setFavorite] = React.useState(isFavorite)
  const history = useHistory()

  const addToFavorites = () => {
    services.favoriteServices
      .addRemoveToList({
        type: "company",
        uuid: companyUuid,
        favorite: !favorite,
      })
      .then(({ data }) => {
        setFavorite(!favorite)

        if (favorite) {
          removeFromFavorites(companyUuid)
        } else {
          toast.info(
            <ToastInfo>
              The <span className={styles.highlighted}>{companyName}</span>{" "}
              company was successfully added to your Favorites
            </ToastInfo>,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
          )
        }
      })
  }

  const openChat = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()

    if (chatUuid) {
      history.push(paths.chats(chatUuid))
    } else {
      services.chatServices
        .createChatRoom({ type: "company", uuid: companyUuid })
        .then(({ data }) => {
          history.push(paths.chats(data.uuid))
        })
    }
  }

  const [confirmPopupOpened, setConfirmPopupOpened] = React.useState(false)

  return (
    <React.Fragment>
      {confirmPopupOpened && (
        <ConfirmCancelPopup
          cancelMessage="Are you sure you want to delete user from favorites?"
          setIsPopupOpened={setConfirmPopupOpened}
          handleCancel={() => {
            addToFavorites()
            setConfirmPopupOpened(false)
          }}
        />
      )}

      <div className={classNames(styles.card, classes)} onClick={onClick}>
        <div className={styles.block}>
          <div
            className={styles.companyAvatar}
            style={{
              ...(companyAvatar && {
                backgroundImage: `url(${companyAvatar})`,
              }),
            }}
          />
          <span className={styles.companyName}>{companyName}</span>
        </div>
        {role && (
          <p className={styles.companyType}>{`${role} ${activity ? ` · ${activity}` : ""} ${industr ? ` · ${industr}` : ""}`}</p>
        )}
        {description && <p className={styles.description}>{description}</p>}
        {!isOwned && (
          <>
            {isHaveChatButton && (
              <ChatIcon className={styles.chatButton} onClick={openChat} />
            )}

            <div
              className={classNames(
                styles.heartButton,
                favorite && styles.favorite,
              )}
              onClick={(e) => {
                e.stopPropagation()

                if (!favorite) {
                  addToFavorites()
                } else {
                  setConfirmPopupOpened(true)
                }
              }}
            >
              {favorite ? <HeartFilledIcon /> : <HeartIcon />}
            </div>
          </>
        )}
      </div>
    </React.Fragment>
  )
}
export default CompanyCard
