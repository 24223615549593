import {
  OPEN_FROM_DATE,
  OPEN_TO_DATE,
  SET_DATES,
  CLOSE_CALENDAR,
} from "./reducer"

export const openFromDate = () => ({
  type: OPEN_FROM_DATE,
  payload: null,
})
export const openToDate = () => ({
  type: OPEN_TO_DATE,
  payload: null,
})
export const setDates = (fromDate, toDate) => ({
  type: SET_DATES,
  payload: { fromDate, toDate },
})
export const closeCalendar = () => ({
  type: CLOSE_CALENDAR,
  payload: null,
})
