import React from "react"
import PropTypes from "prop-types"

import SignUpContainer from "./SignUpContainer"
import CompanyStep3Form from "../../forms/SignUpForms/CompanyStep3Form"
import SignUpWizard from "../../components/SignUpWizard"

function SignUpCompanyStep3Container({
  onHandleNext,
  onBack,
  handleSaveUser,
  user,
}) {
  return (
    <SignUpContainer
      title="Sign Up Process"
      subtitle="Step 3: Profile Creation"
      description="Fill up info to continue to platform"
      onBack={onBack}
      onNext={onHandleNext}
      isButtonSubmit
      withBackBtn
    >
      {user?.type && <SignUpWizard userType={user.type} />}

      <CompanyStep3Form handleSaveUser={handleSaveUser} />
    </SignUpContainer>
  )
}

SignUpCompanyStep3Container.propTypes = {
  onHandleNext: PropTypes.func,
  onHandleRole: PropTypes.func,
  user: PropTypes.any,
}

export default SignUpCompanyStep3Container
