import React from "react"

import styles from "./index.module.scss"
import AttendeesContainer from "../../containers/AttendeesContainer"

const AttendeesPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <AttendeesContainer />
    </div>
  )
}

export default AttendeesPage
