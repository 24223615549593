import React, { Fragment } from "react"
import { useLocation } from "react-router-dom"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"
import { ToastContainer } from "react-toastify"

import "./App.scss"
import Header from "./components/Header"
import Footer from "./components/Footer/Footer"
import ScrollToTop from "./helpers/scrollToTop"
import { paths } from "./routes/paths"

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 768,
      lg: 1440,
      xl: 1920,
    },
  },
})

function App({ children }) {
  const location = useLocation()

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        autoClose={4000}
        position="top-right"
        hideProgressBar
        newestOnTop
        closeOnClick
        className="custom-toast-component"
      />
      <ScrollToTop />

      <div className="mainWrapper">
        {location.pathname !== paths.companyProviderLanding &&
        location.pathname !== paths.individualLanding ? (
          <Fragment>
            <Header />
            <div className="content">{children}</div>
            <Footer />
          </Fragment>
        ) : (
          <>{children}</>
        )}
      </div>
    </ThemeProvider>
  )
}

export default App
