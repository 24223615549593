import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"

const PageLinedTitle = ({ title, ...rest }) => {
  return (
    <div className={styles.titleWrap} {...rest}>
      <div className="container">
        <span className={styles.titleText}>{title}</span>
      </div>
    </div>
  )
}

export default PageLinedTitle

PageLinedTitle.propTypes = {
  title: PropTypes.string.isRequired,
}
