import React from "react"
import PropTypes from "prop-types"
import { useHistory, useLocation, useParams } from "react-router-dom"

import Breadcrumbs from "../../components/elements/Breadcrumbs"
import PageLinedTitle from "../../components/elements/PageLinedTitle"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import Button from "../../components/Button/Button"
import QuestionsList from "../../components/QuestionsList"
import QuestionsSidebar from "../../components/QuestionsSidebar"
import Loading from "../../components/Loading/Loading"
import Pagination from "../../components/elements/Pagination"
import { useSelector } from "react-redux"
import queryString from "query-string"

const CommunityQuestionsContainer = ({
  breadcrumbs,
  pageTitle,
  currentPage,
  isLoading,
  popular_topics,
  questions,
}) => {
  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const userUuid = useSelector((state) => state.user.uuid)

  const values = queryString.parse(location.search)
  const isMyQuestions = userUuid === params.uuid || params?.uuid === undefined
  return (
    <React.Fragment>
      <div className="container">
        <Breadcrumbs chain={breadcrumbs} />
      </div>

      <div style={{ marginBottom: 12 }}>
        <PageLinedTitle title={pageTitle} />
      </div>

      <ContentWithSidebars
        topSidebar={() =>
          isMyQuestions && (
            <Button
              width="100%"
              onClick={() => history.push("/community-topic-edit")}
            >
              Add Question
            </Button>
          )
        }
        bottomSidebar={() => (
          <QuestionsSidebar
            data={popular_topics}
            isLoading={isLoading}
            title="Popular Topics"
          />
        )}
      >
        {isLoading ? <Loading /> : <QuestionsList data={questions.on_page} />}

        {getPageCount(questions.total_count, 6) && (
          <div style={{ padding: "20px", textAlign: "right" }}>
            <Pagination
              currentPageNumber={currentPage}
              totalPageCount={getPageCount(questions.total_count, 6)}
              onSelect={(page) =>
                history.push(`${location.pathname}?page=${page}`)
              }
            />
          </div>
        )}
      </ContentWithSidebars>
    </React.Fragment>
  )
}

CommunityQuestionsContainer.defaultProps = {
  questions: {
    on_page: [],
    total_count: 0,
  },
}

CommunityQuestionsContainer.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  pageTitle: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  popular_topics: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  questions: PropTypes.shape({
    on_page: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
    total_count: PropTypes.number,
  }),
}

export default CommunityQuestionsContainer

export const getPageCount = (totalCount, perPage) =>
  Math.ceil(totalCount / perPage) || 1
