import React, { useEffect, useState } from "react"
import { useLastLocation } from "react-router-last-location"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import classNames from "classnames"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import styles from "./ResourceEditContainer.module.scss"
import ResourceForm from "../../forms/ResourceForm/ResourceForm"
import { convertCamelCaseToTitle } from "../../helpers/textHelpers"
import userServices from "../../services"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"

const ResourceEditContainer = ({ type, id }) => {
  const [data, setData] = useState({})
  const history = useHistory()
  const lastLocation = useLastLocation()
  const companyName = useSelector((state) => state.user.company.name)
  const companyId = useSelector((state) => state.user.company.id)
  const companyUuid = useSelector((state) => state.user.company.uuid)
  const userPhoto = useSelector((state) => state.user.company.photo)
  const [deletePopupOpened, setDeletePopupOpened] = useState(false)
  const onDeleteResource = () => {
    if (id && data?.company?.uuid) {
      userServices.resourceServices.deleteResourceByUuid(id).then(() => {
        history.push(`/company/${data.company.uuid}/resources`)
      })
    }
  }

  const onSave = (newData) => {
    if (id) {
      userServices.resourceServices
        .updateResource({
          ...data,
          ...newData,
          company_id: companyId,
        })
        .then((data) => history.push(`/resources/${data.data.uuid}`))
    } else {
      userServices.resourceServices
        .createResource({
          ...newData,
          company_id: companyId,
        })
        .then((data) => history.push(`/resources/${data.data.uuid}`))
    }
  }

  useEffect(() => {
    if (id) {
      userServices.resourceServices
        .getResourceByUuid(id)
        .then(({ data }) => setData(data))
    }
  }, [])

  useEffect(() => {
    setData({
      ...data,
      ["company_name"]: companyName,
      company: { photo: userPhoto },
    })
  }, [companyName, userPhoto])

  return (
    <div className={styles.resourcesEditWrapper}>
      <div className={classNames("container", "fullWidth", styles.wrapper)}>
        <div className={styles.formWrapper}>
          {deletePopupOpened && (
            <Popup
              dialog
              subtitle="Do you want to delete this resource?"
              button={
                <Button buttonStyle="black" onClick={onDeleteResource}>
                  Yes
                </Button>
              }
              acceptButton={
                <Button
                  buttonStyle="outlinedBlack"
                  onClick={() => {
                    setDeletePopupOpened(false)
                  }}
                >
                  No
                </Button>
              }
              closeAction={() => {
                setDeletePopupOpened(false)
              }}
              isCrossIcon={true}
            />
          )}

          <Breadcrumbs className={styles.breadcrumbs}>
            <Link to="/company-profile" className={styles.link}>
              {companyName}
            </Link>

            {lastLocation &&
              lastLocation.pathname.includes("/company/") &&
              lastLocation.pathname.includes("/resources") && (
                <Link
                  to={`/company/${companyUuid}/resources`}
                  className={classNames(styles.link, styles.link)}
                >
                  My Resources
                </Link>
              )}

            {id ? (
              <Link
                to={"#"}
                className={classNames(styles.link, styles.currentLink)}
              >
                {`Editing ${convertCamelCaseToTitle(
                  data.resource_type || " ",
                )}`}
              </Link>
            ) : (
              <Link
                to={"#"}
                className={classNames(styles.link, styles.currentLink)}
              >
                {`Creating new ${convertCamelCaseToTitle(
                  type || data["resource_type"] || " ",
                )}`}
              </Link>
            )}
          </Breadcrumbs>

          <ResourceForm
            onSave={onSave}
            onDelete={() => setDeletePopupOpened(true)}
            resource_type={type || data["resource_type"] || " "}
            data={data}
          />
        </div>
      </div>
    </div>
  )
}

export default ResourceEditContainer
