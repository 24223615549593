import React from "react"
import features from "./features"
import b2tLogo from "./images/logo.png"
import { ReactComponent as WbencLogo } from "./images/wbencLogo.svg"
import { ReactComponent as NglccLogo } from "./images/nglccLogo.svg"
import { ReactComponent as NmsdcLogo } from "./images/nmsdcLogo.svg"
import { ReactComponent as WbencFooterLogo } from "./images/wbenc-footer-logo.svg"
import { ReactComponent as NmsdcFooterLogo } from "./images/nmsdcLogo.svg"
import { ReactComponent as NglccFooterLogo } from "./images/nglcc-footer-logo.svg"
import footerLogoB2t from "./images/icons/logo-footer.png"
import styles from "./components/Header/index.module.scss"
import footerStyles from "./components/Footer/Footer.module.scss"

const getCompanyLogo = () => {
  switch (features?.licenseName) {
    case "wbenc": {
      return (
        <div className={styles.headerLogo}>
          <WbencLogo className={styles.svgLogo} />
        </div>
      )
    }
    case "nglcc": {
      return (
        <div className={styles.headerLogo}>
          <NglccLogo />
        </div>
      )
    }
    case "nmsdc": {
      return (
        <div className={styles.headerLogo}>
          <NmsdcLogo className={styles.svgLogo} />
        </div>
      )
    }
    default: {
      return <img src={b2tLogo} alt="logo" className={styles.headerLogoImg} />
    }
  }
}

const getFooterLogo = () => {
  const content = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 10,
      }}
    >
      Powered by <img style={{ width: 39 }} src={footerLogoB2t} alt="logo" />
    </div>
  )
  switch (features?.licenseName) {
    case "wbenc": {
      return content
    }
    case "nglcc": {
      return content
    }
    case "nmsdc": {
      return content
    }
    default: {
      return <img src={footerLogoB2t} className={styles.logo} alt="logo" />
    }
  }
}

const getFooterText = () => {
  switch (features?.licenseName) {
    case "wbenc": {
      return (
        <div className={footerStyles.logoSvg}>
          <WbencFooterLogo />
        </div>
      )
    }
    case "nglcc": {
      return (
        <div className={footerStyles.logoSvg}>
          <NglccFooterLogo />
        </div>
      )
    }
    case "nmsdc": {
      return (
        <div className={footerStyles.logoSvg}>
          <NmsdcFooterLogo />
        </div>
      )
    }
    default: {
      return <span className={footerStyles.title}>{"BRIDGE2TECHNOLOGIES"}</span>
    }
  }
}

export default {
  getCompanyLogo,
  getFooterLogo,
  getFooterText,
}
