export const selectFormattedUser = (userObject) => {
  return {
    id: userObject.user.id,
    fullName: userObject.user.full_name,
    email: userObject.user.email,
    role: userObject.user.account_type,
    photo: userObject.user.photo,
    uuid: userObject.user.uuid,
    company: {
      id: userObject.user.company?.id,
      uuid: userObject.user.company?.uuid,
      name: userObject.user.company?.name,
      type: userObject.user.company?.company_type,
      photo: userObject.user.company?.photo,
    },
  }
}
