import React from "react"
import classnames from "classnames"
import { ReactComponent as EditIcon } from "../../images/icons/edit.svg"
import AccountSettingsForm from "../../forms/AccountSettingsForm"
import EmailPending from "./email-pending"
import services from "../../services"
import { toast } from "react-toastify"
import ToastInfo from "../../components/ToastContents/ToastInfo"
import styles from "./index.module.scss"
import Switch from "../../components/Switch/Switch"

const AccountSettingsContainer = ({ data, setSettings }) => {
  const [openedForm, openForm] = React.useState(null)
  const [emailPending, setEmailPendingStatus] = React.useState(
    data.email_pending,
  )
  const [resendLimitStatus, setResendLimitStatus] = React.useState(
    data.resend_limit,
  )
  const [emailNotificationStatus, setEmailNotificationStatus] = React.useState(
    data.email_notifications,
  )

  React.useEffect(() => {
    setEmailPendingStatus(data.email_pending)
    setResendLimitStatus(data.resend_limit)
    setEmailNotificationStatus(data.email_notifications);
  }, [data])

  const settings = {
    email: {
      label: "Email",
      value: data.email,
    },
    password: {
      label: "Password",
      value: "",
    },
    phone_number: {
      label: "Phone Number",
      value: data.phone_number,
    },
    email_notifications: {
      label: "Email Notifications",
      value: emailNotificationStatus,
    }
  }

  const resendEmail = () => {
    services.accountServices.resendEmail().catch(() => {
      setResendLimitStatus(true)
      showToast()
    })
  }

  const resendEmailNotificationStatus = () => {
    const updatedStatus = !emailNotificationStatus;

    services.accountServices.setAccountSettings({ email_notifications: updatedStatus })
      .then(({ data }) => {
        setEmailNotificationStatus(updatedStatus);
      })
      .catch((error) => {
        console.error("API call error:", error);
        showToast();
      });
  };

  const showToast = () => {
    toast.info(<ToastInfo>Please try again later</ToastInfo>, {
      position: toast.POSITION.TOP_RIGHT,
    })
  }

  const clearToken = () =>
    services.accountServices.clearToken().then(() => {
      setEmailPendingStatus(false)
    })

  return (
    <div className={styles.settingsWrap}>
      <h1 className={styles.settingsTitle}>Account Settings</h1>

      <div className={styles.settingsContent}>
        <div className={styles.settingsTable}>
          {Object.keys(settings).map((key) =>
            key === "email" && emailPending ? (
              <EmailPending
                key={key}
                resendEmail={resendEmail}
                clearToken={clearToken}
                resendLimitStatus={resendLimitStatus}
                newEmail={hideDetail({
                  detail: emailPending,
                  type: "email",
                })}
              />
            ) : (
              <div key={key} className={styles.settingsTableRow}>
                <div className={styles.settingsTableCol}>
                  <div className={styles.settingsLabel}>
                    {settings[key].label}
                  </div>
                </div>
                {key !== "email_notifications" && (
                  <div className={styles.settingsTableCol}>
                    {hideDetail({
                      detail: settings[key].value,
                      type: key,
                    }) || (
                      <React.Fragment>
                        {openedForm !== key && (
                          <div
                            onClick={() => openForm(key)}
                            className={classnames(
                              styles.settingsAddNew,
                              styles.settingsValue,
                            )}
                          >
                            Add +
                          </div>
                        )}
                      </React.Fragment>
                    )}

                    {openedForm === key && (
                      <AccountSettingsForm
                        data={settings}
                        type={key}
                        onConfirm={setSettings}
                        closeForm={() => openForm(null)}
                      />
                    )}
                  </div>
                )}
                {settings[key].value !== null && openedForm !== key
                  && key !== "email_notifications" && (
                  <div className={styles.settingsTableCol}>
                    <div
                      className={styles.settingsEditBtn}
                      onClick={() => {
                        if (key === "email" && resendLimitStatus) {
                          showToast()
                        } else {
                          openForm(key)
                        }
                      }}
                    >
                      <EditIcon />
                    </div>
                  </div>
                )}
                {key === "email_notifications" && (
                    <div className={styles.settingsTableCol}>
                      <div className={styles.settingsValue} >
                        <Switch
                          defaultActive={emailNotificationStatus}
                          action={resendEmailNotificationStatus}
                        />
                      </div>
                    </div>
                )}
              </div>
            ),
          )}
        </div>
      </div>
    </div>
  )
}

export default AccountSettingsContainer

const hideDetail = ({ detail, type }) => {
  let res = null

  if (detail) {
    if (type === "email") {
      const emailArray = detail.split("@")
      const partToHide = emailArray[0].slice(1, emailArray[0].length - 2)
      res = detail.replace(partToHide, "******")
    } else if (type === "phone_number") {
      const partToHide = detail.slice(1, detail.length - 2)
      res = detail.replace(partToHide, "**********")
    } else {
      res = "**********"
    }
  } else if (type === "password") {
    res = "**********"
  }

  return res && <div className={styles.settingsValue}>{res}</div>
}
