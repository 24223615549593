import React from "react"
import styles from "../Resources/Resources.module.scss"
import PageLinedTitleHome from "../../elements/PageLinedTitleHome"
import classNames from "classnames"
import QuestionTypeFilter from "../../filters/QuestionTypeFilter"
import Button from "../../Button/Button"
import { useHistory } from "react-router-dom"
import QuestionCard from "../../cards/QuestionCard"
import NoDataPlaceholder from "../../elements/NoDataPlaceholder"

const Community = ({
  communityQuestions,
  popularTopics,
  handleChangeCommunityType,
}) => {
  const history = useHistory()

  const showMore = () => history.push("/community-search")

  return (
    <div className={styles.wrapper}>
      <PageLinedTitleHome title={"Community"} subTitle={"Popular Topics"} />

      <div className={classNames("container", "fullWidth", styles.container)}>
        <QuestionTypeFilter onSelectType={handleChangeCommunityType} />

        <div className={styles.contentWrapper}>
          <div className={styles.cardsWrapper}>
            {communityQuestions?.length ? (
              communityQuestions.map((question) => (
                <div
                  key={question.id}
                  style={{ width: "100%", marginBottom: 24 }}
                >
                  <QuestionCard data={question} />
                </div>
              ))
            ) : (
              <div
                className={styles.showAllButton}
                style={{ marginBottom: 24 }}
              >
                <NoDataPlaceholder>
                  Nothing here yet. Be the first one to contribute.
                </NoDataPlaceholder>
              </div>
            )}

            <div className={styles.showAllButton}>
              <Button buttonStyle="outlined" onClick={showMore}>
                VIEW ALL
              </Button>
            </div>
          </div>

          <div className={styles.trending}>
            {popularTopics &&
              popularTopics.map((question) => (
                <div
                  key={question.id}
                  style={{ width: "100%", margin: "16px 0", textAlign: "left" }}
                >
                  <QuestionCard data={question} inSidebar />
                </div>
              ))}

            <div
              className={classNames(styles.showAll, "goldLink")}
              onClick={showMore}
            >
              show all news
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Community
