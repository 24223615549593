import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Button from "@material-ui/core/Button"
import useStyles from "./buttonStyles"

const ButtonComponent = ({
  children,
  className,
  onClick,
  disabled,
  style,
  buttonStyle,
  width,
  height,
  ...attr
}) => {
  const classes = useStyles()

  const onClickAction = (e) => {
    if (disabled) {
      e.preventDefault()
    } else {
      return onClick(e)
    }
  }
  return (
    <Button
      style={{
        width: `${width}`,
        height: `${height}`,
        fontSize: 16,
        ...style,
      }}
      variant={buttonStyle}
      color={buttonStyle === "contained" ? "secondary" : "default"}
      className={classNames(
        classes.root,
        classes.label,
        {
          [classes.containedSecondary]: buttonStyle === "contained",
          [classes.outlined]: buttonStyle === "outlined",
          [classes.outlinedBlack]: buttonStyle === "outlinedBlack",
          [classes.black]: buttonStyle === "black" && !disabled,
          [classes.blackDisabled]: buttonStyle === "black" && disabled,
          [classes.disabled]: disabled,
        },
        className,
      )}
      onClick={onClickAction}
      {...attr}
      disabled={disabled}
    >
      {children}
    </Button>
  )
}

ButtonComponent.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  buttonStyle: PropTypes.oneOf([
    "contained",
    "outlined",
    "outlinedBlack",
    "black",
  ]),
  width: PropTypes.string,
  height: PropTypes.string,
  disabled: PropTypes.bool,
}

ButtonComponent.defaultProps = {
  onClick: () => {},
  className: "",
  children: "",
  buttonStyle: "contained",
  disabled: false,
}

export default ButtonComponent
