import React from "react"
import p from "prop-types"
import styles from "./EmptyList.module.scss"

const EmptyList = ({ messageArray }) => (
  <div className={styles.wrapper}>
    {messageArray &&
      messageArray.map((text) => (
        <div key={text} className={styles.item}>
          {text}
        </div>
      ))}
  </div>
)

EmptyList.propTypes = {
  message: p.string,
}

EmptyList.defaultProps = {
  message: ["No data"],
}

export default EmptyList
