import React from "react"
import styles from "./PhotoGalleryPage.module.scss"
import GalleryContainer from "../../containers/GalleryContainer/GalleryContainer"
import { useParams, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { paths } from "../../routes/paths"
const PhotoGalleryPage = () => {
  const { id } = useParams()
  const userInfo = useSelector((state) => state.user)
  const selfGallery = !id

  if (id === userInfo.uuid) {
    return <Redirect to={paths.photoGallery("", "")} />
  }
  return (
    <div className={styles.galleryPage}>
      <GalleryContainer selfGallery={selfGallery} id={id} />
    </div>
  )
}

export default PhotoGalleryPage
