import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATES_AVATAR,
  USER_DELETES_AVATAR,
  USER_CHANGE_FULLNAME,
  UPDATE_COMPANY_AVATAR,
  DELETE_COMPANY_AVATAR,
} from "../../helpers/constants"
import services from "../../services"
import { remove, save, load } from "../../helpers/localStorage"

export const userLogin = (userInfo) => {
  return async (dispatch) => {
    dispatch({
      type: USER_LOGIN,
      payload: { ...userInfo },
    })
  }
}

export const userLogout = () => {
  return async (dispatch) => {
    services.userServices
      .signOut()
      .then(() => {
        dispatch({
          type: USER_LOGOUT,
        })
        remove("currentUser")
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const userSetsAvatar = (avatar) => {
  const currentUser = load("currentUser")
  return async (dispatch) => {
    dispatch({
      type: USER_UPDATES_AVATAR,
      payload: { avatar },
    })
    save("currentUser", { ...currentUser, photo: avatar })
  }
}

export const userDeletesAvatar = () => {
  const currentUser = load("currentUser")
  return async (dispatch) => {
    services.userServices
      .deleteProfilePhoto()
      .then(() => {
        dispatch({
          type: USER_DELETES_AVATAR,
        })
        save("currentUser", { ...currentUser, photo: null })
      })
      .catch((e) => {
        console.log(e)
      })
  }
}

export const userChangeFullName = (fullName) => {
  const currentUser = load("currentUser")
  // console.log(fullName)
  // return {
  //   type: USER_CHANGE_FULLNAME,
  //   payload: {fullName},
  // }
  return async (dispatch) => {
    dispatch({
      type: USER_CHANGE_FULLNAME,
      payload: { fullName },
    })
    save("currentUser", { ...currentUser, fullName: fullName })
  }
}

export const userChangeCompanyLogo = (photo) => async (dispatch) => {
  const currentUser = load("currentUser")
  save("currentUser", {
    ...currentUser,
    company: { ...currentUser.company, photo },
  })
  dispatch({
    type: UPDATE_COMPANY_AVATAR,
    payload: { photo },
  })
}

export const userDeletesCompanyAvatar = () => async (dispatch) => {
  const currentUser = load("currentUser")
  services.companyServices
    .deleteCompanyAvatar()
    .then(() => {
      dispatch({
        type: DELETE_COMPANY_AVATAR,
      })
      save("currentUser", {
        ...currentUser,
        company: { ...currentUser.company, photo: "" },
      })
    })
    .catch((e) => {
      console.log(e)
    })
}
