import React from "react"

import styles from "./index.module.scss"
import ResourceCard from "../cards/ResourceCard"
import NoDataPlaceholder from "../elements/NoDataPlaceholder"

const SimiliarResources = ({ data }) => {
  return (
    <div className={styles.similiarsWrap}>
      <h3 className={styles.similiarsTitle}>Similar resources</h3>

      <div>
        {data ? (
          data.map((resource) => (
            <ResourceCard key={resource.id} resource={resource} isSidebar />
          ))
        ) : (
          <NoDataPlaceholder>No similar resources</NoDataPlaceholder>
        )}
      </div>
    </div>
  )
}

export default SimiliarResources
