import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./index.module.scss"

const PageLinedTitleHome = ({ title, subTitle, ...rest }) => {
  return (
    <div className={styles.titleWrap} {...rest}>
      <div className={classNames("container", styles.container)}>
        <span className={styles.titleText}>
          <div>{title}</div>
          <div className={styles.line} />
        </span>

        <span className={styles.titleSubTitle}>
          <div style={{ whiteSpace: "pre", marginLeft: "13px" }}>
            {subTitle}
          </div>
          <div className={styles.line} />
        </span>
      </div>
    </div>
  )
}

export default PageLinedTitleHome

PageLinedTitleHome.propTypes = {
  title: PropTypes.string.isRequired,
}
