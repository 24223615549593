import React from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import classnames from "classnames"
import { IconButton } from "@material-ui/core"

import styles from "./index.module.scss"
import logo from "../../images/logo.png"
import Button from "../Button/Button"
import Nav from "./Nav"
import ProfileMenu from "./ProfileMenu"
import Panel from "./Panel"
import { ReactComponent as CloseMenu } from "../../images/icons/delete.svg"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import { paths } from "../../routes/paths"
import { GUEST, ACCOUNT_MANAGER } from "../../helpers/userRoles"
import licensedAssets from "../../licensedAccets"

const documentBody = document.querySelector("body")

const Header = () => {
  const userInfo = useSelector((state) => state.user)
  const [isProfileMenuVisible, setProfileMenuVisibility] = React.useState(false)
  const [isNavVisible, setNavVisibility] = React.useState(false)
  const { width } = useWindowHeightWidth()

  const location = useLocation()

  React.useEffect(() => {
    setNavVisibility(false)
    setProfileMenuVisibility(false)
  }, [location])

  React.useEffect(() => {
    if (width <= 1360 && isNavVisible) {
      setTimeout(() => {
        documentBody.style.position = "fixed"
      }, 350)
    } else {
      setNavVisibility(false)
      documentBody.style.position = "static"
    }
  }, [isNavVisible, width])

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.headerContent}>
          <div className={styles.headerLogo}>
            <Link to="/" className={styles.headerLogoLink}>
              {licensedAssets.getCompanyLogo()}
            </Link>
          </div>

          <div
            className={classnames(
              styles.headerNav,
              isNavVisible && styles.headerNavVisible,
            )}
          >
            <div className={styles.headerNavCloseButtonHandler}>
              <div className={styles.headerNavCloseButton}>
                <IconButton aria-label="nav">
                  <CloseMenu onClick={() => setNavVisibility(false)} />
                </IconButton>
              </div>
            </div>

            <div className={styles.headerNavScrollContent}>
              {userInfo.role !== ACCOUNT_MANAGER && (
                <Nav width={width} userRole={userInfo.role} />
              )}

              {userInfo.role !== GUEST ? (
                <ProfileMenu
                  isVisible={isProfileMenuVisible}
                  setVisibility={setProfileMenuVisibility}
                  userInfo={userInfo}
                />
              ) : (
                <div className={styles.headerNavButtonsWrap}>
                  <Button
                    to={paths.login}
                    className={styles.headerNavButton}
                    buttonStyle="outlined"
                    width="auto"
                    component={NavLink}
                  >
                    Sign In
                  </Button>

                  {/*<Button*/}
                  {/*  to={paths.signUp("", "1")}*/}
                  {/*  className={classnames(*/}
                  {/*    styles.headerNavButton,*/}
                  {/*    styles.headerNavButtonSign,*/}
                  {/*  )}*/}
                  {/*  buttonStyle="outlined"*/}
                  {/*  width="auto"*/}
                  {/*  component={NavLink}*/}
                  {/*>*/}
                  {/*  Sign Up*/}
                  {/*</Button>*/}
                </div>
              )}
            </div>
          </div>

          <Panel
            isProfileMenuVisible={isProfileMenuVisible}
            setProfileMenuVisibility={setProfileMenuVisibility}
            setNavVisibility={setNavVisibility}
            userInfo={userInfo}
          />
        </div>
      </div>
    </header>
  )
}

export default Header
