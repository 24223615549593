import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import { setCompanyCertified } from "../../useReducer/actionCreators"
import useOption from "./useOption"

const CompanyCertifiedTypeDropdown = ({ externalDispatch, selectedType }) => {
  const [option, setOption] = useOption(selectedType)
  return (
    <Dropdown
      label="Certification Status"
      id="company_certified_type_dropdown"
      options={options}
      value={option}
      onChange={(option) => {
        console.log("CompanyCertifiedTypeDropdown option:", option)
        setOption(option)
        externalDispatch(setCompanyCertified(option.id))
      }}
    />
  )
}
export default CompanyCertifiedTypeDropdown
