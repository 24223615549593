import React, { useEffect, useState } from "react"
import styles from "./DialogueCard.module.scss"
import PropTypes from "prop-types"
import MessageStatus from "./MessageStatus"
import defaultAvatar from "../../images/defaultUserAvatar.png"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const DialogueCard = ({ card, read, activeCard, handleCardClick }) => {
  const { id, url, name, subname, visitTime, msg, myMessage, msgCount } = card

  return (
    <div
      className={styles.msgCardWrap}
      style={activeCard ? { background: "#F4C00D" } : null}
      onClick={() => handleCardClick(id)}
    >
      <div className={styles.vectorWrap}>
        <div
          className={styles.userBar}
          style={{ backgroundImage: `url(${url || defaultAvatar})` }}
        />
        {/* <div style={{ width: "218px" }}> */}
        <div style={{ width: "80%" }}>
          <div className={styles.cardImg}>
            <div className={styles.nameWrapper}>
              <CustomTooltip name={name}>
                <span
                  className={subname ? styles.nameWithSubname : styles.name}
                  style={activeCard ? { color: "black" } : { color: "white" }}
                >
                  {name}
                </span>
              </CustomTooltip>
              {subname && (
                <CustomTooltip name={subname}>
                  <span
                    className={styles.subname}
                    style={activeCard ? { color: "black" } : { color: "white" }}
                  >
                    {subname}
                  </span>
                </CustomTooltip>
              )}
            </div>
            <div className={styles.visitTime}>
              <MessageStatus
                read={read}
                sent={myMessage && !read}
                activeCard={activeCard}
              />
              <p
                style={
                  activeCard
                    ? { color: "black", marginLeft: "8px" }
                    : { marginLeft: "8px" }
                }
              >
                {visitTime}
              </p>
            </div>
          </div>
          <div className={styles.msgWrap}>
            <div
              className={styles.msg}
              style={activeCard ? { color: "black" } : { color: "white" }}
            >
              {myMessage && (
                <span style={!activeCard ? { color: "#F4C00D" } : null}>
                  You:&nbsp;
                </span>
              )}
              {msg}
              {!msg && (
                <span className={styles.noMessages}>No messages yet...</span>
              )}
            </div>
            {msgCount > 0 && <div className={styles.msgCount}>{msgCount}</div>}
          </div>
        </div>
      </div>
    </div>
  )
}

DialogueCard.propTypes = {
  card: PropTypes.objectOf({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    visitTime: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    msgCount: PropTypes.number.isRequired,
  }),
  read: PropTypes.bool.isRequired,
  activeCard: PropTypes.bool.isRequired,
}

DialogueCard.defaultProps = {
  read: false,
  activeCard: false,
}

export default DialogueCard
