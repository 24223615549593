import axios from "./axios"

export default {
  passwordRetrieval: async (data) => {
    try {
      return await axios.post(`/password_retrieval`, data)
    } catch (error) {
      throw error
    }
  },
}
