import { object, string, ref, mixed } from "yup"
import { withFormik } from "formik"

import AccountSettingsFormContent from "./AccountSettingsFormContent"

const AccountSettingsForm = withFormik({
  mapPropsToValues({ data, type }) {
    return {
      email: data.email.value,
      old_password: data.password.value,
      password: "",
      confirm_password: "",
      phone_number: data.phone_number.value,
      type,
    }
  },

  validationSchema: () =>
    object().shape({
      email: mixed().when("type", {
        is: "email",
        then: string()
          .trim()
          .required("Please enter email address")
          .email(
            "Please enter your email address in format yourname@example.com",
          )
          .max(
            200,
            "Please enter a valid email address that consists of no more than 200 characters",
          ),
      }),

      phone_number: mixed().when("type", {
        is: "phone_number",
        then: string()
          .trim()
          .matches(/^[+-]?\d+$/, "Please use only digits")
          .min(
            9,
            "Please enter a correct phone number that consists of a minimum 9 digits",
          )
          .max(
            20,
            "Please enter a correct phone number that consists of a maximum 20 digits",
          ),
      }),

      old_password: mixed().when("type", {
        is: "password",
        then: string().trim().required("Please enter current password"),
      }),

      password: mixed().when("type", {
        is: "password",
        then: string()
          .trim()
          .required("Please enter a password")
          .notOneOf(
            [ref("old_password"), null],
            "Please enter a different password",
          )
          .min(
            8,
            "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
          )
          .max(
            32,
            "Please enter a valid password that consists of no more than 32 characters",
          )
          .matches(
            /(?=.*[a-z])/,
            "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
          )
          .matches(
            /(?=.*[A-Z])/,
            "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
          )
          .matches(
            /(?=.*\d)/,
            "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
          )
          .matches(
            /(?=.*\W|_)/,
            "Enter a combination of at least 8 characters, no more then 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
          ),
      }),

      confirm_password: mixed().when("type", {
        is: "password",
        then: string()
          .trim()
          .required("Please confirm your password")
          .oneOf(
            [ref("password"), null],
            "Entered password doesn`t match. Try again.",
          ),
      }),
    }),

  handleSubmit(
    values,
    { setSubmitting, setFieldError, props: { type, onConfirm, closeForm } },
  ) {
    const res = {
      [type]: values[type],
    }

    if (type === "password") {
      res.old_password = values.old_password
    }

    onConfirm(res)
      .then(() => {
        closeForm()
      })
      .catch((data) => {
        if (data.error) {
          let fieldName = type
          let errorTxt = data.error

          if (
            errorTxt === "That email address is already taken. Try another one"
          ) {
            errorTxt =
              "Please enter email address which doesn’t belong to another user."
          }

          if (
            errorTxt === "Wrong old password" ||
            errorTxt === "Wrong current password"
          ) {
            fieldName = "old_password"
            errorTxt = "Wrong current password"
          }
          if (errorTxt === "Parameter old_password cannot be blank") {
            fieldName = "old_password"
            errorTxt = "Current password cannot be blank"
          }

          setFieldError(fieldName, errorTxt)
        }
      })
      .finally(() => setSubmitting(false))
  },
})(AccountSettingsFormContent)

export default AccountSettingsForm
