import React from "react"
import pt from "prop-types"
import styles from "./MyProspectCard.module.scss"
import Label from "../../elements/Label"
import { makeCutDottedString } from "../../../helpers/textHelpers"
import ButtonComponent from "../../Button/Button"

import avatar from "../../../images/defaultUserAvatar.png"

const MyProspectCard = ({
  data: {
    created_at,
    company_uuid,
    chat,
    opportunity: {
      opportunity_type,
      uuid,
      company_photo,
      company_name,
      name,
      video,
      photo,
    },
    ...restData
  },
  onClick,
  onDecline,
  onAccept,
  onChatOpen,
}) => {
  const maxNameLength = 32
  const prospectStatus = restData.status;
  return (
    <div className={styles.cardWrapper}>
      <div onClick={onClick} className={styles.link}>
        <Label classes={styles.label} type={opportunity_type} />
        <div className={styles.thumbnail}>
          <img
            src={video?.thumbnail || photo || null}
            alt="opportunity logo"
            className={styles.thumbnailImage}
          />
        </div>

        <div className={styles.userSection}>
          <img
            src={company_photo || avatar}
            alt="company logo"
            className={styles.companyLogo}
          />
          <div style={{ width: "100%" }}>
            <span className={styles.name}>
              {makeCutDottedString(name, maxNameLength)}
            </span>
            <span className={styles.subName}>
              <div>
                <span>{makeCutDottedString(company_name, maxNameLength)}</span>
                <span>{new Date(created_at).toLocaleDateString("en-US")}</span>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        {prospectStatus === "requested" && (
          <ButtonComponent
              buttonStyle={"contained"}
              className={styles.button}
              onClick={() => onAccept(restData.uuid)}>
            Accept
          </ButtonComponent>
        )}
        {(prospectStatus === "requested" || prospectStatus === "created" || prospectStatus === "accepted")  && (
          <ButtonComponent
            buttonStyle={"outlined"}
            className={styles.button}
            onClick={() => onDecline(restData.uuid)}>
            Decline
          </ButtonComponent>
        )}
        {/*Hyde by CO-338*/}
        {/*<ButtonComponent*/}
        {/*  buttonStyle={"outlined"}*/}
        {/*  className={styles.button}*/}
        {/*  onClick={() => {*/}
        {/*    onChatOpen(chat, company_uuid, "company")*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Chat*/}
        {/*</ButtonComponent>*/}
      </div>
    </div>
  )
}

MyProspectCard.propTypes = {
  data: pt.shape({
    opportunity: {
      opportunity_type: pt.string,
      company_photo: pt.string,
      name: pt.string,
      uuid: pt.string,
      video: {
        thumbnail: pt.string,
      },
    },
  }),
  onClick: pt.func,
}

export default MyProspectCard
