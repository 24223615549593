import React, { useState } from "react"

import styles from "./index.module.scss"
import CommentForm from "../../forms/CommentForm"
import CommentWrapper from "./CommentWrapper"

const Comments = ({
  data,
  userInfo,
  onCommentPost,
  voteComment,
  handleEditComment,
  handleDeleteComment,
  commentsCount,
}) => {
  const [commentsRefs, setCommentsRefs] = useState([])
  const [displayedCommentCount, setDisplayedCommentCount] = useState(5)

  const handleShowMore = () => {
    if (displayedCommentCount + 5 > data.length) {
      setDisplayedCommentCount(data.length)
    } else {
      setDisplayedCommentCount(displayedCommentCount + 5)
    }
  }
  return (
    <div className={styles.commentsWrap}>
      <h4 className={styles.commentsTitle}>Comments ({commentsCount}):</h4>
      <CommentForm userInfo={userInfo} onCommentPost={onCommentPost} />

      {!data.length ? (
        <div className={styles.noComment}>
          <p>There are no comments yet.</p>
          <p>Be the first one to leave a comment.</p>
        </div>
      ) : (
        <div className={styles.commentsList}>
          {data
            .slice(
              data.length - displayedCommentCount >= 0
                ? data.length - displayedCommentCount
                : 0,
              data.length,
            )
            .map((comment, index) => {
              return (
                <CommentWrapper
                  key={comment.uuid}
                  handleDeleteComment={handleDeleteComment}
                  handleEditComment={handleEditComment}
                  onCommentPost={onCommentPost}
                  userInfo={userInfo}
                  ref={(ref) => {
                    commentsRefs[index] = ref
                  }}
                  body={comment.body}
                  voteComment={voteComment}
                  userLink={comment.userLink}
                  commenter={
                    comment.commenter.name || comment.commenter.full_name
                  }
                  photo={comment.commenter.photo}
                  reputation={comment.reputation}
                  userUuid={comment.commenter.uuid}
                  id={comment.id}
                  level={comment.level}
                  isMyComment={comment.isMyComment}
                  created_at={comment.created_at}
                  replies={comment.replies}
                  uuid={comment.uuid}
                />
              )
            })}
        </div>
      )}

      {data.length !== displayedCommentCount &&
        !data.length !== 0 &&
        data.length > 5 && (
          <div className={styles.commentsShowMoreWrap}>
            <div onClick={handleShowMore} className={styles.commentsShowMore}>
              Show more comments
            </div>
          </div>
        )}
    </div>
  )
}

export default Comments
