import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ModalProvider } from "./context/modalContext"
import RouteWithSubRoutes from "./helpers/RouteWithSubRoutes"
import routes from "./routes/routes"
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage"
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles"
import LeavePagePopup from "./components/LeavePagePopup/LeavePagePopup"
import { LastLocationProvider } from "react-router-last-location"

import App from "./App"
import store from "./store"
import ScrollToTop from "./components/ScrollToTop/ScrollToTop"

// for grid
const breakpointValues = {
  xs: 0,
  sm: 769,
  md: 992,
  lg: 1360,
  xl: 1680,
}
const theme = createMuiTheme({ breakpoints: { values: breakpointValues } })

ReactDOM.render(
  <Provider store={store}>
    <Router
      getUserConfirmation={(message, callback) => {
        ReactDOM.render(
          <LeavePagePopup message={message} callback={callback} />,
          document.getElementById("modal-root"),
        )
      }}
    >
      <LastLocationProvider>
        <ModalProvider>
          <ThemeProvider theme={theme}>
            <App>
              <ScrollToTop />
              <Switch>
                {routes.map((route, i) => (
                  <RouteWithSubRoutes key={i} {...route} />
                ))}
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </App>
          </ThemeProvider>
        </ModalProvider>
      </LastLocationProvider>
    </Router>
  </Provider>,
  document.getElementById("root"),
)
