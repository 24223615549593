export const checkIsFilterUsed = ({ searchParamGroup }) => {
  if (
    searchParamGroup.opportunity_type ||
    searchParamGroup.from ||
    searchParamGroup.to ||
    searchParamGroup.company ||
    searchParamGroup.locations
  ) {
    return true
  } else {
    return false
  }
}
