import { useState, useEffect } from "react"
import { objectDeepCopy } from "../../../../../helpers"
import { isHaveBadWords } from "../../../../../helpers/badWords"

const useValidation = () => {
  const [errors, setErrors] = useState({ validation: false })
  const [touched, setTouched] = useState({})

  useEffect(() => {
    let tochedLength = 0
    Object.keys(touched).forEach((el) => {
      if (el !== "null") {
        tochedLength++
      }
    })
    if (
      Object.keys(errors).length === 1 &&
      tochedLength === 6 &&
      !errors.validation
    ) {
      setErrors({ ...errors, validation: true })
    } else if (
      Object.keys(errors).length > 1 &&
      Object.keys(touched).length <= 6 &&
      errors.validation
    ) {
      setErrors({ ...errors, validation: false })
    }
  }, [touched, errors])

  const handleDeleteSchoolValidation = (index) => {
    const errorsCopy = objectDeepCopy(errors)
    const touchedCopy = objectDeepCopy(touched)

    let deleteErrorKeys = Object.keys(errorsCopy)
    deleteErrorKeys = deleteErrorKeys.filter((key) => key.includes(index))
    let deleteTouchedKeys = Object.keys(touchedCopy)
    deleteErrorKeys = deleteTouchedKeys.filter((key) => key.includes(index))

    deleteErrorKeys.forEach((key) => delete errorsCopy[key])
    deleteTouchedKeys.forEach((key) => delete touchedCopy[key])

    setErrors(errorsCopy)
    setTouched(touchedCopy)
  }

  const handeFocus = (e) => {
    const name = e.target.getAttribute("name")
    setTouched({ ...touched, [name]: true })
  }

  const handleBlur = (e, value) => {
    const name = e.target.getAttribute("name")
    handleChange(name, value || e.target.value)
  }

  const mapNewState = (values, id) => {
    let validation = false
    let touch = {
      [`name-${id}`]: true,
      [`degree-${id}`]: true,
      [`StartMonth-${id}`]: true,
      [`FinishMonth-${id}`]: true,
      [`StartYear-${id}`]: true,
      [`FinishYear-${id}`]: true,
    }

    const keys = Object.keys(values)

    keys.forEach((k) => {
      if (typeof values[k] === "object") {
        for (let i in values[k]) {
          if (Object.values(values[k][i]).length > 0) {
            validation = true
            setTouched(touch)
          } else {
            validation = false
            setTouched({})
          }
        }
      } else if (
        typeof values[k] === "string" ||
        typeof values[k] === "number"
      ) {
        if (values[k]) {
          validation = true
          setTouched(touch)
        } else {
          validation = false
          setTouched({})
        }
      }
    })
    setErrors({ validation })
  }

  const handleChange = (name, value, currentData, id) => {
    const subName = name?.substr(0, name.indexOf("-"))
    const regExp = /(\d{1,2})\/(\d{1,2})\/(\d{2,4})/

    setTouched({ ...touched, [name]: true })

    switch (subName) {
      case "StartMonth": {
        if (touched[name] && value && !value.id) {
          setErrors({
            ...errors,
            [name]: "Please select a month",
            validation: false,
          })
        } else if (
          currentData?.finish.month.id &&
          currentData?.finish.year.value &&
          currentData?.start.year.value &&
          currentData?.start.year.value === currentData?.finish.year.value
        ) {
          const endDate = `01/${currentData.finish.month.id}/${currentData.finish.year.value}`

          const startDate = `01/${value.id}/${currentData.start.year.value}`
          const isValid =
            parseInt(endDate.replace(regExp, "$3$2$1")) >=
            parseInt(startDate.replace(regExp, "$3$2$1"))

          if (!isValid) {
            setErrors({
              ...errors,
              [name]: "Please, select a correct date",
              validation: false,
            })
          } else {
            const newErrors = { ...errors }
            if (newErrors[`StartYear-${id}`]) {
              delete newErrors[`StartYear-${id}`]
            } else if (newErrors[`FinishYear-${id}`]) {
              delete newErrors[`FinishYear-${id}`]
            }
            delete newErrors[`FinishMonth-${id}`]
            delete newErrors[name]
            setErrors(newErrors)
          }
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }

        break
      }
      case "FinishMonth": {
        if (touched[name] && value && !value.id) {
          setErrors({
            ...errors,
            [name]: "Please select a month",
            validation: false,
          })
        } else if (
          currentData?.start.month.id &&
          currentData?.start.year.value &&
          currentData?.finish.year.value &&
          currentData?.start.year.value === currentData.finish.year.value
        ) {
          const endDate = `01/${value.id}/${currentData.finish.year.value}`

          const startDate = `01/${currentData.start.month.id}/${currentData.start.year.value}`
          const isValid =
            parseInt(endDate.replace(regExp, "$3$2$1")) >=
            parseInt(startDate.replace(regExp, "$3$2$1"))

          if (!isValid) {
            setErrors({
              ...errors,
              [name]: "Please, select a correct date",
              validation: false,
            })
          } else {
            const newErrors = { ...errors }
            if (newErrors[`StartYear-${id}`]) {
              delete newErrors[`StartYear-${id}`]
            } else if (newErrors[`FinishYear-${id}`]) {
              delete newErrors[`FinishYear-${id}`]
            }
            delete newErrors[`StartMonth-${id}`]
            delete newErrors[name]
            setErrors(newErrors)
          }
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }

        break
      }
      case "StartYear": {
        if (touched[name] && value && !value.value) {
          setErrors({
            ...errors,
            [name]: "Please select a year",
            validation: false,
          })
        } else if (currentData?.finish.year.value) {
          const endDate = `01/${currentData.finish.month.id || "01"}/${
            currentData.finish.year.value
          }`

          const startDate = `01/${currentData.start.month.id || "01"}/${
            value.value
          }`
          const isValid =
            parseInt(endDate.replace(regExp, "$3$2$1")) >=
            parseInt(startDate.replace(regExp, "$3$2$1"))

          if (!isValid) {
            if (value.value === currentData.finish.year.value) {
              const newErrors = { ...errors }
              delete newErrors[`FinishYear-${id}`]
              delete newErrors[name]
              newErrors[`StartMonth-${id}`] = "Please, select a correct date"
              newErrors[`FinishMonth-${id}`] = "Please, select a correct date"
              setErrors(newErrors)
            } else {
              setErrors({
                ...errors,
                [name]: "Please, select a correct date",
                validation: false,
              })
            }
          } else {
            const newErrors = { ...errors }
            if (newErrors[`StartMonth-${id}`]) {
              delete newErrors[`StartMonth-${id}`]
            } else if (newErrors[`FinishMonth-${id}`]) {
              delete newErrors[`FinishMonth-${id}`]
            }
            delete newErrors[`FinishYear-${id}`]
            delete newErrors[name]
            setErrors(newErrors)
          }
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }

        break
      }
      case "FinishYear": {
        if (touched[name] && value && !value.value) {
          setErrors({
            ...errors,
            [name]: "Please select a year",
            validation: false,
          })
        } else if (currentData?.start.year.value) {
          const endDate = `01/${currentData.finish.month.id || "01"}/${
            value.value
          }`

          const startDate = `01/${currentData.start.month.id || "01"}/${
            currentData.start.year.value
          }`
          const isValid =
            parseInt(endDate.replace(regExp, "$3$2$1")) >=
            parseInt(startDate.replace(regExp, "$3$2$1"))

          if (!isValid) {
            if (value.value === currentData.start.year.value) {
              const newErrors = { ...errors }
              delete newErrors[`StartYear-${id}`]
              delete newErrors[name]
              newErrors[`StartMonth-${id}`] = "Please, select a correct date"
              newErrors[`FinishMonth-${id}`] = "Please, select a correct date"
              setErrors(newErrors)
            } else {
              setErrors({
                ...errors,
                [name]: "Please, select a correct date",
                validation: false,
              })
            }
          } else {
            const newErrors = { ...errors }
            if (newErrors[`StartMonth-${id}`]) {
              delete newErrors[`StartMonth-${id}`]
            } else if (newErrors[`FinishMonth-${id}`]) {
              delete newErrors[`FinishMonth-${id}`]
            }
            delete newErrors[`StartYear-${id}`]
            delete newErrors[name]
            setErrors(newErrors)
          }
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }

        break
      }
      case "name": {
        if (value.trim().length === 0) {
          setErrors({
            ...errors,
            [name]: "Please enter School or University name",
          })
        } else if (value.trim().length < 2) {
          setErrors({
            ...errors,
            [name]:
              "Please enter a correct School or University name that consists of a minimum 2 characters",
            validation: false,
          })
        } else if (value.trim().length > 64) {
          setErrors({
            ...errors,
            [name]:
              "Please enter a correct School or University name that consists of no more than 64 characters",
            validation: false,
          })
        } else if (isHaveBadWords(value)) {
          setErrors({
            ...errors,
            [name]: "Please don't use bad language",
            validation: false,
          })
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }
        break
      }
      case "degree": {
        if (value.trim().length === 0 || !touched[name]) {
          setErrors({
            ...errors,
            [name]: "Please enter degree",
            validation: false,
          })
        } else if (value.trim().length < 2) {
          setErrors({
            ...errors,
            [name]:
              "Please enter a correct degree that consists of a minimum 2 characters",
            validation: false,
          })
        } else if (value.trim().length > 64) {
          setErrors({
            ...errors,
            [name]:
              "Please enter a correct degree that consists of no more than 64 characters",
            validation: false,
          })
        } else if (isHaveBadWords(value)) {
          setErrors({
            ...errors,
            [name]: "Please don't use bad language",
            validation: false,
          })
        } else {
          const newErrors = { ...errors }
          delete newErrors[name]
          setErrors(newErrors)
        }
        break
      }
    }
  }

  return {
    handleBlur,
    handeFocus,
    handleChange,
    handleDeleteSchoolValidation,
    mapNewState,
    errors,
    touched,
  }
}

export default useValidation
