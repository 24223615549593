import React from "react"
import { useParams, useHistory } from "react-router-dom"
import classnames from "classnames"

import styles from "../ChatsContainer.module.scss"
import ChatSearchFilter from "../../../components/ChatSearchFilter/ChatSearchFilter"
import DialogueCard from "../../../components/DialogueCard/DialogueCard"
import { getMessageTime } from "../../../helpers/dateHandlers"
import { paths } from "../../../routes/paths"
import { messageDecoder } from "../../../helpers/messages/messagesDecoder"
import { setSearch } from "../helpers/actionCreators"

const ChatsListWrapper = ({ state, dispatch }) => {
  const { dialogList, isChatOpenedMobile } = state
  const { uuid } = useParams()
  const history = useHistory()

  const handleCardLick = (uuid) => {
    history.push(paths.chats(uuid))
  }

  return (
    <div
      className={classnames(styles.chatsList, {
        [styles.closed]: isChatOpenedMobile,
      })}
    >
      <ChatSearchFilter
        handleSearch={(value) => {
          dispatch(setSearch(value))
        }}
      />

      {dialogList?.length > 0 &&
        dialogList.map((dialog) => (
          <DialogueCard
            key={dialog.uuid}
            card={{
              id: dialog.uuid,
              url: dialog.partner.photo,
              name: dialog.partner.name,
              subname: dialog.subject?.type === 'freelancer' ? 'about ' + dialog.subject?.name : '',
              visitTime:
                dialog.last_message.created_at &&
                getMessageTime(dialog.last_message.created_at),
              // msg: dialog.last_message.body,
              msg: messageDecoder(dialog.last_message, dialog.partner.name),
              msgCount: dialog.unread_count,
              myMessage: dialog.last_message.from_you,
            }}
            activeCard={uuid === dialog.uuid}
            read={dialog.last_message.from_you && dialog.last_message.is_readed}
            handleCardClick={handleCardLick}
          />
        ))}

      {dialogList?.length < 1 && state.search !== "" && (
        <div className={styles.noChatsFoundMessage}>
          Sorry. There no chats which match your keyword
        </div>
      )}
    </div>
  )
}

export default ChatsListWrapper
