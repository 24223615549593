import { combineReducers } from "redux"
import user from "./userReducer"
import notifications from "./notificationReducer"

const rootReducer = combineReducers({
  user,
  notifications,
})

export default rootReducer
