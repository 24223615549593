import { isHaveBadWords } from "../../helpers/badWords"

export const nameValidaton = (name) => {
  const letters = /./
  if (name.length === 0)
    return {
      valid: false,
      errorMessage: "Please enter company name",
    }
  if (name.length > 50)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct company name that consists of no more than 50 characters",
    }
  if (name.length < 2)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct company name that consists of a minimum 2 character",
    }
  if (isHaveBadWords(name)) {
    return {
      valid: false,
      errorMessage: "Please don't use bad language",
    }
  }
  if (name.trim().length === 0)
    return {
      valid: false,
      errorMessage: "Please enter a non-empty name",
    }
  return {
    valid: true,
    errorMessage: "",
  }
}

export const nameValidationEmployee = (name) => {
  const letters = /[0-9!$%^&*()_+|~={}\[\]:";<>?,.\/]/
  if (name.length === 0)
    return {
      valid: false,
      errorMessage: "Please enter full name",
    }
  if (name.length > 32)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct full name that consists of no more than 32 characters",
    }
  if (name.length < 2)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct full name that consists of a minimum 2 character",
    }
  if (name.match(letters))
    return {
      valid: false,
      errorMessage:
        "Enter a correct full name that doesn't contain any special characters or numbers",
    }
  if (isHaveBadWords(name)) {
    return {
      valid: false,
      errorMessage: "Please don't use bad language",
    }
  }
  if (name.trim().length === 0)
    return {
      valid: false,
      errorMessage: "Please enter a non-empty name",
    }
  return {
    valid: true,
    errorMessage: "",
  }
}

export const decriptionValidation = (descr) => {
  if (descr.length === 0)
    return {
      valid: false,
      errorMessage: "Please enter company description",
    }
  if (isHaveBadWords(descr)) {
    return {
      valid: false,
      errorMessage: "Please don't use bad language",
    }
  }
  if (descr.length > 1000)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct description that consists of no more than 1000 characters",
    }
  if (descr.length <= 7)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct description that consists of a minimum 8 character",
    }
  if (descr.trim().length === 0)
    return {
      valid: false,
      errorMessage: "Please enter a non-empty description",
    }
  return {
    valid: true,
    errorMessage: "",
  }
}
