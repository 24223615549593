export const departments = [
  { id: 1, value: "Finance & Accounting", name: "finance_and_accounting" },
  { id: 2, value: "Human Resources", name: "human_resources" },
  { id: 3, value: "Procurement/Purchasing", name: "procurement_or_purchasing" },
  {
    id: 4,
    value: "Business Development & Sales",
    name: "business_development_and_sales",
  },
  {
    id: 5,
    value: "Marketing & Public Relations",
    name: "marketing_and_public_relations",
  },
  {
    id: 6,
    value: "Administration & Management",
    name: "administration_and_management",
  },
  { id: 7, value: "Operations", name: "operations" },
  { id: 8, value: "Technology", name: "technology" },
  {
    id: 9,
    value: "Customer Service Support",
    name: "customer_service_support",
  },
  { id: 10, value: "Legal", name: "legal" },
  { id: 11, value: "Distribution", name: "distribution" },
  { id: 12, value: "Research & Development", name: "research_and_development" },
]
