import moment from "moment"
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE"
export const SET_WHOLE_STATE = "SET_WHOLE_STATE"
export const CHANGE_OPPORTUNITY_TYPE = "CHANGE_OPPORTUNITY_TYPE"
export const SET_FROM_DATE = "SET_FROM_DATE"
export const SET_TO_DATE = "SET_TO_DATE"
export const SET_COMPANY = "SET_COMPANY"
export const SET_LOCATION = "SET_LOCATION"
export const SET_COMPANY_INPUT = "SET_COMPANY_INPUT"
export const SET_LOCATION_INPUT = "SET_LOCATION_INPUT"
export const RESET_FORM = "RESET_FORM"

const initValues = {
  opportunityType: "",
  fromDate: null,
  toDate: null,
  company: null,
  location: "",
  companyInput: "",
  locationInput: "",
}
export const initState = {
  isCollapse: false,
  values: initValues,
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_COLLAPSE:
      return { ...state, isCollapse: !state.isCollapse }
    case SET_WHOLE_STATE: {
      const {
        searchParamGroup: {
          opportunity_type,
          from,
          to,
          company,
          company_name,
          location,
        },
      } = payload
      const values = {}
      values.opportunityType = opportunity_type ? opportunity_type : ""
      values.fromDate = from ? moment(from) : null
      values.toDate = to ? moment(to) : null
      values.company = company ? { id: company, name: company_name } : null
      values.location = location ? location : ""
      values.companyInput = company_name ? company_name : ""
      values.locationInput = location ? location : ""
      return { ...state, values }
    }
    case CHANGE_OPPORTUNITY_TYPE: {
      const { opportunityType } = payload
      return { ...state, values: { ...state.values, opportunityType } }
    }
    case SET_FROM_DATE: {
      const { fromDate } = payload
      return { ...state, values: { ...state.values, fromDate } }
    }
    case SET_TO_DATE: {
      const { toDate } = payload
      return { ...state, values: { ...state.values, toDate } }
    }
    case SET_COMPANY: {
      const { company } = payload
      return {
        ...state,
        values: { ...state.values, company, companyInput: company.name },
      }
    }
    case SET_LOCATION: {
      const { location } = payload
      return {
        ...state,
        values: { ...state.values, location, locationInput: location },
      }
    }
    case SET_COMPANY_INPUT: {
      const { companyInput } = payload
      return {
        ...state,
        values: { ...state.values, company: null, companyInput },
      }
    }
    case SET_LOCATION_INPUT: {
      const { locationInput } = payload
      return {
        ...state,
        values: { ...state.values, location: "", locationInput },
      }
    }
    case RESET_FORM:
      return { ...state, values: initValues }
    default:
      throw new Error("Unknown action")
  }
}
