import React from "react"
import Button from "../Button/Button"

const SaveCancelButtons = ({
  onCancelClick,
  style,
  handleSave,
  ableToSave,
}) => (
  <>
    <Button
      onClick={onCancelClick}
      className={style.saveButtons}
      buttonStyle="outlined"
    >
      Cancel
    </Button>
    <Button
      onClick={handleSave}
      className={style.saveButtons}
      disabled={ableToSave}
    >
      Save
    </Button>
  </>
)

export default SaveCancelButtons
