import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as ShareIcon } from "../../../images/icons/shareIcon.svg"
import styles from "./index.module.scss"

const ShareButton = ({ onClick }) => (
  <span className={styles.container} onClick={onClick}>
    <ShareIcon />
    Share
  </span>
)

ShareButton.propTypes = {
  /** Callback for onClick */
  onClick: PropTypes.func,
}
export default ShareButton
