export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "corporations",
    value: "Corporation",
  },
  {
    id: "financial_organizations",
    value: "Financial Services",
  },
  {
    id: "dc_education",
    value: "Education",
  },
  {
    id: "nonprofit",
    value: "Nonprofit",
  }
]
