import axios from "./axios"
import queryString from "query-string";

export default {
  getFreelancers: async (requestParams) => {
    const search = queryString.stringify(
        {
          ...requestParams,
        },
        { arrayFormat: "comma", skipEmptyString: true },
    )
    try {
      const result = await axios.get(`/freelancers?${search}`)

      return result
    } catch (error) {
      throw error
    }
  },

  getUuidByExternalId: async (externalId) => {
    try {
      const result = await axios.get(`/freelancer/${externalId}`)

      return result
    } catch (error) {
      throw error
    }
  },
}
