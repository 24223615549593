import React, { Fragment, useState, useEffect, useRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import PreviewGallery from "./PreviewGallery"
import FullGallery from "./FullGallery"
import UploadMediaPopup from "../Videos/UploadMediaPopup/UploadMediaPopup"
import userServices from "../../services/userServices"
import Popup from "../Popup/Popup"
import Button from "../Button/Button"
import usePhotoUpload from "./hooks/usePhotoUpload"
import { source } from "../../services/axios"
import styles from "./PhotoGallery.module.scss"
import { ReactComponent as Delete } from "../../images/icons/deleteImage.svg"
import CrossButton from "../CrossButton/CrossButton"
import { ReactComponent as Arrow } from "../../images/icons/arrow-outlined.svg"

const PhotoGalleryWrap = ({
  className,
  type,
  data,
  onAddPhotos,
  onDeletePhoto,
  editable,
  userId,
  userType,
}) => {
  const [photoId, setPhotoId] = useState(null)
  const [selectedPhoto, setSelectedPhoto] = useState({})
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0)
  const photoPopupRef = useRef(null)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [uploadPopup, setUploadPopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false)

  const deletePopupRef = useRef(null)

  const handleMouseDownOutside = (e) => {
    if (
      !deletePopupRef?.current?.contains(e.target) &&
      !photoPopupRef.current?.contains(e.target)
    ) {
      setVisiblePopup(false)
    }
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDownOutside)
    return () => window.removeEventListener("mousedown", handleMouseDownOutside)
  }, [])

  const {
    uploadedPhotos,
    loader,
    error,
    setLoader,
    setError,
    clearUploadPhotos,

    handleUploadPhoto,
    handleUpload,
    handlePopupDelete,
  } = usePhotoUpload()

  useEffect(() => {
    if (data?.length === 12) {
      setError("Sorry, you can`t add more than 12 photos.")
    }
  }, [data])

  useEffect(() => {
    if (!uploadPopup) {
      clearUploadPhotos()
    }
  }, [uploadPopup])

  const handleDelete = (id) => {
    userServices.deletePhotoGallery(id).then(() => {
      onDeletePhoto(id)
    })
  }

  const handleClick = (id) => {
    const selectedPhoto = data.find((photo) => photo.uuid === id)
    setSelectedPhoto(selectedPhoto)
    setPhotoId(selectedPhoto.uuid)
    if (data.indexOf(selectedPhoto) > -1) {
      setSelectedPhotoIndex(data.indexOf(selectedPhoto))
    }
    setVisiblePopup(true)
  }

  return (
    <Fragment>
      {type === "preview" && (
        <PreviewGallery
          className={className}
          setUploadPopup={() => {
            setUploadPopup(true)
          }}
          setDeletePopup={setDeletePopup}
          setPhotoId={setPhotoId}
          setError={setError}
          galleryData={data}
          deletePopupRef={deletePopupRef}
          editable={editable}
          userId={userId}
          userType={userType}
          onClose={() => setVisiblePopup(false)}
          onClick={handleClick}
        />
      )}
      {type === "full" && (
        <FullGallery
          className={className}
          setUploadPopup={() => {
            setUploadPopup(true)
          }}
          setDeletePopup={setDeletePopup}
          setPhotoId={setPhotoId}
          setError={setError}
          galleryData={data}
          deletePopupRef={deletePopupRef}
          editable={editable}
          onClick={handleClick}
          onClose={() => setVisiblePopup(false)}
        />
      )}
      {visiblePopup && (
        <div className={styles.photoPopupWrap}>
          <div
            className={classNames("container", styles.photoPopup)}
            ref={photoPopupRef}
          >
            <button
              className={styles.prev}
              onClick={() => {
                const prevPhoto = data.find(
                  (photo) => data.indexOf(photo) === selectedPhotoIndex - 1,
                )
                setSelectedPhotoIndex(selectedPhotoIndex - 1)
                setPhotoId(prevPhoto.uuid)
              }}
              disabled={selectedPhotoIndex === 0}
            >
              <Arrow />
            </button>
            <div
              className={styles.imagesWrapper}
              style={{
                transform: `translateX(-${
                  selectedPhotoIndex * (100 / data.length)
                }%)`,
              }}
            >
              {data.map((photo) => (
                <div className={styles.imageWrap}>
                  <div className={classNames(styles.imageInlineWrap)}>
                    <div className={styles.titleWrap}>
                      <div className={styles.photoName}>
                        <h5>
                          {photo.name.length > 25
                            ? `${photo.name.substring(0, 25)}...`
                            : photo.name}
                        </h5>
                        {editable !== false && (
                          <Delete
                            className={styles.deleteBtn}
                            onClick={setDeletePopup}
                          />
                        )}
                      </div>
                      <CrossButton
                        className={styles.closeIcon}
                        crossStyle="darkRounded"
                        onClick={() => setVisiblePopup(false)}
                      />
                    </div>
                    <img src={photo.path} alt={photo.name} />
                  </div>
                </div>
              ))}
            </div>
            <button
              className={styles.next}
              disabled={selectedPhotoIndex === data.length - 1}
              onClick={() => {
                const prevPhoto = data.find(
                  (photo) => data.indexOf(photo) === selectedPhotoIndex + 1,
                )
                setSelectedPhotoIndex(selectedPhotoIndex + 1)
                setPhotoId(prevPhoto.uuid)
              }}
            >
              <Arrow />
            </button>
          </div>
        </div>
      )}
      <UploadMediaPopup
        visible={uploadPopup}
        handleCancel={() => {
          source.cancel()
          setLoader(false)
          setUploadPopup(false)
          clearUploadPhotos()
        }}
        handleUpload={async () => {
          await handleUpload(onAddPhotos)
          setUploadPopup(false)
        }}
        uploadedMedias={uploadedPhotos}
        title="Upload Photo"
        galleryData={data}
        error={error}
        loader={loader}
        handlePopupMediaDelete={(id) => handlePopupDelete(id)}
        handleUploadMedia={(e) => {
          handleUploadPhoto(data, e)
        }}
        mediaType="image"
        acceptableFileTypes=".jpg, .jpeg, .png"
      />
      <div ref={deletePopupRef}>
        {deletePopup && (
          <Popup
            dialog
            isCrossIcon
            closeAction={() => setDeletePopup(false)}
            subtitle="Do you want to delete this photo?"
            acceptButton={
              <Button
                buttonStyle="outlinedBlack"
                onClick={() => {
                  setDeletePopup(false)
                }}
              >
                Cancel
              </Button>
            }
            button={
              <Button
                buttonStyle="black"
                onClick={() => {
                  handleDelete(photoId)
                  setDeletePopup(false)
                  setVisiblePopup(false)
                }}
              >
                Yes, sure
              </Button>
            }
          />
        )}
      </div>
    </Fragment>
  )
}

PhotoGalleryWrap.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onAddPhotos: PropTypes.func,
  onDeletePhoto: PropTypes.func,
}

export default PhotoGalleryWrap
