export const articleTypes = [
  {
    title: "Trends",
    type: "trends",
  },
  {
    title: "Skills",
    type: "skills",
  },
]
