import React from "react"
import styles from "./UserInfo.module.scss"
import classNames from "classnames"
import { ReactComponent as Pencil } from "../../images/icons/pencil.svg"
import Button from "../Button/Button"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const UserInfoShowInfo = ({
  handleEdit,
  anotherUser,
  dropdownData,
  onOpenChat,
  style,
  name,
  title,
  userType,
}) => {
  return (
    <>
      <div className={styles.userNameContainer}>
        <CustomTooltip name={name.showedName}>
          <h4
            className={classNames(
              style.userName,
              anotherUser && style.userNameWithoutIcon,
            )}
          >
            {name.showedName}
          </h4>
        </CustomTooltip>
        {!anotherUser && (
          <div
            className={classNames("iconWrap", styles.editIconWrap)}
            onClick={handleEdit}
          >
            <Pencil className="icon" />
          </div>
        )}
      </div>
      {title && (
        <div className={style.userTitle}>
          <h6 className={style.title}>{title}</h6>
        </div>
      )}

      <div>
        <div className={style.userProfession}>
            <h6 className={style.title}>{dropdownData.profession.showed.value}</h6>
        </div>
        <div className={style.userGender}>
          {dropdownData.gender.showed.databaseName !==
              "not_applicable_gender" && (
              <h6 className={style.title}>{dropdownData.gender.showed.value}</h6>
          )}
        </div>
        {anotherUser && userType !== 'freelancer' && (
          <Button
            width="100%"
            onClick={onOpenChat}
            className={styles.chatButton}
          >
            Chat
          </Button>
        )}
        {anotherUser && userType === 'freelancer' && (
          <Button
              width="100%"
              as="a"
              href={`mailto:B2TAmbassador@TheB2t.com?subject=Interested in B2T Freelancer ${name.showedName}`}
              className={styles.chatButton}
          >
            Contact Account manager
          </Button>
        )}
      </div>
    </>
  )
}

export default UserInfoShowInfo
