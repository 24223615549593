export const timeValues = [
  {
    id: 1,
    value: "12:00 AM",
  },
  {
    id: 2,
    value: "12:15 AM",
  },
  {
    id: 3,
    value: "12:30 AM",
  },
  {
    id: 4,
    value: "12:45 AM",
  },
  {
    id: 5,
    value: "1:00 AM",
  },
  {
    id: 6,
    value: "1:15 AM",
  },
  {
    id: 7,
    value: "1:30 AM",
  },
  {
    id: 8,
    value: "1:45 AM",
  },
  {
    id: 9,
    value: "2:00 AM",
  },
  {
    id: 10,
    value: "2:15 AM",
  },
  {
    id: 11,
    value: "2:30 AM",
  },
  {
    id: 12,
    value: "2:45 AM",
  },
  {
    id: 13,
    value: "3:00 AM",
  },
  {
    id: 14,
    value: "3:15 AM",
  },
  {
    id: 15,
    value: "3:30 AM",
  },
  {
    id: 16,
    value: "3:45 AM",
  },
  {
    id: 17,
    value: "4:00 AM",
  },
  {
    id: 18,
    value: "4:15 AM",
  },
  {
    id: 19,
    value: "4:30 AM",
  },
  {
    id: 20,
    value: "4:45 AM",
  },
  {
    id: 21,
    value: "5:00 AM",
  },
  {
    id: 22,
    value: "5:15 AM",
  },
  {
    id: 23,
    value: "5:30 AM",
  },
  {
    id: 24,
    value: "5:45 AM",
  },
  {
    id: 25,
    value: "6:00 AM",
  },
  {
    id: 26,
    value: "6:15 AM",
  },
  {
    id: 27,
    value: "6:30 AM",
  },
  {
    id: 28,
    value: "6:45 AM",
  },
  {
    id: 29,
    value: "7:00 AM",
  },
  {
    id: 30,
    value: "7:15 AM",
  },
  {
    id: 31,
    value: "7:30 AM",
  },
  {
    id: 32,
    value: "7:45 AM",
  },
  {
    id: 33,
    value: "8:00 AM",
  },
  {
    id: 34,
    value: "8:15 AM",
  },
  {
    id: 35,
    value: "8:30 AM",
  },
  {
    id: 36,
    value: "8:45 AM",
  },
  {
    id: 37,
    value: "9:00 AM",
  },
  {
    id: 38,
    value: "9:15 AM",
  },
  {
    id: 39,
    value: "9:30 AM",
  },
  {
    id: 40,
    value: "9:45 AM",
  },
  {
    id: 41,
    value: "10:00 AM",
  },
  {
    id: 42,
    value: "10:15 AM",
  },
  {
    id: 43,
    value: "10:30 AM",
  },
  {
    id: 44,
    value: "10:45 AM",
  },
  {
    id: 45,
    value: "11:00 AM",
  },
  {
    id: 46,
    value: "11:15 AM",
  },
  {
    id: 47,
    value: "11:30 AM",
  },
  {
    id: 48,
    value: "11:45 AM",
  },
  {
    id: 49,
    value: "12:00 PM",
  },
  {
    id: 50,
    value: "12:15 PM",
  },
  {
    id: 51,
    value: "12:30 PM",
  },

  {
    id: 52,
    value: "12:45 PM",
  },
  {
    id: 53,
    value: "1:00 PM",
  },
  {
    id: 54,
    value: "1:15 PM",
  },
  {
    id: 55,
    value: "1:30 PM",
  },
  {
    id: 56,
    value: "1:45 PM",
  },
  {
    id: 57,
    value: "2:00 PM",
  },
  {
    id: 58,
    value: "2:15 PM",
  },
  {
    id: 59,
    value: "2:30 PM",
  },
  {
    id: 60,
    value: "2:45 PM",
  },
  {
    id: 61,
    value: "3:00 PM",
  },
  {
    id: 62,
    value: "3:15 PM",
  },
  {
    id: 63,
    value: "3:30 PM",
  },
  {
    id: 64,
    value: "3:45 PM",
  },
  {
    id: 65,
    value: "4:00 PM",
  },
  {
    id: 66,
    value: "4:15 PM",
  },
  {
    id: 67,
    value: "4:30 PM",
  },
  {
    id: 68,
    value: "4:45 PM",
  },
  {
    id: 69,
    value: "5:00 PM",
  },
  {
    id: 70,
    value: "5:15 PM",
  },
  {
    id: 71,
    value: "5:30 PM",
  },
  {
    id: 72,
    value: "5:45 PM",
  },
  {
    id: 73,
    value: "6:00 PM",
  },
  {
    id: 74,
    value: "6:15 PM",
  },
  {
    id: 75,
    value: "6:30 PM",
  },
  {
    id: 76,
    value: "6:45 PM",
  },
  {
    id: 77,
    value: "7:00 PM",
  },
  {
    id: 78,
    value: "7:15 PM",
  },
  {
    id: 79,
    value: "7:30 PM",
  },
  {
    id: 80,
    value: "7:45 PM",
  },
  {
    id: 81,
    value: "8:00 PM",
  },
  {
    id: 82,
    value: "8:15 PM",
  },
  {
    id: 83,
    value: "8:30 PM",
  },
  {
    id: 84,
    value: "8:45 PM",
  },
  {
    id: 85,
    value: "9:00 PM",
  },
  {
    id: 86,
    value: "9:15 PM",
  },
  {
    id: 87,
    value: "9:30 PM",
  },
  {
    id: 88,
    value: "9:45 PM",
  },
  {
    id: 89,
    value: "10:00 PM",
  },
  {
    id: 90,
    value: "10:15 PM",
  },
  {
    id: 91,
    value: "10:30 PM",
  },
  {
    id: 92,
    value: "10:45 PM",
  },
  {
    id: 93,
    value: "11:00 PM",
  },
  {
    id: 94,
    value: "11:15 PM",
  },
  {
    id: 95,
    value: "11:30 PM",
  },
  {
    id: 96,
    value: "11:45 PM",
  },
]
