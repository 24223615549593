export const options = [
  {
    id: "",
    value: "All",
  },
  // {
  //   "id":"531770282584862721",
  //   "value":"Accounting Consulting"
  // },
  // {
  //   "id":"531770282580668416",
  //   "value":"Admin Support"
  // },
  // {
  //   "id":"531770282580668417",
  //   "value":"Customer Service"
  // },
  // {
  //   "id":"531770282580668420",
  //   "value":"Data Science Analytics"
  // },
  // {
  //   "id":"531770282580668421",
  //   "value":"Design Creative"
  // },
  // {
  //   "id":"531770282584862722",
  //   "value":"Engineering Architecture"
  // },
  // {
  //   "id":"531770282580668419",
  //   "value":"IT Networking"
  // },
  // {
  //   "id":"531770282584862723",
  //   "value":"Legal"
  // },
  // {
  //   "id":"531770282580668422",
  //   "value":"Sales Marketing"
  // },
  // {
  //   "id":"531770282584862720",
  //   "value":"Translation"
  // },
  // {
  //   "id":"531770282580668418",
  //   "value":"Web, Mobile Software Dev"
  // },
  // {
  //   "id":"531770282580668423",
  //   "value":"Writing"
  // }

  {
    id: "1017484851352698941",
    value: "2D Animation",
  },
  {
    id: "1044578476142100490",
    value: "3D Animation",
  },
  {
    id: "1110580766784868352",
    value: "3D Modeling & Rendering",
  },
  {
    id: "1110580746216001536",
    value: "A/B Testing",
  },
  {
    id: "1044578476142100498",
    value: "AR/VR Design",
  },
  {
    id: "1110580748103438336",
    value: "AR/VR Development",
  },
  {
    id: "1534904462442053632",
    value: "Academic & Research Writing",
  },
  {
    id: "1017484851352698921",
    value: "Accounting",
  },
  {
    id: "1110580746887090176",
    value: "Acting",
  },
  {
    id: "1534904462156840960",
    value: "Ad & Email Copywriting",
  },
  {
    id: "1017484851352698946",
    value: "Agile Leadership",
  },
  {
    id: "1017484851352698980",
    value: "Architectural Design",
  },
  {
    id: "1044578476142100484",
    value: "Art Direction",
  },
  {
    id: "1534904462433665024",
    value: "Article & Blog Writing",
  },
  {
    id: "1110580748338319360",
    value: "Audio Editing",
  },
  {
    id: "1017484851352698924",
    value: "Audio Production",
  },
  {
    id: "1110580748627726336",
    value: "Automation Testing",
  },
  {
    id: "1110580748673863680",
    value: "Back-End Development",
  },
  {
    id: "1110580748858413056",
    value: "Biology",
  },
  {
    id: "1517518458463281152",
    value: "Blockchain & NFT Development",
  },
  {
    id: "1110580748954882048",
    value: "Bookkeeping",
  },
  {
    id: "1044578476142100497",
    value: "Brand Identity Design",
  },
  {
    id: "1044578476142100496",
    value: "Brand Strategy",
  },
  {
    id: "1110580748795498496",
    value: "Building Information Modeling",
  },
  {
    id: "1110580749328175104",
    value: "Business & Corporate Law",
  },
  {
    id: "1017484851352698963",
    value: "Business & Proposal Writing",
  },
  {
    id: "1017484851352699002",
    value: "Business Analysis & Strategy",
  },
  {
    id: "1270387427651076096",
    value: "Business Applications Development",
  },
  {
    id: "1534904461808713728",
    value: "Business Project Management",
  },
  {
    id: "1110580749684690944",
    value: "CAD",
  },
  {
    id: "1110580750267699200",
    value: "CMS Development",
  },
  {
    id: "1110580749709856768",
    value: "Campaign Management",
  },
  {
    id: "1110580749730828288",
    value: "Career Coaching",
  },
  {
    id: "1044578476142100483",
    value: "Cartoons & Comics",
  },
  {
    id: "1017484851352698957",
    value: "Chemical & Process Engineering",
  },
  {
    id: "1110580749982486528",
    value: "Chemistry",
  },
  {
    id: "1017484851352698938",
    value: "Civil Engineering",
  },
  {
    id: "1270387428129226752",
    value: "Cloud Engineering",
  },
  {
    id: "1110580765513994240",
    value: "Coding Tutoring",
  },
  {
    id: "1110580750376751104",
    value: "Community Management",
  },
  {
    id: "1484275234401603584",
    value: "Construction & Engineering Project Management",
  },
  {
    id: "1453032887021113344",
    value: "Content Moderation",
  },
  {
    id: "1110580750791987200",
    value: "Content Strategy",
  },
  {
    id: "1110580753635725312",
    value: "Copy Editing",
  },
  {
    id: "1044578476142100492",
    value: "Creative Direction",
  },
  {
    id: "1017484851352698994",
    value: "Creative Writing",
  },
  {
    id: "1517518458454892544",
    value: "Crypto Coins & Tokens",
  },
  {
    id: "1517518458480058368",
    value: "Crypto Wallet Development",
  },
  {
    id: "1484275408234532864",
    value: "Customer Onboarding",
  },
  {
    id: "1453032784252276736",
    value: "Customer Success",
  },
  {
    id: "1110580752008335360",
    value: "Data Analytics",
  },
  {
    id: "1110580752188690432",
    value: "Data Engineering",
  },
  {
    id: "1017484851352698991",
    value: "Data Entry",
  },
  {
    id: "1110580752293548032",
    value: "Data Extraction",
  },
  {
    id: "1017484851352698997",
    value: "Data Mining",
  },
  {
    id: "1110580752452931584",
    value: "Data Processing",
  },
  {
    id: "1031626803117539328",
    value: "Data Visualization",
  },
  {
    id: "1017484851352699011",
    value: "Database Administration",
  },
  {
    id: "1110580752155136000",
    value: "Database Development",
  },
  {
    id: "1031626803146899456",
    value: "Deep Learning",
  },
  {
    id: "1110580753069494272",
    value: "Desktop Software Development",
  },
  {
    id: "1110580753140797440",
    value: "DevOps Engineering",
  },
  {
    id: "1484275220996608000",
    value: "Development & IT Project Management",
  },
  {
    id: "1017484851352698979",
    value: "Digital Project Management",
  },
  {
    id: "1549040624867348480",
    value: "Display Advertising",
  },
  {
    id: "1110580761592320000",
    value: "Dropshipping & Order Processing",
  },
  {
    id: "1017484851352698949",
    value: "Ecommerce Website Development",
  },
  {
    id: "1110580753669279744",
    value: "Editorial Design",
  },
  {
    id: "1017484851352698992",
    value: "Electrical Engineering",
  },
  {
    id: "1110580753891577856",
    value: "Electronic Engineering",
  },
  {
    id: "1110580753946103808",
    value: "Email Marketing",
  },
  {
    id: "1017484851352698983",
    value: "Email Phone & Chat Support",
  },
  {
    id: "1044578476142100518",
    value: "Emerging Tech",
  },
  {
    id: "1281256152357486592",
    value: "Energy Engineering",
  },
  {
    id: "1484275369793736704",
    value: "Executive Virtual Assistance",
  },
  {
    id: "1110580754524917760",
    value: "Experimentation & Testing",
  },
  {
    id: "1017484851352698935",
    value: "Fashion Design",
  },
  {
    id: "1110580754793353216",
    value: "Financial Analysis & Modeling",
  },
  {
    id: "1110580754822713344",
    value: "Financial Management/CFO",
  },
  {
    id: "1356688560183578624",
    value: "Fine Art",
  },
  {
    id: "1110580754940153856",
    value: "Firmware Development",
  },
  {
    id: "1110580755057594368",
    value: "Front-End Development",
  },
  {
    id: "1110580755107926016",
    value: "Full Stack Development",
  },
  {
    id: "1356688560498151424",
    value: "Game Art",
  },
  {
    id: "1110580755376361472",
    value: "General Counsel",
  },
  {
    id: "1110580761541988352",
    value: "General Research Services",
  },
  {
    id: "1017484851352699003",
    value: "General Translation Services",
  },
  {
    id: "1110580768370315264",
    value: "General Virtual Assistance",
  },
  {
    id: "1623717017731600384",
    value: "Generative AI",
  },
  {
    id: "1110580755493801984",
    value: "Ghostwriting",
  },
  {
    id: "1110580755560910848",
    value: "Grant Writing",
  },
  {
    id: "1017484851352698959",
    value: "Graphic Design",
  },
  {
    id: "1110580756089393152",
    value: "HR Administration",
  },
  {
    id: "1484275306770124800",
    value: "Healthcare Project Management",
  },
  {
    id: "1110580750410305536",
    value: "IT Compliance",
  },
  {
    id: "1313512632832798720",
    value: "IT Support",
  },
  {
    id: "1017484851352698968",
    value: "Illustration",
  },
  {
    id: "1044578476142100517",
    value: "Image Editing",
  },
  {
    id: "1017484851352698998",
    value: "Immigration Law",
  },
  {
    id: "1017484851352698966",
    value: "Information Security",
  },
  {
    id: "1031626805600567296",
    value: "Instructional Design",
  },
  {
    id: "1110580756957614080",
    value: "Intellectual Property Law",
  },
  {
    id: "1044578476142100509",
    value: "Interior Design",
  },
  {
    id: "1110580757217660928",
    value: "International Law",
  },
  {
    id: "1110580757892943872",
    value: "Jewelry Design",
  },
  {
    id: "1110580757939081216",
    value: "Knowledge Representation",
  },
  {
    id: "1110580757964247040",
    value: "Labor & Employment Law",
  },
  {
    id: "1044578476142100524",
    value: "Landscape Architecture",
  },
  {
    id: "1110580758211710976",
    value: "Language Localization",
  },
  {
    id: "1031626806594617344",
    value: "Language Tutoring",
  },
  {
    id: "1017484851352698936",
    value: "Lead Generation",
  },
  {
    id: "1110580758618558464",
    value: "Legal Document Translation",
  },
  {
    id: "1484275401007747072",
    value: "Legal Virtual Assistance",
  },
  {
    id: "1534904462467219456",
    value: "Legal Writing",
  },
  {
    id: "1534904462387527680",
    value: "Live Interpretation",
  },
  {
    id: "1356688563048288256",
    value: "Local Photography",
  },
  {
    id: "1110580758912159744",
    value: "Logistics & Supply Chain Management",
  },
  {
    id: "1356688563123785728",
    value: "Logo Design",
  },
  {
    id: "1110580759050571776",
    value: "Machine Learning",
  },
  {
    id: "1017484851352698988",
    value: "Management Consulting",
  },
  {
    id: "1313512633755545600",
    value: "Manual Testing",
  },
  {
    id: "1017484851352698990",
    value: "Market Research",
  },
  {
    id: "1017484851352698948",
    value: "Marketing Automation",
  },
  {
    id: "1534904462144258048",
    value: "Marketing Copywriting",
  },
  {
    id: "1017484851352698974",
    value: "Marketing Strategy",
  },
  {
    id: "1110580759579054080",
    value: "Mathematics",
  },
  {
    id: "1017484851352698970",
    value: "Mechanical Engineering",
  },
  {
    id: "1110580759847489536",
    value: "Medical Document Translation",
  },
  {
    id: "1484275299186823168",
    value: "Medical Virtual Assistance",
  },
  {
    id: "1534904462110703616",
    value: "Medical Writing",
  },
  {
    id: "996364627857502209",
    value: "Mobile App Development",
  },
  {
    id: "1044578476142100508",
    value: "Mobile Design",
  },
  {
    id: "996364627857502208",
    value: "Mobile Game Development",
  },
  {
    id: "1044578476142100506",
    value: "Motion Graphics",
  },
  {
    id: "1017484851352698971",
    value: "Music Performance",
  },
  {
    id: "1044578476142100505",
    value: "Music Production",
  },
  {
    id: "1517518458446503936",
    value: "NFT Art",
  },
  {
    id: "1110580761214832640",
    value: "Network Administration",
  },
  {
    id: "1031626808452694016",
    value: "Network Security",
  },
  {
    id: "1517518458471669760",
    value: "Other Blockchain NFT & Cryptocurrency",
  },
  {
    id: "1484275343281541120",
    value: "Other Community Management & Tagging",
  },
  {
    id: "1110580750812958720",
    value: "Other Content Writing",
  },
  {
    id: "1453032787792269312",
    value: "Other Customer Service & Tech Support",
  },
  {
    id: "1484275274419458048",
    value: "Other Data Entry & Transcription Services",
  },
  {
    id: "1110580753207906304",
    value: "Other Digital Marketing",
  },
  {
    id: "1534904462450442240",
    value: "Other Editing & Proofreading Services",
  },
  {
    id: "1534904462391721984",
    value: "Other Language Tutoring & Interpretation",
  },
  {
    id: "1549040624921874432",
    value: "Other Lead Generation & Telemarketing",
  },
  {
    id: "1484275076360228864",
    value: "Other Market Research & Product Reviews",
  },
  {
    id: "1549040624909291520",
    value: "Other Marketing PR & Brand Strategy",
  },
  {
    id: "1534904462140063744",
    value: "Other Professional & Business Writing",
  },
  {
    id: "1484275392803688448",
    value: "Other Project Management",
  },
  {
    id: "1062711146730590208",
    value: "Other Sales & Marketing Copywriting",
  },
  {
    id: "1534904462408499200",
    value: "Other Translation & Localization Services",
  },
  {
    id: "1484275136116477952",
    value: "Other Virtual Assistance",
  },
  {
    id: "1031626808918261760",
    value: "Packaging Design",
  },
  {
    id: "1110580762229854208",
    value: "Paralegal Services",
  },
  {
    id: "1356688565199966208",
    value: "Pattern Design",
  },
  {
    id: "1313512633344503808",
    value: "Personal Coaching",
  },
  {
    id: "1484275250881024000",
    value: "Personal Virtual Assistance",
  },
  {
    id: "1110580762867388416",
    value: "Physics",
  },
  {
    id: "1356688556572282880",
    value: "Portraits & Caricatures",
  },
  {
    id: "1017484851352698958",
    value: "Presentation Design",
  },
  {
    id: "1110580763349733376",
    value: "Product & Industrial Design",
  },
  {
    id: "1356688566152073216",
    value: "Product Photography",
  },
  {
    id: "1484275345210920960",
    value: "Product Reviews",
  },
  {
    id: "1534904462421082112",
    value: "Proofreading",
  },
  {
    id: "1044578476142100527",
    value: "Prototyping",
  },
  {
    id: "1017484851352698961",
    value: "Public Relations",
  },
  {
    id: "1484275336293830656",
    value: "Qualitative Research",
  },
  {
    id: "1484275349048709120",
    value: "Quantitative Research",
  },
  {
    id: "1031626810218496000",
    value: "Recruiting & Talent Sourcing",
  },
  {
    id: "1110580764293451776",
    value: "Regulatory Law",
  },
  {
    id: "1534904462106509312",
    value: "Resume & Cover Letter Writing",
  },
  {
    id: "1017484851352698933",
    value: "SEO",
  },
  {
    id: "1281256160058228736",
    value: "STEM Tutoring",
  },
  {
    id: "1110580749500141568",
    value: "Sales & Business Development",
  },
  {
    id: "1534904462190395392",
    value: "Sales Copywriting",
  },
  {
    id: "1110580764771602432",
    value: "Scripting & Automation",
  },
  {
    id: "1044578476142100528",
    value: "Scriptwriting",
  },
  {
    id: "1110580764859682816",
    value: "Scrum Leadership",
  },
  {
    id: "1017484851352699006",
    value: "Search Engine Marketing",
  },
  {
    id: "1110580765056815104",
    value: "Securities & Finance Law",
  },
  {
    id: "1534904462395916288",
    value: "Sign Language Interpretation",
  },
  {
    id: "1044578476142100539",
    value: "Singing",
  },
  {
    id: "1017484851352698999",
    value: "Social Media Marketing",
  },
  {
    id: "1044578476142100543",
    value: "Social Media Strategy",
  },
  {
    id: "1110580765614657536",
    value: "Solution Architecture",
  },
  {
    id: "1045317722194345984",
    value: "Songwriting & Music Composition",
  },
  {
    id: "1110580765719515136",
    value: "Sourcing & Procurement",
  },
  {
    id: "1031626811304820736",
    value: "Structural Engineering",
  },
  {
    id: "1534904461850656768",
    value: "Supply Chain & Logistics Project Management",
  },
  {
    id: "1017484851352698927",
    value: "Systems Administration",
  },
  {
    id: "1031626811434844160",
    value: "Systems Engineering",
  },
  {
    id: "1110580766155722752",
    value: "Tax Law",
  },
  {
    id: "1110580766206054400",
    value: "Tax Preparation",
  },
  {
    id: "1110580766470295552",
    value: "Tech Support",
  },
  {
    id: "1110580766327689216",
    value: "Technical Document Translation",
  },
  {
    id: "1017484851352698996",
    value: "Technical Writing",
  },
  {
    id: "1110580766558375936",
    value: "Telemarketing",
  },
  {
    id: "1044578476142100519",
    value: "Trade Show Design",
  },
  {
    id: "1110580767023943680",
    value: "Training & Development",
  },
  {
    id: "1110580767091052544",
    value: "Transcription",
  },
  {
    id: "1044578476142100545",
    value: "UX/UI Design",
  },
  {
    id: "1044578476142100540",
    value: "Video Editing",
  },
  {
    id: "1110580755233755136",
    value: "Video Game Development",
  },
  {
    id: "1017484851352698982",
    value: "Video Production",
  },
  {
    id: "1044578476142100538",
    value: "Videography",
  },
  {
    id: "1356688570329600000",
    value: "Visual Effects",
  },
  {
    id: "1484275127883059200",
    value: "Visual Tagging & Processing",
  },
  {
    id: "1017484851352698939",
    value: "Voice Talent",
  },
  {
    id: "1044578476142100493",
    value: "Web & Software Product Research",
  },
  {
    id: "1534904462404304896",
    value: "Web & UX Writing",
  },
  {
    id: "1044578476142100494",
    value: "Web Design",
  },
  {
    id: "1110580769746046976",
    value: "Writing Tutoring",
  },
]
