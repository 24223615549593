export const PaymentLinks = [
  {
    cost: "$25",
    resource_type: "meetup",
    link: "https://buy.stripe.com/6oE16ffEfar980w3dY",
  },
  {
    cost: "$50",
    resource_type: "meetup",
    link: "https://buy.stripe.com/bIY9CL9fRczh1C87uf",
  },  {
    cost: "$75",
    resource_type: "meetup",
    link: "https://buy.stripe.com/fZeaGPeAb9n54Ok8yk",
  },
  {
    cost: "$100",
    resource_type: "meetup",
    link: "https://buy.stripe.com/aEU8yH9fR2YH4Ok7uh",
  },
  {
    cost: "$25",
    resource_type: "advice",
    link: "https://buy.stripe.com/7sI5mv63Fbvd2GcaGu",
  },
  {
    cost: "$50",
    resource_type: "advice",
    link: "https://buy.stripe.com/7sI8yH1NpgPxcgMdSH",
  },  {
    cost: "$75",
    resource_type: "advice",
    link: "https://buy.stripe.com/fZe6qzdw7dDla8EdSI",
  },
  {
    cost: "$100",
    resource_type: "advice",
    link: "https://buy.stripe.com/9AQaGP3Vx9n580waGx",
  },
  {
    cost: "$25",
    resource_type: "funding",
    link: "https://buy.stripe.com/aEU9CLdw7ar94Ok6qi",
  },
  {
    cost: "$50",
    resource_type: "funding",
    link: "https://buy.stripe.com/eVadT1gIj0QzdkQcOH",
  },  {
    cost: "$75",
    resource_type: "funding",
    link: "https://buy.stripe.com/aEU2ajgIjeHpfsYaGA",
  },
  {
    cost: "$100",
    resource_type: "funding",
    link: "https://buy.stripe.com/9AQg19fEf6aT2Gc01X",
  },
  {
    cost: "$25",
    resource_type: "etiquette",
    link: "https://buy.stripe.com/14k4ircs356Pa8E9Cy",
  },
  {
    cost: "$50",
    resource_type: "etiquette",
    link: "https://buy.stripe.com/cN26qzbnZ2YH3KgeWT",
  },  {
    cost: "$75",
    resource_type: "etiquette",
    link: "https://buy.stripe.com/14kaGP8bNeHpbcI9CA",
  },
  {
    cost: "$100",
    resource_type: "etiquette",
    link: "https://buy.stripe.com/00gdT1dw71UD1C8021",
  },
  {
    cost: "$25",
    resource_type: "unspoken_rules",
    link: "https://buy.stripe.com/6oE6qz63Fczh6Ws6qq",
  },
  {
    cost: "$50",
    resource_type: "unspoken_rules",
    link: "https://buy.stripe.com/eVadT1ajV56P2Gcg11",
  },  {
    cost: "$75",
    resource_type: "unspoken_rules",
    link: "https://buy.stripe.com/cN2dT19fRbvd4Ok6qs",
  },
  {
    cost: "$100",
    resource_type: "unspoken_rules",
    link: "https://buy.stripe.com/dR66qz2RtdDl1C8025",
  },
]