import {
  TOGGLE_COLLAPSE,
  OPEN_INDIVIDUAL_SUBFORM,
  OPEN_COMPANY_SUBFORM,
  SET_WHOLE_STATE,
  SET_PROFESSION_TYPE,
  SET_INDUSTRY_TYPE,
  SET_MARKETS,
  SET_EXPERIENCE_LEVEL,
  SET_JOB_TITLE,
  SET_JOB_TITLE_INPUT,
  SET_LOCATION,
  SET_LOCATION_INPUT,
  SET_SKILL,
  SET_SKILL_INPUT,
  SET_ROLE_TYPE,
  SET_ACTIVITY_TYPE,
  SET_GENDER_TYPE,
  SET_RACE_TYPE,
  SET_COMPANY_TYPE,
  SET_LEVEL_TYPE,
  SET_COMPANY_ROLE_TYPE,
  SET_COMPANY_ACTIVITY_TYPE,
  SET_COMPANY_INDUSTRY_TYPE,
  SET_COMPANY_DEPARTMENT_TYPE,
  SET_COMPANY_DIVERSE_TYPE,
  SET_COMPANY_CLASSIFICATION,
  SET_COMPANY_MARKET,
  RESET_FORM,
  OPEN_FREELANCERS_SUBFORM,
  SET_CATEGORY_TYPE,
  SET_FREELANCERS_COUNTRY,
  SET_COMPANY_CERTIFIED
} from "./reducer"

export const toggleCollapse = () => ({
  type: TOGGLE_COLLAPSE,
  payload: null,
})
export const openIndividualSubForm = () => ({
  type: OPEN_INDIVIDUAL_SUBFORM,
  payload: null,
})
export const openFreelancersSubForm = () => ({
  type: OPEN_FREELANCERS_SUBFORM,
  payload: null,
})
export const openCompanySubForm = () => ({
  type: OPEN_COMPANY_SUBFORM,
  payload: null,
})
export const setWholeState = (requestResultGroup) => ({
  type: SET_WHOLE_STATE,
  payload: { requestResultGroup },
})
export const setProfessionType = (professionType) => ({
  type: SET_PROFESSION_TYPE,
  payload: { professionType },
})
export const setIndustryType = (industryType) => ({
  type: SET_INDUSTRY_TYPE,
  payload: { industryType },
})
export const setMarket = (markets) => ({
  type: SET_MARKETS,
  payload: { markets },
})
export const setExperienceLevel = (experienceLevel) => ({
  type: SET_EXPERIENCE_LEVEL,
  payload: { experienceLevel },
})
export const setJobTitle = (jobTitle) => ({
  type: SET_JOB_TITLE,
  payload: { jobTitle },
})
export const setJobTitleInput = (jobTitleInput) => ({
  type: SET_JOB_TITLE_INPUT,
  payload: { jobTitleInput },
})
export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: { location },
})
export const setLocationInput = (locationInput) => ({
  type: SET_LOCATION_INPUT,
  payload: { locationInput },
})
export const setSkill = (skill) => ({
  type: SET_SKILL,
  payload: { skill },
})
export const setSkillInput = (skillInput) => ({
  type: SET_SKILL_INPUT,
  payload: { skillInput },
})
export const setRoleType = (roleType) => ({
  type: SET_ROLE_TYPE,
  payload: { roleType },
})
export const setActivityType = (activityType) => ({
  type: SET_ACTIVITY_TYPE,
  payload: { activityType },
})
export const setGenderType = (genderType) => ({
  type: SET_GENDER_TYPE,
  payload: { genderType },
})
export const setRaceType = (raceType) => ({
  type: SET_RACE_TYPE,
  payload: { raceType },
})
export const setCategoryType = (categoryType) => ({
  type: SET_CATEGORY_TYPE,
  payload: { categoryType },
})
export const setFreelancersCountry = (freelancersCountry) => ({
  type: SET_FREELANCERS_COUNTRY,
  payload: { freelancersCountry },
})
export const setCompanyType = (companyType) => ({
  type: SET_COMPANY_TYPE,
  payload: { companyType },
})
export const setLevelType = (levelType) => ({
  type: SET_LEVEL_TYPE,
  payload: { levelType },
})
export const setCompanyRoleType = (companyRoleType) => ({
  type: SET_COMPANY_ROLE_TYPE,
  payload: { companyRoleType },
})
export const setCompanyActivityType = (companyActivityType) => ({
  type: SET_COMPANY_ACTIVITY_TYPE,
  payload: { companyActivityType },
})
export const setCompanyIndustryType = (companyIndustryType) => ({
  type: SET_COMPANY_INDUSTRY_TYPE,
  payload: { companyIndustryType },
})
export const setCompanyDepartmentType = (companyDepartmentType) => ({
  type: SET_COMPANY_DEPARTMENT_TYPE,
  payload: { companyDepartmentType },
})
export const setCompanyDiverseType = (companyDiverseType) => ({
  type: SET_COMPANY_DIVERSE_TYPE,
  payload: { companyDiverseType },
})
export const setCompanyClassification = (companyClassification) => ({
  type: SET_COMPANY_CLASSIFICATION,
  payload: { companyClassification },
})
export const setCompanyCertified = (companyCertified) => ({
  type: SET_COMPANY_CERTIFIED,
  payload: { companyCertified },
})
export const setCompanyMarket = (companyMarket) => ({
  type: SET_COMPANY_MARKET,
  payload: { companyMarket },
})
export const resetForm = () => ({
  type: RESET_FORM,
  payload: null,
})
