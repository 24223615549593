import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"

const XYCenterAlignedWindow = ({ title, fullWidth, children }) => {
  return (
    <div className={styles.windowWrap}>
      <div
        className={classnames(
          styles.windowContent,
          fullWidth && styles.fullWidth,
        )}
      >
        {title && <div className={styles.windowHeader}>{title}</div>}
        <div className={styles.windowBody}>{children}</div>
      </div>
    </div>
  )
}

XYCenterAlignedWindow.defaultProps = {
  fullWidth: false,
}

XYCenterAlignedWindow.propTypes = {
  title: PropTypes.string,
  fullWidth: PropTypes.bool,
}

export default XYCenterAlignedWindow
