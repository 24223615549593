import React from "react"
import classNames from "classnames"
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { tooltips } from "./tooltips"
import styles from "./index.module.scss"

const titleGroup = {
  careers_executive: "executive",
  mentoring: "mentoring",
  funding: "funding",
  project: "project",
  sponsorship: "sponsorship prospects",
  diverse_company: "diverse company",
  board_of_directors: "board of directors",
  advice: "advice",
  etiquette: "etiquette",
  meetup: "meetup",
  unspoken_rules: "unspoken rules",
  business_news: "business news",
  cryptocurrency: "cryptocurrency",
  cybersecurity: "cybersecurity",
  energy: "energy",
  financial_services: "financial services",
  healthcare: "healthcare",
  industrial: "industrial",
  publications: "publications",
  technology: "technology",
  telecom: "telecom",
  trends: "trends",
  skills: "skills",
  the_watch_list: "the watch list",
  peer_circles: "peer circles",
  group_chats: "group chats",
  referrals: "referrals",
  the_vent: "the vent",
  network: "network",
  careers_professional: "professional",
}
const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: "rgba(0, 0, 0, 0.9)",
  },
  tooltip: {
    maxWidth: 200,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 10,
    lineHeight: 1.6,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    cursor: "default",
  },
}))
export type LabelProps = {
  /** External styles */
  classes?: any
  subject?: "opportunity" | "resource" | "article" | "question"
  type:
    | "careers_executive"
    | "mentoring"
    | "funding"
    | "project"
    | "sponsorship"
    | "diverse_company"
    | "board_of_directors"
    | "advice"
    | "etiquette"
    | "meetup"
    | "unspoken_rules"
    | "business_news"
    | "cryptocurrency"
    | "cybersecurity"
    | "energy"
    | "financial_services"
    | "healthcare"
    | "industrial"
    | "publications"
    | "technology"
    | "telecom"
    | "trends"
    | "skills"
    | "the_watch_list"
    | "peer_circles"
    | "group_chats"
    | "referrals"
    | "the_vent"
    | "network"
    | "careers_professional"
}
const Label = ({ classes, subject = "opportunity", type }: LabelProps) => {
  const tooltipClasses = useTooltipStyles()
  if (titleGroup[type]) {
    return (
      <Tooltip
        classes={tooltipClasses}
        title={tooltips[type] || ""}
        placement="bottom"
        arrow
      >
        <span
          className={classNames(
            styles.label,
            styles[subject],
            styles[type],
            classes,
          )}
        >
          {titleGroup[type]}
        </span>
      </Tooltip>
    )
  } else {
    return null
  }
}
export default Label
