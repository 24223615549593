import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./Switch.module.scss";

const Switch = ({ action, switchStyle, defaultActive }) => {
  const [active, isActive] = useState(defaultActive);

  useEffect(() => {
    isActive(defaultActive);
  }, [defaultActive]);

  const handleChange = () => {
    isActive(!active);
    action();
  };

  const classes = classNames(styles.switch, {
    [styles.smallSwitch]: switchStyle === "small",
    [styles.bigSwitch]: switchStyle === "big",
  });

  return (
    <label className={classes}>
      <input type="checkbox" checked={active} onChange={handleChange} />
      <span className={styles.slider} />
    </label>
  );
};

Switch.propTypes = {
  action: PropTypes.func,
  switchStyle: PropTypes.string,
  defaultActive: PropTypes.bool,
};

Switch.defaultProps = {
  action: () => {},
  switchStyle: "big",
  defaultActive: false,
};

export default Switch;
