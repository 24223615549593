import axios from "./axios"
import queryString from "query-string"

export default {
  getMyQuestions: async (params) => {
    try {
      return await axios.get(`/my_questions`, { params })
    } catch (error) {
      throw error
    }
  },

  getCompanyQuestions: async (uuid, params) => {
    try {
      return await axios.get(`/companies/${uuid}/questions`, { params })
    } catch (error) {
      throw error
    }
  },

  getQuestion: async (uuid) => {
    try {
      return await axios.get(`/questions/${uuid}`)
    } catch (error) {
      throw error
    }
  },
  voteForComment: async ({ uuid, vote }) => {
    try {
      return await axios.post(`/comments/${uuid}/vote`, { vote })
    } catch (error) {
      throw error
    }
  },
  voteForQuestion: async ({ uuid, vote }) => {
    try {
      return await axios.post(`/questions/${uuid}/vote`, { vote })
    } catch (error) {
      throw error
    }
  },

  deleteCommunityTopicByUuid: async (uuid) => {
    try {
      return await axios.delete(`/questions/${uuid}`)
    } catch (error) {
      throw error
    }
  },
  acceptCommunityTopic: async (uuid) => {
    try {
      return await axios.post(`/questions/${uuid}/accept`)
    } catch (error) {
      throw error
    }
  },

  createCommunityTopic: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        console.log(item, value)
        console.log(typeof value)
        console.log("-----------------")
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (typeof value === "object") {
          if (item === "emails") {
            formattedData[item] = JSON.stringify(value)
          } else if (item === "companies") {
            formattedData["companies"] = JSON.stringify(value)
          } else {
            for (let key in value) {
              let itemName = `${item}_${key}`
              let itemValue = value[key]

              if (itemName === "photo_path") {
                formattedData["photo"] = formattedData[item]?.file
              } else if (itemName === "video_path") {
                formattedData["video_file"] = formattedData[item].file.file
              } else if (
                itemName === "video_file" ||
                itemName === "photo_file"
              ) {
              } else {
                formattedData[itemName] = itemValue
              }
            }
          }
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      delete formattedData["tell_your_story_obj"]
      delete formattedData["reputation"]
      delete formattedData["user_id"]
      delete formattedData["user_name"]
      delete formattedData["user_photo"]
      delete formattedData["id"]
      delete formattedData["uuid"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          Array.isArray(value) ||
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo"
        )
          form.append(item, value)
      })
      return await axios.post(`/questions`, form)
    } catch (error) {
      throw error
    }
  },

  updateCommunityTopic: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (typeof value === "object") {
          if (item === "emails") {
            formattedData[item] = JSON.stringify(value)
          } else if (item === "companies") {
            formattedData["companies"] = JSON.stringify(value)
          } else {
            for (let key in value) {
              let itemName = `${item}_${key}`
              let itemValue = value[key]

              if (itemName === "photo_path") {
                formattedData["photo"] = formattedData[item]?.file
              } else if (itemName === "video_path") {
                formattedData["video_file"] = formattedData[item].file.file
              } else if (
                itemName === "video_file" ||
                itemName === "photo_file"
              ) {
              } else {
                formattedData[itemName] = itemValue
              }
            }
          }
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      delete formattedData["tell_your_story_obj"]
      delete formattedData["reputation"]
      delete formattedData["user_id"]
      delete formattedData["user_name"]
      delete formattedData["user_photo"]
      delete formattedData["id"]
      delete formattedData["uuid"]
      delete formattedData["created_at"]
      delete formattedData["photo_size"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo"
        ) {
          form.append(item, value)
        }
      })
      return await axios.put(`/questions/${data.uuid}`, form)
    } catch (error) {
      throw error
    }
  },
  getMainQuestions: async (type, limit, userId) => {
    const search = queryString.stringify({
      question_type: type,
      limit: limit || null,
      uuid: userId,
    })
    try {
      return await axios.get(`/main_questions?${search}`)
    } catch (error) {
      throw error
    }
  },
  getTopTalent: async (limit) => {
    try {
      return await axios.get(`/top_talent?limit=${limit}`)
    } catch (error) {
      throw error
    }
  },
}
