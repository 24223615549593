import React, { useEffect, useState } from "react"
import { Prompt } from "react-router"
import PropTypes from "prop-types"

import styles from "./CompanyUserInfo.module.scss"
import UserImage from "../UserImage/UserImage"
import Input from "../Input/Input"

import { industryOptions } from "./options/industryOptions"
import { roleOptions } from "./options/roleOptions"
import {
  decriptionValidation,
  nameValidationEmployee,
  nameValidaton,
} from "./validation"
import { objectDeepCopy } from "../../helpers"
import CompanyEmployShowInfo from "./CompanyEmployShowInfo"
import ConfirmCancelPopup from "../Popup/ConfirmCancelPopup"

import SaveCancelButtons from "./SaveCancelButtons"
import { messages } from "../../helpers/constants"
import classnames from "classnames"
import CopyToClipboard from "../CopyToClipboard"
import CustomTooltip from "./CustomTooltip"

const CompanyEmployUserInfo = ({
  image,
  openPhotoPopup,
  anotherUser,
  name,
  onDeletePhotoProfile,
  activity,
  describeMe,
  descriptionProp,
  updateCompanyUser,
  companyType,
  onOpenChat,
  isHaveChatButton,
  employName,
  industr,
  isEmployee,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [description, setDescription] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [fullName, setFullname] = useState("")
  const [errors, setErrors] = useState({})
  const [dropdownData, setDropdownData] = useState({
    activity: {},
    describeMe: {},
    industry: {},
  })

  const companyTypeRoleOptions = roleOptions.filter(
    (option) => option.companyType === companyType,
  )

  const descr =
    companyTypeRoleOptions.find((item) => item.databaseName === describeMe) ||
    ""
  const act = descr
    ? descr.subValue.find((item) => item.databaseName === activity)
    : ""
  const industry = industr
    ? industryOptions.find((item) => item.databaseName === industr)
    : ""
  useEffect(() => {
    if (descr || activity || industr) {
      setDropdownData({
        ...dropdownData,
        industry: industry,
        describeMe: descr,
        activity: act,
      })
    }
    if (descriptionProp) {
      setDescription(descriptionProp)
    }
    if (name) {
      setCompanyName(name)
    }
    if (employName) {
      setFullname(employName)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activity, descriptionProp, name, employName, industr])

  const checkToSave = () => {
    return (
      description.length === 0 ||
      Object.values(errors).some((item) => item.valid === false)
    )
  }

  const handleCancel = () => {
    const data = objectDeepCopy(dropdownData)
    setIsEditing(false)
    setHasChanges(false)
    setIsPopupOpened(false)
    setDropdownData({
      ...data,
      describeMe: descr || "",
      activity: act || "",
    })
    setDescription(descriptionProp || "")
    setCompanyName(name)
    setErrors({})
  }

  const onCancelClick = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setIsEditing(false)
      setErrors({})
    }
  }

  const handleSave = async () => {
    await updateCompanyUser({
      full_name: fullName,
    })
    setIsEditing(false)
    setHasChanges(false)
  }

  const handleChangeInput = (e) => {
    setHasChanges(true)
    setFullname(e.target.value)
    setErrors({
      ...errors,
      companyName: nameValidationEmployee(e.target.value),
    })
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  return (
    <div className={styles.userInfoContainer}>
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />

      {isPopupOpened && hasChanges && (
        <ConfirmCancelPopup
          handleCancel={handleCancel}
          setIsPopupOpened={setIsPopupOpened}
        />
      )}

      <div className={styles.imageContainer}>
        <UserImage
          image={image}
          onUpdate={openPhotoPopup}
          selectedUser={anotherUser}
          onDelete={onDeletePhotoProfile}
          isEmployee
        />
      </div>

      {isEditing ? (
        <div className={styles.inputsWrapper}>
          <Input
            name="fullName"
            error={errors.companyName && !errors.companyName.valid}
            errorMessage={errors.companyName && errors.companyName.errorMessage}
            value={fullName}
            onChange={handleChangeInput}
            fieldStyle="black"
            label="Full Name"
            className={styles.editItem}
          />
          <div className={styles.buttonsWrapper}>
            <SaveCancelButtons
              style={styles}
              ableToSave={checkToSave()}
              handleSave={handleSave}
              onCancelClick={onCancelClick}
            />
          </div>
          <div className={styles.userNameContainer}>
            <CustomTooltip name={name}>
              <h4 className={styles.userNameEmploy}>{name}</h4>
            </CustomTooltip>
          </div>
          <div className={styles.userEducation}>
            <h6 className={styles.title}>{dropdownData.describeMe.value}</h6>
            <h6 className={styles.title}>
              {dropdownData.activity ? dropdownData.activity.value : ""}
            </h6>
            <div className={styles.userDescription}>
              <h6 className={styles.title}>Company description:</h6>
              <p className={classnames("note14r", styles.subTitle)}>
                {description ? (
                  <CopyToClipboard text={description} />
                ) : (
                  <span>Add your description</span>
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <CompanyEmployShowInfo
          employName={employName}
          name={companyName}
          anotherUser={anotherUser}
          description={description}
          style={styles}
          dropdownData={dropdownData}
          handleEdit={handleEdit}
          onOpenChat={onOpenChat}
          isHaveChatButton={isHaveChatButton}
        />
      )}
    </div>
  )
}

CompanyEmployUserInfo.defaultProps = {
  name: "Company Name",
  activity: "",
  describeMe: "",
}

CompanyEmployUserInfo.propTypes = {
  image: PropTypes.string,
  openPhotoPopup: PropTypes.func,
  anotherUser: PropTypes.bool,
  name: PropTypes.string,
  onDeletePhotoProfile: PropTypes.func,
  activity: PropTypes.string,
  describeMe: PropTypes.string,
  descriptionProp: PropTypes.string,
  updateCompanyUser: PropTypes.func,
  companyType: PropTypes.string,
}

export default CompanyEmployUserInfo
