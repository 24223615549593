import React, { useState, useEffect } from "react";
import Button from "../Button/Button";

import Popup from "../Popup/Popup";
import styles from "./LeavePagePopup.module.scss";

const LeavePagePopup = ({ message, callback }) => {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(false);
  }, [message, callback]);

  return (
    <>
      {!isVisible && (
        <Popup
          dialog
          isCrossIcon
          closeAction={() => {
            callback(false);
            setVisible(true);
          }}
          subtitle={message}
          acceptButton={
            <Button
              buttonStyle="default"
              className={styles.cancelButtonPopUp}
              onClick={() => {
                callback(false);
                setVisible(true);
              }}
            >
              No
            </Button>
          }
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                callback(true);
                setVisible(true);
              }}
            >
              Yes
            </Button>
          }
        />
      )}
    </>
  );
};

export default LeavePagePopup;
