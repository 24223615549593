export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "elite",
    value: "Elite - Revenue below $10M",
  },
  {
    id: "elite_plus",
    value: "Elite Plus - Revenue $10M to $50M",
  },
  {
    id: "elite_premium",
    value: "Elite Premium - Revenue above $50M",
  }
]
