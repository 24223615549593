import React from "react"
import styles from "./index.module.scss"
import classnames from "classnames"
import defaultAvatar from "../../../images/defaultUserAvatar.png"
import {
  notificationMessageDecoder,
  notificationTitleDecoder,
} from "../../../helpers/messages/notificationsDecoder"
import { getNotificationDate } from "../../../helpers/dateHandlers"
import handleViewport from "react-in-viewport"

const NotificationCard = ({
  image,
  isNew,
  darkCard,
  date,
  notification,
  forwardedRef,
}) => {
  const formatedDate = getNotificationDate(date)
  return (
    <div
      className={classnames(styles.cardWrapper, {
        [styles.newCard]: isNew && !darkCard,
        [styles.darkCard]: darkCard,
        [styles.newDarkCard]: darkCard && isNew,
      })}
      ref={forwardedRef}
    >
      <div className={styles.mainInfo}>
        <img className={styles.userImage} src={image || defaultAvatar} />
        <div className={styles.textContent}>
          <h2 className={styles.action}>
            {notificationTitleDecoder(notification)}
          </h2>
          <div className={styles.message}>
            {notificationMessageDecoder(notification)}
          </div>
        </div>
      </div>
      <div className={styles.date}>{formatedDate}</div>
    </div>
  )
}

export default handleViewport(NotificationCard)
