import React, { useState, useEffect } from "react"
import classNames from "classnames"
import InstituteSearchForm from "../../../../forms/InstituteSearchForm"
import { setSearchQuery } from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const SearchFormWrapper = ({ state, dispatch }) => {
  const [query, setQuery] = useState(state.searchParamGroup.query)
  useEffect(() => {
    setQuery(state.requestResultGroup.query)
  }, [state.requestResultGroup])
  return (
    <div
      className={classNames(styles.searchFormContainer, {
        [styles.emptyInput]: !state.requestResultGroup.query,
        [styles.fullInput]: !!state.requestResultGroup.query,
      })}
    >
      <InstituteSearchForm
        emptyMode={!state.requestResultGroup.query}
        placeholder="Search"
        searchQuery={query}
        onChange={(query) =>
          setQuery(query.length > 200 ? query.substring(0, 200) : query)
        }
        onSearch={() => dispatch(setSearchQuery(query.trim()))}
      />
    </div>
  )
}
export default SearchFormWrapper
