import React, { useState, useEffect, useRef } from "react"

import Editor from "draft-js-plugins-editor"
import { EditorState, convertToRaw, convertFromRaw } from "draft-js"
import createToolbarPlugin from "draft-js-static-toolbar-plugin"
import { BoldButton } from "draft-js-buttons"
import TextLimit from "../TextLimit/TextLimit"
import classNames from "classnames"
import styles from "./TextEditor.module.scss"
import toolbarStyles from "./ToolbarStyles.module.scss"
import buttonStyles from "./buttonStyles.module.scss"
import { ReactComponent as Error } from "../../images/icons/error.svg"

const toolbarPlugin = createToolbarPlugin({
  theme: { buttonStyles, toolbarStyles },
})

const { Toolbar } = toolbarPlugin
const plugins = [toolbarPlugin]

const TextEditor = ({
  text,
  placeholder,
  onSave,
  onChange,
  maxLength,
  disabled,
  label,
  errorMessage,
  error,
  onBlur,
  ...attr
}) => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromRaw(JSON.parse(text))) ||
      EditorState.createEmpty(),
  )
  const ref = useRef()
  const handleChange = (newEditorState) => {
    setEditorState(newEditorState)
    onChange(JSON.stringify(convertToRaw(newEditorState.getCurrentContent())))
  }

  const setTextFromProps = (text) => {
    if (text) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(text))),
      )
    }
  }

  useEffect(() => {
    setTextFromProps(text)
  }, [text])

  return (
    <div className={styles.wrapper} onClick={() => ref.current.focus()}>
      {label ? (
        <span className={classNames("note14r", styles.fieldName)}>{label}</span>
      ) : null}
      <div
        className={classNames(
          styles.editorWrapper,
          error && styles.editorWrapperError,
        )}
      >
        <Editor
          ref={ref}
          editorState={editorState}
          onChange={handleChange}
          onBlur={onBlur}
          // stripPastedStyles
          plugins={plugins}
          placeholder={placeholder}
          {...attr}
        />
      </div>
      <Toolbar className={"test"}>
        {
          // may be use React.Fragment instead of div to improve perfomance after React 16
          (externalProps) => (
            <div>
              <BoldButton {...externalProps} />
            </div>
          )
        }
      </Toolbar>
      <div
        className={classNames(
          styles.infoSection,
          errorMessage && styles.infoSectionError,
        )}
      >
        {error && errorMessage && (
          <div className={styles.errorMessageWrap}>
            <Error />
            <p className={styles.errorMessage}>{errorMessage}</p>
          </div>
        )}
        <TextLimit
          currentLength={editorState.getCurrentContent().getPlainText().length}
          maxLength={maxLength}
        />
      </div>
    </div>
  )
}

// TextEditor.propTypes = {
//   text: PropTypes.string.isRequired,
//   onSave: PropTypes.func.isRequired,
//   placeholder: PropTypes.string.isRequired
// };

export default TextEditor
