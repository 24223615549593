import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./index.module.scss"

const InstituteArticleMiniCard = ({
  classes,
  title,
  description,
  photoUrl,
  onClick,
}) => {
  let descriptionText
  try {
    const draftText = JSON.parse(description)
    descriptionText = draftText?.blocks
      ?.map((block) => (!block.text.trim() && "\n") || block.text)
      ?.join("\n")
  } catch {
    descriptionText = description
  }
  return (
    <div className={classNames(styles.card, classes)} onClick={onClick}>
      <p className={styles.title}>{title}</p>
      {!!descriptionText && (
        <p className={styles.description}>{descriptionText}</p>
      )}
      {!!photoUrl && (
        <div
          className={styles.photo}
          style={{ backgroundImage: `url(${photoUrl})` }}
        ></div>
      )}
    </div>
  )
}
InstituteArticleMiniCard.propTypes = {
  /** Data for `Label` component */
  label: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  /** User data */
  userAvatar: PropTypes.string.isRequired,
  /** Main text of the card */
  text: PropTypes.string.isRequired,
  /** Data of `CommentCounter` component */
  commentCount: PropTypes.number,
  /** Action on click */
  onClick: PropTypes.func,
}
export default InstituteArticleMiniCard
