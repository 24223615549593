import React from "react"
import styles from "./index.module.scss"

const AccountSubscriptionContainer = ({
  companyProfile,
  paymentAccountUrl,
  onClickToBuySubscription,
  onClickToGoToPaymentAccount,
  onPauseSubscription,
  onResumeSubscription,
}) => {
  const {
    paid_subscription_status,
    extended_trial,
    basic_trial,
    remaining_days_on_trial,
    extended_trial_label,
  } = companyProfile
  const getSubscriptionMessage = () => {
    if (
      paid_subscription_status === "none" &&
      !extended_trial &&
      !basic_trial
    ) {
      return (
        <>
          Your trial period expired.{" "}
          <span onClick={onClickToBuySubscription}>
            Purchase a subscription
          </span>{" "}
          now.
        </>
      )
    } else if (
      paid_subscription_status === "none" &&
      !extended_trial &&
      basic_trial
    ) {
      return (
        <>
          You have {parseInt(remaining_days_on_trial)} more days on your trial.{" "}
          <span onClick={onClickToBuySubscription}>
            Purchase a subscription
          </span>{" "}
          now.
        </>
      )
    } else if (paid_subscription_status === "none" && extended_trial) {
      return <>{extended_trial_label || ""}.</>
    } else if (paid_subscription_status === "active") {
      return (
        <>
          Active. You can <span onClick={onPauseSubscription}>pause</span> any
          time.
        </>
      )
    } else if (paid_subscription_status === "paused") {
      return (
        <>
          Paused. You can <span onClick={onResumeSubscription}>resume</span> any
          time.
        </>
      )
    } else {
      return null
    }
  }
  return (
    <div className={styles.settingsWrap}>
      <h1 className={styles.settingsTitle}>Subscription</h1>
      <div className={styles.settingsContent}>
        <div className={styles.settingsTable}>
          <div className={styles.settingsTableRow}>
            <div className={styles.settingsTableCol}>
              <div className={styles.settingsLabel}>Subscription Status</div>
            </div>
            <div className={styles.settingsTableCol}>
              <div className={styles.settingsText}>
                {getSubscriptionMessage()}
              </div>
            </div>
          </div>
        </div>
        {Boolean(paymentAccountUrl) && (
          <div className={styles.settingsTable}>
            <div className={styles.settingsTableRow}>
              <div className={styles.settingsTableCol}>
                <div className={styles.settingsText}>
                  <span onClick={onClickToGoToPaymentAccount}>
                    Change Payment Method
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.settingsTableRow}>
              <div className={styles.settingsTableCol}>
                <div className={styles.settingsText}>
                  <span onClick={onClickToGoToPaymentAccount}>
                    View Payment History
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccountSubscriptionContainer
