import React from "react"
import PropTypes from "prop-types"

const FormattedDate = ({ classes, timestamp }) => {
  const date = new Date(timestamp)
  const year = date.getFullYear()
  const month = ("0" + (date.getMonth() + 1)).substr(-2)
  const day = ("0" + date.getDate()).substr(-2)
  return <span className={classes}>{`${month}/${day}/${year}`}</span>
}

FormattedDate.propTypes = {
  /** External styles  */
  classes: PropTypes.any,
  /** Unix timestamp from backend  */
  timestamp: PropTypes.number.isRequired,
}
export default FormattedDate
