import styles from "../../containers/ProspectsContainer/ProspectsContainer.module.scss"
import Tag from "../elements/Tag/Tag"
import React from "react"
import { OpportunityOptions, UsersRoleOptions } from "../Opportunity/heplers"

export const getTagData = (name, value) => {
  switch (name) {
    case "accepted": {
      return { name: name, value: "Accepted" }
    }
    case "opportunity_type": {
      const result =
        OpportunityOptions.find((item) => item.dbName === value) || null
      if (result) return { name: name, value: result.value }
      break
    }
    case "is_full_profile": {
      return { name: name, value: "Completed Profile" }
    }
    case "role": {
      const result =
        UsersRoleOptions.find((item) => item.dbName === value) || null
      if (result) return { name: name, value: result.value }
      break
    }
    case "location": {
      return { name: name, value: value }
    }
    case "from": {
      return { name: name, value: `From: ${value}` }
    }
    case "to": {
      return { name: name, value: `To: ${value}` }
    }
    case "is_educated": {
      return { name: name, value: "Prospect`s higher education" }
    }
    case "company": {
      return { name: name, value: value }
    }
    default: {
      return {}
    }
  }
}

const renderTag = (tags, onHandleDeleteTag) => {
  if (tags.length === 0) return null
  return (
    <div className={styles.tagsWrap}>
      {tags.map(({ name, value }) => (
        <Tag
          key={name}
          name={name}
          title={value}
          onDelete={onHandleDeleteTag}
        />
      ))}
    </div>
  )
}

export default renderTag
