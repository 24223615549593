export const options = [
  {
    id: "false",
    value: "Companies/Organizations",
  },
  {
    id: "true",
    value: "Solopreneurs",
  },
  {
    id: "",
    value: "All",
  }
]
