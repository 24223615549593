import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styles from "./MessengerChatInput.module.scss"
import { ReactComponent as SendMsg } from "../../images/icons/sendMsg.svg"
import Textarea from "./Textarea"
import classNames from "classnames"

import { ReactComponent as Error } from "../../images/icons/error.svg"
import { isHaveBadWords } from "../../helpers/badWords"

const MessengerChatInput = ({ sendMessage }) => {
  const [valueOfInput, setValue] = useState("")
  const [error, setError] = useState("")

  useEffect(() => {
    if (isHaveBadWords(valueOfInput)) {
      setError("Please don't use bad language")
    } else {
      setError("")
    }
  }, [valueOfInput])

  const keyListener = (e) => {
    if (e.charCode === 13 && e.shiftKey) {
      if (valueOfInput.trim().length > 0) {
        return
      }
    }
    if (e.charCode === 13) {
      if (error.length === 0) {
        handleSend()
      }
      e.preventDefault()
    }
  }

  const handleSend = () => {
    if (valueOfInput.trim().length > 0) {
      sendMessage(valueOfInput.trim())
      setValue("")
    }
  }

  return (
    <div className={styles.chatWrap}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className={classNames(styles.textareaWrap, {
            [styles.textareaWrapError]: error,
          })}
        >
          <Textarea
            setValue={(e) => {
              setValue(e.target.value)
            }}
            valueOfInput={valueOfInput}
            placeholder="Type message..."
            onKeyPress={keyListener}
          />
        </div>
        <span
          onClick={error ? void 0 : handleSend}
          style={{ marginLeft: "20px" }}
        >
          <SendMsg />
        </span>
      </div>
      {error && error && (
        <div className={styles.errorMessageWrap}>
          <Error />
          <p className={styles.errorMessage}>{error}</p>
        </div>
      )}
    </div>
  )
}

MessengerChatInput.propTypes = {
  sendMessage: PropTypes.func,
}

MessengerChatInput.defaultProps = {
  sendMessage: () => {
    console.log("return message")
  },
}

export default MessengerChatInput
