import { useReducer, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { reducer } from "./reducer"
import {
  setSearchParams,
  setRequestResult,
  setSideArticles,
} from "./actionCreators"
import articleServices from "../../../services/articleServices"
import { paths } from "../../../routes/paths"

const useSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const locationSearchGroup = queryString.parse(location.search)
  const [state, dispatch] = useReducer(reducer, {
    searchParamGroup: {
      query: "",
      ...locationSearchGroup,
    },
    requestResultGroup: {
      itemList: [],
      itemCount: 0,
      pageCount: 0,
      isFetching: true,
    },
    sideArticleGroup: {
      type: locationSearchGroup.query ? "popular" : "recent",
      itemList: [],
    },
  })
  useEffect(() => {
    const locationSearchGroup = queryString.parse(location.search)
    dispatch(setSearchParams(locationSearchGroup))
    articleServices
      .getInspirationArticlePage(locationSearchGroup)
      .then(({ data }) => {
        dispatch(setRequestResult(data, locationSearchGroup))
        window.scrollTo(0, 0)
      })
    if (locationSearchGroup.query) {
      articleServices.getInspirationArticlePage({}).then(({ data }) => {
        dispatch(setSideArticles(true, data.inspiration_articles))
      })
    } else {
      articleServices.getRecentInspirationArticles().then(({ data }) => {
        dispatch(setSideArticles(false, data?.inspiration_articles))
      })
    }
  }, [location.search])
  useEffect(() => {
    history.replace(paths.inspirationSearch(state.searchParamGroup))
  }, [state.searchParamGroup])
  return [state, dispatch]
}
export default useSearch
