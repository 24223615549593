import {
  SET_SEARCH_QUERY,
  SET_SEARCH_PAGE,
  SET_TAB_FILTER,
  SET_FORM_FILTERS,
  RESET_FORM_FILTERS,
  REMOVE_QUESTION_TYPE,
  REMOVE_CREATOR_TYPE,
  REMOVE_FROM_DATE,
  REMOVE_TO_DATE,
  SET_REQUEST_RESULT,
  SET_SEARCH_PARAMS,
  SET_SIDE_QUESTIONS,
} from "./reducer"

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: { query },
})
export const setSearchPage = (pageNumber) => ({
  type: SET_SEARCH_PAGE,
  payload: { pageNumber },
})
export const setTabFilter = (questionType) => ({
  type: SET_TAB_FILTER,
  payload: { questionType },
})
export const setFormFilters = (filters) => ({
  type: SET_FORM_FILTERS,
  payload: { filters },
})
export const resetFormFilters = () => ({
  type: RESET_FORM_FILTERS,
  payload: null,
})
export const removeQuestionType = () => ({
  type: REMOVE_QUESTION_TYPE,
  payload: null,
})
export const removeCreatorType = () => ({
  type: REMOVE_CREATOR_TYPE,
  payload: null,
})
export const removeFromDate = () => ({
  type: REMOVE_FROM_DATE,
  payload: null,
})
export const removeToDate = () => ({
  type: REMOVE_TO_DATE,
  payload: null,
})
export const setSearchParams = (locationSearchGroup) => ({
  type: SET_SEARCH_PARAMS,
  payload: { locationSearchGroup },
})
export const setRequestResult = (data, locationSearchGroup) => ({
  type: SET_REQUEST_RESULT,
  payload: { data, locationSearchGroup },
})
export const setSideQuestions = (isQuery, questions) => ({
  type: SET_SIDE_QUESTIONS,
  payload: { isQuery, questions },
})
