import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { useSelector } from "react-redux"
import classNames from "classnames"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import styles from "./CommunityTopicEditContainer.module.scss"
import userServices from "../../services"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"
import CommunityTopicForm from "../../forms/CommunityTopicForm/CommunityTopicForm"

const CommunityTopicEdit = ({ id }) => {
  const [data, setData] = useState({})
  const history = useHistory()
  const userInfo = useSelector((state) => state.user)
  const lastLocation = useLastLocation()
  const [deletePopupOpened, setDeletePopupOpened] = useState(false)

  const checkEmail = (email) => {
    return userServices.suggestionServices
      .checkEmailInBase(email)
      .catch((e) => console.log(e))
  }

  const onDeleteCommunityTopic = () => {
    if (id) {
      userServices.communityServices.deleteCommunityTopicByUuid(id).then(() => {
        history.push(`/community/my-questions`)
      })
    }
  }

  const onSave = (newData) => {
    if (id) {
      userServices.communityServices
        .updateCommunityTopic({
          ...data,
          ...newData,
        })
        .then((data) => history.push(`/community/topics/${data.data.uuid}`))
    } else {
      userServices.communityServices
        .createCommunityTopic({
          ...newData,
        })
        .then((data) => history.push(`/community/topics/${data.data.uuid}`))
    }
  }

  useEffect(() => {
    if (id) {
      userServices.communityServices.getQuestion(id).then(({ data }) => {
        if (isUserAuthor(userInfo, data.user)) {
          setData(data)
        }
      })
    }
  }, [id, userInfo])

  return (
    <div className={styles.resourcesEditWrapper}>
      <div className={classNames("container", "fullWidth", styles.wrapper)}>
        <div className={styles.formWrapper}>
          {deletePopupOpened && (
            <Popup
              dialog
              subtitle="Do you want to delete this question?"
              button={
                <Button buttonStyle="black" onClick={onDeleteCommunityTopic}>
                  Yes
                </Button>
              }
              acceptButton={
                <Button
                  buttonStyle="outlinedBlack"
                  onClick={() => {
                    setDeletePopupOpened(false)
                  }}
                >
                  No
                </Button>
              }
              closeAction={() => {
                setDeletePopupOpened(false)
              }}
              isCrossIcon={true}
            />
          )}

          <Breadcrumbs className={styles.breadcrumbs}>
            <Link
              to={
                userInfo.role === "individual"
                  ? "/individual-profile"
                  : "/company-profile"
              }
              className={styles.link}
            >
              {userInfo.role === "individual"
                ? "My profile"
                : userInfo.company.name}
            </Link>

            {lastLocation && lastLocation.pathname.includes("my-questions") && (
              <Link to="/community/my-questions" className={styles.link}>
                My Questions
              </Link>
            )}

            <Link
              to={"#"}
              className={classNames(styles.link, styles.currentLink)}
            >
              {`${id ? "Editing" : "Adding new"} question`}
            </Link>
          </Breadcrumbs>

          <CommunityTopicForm
            onSave={onSave}
            onDelete={() => setDeletePopupOpened(true)}
            checkEmail={checkEmail}
            data={data}
          />
        </div>
      </div>
    </div>
  )
}

export default CommunityTopicEdit

const isUserAuthor = (userInfo, resourcesUser) => {
  if (userInfo.company && userInfo.company.id) {
    return userInfo.company.id === resourcesUser.id
  }

  return userInfo.id === resourcesUser.id
}
