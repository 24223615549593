import React from "react"
import classnames from "classnames"
import Button from "../Button/Button"
import CustomTooltip from "./CustomTooltip"
import styles from "./CompanyUserInfo.module.scss"
import CopyToClipboard from "../CopyToClipboard"
import { ReactComponent as Pencil } from "../../images/icons/pencil.svg"

const CompanyEmployShowInfo = ({
  handleEdit,
  anotherUser,
  dropdownData,
  onOpenChat,
  style,
  name,
  description,
  isHaveChatButton,
  employName,
}) => {
  return (
    <>
      <div
        className={classnames(
          styles.userNameContainer,
          styles.userNameContainerEmploy,
        )}
      >
        <span className={styles.nameSection}>
          <CustomTooltip name={employName}>
            <h4 className={classnames(styles.userName, styles.userNameFull)}>
              {employName}
            </h4>
          </CustomTooltip>
          {!anotherUser && (
            <div
              className={classnames("iconWrap", styles.editIconWrap)}
              onClick={handleEdit}
            >
              <Pencil className="icon" />
            </div>
          )}
        </span>
        <CustomTooltip name={name}>
          <h4 className={classnames(styles.userName, styles.userNameEmploy)}>
            {name}
          </h4>
        </CustomTooltip>
      </div>
      <div className={styles.userEducation}>
        <h6 className={style.title}>{dropdownData.describeMe.value}</h6>
        <h6 className={style.title}>
          {dropdownData.activity ? dropdownData.activity.value : ""}
        </h6>
        <h6 className={style.title}>
          {dropdownData.industry ? dropdownData.industry.value : ""}
        </h6>
        <div className={style.userDescription}>
          <h6 className={style.title}>Company description:</h6>
          <p className={classnames("note14r", style.subTitle)}>
            {description ? (
              <CopyToClipboard text={description} />
            ) : (
              <span>Add your description</span>
            )}
          </p>
        </div>
      </div>
      {anotherUser && isHaveChatButton && (
        <Button width="100%" onClick={onOpenChat} className={styles.chatButton}>
          Chat
        </Button>
      )}
    </>
  )
}

export default CompanyEmployShowInfo
