export const marketOptions = [
  {
    id: 1,
    value: "United States",
    databaseName: "US",
    source: "https://gist.github.com/mshafrir/2646763",
    subValue: [
      { id: "AL", databaseName: "AL", name: "Alabama" },
      { id: "AK", databaseName: "AK", name: "Alaska" },
      { id: "AZ", databaseName: "AZ", name: "Arizona" },
      { id: "AR", databaseName: "AR", name: "Arkansas" },
      { id: "CA", databaseName: "CA", name: "California" },
      { id: "CO", databaseName: "CO", name: "Colorado" },
      { id: "CT", databaseName: "CT", name: "Connecticut" },
      { id: "DE", databaseName: "DE", name: "Delaware" },
      { id: "DC", databaseName: "DC", name: "District Of Columbia" },
      { id: "FL", databaseName: "FL", name: "Florida" },
      { id: "GA", databaseName: "GA", name: "Georgia" },
      { id: "ID", databaseName: "ID", name: "Idaho" },
      { id: "IL", databaseName: "IL", name: "Illinois" },
      { id: "IN", databaseName: "IN", name: "Indiana" },
      { id: "IA", databaseName: "IA", name: "Iowa" },
      { id: "KS", databaseName: "KS", name: "Kansas" },
      { id: "KY", databaseName: "KY", name: "Kentucky" },
      { id: "LA", databaseName: "LA", name: "Louisiana" },
      { id: "ME", databaseName: "ME", name: "Maine" },
      { id: "MD", databaseName: "MD", name: "Maryland" },
      { id: "MA", databaseName: "MA", name: "Massachusetts" },
      { id: "MI", databaseName: "MI", name: "Michigan" },
      { id: "MN", databaseName: "MN", name: "Minnesota" },
      { id: "MS", databaseName: "MS", name: "Mississippi" },
      { id: "MO", databaseName: "MO", name: "Missouri" },
      { id: "MT", databaseName: "MT", name: "Montana" },
      { id: "NE", databaseName: "NE", name: "Nebraska" },
      { id: "NV", databaseName: "NV", name: "Nevada" },
      { id: "NH", databaseName: "NH", name: "New Hampshire" },
      { id: "NJ", databaseName: "NJ", name: "New Jersey" },
      { id: "NM", databaseName: "NM", name: "New Mexico" },
      { id: "NY", databaseName: "NY", name: "New York" },
      { id: "NC", databaseName: "NC", name: "North Carolina" },
      { id: "ND", databaseName: "ND", name: "North Dakota" },
      { id: "OH", databaseName: "OH", name: "Ohio" },
      { id: "OK", databaseName: "OK", name: "Oklahoma" },
      { id: "OR", databaseName: "OR", name: "Oregon" },
      { id: "PA", databaseName: "PA", name: "Pennsylvania" },
      { id: "PR", databaseName: "PR", name: "Puerto Rico" },
      { id: "RI", databaseName: "RI", name: "Rhode Island" },
      { id: "SC", databaseName: "SC", name: "South Carolina" },
      { id: "SD", databaseName: "SD", name: "South Dakota" },
      { id: "TN", databaseName: "TN", name: "Tennessee" },
      { id: "TX", databaseName: "TX", name: "Texas" },
      { id: "UT", databaseName: "UT", name: "Utah" },
      { id: "VT", databaseName: "VT", name: "Vermont" },
      { id: "VI", databaseName: "VI", name: "Virgin Islands" },
      { id: "VA", databaseName: "VA", name: "Virginia" },
      { id: "WA", databaseName: "WA", name: "Washington" },
      { id: "WV", databaseName: "WV", name: "West Virginia" },
      { id: "WI", databaseName: "WI", name: "Wisconsin" },
      { id: "WY", databaseName: "WY", name: "Wyoming" },
      { id: "HI", databaseName: "HI", name: "Hawaii" },
    ],
  },
  {
    id: 2,
    value: "Canada",
    databaseName: "CA",
    subValue: [],
  },
  {
    id: 3,
    value: "Europe",
    databaseName: "EU",
    source:
      "https://gist.github.com/jim-at-jibba/25fbdb561e927eeb9376a1f49db3907e",
    subValue: [
      { id: "AD", databaseName: "AD", name: "Andorra" },
      { id: "AL", databaseName: "AL", name: "Albania" },
      { id: "AT", databaseName: "AT", name: "Austria" },
      { id: "BA", databaseName: "BA", name: "Bosnia and Herzegovina" },
      { id: "BE", databaseName: "BE", name: "Belgium" },
      { id: "BG", databaseName: "BG", name: "Bulgaria" },
      { id: "BY", databaseName: "BY", name: "Belarus" },
      { id: "CH", databaseName: "CH", name: "Switzerland" },
      { id: "CY", databaseName: "CY", name: "Cyprus" },
      { id: "CZ", databaseName: "CZ", name: "Czech Republic" },
      { id: "DE", databaseName: "DE", name: "Germany" },
      { id: "DK", databaseName: "DK", name: "Denmark" },
      { id: "EE", databaseName: "EE", name: "Estonia" },
      { id: "ES", databaseName: "ES", name: "Spain" },
      { id: "FI", databaseName: "FI", name: "Finland" },
      { id: "FO", databaseName: "FO", name: "Faroe Islands" },
      { id: "FR", databaseName: "FR", name: "France" },
      { id: "GB", databaseName: "GB", name: "United Kingdom" },
      { id: "GR", databaseName: "GR", name: "Greece" },
      { id: "HR", databaseName: "HR", name: "Croatia" },
      { id: "HU", databaseName: "HU", name: "Hungary" },
      { id: "HS", databaseName: "HS", name: "Holy See" },
      { id: "IE", databaseName: "IE", name: "Ireland" },
      { id: "IC", databaseName: "IC", name: "Iceland" },
      { id: "IT", databaseName: "IT", name: "Italy" },
      { id: "LI", databaseName: "LI", name: "Liechtenstein" },
      { id: "LT", databaseName: "LT", name: "Lithuania" },
      { id: "LU", databaseName: "LU", name: "Luxembourg" },
      { id: "LV", databaseName: "LV", name: "Latvia" },
      { id: "MC", databaseName: "MC", name: "Monaco" },
      { id: "MD", databaseName: "MD", name: "Moldova" },
      { id: "MK", databaseName: "MK", name: "Macedonia" },
      { id: "MNN", databaseName: "MNN", name: "Montenegro" },
      { id: "MT", databaseName: "MT", name: "Malta" },
      { id: "NL", databaseName: "NL", name: "Netherlands" },
      { id: "NO", databaseName: "NO", name: "Norway" },
      { id: "MNK", databaseName: "MNK", name: "North Macedonia" },
      { id: "PL", databaseName: "PL", name: "Poland" },
      { id: "PT", databaseName: "PT", name: "Portugal" },
      { id: "RO", databaseName: "RO", name: "Romania" },
      { id: "RU", databaseName: "RU", name: "Russia" },
      { id: "SE", databaseName: "SE", name: "Sweden" },
      { id: "SI", databaseName: "SI", name: "Slovenia" },
      { id: "SW", databaseName: "SW", name: "Serbia" },
      { id: "SK", databaseName: "SK", name: "Slovakia" },
      { id: "SM", databaseName: "SM", name: "San Marino" },
      { id: "UA", databaseName: "UA", name: "Ukraine" },
      { id: "VA", databaseName: "VA", name: "Vatican City State" },
    ],
  },
  {
    id: 4,
    value: "Asia-Pacific",
    databaseName: "AP",
    source: "https://worldpopulationreview.com/country-rankings/apac-countries",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AZE", databaseName: "AZE", name: "Azerbaijan" },
      { id: "AS", databaseName: "AS", name: "American Samoa" },
      { id: "AM", databaseName: "AM", name: "Armenia" },
      { id: "AU", databaseName: "AU", name: "Australia" },
      { id: "BD", databaseName: "BD", name: "Bangladesh" },
      { id: "BT", databaseName: "BT", name: "Bhutan" },
      { id: "BN", databaseName: "BN", name: "Brunei" },
      { id: "KH", databaseName: "KH", name: "Cambodia" },
      { id: "CN", databaseName: "CN", name: "China" },
      { id: "CK", databaseName: "CK", name: "Cook Islands" },
      { id: "FJ", databaseName: "FJ", name: "Fiji" },
      { id: "PF", databaseName: "PF", name: "French Polynesia" },
      { id: "GU", databaseName: "GU", name: "Guam" },
      { id: "IN", databaseName: "IN", name: "India" },
      { id: "ID", databaseName: "ID", name: "Indonesia" },
      { id: "JP", databaseName: "JP", name: "Japan" },
      { id: "KI", databaseName: "KI", name: "Kiribati" },
      { id: "LA", databaseName: "LA", name: "Laos" },
      { id: "MY", databaseName: "MY", name: "Malaysia" },
      { id: "MV", databaseName: "MV", name: "Maldives" },
      { id: "MH", databaseName: "MH", name: "Marshall Islands" },
      { id: "FM", databaseName: "FM", name: "Micronesia" },
      { id: "MN", databaseName: "MN", name: "Mongolia" },
      { id: "MM", databaseName: "MM", name: "Myanmar" },
      { id: "NR", databaseName: "NR", name: "Nauru" },
      { id: "NP", databaseName: "NP", name: "Nepal" },
      { id: "NC", databaseName: "NC", name: "New Caledonia" },
      { id: "NZ", databaseName: "NZ", name: "New Zealand" },
      { id: "NU", databaseName: "NU", name: "Niue" },
      { id: "KP", databaseName: "KP", name: "North Korea" },
      { id: "MP", databaseName: "MP", name: "Northern Mariana Islands" },
      { id: "PK", databaseName: "PK", name: "Pakistan" },
      { id: "PW", databaseName: "PW", name: "Palau" },
      { id: "PG", databaseName: "PG", name: "Papua New Guinea" },
      { id: "PH", databaseName: "PH", name: "Philippines" },
      { id: "RU", databaseName: "RU", name: "Russia" },
      { id: "WS", databaseName: "WS", name: "Samoa" },
      { id: "SG", databaseName: "SG", name: "Singapore" },
      { id: "SB", databaseName: "SB", name: "Solomon Islands" },
      { id: "KR", databaseName: "KR", name: "South Korea" },
      { id: "LK", databaseName: "LK", name: "Sri Lanka" },
      { id: "TW", databaseName: "TW", name: "Taiwan" },
      { id: "TJ", databaseName: "TJ", name: "Tajikistan" },
      { id: "TH", databaseName: "TH", name: "Thailand" },
      { id: "TL", databaseName: "TL", name: "Timor Leste" },
      { id: "TK", databaseName: "TK", name: "Tokelau" },
      { id: "TU", databaseName: "TU", name: "Turkmenistan" },
      { id: "TO", databaseName: "TO", name: "Tonga" },
      { id: "TV", databaseName: "TV", name: "Tuvalu" },
      { id: "KZ", databaseName: "KZ", name: "Kazakhstan" },
      { id: "KG", databaseName: "KG", name: "Kyrgyzstan" },
      { id: "VU", databaseName: "VU", name: "Vanuatu" },
      { id: "VN", databaseName: "VN", name: "Vietnam" },
      { id: "WF", databaseName: "WF", name: "Wallis And Futuna" },
      { id: "UZ", databaseName: "UZ", name: "Uzbekistan" },
    ],
  },
  {
    id: 5,
    value: "Latin America",
    databaseName: "LA",
    source:
      "https://www.britannica.com/topic/list-of-countries-in-Latin-America-2061416",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AR", databaseName: "AR", name: "Argentina" },
      { id: "BZ", databaseName: "BZ", name: "Belize" },
      { id: "BO", databaseName: "BO", name: "Bolivia" },
      { id: "BM", databaseName: "BM", name: "Bahamas" },
      { id: "BS", databaseName: "BS", name: "Barbados" },
      { id: "BR", databaseName: "BR", name: "Brazil" },
      { id: "BR", databaseName: "BR", name: "Brazil" },
      { id: "CL", databaseName: "CL", name: "Chile" },
      { id: "CO", databaseName: "CO", name: "Colombia" },
      { id: "CR", databaseName: "CR", name: "Costa Rica" },
      { id: "CU", databaseName: "CU", name: "Cuba" },
      { id: "DO", databaseName: "DO", name: "Dominican Republic" },
      { id: "EC", databaseName: "EC", name: "Ecuador" },
      { id: "SV", databaseName: "SV", name: "El Salvador" },
      { id: "GF", databaseName: "GF", name: "French Guiana" },
      { id: "GP", databaseName: "GP", name: "Guadeloupe" },
      { id: "GT", databaseName: "GT", name: "Guatemala" },
      { id: "GY", databaseName: "GY", name: "Guyana" },
      { id: "HT", databaseName: "HT", name: "Haiti" },
      { id: "HN", databaseName: "HN", name: "Honduras" },
      { id: "MQ", databaseName: "MQ", name: "Martinique" },
      { id: "MX", databaseName: "MX", name: "Mexico" },
      { id: "NI", databaseName: "NI", name: "Nicaragua" },
      { id: "PA", databaseName: "PA", name: "Panama" },
      { id: "PY", databaseName: "PY", name: "Paraguay" },
      { id: "PE", databaseName: "PE", name: "Peru" },
      { id: "JM", databaseName: "JM", name: "Jamaica" },
      { id: "PR", databaseName: "PR", name: "Puerto Rico" },
      { id: "BL", databaseName: "BL", name: "Saint-Barthlemy" },
      { id: "MF", databaseName: "MF", name: "Saint-Martin" },
      { id: "VG", databaseName: "VG", name: "St. Vincent & Grenadines" },
      { id: "KN", databaseName: "KN", name: "Saint Kitts & Nevis" },
      { id: "SR", databaseName: "SR", name: "Suriname" },
      { id: "TT", databaseName: "TT", name: "Trinidad and Tobago" },
      { id: "UY", databaseName: "UY", name: "Uruguay" },
      { id: "VE", databaseName: "VE", name: "Venezuela" },
    ],
  },
  {
    id: 6,
    value: "Middle East",
    databaseName: "ME",
    source:
      "https://worldpopulationreview.com/country-rankings/middle-east-countries",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "AG", databaseName: "AG", name: "Afghanistan" },
      { id: "BH", databaseName: "BH", name: "Bahrain" },
      { id: "CY", databaseName: "CY", name: "Cyprus" },
      { id: "EG", databaseName: "EG", name: "Egypt" },
      { id: "IR", databaseName: "IR", name: "Iran" },
      { id: "IQ", databaseName: "IQ", name: "Iraq" },
      { id: "IL", databaseName: "IL", name: "Israel" },
      { id: "JO", databaseName: "JO", name: "Jordan" },
      { id: "KW", databaseName: "KW", name: "Kuwait" },
      { id: "LB", databaseName: "LB", name: "Lebanon" },
      { id: "OM", databaseName: "OM", name: "Oman" },
      { id: "PS", databaseName: "PS", name: "Palestine" },
      { id: "QA", databaseName: "QA", name: "Qatar" },
      { id: "SA", databaseName: "SA", name: "Saudi Arabia" },
      { id: "SY", databaseName: "SY", name: "Syria" },
      { id: "AE", databaseName: "AE", name: "The United Arab Emirates" },
      { id: "TR", databaseName: "TR", name: "Turkey" },
      { id: "YE", databaseName: "YE", name: "Yemen" },
    ],
  },
  {
    id: 7,
    value: "Africa",
    databaseName: "AF",
    source: "https://www.countries-ofthe-world.com/countries-of-africa.html",
    codes: "https://www.iban.com/country-codes",
    subValue: [
      { id: "DZ", databaseName: "DZ", name: "Algeria" },
      { id: "AO", databaseName: "AO", name: "Angola" },
      { id: "BJ", databaseName: "BJ", name: "Benin" },
      { id: "BW", databaseName: "BW", name: "Botswana" },
      { id: "BF", databaseName: "BF", name: "Burkina Faso" },
      { id: "BI", databaseName: "BI", name: "Burundi" },
      { id: "CV", databaseName: "CV", name: "Cabo Verde" },
      { id: "CM", databaseName: "CM", name: "Cameroon" },
      { id: "CF", databaseName: "CF", name: "Central African Republic" },
      { id: "TD", databaseName: "TD", name: "Chad" },
      { id: "KM", databaseName: "KM", name: "Comoros" },
      {
        id: "CD",
        databaseName: "CD",
        name: "Congo, Democratic Republic of the",
      },
      { id: "CG", databaseName: "CG", name: "Congo, Republic of the" },
      { id: "CI", databaseName: "CI", name: "Cote d'Ivoire" },
      { id: "DJ", databaseName: "DJ", name: "Djibouti" },
      { id: "EG", databaseName: "EG", name: "Egypt" },
      { id: "GQ", databaseName: "GQ", name: "Equatorial Guinea" },
      { id: "ER", databaseName: "ER", name: "Eritrea" },
      { id: "SZ", databaseName: "SZ", name: "Eswatini" },
      { id: "ET", databaseName: "ET", name: "Ethiopia" },
      { id: "GA", databaseName: "GA", name: "Gabon" },
      { id: "GM", databaseName: "GM", name: "Gambia" },
      { id: "GH", databaseName: "GH", name: "Ghana" },
      { id: "GN", databaseName: "GN", name: "Guinea" },
      { id: "GW", databaseName: "GW", name: "Guinea-Bissau" },
      { id: "KE", databaseName: "KE", name: "Kenya" },
      { id: "LS", databaseName: "LS", name: "Lesotho" },
      { id: "LR", databaseName: "LR", name: "Liberia" },
      { id: "LY", databaseName: "LY", name: "Libya" },
      { id: "MG", databaseName: "MG", name: "Madagascar" },
      { id: "MW", databaseName: "MW", name: "Malawi" },
      { id: "ML", databaseName: "ML", name: "Mali" },
      { id: "MR", databaseName: "MR", name: "Mauritania" },
      { id: "MU", databaseName: "MU", name: "Mauritius" },
      { id: "MA", databaseName: "MA", name: "Morocco" },
      { id: "MZ", databaseName: "MZ", name: "Mozambique" },
      { id: "NA", databaseName: "NA", name: "Namibia" },
      { id: "NE", databaseName: "NE", name: "Niger" },
      { id: "NG", databaseName: "NG", name: "Nigeria" },
      { id: "RW", databaseName: "RW", name: "Rwanda" },
      { id: "ST", databaseName: "ST", name: "Sao Tome and Principe" },
      { id: "SN", databaseName: "SN", name: "Senegal" },
      { id: "SC", databaseName: "SC", name: "Seychelles" },
      { id: "SL", databaseName: "SL", name: "Sierra Leone" },
      { id: "SO", databaseName: "SO", name: "Somalia" },
      { id: "ZA", databaseName: "ZA", name: "South Africa" },
      { id: "SS", databaseName: "SS", name: "South Sudan" },
      { id: "SD", databaseName: "SD", name: "Sudan" },
      { id: "TZ", databaseName: "TZ", name: "Tanzania" },
      { id: "TG", databaseName: "TG", name: "Togo" },
      { id: "TN", databaseName: "TN", name: "Tunisia" },
      { id: "UG", databaseName: "UG", name: "Uganda" },
      { id: "ZM", databaseName: "ZM", name: "Zambia" },
      { id: "ZW", databaseName: "ZW", name: "Zimbabwe" },
    ],
  },
]
