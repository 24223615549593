import React from "react"

import styles from "./index.module.scss"
import Input from "../../components/Input/Input"
import PasswordInput from "../../components/PasswordInput/PasswordInput"
import Button from "../../components/Button/Button"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"

const AccountSettingsFormContent = ({
  type,
  onCancel,
  closeForm,
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  touched,
  isSubmitting,
}) => {
  const [isPopupOpened, setIsPopupOpened] = React.useState(false)

  const handleCancel = () => {
    if (Object.keys(touched).length) {
      setIsPopupOpened(true)
    } else {
      closeForm()
    }
  }

  return (
    <div className={styles.formWrap}>
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          handleCancel={() => {
            onCancel ? onCancel() : closeForm()
            setIsPopupOpened(false)
          }}
        />
      )}

      <form onSubmit={handleSubmit} autoComplete="off">
        <Input
          type="text"
          name="type"
          value={values.type}
          label=""
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleBlur}
          hidden
        />

        {type === "email" && (
          <div className={styles.formItem}>
            <Input
              type="text"
              name="email"
              value={values.email}
              placeholder="Type here"
              label="Enter new email"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.email && touched.email}
              errorMessage={errors.email && touched.email && errors.email}
            />
          </div>
        )}

        {type === "phone_number" && (
          <div className={styles.formItem}>
            <Input
              type="text"
              name="phone_number"
              value={values.phone_number}
              placeholder="Type here"
              label="Enter new phone number"
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.phone_number && touched.phone_number}
              errorMessage={
                errors.phone_number &&
                touched.phone_number &&
                errors.phone_number
              }
            />
          </div>
        )}

        {type === "password" && (
          <React.Fragment>
            <div className={styles.formItem}>
              <PasswordInput
                name="old_password"
                value={values.old_password}
                placeholder="Type here"
                label="Enter current password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.old_password && touched.old_password}
                errorMessage={
                  errors.old_password &&
                  touched.old_password &&
                  errors.old_password
                }
              />
            </div>
            <div className={styles.formItem}>
              <PasswordInput
                name="password"
                value={values.password}
                placeholder="Type here"
                label="Enter new password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                errorMessage={
                  errors.password && touched.password && errors.password
                }
              />
            </div>
            <div className={styles.formItem}>
              <PasswordInput
                name="confirm_password"
                value={values.confirm_password}
                placeholder="Type here"
                label="Confirm new password"
                autoComplete="off"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.confirm_password && touched.confirm_password}
                errorMessage={
                  errors.confirm_password &&
                  touched.confirm_password &&
                  errors.confirm_password
                }
              />
            </div>
          </React.Fragment>
        )}

        <div className={styles.formBottom}>
          <div className={styles.formBottomItem}>
            <Button
              type="button"
              onClick={handleCancel}
              buttonStyle="outlined"
              disabled={isSubmitting}
            >
              Cancel
            </Button>
          </div>

          <div className={styles.formBottomItem}>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default AccountSettingsFormContent
