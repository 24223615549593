import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"
import copy from "copy-to-clipboard"

import styles from "./index.module.scss"
import { ReactComponent as ShareIcon } from "../../images/icons/shareIcon.svg"
import { ReactComponent as LinkIcon } from "../../images/icons/link.svg"
import { ReactComponent as TwitterIcon } from "../../images/icons/twitter.svg"
import { ReactComponent as FacebookIcon } from "../../images/icons/facebook.svg"
import { ReactComponent as LinkedinIcon } from "../../images/icons/linkedin.svg"
import { ReactComponent as WhatsappIcon } from "../../images/icons/whatsapp.svg"
import useOutsideClicker from "../../hooks/useOutsideClicker"

const Share = ({ title, description, url, children, type }) => {
  const [menuVisibility, setMenuVisibility] = React.useState(false)
  const [copyMsgVisibility, setCopyMsgVisibility] = React.useState(false)

  const menuRef = React.useRef(null)
  useOutsideClicker(menuRef, () => {
    setMenuVisibility(false)
  })

  const copLink = () => {
    copy(url)
    setCopyMsgVisibility(true)

    setTimeout(() => {
      setCopyMsgVisibility(false)
    }, 2500)
  }

  return (
    <div ref={menuRef} className={styles.shareMenu}>
      <div
        className={classnames(
          styles.shareMenuButton,
          type === "outlined" && styles.shareMenuButtonHidden,
        )}
        onClick={() => setMenuVisibility(!menuVisibility)}
      >
        {children || (
          <React.Fragment>
            <div className={styles.shareMenuButtonIcon}>
              <ShareIcon />
            </div>

            <span className={styles.shareMenuButtonText}>Share</span>
          </React.Fragment>
        )}
      </div>

      <div
        className={classnames(
          styles.shareMenuList,
          type === "horizontal" && styles.shareMenuListHorizontal,
          type === "outlined" && styles.shareMenuListStatic,
          menuVisibility && styles.shareMenuListVisible,
        )}
      >
        <div className={styles.shareMenuListContent}>
          <div
            url={url}
            quote={title}
            className={classnames(
              styles.shareMenuItem,
              type === "horizontal" && styles.shareMenuItemRounded,
              type === "outlined" && styles.shareMenuItemOutlined,
            )}
            onClick={copLink}
          >
            <div className={styles.shareMenuItemIcon}>
              <LinkIcon />
            </div>

            <div className={styles.shareMenuItemText}>Copy link</div>

            <div
              className={classnames(
                styles.shareMenuItemCopyPopup,
                copyMsgVisibility && styles.shareMenuItemCopyPopupVisible,
              )}
            >
              Copied
            </div>
          </div>

          <TwitterShareButton
            url={url}
            title={title}
            via="https://private.theb2t.com/"
            hashtags={["Bridge2Technologies"]}
            className={classnames(
              styles.shareMenuItem,
              type === "horizontal" && styles.shareMenuItemRounded,
              type === "outlined" && styles.shareMenuItemOutlined,
            )}
          >
            <div className={styles.shareMenuItemIcon}>
              <TwitterIcon />
            </div>
            <div className={styles.shareMenuItemText}>Twitter</div>
          </TwitterShareButton>

          <FacebookShareButton
            url={url}
            quote={title}
            hashtag="Bridge2Technologies"
            className={classnames(
              styles.shareMenuItem,
              type === "horizontal" && styles.shareMenuItemRounded,
              type === "outlined" && styles.shareMenuItemOutlined,
            )}
          >
            <div className={styles.shareMenuItemIcon}>
              <FacebookIcon />
            </div>
            <div className={styles.shareMenuItemText}>Facebook</div>
          </FacebookShareButton>

          <WhatsappShareButton
            url={url}
            title={title}
            className={classnames(
              styles.shareMenuItem,
              type === "horizontal" && styles.shareMenuItemRounded,
              type === "outlined" && styles.shareMenuItemOutlined,
            )}
          >
            <div className={styles.shareMenuItemIcon}>
              <WhatsappIcon />
            </div>
            <div className={styles.shareMenuItemText}>Whatsapp</div>
          </WhatsappShareButton>

          <LinkedinShareButton
            url={url}
            title={title}
            summary={description || "https://private.theb2t.com/"}
            source="Bridge2Technologies"
            className={classnames(
              styles.shareMenuItem,
              type === "horizontal" && styles.shareMenuItemRounded,
              type === "outlined" && styles.shareMenuItemOutlined,
            )}
          >
            <div className={styles.shareMenuItemIcon}>
              <LinkedinIcon />
            </div>
            <div className={styles.shareMenuItemText}>Linkedin</div>
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  )
}

Share.defaultProps = {
  description: null,
  type: null,
}

Share.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["horizontal", "outlined", null]),
}

export default Share
