import React, { useEffect, useState } from "react"
import styles from "./NotificationsContainer.module.scss"
import NotificationCard from "../../components/cards/NotificationCard"
import Pagination from "../../components/elements/Pagination"
import services from "../../services"
import { useLocation, useHistory } from "react-router"
import queryString from "query-string"
import { useDispatch, useSelector } from "react-redux"
import {
  setNotifications as setNotificationRedux,
  updateNotifications,
} from "../../actions/notifications/notificationsActions"

const NotificationsContainer = () => {
  const location = useLocation()
  const history = useHistory()
  const { page } = queryString.parse(location.search)
  const currentPage = Number(page) || 1

  const dispatch = useDispatch()

  const [notifications, setNotifications] = useState([])
  const [totalCountPages, setTotalCountPages] = useState()

  const getNotificationsList = () => {
    services.notificationsServices
      .getNotificationsList(currentPage)
      .then((result) => {
        setNotifications(result.data.message_on_page)
        setTotalCountPages(Math.ceil(result.data.total_count / 12))
      })

    dispatch(updateNotifications())
  }

  useEffect(() => {
    getNotificationsList()
    const intervalName = setInterval(() => {
      getNotificationsList()
    }, 10000)
    return () => {
      clearInterval(intervalName)
    }
  }, [page])

  const markNotificationAsRead = (uuid) => {
    services.notificationsServices.markNotificationRead(uuid)
    const newNotifications = notifications.map((n) => {
      if (n.uuid === uuid) {
        return {
          ...n,
          is_readed: true,
        }
      }
      return n
    })

    setNotifications(newNotifications)
  }

  const onPageChange = (page) => {
    history.push(`${location.pathname}?page=${page}`)
  }
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>Notifications</h2>
      </div>

      <div className={styles.cardWrapper}>
        {notifications.map((notification) => (
          <NotificationCard
            isNew={!notification.is_readed}
            date={notification.created_at}
            image={notification.sender.photo}
            notification={notification}
            onEnterViewport={() => {
              if (!notification.is_readed) {
                markNotificationAsRead(notification.uuid)
              }
            }}
            darkCard
          />
        ))}
      </div>

      <div className={styles.pagination}>
        <Pagination
          currentPageNumber={currentPage}
          totalPageCount={totalCountPages}
          onSelect={onPageChange}
        />
      </div>
    </div>
  )
}

export default NotificationsContainer
