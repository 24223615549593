import React, { forwardRef, useEffect, useState } from "react"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"
import VoteUpDown from "../../VoteUpDown"
import UserAvatar from "../../elements/UserAvatar"
import { ReactComponent as CommentIcon } from "../../../images/icons/commentIcon.svg"
import { ReactComponent as ShareIcon } from "../../../images/icons/shareIcon.svg"
import { ReactComponent as EditIcon } from "../../../images/icons/edit.svg"
import { ReactComponent as DeleteIcon } from "../../../images/icons/deleteImage.svg"
import CopyToClipboard from "../../CopyToClipboard"

import TextArea from "../../TextArea/TextArea"
import ButtonComponent from "../../Button/Button"

import ConfirmCancelPopup from "../../Popup/ConfirmCancelPopup"
import CommentForm from "../../../forms/CommentForm"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"

import classnames from "classnames"

const CommentWrapper = forwardRef(
  (
    {
      handleDeleteComment,
      handleEditComment,
      onCommentPost,
      body,
      voteComment,
      userLink,
      commenter,
      photo,
      reputation,
      userUuid,
      id,
      uuid,
      isMyComment,
      created_at,
      replies,
      userInfo,
      level,
    },
    ref,
  ) => {
    const [isEdit, setIsEdit] = useState(false)
    const [commentText, setCommentText] = useState(body || "")
    const [isDeletePopupOpened, setIsDeletePopupOpened] = useState(false)
    const handleEditClick = () => setIsEdit(true)

    const { width } = useWindowHeightWidth()

    const [repliesRefs, setRepliesRefs] = useState([])

    const [isRepliesOpened, setIsRepliesOpened] = useState(false)

    const handleShowMore = () => {
      setIsRepliesOpened(!isRepliesOpened)
      setTimeout(() => {
        if (repliesRefs[0]) {
          repliesRefs[0].scrollIntoView({
            block: "center",
          })
        }
      })
    }
    const [isReplyFormOpen, setIsReplyFormOpen] = useState(false)

    const handlePostComment = (text, id) => {
      onCommentPost(text, id)
      setIsRepliesOpened(true)
      setTimeout(() => {
        if (repliesRefs[repliesRefs.length - 1]) {
          repliesRefs[repliesRefs.length - 1].scrollIntoView({
            block: "center",
          })
        }
      })
    }

    useEffect(() => {
      setCommentText(body)
    }, [body])

    const handleChangeCommentText = (e) => {
      setCommentText(e.target.value.slice(0, 546))
    }

    const handleDeleteCommentClick = () => {
      handleDeleteComment(uuid)
      setIsDeletePopupOpened(false)
    }

    const handleSaveComment = () => {
      setIsEdit(false)
      handleEditComment(commentText, uuid)
    }

    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault()
        setIsEdit(false)
        handleEditComment(commentText, uuid)
      }
      if (e.keyCode === 13 && e.shiftKey === true && !commentText.trim()) {
        e.preventDefault()
      }
    }

    return (
      <div className={styles.commentWrap} ref={ref}>
        {isDeletePopupOpened && (
          <ConfirmCancelPopup
            handleCancel={handleDeleteCommentClick}
            setIsPopupOpened={setIsDeletePopupOpened}
            cancelMessage={"Do you want to delete this comment?"}
          />
        )}

        <div className={styles.commentContentWrap}>
          {voteComment && (width > 425 || !isEdit) && (
            <div
              className={classnames(
                styles.commentSidebar,
                !replies && styles.commentSidebarWithReplies,
              )}
            >
              <VoteUpDown
                reputation={reputation}
                voteFn={(e) => voteComment(e, uuid)}
                horizontalOnMobile
              />
            </div>
          )}

          <div className={styles.commentContent}>
            <div className={styles.commentHeader}>
              <UserAvatar size={40} src={photo} />

              <div className={styles.commentAuthorInfo}>
                <div className={styles.commentPostedBy}>
                  Posted by <Link to={userLink}>{commenter}</Link>
                </div>

                <div className={styles.commentPublishedDate}>{created_at}</div>
              </div>
            </div>

            {isEdit ? (
              <div className={styles.commentEdit}>
                <div className={styles.commentEditTextArea}>
                  <TextArea
                    border
                    value={commentText}
                    onChange={handleChangeCommentText}
                    onKeyDown={handleKeyDown}
                    placeholder="Type here..."
                  />
                </div>

                <div className={styles.commentEditButtons}>
                  <ButtonComponent
                    buttonStyle={"outlined"}
                    onClick={() => setIsEdit(false)}
                    className={styles.commentEditButtonsItem}
                  >
                    Cancel
                  </ButtonComponent>

                  <ButtonComponent
                    buttonStyle={"contained"}
                    onClick={handleSaveComment}
                    className={styles.commentEditButtonsItem}
                  >
                    Save
                  </ButtonComponent>
                </div>
              </div>
            ) : (
              <>
                <div className={styles.commentBody}>
                  {<CopyToClipboard text={body} />}
                </div>
                <div className={styles.commentFooterWrapper}>
                  <div className={styles.commentFooter}>
                    {level !== 2 && (
                      <div
                        onClick={() => setIsReplyFormOpen(true)}
                        className={styles.commentFooterItems}
                      >
                        <CommentIcon />
                        <span>Reply</span>
                      </div>
                    )}

                    {isMyComment && (
                      <>
                        <div
                          onClick={handleEditClick}
                          className={styles.commentFooterItems}
                        >
                          <EditIcon />
                          <span>Edit</span>
                        </div>

                        <div
                          onClick={() => setIsDeletePopupOpened(true)}
                          className={styles.commentFooterItems}
                        >
                          <DeleteIcon />
                          <span>Delete</span>
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    {replies && replies.length > 0 && (
                      <div
                        onClick={handleShowMore}
                        className={classnames(
                          styles.commentFooterShowReplies,
                          "goldLink",
                        )}
                      >
                        <span>
                          {isRepliesOpened
                            ? "hide replies"
                            : getRepliesCount(replies)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {isReplyFormOpen && (
          <div className={styles.commentReplyForm}>
            <CommentForm
              userInfo={userInfo}
              onCommentPost={handlePostComment}
              id={id}
              handleCloseForm={() => setIsReplyFormOpen(false)}
            />
          </div>
        )}
        {isRepliesOpened && replies && replies.length > 0 && (
          <div className={styles.commentReplyList}>
            {replies.map((item, index) => (
              <CommentWrapper
                key={item.uuid}
                onCommentPost={onCommentPost}
                body={item.body}
                userLink={item.userLink}
                voteComment={voteComment}
                ref={(ref) => {
                  repliesRefs[index] = ref
                }}
                commenter={item.commenter.name || item.commenter.full_name}
                isMyComment={item.isMyComment}
                photo={item.commenter.photo}
                reputation={item.reputation}
                userUuid={item.commenter.uuid}
                created_at={item.created_at}
                replies={item.replies}
                userInfo={userInfo}
                id={item.id}
                uuid={item.uuid}
                level={item.level}
                handleDeleteComment={handleDeleteComment}
                handleEditComment={handleEditComment}
              />
            ))}
          </div>
        )}
      </div>
    )
  },
)

const getRepliesCount = (replies) => {
  if (replies.length === 1) {
    return "1 more reply"
  } else if (replies.length < 5) {
    return `${replies.length} more replies`
  }
  return "5 more replies"
}

export default CommentWrapper
