import React from "react"
import Popup from "../../../components/Popup/Popup"
import Button from "../../../components/Button/Button"

const CompanyEmployeeInvitedPopup = ({
  data,
  closeAction,
  title = "Invite employee",
}) => {
  return (
    <Popup
      width={496}
      title={title}
      isCrossIcon
      closeAction={closeAction}
      closeByOutsideClick={false}
      button={
        <Button buttonStyle="black" onClick={closeAction}>
          Close
        </Button>
      }
    >
      <div
        style={{ padding: "60px 0px", color: "#7e8085", textAlign: "center" }}
      >
        Invite email to <span style={{ color: "black" }}>{data}</span> has been
        sent
      </div>
    </Popup>
  )
}

export default CompanyEmployeeInvitedPopup
