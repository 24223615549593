import React from "react"
import moment from "moment"
import TrueTag from "../../../../components/elements/TrueTag"
import { getOpportunityTypeName } from "./helpers"
import {
  removeOpportunityType,
  removeFromDate,
  removeToDate,
  removeCompany,
  removeLocation,
} from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const TagWrapper = ({ classes, searchParamGroup, dispatch }) => {
  const {
    opportunity_type,
    from,
    to,
    company_name,
    location,
  } = searchParamGroup
  return (
    <div className={classes}>
      {Boolean(opportunity_type) && (
        <TrueTag
          classes={styles.tag}
          title={getOpportunityTypeName(opportunity_type)}
          onClick={() => dispatch(removeOpportunityType())}
        />
      )}
      {Boolean(from) && (
        <TrueTag
          classes={styles.tag}
          title={`From: ${moment(from).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeFromDate())}
        />
      )}
      {Boolean(to) && (
        <TrueTag
          classes={styles.tag}
          title={`To: ${moment(to).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeToDate())}
        />
      )}
      {Boolean(location) && (
        <TrueTag
          classes={styles.tag}
          title={location}
          onClick={() => dispatch(removeLocation())}
        />
      )}
      {Boolean(company_name) && (
        <TrueTag
          classes={styles.tag}
          title={company_name}
          onClick={() => dispatch(removeCompany())}
        />
      )}
    </div>
  )
}
export default TagWrapper
