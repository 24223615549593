import React from "react"
import classNames from "classnames"
import styles from "../index.module.scss"

const printPageNumberRange = (
  startNumber,
  endNumber,
  currentPageNumber,
  onSelect,
) => {
  const pageNumberList = []
  for (let i = startNumber; i <= endNumber; i++) {
    pageNumberList.push(
      <span
        key={i}
        className={classNames({
          [styles.currentPageNumber]: currentPageNumber === i,
        })}
        onClick={() => {
          if (currentPageNumber !== i) {
            onSelect(i)
          }
        }}
      >
        {i}
      </span>,
    )
  }
  return pageNumberList
}
export default printPageNumberRange
