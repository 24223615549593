export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE"
export const SET_FORM_FILTERS = "SET_FORM_FILTERS"
export const SET_TAB_FILTER = "SET_TAB_FILTER"
export const RESET_FORM_FILTERS = "RESET_FORM_FILTERS"
export const REMOVE_RESOURCE_TYPE = "REMOVE_RESOURCE_TYPE"
export const REMOVE_EVENT_TYPE = "REMOVE_EVENT_TYPE"
export const REMOVE_ACCESS_TYPE = "REMOVE_ACCESS_TYPE"
export const REMOVE_FROM_DATE = "REMOVE_FROM_DATE"
export const REMOVE_TO_DATE = "REMOVE_TO_DATE"
export const REMOVE_LOCATION = "REMOVE_LOCATION"
export const REMOVE_FREE_ONLY = "REMOVE_FREE_ONLY"
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS"
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT"

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY: {
      const { query } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: 1, query },
      }
    }
    case SET_SEARCH_PAGE: {
      const { pageNumber } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: pageNumber },
      }
    }
    case SET_TAB_FILTER: {
      const { resourceType } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          resource_type: resourceType ? resourceType : "",
          page: 1,
        },
      }
    }
    case SET_FORM_FILTERS: {
      const {
        filters: {
          resourceType,
          eventType,
          accessType,
          fromDate,
          toDate,
          location,
          isFree,
        },
      } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          resource_type: resourceType ? resourceType : "",
          resource_sub_type: eventType ? eventType : "",
          event_type: accessType ? accessType : "",
          from: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          to: toDate ? toDate.format("YYYY-MM-DD") : null,
          location: location ? location : "",
          free_only: isFree ? true : null,
          page: 1,
        },
      }
    }
    case RESET_FORM_FILTERS: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          resource_type: "",
          resource_sub_type: "",
          event_type: "",
          from: null,
          to: null,
          location: "",
          free_only: null,
          page: 1,
        },
      }
    }
    case REMOVE_RESOURCE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          resource_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_EVENT_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          resource_sub_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_ACCESS_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          event_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_FROM_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, from: null, page: 1 },
      }
    }
    case REMOVE_TO_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, to: null, page: 1 },
      }
    }
    case REMOVE_LOCATION: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, location: "", page: 1 },
      }
    }
    case REMOVE_FREE_ONLY: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          free_only: null,
          page: 1,
        },
      }
    }
    case SET_SEARCH_PARAMS: {
      const { locationSearchGroup } = payload
      return {
        ...state,
        searchParamGroup: {
          // ...state.searchParamGroup,
          ...locationSearchGroup,
        },
      }
    }
    case SET_REQUEST_RESULT: {
      const { data, locationSearchGroup } = payload
      const dataTotalCount = data.total_count || 0
      return {
        ...state,
        requestResultGroup: {
          itemList: data.resources || [],
          itemCount: dataTotalCount,
          pageCount: Math.ceil(dataTotalCount / 12),
          page: 1,
          query: "",
          ...locationSearchGroup,
        },
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
