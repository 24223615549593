import React, { useState, useEffect } from "react"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import AccountSidebarNav from "../../components/AccountSidebarNav"
import AccountSubscriptionContainer from "../../containers/AccountSubscriptionContainer"
import Loading from "../../components/Loading/Loading"
import services from "../../services"
import styles from "./index.module.scss"

const AccountSubscriptionPage = () => {
  const [state, setState] = useState({
    isLoading: true,
    companyProfile: {},
    paymentAccountUrl: "",
  })

  useEffect(() => {
    services.companyServices
      .getSelfCompanyInfo()
      .then(({ data }) => {
        const companyProfile = data
        setState((prevState) => ({
          ...prevState,
          companyProfile,
          isLoading: false,
        }))
        return companyProfile
      })
      .then((companyProfile) => {
        if (companyProfile.paid_subscription_status !== "none") {
          return services.companyServices.getPaymentUrl()
        }
      })
      .then((url) => {
        if (url) {
          setState((prevState) => ({ ...prevState, paymentAccountUrl: url }))
        }
      })
  }, [])

  const onClickToBuySubscription = () =>
    services.companyServices
      .getPaymentUrl()
      .then((url) => window.open(url, "_blank"))

  const onPauseSubscription = () =>
    services.companyServices.pauseSubscription().then((msg) => {
      if (msg === "ok") {
        setState((prevState) => ({
          ...prevState,
          companyProfile: {
            ...prevState.companyProfile,
            paid_subscription_status: "paused",
          },
        }))
      }
    })

  const onResumeSubscription = () =>
    services.companyServices.resumeSubscription().then((msg) => {
      if (msg === "ok") {
        setState((prevState) => ({
          ...prevState,
          companyProfile: {
            ...prevState.companyProfile,
            paid_subscription_status: "active",
          },
        }))
      }
    })

  const onClickToGoToPaymentAccount = () =>
    window.open(state.paymentAccountUrl, "_blank")

  return (
    <div className={styles.pageWrap}>
      <ContentWithSidebars topSidebar={() => <AccountSidebarNav />}>
        {state.isLoading ? (
          <Loading />
        ) : (
          <>
            {state.companyProfile && (
              <AccountSubscriptionContainer
                companyProfile={state.companyProfile}
                paymentAccountUrl={state.paymentAccountUrl}
                onClickToBuySubscription={onClickToBuySubscription}
                onClickToGoToPaymentAccount={onClickToGoToPaymentAccount}
                onPauseSubscription={onPauseSubscription}
                onResumeSubscription={onResumeSubscription}
              />
            )}
          </>
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default AccountSubscriptionPage
