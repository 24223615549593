import React, { useEffect, useState } from "react"
import Grid from "@material-ui/core/Grid"
import styles from "./SignUpContainer.module.scss"
import SignUpContainer from "./SignUpContainer"
import SignUpRadio from "../../components/SignUpRadio/SignUpRadio"
import { ReactComponent as Image3 } from "../../images/signUp/step2-1.svg"
import { ReactComponent as Image4 } from "../../images/signUp/step2-2.svg"

function SignUpCompnayStep2Container({
  user,
  onBack,
  onAccountTypeChange,
  onHandleNext,
}) {
  const [accountType, setAccountType] = useState()

  useEffect(() => {
    if (user.type) {
      setAccountType(user.type)
    }
  }, [user])

  const handleChangeAccountType = (type) => {
    setAccountType(type)
    onAccountTypeChange(type)
  }
  return (
    <SignUpContainer
      title="Sign Up Process"
      subtitle="Step 2: Account Type"
      description="Are you a seeker of opportunities or a provider of opportunities"
      onBack={onBack}
      onNext={() => onHandleNext(accountType)}
      withBackBtn
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SignUpRadio
            name="company_seeker"
            onClick={(name) => {
              handleChangeAccountType(name)
            }}
            selected={accountType === "company_seeker"}
            title="I am a Seeker of Opportunities"
            image={<Image3 />}
            className={styles.signUpCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SignUpRadio
            name="company_provider"
            onClick={(name) => {
              handleChangeAccountType(name)
            }}
            selected={accountType === "company_provider"}
            title="I am a Provider of Opportunities"
            image={<Image4 />}
            className={styles.signUpCard}
          />
        </Grid>
      </Grid>
    </SignUpContainer>
  )
}

SignUpCompnayStep2Container.propTypes = {}

export default SignUpCompnayStep2Container
