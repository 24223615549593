import React from "react"
import { withFormik } from "formik"
import { object, string, ref } from "yup"

import styles from "./ChoosePasswordForm.module.scss"
import PasswordInput from "../../components/PasswordInput/PasswordInput"
import Button from "../../components/Button/Button"

const ChoosePasswordForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleCancel,
  handleSubmit,
  wordingTexts,
}) => {
  return (
    <form className={styles.formWrapper}>
      <div className={styles.fieldsWrapper}>
        <PasswordInput
          name="password"
          label={wordingTexts.passwordLabel || "Password"}
          value={values.password}
          placeholder={
            wordingTexts.passwordPlaceholder || "Enter your password"
          }
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.password && touched.password}
          errorMessage={errors.password && touched.password && errors.password}
        />
        <PasswordInput
          name="confirmPassword"
          label={wordingTexts.confirmPasswordLabel || "Password confirmation"}
          placeholder={
            wordingTexts.confirmPasswordPlaceholder || "Confirm your password"
          }
          value={values.confirmPassword}
          onBlur={handleBlur}
          onChange={handleChange}
          error={errors.confirmPassword && touched.confirmPassword}
          errorMessage={
            errors.confirmPassword &&
            touched.confirmPassword &&
            errors.confirmPassword
          }
        />
      </div>
      <div className={styles.btnsWrapper}>
        <Button buttonStyle="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={
            Object.values(errors).length > 0 ||
            !values.password.trim() ||
            !values.confirmPassword.trim()
          }
          onClick={handleSubmit}
        >
          {wordingTexts.submitBtn || "Sign in"}
        </Button>
      </div>
    </form>
  )
}

export default withFormik({
  mapPropsToValues() {
    return {
      password: "",
      confirmPassword: "",
    }
  },
  validationSchema() {
    return object().shape({
      password: string()
        .min(
          8,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*[a-z])/,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*[A-Z])/,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*\d)/,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .matches(
          /(?=.*\W|_)/,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .max(
          32,
          "Enter a combination of minimum 8 characters & maximum 32 characters, with a mix of uppercase letters, lower case letters, numbers & symbols",
        )
        .required("Please enter a password"),
      confirmPassword: string()
        .oneOf(
          [ref("password"), null],
          "Entered passwords didn`t match. Try again.",
        )
        .required("Please confirm your password"),
    })
  },
  handleSubmit(
    values,
    { setSubmitting, setFieldError, props: { handleSubmit } },
  ) {
    handleSubmit(values.password)
    setSubmitting(false)
  },
})(ChoosePasswordForm)
