import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import OpportunityContainer from "../../containers/OpportunityContainer/OpportunityContainer"

const OpportunityPage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)
  return <OpportunityContainer uuid={values?.id} from={values?.from} />
}
export default OpportunityPage
