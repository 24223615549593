import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import printArrow from "./subRender/printArrow"
import printPageNumberRange from "./subRender/printPageNumberRange"
import styles from "./index.module.scss"

const Pagination = ({
  classes,
  currentPageNumber,
  totalPageCount,
  onSelect,
}) => {
  return (
    <span className={classNames(styles.pagination, classes)}>
      {(() => {
        currentPageNumber = isNaN(currentPageNumber) ? 1 : currentPageNumber
        if (totalPageCount === 1) {
          return null
        } else if (totalPageCount <= 5) {
          return (
            <>
              {printArrow("left", currentPageNumber, totalPageCount, onSelect)}
              {printPageNumberRange(
                1,
                totalPageCount,
                currentPageNumber,
                onSelect,
              )}
              {printArrow("right", currentPageNumber, totalPageCount, onSelect)}
            </>
          )
        } else {
          if (currentPageNumber <= 3) {
            const endPageNumber = currentPageNumber === 3 ? 4 : 3
            return (
              <>
                {printArrow(
                  "left",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
                {printPageNumberRange(
                  1,
                  endPageNumber,
                  currentPageNumber,
                  onSelect,
                )}
                <span className={styles.threeDots}>...</span>
                {printPageNumberRange(
                  totalPageCount,
                  totalPageCount,
                  currentPageNumber,
                  onSelect,
                )}
                {printArrow(
                  "right",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
              </>
            )
          } else if (currentPageNumber >= totalPageCount - 2) {
            const startPageNumber =
              currentPageNumber === totalPageCount - 2
                ? totalPageCount - 3
                : totalPageCount - 2
            return (
              <>
                {printArrow(
                  "left",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
                {printPageNumberRange(1, 1, currentPageNumber, onSelect)}
                <span className={styles.threeDots}>...</span>
                {printPageNumberRange(
                  startPageNumber,
                  totalPageCount,
                  currentPageNumber,
                  onSelect,
                )}
                {printArrow(
                  "right",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
              </>
            )
          } else {
            return (
              <>
                {printArrow(
                  "left",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
                {printPageNumberRange(1, 1, currentPageNumber, onSelect)}
                <span className={styles.threeDots}>...</span>
                {printPageNumberRange(
                  currentPageNumber - 1,
                  currentPageNumber + 1,
                  currentPageNumber,
                  onSelect,
                )}
                <span className={styles.threeDots}>...</span>
                {printPageNumberRange(
                  totalPageCount,
                  totalPageCount,
                  currentPageNumber,
                  onSelect,
                )}
                {printArrow(
                  "right",
                  currentPageNumber,
                  totalPageCount,
                  onSelect,
                )}
              </>
            )
          }
        }
      })()}
    </span>
  )
}
Pagination.propTypes = {
  classes: PropTypes.any,
  /** Number of current page */
  currentPageNumber: PropTypes.number.isRequired,
  /** Number of page count */
  totalPageCount: PropTypes.number.isRequired,
  /** Callback gets number of selected page as argument */
  onSelect: PropTypes.func.isRequired,
}

export default Pagination
