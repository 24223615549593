import CopyToClipboard from "../components/CopyToClipboard"
import React from "react"

export const convertCamelCaseToTitle = (string) => {
  if (string === "company_culture") return "Company Culture & Vision"
  if (string === "location") return "Location Address"
  if (string === "compensation") return "Compensation/Budget"
  if (string === "capabilities") return "Capabilities/Experise"
  //enter input_text_text -> Input Text Text
  string = string.split("_")
  string = string.map((word) => {
    return `${word[0].toUpperCase()}${word.slice(1)}`
  })
  return string.join(" ")
}

export const makeCutDottedString = (string, length) =>
  string.length > length ? `${string.slice(0, length)}...` : string

export function stripHtml(html) {
  let temporalDivElement = document.createElement("div")
  temporalDivElement.innerHTML = html
  return temporalDivElement.textContent || temporalDivElement.innerText || ""
}
//
export function opportunutiOptimizeText(text, maxLength) {
  if (text) {
    // wtf
    let string =
      text.length && JSON.parse(text).blocks.map((block) => block.text)
    string = string.splice(0, 3)
    return string.join(" ").length > maxLength
      ? `${string.join(" ").slice(0, maxLength)}...`
      : string.join(" ")
  }
}

export const getContact = (string) => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const phoneRegex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

  return string.split(" ").map((word) => {
    if (
      emailRegex.test(String(word).toLowerCase()) ||
      phoneRegex.test(String(word))
    ) {
      return (
        <span key={new Date().getMilliseconds()}>
          {" "}
          <CopyToClipboard text={word} />{" "}
        </span>
      )
    } else {
      return <span> {word} </span>
    }
  })
}

export function dhm(ms) {
  let days = Math.floor(ms / (24 * 60 * 60 * 1000))
  let daysms = ms % (24 * 60 * 60 * 1000)
  let hours = Math.floor(daysms / (60 * 60 * 1000))
  let hoursms = ms % (60 * 60 * 1000)
  let minutes = Math.floor(hoursms / (60 * 1000))
  let minutesms = ms % (60 * 1000)
  let sec = Math.floor(minutesms / 1000)
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    sec: sec,
  }
}

export const thousandToK = (number) => {
  if (number > 999) {
    return number.toString().slice(0, -3) + " k"
  }

  return number
}
