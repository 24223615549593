import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { useParams, Redirect, useHistory } from "react-router-dom"
import Box from "@material-ui/core/Box"

import styles from "./SelectedCompanyProfileContainer.module.scss"
import PhotoGalleryWrap from "../../components/PhotoGallery/PhotoGalleryWrap"
import VideosPreviewer from "../../components/Videos/VideosPreviewer"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import services from "../../services"
import CompanyUserInfo from "../../components/CompanyUserInfo/CompanyUserInfo"
import CompanyDepartments from "../../components/CompanyDepartments/CompanyDepartments"
import CompanyOpportunities from "../../components/CompanyOpportunities/CompanyOpportunities"
import { paths } from "../../routes/paths"
import UserResponseCard from "../../components/UserResponseCard/UserResponseCard"
import CompanyResources from "../../components/CompanyResources"
import ProfileMyQuestions from "../../components/ProfileMyQuestions"
import useBreadcrumbs from "./useBreadcrumbs"
import {ADMIN, INDIVIDUAL, COMPANY_SEEKER, COMPANY_PROVIDER} from "../../helpers/userRoles"
import Alert from "@material-ui/lab/Alert"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

const SelectedCompanyProfileContainer = ({ from }) => {
  const [data, setData] = useState({})
  const { width } = useWindowHeightWidth()

  const userInfo = useSelector((state) => state.user)
  const params = useParams()
  const history = useHistory()
  const breadcurmbsPaths = useBreadcrumbs(data)

  const [reportAlertVisible, setReportAlertVisible] = useState(false)

  const isHaveChatButton = (userInfo.role !== INDIVIDUAL) && (userInfo.company.type !== COMPANY_SEEKER)
  const isOpportunityBlockVisible = data?.company_type === COMPANY_PROVIDER

  useEffect(() => {
    services.companyServices.getCompanyInfo(params.uuid).then((result) => {
      let res = {
        ...result.data,
        opportunities: result.data.opportunities.sort((a, b) => b.id - a.id),
      }
      setData(res)
    })
  }, [params.uuid])

  const onOpenChat = () => {
    if (!data.chat) {
      services.chatServices
        .createChatRoom({ type: "company", uuid: params.uuid })
        .then((result) => history.push(paths.chats(result.data.uuid)))
        .catch((error) => console.log(error))
    } else {
      history.push(paths.chats(data.chat))
    }
  }

  const onBlock = (status) => {
    services.reportServices
      .blockCompany({ company_uuid: data.uuid, block: status })
      .then(() => {
        setData({ ...data, blocked: status })
      })
      .catch(console.log)
  }

  const onReport = ({ title, description }) => {
    return services.reportServices
      .reportUser({
        title,
        description,
        reported_id: params.uuid,
        reported_type: "company",
      })
      .then((res) => {
        setReportAlertVisible(true)
        setData({ ...data, reported: true })
        setTimeout(() => setReportAlertVisible(false), 5000)
        return res
      })
  }

  const headBtnWidth = width <= 360 ? "62px" : "182px"

  if (userInfo.company.uuid === params.uuid) {
    return <Redirect to={paths.companyProfile} />
  }

  if (data?.blocked && userInfo.role !== ADMIN) {
    return <Redirect to={paths.notFound} />
  }

  return (
    <div className={classNames("container", "fullWidth", styles.profileWrap)}>
      {userInfo.role !== ADMIN && (
        <Breadcrumbs classes={styles.breadcrumbs} chain={breadcurmbsPaths} />
      )}

      {reportAlertVisible && (
        <Alert
          variant="filled"
          severity="info"
          className={styles.alert}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setReportAlertVisible(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Your report was successfully sent.
        </Alert>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        style={{ width: "100%" }}
      >
        <div className={styles.profileLeftColumn}>
          <div className={styles.companyInfo}>
            <CompanyUserInfo
              data-debug1={userInfo.company.type}
              data-debug2={userInfo}
              data-debug3={userInfo.company.type !== COMPANY_SEEKER}
              activity={data.activity}
              describeMe={data.describe_company}
              descriptionProp={data.description}
              industr={data.industry}
              name={data.name}
              companyType={data.company_type}
              levelType={data.level_type}
              image={data.photo}
              diverse={data.diverse}
              markets={data.markets}
              certified={data.certified}
              classifications={data.classifications}
              certifications={data.certifications}
              anotherUser={true}
              onOpenChat={onOpenChat}
              isHaveChatButton={isHaveChatButton}
              onReport={onReport}
              reported={data.reported}
              userRole={userInfo.role}
              isBlocked={data.blocked}
              onBlock={onBlock}
            />
          </div>
          <PhotoGalleryWrap
            className={classNames(styles.galleryWrap, styles.tablet)}
            userId={params.uuid}
            type="preview"
            data={data["photo_gallery"]}
            editable={false}
            userType="companies"
          />
          <UserResponseCard
            data={data.related_prospects}
            userInfo={{ uuid: data.uuid, name: data.name, type: "company" }}
          />
          <VideosPreviewer
            className={styles.videosWrap}
            videos={data.video_gallery}
            anotherUser={true}
            userId={params.uuid}
            userType="companies"
          />
        </div>
        <div className={styles.profileRightColumn}>
          <PhotoGalleryWrap
            className={classNames(styles.galleryWrap)}
            userId={params.uuid}
            type="preview"
            data={data["photo_gallery"]}
            editable={false}
            userType="companies"
          />
          <div className={styles.departmentsContainer}>
            <CompanyDepartments
              data={data.departments}
              headBtnWidth={headBtnWidth}
              width={width}
              anotherUser
              uuid={params.uuid}
            />
            {isOpportunityBlockVisible && (
                <CompanyOpportunities
                    data={data}
                    headBtnWidth={headBtnWidth}
                    width={width}
                    anotherUser
                    uuid={params.uuid}
                />
            )}
            {isOpportunityBlockVisible && (
                <CompanyOpportunities
                    data={data}
                    headBtnWidth={headBtnWidth}
                    width={width}
                    anotherUser
                    uuid={params.uuid}
                    isPrivate={true}
                />
            )}

            <CompanyResources
              data={data.company_resources}
              userInfo={{
                company: {
                  type: data.company_type,
                  uuid: data.uuid,
                },
              }}
              width={width}
              anotherUser
            />

            <ProfileMyQuestions
              data={data.community_topics}
              width={width}
              anotherUser
              uuid={data.uuid}
            />
          </div>
        </div>
      </Box>
    </div>
  )
}

export default SelectedCompanyProfileContainer
