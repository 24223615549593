import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./CompanyInfoItem.module.scss"
import ButtonComponent from "../Button/Button"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const CompanyInfoItem = ({
  headTitle,
  headBtnText,
  bodyPlaceholderText,
  handleButtonClick,
  children,
  width,
  anotherUser,
  disabled,
  tooltipText,
}) => {
  const btnWidth = width < 451 ? "62px" : "210px"
  return (
    <div
      className={classNames(styles.departmentItem, {
        [styles.withData]: !bodyPlaceholderText,
      })}
    >
      <div className={styles.departmentHead}>
        <h5 className={styles.departmentHeadTitle}>{headTitle}</h5>
        {!anotherUser && (
          <CustomTooltip
            text={tooltipText}
            children={
              <span className={styles.tooltipWrapper}>
                <ButtonComponent
                  disabled={disabled}
                  className={classNames(styles.departmentHeadBtn, "text16r")}
                  width={btnWidth}
                  onClick={handleButtonClick}
                  height="40px"
                >
                  {headBtnText}
                </ButtonComponent>
              </span>
            }
          />
        )}
      </div>
      <div className={styles.departmentBody}>
        {bodyPlaceholderText && (
          <p
            className={classNames(styles.departmentBodyPlaceholder, "text16r")}
          >
            {bodyPlaceholderText}
          </p>
        )}
        {children}
      </div>
    </div>
  )
}

CompanyInfoItem.propTypes = {
  /** Title of the department item */
  headTitle: PropTypes.string.isRequired,
  /** Add button text */
  headBtnText: PropTypes.string.isRequired,
  bodyPlaceholderText: PropTypes.string,
  /** Add button width */
  headBtnWidth: PropTypes.string,
  /** Disabled input  */
  disabled: PropTypes.bool,
  /** Tooltip on button hover */
  tooltipText: PropTypes.string,
}

export default CompanyInfoItem
