import React, { useState } from "react"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import P from "prop-types"
import { Link } from "react-router-dom"
import classNames from "classnames"

import avatar from "../../../../images/defaultUserAvatar.png"
import styles from "./OpportunityHeader.module.scss"
import { ReactComponent as Heart } from "../../../../images/icons/heartEmpty.svg"
import { ReactComponent as HeartFilledIcon } from "../../../../images/icons/heart-filled.svg"
import ThreeDotsMenu from "../../../ThreeDotsMenu/ThreeDotsMenu"
import ButtonComponent from "../../../Button/Button"
import Popup from "../../../Popup/Popup"
import Button from "../../../Button/Button"
import CustomTooltip from "../../../CompanyUserInfo/CustomTooltip"

const OpportunityHeader = ({
  onEdit,
  isFavorite,
  onLikeClick,
  isEditable,
  setDeletePopupOpened,
  data,
  hasApplyButton,
  applyStatus,
  handleApplyOpportunity,
  handleRefuseOpportunity,
}) => {
  const menu = [
    {
      name: "Edit",
      click: () => {
        onEdit()
      },
    },
    {
      name: "Delete",
      click: () => {
        setDeletePopupOpened(true)
      },
    },
  ]

  const onDecline = () => {
    handleRefuseOpportunity()
    setDeclinePopupOpened(false)
  }

  const [declinePopupOpened, setDeclinePopupOpened] = useState(false)

  return (
    <Grid
      component="div"
      container
      className={classNames(
        styles.wrapper,
        hasApplyButton && styles.wrapperWithApply,
      )}
    >
      {declinePopupOpened && (
        <Popup
          dialog
          subtitle={"Are you sure you want to decline your application?"}
          button={
            <Button buttonStyle="black" onClick={onDecline}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              onClick={() => setDeclinePopupOpened(false)}
              buttonStyle="outlinedBlack"
            >
              No
            </Button>
          }
          closeAction={() => setDeclinePopupOpened(false)}
          isCrossIcon={true}
        />
      )}
      <Grid
        component="div"
        item
        xl={hasApplyButton ? 10 : 11}
        xs={hasApplyButton ? 12 : 11}
        lg={hasApplyButton ? 9 : 11}
        md={hasApplyButton ? 8 : 11}
        sm={hasApplyButton ? 12 : 11}
        className={styles.textWrapper}
      >
        <Grid component="div" container alignItems="center" wrap="nowrap">
          <Grid
            item
            component="div"
            xl={2}
            xs={3}
            lg={2}
            md={3}
            sm={4}
            className={styles.avatarWrap}
          >
            <img
              src={data?.company?.photo || avatar}
              alt="avatar"
              width="64px"
              className={styles.avatar}
            />
          </Grid>
          {/*//sm width-83% flex-basis-83%*/}
          <Grid
            item
            component="div"
            xl={10}
            xs={9}
            lg={10}
            md={9}
            sm={8}
            className={styles.headerWrapper}
          >
            <Grid
              component="div"
              container
              direction="column"
              className={styles.header}
            >
              <Grid component="div" item className={styles.titleWrap}>
                <Typography
                  variant="h6"
                  component="h6"
                  className={styles.headerTitle}
                >
                  {data["name"] || ""}

                  <div
                    className={classNames(
                      "iconWrap",
                      hasApplyButton
                        ? styles.headerIconWithApply
                        : styles.headerIcon,
                    )}
                    onClick={onLikeClick}
                  >
                    {isFavorite ? (
                      <HeartFilledIcon className={"iconWrap"} />
                    ) : (
                      <Heart className={"iconWrap"} />
                    )}
                  </div>
                </Typography>
              </Grid>
              <Grid component="div" item>
                <Typography
                  variant="h6"
                  component="h6"
                  className={styles.headerSubTitle}
                >
                  <Link
                    to={`/company/${data?.company?.uuid}`}
                    className={styles.companyName}
                  >
                    {data.company_name || ""}
                  </Link>
                  <span className={styles.location}>
                    {data["location"] || ""}
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        component="div"
        container
        item
        xl={2}
        xs={1}
        lg={hasApplyButton ? 3 : 1}
        md={hasApplyButton ? 3 : 1}
        sm={1}
        justify="flex-end"
        alignItems="center"
        className={hasApplyButton ? styles.buttonWrapApply : styles.buttonWrap}
      >
        {hasApplyButton && (
          <>
            <CustomTooltip
              text={
                applyStatus === "rejected" &&
                "You will be able to apply again in 1 day"
              }
            >
              <div className={styles.btnWrapper}>
                <ButtonComponent
                  buttonStyle={
                    applyStatus !== "applied" ? "contained" : "outlined"
                  }
                  className={styles.applyButton}
                  disabled={applyStatus === "rejected"}
                  onClick={
                    applyStatus === "applied"
                      ? () => setDeclinePopupOpened(true)
                      : handleApplyOpportunity
                  }
                >
                  {applyStatus === "applied" ? "Decline" : "Apply"}
                </ButtonComponent>
              </div>
            </CustomTooltip>

            {applyStatus === "applied" && (
              <p className={styles.applyButtonText}>
                You have already responded
              </p>
            )}
          </>
        )}
        {isEditable && (
          <div className={styles.threeDots}>
            <ThreeDotsMenu actions={menu} />
          </div>
        )}
      </Grid>
    </Grid>
  )
}

OpportunityHeader.propTypes = {
  data: P.object,
  buttonText: P.string.isRequired,
  onButtonClick: P.func.isRequired,
  isFavorite: P.bool,
  onLikeClick: P.func,
  hasApplyButton: P.bool,
  isAplied: P.bool,
  handleApplyOpportunity: P.func,
  handleRefuseOpportunity: P.func,
}

OpportunityHeader.defaultProps = {
  photo: avatar,
  onButtonClick: () => {
    console.log("Button click")
  },
  onLikeClick: () => {
    console.log("Like Click")
  },
}

export default OpportunityHeader
