import React from "react"
import { useHistory, Prompt } from "react-router-dom"
import { messages } from "../../helpers/constants"
import SearchFormWrapper from "./wrappers/SearchFormWrapper"
import CaptionWrapper from "./wrappers/CaptionWrapper"
import TagWrapper from "./wrappers/TagWrapper"
import MainWrapper from "./wrappers/MainWrapper"
import InstituteTypeFilter from "../../components/filters/InstituteTypeFilter"
import InstituteSearchFilter from "../../components/filters/InstituteSearchFilter"
import InstituteArticleMiniCard from "../../components/cards/InstituteArticleMiniCard"
import useSearch from "./useSearch"
import {
  setTabFilter,
  setFormFilters,
  resetFormFilters,
} from "./useSearch/actionCreators"
import { paths } from "../../routes/paths"
import styles from "./index.module.scss"

const InstituteSearchContainer = () => {
  const history = useHistory()
  const [state, dispatch] = useSearch()
  const { type, itemList } = state.sideArticleGroup
  const { requestResultGroup, searchParamGroup } = state
  return (
    <div className={styles.searchContainer}>
      <SearchFormWrapper state={state} dispatch={dispatch} />
      <CaptionWrapper state={state} />
      <div className={styles.container}>
        <InstituteTypeFilter
          classes={styles.tabFilter}
          requestResultGroup={requestResultGroup}
          onSelectType={(articleType) => dispatch(setTabFilter(articleType))}
        />
        <div className={styles.body}>
          <main>
            <TagWrapper
              classes={styles.tagBlock}
              requestResultGroup={requestResultGroup}
              dispatch={dispatch}
            />
            <MainWrapper state={state} dispatch={dispatch} />
          </main>
          <aside>
            <div className={styles.sideBar}>
              <InstituteSearchFilter
                requestResultGroup={requestResultGroup}
                onReset={() => dispatch(resetFormFilters())}
                onSubmit={(values) => dispatch(setFormFilters(values))}
              />
              {itemList.length > 0 && (
                <div className={styles.block}>
                  <p className={styles.caption}>
                    {type === "recent" ? "Recent Topics" : "Popular Topics"}
                  </p>
                  {itemList.map((item) => (
                    <InstituteArticleMiniCard
                      key={item.id}
                      classes={styles.card}
                      title={item.title}
                      description={item.content}
                      photoUrl={item.image_url}
                      onClick={() => {
                        history.push(paths.specificInstituteArticle(item.uuid))
                      }}
                    />
                  ))}
                </div>
              )}
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default InstituteSearchContainer
