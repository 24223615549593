import React from "react"

import styles from "./index.module.scss"
import ReportContainer from "../../containers/ReportContainer/ReportContainer"

const ReportPage = () => {
  return (
    <div className={styles.pageWrap}>
      <ReportContainer />
    </div>
  )
}

export default ReportPage
