import {
  TOGGLE_COLLAPSE,
  SET_WHOLE_STATE,
  SET_ARTICLE_TYPE,
  SET_FROM_DATE,
  SET_TO_DATE,
  RESET_FORM,
} from "./reducer"

export const toggleCollapse = () => ({
  type: TOGGLE_COLLAPSE,
  payload: null,
})
export const setWholeState = (requestResultGroup) => ({
  type: SET_WHOLE_STATE,
  payload: { requestResultGroup },
})
export const setArticleType = (articleType) => ({
  type: SET_ARTICLE_TYPE,
  payload: { articleType },
})
export const setFromDate = (fromDate) => ({
  type: SET_FROM_DATE,
  payload: { fromDate },
})
export const setToDate = (toDate) => ({
  type: SET_TO_DATE,
  payload: { toDate },
})
export const resetForm = () => ({
  type: RESET_FORM,
  payload: null,
})
