export const scrollOnError = (errors) => {
  try {
    setTimeout(() => {
      let errored

      errored = document.querySelectorAll("*")
      errored = [].slice.call(errored).filter(function (item) {
        if (item.classList)
          return (
            [].slice.call(item.classList).filter(function (item) {
              return item.indexOf("error") !== -1
            }).length !== 0
          )
        return false
      })
      errored[0] && errored[0].scrollIntoView(false)
    })
  } catch (e) {
    console.log(e)
  }
}

export const types = [
  {
    id: "01",
    value: "Peer Circles",
    dbName: "peer_circles",
    tooltip: "Connect with B2T members to join your peer circle",
  },
  {
    id: "02",
    value: "Group Chats",
    dbName: "group_chats",
    tooltip:
      "Connect with other members in the B2T community by sharing ideas, asking and answering questions.",
  },
  {
    id: "03",
    value: "Referrals",
    dbName: "referrals",
    tooltip: "Refer a great company for business, projects or personal use.",
  },
  {
    id: "04",
    value: "Let’s Connect",
    dbName: "the_vent",
    tooltip:
      "Your B2T community is here to help you navigate professional pain points and challenges.",
  },
  {
    id: "05",
    value: "Network",
    dbName: "network",
    tooltip:
      "Network with the best talent, companies, opportunities and projects.",
  },
]
