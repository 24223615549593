import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useLocation, useHistory } from "react-router"
import queryString from "query-string"
import classNames from "classnames"
import Box from "@material-ui/core/Box"
import styles from "./CompanyProfileContainer.module.scss"
import PhotoGalleryWrap from "../../components/PhotoGallery/PhotoGalleryWrap"
import VideosPreviewer from "../../components/Videos/VideosPreviewer"
import AddProfilePhotoPopup from "../../components/AddProfilePhotoPopup/AddProfilePhotoPopup"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import {
  userDeletesCompanyAvatar,
  userChangeCompanyLogo,
  userChangeFullName,
} from "../../actions/user/userActions"
import services from "../../services"
import { paths } from "../../routes/paths"
import ButtonComponent from "../../components/Button/Button"
import CompanyUserInfo from "../../components/CompanyUserInfo/CompanyUserInfo"
import CompanyDepartments from "../../components/CompanyDepartments/CompanyDepartments"
import CompanyOpportunities from "../../components/CompanyOpportunities/CompanyOpportunities"
import CompanyResources from "../../components/CompanyResources"
import ProfileMyQuestions from "../../components/ProfileMyQuestions"
import {COMPANY_EMPLOYEE, COMPANY_SEEKER} from "../../helpers/userRoles"
import CompanyEmployUserInfo from "../../components/CompanyUserInfo/CompanyEmployUserInfo"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"
import ResetPasswordError from "../../components/ResetPasswordError/ResetPasswordError"
import companyService from "../../services/copmanyServices"

const CompanyProfileContainer = () => {
  const [showPhotoPopup, setShowPhotoPopup] = useState(false)
  const [data, setData] = useState({})
  const { width } = useWindowHeightWidth()
  const location = useLocation()
  const history = useHistory()
  const { msg: checkoutMsg } = queryString.parse(location.search)

  const userInfo = useSelector((state) => state.user)
  const isEmploy = useSelector((state) => state.user).role === COMPANY_EMPLOYEE

  const dispatcher = useDispatch()
  useEffect(() => {
    services.companyServices.getSelfCompanyInfo().then((result) => {
      let res = {
        ...result.data,
        opportunities: result.data.opportunities.sort((a, b) => b.id - a.id),
      }
      setData(res)
      dispatcher(userChangeCompanyLogo(result.data.photo || ""))
    })
  }, [dispatcher])

  const updateCompanyUser = (params) =>
    services.companyServices
      .updateCompanyUser(params)
      .then((result) => setData({ ...data, ...result.data }))

  const updateEmployUser = (params) =>
    services.userServices.updateUsers(params).then((result) => {
      dispatcher(userChangeFullName(result.data.full_name))
    })

  const onSaveProfilePhoto = ({
    file,
    croppedImage,
    cropData: { x, y, height, width },
  }) => {
    const photoData = {
      photo: croppedImage,
      origin_photo: file,
      x,
      y,
      height,
      width,
    }

    services.companyServices
      .setCompanyLogo(photoData)
      .then((result) => {
        setData({ ...data, ...result.data })
        dispatcher(userChangeCompanyLogo(result.data.photo))
      })
      .catch((error) => console.log(error))
  }

  const onDeletePhotoProfile = () => {
    dispatcher(userDeletesCompanyAvatar())
    setData({
      ...data,
      x: null,
      y: null,
      width: null,
      height: null,
      photo: null,
      origin_photo: null,
    })
  }

  const headBtnWidth = width <= 360 ? "62px" : "182px"

  const saveNewDeparment = async ({ departmentName, employees }) => {
    try {
      const formattedData = {
        name: departmentName,
        employees_count: employees,
      }
      const result = await services.departmentsServices.createDepartment(
        userInfo.company.uuid,
        formattedData,
      )
      setData({ ...data, departments: [result.data, ...data.departments] })
    } catch (e) {
      throw e
    }
  }

  const onAddPhotos = (photos) => {
    const newPhotos = [...data.photo_gallery, ...photos]
    setData({ ...data, photo_gallery: newPhotos })
  }

  const onDeletePhoto = (uuid) => {
    let newPhotos = [...data.photo_gallery]
    newPhotos = newPhotos.filter((photo) => photo.uuid !== uuid)
    setData({ ...data, photo_gallery: newPhotos })
  }

  const onDeleteVideo = (uuid) => {
    let newVideos = [...data.video_gallery]
    services.userServices.deleteVideo(uuid).then(() => {
      newVideos = newVideos.filter((video) => video.uuid !== uuid)
      setData({ ...data, video_gallery: newVideos })
    })
  }

  const onAddVideo = (videos) => {
    const newVideos = [...data.video_gallery, ...videos]
    setData({ ...data, video_gallery: newVideos })
  }

  const onClictToBuySubscription = () =>
    companyService.getPaymentUrl().then((url) => window.open(url, "_blank"))

  if (!data?.company_type) {
    return null
  }

  if (data?.blocked) {
    return (
      <XYCenterAlignedWindow
        children={
          <ResetPasswordError
            errorMessage={"Please contact support for the solution."}
            title={
              "Your profile has been turned down because of inappropriate activity."
            }
          />
        }
      />
    )
  }

  if (data)
    return (
      <>
        <div
          className={classNames(
            "container",
            "fullWidth",
            styles.subscriptionBox,
          )}
        >
          {(() => {
            if (checkoutMsg === "checkout.success") {
              return (
                <p className={styles.subscriptionMsg}>
                  Thank you for purchasing a subscription!
                  <ButtonComponent
                    style={{ position: "absolute", top: "18px", right: "24px" }}
                    onClick={() => history.replace(paths.companyProfile)}
                    height="40px"
                  >
                    Close
                  </ButtonComponent>
                </p>
              )
            } else if (
              !data.extended_trial &&
              Boolean(data.basic_trial) &&
              !data.has_paid_subscription
            ) {
              return (
                <p className={styles.trialText}>
                  You have {parseInt(data.remaining_days_on_trial)} days more on
                  your trial.
                  <span onClick={onClictToBuySubscription}>
                    Purchase a subscription now
                  </span>
                </p>
              )
            } else if (
              !data.extended_trial &&
              !data.basic_trial &&
              !data.has_paid_subscription
            ) {
              return (
                <p className={styles.trialText}>
                  Your trial period expired.
                  <span onClick={onClictToBuySubscription}>
                    Purchase a subscription now
                  </span>
                </p>
              )
            } else {
              return null
            }
          })()}
        </div>
        <div
          className={classNames("container", "fullWidth", styles.profileWrap)}
        >
          {showPhotoPopup && (
            <AddProfilePhotoPopup
              onClose={() => {
                setShowPhotoPopup(false)
              }}
              onSave={onSaveProfilePhoto}
              url={data.origin_photo}
              currentData={{
                x: data.x,
                y: data.y,
                width: data.width,
                height: data.height,
              }}
            />
          )}
          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            style={{ width: "100%" }}
          >
            <div className={styles.profileLeftColumn}>
              {isEmploy ? (
                <CompanyEmployUserInfo
                  activity={data.activity}
                  describeMe={data.describe_company}
                  descriptionProp={data.description}
                  employName={userInfo.fullName}
                  name={userInfo.company.name}
                  companyType={data.company_type}
                  image={userInfo.company.photo}
                  openPhotoPopup={() => {
                    setShowPhotoPopup(true)
                  }}
                  onDeletePhotoProfile={onDeletePhotoProfile}
                  updateCompanyUser={updateEmployUser}
                  industr={data.industry}
                  isEmployee={isEmploy}
                />
              ) : (
                <CompanyUserInfo
                  activity={data.activity}
                  describeMe={data.describe_company}
                  descriptionProp={data.description}
                  markets={data.markets}
                  classifications={data.classifications}
                  certified={data.certified}
                  certifications={data.certifications}
                  industr={data.industry}
                  diverse={data.diverse}
                  name={data.name}
                  companyType={data.company_type}
                  levelType={data.level_type}
                  image={userInfo.company.photo}
                  openPhotoPopup={() => {
                    setShowPhotoPopup(true)
                  }}
                  onDeletePhotoProfile={onDeletePhotoProfile}
                  updateCompanyUser={updateCompanyUser}
                />
              )}
              <PhotoGalleryWrap
                className={classNames(styles.galleryWrap, styles.tablet)}
                type="preview"
                data={data.photo_gallery}
                onAddPhotos={onAddPhotos}
                onDeletePhoto={onDeletePhoto}
              />
              <VideosPreviewer
                className={styles.videosWrap}
                videos={data.video_gallery}
                onDeleteVideo={onDeleteVideo}
                onAddVideo={onAddVideo}
                anotherUser={false}
                userType="companies"
              />
            </div>
            <div className={styles.profileRightColumn}>
              <PhotoGalleryWrap
                className={styles.galleryWrap}
                type="preview"
                data={data.photo_gallery}
                onAddPhotos={onAddPhotos}
                onDeletePhoto={onDeletePhoto}
              />

              <div className={styles.departmentsContainer}>
                  {data?.solopreneur !== true &&
                      <CompanyDepartments
                          data={data.departments}
                          saveNewDeparment={saveNewDeparment}
                          headBtnWidth={headBtnWidth}
                          width={width}
                          uuid={userInfo.company.uuid}
                      />
                  }
                  {userInfo?.company?.type !== COMPANY_SEEKER &&
                      <CompanyOpportunities
                      data={data}
                      headBtnWidth={headBtnWidth}
                      width={width}
                      uuid={userInfo.company.uuid}
                      isPrivate={false}
                  />}
                  {userInfo?.company?.type !== COMPANY_SEEKER &&
                    <CompanyOpportunities
                        data={data}
                        headBtnWidth={headBtnWidth}
                        width={width}
                        uuid={userInfo.company.uuid}
                        isPrivate={true}
                    />}
                <CompanyResources
                  data={data.company_resources}
                  userInfo={userInfo}
                  width={width}
                />

                <ProfileMyQuestions
                  data={data.community_topics}
                  width={width}
                />
              </div>
            </div>
          </Box>
        </div>
      </>
    )
}

export default CompanyProfileContainer
