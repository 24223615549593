/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import styles from "./HomeContainer.module.scss"
import HomeSearchForm from "../../forms/SearchForm/HomeSearchForm"
import TopBg from "../../images/bg/b2tbg.jpg"
import { paths } from "../../routes/paths"
import TopOpportunitiesSection from "../../components/Opportunity/components/TopOpportunitiesSection/TopOpportunitiesSection"
import {
  ADMIN,
  COMPANY_PROVIDER,
  COMPANY_SEEKER,
} from "../../helpers/userRoles"
import services from "../../services/index"
import QuoteCard from "../../components/QuoteCard/QuoteCard"
import Banner from "../../components/HomePage/Banner/Banner"
import InfoSection from "../../components/HomePage/InfoSection/InfoSection"
import Resources from "../../components/HomePage/Resources/Recources"
import Institutes from "../../components/HomePage/Insitutes/Institutes"
import Community from "../../components/HomePage/Community/Community"
import Inspiration from "../../components/HomePage/Inspiration/Inspiration"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import ReportsPage from "../../pages/ReportsPage/ReportsPage"
import TopTalentSection from "../../components/TopTalentSection/TopTalentSection"

const HomeContainer = () => {
  const history = useHistory()
  const { width } = useWindowHeightWidth()
  const userInfo = useSelector((state) => state.user)
  const [resourceData, setResourceData] = useState(null)
  const [trendingTopics, setTrendingTopics] = useState(null)
  const [instituteArticles, setInstituteArticles] = useState(null)
  const [topIssues, setTopIssues] = useState(null)
  const [popularTopics, setPopularTopics] = useState(null)
  const [communityQuestions, setCommunityQuestions] = useState(null)
  const [inspiration, setInspiration] = useState(null)
  const [featuredTopics, setFeaturedTopics] = useState(null)
  //const [topTalent, setTopTalent] = useState(null)
  const [combinedArticles, setCombinedArticles] = useState({})

  let topTalentCardsCount = 1
  const isMobile = width < 768
  const resourcesCount = isMobile ? 3 : 6
  const instituteArticlesCount = isMobile ? 2 : 4
  const communityQuestionCount = 4
  const communityInspirationCount = isMobile ? 2 : 4

  if (width < 768) {
    topTalentCardsCount = 1
  } else if (width === 768) {
    topTalentCardsCount = 2
  } else if (width > 768) {
    topTalentCardsCount = 3
  }

  useEffect(() => {
    if (userInfo.role !== ADMIN) {
      services.resourceServices
        .getMainResources(null, resourcesCount)
        .then(({ data }) => {
          setResourceData(data.resources)
          setTrendingTopics(data.trending_topics)
        })
      services.articleServices
        .getMainInstituteArticle(null, instituteArticlesCount)
        .then(({ data }) => {
          setInstituteArticles(data.institute_articles)
          setTopIssues(data.top_issues)
        })

      services.communityServices
        .getMainQuestions(null, communityQuestionCount, userInfo.uuid)
        .then(({ data }) => {
          setCommunityQuestions(data.questions)
          setPopularTopics(data.popular_topics)
        })

      services.articleServices
        .getMainInspiration(null, communityInspirationCount)
        .then(({ data }) => {
          setInspiration(data.inspiration_articles)
          setFeaturedTopics(data.featured_topics)
        })

      services.articleServices.getHomePageArticles().then(({ data }) => {
        setCombinedArticles(data)
      })

/*      services.communityServices
        .getTopTalent(topTalentCardsCount)
        .then(({ data }) => {
          setTopTalent(data)
        })*/
    }
  }, [])

  const handleChangeResourceType = (type) => {
    services.resourceServices
      .getMainResources(type, resourcesCount)
      .then(({ data }) => {
        setResourceData(data.resources)
        setTrendingTopics(data.trending_topics)
      })
  }

  const handleChangeInstituteType = (type) => {
    services.articleServices
      .getMainInstituteArticle(type, instituteArticlesCount)
      .then(({ data }) => {
        setInstituteArticles(data.institute_articles)
        setTopIssues(data.top_issues)
      })
  }

  const handleChangeCommunityType = (type) => {
    services.communityServices
      .getMainQuestions(type, instituteArticlesCount)
      .then(({ data }) => {
        setCommunityQuestions(data.questions)
        setPopularTopics(data.popular_topics)
      })
  }

  const handleChangeInspirationType = (type) => {
    services.articleServices
      .getMainInspiration(type, communityInspirationCount)
      .then(({ data }) => {
        setInspiration(data.inspiration_articles)
        setFeaturedTopics(data.featured_topics)
      })
  }

  const onSearch = ({ type, query }) => {
    switch (type) {
      case "Opportunities":
        return history.push(paths.opportunitySearch({ query }))
      case "Resources":
        return history.push(paths.resourceSearch({ query }))
      case "Companies":
        return history.push(
          paths.memberSearch({ type: "companies", query }),
        )
      case "Freelancers":
        return history.push(
            paths.memberSearch({ account_type: "freelancers", query }),
        )
      case "Companies/Orgs.":
        return history.push(
          paths.memberSearch({ account_type: "company", query }),
        )
      case "Solopreneurs":
        return history.push(
            paths.memberSearch({ account_type: "company", query, solopreneur: true }),
        )
      case "Professionals":
        return history.push(
          paths.memberSearch({ account_type: "professional", query }),
        )
      case "Community":
        return history.push(paths.questionSearch({ query }))
      case "Institute":
        return history.push(paths.instituteSearch({ query }))
      case "Inspiration":
        return history.push(paths.inspirationSearch({ query }))
      default:
        console.error("Unknown search type")
    }
  }

  if (userInfo.role === ADMIN) return <ReportsPage />

  return (
    <div className={styles.homeWrap}>
      <section
        className={styles.topSection}
        style={{ backgroundImage: `url(${TopBg})` }}
      >
        <div className={classNames("smallContainer", styles.inlineWrap)}>
          <h1 className={styles.title}>
            Bridging the Digital Divide Through Global Intellect and
            Inclusiveness
          </h1>
          <HomeSearchForm onSubmit={onSearch} />
        </div>
      </section>
      <QuoteCard />
      <Banner
        onReadMoreClick={() => {
          const uuid = combinedArticles.inspiration_articles?.[3].uuid
          history.push(paths.specificInspirationArticle(uuid))
        }}
        article={combinedArticles.inspiration_articles?.[3]}
      />

      {userInfo.company.type !== COMPANY_PROVIDER && (
        <TopOpportunitiesSection
          user={userInfo}
          article={combinedArticles.institute_articles?.[2]}
          companyInfo={userInfo.company}
        />
      )}

      {/*{(userInfo.company.type === COMPANY_PROVIDER ||*/}
      {/*  userInfo.company.type === COMPANY_SEEKER) && (*/}
      {/*  <TopTalentSection user={userInfo} data={topTalent} />*/}
      {/*)}*/}

      <div className={styles.communityWrapper}>
        <InfoSection
          articles={combinedArticles.inspiration_articles?.slice(4, 6)}
        />

        <Resources
          resources={resourceData}
          trending={trendingTopics}
          handleChangeResourceType={handleChangeResourceType}
          companyInfo={userInfo.company}
        />

        <Institutes
          instituteArticles={instituteArticles}
          topIssues={topIssues}
          handleChangeInstituteType={handleChangeInstituteType}
        />

        <Community
          popularTopics={popularTopics}
          communityQuestions={communityQuestions}
          handleChangeCommunityType={handleChangeCommunityType}
        />

        <Inspiration
          inspiration={inspiration}
          featuredTopics={featuredTopics}
          handleChangeInspirationType={handleChangeInspirationType}
        />
      </div>
    </div>
  )
}

export default HomeContainer
