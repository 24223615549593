export const requiredExperienceOption = [
  {
    id: "01",
    value: "No experience",
  },
  {
    id: "02",
    value: "0 - 1 years",
  },
  {
    id: "03",
    value: "1 - 3 years",
  },
  {
    id: "04",
    value: "3 - 5 years",
  },
  {
    id: "05",
    value: "5 - 10 years",
  },
  {
    id: "06",
    value: "More than 10 years",
  },
]

export const workEnvironmentOptions = [
  {
    id: "01",
    value: "Office",
  },
  {
    id: "02",
    value: "Remote",
  },
  {
    id: "03",
    value: "Hybrid",
  },
]

export const fundingTypeOptions = [
  {
    id: "01",
    value: "Debt",
  },
  {
    id: "02",
    value: "Equity",
  },
  {
    id: "03",
    value: "Accounts Receivable",
  },
  {
    id: "04",
    value: "Purchase Order",
  },
  {
    id: "05",
    value: "Leasing",
  },
]

export const experienceLevelOptions = [
  {
    id: "01",
    value: "1 to 5 years",
  },
  {
    id: "02",
    value: "6 to 10 years",
  },
  {
    id: "03",
    value: "11 to 20 years",
  },
  {
    id: "05",
    value: "20+ years",
  },
]

export const mentorOptions = [
  {
    id: "01",
    value: "Individual",
  },
  {
    id: "02",
    value: "Company",
  },
]
