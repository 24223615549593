import { ReactComponent as Profile } from "../../../images/icons/profile.svg"
import { ReactComponent as Prospects } from "../../../images/icons/prospects.svg"
import { ReactComponent as Members } from "../../../images/icons/MembersIcon.svg"
import { ReactComponent as DollarFilled } from "../../../images/icons/dollar-filled.svg"
import { ReactComponent as Calendar } from "../../../images/icons/calendar.svg"
import { ReactComponent as CommentIcon } from "../../../images/icons/commentIcon.svg"
import { ReactComponent as Favorities } from "../../../images/icons/favorities.svg"
import { paths } from "../../../routes/paths"
import * as roles from "../../../helpers/userRoles"
import { COMPANY_SEEKER } from "../../../helpers/userRoles"

export const getMenuLinks = (userInfo) => {
  const menuLinks = [
    {
      path: paths[
        userInfo === roles.INDIVIDUAL ? "individualProfile" : "companyProfile"
      ],
      name: "My Profile",
      icon: Profile,
    },
    {
      path: paths.prospects,
      name: "Prospects",
      icon: Prospects,
    },
    {
      path:
        userInfo.company?.type === roles.COMPANY_PROVIDER
          ? paths.companyResources(userInfo.company.uuid)
          : "/resources/my-events",
      name:
        userInfo.company?.type === roles.COMPANY_PROVIDER
          ? "My Resources"
          : "My Events",
      icon: Calendar,
    },
    {
      path: paths.communityMyQuestions,
      name: "My Questions",
      icon: CommentIcon,
    },
    {
      path: paths.favorites,
      name: "Favorites",
      icon: Favorities,
    },
  ]

  if (
    userInfo?.company?.type === roles.COMPANY_PROVIDER ||
    userInfo?.company?.type === roles.COMPANY_SEEKER ||
    userInfo?.role === roles.COMPANY_EMPLOYEE
  ) {
    menuLinks.splice(2, 0, {
      path: paths.employees,
      name: "My Team",
      icon: Members,
    })
  }

  if (
    userInfo.role !== roles.INDIVIDUAL &&
    userInfo?.company?.type !== COMPANY_SEEKER
  ) {
    menuLinks.splice(3, 0, {
      path: paths.opportunities(userInfo.company?.uuid),
      name: "My Opportunities",
      icon: DollarFilled,
    })
    menuLinks.splice(4, 0, {
      path: paths.privateOpportunities(userInfo.company?.uuid),
      name: "My Private Opportunities",
      icon: DollarFilled,
    })
  }

  return menuLinks
}
