import React from "react"
import { NavLink } from "react-router-dom"
import classnames from "classnames"

import styles from "./nav.module.scss"
import { ReactComponent as Arrow } from "../../../images/icons/arrow-top.svg"
import { navLinks } from "./nav-links"
import { ADMIN } from "../../../helpers/userRoles"

const Nav = ({ width, userRole }) => {
  const [activeSubNav, setActiveSubNav] = React.useState(null)
  const [navMenuLinks, setNavMenuLinks] = React.useState([])

  React.useEffect(() => {
    if (width > 1360) {
      setActiveSubNav(null)
    }
  }, [width])

  React.useEffect(() => {
    setNavMenuLinks(userRole === ADMIN ? [navLinks[0]] : navLinks)
  }, [userRole])

  const toggleSubNav = (event, route) => {
    if (width <= 1360 && route.children) {
      event.preventDefault()
      setActiveSubNav(activeSubNav === route.path ? null : route.path)
    }
  }

  return (
    <div className={styles.nav}>
      {navMenuLinks.map((route, index) => (
        <div
          className={classnames(
            styles.navItem,
            activeSubNav === route.path && styles.navItemExpanded,
          )}
          key={index}
        >
          <NavLink
            to={route.path}
            className={styles.navItemLink}
            activeClassName={styles.navItemLinkActive}
            exact={route.exact}
            onClick={(e) => toggleSubNav(e, route)}
          >
            <div className={styles.navItemLinkText}>{route.name}</div>

            {route.children && (
              <div className={styles.navItemLinkIcon}>
                <Arrow />
              </div>
            )}
          </NavLink>

          {route.children && (
            <div className={styles.navItemSubNav}>
              <NavLink to={route.path} className={styles.navItemSubItem}>
                All
              </NavLink>

              {route.children.map((subRoute, index) => (
                <NavLink
                  key={index}
                  to={subRoute.path}
                  className={styles.navItemSubItem}
                >
                  {subRoute.name}
                </NavLink>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Nav
