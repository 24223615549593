import React from "react"
import styles from "./CompanyOpportunitiesPage.module.scss"
import CompanyOpportunitiesContainer from "../../containers/CompanyOpportunitiesContainer/CompanyOpportunitiesContainer"
import {useLocation} from "react-router-dom";
import queryString from "query-string";

const CompanyDepartmentsPage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)
  return (
    <div className={styles.pageWrapper}>
      <CompanyOpportunitiesContainer
          isPrivate={values?.private}
      />
    </div>
  )
}

export default CompanyDepartmentsPage
