import axios from "./axios"
import { save } from "../helpers/localStorage"

export default {
  createEmployee: async ({ email, full_name }) => {
    try {
      const result = await axios.post("/self/company/employees", {
        email,
        full_name,
      })
      return result
    } catch (e) {
      throw e
    }
  },

  getEmployees: async () => {
    try {
      const result = await axios.get("/self/company/team")
      return result
    } catch (e) {
      console.log(e)
    }
  },

  deleteEmployee: async (uuid) => {
    try {
      axios.delete(`/employees/${uuid}`)
    } catch (e) {
      console.log(e)
    }
  },

  setPassword: async (password, token) => {
    try {
      const result = await axios.post(`/reset_password`, {
        password,
        reset_password_token: token,
      })
      if (result.status === 200) {
        axios.defaults.headers[
          "Authorization"
        ] = `Bearer ${result.data.access_token}`
      }
      save("accessToken", result.data.access_token)
      return result
    } catch (e) {
      console.log(e)
    }
  },
  checkToken: async (token) => {
    try {
      const result = await axios.get(
        `/token_status?reset_password_token=${token}`,
      )

      return result
    } catch (e) {
      throw e
    }
  },
}
