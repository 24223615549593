export const generateInfo = (type, card) => {
  switch (type) {
    case "careers_executive":
      return `${card.compensation}`
    case "mentoring":
      return `${card.compensation} • ${card.duration}`
    case "project":
      return `${card.compensation}`
    case "sponsorship":
      return `${card.role}`
    case "funding":
      return `${card.compensation} • ${card.role}`
    case "board_of_directors":
      return `${card.compensation}`
    case "diverse_company":
      return `${card.compensation}`
    case "careers_professional":
      return `${card.compensation}`
    default:
      return `${card.compensation}`
  }
}
