import queryString from "query-string"

export const paths = {
  home: "/",
  login: "/login",
  notFound: "/not-found",
  resetPassword: "/reset-password",
  signUp: (type, step) => `/sign-up${type}/${step}`,
  communityGuideline: "/community-guideline",
  emailVerifyMsgPage: (token) => `/verify-email/${token}`,
  emailVerifyResend: (email) => `/verify-email-resend/${email}`,
  individualProfile: "/professional-profile",
  selectedUserProfile: "/user/:id",
  freelancerUserProfile: "/freelancer/:id",
  videoGallery: (type, id) => `/video-gallery/${type}${id}`,
  // photoGallery: "/photo-gallery/:id?",
  photoGallery: (type, id) => `/photo-gallery/${type}${id}`,
  companyProfile: "/company-profile",
  opportunityEdit: "/opportunity-edit/",
  opportunityShare: "/opportunity/:id/share",
  opportunity: "/opportunity/",
  opportunitySearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
    })
    return `/opportunity-search${search ? `?${search}` : ``}`
  },
  memberSearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
      arrayFormat: "comma",
    })
    return `/member-search${search ? `?${search}` : ``}`
  },
  resourceSearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
    })
    return `/resource-search${search ? `?${search}` : ``}`
  },
  questionSearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
    })
    return `/community-search${search ? `?${search}` : ``}`
  },
  instituteSearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
    })
    return `/institute-search${search ? `?${search}` : ``}`
  },
  inspirationSearch: (searchParamGroup) => {
    const search = queryString.stringify(searchParamGroup, {
      skipEmptyString: true,
      skipNull: true,
    })
    return `/inspiration-search${search ? `?${search}` : ``}`
  },
  opportunityPage: (uuid, from) =>
    `/opportunity?id=${uuid}${from ? `&from=${from}` : ""}`,
  departments: (uuid) => `/departments/${uuid}`,
  opportunities: (uuid) => `/opportunities/${uuid}`,
  privateOpportunities: (uuid) => `/opportunities/${uuid}?private=true`,
  companyResources: (uuid) => `/company/${uuid}/resources`,
  companyQuestions: (uuid) => `/company/${uuid}/questions`,
  myEvents: "/resources/my-events",
  resourcePage: (resourceID) => `/resources/${resourceID}`,
  resourceAttendees: (resourceID) => `/resources/${resourceID}/attendees`,
  selectedCopmanyProfile: (uuid, from) =>
    `/company/${uuid}${from ? `?from=${from}` : ""}`,
  selectedFreelancerProfile: (uuid, from) =>
    `/freelancer/${uuid}${from ? `?from=${from}` : ""}`,
  selectedIndividualProfile: (uuid) => `/user/${uuid}`,
  prospects: "/prospects",
  chats: (uuid) => `/chats/${uuid}`,
  resourcesEdit: "/resources-edit/",
  communityMyQuestions: "/community/my-questions",
  communityTopic: (uuid) => `/community/topics/${uuid}`,
  communityTopicAccept: (uuid) => `/community/topics/${uuid}/accept`,
  communityTopicDecline: (uuid) => `/community/topics/${uuid}/decline`,
  communityTopicEdit: "/community-topic-edit/",
  notifications: "/notifications",
  specificInstituteArticle: (uuid) => `/institute-article/${uuid}`,
  specificInspirationArticle: (uuid) => `/inspiration-article/${uuid}`,
  accountSettings: "/settings",
  accountSettingsToken: (token) => `/settings/${token}`,
  accountSubscription: "/subscription",
  companyProviderLanding: "/company-provider-landing",
  individualLanding: "/professional-landing",
  emailComfirmed: "/email-comfirmed",
  employees: "/employees",
  choosePassword: "/choose-password/:token",
  favorites: "/favorites",
  reports: "/reports",
  report: (uuid) => `/reports/${uuid}`,
}
