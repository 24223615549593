import React from "react"
import styles from "./NotFoundPage.module.scss"
import errorImage from "../../images/notFound/errorText.png"
import bridgeImage from "./../../images/notFound/Broken bridge.png"

const NotFoundPage = (props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>Oops...</h1>
          <h2 className={styles.subTitle}>
            It looks like this bridge is broken. <br />
            Try to reconsider your way...
          </h2>
          <img src={errorImage} className={styles.image} alt="404" />
          <img src={bridgeImage} className={styles.bridgeImage} alt="404" />
        </div>
      </div>
    </>
  )
}

export default NotFoundPage
