export const tooltips: {
  [key: string]: string
} = {
  peer_circles: "Connect or invite B2T members to join your peer circle.",
  group_chats:
    "Connect with other members in the B2T community by sharing ideas, asking and answering questions.",
  referrals: "Refer a great company for business, projects or personal use.",
  the_vent:
    "Your B2T community is here to help you navigate professional pain points and challenges.",
  network:
    "Network with the best talent, companies, opportunities and projects.",
  bridge_talks:
    "Search for interesting and thought provoking talks from global leaders, influencers, organizations, and companies.",
  corporate_dna:
    "Seeking help about leadership, board assignments, talent development, coaching, etc.",
  decoding_the_code:
    "Ever wonder what you need to wear to your job interview, find out the answer to this question and others?",
  how_do_i:
    "Search for answers to questions about leadership, talent, investments, resource allocation, etc.",
  success_stories: 'Search for success stories from individuals, companies or organizations.',
  grit_and_grind: 'Explore the grit and grind of individuals, companies and organizations.',
  elevation: 'Search to learn ways to take your professional career or business to the next level.'
}
