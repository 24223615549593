import axios from "./axios"

export default {
  getSeekersNames: async (name) => {
    try {
      const result = await axios.get(`suggestions/seekers?name=${name}`)
      return result
    } catch (error) {
      throw error
    }
  },
  getLocations: async (name, from) => {
    try {
      const result = await axios.get(
        `suggestions/prospect_location?location=${name}&from=${from}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getOpportunityLocations: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/locations?location=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getIndividualLocations: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/individual_locations?location=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getIndividualSkills: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/skills?skill=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getJobTitles: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/job_titles?job_title=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getCompanies: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/companies?company=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  getAvailableCompanies: async (name) => {
    try {
      const result = await axios.get(
        `suggestions/shared_companies?company=${encodeURIComponent(name)}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
  checkEmailInBase: async (name) => {
    try {
      const result = await axios.get(`check_email?email=${name}`)
      return result
    } catch (error) {
      throw error
    }
  },
  getResourcesLocations: async (location) => {
    try {
      const result = await axios.get(
        `/suggestions/resources_locations?location=${location}`,
      )
      return result
    } catch (error) {
      throw error
    }
  },
}
