import axios from "./axios"

export default {
  postComment: async (text, id, type, parentId) => {
    const data = {
      body: text,
      commentable_id: parentId ? null : id,
      commentable_type: parentId ? null : type,
      parent_id: parentId,
    }
    try {
      const result = await axios.post(`/comments`, data)
      return result
    } catch (error) {
      throw error
    }
  },
  editComment: async (text, uuid) => {
    const data = {
      body: text,
    }
    try {
      const result = await axios.put(`/comments/${uuid}`, data)
      return result
    } catch (error) {
      throw error
    }
  },
  deleteComment: async (uuid) => {
    try {
      const result = await axios.delete(`/comments/${uuid}`)
      return result
    } catch (error) {
      throw error
    }
  },
  voteForComment: async (vote, uuid) => {
    try {
      const result = await axios.post(`/comments/${uuid}/vote`, { vote })
      return result
    } catch (error) {
      throw error
    }
  },
}
