/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import styles from "./ReportsContainer.module.scss"
import notificationsServices from "../../services/notificationsServices"
import { getPageCount } from "../CommunityQuestionsContainer"
import reportServices from "../../services/reportServices"
import { setNotifications } from "../../actions/notifications/notificationsActions"
import ReportsList from "../../components/Reports/ReportsList/ReportsList"
import Pagination from "../../components/elements/Pagination"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"

const ReportsContainer = () => {
  const [reports, setReports] = useState([])
  const [reportsCount, setReportsCount] = useState()
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(1)

  const getNotif = () => {
    notificationsServices
      .getNotificationsAdmin()
      .then(({ data }) => {
        dispatch(
          setNotifications({
            system_notifications: data.system_notifications_count,
          }),
        )
      })
      .catch(console.log)
  }

  const getReports = (currentPage) =>
    reportServices
      .getReports(currentPage)
      .then(({ data }) => {
        setReports(data.reports)
        setReportsCount(data.reports_count)
      })
      .catch(console.log)

  useEffect(() => {
    getReports(currentPage)
    getNotif()
  }, [currentPage])

  return (
    <div className={styles.wrapper}>
      <ContentWithSidebars bottomSidebar={() => {}}>
        <ReportsList data={reports} />

        <div className={styles.paggination}>
          <Pagination
            currentPageNumber={currentPage}
            totalPageCount={getPageCount(reportsCount, 6)}
            onSelect={setCurrentPage}
          />
        </div>
      </ContentWithSidebars>
    </div>
  )
}

export default ReportsContainer
