import React from "react"
import styles from "./DepartmentItem.module.scss"
import PropTypes from "prop-types"
import ThreeDotsMenu from "../../ThreeDotsMenu/ThreeDotsMenu"
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip"

const DepartmentItem = ({ departmentInfo, onEdit, onDelete, withMenu }) => {
  const menu = [
    {
      name: "Edit",
      click: () => {
        onEdit(departmentInfo.uuid)
      },
    },
    {
      name: "Delete",
      click: () => {
        onDelete(departmentInfo.uuid)
      },
      tooltip:
        departmentInfo.open_opportunities > 0 &&
        "Department can not be deleted while it's bonded with opportunity",
    },
  ]

  const value = departmentInfo.name

  return (
    <div className={styles.departmentWrapper}>
      {departmentInfo.name?.length > 27 ? (
        <CustomTooltip name={value}>
          <p className={styles.name}>{value}</p>
        </CustomTooltip>
      ) : (
        <p className={styles.name}>{value}</p>
      )}
      <p className={styles.employees}>
        {departmentInfo.employees_count} Employees
      </p>
      <p className={styles.openOpportunities}>
        {departmentInfo.open_opportunities} Open opportunities
      </p>
      {withMenu && (
        <div className={styles.threeDots}>
          <ThreeDotsMenu actions={menu} />
        </div>
      )}
    </div>
  )
}

DepartmentItem.propTypes = {
  departmentInfo: PropTypes.shape({
    name: PropTypes.string,
    employees_count: PropTypes.number,
    open_opportunities: PropTypes.number,
  }),
}

export default DepartmentItem
