import React, { useState } from "react"
import { useSelector } from "react-redux"
import Box from "@material-ui/core/Box"
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import Dropdown from "../../components/Dropdown/Dropdown"
import { ReactComponent as Search } from "../../images/icons/search.svg"
import styles from "./HomeSearchForm.module.scss"
import features from "../../features";

const values = [
  { id: 1, value: "Opportunities", placeholder: "Search for opportunities" },
  { id: 2, value: "Resources", placeholder: "Search for resources" },
]
let signUpedvalues = [
  {
    id: 1,
    value: "Companies/Orgs.",
    placeholder: "Search for companies/organizations",
  },
  { id: 2, value: "Professionals", placeholder: "Search for professionals" },
  { id: 3, value: "Solopreneurs", placeholder: "Search for solopreneurs" },
  { id: 5, value: "Opportunities", placeholder: "Search for opportunities" },
  { id: 6, value: "Resources", placeholder: "Search for resources" },
]

if(features.upworkIntegration) {
  signUpedvalues.splice(3,0,  {
    id: 4,
    value: "Freelancers",
    placeholder: "Search for freelancers",
  })
}

const HomeSearchForm = ({ onSubmit }) => {
  const user = useSelector((state) => state.user)
  const options = user?.role !== "guest" ? signUpedvalues : values

  const [inputValue, setInputValue] = useState("")
  const [activeValue, setActiveValue] = useState(options[0])

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({ type: activeValue.value, query: inputValue })
  }

  const handleSearchInput = (e) => {
    const query = e.target.value
    setInputValue(query.length > 200 ? query.substring(0, 200) : query)
  }

  return (
    <div className={styles.searchForm}>
      <h5 className={styles.title}>
        Welcome to B2T. As a member of our community, you have access to global
        opportunities, diverse companies, top talent, industry leaders, and
        critical resources.
      </h5>

      <form onSubmit={handleSubmit}>
        <Box display="flex" flexWrap="wrap">
          <Box flexGrow={1}>
            <Input
              placeholder={activeValue.placeholder}
              className={styles.field}
              type="search"
              name="search"
              beforeIcon={<Search />}
              fieldStyle="light"
              value={inputValue}
              onChange={handleSearchInput}
            />
          </Box>

          <div className={styles.dropdownWrap}>
            <Dropdown
              wrapperStyles={{ width: "100%" }}
              id={"2"}
              styles={{ width: "100%", height: "48px" }}
              placeholder="placeholder"
              disabled={false}
              options={options}
              onChange={(value) => {
                setActiveValue(value)
              }}
              value={activeValue}
              onBlur={() => {}}
              border={false}
              isWhiteBackground={true}
            />
          </div>

          <Button className={styles.btn} type="submit">
            Search
          </Button>
        </Box>
      </form>
    </div>
  )
}

export default HomeSearchForm
