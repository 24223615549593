export const objectComparison = (x, y) => {
  if (x === y) return true
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectComparison(x[p], y[p])) return false
    // Objects and Arrays must be tested recursively
  }

  for (p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false
    // allows x[ p ] to be set to undefined
  }
  return true
}

export const throttle = (func, ms) => {
  let isThrottled = false,
    savedArgs,
    savedThis

  function wrapper() {
    if (isThrottled) {
      // (2)
      savedArgs = arguments
      savedThis = this
      return
    }

    func.apply(this, arguments) // (1)

    isThrottled = true

    setTimeout(function () {
      isThrottled = false // (3)
      if (savedArgs) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = savedThis = null
      }
    }, ms)
  }

  return wrapper
}

export const objectDeepCopy = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const getVideoDuration = (file) => {
  return new Promise((resolve) => {
    const vid = document.createElement("video")
    // create url to use as the src of the video
    const fileURL = URL.createObjectURL(file)
    vid.src = fileURL
    // wait for duration to change from NaN to the actual duration
    vid.ondurationchange = (e) => {
      const duration = e.path?.[0].duration || e.target?.duration
      const min = ~~((duration % 3600) / 60)
      let sec = ~~(duration % 60)
      sec = sec < 10 ? `0${sec}` : sec
      resolve(`${min}:${sec}`)
    }
  })
}
