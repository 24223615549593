import React from "react"
import styles from "../ChatsContainer.module.scss"
import moment from "moment"
import MessageCard from "../../../components/MessageCard/MessageCard"
import defaultAvatar from "../../../images/defaultUserAvatar.png"
import classnames from "classnames"
import services from "../../../services"
import {
  setActiveChat,
  setFirstMessage,
  setLoadingOldMessage,
} from "./actionCreators"
import {
  addPreviousMessages,
  markMessageAsRead,
  getFirstMessageUuid,
} from "./index"

export const renderMessage = (message, state, dispatch, userInfo) => {
  const messageInView = (isReaded, isMy, uuid) => {
    const isFirst = state.firstMessage.uuid === uuid

    if (!isReaded && !isMy) {
      const newRoom = markMessageAsRead(state.activeChat, [uuid])
      dispatch(setActiveChat(newRoom))

      services.chatServices
        .setStatusMessageToRead(uuid)
        .catch((e) => console.log(e))
    }
    if (
      isFirst &&
      !state.firstMessage.isEnd &&
      !state.firstMessage.isFistLoad
    ) {
      dispatch(setLoadingOldMessage(true))
      services.chatServices
        .getPreviousMessages(state.activeChat.uuid, state.firstMessage.uuid)
        .then((result) => {
          const newRoom = addPreviousMessages(state.activeChat, result.data)
          dispatch(setActiveChat(newRoom))
          dispatch(setFirstMessage({ uuid: getFirstMessageUuid(newRoom) }))
          dispatch(setLoadingOldMessage(false))
          if (Object.keys(result.data).length === 0) {
            dispatch(setFirstMessage({ isEnd: true }))
          }
        })
        .catch((e) => console.log(e))
    }
  }

  return message.map((m, index) => (
    <div
      className={classnames(styles.messageWrapper, {
        [styles.myMessage]: m.from_you,
      })}
      key={index}
    >
      <MessageCard
        name={state.activeChat.partner.name}
        time={moment(m.created_at).format("hh:mm A")}
        text={m.body}
        myMessage={m.from_you}
        read={m.from_you && m.is_readed}
        sent={m.from_you && !m.is_readed}
        uuid={m.uuid}
        //TODO: Temporary Prop messageObject
        messageObject={m}
        photo={
          m.from_you
            ? userInfo.photo || userInfo.company.photo || defaultAvatar
            : state.activeChat.partner.photo
        }
        onEnterViewport={() => {
          messageInView(m.is_readed, m.from_you, m.uuid)
        }}
      />
    </div>
  ))
}
