import React from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"

import styles from "./index.module.scss"
import PageLinedTitle from "../../components/elements/PageLinedTitle"
import TrendingTopics from "../../components/TrendingTopics"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import ResourceCard from "../../components/cards/ResourceCard/index.jsx"
import services from "../../services"
import NoDataPlaceholder from "../../components/elements/NoDataPlaceholder"
import Loading from "../../components/Loading/Loading"
import Pagination from "../../components/elements/Pagination"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"

const UserEventsContainer = () => {
  const [refusePopupOpened, setRefusePopupOpened] = React.useState(false)
  const [state, setState] = React.useState({
    isLoading: false,
  })
  const history = useHistory()
  const location = useLocation()
  const params = useParams()

  const currentPage = getCurrentPage(location)

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.resourceServices
      .getMyEvents({
        page: currentPage,
      })
      .then(({ data }) => {
        setState({
          data,
          isLoading: false,
        })
      })
      .catch(({ response }) => {
        const res = { isLoading: false }

        if (response.status === 422) {
          res.data = {
            resources: {
              on_page: [],
              total_count: 0,
            },
            trending_topics: [],
          }
        }

        setState(res)
      })
  }, [currentPage, params.uuid])

  const onRefuse = () => {
    services.resourceServices
      .attendToResource({
        uuid: refusePopupOpened,
        status: false,
      })
      .then(() => {
        setState({
          data: {
            resources: {
              on_page: state.data.resources.on_page.filter(
                (resource) => resource.uuid !== refusePopupOpened,
              ),
              total_count: state.data.resources.total_count - 1,
            },
            trending_topics: state.data.trending_topics,
          },
          isLoading: false,
        })
      })
  }

  return (
    <div className={styles.pageWrapper}>
      {refusePopupOpened && (
        <Popup
          dialog
          subtitle="Are you sure you want to cancel your attendance?"
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                onRefuse()
                setRefusePopupOpened(false)
              }}
            >
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setRefusePopupOpened(false)
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setRefusePopupOpened(false)
          }}
          isCrossIcon={true}
        />
      )}

      <PageLinedTitle title="My events" />

      <ContentWithSidebars bottomSidebar={() => <TrendingTopics />}>
        {state.isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            {state.data && (
              <React.Fragment>
                {state.data.resources.on_page.length ? (
                  <div className={styles.resources}>
                    {state.data.resources.on_page.map((resource) => (
                      <ResourceCard
                        key={resource.id}
                        resource={resource}
                        refuseFn={() => setRefusePopupOpened(resource.uuid)}
                      />
                    ))}
                  </div>
                ) : (
                  <NoDataPlaceholder>No events yet</NoDataPlaceholder>
                )}

                {getPageCount(state.data.resources.total_count, 12) > 1 && (
                  <div style={{ padding: "20px", textAlign: "right" }}>
                    <Pagination
                      currentPageNumber={currentPage}
                      totalPageCount={getPageCount(
                        state.data.resources.total_count,
                        12,
                      )}
                      onSelect={(page) => {
                        history.push(`${location.pathname}?page=${page}`)
                      }}
                    />
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default UserEventsContainer

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}

const getPageCount = (totalCount, perPage) =>
  Math.ceil(totalCount / perPage) || 1
