import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./PasswordInput.module.scss"
import { ReactComponent as EyeOutline } from "../../images/icons/eye-outline.svg"
import { ReactComponent as Eye } from "../../images/icons/eye.svg"
import { ReactComponent as Error } from "../../images/icons/error.svg"

const PasswordInput = ({
  name,
  className,
  label,
  placeholder,
  error,
  errorMessage,
  ...attrs
}) => {
  const [focus, setFocus] = useState(false)
  const [inputType, setInputType] = useState("password")

  const toogleInputType = () => {
    if (inputType === "password") {
      setInputType("text")
    } else {
      setInputType("password")
    }
  }

  const classes = classNames(styles.fieldWrap, className, {
    [styles.errorInput]: error,
  })

  return (
    <div className={classes}>
      <label htmlFor={name}>
        {label ? (
          <span className={classNames("note14r", styles.fieldName)}>
            {label}
          </span>
        ) : null}
        <div style={{ position: "relative" }} className={styles.inputContainer}>
          <input
            id={name}
            type={inputType}
            className={classNames("text16r", styles.field)}
            name={name}
            placeholder={placeholder}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            {...attrs}
          />
          <span
            onClick={toogleInputType}
            className={classNames("iconWrap", styles.showPassBtn)}
          >
            {inputType === "password" ? (
              <EyeOutline className={classNames("icon", styles.Icon)} />
            ) : (
              <Eye className={classNames("icon", styles.Icon)} />
            )}
          </span>
        </div>
        {error && errorMessage && (
          <div className={styles.errorMessageWrap}>
            <Error />
            <p className={styles.errorMessage}>{errorMessage}</p>
          </div>
        )}
      </label>
    </div>
  )
}

PasswordInput.propTypes = {
  /** input name */
  name: PropTypes.string.isRequired,
  /** input class name */
  className: PropTypes.object,
  /** input label */
  label: PropTypes.string,
  /** input placeholder */
  placeholder: PropTypes.string,
  /** enables error message */
  error: PropTypes.bool,
  /** input error message */
  errorMessage: PropTypes.string,
}

PasswordInput.defaultProps = {
  name: "",
  className: {},
  label: "",
  placeholder: "",
}

export default PasswordInput
