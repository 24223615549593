import { COMPANY_PROVIDER, COMPANY_SEEKER } from "../../helpers/userRoles"
import { getCommentTime } from "../../helpers/dateHandlers"

export const formatComments = (comments, userInfo) => {
  if (comments.length === 0 || !Array.isArray(comments)) {
    return []
  }

  const fullFilledComments = comments.map((comment) => ({
    ...comment,
    userLink:
      comment.commenter.type === COMPANY_PROVIDER ||
      comment.commenter.type === COMPANY_SEEKER
        ? `/company/${comment.commenter.uuid}`
        : `/user/${comment.commenter.uuid}`,
    isMyComment:
      userInfo.uuid === comment.commenter.uuid ||
      userInfo.uuid === comment.commenter.user_uuid,
    created_at: getCommentTime(comment.created_at),
  }))

  const replies = fullFilledComments.filter((comment) => comment.parent_id)

  const sortedReplies = replies.reduce((prev, curr) => {
    if (curr.parent_id in prev) {
      return {
        ...prev,
        [curr.parent_id]: [...prev[curr.parent_id], curr],
      }
    } else {
      return {
        ...prev,
        [curr.parent_id]: [curr],
      }
    }
  }, {})

  const sortedRepliesEntries = Object.entries(sortedReplies)

  const subRepliesEntries = sortedRepliesEntries.map((arr) => [
    arr[0],
    arr[1].map((comment) => {
      if (comment.id in sortedReplies) {
        return {
          ...comment,
          replies: sortedReplies[comment.id],
        }
      } else {
        return comment
      }
    }),
  ])

  const objectOfReplies = Object.fromEntries(subRepliesEntries)

  let newComments = fullFilledComments.map((comment) => {
    if (comment.id in objectOfReplies) {
      return {
        ...comment,
        replies: objectOfReplies[comment.id],
      }
    }
    return comment
  })

  newComments = newComments.filter((comment) => !comment.parent_id)

  const isHasReplies = (comment) => comment?.replies?.length > 0

  newComments.forEach((comment) => {
    comment.level = 0
    if (isHasReplies(comment)) {
      comment.replies.forEach((commentFilled) => {
        if (isHasReplies(commentFilled)) {
          commentFilled.level = 1
          commentFilled.replies.forEach((commentFilledTwo) => {
            if (isHasReplies(comment)) {
              commentFilledTwo.level = 2
            }
          })
        }
      })
    }
  })

  return newComments
}
