import axios from "./axios"
import queryString from "query-string"
import { fetch as fetchPolyfill } from "whatwg-fetch"
import { b64toBlob } from "./helpers"

export default {
  getSelfCompanyInfo: async () => {
    try {
      return await axios.get("/self/company")
    } catch (error) {
      throw error
    }
  },
  updateCompanyUser: async (params) => {
    try {
      return await axios.put("/companies", params)
    } catch (error) {
      throw error
    }
  },
  getCompanyInfo: async (uuid) => {
    try {
      return await axios.get(`/companies/${uuid}`)
    } catch (error) {
      throw error
    }
  },
  setCompanyLogo: async (data) => {
    try {
      const formData = new FormData()
      let avatar
      let originImage
      if (data.photo.indexOf("https://s3.amazonaws.com") !== -1) {
        //for IE 11 support
        avatar = await fetchPolyfill(data.photo, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => res.blob())
          .then((blobFile) => new Blob([blobFile], { type: "image/jpeg" }))
      } else {
        //for IE 11 support
        avatar = b64toBlob(data.photo)
      }

      if (data.origin_photo.indexOf("https://s3.amazonaws.com") !== -1) {
        //for IE 11 support
        originImage = await fetchPolyfill(data.origin_photo, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => res.blob())
          .then((blobFile) => new Blob([blobFile], { type: "image/jpeg" }))
      } else {
        //for IE 11 support
        originImage = b64toBlob(data.origin_photo)
      }

      formData.append("photo", avatar)
      formData.append("origin_photo", originImage)
      formData.append("x", data.x)
      formData.append("y", data.y)
      formData.append("width", data.width)
      formData.append("height", data.height)

      const res = await axios.put(`/company/photo`, formData)
      return res
    } catch (e) {
      throw e
    }
  },

  deleteCompanyAvatar: async () => {
    try {
      return axios.delete("/company/photo")
    } catch (error) {
      throw error
    }
  },
  getCompanies: async (requestParams) => {
    const search = queryString.stringify(
      {
        ...requestParams,
      },
      { arrayFormat: "comma", skipEmptyString: true },
    )
    try {
      return await axios.get(`/companies?${search}`)
    } catch (error) {
      throw error
    }
  },
  getPaymentUrl: async () => {
    try {
      const {
        data: { url },
      } = await axios.get(`/integrations/stripe/session`)
      return url
    } catch (error) {
      throw error
    }
  },
  pauseSubscription: async () => {
    try {
      const {
        data: { msg },
      } = await axios.put(`/integrations/stripe/subscription/pause`)
      return msg
    } catch (error) {
      throw error
    }
  },
  resumeSubscription: async () => {
    try {
      const {
        data: { msg },
      } = await axios.put(`/integrations/stripe/subscription/resume`)
      return msg
    } catch (error) {
      throw error
    }
  },
}
