import React from "react"
import styles from "./Peer.module.scss"
import pt from "prop-types"
import classNames from "classnames"

import defaultAvatar from "../../../images/defaultUserAvatar.png"
import CloseIcon from "@material-ui/icons/Close"

const Peer = ({ avatar, name, uuid, email, onCrossClick }) => {
  const handleCrossClick = () => {
    onCrossClick({
      avatar,
      name,
      uuid,
      email,
    })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.photoWrapper}>
        <img
          src={avatar || defaultAvatar}
          className={styles.avatar}
          alt="avatar"
        />
        <span
          className={classNames("iconWrap", styles.crossIcon)}
          onClick={handleCrossClick}
        >
          <CloseIcon
            style={{
              color: "rgba(255, 255, 255, 0.7)",
            }}
          />
        </span>
      </div>
      <div className={styles.name}>{name}</div>
    </div>
  )
}

Peer.propTypes = {
  avatar: pt.string,
  name: pt.string,
  uuid: pt.string,
  email: pt.string,
  onCrossClick: pt.func,
}

export default Peer
