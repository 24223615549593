import React from "react"
import { useHistory } from "react-router-dom"
import { paths } from "../../../../routes/paths"
import InstituteCard from "../../../../components/cards/InstituteCard"
import OpportunityCard from "../../../../components/cards/OpportunityCard"
import Pagination from "../../../../components/elements/Pagination"
import AccessCheckerPopup from "../../../../components/Opportunity/components/AccessCheckerPopup/AccessCheckerPopup"
import { setSearchPage } from "../../useSearch/actionCreators"
import useTopOpportunities from "./useTopOpportunities"
import useAccessChecker from "./useAccessChecker"
import { checkIsFilterUsed } from "./helpers"
import styles from "./index.module.scss"

const MainWrapper = ({ state, dispatch, companyInfo }) => {
  const history = useHistory()
  const { query, page } = state.searchParamGroup
  const {
    opportunityList,
    opportunityCount,
    pageCount,
    isFetching,
  } = state.requestResultGroup
  const [isPopupDisplayed, closePopup, checkAccess] = useAccessChecker()
  const topOpportunities = useTopOpportunities(query, page, opportunityList)

  if (isFetching) return null
  const isFilterUsed = checkIsFilterUsed(state)

  return (
    <>
      {!!opportunityCount && (
        <>
          <div className={styles.opportunityList}>
            {opportunityList.map((opportunity) => (
              <InstituteCard
                key={opportunity.id}
                classes={styles.card}
                type={opportunity.opportunity_type}
                thumbnailUrl={opportunity.video?.thumbnail}
                photoUrl={opportunity.photo?.path}
                description={opportunity.name}
                companyName={opportunity.company_name}
                companyAvatar={opportunity.company_photo}
                companyLocation={opportunity.location}
                publicationDate={opportunity.created_at}
                onClick={() =>
                  checkAccess(() =>
                    history.push(
                      paths.opportunityPage(opportunity.uuid, "search"),
                    ),
                  )
                }
              />
            ))}
          </div>
          {pageCount > 1 && (
            <div className={styles.pagination}>
              <Pagination
                currentPageNumber={parseInt(state.searchParamGroup.page)}
                totalPageCount={pageCount}
                onSelect={(pageNumber) => dispatch(setSearchPage(pageNumber))}
              />
            </div>
          )}
        </>
      )}
      {!opportunityCount && !query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Nothing here yet.
          <br />
          {companyInfo.type
            ? "Be the first one to contribute."
            : "Please try again later."}
        </p>
      )}
      {!opportunityCount && !!query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Sorry, we found 0 matches.
          <br />
          Please use other keywords or filters.
        </p>
      )}
      {!opportunityCount && isFilterUsed && (
        <p className={styles.notFoundBlock}>
          There are no results that match your search.
          <br />
          Please try adjusting your filters.
        </p>
      )}
      {!!query && !!topOpportunities.length && (
        <div className={styles.topOpportunityBlock}>
          <p>Top Opportunities:</p>
          <div className={styles.opportunityList}>
            {topOpportunities.map((opportunity) => (
              <OpportunityCard
                key={opportunity.id}
                classes={styles.card}
                type={opportunity.opportunity_type}
                caption={opportunity.name}
                avatar={opportunity.company_photo}
                description={opportunity.description}
                location={{
                  head: opportunity.company_name,
                  city: opportunity.location,
                }}
                timestamp={opportunity.created_at}
                onClick={() =>
                  checkAccess(() =>
                    history.push(paths.opportunityPage(opportunity.uuid)),
                  )
                }
              />
            ))}
          </div>
        </div>
      )}
      <AccessCheckerPopup visible={isPopupDisplayed} closeAction={closePopup} />
    </>
  )
}
export default MainWrapper
