import React from "react"
import P from "prop-types"
import styles from "./OpportunityParamsItem.module.scss"
import { Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import classNames from "classnames"
import {
  convertCamelCaseToTitle,
  dhm,
} from "../../../../../helpers/textHelpers"
import { getIconByName } from "../../../heplers"
import CopyToClipboard from "../../../../CopyToClipboard"
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const OpportunityParamsItem = ({ title, value }) => {

  if (!title || !value) return null
  if (title === "posted") {
    const delta = Math.abs(new Date() - new Date(value))
    value =
      dhm(delta).days > 7
        ? new Date(value).toLocaleDateString("en-US")
        : timeAgo.format(new Date(value))
  }
  if (
    title === "contact" ||
    title === "how_to_apply" ||
    title === "company_culture" ||
    title === "advisory_board_protocol" ||
    title === "sponsorship_protocol" ||
    title === "board_of_directors_protocol"
  ) {
    value = <CopyToClipboard text={value} />
  }

  return (
    <Grid
      component="div"
      className={classNames(styles.infoSectionItem)}
      container
    >
      <Grid
        component="div"
        xl={4}
        xs={6}
        lg={4}
        md={4}
        sm={6}
        item
        className={styles.infoSectionItemTitle}
      >
        <span className={styles.infoSectionIcon}>{getIconByName(title)}</span>
        <Typography
          className={styles.infoSectionTitle}
          component="h5"
          variant="h5"
        >
          {convertCamelCaseToTitle(title)}:
        </Typography>
      </Grid>
      <Grid
        component="div"
        className={styles.infoSectionItem}
        xl={8}
        xs={6}
        lg={8}
        md={8}
        sm={6}
        item
      >
        <h5 className={classNames(styles.infoSectionValue)}>{value}</h5>
      </Grid>
    </Grid>
  )
}

OpportunityParamsItem.propTypes = {
  title: P.string,
  value: P.string,
}

OpportunityParamsItem.defaultProps = {
  title: "",
  value: "",
}

export default OpportunityParamsItem
