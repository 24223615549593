import moment from "moment"
import {
  isDuringLastMinute,
  isDuringLastHour,
  isDuringCurrentDay,
} from "./timeHandler"

const isToday = (date) => {
  const today = moment()
  if (date.isSame(today, "d")) return "Today"
}

const isYesterday = (date) => {
  const yesterday = moment().add(-1, "days")
  if (date.isSame(yesterday, "d")) return "Yesterday"
}

const isDuringLastWeek = (date) => {
  const firstDay = moment().add(-2, "days").endOf("day")
  const lastDay = moment().add(-7, "days").startOf("day")

  return date.isBetween(lastDay, firstDay)
}

const isDuringLastYear = (date) => {
  const firstDay = moment().add(-7, "days").endOf("day")
  const startOfYear = moment().startOf("year")

  return date.isBetween(startOfYear, firstDay)
}

export const getMessagesDate = (date) => {
  const takenDate = moment(date)

  if (isToday(takenDate)) return "Today"
  if (isYesterday(takenDate)) return "Yesterday"
  if (isDuringLastWeek(takenDate)) return takenDate.format("dddd")
  if (isDuringLastYear(takenDate)) return takenDate.format("MMMM DD")

  return takenDate.format("MMMM DD, YYYY")
}

export const getCommentTime = (date) => {
  const takenDate = moment(date)

  if (isDuringLastMinute(takenDate)) return "Recently"
  if (isDuringLastHour(takenDate)) return `${isDuringLastHour(takenDate)} m ago`
  if (isDuringCurrentDay(takenDate)) {
    return takenDate.format("h:mm a")
  }

  if (isYesterday(takenDate)) return "Yesterday"
  if (isDuringLastWeek(takenDate)) return takenDate.format("dddd h:mm a")
  if (isDuringLastYear(takenDate)) return takenDate.format("MMMM DD, h:mm a")

  return takenDate.format("MMMM DD, 'YY, h:mm a")
}

export const getMessageTime = (date) => {
  const takenDate = moment(date)

  if (isDuringLastMinute(takenDate)) return "Recently"
  if (isDuringLastHour(takenDate)) return `${isDuringLastHour(takenDate)} m ago`
  if (isDuringCurrentDay(takenDate)) {
    return `${isDuringCurrentDay(takenDate)} h ago`
  }

  if (isYesterday(takenDate)) return "Yesterday"
  if (isDuringLastWeek(takenDate)) return takenDate.format("dddd")
  if (isDuringLastYear(takenDate)) return takenDate.format("MMMM DD")

  return takenDate.format("MMMM DD, 'YY")
}

export const getNotificationDate = (date) => {
  const takenDate = moment(date)
  const time = moment(takenDate).format("hh:mm A")

  if (isToday(takenDate)) {
    return `${time} - Today`
  }

  if (isYesterday(takenDate)) return `${time} - Yesterday`
  if (isDuringLastWeek(takenDate))
    return `${time} - ${takenDate.format("dddd")}`
  if (isDuringLastYear(takenDate)) return takenDate.format("MMMM DD")

  return takenDate.format("MMMM DD, 'YY")
}
