import { useEffect, useState } from "react"

const useWindowHeightWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const [height, setHeight] = useState(window.innerHeight)
  useEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth)
      setHeight(window.innerHeight)
    }

    window.addEventListener("resize", updateWindowDimensions)

    return () => {
      window.removeEventListener("resize", updateWindowDimensions)
    }
  }, [window.innerWidth, window.innerHeight])

  return {
    width,
    height,
  }
}

export default useWindowHeightWidth
