import axios from "./axios"
import queryString from "query-string"

export default {
  getQuestionPage: async (requestParams) => {
    const search = queryString.stringify(
      {
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/questions?${search}`)
    } catch (error) {
      throw error
    }
  },
  getRecentQuestions: async () => {
    try {
      return await axios.get(`/recent_questions`)
    } catch (error) {
      throw error
    }
  },
}
