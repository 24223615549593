import React, { useState, useEffect } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import suggestionServices from "../../services/suggestionServices";
import Input from "../Input/Input";
import MailIcon from "@material-ui/icons/Mail";

const CommunityTopicCompanyAutocomplete = ({ companyInput, externalDispatch, companyIdx, fieldName }) => {
  const [options, setOptions] = useState([])

  const getCompanies = () => {
    suggestionServices.getAvailableCompanies(companyInput).then(({ data }) => {
      setOptions(
        data
          .slice(0, 20)
          .sort((a, b) => {
            const str1 = a[0]?.toLowerCase()
            const str2 = b[0]?.toLowerCase()
            if (str1 > str2) {
              return 1
            } else if (str1 < str2) {
              return -1
            } else {
              return 0
            }
          })
          .map((company) => ({ id: company[1], name: company[0] })),
      )
    })
  }
  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line
  }, [companyInput])
  return (
    <Autocomplete
      name={`${fieldName}${companyIdx}`}
      inputValue={companyInput}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => {
          return (
          <div ref={params.InputProps.ref}>
            <Input
              afterIcon={
                    <MailIcon
                        style={{
                            color: "rgba(255, 255, 255, 0.7)",
                        }}
                    />
                }
              type="text"
              placeholder="Enter company"
              {...params.inputProps}
            />
          </div>
        )
      }}
      onInputChange={(e, value) => {
          externalDispatch(value.substring(0, 32))
      }}
      onChange={(e, company, reason) => {
          if (reason === "select-option") {
              externalDispatch(company)
          }
      }}
    />
  )
}
export default CommunityTopicCompanyAutocomplete
