import React, { useState } from "react"
import PropTypes from "prop-types"

import styles from "./ChatSearchFilter.module.scss"
import { ReactComponent as Loop } from "../../images/icons/loop.svg"
import { ReactComponent as Close } from "../../images/icons/Close.svg"

const ChatSearchFilter = ({ handleSearch }) => {
  const [activeInput, setActiveInput] = useState(false)
  const [inputValue, setInputValue] = useState("")

  const inputChange = (e) => {
    const value = e.target.value.substring(0, 64)
    setInputValue(value)
    handleSearch(value)
  }

  return (
    <div className={styles.filterWrap}>
      <div
        className={activeInput ? styles.inputContainer : styles.InboxContainer}
      >
        {!activeInput ? (
          <p className={styles.inbox}>Inbox</p>
        ) : (
          <input
            onChange={inputChange}
            value={inputValue}
            placeholder={"Search"}
            className={styles.input}
          />
        )}
        <span
          onClick={() => {
            setActiveInput(!activeInput)
            setInputValue("")
            handleSearch("")
          }}
          className={styles.iconWrap}
        >
          {activeInput && inputValue ? (
            <Close crossStyle="darkRounded" />
          ) : (
            <Loop viewBox="0 0 15 15" />
          )}
        </span>
      </div>
    </div>
  )
}

ChatSearchFilter.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func,
}

ChatSearchFilter.defaultProps = {
  setData: () => {
    console.log("setData")
  },
}

export default ChatSearchFilter
