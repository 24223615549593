/* eslint-disable no-useless-computed-key */
import React, { useState, useRef } from "react"
import classNames from "classnames"
import Input from "../../components/Input/Input"
import Grid from "@material-ui/core/Grid"
import Dropdown from "../../components/Dropdown/Dropdown"
import TextArea from "../../components/TextArea/TextArea"
import VideoCard from "../../components/Videos/VideoCard/VideoCard"
import { withFormik } from "formik"
import { mixed, object, string } from "yup"
import ButtonComponent from "../../components/Button/Button"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"
import { v4 as uuidv4 } from "uuid"
import { readFile } from "../../helpers/imageHelpers"
import { getVideoDuration, objectComparison } from "../../helpers"
import styles from "./EditStyles.module.scss"
import avatar from "../../images/defaultUserAvatar.png"
import { Prompt, useHistory } from "react-router-dom"
import TextEditor from "../../components/TextEditor/TextEditor"
import {
  experienceLevelOptions,
  workEnvironmentOptions,
} from "../../components/Opportunity/dropdownOptions"
import useOutsideClicker from "../../hooks/useOutsideClicker"
import DateRangeIcon from "@material-ui/icons/DateRange"
import Calendar from "../../components/Calendar/Calendar"

import { scrollOnError } from "./helpers"
import Loading from "../../components/Loading/Loading"
import DeleteIcon from "@material-ui/icons/Delete"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import Button from "../../components/Button/Button"
import { messages } from "../../helpers/constants"
import { isHaveBadWords } from "../../helpers/badWords"
import { ReactComponent as AddMedia } from "../../images/icons/add_media.svg"

const maxTextAreaLength = 1000
const maxDescriptionLength = 5000

const ProjectForm = ({
  data,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  errors,
  touched,
  handleSubmit,
  onCancel,
  departmentsOptions,
  isSubmitting,
  onDelete,
  initialValues,
  isPrivate,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const fileInputRef = useRef(null)
  const [buttonClickedStatus, setButtonClickedStatus] = useState(false)
  const [calendarOpened, setCalendarOpened] = useState(false)
  const CalendarRef = useRef(null)
  useOutsideClicker(CalendarRef, () => {
    setCalendarOpened(false)
  })
  const handleOpenCalendar = () => {
    setCalendarOpened(true)
  }
  const { width } = useWindowHeightWidth()
  const history = useHistory()

  const handleCancel = () => {
    setButtonClickedStatus(true)
    if (Object.keys(touched).length) {
      setIsPopupOpened(true)
    } else {
      onCancel ? onCancel() : history.push("/company-profile")
    }
  }
  const getVideo = () => {
    if (values["video"]?.path) {
      return values["video"]
    } else if (values["photo"]) {
      return { thumbnail: values["photo"].path }
    } else {
      return null
    }
  }

  const onUploadVideo = async ({ target }) => {
    const file = target.files[0]

    const path = await readFile(file)

    if (file.type.includes("video")) {
      setFieldValue(
        "video",
        {
          id: Number(uuidv4()),
          name: file.name,
          length: await getVideoDuration(file),
          file: { file },
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "video" },
      })
    } else if (file.type.includes("image")) {
      setFieldValue(
        "photo",
        {
          id: Number(uuidv4()),
          name: file.name,
          file: file,
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "photo" },
      })
    }
  }
  return (
    <div className={styles.wrapper}>
      <Prompt
        when={
          objectComparison(values, initialValues) === false &&
          !buttonClickedStatus
        }
        message={messages.CONFIRM_LEAVING_FROM_PAGE}
      />
      {isSubmitting && (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          handleCancel={() => {
            onCancel ? onCancel() : history.push("/company-profile")
            setIsPopupOpened(false)
          }}
        />
      )}
      <div className={styles.headerWrapp}>
        <h4 className={styles.title}>
          {data["uuid"] ? "Editing" : "Creating"} {isPrivate ? "Private " : ""}projects opportunity
        </h4>
        {data["uuid"] &&
          (width < 768 ? (
            <div
              className="iconWrap"
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />
            </div>
          ) : (
            <ButtonComponent
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              Delete
            </ButtonComponent>
          ))}
      </div>
      <div className={styles.sectionWrapper}>
        <Grid
          className={styles.inputsWrapper}
          container
          component="div"
          spacing={2}
        >
          <Grid item component="div" xs={12} className={styles.companyInfo}>
            <img
              src={data?.company_photo || avatar}
              alt="avatar"
              width="64px"
              className={styles.avatar}
            />
            <h4 className={styles.title}>{values["company_name"]}</h4>
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              autoFocus
              className={styles.input}
              name="name"
              label="Opportunity name*"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Type opportunity name"
              value={values["name"]}
              error={errors["name"] && touched["name"]}
              errorMessage={errors["name"] && touched["name"] && errors["name"]}
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="industry"
              label="Industry*"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Type your industry"
              value={values["industry"]}
              error={errors["industry"] && touched["industry"]}
              errorMessage={
                errors["industry"] && touched["industry"] && errors["industry"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="department_id"
              label="Department*"
              value={departmentsOptions.find(
                (item) => item.id === values["department_id"],
              )}
              placeholder="Select department"
              onChange={(name, option) =>
                handleChange({
                  target: { name: name, value: option.id },
                })
              }
              onBlur={() => handleBlur({ target: { name: "department_id" } })}
              options={departmentsOptions}
              error={errors["department_id"] && touched["department_id"]}
              errorMessage={
                errors["department_id"] &&
                touched["department_id"] &&
                errors["department_id"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="compensation"
              label="Compensation/Budget*"
              placeholder="Type your compensation/budget"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["compensation"]}
              error={errors["compensation"] && touched["compensation"]}
              errorMessage={
                errors["compensation"] &&
                touched["compensation"] &&
                errors["compensation"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="capabilities"
              label="Capabilities/Expertise"
              placeholder="Type capabilities/expertise"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["capabilities"]}
              error={errors["capabilities"] && touched["capabilities"]}
              errorMessage={
                errors["capabilities"] &&
                touched["capabilities"] &&
                errors["capabilities"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="experience_level"
              label="Experience Level*"
              value={experienceLevelOptions.find(
                (item) => item.value === values["experience_level"],
              )}
              placeholder="Select Experience Level"
              onChange={(name, option) =>
                handleChange({
                  target: { name: name, value: option.value },
                })
              }
              onBlur={() =>
                handleBlur({ target: { name: "experience_level" } })
              }
              options={experienceLevelOptions}
              error={errors["experience_level"] && touched["experience_level"]}
              errorMessage={
                errors["experience_level"] &&
                touched["experience_level"] &&
                errors["experience_level"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="location"
              label="Location Address*"
              placeholder="Type location address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["location"]}
              error={errors["location"] && touched["location"]}
              errorMessage={
                errors["location"] && touched["location"] && errors["location"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="contact"
              label="Contact*"
              placeholder="Type contact information"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["contact"]}
              error={errors["contact"] && touched["contact"]}
              errorMessage={
                errors["contact"] && touched["contact"] && errors["contact"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="work_environment"
              label="Work environment*"
              value={workEnvironmentOptions.find(
                (item) => item.value === values["work_environment"],
              )}
              placeholder="Select work environment"
              onChange={(name, option) =>
                handleChange({
                  target: { name: name, value: option.value },
                })
              }
              onBlur={() =>
                handleBlur({ target: { name: "required_experience" } })
              }
              options={workEnvironmentOptions}
              error={errors["work_environment"] && touched["work_environment"]}
              errorMessage={
                errors["work_environment"] &&
                touched["work_environment"] &&
                errors["work_environment"]
              }
            />
          </Grid>
          <Grid
            item
            component="div"
            xs={12}
            md={6}
            className={styles.expiration}
          >
            <Input
              autoComplete="off"
              onClick={handleOpenCalendar}
              afterIcon={<DateRangeIcon onClick={handleOpenCalendar} />}
              className={styles.input}
              name="opportunity_expiration"
              label="Opportunity Expiration"
              placeholder="Choose expiration date"
              onChange={handleOpenCalendar}
              onBlur={handleBlur}
              value={values["opportunity_expiration"]}
              error={
                errors["opportunity_expiration"] &&
                touched["opportunity_expiration"]
              }
              errorMessage={
                errors["opportunity_expiration"] &&
                touched["opportunity_expiration"] &&
                errors["opportunity_expiration"]
              }
            />
            {calendarOpened && (
              <div
                ref={CalendarRef}
                style={{
                  position: "absolute",
                  marginLeft: "3px",
                  width: "96%",
                  zIndex: "10",
                }}
              >
                <Calendar
                  selected={
                    (values["opportunity_expiration"] &&
                      new Date(values["opportunity_expiration"])) ||
                    false
                  }
                  styles={{
                    marginTop: "3px",
                  }}
                  minDate={new Date()}
                  width="100%"
                  onChange={(date) => {
                    handleChange({
                      target: {
                        name: "opportunity_expiration",
                        value: date
                          .toLocaleDateString("en-US")
                          .replace(/[^ -~]/g, ""),
                      },
                    })
                    setCalendarOpened(false)
                  }}
                />
              </div>
            )}
          </Grid>
          <Grid item component="div" xs={12}>
            <TextArea
              border
              vertical
              className={styles.input}
              style={styles.textArea}
              name="how_to_apply"
              label="How to Apply*"
              placeholder="Type how to apply"
              maxLength={maxTextAreaLength}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["how_to_apply"]}
              currentLength={values["how_to_apply"].length}
              error={errors["how_to_apply"] && touched["how_to_apply"]}
              errorMessage={
                errors["how_to_apply"] &&
                touched["how_to_apply"] &&
                errors["how_to_apply"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextArea
              border
              vertical
              className={styles.input}
              style={styles.textArea}
              name="certifications"
              label="Certifications"
              placeholder="Type certifications"
              maxLength={maxTextAreaLength}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["certifications"]}
              currentLength={values["certifications"].length}
              error={errors["certifications"] && touched["certifications"]}
              errorMessage={
                errors["certifications"] &&
                touched["certifications"] &&
                errors["certifications"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextArea
              border
              vertical
              className={styles.input}
              style={styles.textArea}
              name="company_culture"
              label="Company Vision & Culture"
              placeholder="Type company vision & culture"
              maxLength={maxTextAreaLength}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["company_culture"]}
              currentLength={values["company_culture"].length}
              error={errors["company_culture"] && touched["company_culture"]}
              errorMessage={
                errors["company_culture"] &&
                touched["company_culture"] &&
                errors["company_culture"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <input
              ref={fileInputRef}
              type="file"
              name="video"
              className={styles.fileInput}
              onChange={onUploadVideo}
              multiple={false}
              accept=".mp4, .m4v, .mov, .mpg, .mpeg, .jpg, .jpeg, .png"
            />
            <VideoCard
              empty={!(values["video"]?.path || values["photo"])}
              video={getVideo()}
              Icon={AddMedia}
              addMessage={"Add photo or video *"}
              onAdd={() => {
                fileInputRef.current.value = ""
                fileInputRef.current.click()
              }}
              deleteVideo={() => {
                setFieldValue("video", null)
                setFieldValue("photo", null)
                handleBlur({
                  target: {
                    name: "video",
                  },
                })
              }}
              anotherUser={false}
              error={
                (errors["video"] && touched["video"]) ||
                (errors["photo"] && touched["photo"])
              }
              errorMessage={
                (errors["video"] && touched["video"] && errors["video"]) ||
                (errors["photo"] && touched["photo"] && errors["photo"])
              }
              supportedFormats=".mp4, .m4v, .mov, .mpg, .mpeg"
              maxSize="40 MB"
              maxSizePhoto="10 MB"
              supportedFormatsPhoto={".jpg, .jpeg, .png"}
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextEditor
              className={styles.description}
              name="description_obj"
              placeholder="Type description"
              text={values["description"]}
              onChange={(value) =>
                handleChange({
                  target: { name: "description_obj", value: value },
                })
              }
              maxLength={maxDescriptionLength}
              label="Description*"
              error={errors["description_obj"] && touched["description_obj"]}
              errorMessage={
                errors["description_obj"] &&
                touched["description_obj"] &&
                errors["description_obj"]
              }
              onBlur={() =>
                handleBlur({
                  target: { name: "description_obj" },
                })
              }
            />
          </Grid>
        </Grid>
        <Grid
          component="div"
          container
          justify="flex-end"
          className={styles.buttonsWrapper}
        >
          <div className={styles.btnsWrap}>
            <Button
              className={styles.btn}
              buttonStyle="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
                scrollOnError(errors)
                setButtonClickedStatus(true)
              }}
              buttonStyle="contained"
              disabled={isSubmitting}
              className={styles.btn}
            >
              Save
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  )
}

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ data, departmentsOptions }) {
    let emptyDescription =
      '{"blocks":[{"key":"fvm5o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
    return {
      ["description_obj"]: data["description"] || emptyDescription,
      ["name"]: data["name"] || "",
      ["company_name"]: data["company_name"] || "",
      ["department_id"]:
        departmentsOptions.find((item) => item.value === data["department"])
          ?.id || "",
      ["industry"]: data["industry"] || "",
      ["project"]: data["project"] || "",
      ["compensation"]: data["compensation"] || "",
      ["capabilities"]: data["capabilities"] || "",
      ["experience_level"]: data["experience_level"] || "",
      ["location"]: data["location"] || "",
      ["contact"]: data["contact"] || "",
      ["work_environment"]: data["work_environment"] || "",
      ["how_to_apply"]: data["how_to_apply"] || "",
      ["certifications"]: data["certifications"] || "",
      ["opportunity_expiration"]: data["opportunity_expiration"] || "",
      ["description"]: data["description"] || emptyDescription,
      ["company_culture"]: data["company_culture"] || "",
      ["video"]: data["video"] || null,
      ["photo"]: data["photo"] || null,
    }
  },
  validationSchema: () =>
    object().shape(
      {
        ["video"]: mixed().when(["photo"], {
          is: (photo) => !photo,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "size",
              exclusive: false,

              message: "Sorry, you can upload video with max. size 40 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 40000000
              },
            }),
        }),
        ["photo"]: mixed().when(["video"], {
          is: (video) => !video,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "max",
              exclusive: true,

              message: "Sorry, you can upload photo with max. size 10 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 10000000
              },
            }),
        }),
        ["name"]: string()
          .max(
            32,
            "Please enter a correct name that consists of no more than 32 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter opportunity name"),
        ["company_name"]: string(),
        ["department_id"]: string().required("Please select department"),
        ["industry"]: string()
          .max(
            64,
            "Please enter a correct industry that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter industry"),
        ["compensation"]: string()
          .max(
            64,
            "Please enter a correct compensation/budget that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter compensation"),
        ["capabilities"]: string()
          .max(
            64,
            "Please enter a correct capabilities/expertise that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter team requirements"),
        ["experience_level"]: string()
          .max(
            64,
            "Please enter a correct experience description that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter experience level"),
        ["location"]: string()
          .max(
            64,
            "Please enter a correct location that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter location"),
        ["contact"]: string()
          .max(
            100,
            "Please enter a correct contacts that consists of no more than 100 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter contact information"),
        ["work_environment"]: string().required(
          "Please enter mentor environment",
        ),
        ["how_to_apply"]: string()
          .max(
            maxTextAreaLength,
            `Please enter a correct how to apply that consists of no more than ${maxTextAreaLength} characters`,
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter how to apply"),
        ["certifications"]: string()
          .max(
            maxTextAreaLength,
            `Please enter a correct certifications that consists of no more than ${maxTextAreaLength} characters`,
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["opportunity_expiration"]: string()
          .max(10, `Please enter an opportunity expiration date`)
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["company_culture"]: string()
          .max(
            maxTextAreaLength,
            `Please enter a correct company vision & culture that consists of no more than ${maxTextAreaLength} characters`,
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["description_obj"]: mixed()
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length <= maxDescriptionLength,
            message: `Please enter a correct description culture that consists of no more than ${maxDescriptionLength} characters`,
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length !== 0,
            message: "Please enter description",
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              !isHaveBadWords(
                JSON.parse(val)
                  .blocks.map((p) => p.text)
                  .join(" "),
              ),
            message: `Please don't use bad language`,
            exclusive: false,
          }),
      },
      ["photo", "video"],
    ),
  handleSubmit(values, { setSubmitting, props: { onSave } }) {
    setSubmitting(true)
    let savedValues = { ...values, description: values["description_obj"] }
    delete savedValues["description_obj"]
    onSave(savedValues)
  },
})(ProjectForm)

export default FormikApp
