import React, { useEffect, useState } from "react"
import Input from "../../../../Input/Input"
import styles from "./GetToKnowItem.module.scss"
import PropTypes from "prop-types"
import classNames from "classnames"
import validation from "./validation"

import Switch from "@material-ui/core/Switch"
import withStyles from "@material-ui/core/styles/withStyles"
import CrossButton from "../../../../CrossButton/CrossButton"
import CopyToClipboard from "../../../../CopyToClipboard"

function generateLink(type, value) {
  switch (type) {
    case "custom":
    case "facebook":
    case "instagram":
    case "twitter":
    case "linkedin":
    case "phone":
    case "email": {
      return <CopyToClipboard text={value} />
    }
    default:
      return value
  }
}

const GetToKnowItem = ({
  placeholder,
  Icon,
  onChange,
  type,
  propValue,
  showData,
  isVisible,
  onSwitchChange,
  handleDeleteCustomLink,
  getInputValidStatus,
  id,
  setHasChanges,
  autoFocus,
                         label,
}) => {
  const [value, setValue] = useState(propValue)
  const [visible, setVisible] = useState(isVisible)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    setValue(propValue)
    setVisible(isVisible)
  }, [isVisible, propValue])

  useEffect(() => {
    setValue(propValue)
    setVisible(isVisible)
  }, [isVisible, propValue, showData, value, visible])

  const onDelete = () => {
    handleDeleteCustomLink(id)
  }

  const HandleChangeInput = (value) => {
    let error
    setHasChanges(true)
    setValue(value)
    onChange(id, value)
    error = validation(type, value, true)

    if (visible === false) {
      let isVisibleNext = !isVisible
      onSwitchChange(id)
      setVisible(isVisibleNext)
      error = validation(type, value.trim(), isVisibleNext)
    }

    if (value.length === 0) {
      let isVisibleNext = !isVisible
      onSwitchChange(id)
      setVisible(isVisibleNext)
      error = validation(type, value.trim(), isVisibleNext)
    }

    getInputValidStatus(
      type === "custom" ? `custom${id}` : type,
      !error.errorMessage.length,
      id,
    )
    setErrorMessage(error.errorMessage)
  }

  const handleSwitchChange = () => {
    setHasChanges(true)
    onSwitchChange(id)
    onChange(id, value)
    setValue(value)
    const { errorMessage } = validation(type, value, !visible)
    setErrorMessage(errorMessage)
    getInputValidStatus(
      type === "custom" ? `custom${id}` : type,
      !errorMessage.length,
      id,
    )
    setVisible(!isVisible)
  }

  if (showData && !visible) return null
  if (showData && !visible && !propValue) return void 0

  return (
    <>
      <div className={classNames(styles.wrap, showData && styles.showWrap)}>
        <div
          className={classNames(
            styles.iconWrap,
            showData && styles.iconWrapShow,
          )}
        >
          <Icon className={styles.icon} />
        </div>
        {showData ? (
          <div className={styles.showField}>{generateLink(type, value)}</div>
        ) : (
          <>
            <Input
              name="input"
              label={label}
              className={styles.field}
              errorMessage={errorMessage}
              error={errorMessage}
              type={type}
              afterIcon={
                type === "custom" ? <CrossButton onClick={onDelete} /> : false
              }
              onChange={(e) => HandleChangeInput(e.target.value)}
              value={type === "address" || type ==="company_name" ? value : value.trim()}
              placeholder={placeholder}
              autoFocus={autoFocus}
            />
            {type === "custom"}
            <div className={styles.switchWrap}>
              <YellowSwitch checked={visible} onChange={handleSwitchChange} />
            </div>
          </>
        )}
      </div>
    </>
  )
}

const YellowSwitch = withStyles({
  switchBase: {
    color: "#9E9E9E",
    "&$checked": {
      color: "#F4C00D",
    },
    "&$checked + $track": {
      backgroundColor: "#F4C00D",
      opacity: "0.37",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#BABDC2",
  },
})(Switch)

GetToKnowItem.propTypes = {
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  action: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  propValue: PropTypes.string,
  showData: PropTypes.bool,
  isVisible: PropTypes.bool,
  onSwitchChange: PropTypes.func,
  id: PropTypes.number,
  setHasChanges: PropTypes.func,
  setReadyToSave: PropTypes.func,
}

GetToKnowItem.defaultProps = {
  placeholder: " ",
  isVisible: false,
  propData: "",
}

export default GetToKnowItem
