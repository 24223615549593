import moment from "moment"
export const OPEN_FROM_DATE = "OPEN_FROM_DATE"
export const OPEN_TO_DATE = "OPEN_TO_DATE"
export const SET_DATES = "SET_DATES"
export const CLOSE_CALENDAR = "CLOSE_CALENDAR"

export const initState = {
  isCalendarOpen: false,
  dateType: null,
  minDate: null,
  maxDate: null,
  fromDate: null,
  toDate: null,
  selectedDate: false,
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case OPEN_FROM_DATE: {
      return {
        ...state,
        isCalendarOpen: true,
        dateType: "from",
        minDate: moment("2020-01-01").toDate(),
        maxDate: state.toDate
          ? state.toDate.toDate()
          : moment().add(5, "Y").toDate(),
        selectedDate: state.fromDate ? state.fromDate.toDate() : false,
      }
    }
    case OPEN_TO_DATE: {
      return {
        ...state,
        isCalendarOpen: true,
        dateType: "to",
        minDate: state.fromDate
          ? state.fromDate.toDate()
          : moment("2020-01-01").toDate(),
        maxDate: moment().add(5, "Y").toDate(),
        selectedDate: state.toDate ? state.toDate.toDate() : false,
      }
    }
    case SET_DATES: {
      const { fromDate, toDate } = payload
      return {
        ...state,
        fromDate,
        toDate,
      }
    }
    case CLOSE_CALENDAR: {
      return {
        ...state,
        isCalendarOpen: false,
      }
    }
    default:
      throw new Error("Unknown action")
  }
}
