import React, { useEffect, useState } from "react"
import styles from "./TimePicker.module.scss"
import Dropdown from "../Dropdown/Dropdown"
import { timeValues } from "./helpers"

const TimePicker = ({
  label,
  errorFrom,
  errorTo,
  errorMessageFrom,
  errorMessageTo,
  handleChange,
  handleBlur,
  valueStart,
  valueEnd,
}) => {
  const [timeOptions, setTimeOptions] = useState({
    time_start: timeValues,
    time_end: timeValues,
  })

  useEffect(() => {
    if (valueEnd || valueStart) {
      setTime({
        ...time,
        time_end: timeValues.find((option) => option.value === valueEnd),
        time_start: timeValues.find((option) => option.value === valueStart),
      })
    }
    if (valueEnd && !valueStart) {
      setTime({
        ...time,
        time_end: timeValues.find((option) => option.value === valueEnd),
      })
    }
    if (valueStart && !valueEnd) {
      setTime({
        ...time,
        time_start: timeValues.find((option) => option.value === valueStart),
      })
    }
  }, [valueEnd, valueStart])

  const [time, setTime] = useState({
    time_start: timeValues.find((option) => option.value === valueStart) || "",
    time_end: timeValues.find((option) => option.value === valueEnd) || "",
  })

  useEffect(() => {
    let newEndOptions = [...timeValues]

    if (time?.time_start?.id > time?.time_end?.id) {
      setTime({ ...time, time_end: "", time_start: time.time_start })
      handleChange({
        target: { name: "time_end", value: "" },
      })
    }

    if (time?.time_start?.id) {
      newEndOptions = newEndOptions.map((option, index) => {
        return index < time?.time_start?.id
          ? { ...option, disabled: true }
          : option
      })
    }
    setTimeOptions({ ...timeOptions, time_end: newEndOptions })
  }, [time])

  const handleChangeTime = (type, value) => {
    setTime({ ...time, [type]: value })
    handleChange({
      target: { name: type, value: value.value },
    })
  }

  const onBlur = (name) => {
    handleBlur({
      target: {
        name: name,
      },
    })
  }

  return (
    <div className={styles.timePicker}>
      <div className={styles.timePickerInputsWrap}>
        <div className={styles.timePickerInput}>
          <Dropdown
            value={time.time_start}
            fieldStyle={"gray"}
            id={"01"}
            options={timeOptions?.time_start || []}
            onBlur={() => onBlur("time_start")}
            label={label}
            placeholder={"From"}
            onChange={(value) => handleChangeTime("time_start", value)}
            errorMessage={errorMessageFrom}
            error={errorFrom}
          />
        </div>
        <span className={styles.dot}>-</span>
        <div className={styles.timePickerInput}>
          <Dropdown
            fieldStyle={"gray"}
            id={"02"}
            value={time.time_end}
            options={timeOptions?.time_end || []}
            label={" "}
            placeholder={"To"}
            onBlur={() => onBlur("time_end")}
            onChange={(value) => handleChangeTime("time_end", value)}
            errorMessage={errorMessageTo}
            error={errorTo}
          />
        </div>
      </div>
    </div>
  )
}

export default TimePicker
