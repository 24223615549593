import moment from "moment"
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE"
export const SET_WHOLE_STATE = "SET_WHOLE_STATE"
export const SET_ARTICLE_TYPE = "SET_QUESTION_TYPE"
export const SET_FROM_DATE = "SET_FROM_DATE"
export const SET_TO_DATE = "SET_TO_DATE"
export const RESET_FORM = "RESET_FORM"

const initValues = {
  articleType: "",
  fromDate: null,
  toDate: null,
}
export const initState = {
  isCollapse: false,
  values: initValues,
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_COLLAPSE: {
      return { ...state, isCollapse: !state.isCollapse }
    }
    case SET_WHOLE_STATE: {
      const {
        searchParamGroup: { article_type, from, to },
      } = payload
      const values = {}
      values.articleType = article_type ? article_type : ""
      values.fromDate = from ? moment(from) : null
      values.toDate = to ? moment(to) : null
      return { ...state, values }
    }
    case SET_ARTICLE_TYPE: {
      const { articleType } = payload
      return { ...state, values: { ...state.values, articleType } }
    }
    case SET_FROM_DATE: {
      const { fromDate } = payload
      return { ...state, values: { ...state.values, fromDate } }
    }
    case SET_TO_DATE: {
      const { toDate } = payload
      return { ...state, values: { ...state.values, toDate } }
    }
    case RESET_FORM: {
      return { ...state, values: initValues }
    }
    default:
      throw new Error("Unknown action")
  }
}
