import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"

const ContentWithSidebars = ({ children, topSidebar, bottomSidebar }) => {
  return (
    <div className={classnames("container", styles.templateBody)}>
      {topSidebar && (
        <div className={styles.templateSidebar}>{topSidebar()}</div>
      )}

      <div
        className={classnames(
          styles.templateContent,
          !topSidebar && !bottomSidebar && styles.fullWidth,
        )}
      >
        {children}
      </div>

      {bottomSidebar && (
        <div className={styles.templateSidebar}>{bottomSidebar()}</div>
      )}
    </div>
  )
}

ContentWithSidebars.propTypes = {
  children: PropTypes.any.isRequired,
  topSidebar: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  bottomSidebar: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
}

ContentWithSidebars.defaultProps = {
  topSidebar: false,
  bottomSidebar: false,
}

export default ContentWithSidebars
