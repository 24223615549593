import axios from "./axios"

export default {
  getNotifications: async () => {
    try {
      const result = await axios.get("/notifications")
      return result
    } catch (error) {
      throw error
    }
  },
  getNotificationsAdmin: async () => {
    try {
      const result = await axios.get("/admin/notifications")
      return result
    } catch (error) {
      throw error
    }
  },
  getNotificationsList: async (page) => {
    try {
      const result = await axios.get("/notifications/list", {
        params: {
          page,
          per: 12,
        },
      })
      return result
    } catch (error) {
      throw error
    }
  },

  markNotificationRead: async (uuid) => {
    try {
      const result = await axios.put(`/notifications/${uuid}/read`)
      return result
    } catch (error) {
      throw error
    }
  },
}
