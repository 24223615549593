import {
  SET_SEARCH_QUERY,
  SET_SEARCH_PARAMS,
  SET_SEARCH_PAGE,
  SET_FORM_FILTERS,
  RESET_FORM_FILTERS,
  REMOVE_PROFESSION_TYPE,
  REMOVE_INDUSTRY_TYPE,
  REMOVE_MARKETS,
  REMOVE_EXPERIENCE_LEVEL,
  REMOVE_JOB_TITLE,
  REMOVE_LOCATION,
  REMOVE_SKILL,
  REMOVE_ROLE_TYPE,
  REMOVE_ACTIVITY_TYPE,
  REMOVE_GENDER_TYPE,
  REMOVE_RACE_TYPE,
  REMOVE_COMPANY_TYPE,
  REMOVE_LEVEL_TYPE,
  REMOVE_COMPANY_ROLE_TYPE,
  REMOVE_COMPANY_ACTIVITY_TYPE,
  REMOVE_COMPANY_INDUSTRY_TYPE,
  REMOVE_COMPANY_DEPARTMENT_TYPE,
  REMOVE_COMPANY_DIVERSE_TYPE,
  REMOVE_COMPANY_CERTIFIED,
  REMOVE_COMPANY_CLASSIFICATION_TYPE,
  REMOVE_COMPANY_MARKET,
  SET_REQUEST_RESULT,
} from "./reducer"

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: { query },
})
export const setSearchParams = (locationSearchGroup) => ({
  type: SET_SEARCH_PARAMS,
  payload: { locationSearchGroup },
})
export const setSearchPage = (pageNumber) => ({
  type: SET_SEARCH_PAGE,
  payload: { pageNumber },
})
export const setFormFilters = (filters) => ({
  type: SET_FORM_FILTERS,
  payload: { filters },
})
export const resetFormFilters = () => ({
  type: RESET_FORM_FILTERS,
  payload: null,
})
export const removeProfessionType = () => ({
  type: REMOVE_PROFESSION_TYPE,
  payload: null,
})
export const removeIndustryType = () => ({
  type: REMOVE_INDUSTRY_TYPE,
  payload: null,
})
export const removeMarkets = () => ({
  type: REMOVE_MARKETS,
  payload: null,
})
export const removeExperienceLevel = () => ({
  type: REMOVE_EXPERIENCE_LEVEL,
  payload: null,
})
export const removeJobTitle = () => ({
  type: REMOVE_JOB_TITLE,
  payload: null,
})
export const removeLocation = () => ({
  type: REMOVE_LOCATION,
  payload: null,
})
export const removeSkill = () => ({
  type: REMOVE_SKILL,
  payload: null,
})
export const removeRoleType = () => ({
  type: REMOVE_ROLE_TYPE,
  payload: null,
})
export const removeActivityType = () => ({
  type: REMOVE_ACTIVITY_TYPE,
  payload: null,
})
export const removeGenderType = () => ({
  type: REMOVE_GENDER_TYPE,
  payload: null,
})
export const removeRaceType = () => ({
  type: REMOVE_RACE_TYPE,
  payload: null,
})
export const removeCompanyType = () => ({
    type: REMOVE_COMPANY_TYPE,
    payload: null,
})
export const removeLevelType = () => ({
    type: REMOVE_LEVEL_TYPE,
    payload: null,
})
export const removeCompanyRoleType = () => ({
  type: REMOVE_COMPANY_ROLE_TYPE,
  payload: null,
})
export const removeCompanyActivityType = () => ({
  type: REMOVE_COMPANY_ACTIVITY_TYPE,
  payload: null,
})
export const removeCompanyIndustryType = () => ({
  type: REMOVE_COMPANY_INDUSTRY_TYPE,
  payload: null,
})
export const removeCompanyDepartmentType = () => ({
  type: REMOVE_COMPANY_DEPARTMENT_TYPE,
  payload: null,
})
export const removeCompanyDiverseType = () => ({
  type: REMOVE_COMPANY_DIVERSE_TYPE,
  payload: null,
})
export const removeCompanyCertified = () => ({
  type: REMOVE_COMPANY_CERTIFIED,
  payload: null,
})
export const removeCompanyClassificationType = (classificationType) => ({
  type: REMOVE_COMPANY_CLASSIFICATION_TYPE,
  payload: { classificationType },
})
export const removeCompanyMarket = () => ({
  type: REMOVE_COMPANY_MARKET,
  payload: null,
})
export const setRequestResult = (data) => ({
  type: SET_REQUEST_RESULT,
  payload: data,
})
