export const opportunities = [
  {
    title: "All",
    type: "",
  },
  {
    title: "Diverse Company",
    type: "diverse_company",
  },
  {
    title: "Capital/Funding",
    type: "funding",
  },
  {
    title: "Projects",
    type: "project",
  },
  {
    title: "Board of Directors",
    type: "board_of_directors",
  },
  {
    title: "Executive",
    type: "careers_executive",
  },
  {
    title: "Professional",
    type: "careers_professional",
  },
  {
    title: "Mentors",
    type: "mentoring",
  },

  {
    title: "Sponsors",
    type: "sponsorship",
  },
]
