import { months, years } from "../../helpers/helpData"

export const selectInstitute = (institute) => {
  if (institute) {
    let startMonth = institute.start.substr(0, 2)
    let startYear = institute.start.substr(2, 4)
    let finishMonth = institute.finish.substr(0, 2)
    let finishYear = institute.finish.substr(2, 4)

    startMonth = months.find((m) => m.id === startMonth)
    startYear = years(1950, 2030).find((y) => y.value === startYear)
    finishMonth = months.find((m) => m.id === finishMonth)
    finishYear = years(1950, 2030).find((y) => y.value === finishYear)

    return {
      ...institute,
      start: {
        month: startMonth,
        year: startYear,
      },
      finish: {
        month: finishMonth,
        year: finishYear,
      },
    }
  }
}
