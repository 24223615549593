import React from "react"
import styles from "./CompanyEmployeesPage.module.scss"
import CompanyEmployeesContainer from "../../containers/CompanyEmployeesContainer/CompanyEmployeesContainer"

const CompanyEmployeesPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <CompanyEmployeesContainer />
    </div>
  )
}

export default CompanyEmployeesPage
