import React, { useEffect, useReducer } from "react"
import classNames from "classnames"
import Button from "../../Button/Button"
import ArticleTypeDropdown from "./wrappers/ArticleTypeDropdown"
import DatePicker from "./wrappers/DatePicker"
import { ReactComponent as ArrowTopIcon } from "../../../images/icons/arrow-top.svg"
import { reducer, initState } from "./useReducer/reducer"
import {
  toggleCollapse,
  setWholeState,
  resetForm,
} from "./useReducer/actionCreators"
import styles from "./index.module.scss"

const InspirationSearchFilter = ({
  classes,
  requestResultGroup,
  onReset,
  onSubmit,
}) => {
  const [state, dispatch] = useReducer(reducer, initState)
  useEffect(() => {
    dispatch(setWholeState(requestResultGroup))
  }, [requestResultGroup])
  return (
    <div
      className={classNames(styles.filter, classes, {
        [styles.isCollapse]: state.isCollapse,
      })}
    >
      <div className={styles.header} onClick={() => dispatch(toggleCollapse())}>
        <p className={styles.caption}>All Filters</p>
        <div className={styles.collapseIcon}>
          <ArrowTopIcon />
        </div>
      </div>
      <div className={styles.form}>
        <div className={styles.body}>
          <div className={classNames(styles.item, styles.halfOfWidth)}>
            <ArticleTypeDropdown
              selectedType={state.values.articleType}
              externalDispatch={dispatch}
            />
          </div>
          <div
            className={classNames(
              styles.item,
              styles.dateField,
              styles.halfOfWidth,
            )}
          >
            <DatePicker
              fromDate={state.values.fromDate}
              toDate={state.values.toDate}
              externalDispatch={dispatch}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            buttonStyle="outlined"
            className={styles.btn}
            onClick={() => {
              dispatch(resetForm())
              onReset()
            }}
          >
            Clear
          </Button>
          <Button className={styles.btn} onClick={() => onSubmit(state.values)}>
            Apply
          </Button>
        </div>
      </div>
    </div>
  )
}
export default InspirationSearchFilter
