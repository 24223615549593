import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import QuestionCard from "../cards/QuestionCard"
import NoDataPlaceholder from "../elements/NoDataPlaceholder"

const QuestionsList = ({ data, inSidebar }) => {
  return (
    <div className={styles.listWrap}>
      {data.length ? (
        data.map((question) => (
          <QuestionCard
            key={question.id}
            data={question}
            inSidebar={inSidebar}
          />
        ))
      ) : (
        <NoDataPlaceholder>No questions yet</NoDataPlaceholder>
      )}
    </div>
  )
}

QuestionsList.defaultProps = {
  data: [],
  inSidebard: false,
}

QuestionsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  inSidebard: PropTypes.bool,
}

export default QuestionsList
