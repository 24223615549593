export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "administrative_services",
    value: "Administrative Services",
  },
  {
    id: "airlines",
    value: "Airlines",
  },
  { id: "advertising", value: "Advertising" },
  { id: "apparel", value: "Apparel" },
  {
    id: "arts_entertainment_and_recreation",
    value: "Arts, Entertainment & Recreation",
  },
  { id: "beauty", value: "Beauty & Cosmetics" },
  { id: "broadcasting", value: "Broadcasting (except Internet)", databaseName: "beauty" },
  { id: "construction_industry", value: "Construction" },
  {
    id: "data_processing_hosting_and_related_services",
    value: "Data processing, hosting & related services",
  },
  { id: "education", value: "Education" },
  {
    id: "financial_services_and_insurance",
    value: "Financial Services & Insurance",
  },
  {
    id: "food_services",
    value: "Food Services",
  },
  {
    id: "governance_strategic_development",
    value: "Governance & Strategic Development",
  },
  { id: "government", value: "Government" },
  { id: "healthcare_industry", value: "Healthcare" },
  { id: "human_resources", value: "Human Resources" },
  { id: "infrastructure", value: "Infrastructure" },
  {
    id: "it_digital",
    value: "Information Technology & Digital",
  },
  {
    id: "legal",
    value: "Legal",
  },
  {
    id: "leisure_and_hospitality",
    value: "Leisure & Hospitality",
  },
  {
    id: "distribution",
    value: "3PL & Distribution",
  },
  {
    id: "manufacturing",
    value: "Product Development & Manufacturing",
  },
  {
    id: "management_of_companies_and_enterprises",
    value: "Management of Companies & Enterprise",
  },
  { id: "marketing", value: "Marketing & Communications" },
  { id: "media", value: "Media" },
  {
    id: "professional_and_business_services",
    value: "Professional Business Services & Consulting",
  },
  {
    id: "professional_scientific_and_technical_services",
    value: "Professional, Scientific & Technical Services",
  },
  { id: "public_relations", value: "Public Relations" },
  { id: "real_estate", value: "Real Estate" },
  { id: "retail_trade", value: "Retail Trade" },
  {
    id: "software_development",
    value: "Software Development",
  },
  { id: "technology", value: "Technology" },
  { id: "telecommunications", value: "Telecommunications" },
  { id: "transportation", value: "Transportation" },
  { id: "wholesale_trade", value: "Wholesale Trade" },
]
