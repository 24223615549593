import React from "react"
import styles from "./VideoGalleryPage.module.scss"
import VideoGalleryContainer from "../../containers/VideoGalleryContainer/VideoGalleryContainer"
import { useParams, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { paths } from "../../routes/paths"

const VideoGalleryPage = () => {
  const { id } = useParams()
  const userInfo = useSelector((state) => state.user)
  const selfGallery = !id

  if (id === userInfo.uuid) {
    return <Redirect to={paths.videoGallery("", "")} />
  }

  return (
    <div className={styles.videoGalleryPage}>
      <VideoGalleryContainer selfGallery={selfGallery} id={id} />
    </div>
  )
}

export default VideoGalleryPage
