import React, { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import services from "../../services"
import styles from "./ProspectsContainer.module.scss"
import TrendingCard from "../../components/TrendingCard/TrendingCard"
import trendingImage from "../../images/test/trendingCard.png"
import trendingImage2 from "../../images/test/05.png"
import { useHistory } from "react-router-dom"
import qs from "query-string"
import TopFilter from "../../components/TopFilter/TopFilter"
import { useSelector } from "react-redux"
import {
  COMPANY_PROVIDER,
  COMPANY_SEEKER,
  INDIVIDUAL,
} from "../../helpers/userRoles"
import ProspectsList from "../../components/Prospects/ProspectsList/ProspectsList"
import Button from "../../components/Button/Button"
import Popup from "../../components/Popup/Popup"
import {
  OpportunityOptions,
  UsersRoleOptions,
} from "../../components/Opportunity/heplers"

import ProspectsFilter from "../../components/Prospects/ProspectsFilter/ProspectsFilter"
import renderTag, { getTagData } from "../../components/Prospects/renderTag"

import MyProspectsList from "../../components/Prospects/MyProspectsList/MyProspectsList"
import { objectDeepCopy } from "../../helpers"
import MyApplicationFilter from "../../components/Prospects/MyApplicationsFilter/MyApplicationsFilters"

const ProspectsContainer = ({
  page,
  opportunity_type,
  name,
  location,
  is_full_profile,
  is_educated,
  from,
  to,
  accepted,
  role,
  seeker_type,
  seeker_uuid,
  company,
  type,
}) => {
  const individualFilters = ["My prospects"]
  const companySeekerFilters = ["Prospects", "My prospects"]
  const companyProviderFilters = ["Prospects"]
  const [filters, setFilters] = useState([])
  const defaultPage = 1
  const [isLoading, setIsLoading] = useState(false)
  const [myProspectLoading, setMyProspectLoading] = useState(false)
  const [namesList, setNamesList] = useState([])
  const [namesListLoading, setNamesListLoading] = useState(false)
  const [locationsList, setLocationsList] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const [locationsListLoading, setLocationsListLoading] = useState(false)
  const [companiesListLoading, setCompaniesListLoading] = useState(false)
  const [selectedProspectType, setSelectedProspectType] = useState(type || null)
  const [tags, setTags] = useState([])
  const [hasAppliedFilters, setHasApplayedFilters] = useState(false)
  const [declinePopupOpened, setDeclinePopupOpened] = useState(false)
  const [activeFilters, setActiveFilters] = useState({
    page: Number(page) || defaultPage,
    opportunity_type: opportunity_type || OpportunityOptions[0].dbName,
    accepted: Boolean(accepted) || false,
    from: from || null,
    to: to || null,
    location: location || null,
    name: name || null,
    is_full_profile: Boolean(is_full_profile) || false,
    is_educated: Boolean(is_educated) || false,
    role: role || UsersRoleOptions[0].dbName,
    seeker_type: seeker_type || null,
    seeker_uuid: seeker_uuid || null,
    company: company || null,
    type: type || null,
  })

  const [myProspects, setMyProspects] = useState([])
  const [data, setData] = useState([])

  const user = useSelector((state) => state.user)

  const history = useHistory()

  const [popupInfo, setPopupInfo] = useState({
    visible: false,
    message: "",
    type: "",
    targetUuid: "",
  })

  const onDecline = (uuid) => {
    setPopupInfo({
      visible: true,
      message: "Are you sure you want to decline your application?",
      type: "decline",
      targetUuid: uuid,
    })
  }

  const onAccept = (uuid) => {
    setPopupInfo({
      visible: true,
      message: "Are you sure you want to accept this application?",
      type: "accept",
      targetUuid: uuid,
    })
  }

  const getProspectsNames = useMemo(() => {
    if (selectedProspectType === "Prospects") {
      if (activeFilters.name === null) {
        setNamesListLoading(true)
        services.suggestionServices
          .getSeekersNames(" ")
          .then(({ data }) => {
            setNamesList(
              data.slice(0, 20).sort((a, b) => a.name.localeCompare(b.name)),
            )
            setNamesListLoading(false)
          })
          .catch(() => {
            setNamesList([])
            setNamesListLoading(false)
          })
      }
      if (
        activeFilters.name !== undefined &&
        activeFilters?.name?.length >= 0 &&
        activeFilters?.name?.length <= 32
      ) {
        setNamesListLoading(true)
        services.suggestionServices
          .getSeekersNames(activeFilters.name)
          .then(({ data }) => {
            setNamesList(
              data.slice(0, 20).sort((a, b) => a.name.localeCompare(b.name)),
            )
            setNamesListLoading(false)
          })
          .catch(() => {
            setNamesList([])
            setNamesListLoading(false)
          })
      }
    }
  }, [activeFilters.name, selectedProspectType])

  const getLocations = useMemo(() => {
    const locationType =
      selectedProspectType === "Prospects" ? "provider" : "seeker"

    if (
      activeFilters.location === null ||
      activeFilters.location === undefined
    ) {
      setLocationsListLoading(true)
      services.suggestionServices
        .getLocations(" ", locationType)
        .then(({ data }) => {
          setLocationsList(data.slice(0, 20))
          setLocationsListLoading(false)
        })
        .catch(() => {
          setLocationsListLoading(false)
        })
    }
    if (activeFilters?.location?.length < 64) {
      setLocationsListLoading(true)
      services.suggestionServices
        .getLocations(activeFilters.location, locationType)
        .then(({ data }) => {
          setLocationsList(data.slice(0, 20))
          setLocationsListLoading(false)
        })
        .catch(() => {
          setLocationsListLoading(false)
        })
    }
  }, [activeFilters.location, selectedProspectType])

  const getCompanies = useMemo(() => {
    if (selectedProspectType === "My prospects") {
      if (
        activeFilters.company === null ||
        activeFilters.company === undefined
      ) {
        setCompaniesListLoading(true)
        services.suggestionServices
          .getCompanies(" ")
          .then(({ data }) => {
            setCompaniesList(data.slice(0, 20))
            setCompaniesListLoading(false)
          })
          .catch(() => {
            setCompaniesListLoading(false)
          })
      }
      if (activeFilters?.company?.length < 64) {
        setCompaniesListLoading(true)
        services.suggestionServices
          .getCompanies(activeFilters.company)
          .then(({ data }) => {
            setCompaniesList(data.slice(0, 20))
            setCompaniesListLoading(false)
          })
          .catch(() => {
            setCompaniesListLoading(false)
          })
      }
    }
  }, [activeFilters.company, selectedProspectType])

  const onHandleDeleteTag = (name) => {
    setTags([...tags].filter((tag) => tag.name !== name))
    setActiveFilters({ ...activeFilters, [name]: null, page: 1 })
    getProspects({ ...activeFilters, [name]: null, page: 1 })
  }

  const getProspects = (newQuery) => {
    if (selectedProspectType) {
      let queryObj = newQuery || {
        ...activeFilters,
        opportunity_type: activeFilters["opportunity_type"],
      }
      for (let key in queryObj) {
        if (!queryObj[key]) {
          delete queryObj[key]
        }
      }

      let filters = objectDeepCopy(queryObj)
      delete filters["page"]
      delete filters["type"]
      setHasApplayedFilters(
        Boolean(Object.values(filters).filter((item) => item).length),
      )

      const query = qs.stringify(queryObj)

      history.replace(`/prospects?${query}`)

      if (selectedProspectType === "Prospects") {
        setIsLoading(true)
        setData({
          prospects: [],
          total_count: 0,
        })
        services.prospectsServices
          .getProspects(query)
          .then((res) => {
            setData(res.data)

            setIsLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setIsLoading(false)
          })
      }
      if (selectedProspectType === "My prospects") {
        setMyProspects({
          prospects: [],
          total_count: 0,
        })
        setMyProspectLoading(true)
        services.prospectsServices
          .getMyProspects(query)
          .then((res) => {
            setMyProspects(res.data)
            setMyProspectLoading(false)
          })
          .catch((e) => {
            console.log(e)
            setMyProspectLoading(false)
          })
      }
    }
  }

  const setCurrentPage = (page) => setActiveFilters({ ...activeFilters, page })

  const onSelectProspectListItem = (activeFilterName) => {
    setActiveFilters({
      page: 1,
      type: activeFilterName,
    })
    setSelectedProspectType(activeFilterName)
  }

  const handleApplyFilter = () => {
    let render = []
    Object.entries(activeFilters).forEach(([name, value]) => {
      if (name && value) {
        if (getTagData(name, value).value) {
          render.push(getTagData(name, value))
        }
      }
    })

    setTags(render)
    setActiveFilters({ ...activeFilters, page: 1 })
    getProspects()
  }

  const handleClearFilter = () => {
    setTags([])
    setHasApplayedFilters(false)
    if (selectedProspectType === "Prospects" && hasAppliedFilters) {
      setActiveFilters({
        page: 1,
        type: activeFilters.type,
      })
      getProspects({
        page: 1,
      })
    }
    if (selectedProspectType === "My prospects" && hasAppliedFilters) {
      setActiveFilters({
        page: 1,
        type: activeFilters.type,
      })
      getProspects({
        page: 1,
      })
    }
  }

  const handleChangeFilter = (e) => {
    setActiveFilters({
      ...activeFilters,
      [e.target.name]: e.target.value || e.target.checked,
    })
  }

  const setTopFilterData = () => {
    if (user?.company?.type === COMPANY_PROVIDER) {
      handleSetProspectsList(companyProviderFilters)
    } else if (user?.company?.type === COMPANY_SEEKER) {
      handleSetProspectsList(companySeekerFilters)
    } else if (user?.role === INDIVIDUAL) {
      handleSetProspectsList(individualFilters)
    }
  }

  const handleSetProspectsList = (list) => {
    setFilters(list)
    setActiveFilters({
      ...activeFilters,
      type: selectedProspectType || list[0],
    })
    setSelectedProspectType(selectedProspectType || list[0])
  }

  const closePopupAction = () => {
    setPopupInfo({
      visible: false,
      message: "",
      type: "",
      targetUuid: "",
    })
  }
  const cancelApproval = (prospectUuid) => {
    const newProspects = data.prospects.map((prosp) =>
      prosp.uuid === prospectUuid ? { ...prosp, status: "created" } : prosp,
    )

    services.prospectsServices
      .changeProspectStatus(prospectUuid, "created")
      .then(() => {
        setData({ ...data, prospects: newProspects })
        closePopupAction()
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const handleChangeLocation = (selected, value) => {
    if (value === null) {
      setActiveFilters({
        ...activeFilters,
        location: null,
      })
    }
    if (value && value.length <= 64) {
      setActiveFilters({
        ...activeFilters,
        location: value.slice(0, 64),
      })
    }
  }
  const handleChangeCompany = (selected, value) => {
    if (value === null) {
      setActiveFilters({
        ...activeFilters,
        company: null,
      })
    } else {
      setActiveFilters({
        ...activeFilters,
        company: value.slice(0, 32) || null,
      })
    }
  }

  const handleChangeNameFilter = (selected, value) => {
    if (value === null) {
      setActiveFilters({
        ...activeFilters,
        seeker_type: null,
        seeker_uuid: null,
        name: null,
      })
    } else {
      setActiveFilters({
        ...activeFilters,
        seeker_type: value.type || null,
        seeker_uuid: value.uuid || null,
        name: value.name.slice(0, 32) || null,
      })
    }
  }

  const handleChangeDateFilter = (date) => {
    setActiveFilters({ ...activeFilters, from: date.from, to: date.to })
  }

  const handleApprovePopup = (prospectUuid) => {
    const { status } = data.prospects.find((prop) => prop.uuid === prospectUuid)
    if (status === "accepted") return cancelApproval(prospectUuid)

    setPopupInfo({
      visible: true,
      message: "Are you sure you want to accept this prospect?",
      type: "accepted",
      targetUuid: prospectUuid,
    })
  }

  const handleRejectPopup = (prospectUuid) => {
    setPopupInfo({
      visible: true,
      message: "Are you sure you want to reject this prospect?",
      type: "rejected",
      targetUuid: prospectUuid,
    })
  }

  const handleConfirmRequest = () => {
    let newProspects
    if (!popupInfo.targetUuid) return closePopupAction()

    switch (popupInfo.type) {
      case "accepted": {
        newProspects = data.prospects.map((prosp) =>
          prosp.uuid === popupInfo.targetUuid
            ? { ...prosp, status: "accepted" }
            : prosp,
        )
        break
      }
      case "rejected": {
        newProspects = data.prospects.filter(
          (el) => el.uuid !== popupInfo.targetUuid,
        )
        break
      }
      case "decline": {
        services.prospectsServices
          .changeProspectStatus(popupInfo.targetUuid, "rejected")
          .then(() => {
            getProspects()
            closePopupAction()
          })
        break
      }
      case "accept": {
        services.prospectsServices
            .changeProspectStatus(popupInfo.targetUuid, "accepted")
            .then(() => {
                getProspects()
                closePopupAction()
            })
        break;
      }
    }
    if (popupInfo.type === "decline" || popupInfo.type === "accept") return
    services.prospectsServices
      .changeProspectStatus(popupInfo.targetUuid, popupInfo.type)
      .then(() => {
        // setData({ ...data, prospects: newProspects })
        getProspects()
        closePopupAction()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    setTopFilterData()
    let render = []
    Object.entries(activeFilters).forEach(([name, value]) => {
      if (name && value) {
        if (getTagData(name, value).value) {
          render.push(getTagData(name, value))
        }
      }
    })
    setTags(render)
  }, [])

  useEffect(() => {
    if (activeFilters.page) {
      getProspects()
    }
  }, [activeFilters.page])

  useEffect(() => {
    setTags([])
    if (selectedProspectType) {
      getProspects({
        ...activeFilters,
        type: activeFilters.type,
      })
    }
  }, [selectedProspectType])

  return (
    <div
      className={classNames("container", "fullWidth", styles.prospectsWrapper)}
    >
      <div className={styles.leftColumn}>
        <TopFilter
          startFilter={selectedProspectType}
          filterArray={filters}
          onSelect={onSelectProspectListItem}
          className={styles.filterDesktop}
        />
        <div className={styles.prospects}>
          {renderTag(tags, onHandleDeleteTag)}
          {selectedProspectType === "Prospects" && (
            <ProspectsList
              loading={isLoading}
              prospects={data.prospects}
              handleReject={handleRejectPopup}
              handleApprove={handleApprovePopup}
              hasAppliedFilters={hasAppliedFilters}
              page={activeFilters.page}
              setCurrentPage={setCurrentPage}
              totalPageCount={data["total_count"]}
            />
          )}
          {selectedProspectType === "My prospects" && (
            <MyProspectsList
              onDecline={onDecline}
              onAccept={onAccept}
              setCurrentPage={setCurrentPage}
              page={activeFilters.page}
              prospects={myProspects.prospects}
              loading={myProspectLoading}
              totalPageCount={myProspects["total_count"]}
              hasAppliedFilters={hasAppliedFilters}
            />
          )}
        </div>
      </div>
      <div className={styles.filterWrap}>
        <TopFilter
          startFilter={selectedProspectType}
          filterArray={filters}
          onSelect={onSelectProspectListItem}
          className={styles.filterMobile}
        />
        {selectedProspectType === "Prospects" && (
          <ProspectsFilter
            activeFilters={activeFilters}
            handleApplyFilter={handleApplyFilter}
            handleChangeFilter={handleChangeFilter}
            handleChangeLocation={handleChangeLocation}
            handleChangeNameFilter={handleChangeNameFilter}
            handleClearFilter={handleClearFilter}
            handleChangeDateFilter={handleChangeDateFilter}
            locationsList={locationsList}
            locationsListLoading={locationsListLoading}
            namesList={namesList}
            namesListLoading={namesListLoading}
            setActiveFilters={setActiveFilters}
          />
        )}
        {selectedProspectType === "My prospects" && (
          <MyApplicationFilter
            activeFilters={activeFilters}
            handleApplyFilter={handleApplyFilter}
            handleChangeFilter={handleChangeFilter}
            handleChangeLocation={handleChangeLocation}
            handleChangeCompany={handleChangeCompany}
            handleClearFilter={handleClearFilter}
            handleChangeDateFilter={handleChangeDateFilter}
            locationsList={locationsList}
            locationsListLoading={locationsListLoading}
            companiesList={companiesList}
            companiesListLoading={companiesListLoading}
            setActiveFilters={setActiveFilters}
          />
        )}
      </div>
      <div className={styles.trendingWrap}>
        <p className={styles.trendingTitle}>Trending</p>
        <div className={styles.trendingCard}>
          <TrendingCard
            title="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Georgia"
            text="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Geo..."
            imgUrl={trendingImage}
          />
        </div>
        <div className={styles.trendingCard}>
          <TrendingCard
            title="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Georgia"
            text="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Geo..."
            imgUrl={trendingImage2}
          />
        </div>
      </div>
      {declinePopupOpened && (
        <Popup
          dialog
          subtitle={"Are you sure you want to decline your application?"}
          button={
            <Button buttonStyle="black" onClick={onDecline}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              onClick={() => setDeclinePopupOpened(false)}
              buttonStyle="outlinedBlack"
            >
              No
            </Button>
          }
          closeAction={() => setDeclinePopupOpened(false)}
          isCrossIcon={true}
        />
      )}
      {popupInfo.visible && (
        <Popup
          dialog
          isCrossIcon
          closeAction={closePopupAction}
          subtitle={popupInfo.message}
          button={
            <Button
              width="97px"
              buttonStyle="black"
              onClick={handleConfirmRequest}
            >
              Yes, sure
            </Button>
          }
          acceptButton={
            <Button
              width="64px"
              buttonStyle="outlinedBlack"
              onClick={closePopupAction}
            >
              No
            </Button>
          }
        />
      )}
    </div>
  )
}

export default ProspectsContainer
