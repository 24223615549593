import { useEffect } from "react";

const useOutsideClicker = (modalRef, closeHandler) => {
  const handleMouseDownOutside = (e) => {
    if (!modalRef.current?.contains(e.target)) {
      closeHandler();
    }
  };
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDownOutside);
    return () =>
      window.removeEventListener("mousedown", handleMouseDownOutside);
  }, []);
};

export default useOutsideClicker;
