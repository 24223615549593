import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import ResourceEditContainer from "../../containers/ResourceContainer/ResourceEditContainer"

const ResourceEditPage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)

  return <ResourceEditContainer id={values.id} type={values.type} />
}

export default ResourceEditPage
