import React from "react"
import PropTypes from "prop-types"
import { ReactComponent as CommentIcon } from "../../../images/icons/commentIcon.svg"
import styles from "./index.module.scss"

const CommentCounter = ({ commentCount, onClick }) => (
  <span className={styles.counter} onClick={onClick}>
    <CommentIcon />
    {`${commentCount || 0} Comment`}
    {commentCount !== 1 && "s"}
  </span>
)
CommentCounter.defaultProps = {
  commentCount: 0,
}
CommentCounter.propTypes = {
  /** Count of comments */
  commentCount: PropTypes.number,
  /** Callback for onClick */
  onClick: PropTypes.func,
}
export default CommentCounter
