import React, { useEffect } from "react"
import PropTypes from "prop-types"
import draftToHtml from "draftjs-to-html"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import styles from "./index.module.scss"
import ResourcePageHeader from "../../components/ResourcePageHeader"
import ResourcePageDetails from "../../components/ResourcePageDetails"
import AttendeesList from "../../components/AttendeesList"
import { resourceTypes } from "../../helpers/helpData"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"
import GoogleMap from "../../components/GoogleMap"
import services from "../../services"
import copy from "copy-to-clipboard"
import { PaymentLinks } from "./PaymentLinks"

const ResourcePageContainer = ({ uuid, data }) => {
  const userInfo = useSelector((state) => state.user)

  const history = useHistory()

  const [deletePopupOpened, setDeletePopupOpened] = React.useState(false)

  const [attendeeInfo, setAttendeeInfo] = React.useState({
    isAttendee: data.is_attendee,
    attendees: data.attendees,
    attendeesCount: data.attendees_count,
  })

  const type = resourceTypes.find(
    (item) => item.resourceType === data.resource_type.toLowerCase(),
  )

  const deleteResource = () => {
    services.resourceServices.deleteResourceByUuid(data.uuid).then(() => {
      history.goBack()
    })
  }

  const onAttend = (status) => {
    if (status === true)
    {
      const paymentLink = PaymentLinks.find(link => link.cost === data.cost && link.resource_type === data.resource_type)
      if (paymentLink)
      {
        window.open(paymentLink.link);
      }
    }

    services.resourceServices
      .attendToResource({
        uuid: data.uuid,
        status: !attendeeInfo.isAttendee,
      })
      .then((result) => {
        setAttendeeInfo({
          ...attendeeInfo,
          attendeesCount: attendeeInfo.attendeesCount + (status ? 1 : -1),
          attendees: status
            ? [result.data, ...attendeeInfo.attendees]
            : attendeeInfo.attendees.filter(
                (attendee) =>
                  attendee.seeker_uuid !==
                  (userInfo.company.uuid || userInfo.uuid),
              ),
          isAttendee: status,
        })
      })
  }

  let div = document.createElement("div")
  div.innerHTML = draftToHtml(JSON.parse(data.description))

  if(div.getElementsByTagName("a").length){
    div.getElementsByTagName("a").forEach((link) => {
      link.classList.add("goldLink")
      link.classList.add("contactLink")
    })
  }

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("contactLink")) {
        e.preventDefault()
        copy(e.target.innerHTML)
        e.target.classList.add(styles.copied)
        setTimeout(() => {
          e.target.classList.remove(styles.copied)
        }, 1500)
      }
    })
  }, [])

  return (
    <div className={styles.containerWrap}>
      {deletePopupOpened && (
        <Popup
          dialog
          subtitle="Are you sure you want to cancel your attendance?"
          button={
            <Button buttonStyle="black" onClick={deleteResource}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setDeletePopupOpened(false)
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setDeletePopupOpened(false)
          }}
          isCrossIcon={true}
        />
      )}

      <ResourcePageHeader
        badgeText={type.badgeText}
        badgeBgColor={type.badgeColor}
        bgImg={type.image}
        event_type={data.event_type}
        cost={data.cost}
        uuid={data.uuid}
        title={data.topic}
        subTitle={`Company - ${data.company?.name} • ${data.location}`}
        company={data.company}
        onDelete={() => setDeletePopupOpened(true)}
        onAttend={onAttend}
        isAttendee={attendeeInfo.isAttendee}
      />

      <ResourcePageDetails data={data} />

      <div className={styles.resourceTextContent}>
        {data.description && (
          <React.Fragment>
            <h5>Description:</h5>
            <div
              className={styles.resourceTextItem}
              dangerouslySetInnerHTML={{
                __html: div.outerHTML,
              }}
            />
          </React.Fragment>
        )}

        {data.key_benefits && (
          <React.Fragment>
            <h5>Key Beneﬁts:</h5>
            <div className={styles.resourceTextItem}>{data.key_benefits}</div>
          </React.Fragment>
        )}

        {data.who_should_attend && (
          <React.Fragment>
            <h5>Who Should Attend:</h5>
            <div className={styles.resourceTextItem}>
              {data.who_should_attend}
            </div>
          </React.Fragment>
        )}
      </div>

      <AttendeesList
        companyUuid={uuid}
        attendees={attendeeInfo.attendees}
        attendeesCount={attendeeInfo.attendeesCount}
      />

      <div className={styles.mapArea}>
        <GoogleMap lat={data.lat} lng={data.long} />
      </div>
    </div>
  )
}

ResourcePageContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  data: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
}

export default ResourcePageContainer
