import axios from "./axios"

export default {
  getList: async (params) => {
    try {
      return await axios.get("/favorites", {
        params,
      })
    } catch (error) {
      throw error
    }
  },
  addRemoveToList: async ({ type, uuid, favorite }) => {
    try {
      return await axios.post("/favorites", {
        type,
        uuid,
        favorite,
      })
    } catch (error) {
      throw error
    }
  },
}
