export const topics = [
  {
    title: "All",
    type: "",
    tooltipText: "",
  },
  {
    title: "Business News",
    type: "business_news",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Cryptocurrency",
    type: "cryptocurrency",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Cybersecurity",
    type: "cybersecurity",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Energy",
    type: "energy",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Financial Services",
    type: "financial_services",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Healthcare",
    type: "healthcare",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Industrial",
    type: "industrial",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Publications",
    type: "publications",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Technology",
    type: "technology",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Telecom",
    type: "telecom",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
]
