import React from "react"
import PropTypes from "prop-types"
import ProfileTableItem from "../../ProfileTableItem"
import classNames from "classnames"
import styles from "./FreelancerDescription.module.scss"
import CopyToClipboard from "../../../CopyToClipboard"

const FreelancerDescription = ({ data }) => {

  return (
    <div>
      <ProfileTableItem
        title="Description"
        hasData={!!(data && data.length)}
        anotherUser={true}
      />
      <div
        className={classNames({
          [styles.wrapper]: data ,
          [styles.hiddenWrapper]: !data
        })}
      >
        <div className={styles.content}>
            <div className={styles.contentText}>
              <CopyToClipboard text={data ?? "text"} />
            </div>
        </div>
      </div>
    </div>
  )
}

FreelancerDescription.propTypes = {
  data: PropTypes.string,
}

FreelancerDescription.defaultProps = {
  data: "",
}

export default FreelancerDescription
