export const checkIsFilterUsed = ({ searchParamGroup }) => {
  if (
    searchParamGroup.resource_type ||
    searchParamGroup.resource_sub_type ||
    searchParamGroup.event_type ||
    searchParamGroup.from ||
    searchParamGroup.to ||
    searchParamGroup.locations ||
    searchParamGroup.free_only
  ) {
    return true
  } else {
    return false
  }
}
