import React from "react";
import styles from "./HigherLearningItem.module.scss";
import classNames from "classnames";

const HigherLearningStaticItem = (data) => {
  return (
    <div
      className={classNames(styles.staticEducationWrapper, styles.staticData)}
    >
      <div className={styles.itemWrapper}>
        <p>School or University:</p>
        <div className={styles.formWrapper}>
          <p>{data.name}</p>
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <p>Degree:</p>
        <div className={styles.formWrapper}>
          <p>{data.degree}</p>
        </div>
      </div>
      <div className={styles.itemWrapper}>
        <p>Date period:</p>
        <div className={classNames(styles.formWrapper, styles.periodWrapper)}>
          <div className={styles.period}>
            <p>{data.start.month.value} &nbsp;</p>
            <p>{data.start.year.value}</p>
          </div>
          <span className={styles.devider}>–</span>
          <div className={styles.period}>
            <p>{data.finish.month.value} &nbsp;</p>
            <p>{data.finish.year.value}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HigherLearningStaticItem;
