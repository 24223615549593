import React, { useEffect, useState } from "react"
import { withFormik } from "formik"
import { object, number } from "yup"
import styles from "./DepartmentCreatorForm.module.scss"
import Input from "../../components/Input/Input"
import Button from "../../components/Button/Button"
import Dropdown from "../../components/Dropdown/Dropdown"
import { departments } from "../../components/CompanyDepartments/helpers"

const DepartmentCreatorForm = ({
  closeAction,
  values,
  errors,
  handleSubmit,
  handleChange,
  handleBlur,
  setFieldTouched,
  touched,
  isSubmitting,
  maxNumber,
  departmentInfo,
  setFieldValue,
}) => {
  return (
    <form className={styles.departmentForm} onSubmit={handleSubmit}>
      <div className={styles.inlineWrap}>
        {!maxNumber || departmentInfo ? (
          <>
            <Dropdown
              label="Department name"
              id="departmentName"
              name="departmentName"
              placeholder="Select department"
              options={departments}
              onChange={(name, e) => {
                setFieldValue("departmentName", e)
              }}
              error={errors.departmentName && touched.departmentName}
              errorMessage={
                errors.departmentName &&
                touched.departmentName &&
                errors.departmentName
              }
              value={values.departmentName}
              onBlur={(e) => {
                setFieldTouched("departmentName", true)
              }}
              border={false}
              isWhiteBackground={true}
            />

            <Input
              value={values.employees}
              name="employees"
              onChange={(e) => {
                if (e.target.validity.valid) {
                  handleChange(e)
                }
              }}
              onBlur={handleBlur}
              min={0}
              fieldStyle="light"
              label="Employees"
              placeholder="Number of employees"
              error={errors.employees && touched.employees}
              errorMessage={
                errors.employees && touched.employees && errors.employees
              }
              pattern="[0-9]*"
            />
          </>
        ) : (
          <p className={styles.errorMessage}>
            Sorry, you can`t add more than 12 departments.
          </p>
        )}
      </div>
      <div className={styles.btnsWrapper}>
        <Button
          buttonStyle="outlinedBlack"
          height="40px"
          width="82px"
          onClick={closeAction}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="black"
          height="40px"
          width="64px"
          type="submit"
          disabled={
            Object.keys(errors).length > 0 ||
            !values.departmentName ||
            !values.employees ||
            isSubmitting
          }
        >
          Save
        </Button>
      </div>
    </form>
  )
}

const FormikApp = withFormik({
  mapPropsToValues({ departmentInfo }) {
    if (!!departmentInfo) {
      return {
        departmentName: departments.find(
          (department) => department.value === departmentInfo.name,
        ),
        employees: departmentInfo.employees_count,
      }
    }

    return {
      departmentName: "",
      employees: "",
    }
  },
  validationSchema: () =>
    object().shape({
      departmentName: object().required("Please select department name"),
      employees: number()
        .min(
          1,
          "Please enter a valid number of employees that is no less than 1 employee",
        )
        .max(
          999999,
          "Please enter a valid number of employees that is no more than 999999 employees",
        )
        .required("Please enter number of employees"),
    }),
  handleSubmit(
    values,
    { setSubmitting, setFieldError, props: { handleSave, closeAction } },
  ) {
    handleSave({
      departmentName: values.departmentName.value,
      employees: Number(values.employees),
    })
      .then(() => {
        setSubmitting(false)
        closeAction()
      })
      .catch((e) => {
        if (e.response.status === 422) {
          setSubmitting(false)

          if (e.response.data?.error === "Department already added") {
            setFieldError("departmentName", e.response.data?.error)
          }

          if (e.response.data?.error?.includes("valid number")) {
            setFieldError("employees", e.response.data?.error)
          }
        }
      })
  },
})(DepartmentCreatorForm)

export default FormikApp
