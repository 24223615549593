import React from "react"
import classNames from "classnames"
import { useHistory } from "react-router-dom"

import styles from "./Resources.module.scss"
import ResourceTypeFilter from "../../filters/ResourceTypeFilter"
import PageLinedTitleHome from "../../elements/PageLinedTitleHome"
import ResourceCard from "../../cards/TrueResourceCard"
import TrendingCard from "../../TrendingCard/TrendingCard"
import Button from "../../Button/Button"
import { makeCutDottedString } from "../../../helpers/textHelpers"
import NoDataPlaceholder from "../../elements/NoDataPlaceholder"

const Resources = ({
  resources,
  trending,
  handleChangeResourceType,
  companyInfo,
}) => {
  const history = useHistory()
  const handleClick = (uuid) => history.push(`resources/${uuid}`)

  const showMore = () => history.push("/resource-search")

  const showMoreNews = () => history.push("/institute-search")

  const handleNewsClick = (uuid) => history.push(`/institute-article/${uuid}`)

  return (
    <div className={styles.wrapper}>
      <PageLinedTitleHome title={"Resources"} subTitle={"Trending Topics"} />

      <div className={classNames("container", "fullWidth", styles.container)}>
        <ResourceTypeFilter onSelectType={handleChangeResourceType} />

        <div className={styles.contentWrapper}>
          <div className={styles.cardsWrapper}>
            {resources?.length ? (
              resources.map((resource) => (
                <ResourceCard
                  type={resource.resource_type}
                  photo={
                    resource?.video?.thumbnail || resource?.photo?.path || null
                  }
                  classes={styles.card}
                  title={resource.topic}
                  location={resource.location}
                  date={resource.date}
                  time={resource.time}
                  onClick={() => handleClick(resource.uuid)}
                />
              ))
            ) : (
              <div
                className={styles.showAllButton}
                style={{ marginBottom: 24 }}
              >
                <NoDataPlaceholder>
                  Nothing here yet.{" "}
                  {companyInfo.type === "company_provider"
                    ? "Be the first one to contribute."
                    : "Please try again later."}
                </NoDataPlaceholder>
              </div>
            )}

            <div className={styles.showAllButton}>
              <Button buttonStyle="outlined" onClick={showMore}>
                VIEW ALL
              </Button>
            </div>
          </div>

          <div className={styles.trending}>
            {trending &&
              trending.map((topic) => (
                <TrendingCard
                  key={topic.uuid}
                  imgUrl={topic.image_url}
                  title={topic.title}
                  text={makeCutDottedString(topic.content, 78)}
                  classes={styles.card}
                  onClick={() => handleNewsClick(topic.uuid)}
                  uuid
                />
              ))}
            <div
              className={classNames(styles.showAll, "goldLink")}
              onClick={showMoreNews}
            >
              show all news
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resources
