import React, { useEffect, useState } from "react"
import styles from "./index.module.scss"
import copy from "copy-to-clipboard"

const emailRegExp = /\s?([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi
const telRegExp = /\s?[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\./0-9]{6,15}/g
const linkRegExp = /\s?https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
const CopyToClipboard = ({ text }) => {
  const [timeoutName, setTimeoutName] = useState("")

  const clickHandler = (e) => {
    if (e.target.classList.contains(styles.mark) && e?.target?.textContent.match(linkRegExp)) {
      window.open(e.target.textContent, '_blank');
    } else if (e.target.classList.contains(styles.mark)) {
        copyText(e.target.textContent)
        e.target.classList.add(styles.copied)

        const timeout = setTimeout(() => {
            e.target.classList.remove(styles.copied)
        }, 1500)
        setTimeoutName(timeout)
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickHandler)
    return () => {
      document.removeEventListener("click", clickHandler)
      clearTimeout(timeoutName)
    }
  }, [text])

  const copyText = (copiedText) => {
    // navigator.clipboard.writeText(copiedText)
    // document.execCommand("copy")
    copy(copiedText)
  }

  const findChunks = (textToHighlight) => {
    const chunk = []

    const text = textToHighlight.toLowerCase()

    const emails = text.match(emailRegExp)
    const telephones = text.match(telRegExp)
    const webSites = text.match(linkRegExp)

    if (emails && emails.length > 0) {
      emails.forEach((email) => chunk.push(email.trim()))
    }

    if (telephones && telephones.length > 0) {
      telephones.forEach((tel) => chunk.push(tel.trim()))
    }

    if (webSites && webSites.length > 0) {
      webSites.forEach((site) => chunk.push(site.trim()))
    }

    return chunk
  }

  function renderHighlights() {
    let span = React.createElement("span", {
      style: {
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        wordWrap: "break-word",
        display: "inline-block",
        width: "100%",
      },
    })
    const chunks = findChunks(text)
    let newStr = text

    if (chunks.length > 0) {
      chunks.forEach((chunk) => {
        console.log(newStr, chunk)
        newStr = newStr.replace(chunk, `<mark class=${styles.mark}>$&</mark>`)

        span = React.cloneElement(span, {
          dangerouslySetInnerHTML: { __html: newStr },
        })
      })
    } else {
      span = React.cloneElement(span, null, text)
    }

    return span
  }

  return renderHighlights()
}

export default CopyToClipboard
