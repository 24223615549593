import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { opportunities } from "./opportunities"
import styles from "./index.module.scss"

const OpportunityTypeFilter = ({ classes, searchParamGroup, onSelectType }) => {
  const [activeType, setActiveType] = useState(opportunities[0].type)
  useEffect(() => {
    setActiveType(searchParamGroup.opportunity_type || "")
  }, [searchParamGroup])
  return (
    <div className={classNames(styles.filter, classes)}>
      {opportunities.map((opportunity) => (
        <span
          className={classNames({
            [styles.activeType]: activeType === opportunity.type,
          })}
          onClick={() => onSelectType(opportunity.type)}
        >
          {opportunity.title}
        </span>
      ))}
    </div>
  )
}
OpportunityTypeFilter.propTypes = {
  /** Callback gets active opportunity type as argument */
  onSelectType: PropTypes.func,
}
export default OpportunityTypeFilter
