import React from "react"
import classnames from "classnames"

import styles from "./index.module.scss"
import FreelancerCard from "../../../components/cards/FreelancerCard";

const Freelancers = ({ data }) => {

    return (
        <div className={classnames(styles.listWrap, styles.opportunityList)}>
            {data.map((item) => (
                <FreelancerCard
                    key={item.uuid}
                    freelancerAvatar={item.photo}
                    freelancerName={item.name}
                    freelancerUuid={item.uuid}
                    externalId={item.external_id}
                    chatUuid={"1"}
                    description={item.description}
                    isOwned={false}
                    onClick={()=>{}}
                    onChatOpen={()=>{}}
                    isHaveChatButton={true}
                    isFavorite={item.favorite}
                    skills={item.title}
                    classes={styles.cardWrapper}
                />
            ))}
        </div>
    )
}

export default Freelancers
