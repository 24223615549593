import React, { useState, useEffect } from "react"
import {Link, useParams, useHistory, useLocation, Redirect} from "react-router-dom"
import classnames from "classnames"
import { useSelector } from "react-redux"
import queryString from "query-string"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"

import styles from "./CompanyOpportunitiesContainer.module.scss"
import services from "../../services"
import OpportunityCard from "../../components/cards/OpportunityCard"
import avatar from "../../images/defaultUserAvatar.png"
import ButtonComponent from "../../components/Button/Button"
import CompanyAddonTypePopup from "../../components/CompanyAddonTypePopup"
import { opportunitiesTypeCards } from "../../components/Opportunity/heplers"
import Pagination from "../../components/elements/Pagination"
import PageLinedTitle from "../../components/elements/PageLinedTitle"
import NoDataPlaceholder from "../../components/elements/NoDataPlaceholder"
import CustomTooltip from "../../components/CompanyUserInfo/CustomTooltip"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import DropdownListSort from "../../components/DropdownListSort"
import Loading from "../../components/Loading/Loading"
import {COMPANY_SEEKER} from "../../helpers/userRoles";
import {paths} from "../../routes/paths";

const sortOptions = [
  {
    value: "a_to_z",
    label: "A to Z",
    arrow: "up",
  },
  {
    value: "z_to_a",
    label: "Z to A",
    arrow: "down",
  },
  {
    value: "newest",
    label: "Newest",
    arrow: "up",
  },
  {
    value: "oldest",
    label: "Oldest",
    arrow: "down",
  },
]

const CompanyOpportunitiesContainer = ({ isPrivate }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const userInfo = useSelector((state) => state.user)
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const { width } = useWindowHeightWidth()
  const queryParams = queryString.parse(location.search)
  const [currentSort, setCurrentSort] = useState(null)

  const currentPage = getCurrentPage(location)
  const isAbleToEdit = userInfo.company.uuid === data["company_uuid"]
  isPrivate = isPrivate === "true";

  const tooltipText = data?.total_departments_count
    ? null
    : "Please add at least one department before adding an opportunity."

  useEffect(() => {
    if (queryParams.sort_by) {
      setCurrentSort(
        sortOptions.find((option) => option.value === queryParams.sort_by)
          ?.value || sortOptions[2].value,
      )
    } else {
      setCurrentSort(sortOptions[2].value)
    }
  }, [queryParams])

  useEffect(() => {
    setLoading(true)

    services.opportunityServices
      .getOpportunitiesByUuid(params.uuid, {
        page: currentPage,
        sort_by: currentSort,
        is_private: isPrivate,
      })
      .then((result) => {
        let res = {
          ...result.data,
          opportunities: result.data.opportunities.on_page,
          totalCount: result.data.opportunities.total_count,
        }

        setData(res)
        setLoading(false)
      })
  }, [currentPage, params.uuid, currentSort, isPrivate])

  const handleClick = (e, opportunitie) => {
    if (e.target.dataset.type !== "button") {
      history.push(`/opportunity?id=${opportunitie["uuid"]}`)
    }
  }

  const onSort = (type) => {
    const routeParams = queryString.stringify({
      ...queryParams,
      sort_by: type.value,
    })

    history.push(`/opportunities/${params.uuid}?${routeParams}`)
  }

  const addBtnTitle = !isPrivate ? "Add Opportunity" : "Add Private Opportunity";
  const pageTitle = `${isAbleToEdit ? "My" : "Company/Organization"}${isPrivate ? " Private" : ""} Opportunities`;

  return (
    <div className={styles.opportunitiesContainerWrapper}>
      {isPopupOpened && (
        <CompanyAddonTypePopup
          width={952}
          closeAction={setIsPopupOpened}
          title={addBtnTitle}
          handleNextClick={(a) =>
            history.push(`/opportunity-edit?variant=${a.type}&private=${isPrivate}`)
          }
          data={opportunitiesTypeCards}
        />
      )}

      {
        <>
          <div className={styles.opportunitiesContainer}>
            <div
              className={classnames("container", "fullWidth", styles.Wrapper)}
            >
              <Breadcrumbs className={styles.breadcrumbs}>
                <Link
                  to={`/company/${data["company_uuid"]}`}
                  className={styles.link}
                >
                  <p>{data["company_name"]}</p>
                </Link>

                <Typography className={classnames(styles.link, styles.currentLink)}>
                  <p>{pageTitle}</p>
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <PageLinedTitle title={pageTitle}/>
          <div className={styles.opportunitiesContainer}>
            <div className={classnames("container", "fullWidth", styles.Wrapper)}>
              <div className={styles.navBar}>
                <div>
                  <DropdownListSort
                    options={sortOptions}
                    selectedValue={currentSort}
                    onChange={onSort}
                  />
                </div>

                <div className={styles.navBarButtonHandler}>
                  <CustomTooltip
                    text={tooltipText}
                    children={
                      <span className={styles.tooltipWrapper}>
                        <ButtonComponent
                          disabled={data?.total_departments_count === 0}
                          className={classnames(
                            styles.navBarButton,
                            "text16r",
                            !isAbleToEdit && styles.navBarButtonDisabled,
                          )}
                          onClick={() => setIsPopupOpened(true)}
                          height="40px"
                          width={isPrivate ? "200px" : undefined}
                        >
                          {width > 425 ? (isPrivate ? "Add Private Opportunity" : "Add Opportunity") : "Add"}
                        </ButtonComponent>
                      </span>
                    }
                  />
                </div>
              </div>
              {loading && <Loading />}

              {data.opportunities?.length === 0 && (
                <NoDataPlaceholder>No opportunities yet</NoDataPlaceholder>
              )}

              {!loading && data.opportunities?.length > 0 && (
                <div className={styles.opportunitiesWrapper}>
                  {data.opportunities.map((opportunity) => (
                    <OpportunityCard
                      key={opportunity["created_at"]}
                      uuid={opportunity.uuid}
                      type={opportunity["opportunity_type"]}
                      avatar={data["company_photo"] || avatar}
                      description={opportunity["description"]}
                      location={{
                        city: opportunity["location"],
                        head: data["company_name"],
                      }}
                      caption={opportunity["name"]}
                      timestamp={new Date(opportunity["created_at"])}
                      onClick={(e) => handleClick(e, opportunity)}
                    />
                  ))}
                </div>
              )}

              {!loading &&
                data.totalCount !== 0 &&
                getPageCount(data.totalCount) > 1 && (
                  <div style={{ padding: "20px", textAlign: "center" }}>
                    <Pagination
                      currentPageNumber={currentPage}
                      totalPageCount={getPageCount(data.totalCount)}
                      onSelect={(page) => {
                        const routeParams = queryString.stringify({
                          ...queryParams,
                          page,
                        })
                        history.push(`${location.pathname}?${routeParams}`)
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default CompanyOpportunitiesContainer

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}

const getPageCount = (totalCount) => Math.ceil(totalCount / 6) || 1
