import React from "react"
import Checkbox from "../../../../Checkbox/Checkbox"
import { setIsFree } from "../../useReducer/actionCreators"

const FreeCheckbox = ({ classes, externalDispatch, checked }) => {
  return (
    <Checkbox
      className={classes}
      label="Free only"
      checked={checked}
      onChange={({ target: { checked } }) =>
        externalDispatch(setIsFree(checked))
      }
    />
  )
}
export default FreeCheckbox
