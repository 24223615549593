import {
  TOGGLE_COLLAPSE,
  SET_WHOLE_STATE,
  SET_RESOURCE_TYPE,
  SET_EVENT_TYPE,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_ACCESS_TYPE,
  SET_LOCATION,
  SET_LOCATION_INPUT,
  SET_IS_FREE,
  RESET_FORM,
} from "./reducer"

export const toggleCollapse = () => ({
  type: TOGGLE_COLLAPSE,
  payload: null,
})
export const setWholeState = (searchParamGroup) => ({
  type: SET_WHOLE_STATE,
  payload: { searchParamGroup },
})
export const setResourceType = (resourceType) => ({
  type: SET_RESOURCE_TYPE,
  payload: { resourceType },
})
export const setEventType = (eventType) => ({
  type: SET_EVENT_TYPE,
  payload: { eventType },
})
export const setFromDate = (fromDate) => ({
  type: SET_FROM_DATE,
  payload: { fromDate },
})
export const setToDate = (toDate) => ({
  type: SET_TO_DATE,
  payload: { toDate },
})
export const setAccessType = (accessType) => ({
  type: SET_ACCESS_TYPE,
  payload: { accessType },
})
export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: { location },
})
export const setLocationInput = (locationInput) => ({
  type: SET_LOCATION_INPUT,
  payload: { locationInput },
})
export const setIsFree = (isFree) => ({
  type: SET_IS_FREE,
  payload: { isFree },
})
export const resetForm = () => ({
  type: RESET_FORM,
  payload: null,
})
