import React from "react"
import classNames from "classnames"
import { ReactComponent as DeleteIcon } from "../../../images/icons/delete-filled.svg"
import styles from "./index.module.scss"

const TrueTag = ({ classes, title, onClick }) => (
  <div className={classNames(styles.wrapper, classes)} onClick={onClick}>
    <p className={classNames("text16r", styles.title)}>{title}</p>
    <DeleteIcon className={styles.deleteIcon} />
  </div>
)
export default TrueTag
