import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./TrendingCard.module.scss"

const TrendingCard = ({ classes, title, text, imgUrl, onClick }) => {
  return (
    <div className={classNames(styles.card, classes)} onClick={onClick}>
      <h6 className={styles.title}>{title}</h6>

      {!!text && <p className={styles.description}>{text}</p>}

      <div
        className={styles.img}
        style={{ backgroundImage: `url(${imgUrl})` }}
      />
    </div>
  )
}

TrendingCard.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
}

export default TrendingCard
