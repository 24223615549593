import { isHaveBadWords } from "../../../../helpers/badWords"

const validation = (value) => {
  if (value.length === 0 && value[0] === undefined) return ""
  if (isHaveBadWords(value)) return "Please don't use bad language"
  if (value.length > 1000)
    return "Please enter a correct story that consists of no more than 1000 characters"
  if (value.trim().length < 8 || value.trim().length === 0)
    return "Please enter a correct story that consists of a minimum 8 characters"
}

export default validation
