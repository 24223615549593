import React from "react"
import PropTypes from "prop-types"
import Button from "../Button/Button"
import Popup from "../Popup/Popup"
import styles from "./styles.module.scss"
import { ReactComponent as Circle } from "../../../src/images/icons/circle.svg"
import { ReactComponent as On } from "../../../src/images/icons/On.svg"

const CompanyAddonTypePopup = ({
  data,
  closeAction,
  handleNextClick,
  title,
  width,
}) => {
  const [AddonTypeID, setAddonTypeID] = React.useState(null)

  return (
    <Popup
      // width={width}
      // height="900"
      title={title}
      closeAction={() => closeAction(false)}
      button={
        <Button
          buttonStyle="outlinedBlack"
          // width="82px"
          onClick={() => closeAction(false)}
          style={{
            height: "auto",
            width: "auto",
            padding: "8px 24px",
          }}
        >
          Cancel
        </Button>
      }
      acceptButton={
        <Button
          buttonStyle="contained"
          // width="74px"
          disabled={!AddonTypeID}
          onClick={() =>
            handleNextClick(data.find((el) => el.id === AddonTypeID))
          }
          style={{
            height: "auto",
            width: "auto",
            padding: "8px 24px",
          }}
        >
          Next
        </Button>
      }
      childrenOverflowScroll
    >
      {data.length && (
        <div className={styles.cardsWrap}>
          {data.map((card) => (
            <div
              key={card.id}
              className={styles.cardWrap}
              onClick={() => setAddonTypeID(card.id)}
            >
              <div>
                <img
                  src={card.imgUrl}
                  alt={card.title}
                  className={styles.cardImage}
                />
              </div>

              <div className={styles.title}>{card.title}</div>

              <span className={styles.Circle}>
                {card.id === AddonTypeID ? (
                  <On width="20" height="20" />
                ) : (
                  <Circle />
                )}
              </span>
            </div>
          ))}
        </div>
      )}
    </Popup>
  )
}

CompanyAddonTypePopup.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      imgUrl: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,

  handleNextClick: PropTypes.func,
  title: PropTypes.string,
}

CompanyAddonTypePopup.defaultProps = {
  width: 953,
  handleNextClick: () => console.log("Callback on next"),
  title: "Add",
}

export default CompanyAddonTypePopup
