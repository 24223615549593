import axios from "./axios"

export default {
  applyOpportunity: async (companyUUID) => {
    try {
      const result = await axios.post(`/opportunities/${companyUUID}/prospects`)
      return result
    } catch (error) {
      throw error
    }
  },
  refuseOpportunity: async (companyUUID) => {
    try {
      const result = await axios.delete(`/opportunities/${companyUUID}/refuse`)
      return result
    } catch (error) {
      throw error
    }
  },
  getProspects: async (query) => {
    try {
      const result = await axios.get(`/self/prospects?${query}`)
      return result
    } catch (error) {
      throw error
    }
  },
  getMyProspects: async (query) => {
    try {
      const result = await axios.get(`/self/my_prospects?${query}`)
      return result
    } catch (error) {
      throw error
    }
  },
  changeProspectStatus: async (uuid, status) => {
    try {
      const result = await axios.put(`/prospects/${uuid}`, { status })
      return result
    } catch (error) {
      throw error
    }
  },
}
