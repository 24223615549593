import React from "react"
import styles from "./ChoosePasswordPage.module.scss"
import ChoosePasswordContainer from "../../containers/ChoosePasswordContainer/ChoosePasswordContainer"

const ChoosePasswordPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <ChoosePasswordContainer />
    </div>
  )
}

export default ChoosePasswordPage
