import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./SignUpRadio.module.scss";

const SignUpRadio = ({
  onClick,
  selected,
  image,
  title,
  description,
  className,
  name,
}) => {
  const classes = classNames(
    styles.item,
    { [styles.selected]: selected },
    className
  );
  return (
    <div
      className={classes}
      onClick={() => {
        onClick(name);
      }}
    >
      <span />
      <div className={styles.content}>
        {image}
        <h4 className={styles.title}>{title}</h4>
        {description && <p className={styles.description}>{description}</p>}
      </div>
    </div>
  );
};

SignUpRadio.propTypes = {
  selected: PropTypes.bool,
};

SignUpRadio.defaultProps = {
  selected: false,
};

export default SignUpRadio;
