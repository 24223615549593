import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Prompt, useHistory, useParams, useLocation } from "react-router-dom"
import queryString from "query-string"

import styles from "./ChoosePasswordContainer.module.scss"
import ChoosePasswordForm from "../../forms/ChoosePasswordForm/ChoosePasswordForm"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"
import { paths } from "../../routes/paths"
import services from "../../services"
import { selectFormattedUser } from "../../reducers/selectors"
import { userLogin } from "../../actions/user/userActions"
import { save } from "../../helpers/localStorage"
import ResetPasswordError from "../../components/ResetPasswordError/ResetPasswordError"
import Loading from "../../components/Loading/Loading"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"

const wordingTexts = {
  invite_employee: {
    title: "Choose password",
    description: "Please choose your password to sign in.",
    passwordLabel: "Password",
    passwordPlaceholder: "Enter your password",
    confirmPasswordLabel: "Password confirmation",
    confirmPasswordPlaceholder: "Confirm your password",
    submitBtn: "Sign in",
    cancelPopupMsg: "Are you sure you want to cancel registration?",
    linkExpiredMsg:
      "This link has expired. Please contact the company profile administrator.",
    redirectOnCancel: paths.home,
  },
  reset_password: {
    title: "Password retrieval",
    passwordLabel: "New password",
    passwordPlaceholder: "Enter new password",
    confirmPasswordLabel: "Confirm password",
    confirmPasswordPlaceholder: "Confirm password",
    submitBtn: "Change Password",
    cancelPopupMsg: "Are you sure you want to cancel password retrieval?",
    linkExpiredMsg:
      "This link has expired. Please send another request to reset password.",
    redirectOnCancel: paths.login,
  },
}

const ChoosePasswordContainer = () => {
  const [isBrokenToken, setIsBrokenToken] = useState(false)
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [isLoading, setLoadingStatus] = useState(true)
  const [isConfirmSucceed, setConfirmStatus] = useState(false)

  const history = useHistory()
  const { token } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()

  const queryParams = queryString.parse(location.search)

  const handleCancel = () => {
    setVisiblePopup(true)
  }

  useEffect(() => {
    if (token) {
      services.employeeServices
        .checkToken(token)
        .catch((e) => {
          setIsBrokenToken(true)
        })
        .finally(() => {
          setLoadingStatus(false)
        })
    }
  }, [token])

  const handleSubmit = (password) => {
    services.employeeServices
      .setPassword(password, token)
      .then((res) => {
        if (queryParams.reason === "invite_employee") {
          dispatch(userLogin(res.data))
          save("currentUser", selectFormattedUser(res.data))
        }

        if (queryParams.reason === "reset_password") {
          setConfirmStatus(true)

          setTimeout(() => {
            history.push(paths.login)
          }, 4000)
        }
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className={styles.containerWrapper}>
      <Prompt
        when={!visiblePopup && !isBrokenToken && !isConfirmSucceed}
        message={
          wordingTexts[queryParams.reason].cancelPopupMsg ||
          "Are you sure you want to cancel registration?"
        }
      />

      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {isBrokenToken && (
            <ResetPasswordError
              errorMessage={
                wordingTexts[queryParams.reason].linkExpiredMsg ||
                "This link has expired. Please contact the company profile administrator."
              }
            />
          )}

          {isConfirmSucceed && (
            <XYCenterAlignedWindow title="Congratulations! New password confirmed">
              <div className={styles.successMsgText}>
                You will be redirected to main page in few seconds
              </div>
            </XYCenterAlignedWindow>
          )}

          {!isBrokenToken && !isConfirmSucceed && (
            <div className={styles.box}>
              <h2>
                {wordingTexts[queryParams.reason].title || "Choose password"}
              </h2>

              {wordingTexts[queryParams.reason].description && (
                <p className={styles.text}>
                  {wordingTexts[queryParams.reason].description}
                </p>
              )}

              <ChoosePasswordForm
                handleCancel={handleCancel}
                handleSubmit={handleSubmit}
                wordingTexts={wordingTexts[queryParams.reason]}
              />
            </div>
          )}
        </React.Fragment>
      )}

      {visiblePopup && (
        <Popup
          dialog
          subtitle={
            wordingTexts[queryParams.reason].cancelPopupMsg ||
            "Are you sure you want to cancel registration?"
          }
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                history.push(
                  wordingTexts[queryParams.reason].redirectOnCancel ||
                    paths.home,
                )
              }}
            >
              Yes
            </Button>
          }
          acceptButton={
            <Button
              onClick={() => setVisiblePopup(false)}
              buttonStyle="outlinedBlack"
            >
              No
            </Button>
          }
          closeAction={() => setVisiblePopup(false)}
          isCrossIcon={true}
        />
      )}
    </div>
  )
}

export default ChoosePasswordContainer
