import axios from "./axios"

export default {
  getAccountSettings: async (params) => {
    try {
      return await axios.get("/self/settings")
    } catch (error) {
      throw error
    }
  },

  setAccountSettings: async (data) => {
    try {
      return await axios.put("/self/settings", data)
    } catch (error) {
      throw error
    }
  },

  resendEmail: async () => {
    try {
      return await axios.get("/self/resend_email")
    } catch (error) {
      throw error
    }
  },

  clearToken: async () => {
    try {
      return await axios.get("/self/clear_token")
    } catch (error) {
      throw error
    }
  },

  confirmUpdateEmail: async (token) => {
    try {
      return await axios.put("/self/update_email", {
        change_email_token: token,
      })
    } catch (error) {
      throw error
    }
  },
}
