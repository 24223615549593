import React from "react"
import moment from "moment"
import TrueTag from "../../../../components/elements/TrueTag"
import { getQuestionTypeName, getCreatorTypeName } from "./helpers"
import {
  removeQuestionType,
  removeCreatorType,
  removeFromDate,
  removeToDate,
} from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const TagWrapper = ({ classes, searchParamGroup, dispatch }) => {
  const { question_type, creator_type, from, to } = searchParamGroup
  return (
    <div className={classes}>
      {Boolean(question_type) && (
        <TrueTag
          classes={styles.tag}
          title={getQuestionTypeName(question_type)}
          onClick={() => dispatch(removeQuestionType())}
        />
      )}
      {Boolean(creator_type) && (
        <TrueTag
          classes={styles.tag}
          title={getCreatorTypeName(creator_type)}
          onClick={() => dispatch(removeCreatorType())}
        />
      )}
      {Boolean(from) && (
        <TrueTag
          classes={styles.tag}
          title={`From: ${moment(from).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeFromDate())}
        />
      )}
      {Boolean(to) && (
        <TrueTag
          classes={styles.tag}
          title={`To: ${moment(to).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeToDate())}
        />
      )}
    </div>
  )
}
export default TagWrapper
