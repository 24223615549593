import React from "react"
import ReportCard from "../../cards/ReportCard"
import { INDIVIDUAL } from "../../../helpers/userRoles"

const ReportsList = ({ data }) => {
  if (!data.length) {
    return null
  }

  return (
    <>
      {data.length &&
        data.map((report) => (
          <ReportCard
            inSidebar
            createdAt={report.created_at}
            title={report.title}
            toUserType={INDIVIDUAL}
            body={report.description}
            onUserType={
              report?.reported?.company?.company_type ||
              report.reported.account_type
            }
            toUserName={
              report.reporter.company?.name || report.reporter.full_name
            }
            onUserName={
              report.reported.company?.name || report.reported.full_name
            }
            toUserPhoto={getReportToPhoto(report)}
            onUserLink={""}
            onUserPhoto={getReportOnPhoto(report)}
            toUserLink={""}
            key={report.uuid}
            reportLink={`/reports/${report.uuid}`}
            email={report.reported.email}
          />
        ))}
    </>
  )
}
export const getReportToPhoto = (report) => {
  const accountType = report.reporter.account_type
  if (accountType !== INDIVIDUAL) {
    return report.reporter.company.photo
  }
  return report.reporter.photo
}

export const getReportOnPhoto = (report) => {
  const accountType = report.reported.account_type
  if (accountType !== INDIVIDUAL) {
    return report.reported.company.photo
  }
  return report.reported.photo
}

export default ReportsList
