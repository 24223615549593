import React from "react";
import styles from "./IndividualProfilePage.module.scss";
import IndividualProfileContainer from "../../containers/IndividualProfileContainer/IndividualProfileContainer";

const IndividualProfilePage = () => {
  return (
    <div className={styles.profilePageWrapper}>
      <IndividualProfileContainer />
    </div>
  );
};

export default IndividualProfilePage;
