import React from "react"
import GoogleMapReact from "google-map-react"
import PropTypes from "prop-types"
import pin from "../../images/icons/pin.png"

import mapStyles from "./map-styles.json"

const GoogleMap = ({ lat, lng, zoom }) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyAEEuecYrXnoBhFiRhQyrNlaZE0aMVoP3s",
        }}
        defaultCenter={{ lat, lng }}
        defaultZoom={zoom}
        options={{
          styles: mapStyles,
        }}
      >
        <AnyReactComponent lat={lat} lng={lng} />
      </GoogleMapReact>
    </div>
  )
}

GoogleMap.defaultProps = {
  lat: 33.7489954,
  lng: -84.3879824,
  zoom: 14,
}

GoogleMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
}

export default GoogleMap

const AnyReactComponent = () => <img style={{ width: "30px" }} src={pin}></img>
