import React from "react"
import { Link, NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import classnames from "classnames"
import { IconButton } from "@material-ui/core"

import styles from "./index.module.scss"
import { objectComparison } from "../../../helpers"
import { ReactComponent as Mail } from "../../../images/icons/mail.svg"
import { ReactComponent as NotificationsIcon } from "../../../images/icons/notifications.svg"
import { ReactComponent as Hamburger } from "../../../images/icons/hamburger.svg"
import DefaultUserAvatar from "../../../images/defaultUserAvatar.png"
import NotificationBadge from "../../elements/NotificationBadge"
import Notifications from "../Notifications"
import Button from "../../Button/Button"
import { paths } from "../../../routes/paths"
import useNotificationsListener from "../../../hooks/chat/useNotificationsListener"
import { ADMIN } from "../../../helpers/userRoles"

const Panel = ({
  isProfileMenuVisible,
  setProfileMenuVisibility,
  setNavVisibility,
  userInfo,
}) => {
  useNotificationsListener()
  const [isNotificationsVisible, setNotificationsVisibility] = React.useState(
    false,
  )

  const notifications = useSelector(
    (state) => state.notifications,
    (prevState, nextState) => objectComparison(prevState, nextState),
  )

  return (
    <div className={styles.panel}>
      {userInfo.role !== "guest" ? (
        <React.Fragment>
          {userInfo.role !== ADMIN && (
            <div className={styles.panelItem}>
              <IconButton aria-label="messages">
                <NotificationBadge badgeContent={notifications.messages}>
                  <Link to="/chats">
                    <Mail />
                  </Link>
                </NotificationBadge>
              </IconButton>
            </div>
          )}

          <div className={styles.panelItem}>
            <IconButton aria-label="notification">
              <NotificationBadge
                badgeContent={notifications.systemNotificationsCount}
                onClick={() => {
                  if (notifications.notificationsTotalCount > 0) {
                    setNotificationsVisibility(!isNotificationsVisible)
                  }
                }}
              >
                <NotificationsIcon />
              </NotificationBadge>
            </IconButton>

            <Notifications
              onClose={() => setNotificationsVisibility(false)}
              notifications={notifications}
              isVisible={isNotificationsVisible}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className={styles.panelItem}>
            <div className={styles.panelButtonsWrap}>
              <Button
                to={paths.login}
                className={styles.panelButton}
                buttonStyle="outlined"
                width="auto"
                component={NavLink}
              >
                Sign In
              </Button>

              {/*<Button*/}
              {/*  to={paths.signUp("", "1")}*/}
              {/*  className={classnames(*/}
              {/*    styles.panelButton,*/}
              {/*    styles.panelButtonSign,*/}
              {/*  )}*/}
              {/*  buttonStyle="outlined"*/}
              {/*  width="auto"*/}
              {/*  component={NavLink}*/}
              {/*>*/}
              {/*  Sign Up*/}
              {/*</Button>*/}
            </div>
          </div>
        </React.Fragment>
      )}

      <div className={styles.panelItem}>
        {userInfo.role !== "guest" && (
          <div
            className={styles.panelUserAvatar}
            style={{
              backgroundImage: `url(${
                userInfo?.photo || userInfo.company?.photo || DefaultUserAvatar
              })`,
            }}
            onClick={() => {
              setProfileMenuVisibility(!isProfileMenuVisible)
            }}
          />
        )}

        <div className={styles.panelNavToggle}>
          <Hamburger onClick={() => setNavVisibility(true)} />
        </div>
      </div>
    </div>
  )
}

export default Panel
