export const questionTypes = [
  {
    title: "Group Chats",
    type: "group_chats",
  },
  {
    title: "Network",
    type: "network",
  },
  {
    title: "Peer Circles",
    type: "peer_circles",
  },
  {
    title: "Referrals",
    type: "referrals",
  },
  {
    title: "Let’s Connect",
    type: "the_vent",
  },
]
export const creatorTypes = [
  {
    title: "Professional",
    type: "professional",
  },
  {
    title: "Company/Org",
    type: "company",
  },
]
