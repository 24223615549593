import React from "react"

import CommunityTopic from "../../components/CommunityTopic"

const CommunityTopicContainer = ({
  data,
  handlePostComment,
  setState,
  state,
  handleEditComment,
  handleDeleteComment,
  voteComment,
  commentsCount,
}) => {
  return data ? (
    <React.Fragment>
      <CommunityTopic
        data={data}
        handlePostComment={handlePostComment}
        setState={setState}
        state={state}
        handleEditComment={handleEditComment}
        handleDeleteComment={handleDeleteComment}
        voteComment={voteComment}
        commentsCount={commentsCount}
      />
    </React.Fragment>
  ) : (
    ""
  )
}

export default CommunityTopicContainer
