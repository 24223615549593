import React from "react"
import { NavLink } from "react-router-dom"
import { paths } from "../../routes/paths"
import styles from "./index.module.scss"

const AccountSidebarNav = () => {
  return (
    <div className={styles.navWrap}>
      <div className={styles.navList}>
        <NavLink
          to={paths.accountSettings}
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          Settings
        </NavLink>
      </div>
      <div className={styles.navList}>
        <NavLink
          to={paths.accountSubscription}
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          Subscription
        </NavLink>
      </div>
    </div>
  )
}

export default AccountSidebarNav
