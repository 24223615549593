import { useState } from "react"
import { useSelector } from "react-redux"
import { GUEST } from "../../../../helpers/userRoles"

const useAccessChecker = () => {
  const [isPopupDisplayed, setPopupVisibility] = useState(false)
  const closePopup = () => setPopupVisibility(false)
  const userRole = useSelector((state) => state.user.role)
  const checkAccess = (go) => {
    if (userRole !== GUEST) {
      go()
    } else {
      setPopupVisibility(true)
    }
  }
  return [isPopupDisplayed, closePopup, checkAccess]
}
export default useAccessChecker
