import React, { useState, useEffect } from "react"
import classNames from "classnames"
import styles from "./Skills.module.scss"
import TagEditor from "../../../TagEditor/TagEditor"
import Button from "../../../Button/Button"
import ProfileTableItem from "../../ProfileTableItem"
import { Prompt } from "react-router"
import { objectComparison } from "../../../../helpers"
import ConfirmCancelPopup from "../../../Popup/ConfirmCancelPopup"
import { messages } from "../../../../helpers/constants"

const Skills = ({ data, update, anotherUser }) => {
  const [skillsData, setSkillsData] = useState([])
  const [editingSkillsData, setEditingSkillsData] = useState([])
  const [editing, isEditing] = useState(false)
  const [popupVisible, isPopupVisible] = useState(false)

  useEffect(() => {
    if (data) {
      setSkillsData(data)
      setEditingSkillsData(data)
    }
  }, [data])

  const handleCancel = () => {
    setEditingSkillsData(skillsData)
    isEditing(false)
    isPopupVisible(false)
  }
  const handleSave = () => {
    update({ skills: editingSkillsData })
    setSkillsData(editingSkillsData)
    isEditing(false)
  }
  return (
    <div className={styles.skillsWrap}>
      {popupVisible && (
        <ConfirmCancelPopup
          handleCancel={handleCancel}
          setIsPopupOpened={isPopupVisible}
        />
      )}
      <Prompt
        when={!objectComparison(skillsData, editingSkillsData)}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      <ProfileTableItem
        anotherUser={anotherUser}
        title="Skills"
        description="Tell us about your amazing expertise, professional and personal skills"
        linkText="+ Add your skills"
        hasData={skillsData.length > 0}
        isActive={editing}
        onClick={() => {
          isEditing(true)
        }}
      />
      {!editing && skillsData && (
        <div className={styles.skillsTextWrap}>
          <p className={classNames("text16r", styles.skills)}>
            {skillsData.map((skill) => (
              <span key={skill.id}>
                {skill !== skillsData[skillsData.length - 1]
                  ? `${skill.name},`
                  : skill.name}
              </span>
            ))}
          </p>
        </div>
      )}
      {editing && (
        <div className={styles.editSkillsWrap}>
          <TagEditor
            data={editingSkillsData}
            setData={setEditingSkillsData}
            className={styles.editor}
          />
          <div className={styles.btnsWrap}>
            <Button
              buttonStyle="outlined"
              className={styles.btn}
              onClick={
                editingSkillsData !== skillsData
                  ? () => isPopupVisible(true)
                  : () => isEditing(false)
              }
            >
              Cancel
            </Button>
            <Button
              buttonStyle="contained"
              className={styles.btn}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Skills
