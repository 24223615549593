import { useEffect } from "react"

const useClickOutsideCalendar = (calendarRef, isCalendarOpen, callback) => {
  const handleClick = (e) => {
    if (
      isCalendarOpen &&
      calendarRef.current &&
      !calendarRef.current.contains(e.target)
    ) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener("click", handleClick)
    return () => {
      document.removeEventListener("click", handleClick)
    }
  })
}
export default useClickOutsideCalendar
