import React, { useEffect, useState } from "react"
import P from "prop-types"
import classNames from "classnames"
import styles from "./TopFilter.module.scss"

const TopFilter = ({ filterArray, onSelect, startFilter, className }) => {
  const [activeFilter, setActiveFilter] = useState(
    startFilter || (filterArray && filterArray[0]) || "",
  )

  useEffect(() => {
    setActiveFilter(startFilter)
  }, [startFilter])

  const handleFilterTitleClick = (title) => {
    setActiveFilter(title)
    onSelect(title)
  }

  return (
    <div className={classNames(styles.filterWrap, className)}>
      {filterArray &&
        filterArray.map((title, index) => (
          <div
            key={title + index}
            className={
              activeFilter === title ? styles.activeFilter : styles.filter
            }
            onClick={() => handleFilterTitleClick(title)}
          >
            {title}
          </div>
        ))}
    </div>
  )
}

TopFilter.propTypes = {
  filterArray: P.array.isRequired,
  setOpportunitiesList: P.func,
}

TopFilter.defaultProps = {
  setOpportunitiesList: () => {
    console.log("setOpportunitiesList")
  },
}

export default TopFilter
