import React, { useEffect } from "react"
import GetMeKnowItem from "./GetToKnowItem/GetToKnowItem"
import styles from "./GetMeKnow.module.scss"
import classNames from "classnames"
import Button from "../../../Button/Button"
import getIconAndPlaceholderByType from "./helpers/getIconAndPlaceholderByType"
import Popup from "../../../Popup/Popup"
import ProfileTableItem from "../../ProfileTableItem"
import { objectDeepCopy } from "../../../../helpers"
import useGetToKnow from "./hooks/useGetToKnow"
import { Prompt } from "react-router-dom"
import { messages } from "../../../../helpers/constants"

const GetMeKnow = ({ data, getToKnowUpdate, anotherUser }) => {
  const {
    setEditableState,
    isEditing,
    handleStartEdit,
    handleChangeLink,
    handleSwitchChange,
    OnAddCustomLink,
    onClosePopup,
    isPopupOpened,
    hasChanges,
    setIsPopupOpened,
    setHasChanges,
    handleSave,
    handleCancel,
    checkHasDataToShow,
    checkAllInputsValid,
    getInputValidStatus,
    handleDeleteCustomLink,
    editableState,
    customLinksCount,
    setCustomLinksCount,
  } = useGetToKnow({ getToKnowUpdate, data })

  const input = React.useRef(null)

  useEffect(() => {
    //setup data from api
    setEditableState(objectDeepCopy(data).sort((a, b) => a.id - b.id))
    setCustomLinksCount(
      data.filter((item) => item["field_type"] === "custom").length,
    )
  }, [data, setCustomLinksCount, setEditableState])

  const stateToShow = isEditing ? editableState : data

  return (
    <div className={styles.wrap}>
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />

      <ProfileTableItem
        title="Get to know me"
        description="Tell the B2T community about yourself"
        linkText="+ Add information"
        hasData={checkHasDataToShow()}
        isActive={isEditing}
        onClick={() => {
          handleStartEdit()
        }}
        anotherUser={anotherUser}
      />

      {isPopupOpened && hasChanges && (
        <Popup
          dialog
          subtitle={"Are you sure you want to cancel your changes?"}
          button={
            <Button buttonStyle="black" onClick={onClosePopup}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => setIsPopupOpened(false)}
            >
              No
            </Button>
          }
          closeAction={() => setIsPopupOpened(false)}
          isCrossIcon={true}
        />
      )}

      {stateToShow && (
        <div className={styles.itemsWrap}>
          {stateToShow.map((getMeKnowItem, idx) => {
            return (
              <GetMeKnowItem
                setHasChanges={setHasChanges}
                key={getMeKnowItem.id}
                id={getMeKnowItem.id}
                showData={!isEditing}
                onChange={handleChangeLink}
                getInputValidStatus={getInputValidStatus}
                placeholder={
                  getIconAndPlaceholderByType(getMeKnowItem["field_type"])
                    .placeholder
                }
                Icon={
                  getIconAndPlaceholderByType(getMeKnowItem["field_type"]).icon
                }
                type={getMeKnowItem["field_type"]}
                propValue={getMeKnowItem.value}
                handleDeleteCustomLink={handleDeleteCustomLink}
                isVisible={!getMeKnowItem["is_hide"]}
                onSwitchChange={handleSwitchChange}
                autoFocus={idx === 0 ? input : null}
              />
            )
          })}
        </div>
      )}

      {isEditing ? (
        <div className={styles.bottomSection}>
          {customLinksCount < 3 && (
            <span
              className={classNames("text16r", "goldLink", styles.addLink)}
              onClick={OnAddCustomLink}
            >
              + Add new link
            </span>
          )}

          <div className={styles.btnsWrap}>
            <Button
              className={styles.btn}
              buttonStyle="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              buttonStyle="contained"
              disabled={!checkAllInputsValid()}
              className={styles.btn}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

GetMeKnow.defaultProps = {
  data: [],
}

export default GetMeKnow
