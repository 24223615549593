import React from "react"
import { NavLink } from "react-router-dom"
import { useDispatch } from "react-redux"
import classnames from "classnames"

import styles from "./profile-menu.module.scss"
import { ReactComponent as Settings } from "../../../images/icons/settings.svg"
import { ReactComponent as LogOut } from "../../../images/icons/log-out.svg"
import { getMenuLinks } from "./menu-links"
import useOutsideClicker from "../../../hooks/useOutsideClicker"
import { userLogout } from "../../../actions/user/userActions"
import { ADMIN, ACCOUNT_MANAGER} from "../../../helpers/userRoles"

const ProfileMenu = ({ isVisible, setVisibility, userInfo }) => {
  const dispatcher = useDispatch()

  const wrapperRef = React.useRef(null)
  useOutsideClicker(wrapperRef, (e) => setVisibility(false))

  const profileLinks = getMenuLinks(userInfo)

  return (
    <div
      ref={wrapperRef}
      className={classnames(styles.menu, isVisible && styles.menuVisible)}
    >
      {userInfo.role !== ADMIN && userInfo.role !== ACCOUNT_MANAGER && (
        <div className={styles.menuSection}>
          {profileLinks.map((link, index) => (
            <NavLink key={index} to={link.path} className={styles.menuItem}>
              <div className={styles.menuItemIcon}>
                {React.createElement(link.icon)}
              </div>
              <div className={styles.menuItemText}>{link.name}</div>
            </NavLink>
          ))}
        </div>
      )}

      <div className={styles.menuSection}>
        {userInfo.role !== ACCOUNT_MANAGER && (
          <NavLink to="/settings" className={styles.menuItem}>
            <div className={styles.menuItemIcon}>
              <Settings />
            </div>
            <div className={styles.menuItemText}>Settings</div>
          </NavLink>
        )}
        <div
          className={styles.menuItem}
          onClick={() => dispatcher(userLogout())}
        >
          <div className={styles.menuItemIcon}>
            <LogOut />
          </div>
          <div className={styles.menuItemText}>Logout</div>
        </div>
      </div>
    </div>
  )
}

export default ProfileMenu
