import React from "react"
import {
  GUEST,
  ADMIN,
  COMPANY_PROVIDER,
  COMPANY_SEEKER,
  COMPANY_ADMIN,
  COMPANY_EMPLOYEE,
  INDIVIDUAL,
  ACCOUNT_MANAGER
} from "../helpers/userRoles"
import { paths } from "./paths"

import LoginPage from "../pages/LoginPage/LoginPage"
import ResetPasswordPage from "../pages/ResetPasswordPage"
import HomePage from "../pages/HomePage/HomePage"
import SignUpPage from "../pages/SignUpPage/SignUpPage"
import CommunityGuidelinePage from "../pages/CommunityGuidelinePage"
import EmailVerificationPage from "../pages/EmailVerificationPage"
import EmailVerificationResendPage from "../pages/EmailVerificationResendPage"
import IndividualProfilePage from "../pages/IndividualProfilePage/IndividualProfilePage"
import PhotoGalleryPage from "../pages/PhotoGalleryPage/PhotoGalleryPage"
import SelectedUserProfilePage from "../pages/SelectedUserProfilePage/SelectedUserProfilePage"
import ProspectsPage from "../pages/ProspectsPage/ProspectsPage"
import CompanyProfilePage from "../pages/CopmanyProfilePage/CompanyProfilePage"
import OpportunityEditPage from "../pages/OpportunityEditPage/OpportunityEditPage"
import OpportunityPage from "../pages/OpportunityPage/OpportunityPage"
import OpportunitySearchPage from "../pages/OpportunitySearchPage/OpportunitySearchPage"
import MemberSearchPage from "../pages/MemberSearchPage/MemberSearchPage"
import ResourceSearchPage from "../pages/ResourceSearchPage/ResourceSearchPage"
import QuestionSearchPage from "../pages/QuestionSearchPage/QuestionSearchPage"
import InstituteSearchPage from "../pages/InstituteSearchPage/InstituteSearchPage"
import InspirationSearchPage from "../pages/InspirationSearchPage/InspirationSearchPage"
import CompanyDepartmentsPage from "../pages/CompanyDepartmentsPage/CompanyDepartmentsPage"
import CompanyOpportunitiesPage from "../pages/CompanyOpportunitiesPage/CompanyOpportunitiesPage"
import CompanyResourcesPage from "../pages/CompanyResourcesPage/CompanyResourcesPage"
import SelectedCompanyProfilePage from "../pages/SelectedCompanyProfilePage/SelectedCompanyProfilePage"
import VideoGalleryPage from "../pages/VideoGalleryPage/VideoGalleryPage"
import ChatsPage from "../pages/ChatsPage/ChatsPage"
import ResourcePage from "../pages/ResourcePage"
import ResourceEditPage from "../pages/ResourceEdit/ResourceEditPage"
import AttendeesPage from "../pages/AttendeesPage"
import CommunityTopicEditPage from "../pages/CommunityTopicEdit/CommunityTopicEditPage"
import CommunityMyQuestionsPage from "../pages/CommunityMyQuestionsPage"
import NotificationsPage from "../pages/NotificationsPage/NotificationsPage"
import CommunityCompanyQuestionsPage from "../pages/CommunityCompanyQuestionsPage"
import CommunityTopicPage from "../pages/CommunityTopicPage"
import UserEventsPage from "../pages/UserEventsPage"
import ArticlePage from "../pages/ArticlePage/ArticlePage"
import AccountSettingsPage from "../pages/AccountSettingsPage"
import AccountSettingsEmailConfirmPage from "../pages/AccountSettingsEmailConfirmPage"
import AccountSubscriptionPage from "../pages/AccountSubscriptionPage"
import FavoritesPage from "../pages/FavoritesPage"
import CompanyEmployeesPage from "../pages/CompanyEmployeesPage/CompanyEmployeesPage"
import ChoosePasswordPage from "../pages/ChoosePasswordPage/ChoosePasswordPage"
import RedirectedFromEmailPage from "../pages/RedirectedFromEmailPage"
import ReportsPage from "../pages/ReportsPage/ReportsPage"
import ReportPage from "../pages/ReportPage/ReportsPage"
import SelectedFreelancerProfile from "../pages/SelectedFreelancerProfile/SelectedFreelancerProfile";
import OpportunitySharePage from "../pages/OpportunitySharePage/OpportunitySharePage";
const CompanyProviderLandingPage = React.lazy(() =>
  import("../pages/LandingPages/CompanyProvider/CompanyProvider"),
)
const IndividualLandingPage = React.lazy(() =>
  import("../pages/LandingPages/Individual/Individual"),
)

const allRoles = `${GUEST}, ${ADMIN}, ${COMPANY_PROVIDER}, ${COMPANY_SEEKER}, ${INDIVIDUAL}, ${COMPANY_ADMIN}`
const autorized = `${ADMIN}, ${COMPANY_PROVIDER}, ${COMPANY_SEEKER}, ${INDIVIDUAL}, ${COMPANY_ADMIN}`
const COMPANY = `${COMPANY_SEEKER}, ${COMPANY_PROVIDER}, ${COMPANY_ADMIN}, ${COMPANY_EMPLOYEE}`
const INDIVIDUAL_AND_SEEKER = `${INDIVIDUAL}, ${COMPANY_SEEKER}`

const routes = [
  {
    path: "/",
    exact: true,
    Component: HomePage,
    allowed: allRoles,
  },
  {
    path: paths.login,
    Component: LoginPage,
    allowed: GUEST,
  },
  {
    path: paths.resetPassword,
    Component: ResetPasswordPage,
    allowed: GUEST,
  },
  // {
  //   path: paths.passwordRetrieval,
  //   Component: PasswordRetrievalPage,
  //   allowed: GUEST,
  // },
  {
    path: paths.signUp("/:type?", ":step"),
    Component: SignUpPage,
    allowed: GUEST,
  },
  {
    path: paths.communityGuideline,
    Component: CommunityGuidelinePage,
    allowed: allRoles,
  },
  {
    path: paths.emailVerifyMsgPage(":token"),
    Component: EmailVerificationPage,
    allowed: allRoles,
    exact: true,
  },
  {
    path: paths.emailVerifyResend(":email"),
    Component: EmailVerificationResendPage,
    allowed: GUEST,
    exact: true,
  },
  {
    path: paths.individualProfile,
    Component: IndividualProfilePage,
    allowed: INDIVIDUAL,
    exact: true,
  },
  {
    path: paths.selectedUserProfile,
    Component: SelectedUserProfilePage,
    allowed: autorized,
  },
  {
    path: paths.freelancerUserProfile,
    Component: SelectedFreelancerProfile,
    allowed: autorized + `, ${ACCOUNT_MANAGER}`,
  },
  {
    path: paths.videoGallery(":type?", "/:id?"),
    Component: VideoGalleryPage,
    allowed: autorized,
  },
  {
    path: paths.photoGallery(":type?", "/:id?"),
    Component: PhotoGalleryPage,
    allowed: autorized,
  },
  {
    path: paths.companyProfile,
    Component: CompanyProfilePage,
    allowed: COMPANY,
  },
  {
    path: paths.opportunityEdit,
    Component: OpportunityEditPage,
    allowed: COMPANY,
  },
  {
    path: paths.opportunityShare,
    Component: OpportunitySharePage,
    allowed: COMPANY,
  },
  {
    path: paths.opportunity,
    Component: OpportunityPage,
    allowed: autorized,
  },
  {
    path: paths.opportunitySearch(),
    Component: OpportunitySearchPage,
    allowed: allRoles,
  },
  {
    path: paths.memberSearch(),
    Component: MemberSearchPage,
    allowed: autorized,
  },
  {
    path: paths.resourceSearch(),
    Component: ResourceSearchPage,
    allowed: allRoles,
  },
  {
    path: paths.questionSearch(),
    Component: QuestionSearchPage,
    allowed: autorized,
  },
  {
    path: paths.instituteSearch(),
    Component: InstituteSearchPage,
    allowed: autorized,
  },
  {
    path: paths.inspirationSearch(),
    Component: InspirationSearchPage,
    allowed: autorized,
  },
  {
    path: paths.departments(":uuid"),
    Component: CompanyDepartmentsPage,
    allowed: autorized,
  },
  {
    path: paths.opportunities(":uuid"),
    Component: CompanyOpportunitiesPage,
    allowed: autorized,
  },
  {
    path: paths.companyResources(":uuid"),
    Component: CompanyResourcesPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.companyQuestions(":uuid"),
    Component: CommunityCompanyQuestionsPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.myEvents,
    Component: UserEventsPage,
    allowed: INDIVIDUAL_AND_SEEKER,
    exact: true,
  },
  {
    path: paths.resourcePage(":resourceID"),
    Component: ResourcePage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.resourceAttendees(":resourceID"),
    Component: AttendeesPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.selectedCopmanyProfile(":uuid"),
    Component: SelectedCompanyProfilePage,
    allowed: autorized + `, ${ACCOUNT_MANAGER}`
  },
  {
    path: paths.prospects,
    Component: ProspectsPage,
    allowed: autorized,
  },
  {
    path: paths.chats(":uuid?"),
    Component: ChatsPage,
    allowed: autorized + `, ${ACCOUNT_MANAGER}`
  },
  {
    path: paths.resourcesEdit,
    Component: ResourceEditPage,
    allowed: COMPANY,
  },
  {
    path: paths.communityTopicEdit,
    Component: CommunityTopicEditPage,
    allowed: autorized,
  },
  {
    path: paths.communityMyQuestions,
    Component: CommunityMyQuestionsPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.communityTopic(":uuid"),
    Component: CommunityTopicPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.communityTopicAccept(":uuid"),
    Component: CommunityTopicPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.communityTopicDecline(":uuid"),
    Component: CommunityTopicPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.specificInstituteArticle(":uuid"),
    Component: ArticlePage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.specificInspirationArticle(":uuid"),
    Component: ArticlePage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.notifications,
    Component: NotificationsPage,
    allowed: autorized + `, ${ACCOUNT_MANAGER}`,
  },
  {
    path: paths.accountSettings,
    Component: AccountSettingsPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.accountSettingsToken(":token"),
    Component: AccountSettingsEmailConfirmPage,
    allowed: allRoles,
    exact: true,
  },
  {
    path: paths.accountSubscription,
    Component: AccountSubscriptionPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.companyProviderLanding,
    Component: CompanyProviderLandingPage,
    allowed: allRoles,
    exact: true,
  },
  {
    path: paths.individualLanding,
    Component: IndividualLandingPage,
    allowed: allRoles,
  },
  {
    path: paths.employees,
    Component: CompanyEmployeesPage,
    allowed: COMPANY,
    exact: true,
  },
  {
    path: paths.favorites,
    Component: FavoritesPage,
    allowed: autorized,
    exact: true,
  },
  {
    path: paths.choosePassword,
    Component: ChoosePasswordPage,
    allowed: GUEST,
    exact: true,
  },
  {
    path: paths.emailComfirmed,
    Component: RedirectedFromEmailPage,
    allowed: allRoles,
    exact: true,
  },
  {
    path: paths.reports,
    Component: ReportsPage,
    allowed: ADMIN,
    exact: true,
  },
  {
    path: paths.report(":uuid"),
    Component: ReportPage,
    allowed: ADMIN,
  },
]

export default routes
