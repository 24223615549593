import React from "react"
import classNames from "classnames"
import Breadcrumbs from "../../../../components/elements/Breadcrumbs"
import { paths } from "../../../../routes/paths"
import styles from "./index.module.scss"

const CaptionWrapper = ({ state }) => {
  const { opportunityCount, query } = state.requestResultGroup
  return !query ? (
    <div className={styles.topCaption}>
      <div className={styles.container}>
        <span>
          Top Opportunities
          <span className={classNames(styles.line, styles.toLeft)}></span>
          <span className={classNames(styles.line, styles.toRight)}></span>
        </span>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.searchCaption}>
        <Breadcrumbs
          classes={styles.breadcrumb}
          chain={[
            {
              link: paths.opportunitySearch(),
              text: "Opportunities",
            },
            { text: "Search" },
          ]}
        />
        {!!opportunityCount && (
          <p
            className={styles.resultCount}
          >{`We found ${opportunityCount} results for “${query}”`}</p>
        )}
      </div>
    </div>
  )
}
export default CaptionWrapper
