import axios from "./axios"

export default {
  createDepartment: async (companyUUID, { name, employees_count }) => {
    try {
      const result = await axios.post(`/companies/${companyUUID}/departments`, {
        name,
        employees_count,
      })
      return result
    } catch (error) {
      throw error
    }
  },

  getCopmanyDepartments: async (companyUUID) => {
    try {
      const result = await axios.get(`/companies/${companyUUID}/departments`)
      return result
    } catch (error) {
      throw error
    }
  },

  deleteCompanyDepartment: async (companyUuid, uuid) => {
    try {
      await axios.delete(`/companies/${companyUuid}/departments/${uuid}`)
    } catch (error) {
      throw error
    }
  },

  editCompanyDepartment: async ({ companyUuid, departmentUuid, data }) => {
    try {
      return await axios.put(
        `companies/${companyUuid}/departments/${departmentUuid}`,
        data,
      )
    } catch (error) {
      throw error
    }
  },

  getOpportunityDepartments: async () => {
    try {
      const result = await axios.get(`/suggestions/departments`)

      return result
    } catch (error) {
      throw error
    }
  },
}
