import { useState, useEffect } from "react"
import opportunityServices from "../../../../services/opportunityServices"

const useTopOpportunities = (query, page, opportunityList) => {
  const [topOpportunities, setTopOpportunities] = useState([])
  useEffect(() => {
    if (!topOpportunities.length) {
      if (!query && page == 1 && opportunityList.length) {
        setTopOpportunities(opportunityList.slice(0, 2))
      } else if (!!query) {
        opportunityServices
          .getOpportunities({ type: "opportunity",})
          .then(({ data }) => {
            if (data) {
              setTopOpportunities(data.opportunities.slice(0, 2))
            }
          })
      }
    }
  }, [query, opportunityList])
  return topOpportunities
}
export default useTopOpportunities
