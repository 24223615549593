import React from "react"
import styles from "./UploadMediaPopup.module.scss"
import PhotoGalleryItem from "../../PhotoGallery/PhotoGalleryItem/PhotoGalleryItem"
import VideoCard from "../VideoCard/VideoCard"

export const renderItems = (type, args) => {
  if (type === "image") {
    return (
      <PhotoGalleryItem
        key={args.id}
        className={styles.uploadedPhotoItem}
        {...args}
      />
    )
  } else if (type === "video") {
    return (
      <VideoCard
        key={args.id}
        className={styles.uploadVideoItem}
        videoWidth={100}
        videoHeigh={100}
        withName={false}
        video={{ ...args }}
        deleteVideo={args.onDelete}
        small
        anotherUser={args.anotherUser}
      />
    )
  }
}
