export const options = [
  {
    id: "",
    value: "All Types",
  },
  {
    id: "meeting",
    value: "Meeting",
  },
  {
    id: "lunch",
    value: "Lunch",
  },
  {
    id: "breakfast",
    value: "Breakfast",
  },
  {
    id: "webinar",
    value: "Webinar",
  },
  {
    id: "virtual",
    value: "Virtual",
  },
]
