import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import {setCategoryType} from "../../useReducer/actionCreators"
import useOption from "./useOption"

const CategoryTypeDropdown = ({ externalDispatch, selectedType }) => {
  const [option, setOption] = useOption(selectedType)
  return (
    <Dropdown
      label="Category"
      id="category_type_dropdown"
      options={options}
      value={option}
      onChange={(option) => {
        setOption(option)
          externalDispatch(setCategoryType(option.id))
      }}
    />
  )
}
export default CategoryTypeDropdown
