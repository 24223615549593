import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { opportunities } from "./opportunities"
import { changeOpportunityType } from "../../useReducer/actionCreators"
import useInitOpportunity from "./useInitOpportunity"

const TypeDropdown = ({ dispatch, selectedType }) => {
  const [opportunity, setOpportunity] = useInitOpportunity(
    opportunities,
    selectedType,
  )
  return (
    <Dropdown
      label="Opportunity Type"
      id="filter_dropdown"
      options={opportunities.map((opportunity) => ({
        id: opportunity.type,
        value: opportunity.title,
      }))}
      value={opportunity}
      onChange={(opportunity) => {
        setOpportunity(opportunity)
        dispatch(changeOpportunityType(opportunity.id))
      }}
    />
  )
}
export default TypeDropdown
