import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"
import { ReactComponent as ArrowTriangleTop } from "../../images/icons/arrow-triangle-up.svg"
import { thousandToK } from "../../helpers/textHelpers"

const VoteUpDown = ({
  reputation,
  voteFn,
  horizontalOnMobile,
  status = null,
}) => {
  return (
    <div
      className={classnames(styles.voteWrap, {
        [styles.horizontalOnMobile]: horizontalOnMobile,
        [styles.voteUpped]: reputation.type === "up",
        [styles.voteDowned]: reputation.type === "down",
      })}
    >
      <div className={styles.voteUp} onClick={() => voteFn("up")}>
        <ArrowTriangleTop />
      </div>

      <div className={styles.voteCount}>{thousandToK(reputation.value)}</div>

      <div className={styles.voteDown} onClick={() => voteFn("down")}>
        <ArrowTriangleTop />
      </div>
    </div>
  )
}

VoteUpDown.defaultProps = {
  horizontalOnMobile: false,
}

VoteUpDown.propTypes = {
  reputation: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      value: PropTypes.number,
      positive: PropTypes.number,
      negative: PropTypes.number,
      type: PropTypes.string,
    }),
  ]).isRequired,
  voteFn: PropTypes.func.isRequired,
  horizontalOnMobile: PropTypes.bool,
}

export default VoteUpDown
