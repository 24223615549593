export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE"
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT"
export const SET_FORM_FILTERS = "SET_FORM_FILTERS"
export const SET_TAB_FILTER = "SET_TAB_FILTER"
export const RESET_FORM_FILTERS = "RESET_FORM_FILTERS"
export const REMOVE_QUESTION_TYPE = "REMOVE_QUESTION_TYPE"
export const REMOVE_CREATOR_TYPE = "REMOVE_CREATOR_TYPE"
export const REMOVE_FROM_DATE = "REMOVE_FROM_DATE"
export const REMOVE_TO_DATE = "REMOVE_TO_DATE"
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS"
export const SET_SIDE_QUESTIONS = "SET_SIDE_QUESTIONS"

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY: {
      const { query } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: 1, query },
      }
    }
    case SET_SEARCH_PAGE: {
      const { pageNumber } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: pageNumber },
      }
    }
    case SET_TAB_FILTER: {
      const { questionType } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          question_type: questionType ? questionType : "",
          page: 1,
        },
      }
    }
    case SET_FORM_FILTERS: {
      const {
        filters: { questionType, creatorType, fromDate, toDate },
      } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          question_type: questionType ? questionType : "",
          creator_type: creatorType ? creatorType : "",
          from: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          to: toDate ? toDate.format("YYYY-MM-DD") : null,
          page: 1,
        },
      }
    }
    case RESET_FORM_FILTERS: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          question_type: "",
          creator_type: "",
          from: null,
          to: null,
          page: 1,
        },
      }
    }
    case REMOVE_QUESTION_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          question_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_CREATOR_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          creator_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_FROM_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, from: null, page: 1 },
      }
    }
    case REMOVE_TO_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, to: null, page: 1 },
      }
    }
    case SET_SEARCH_PARAMS: {
      const { locationSearchGroup } = payload
      return {
        ...state,
        requestResultGroup: {
          isFetching: true,
        },
        searchParamGroup: {
          // ...state.searchParamGroup,
          ...locationSearchGroup,
        },
      }
    }
    case SET_REQUEST_RESULT: {
      const { data, locationSearchGroup } = payload
      const dataTotalCount = data.total_count || 0
      return {
        ...state,
        requestResultGroup: {
          itemList: data.questions || [],
          itemCount: dataTotalCount,
          pageCount: Math.ceil(dataTotalCount / 6),
          isFetching: false,
          page: 1,
          query: "",
          ...locationSearchGroup,
        },
      }
    }
    case SET_SIDE_QUESTIONS: {
      const { isQuery, questions } = payload
      return {
        ...state,
        sideQuestionGroup: {
          type: isQuery ? "popular" : "recent",
          itemList: questions.slice(0, 4),
        },
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
