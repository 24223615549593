import {
  SET_SEARCH_QUERY,
  SET_SEARCH_PAGE,
  SET_TAB_FILTER,
  SET_FORM_FILTERS,
  RESET_FORM_FILTERS,
  REMOVE_OPPORTUNITY_TYPE,
  REMOVE_FROM_DATE,
  REMOVE_TO_DATE,
  REMOVE_COMPANY,
  REMOVE_LOCATION,
  SET_SEARCH_PARAMS,
  SET_REQUEST_RESULT,
} from "./reducer"

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: { query },
})
export const setSearchPage = (pageNumber) => ({
  type: SET_SEARCH_PAGE,
  payload: { pageNumber },
})
export const setTabFilter = (opportunityType) => ({
  type: SET_TAB_FILTER,
  payload: { opportunityType },
})
export const setFormFilters = (filters) => ({
  type: SET_FORM_FILTERS,
  payload: { filters },
})
export const resetFormFilters = () => ({
  type: RESET_FORM_FILTERS,
  payload: null,
})
export const removeOpportunityType = () => ({
  type: REMOVE_OPPORTUNITY_TYPE,
  payload: null,
})
export const removeFromDate = () => ({
  type: REMOVE_FROM_DATE,
  payload: null,
})
export const removeToDate = () => ({
  type: REMOVE_TO_DATE,
  payload: null,
})
export const removeCompany = () => ({
  type: REMOVE_COMPANY,
  payload: null,
})
export const removeLocation = () => ({
  type: REMOVE_LOCATION,
  payload: null,
})
export const setSearchParams = (locationSearchGroup) => ({
  type: SET_SEARCH_PARAMS,
  payload: { locationSearchGroup },
})
export const setRequestResult = (data, locationSearchGroup) => ({
  type: SET_REQUEST_RESULT,
  payload: { data, locationSearchGroup },
})
