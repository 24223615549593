import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import defaultUserAvatar from "../../../images/defaultUserAvatar.png"
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip"

const UserAvatar = ({ src, alt, size }) => {
  return (
    <div className={styles.avatar} style={{ width: size, height: size }}>
      <CustomTooltip text={alt !== "User Avatar" ? alt : ""}>
        <img src={src || defaultUserAvatar} alt={alt} />
      </CustomTooltip>
    </div>
  )
}

UserAvatar.defaultProps = {
  src: null,
  alt: "User Avatar",
  size: 84,
}

UserAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default UserAvatar
