import React, { useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import styles from "./Footer.module.scss"
import Logo from "../../images/icons/logo-footer.png"
import { ReactComponent as Social1 } from "../../images/icons/youtube.svg"
import { ReactComponent as Social2 } from "../../images/icons/twitter.svg"
import { ReactComponent as Social3 } from "../../images/icons/facebook.svg"
import { ReactComponent as Social4 } from "../../images/icons/linkedin.svg"
import Popup from "../Popup/Popup"
import UserLicense from "../UserLicense/UserLicense"
import FooterNav from "./Nav"
import licensedAssets from '../../licensedAccets'

const Footer = () => {
  const [infoPopupVisible, setInfoPopupVisible] = useState(false)
  console.log(licensedAssets.getFooterText());
  return (
    <footer>
      {infoPopupVisible && (
        <Popup
          isCrossIcon={true}
          crossIconAction={() => setInfoPopupVisible(false)}
        >
          <UserLicense />
        </Popup>
      )}

      <div className="container">
        {licensedAssets.getFooterText()}
        <div className={styles.logosWrap}>
          <Link to="/" className={styles.logoLink}>
            {licensedAssets.getFooterLogo()}
          </Link>
        </div>
        <div className={styles.bottomWrap}>
          <FooterNav />

          <ul className={styles.socialsWrap}>
            <li className={styles.social}>
              <Social1 />
            </li>
            <li className={styles.social}>
              <Social2 />
            </li>
            <li className={styles.social}>
              <Social3 />
            </li>
            <li className={styles.social}>
              <Social4 />
            </li>
          </ul>
        </div>
        <span className={classNames("note12r", styles.privacyPolicy)}>
          © {new Date().getFullYear()} Bridge2Technologies.{" "}
          <div className={styles.privaceLink} onClick={() => setInfoPopupVisible(true)}>
            Terms of Service & Privacy Policy.
          </div>
           All rights reserved
        </span>
      </div>
    </footer>
  )
}

export default Footer
