import React from "react"
import { useHistory, useParams } from "react-router-dom"
import classnames from "classnames"

import styles from "./index.module.scss"
import { ReactComponent as VerifyEmailImage } from "../../images/signUp/verify-email-image.svg"
import services from "../../services"
import Loading from "../../components/Loading/Loading"

const EmailVerificationResendPage = () => {
  const [isLoading, setLoading] = React.useState(true)
  const history = useHistory()
  const params = useParams()

  React.useEffect(() => {
    if (!params.email) {
      history.push("/404")
    }

    setLoading(false)
  }, [history, params])

  const resendEmail = () =>
    services.userServices.resendConfirmEmail(params.email)

  return (
    <div className={styles.pageWrap}>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <div className={styles.pageContainerWrap}>
            <div className={classnames("container", styles.pageContainer)}>
              <div className={styles.pageHeader}>
                <h1>Verify your email address</h1>

                <p>
                  You’re almost done. Please check your inbox and verify your
                  email address.
                  <br />
                  The B2T community is excited to meet you.
                </p>
              </div>

              <div className={styles.pageContent}>
                <div className={styles.imageHandler}>
                  <VerifyEmailImage />
                </div>
              </div>

              <div className={styles.pageFooter}>
                Didn’t receive an email?
                <span className={styles.resendBtn} onClick={resendEmail}>
                  Resend
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

export default EmailVerificationResendPage
