import React from "react"
import styles from "./ProfileTable.module.scss"
import FreelancerDescription from "./components/FreelancerDescription/FreelancerDescription"
import classNames from "classnames"
const ProfileTable = ({ data }) => {
  return (
    <div
      className={classNames(styles.profileTableFreelancer, styles.profileTable)}
    >
      <div className={styles.tableItem}>
        <FreelancerDescription data={data.description} />
      </div>
    </div>
  )
}

export default ProfileTable
