import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { Link } from "react-router-dom"
import moment from "moment"

import styles from "./index.module.scss"
import CardWithBadge from "../../elements/CardWithBadge"
import { resourceTypes } from "../../../helpers/helpData"
import Button from "../../Button/Button"

const ResourceCard = ({ resource, isProfilePage, isSidebar, refuseFn }) => {
  return (
    <Link
      to={`/resources/${resource.uuid}`}
      className={classnames(
        styles.cardWrap,
        isProfilePage && styles.profilePage,
        isSidebar && styles.sidebar,
      )}
    >
      <CardWithBadge {...getBadgeColor(resource.resource_type)}>
        <div className={styles.cardContent}>
          <div
            className={styles.cardThumbnail}
            style={{
              backgroundImage: `url(${
                resource.video?.thumbnail || resource.photo?.path
              })`,
            }}
          />

          <div className={styles.cardTitle} title={resource.topic}>
            {getOptimizedText(resource.topic)}
          </div>

          <div className={styles.cardFooter}>
            <div
              className={classnames(
                styles.cardDetails,
                refuseFn !== null && styles.cardWithRefuse,
              )}
            >
              <div className={styles.cardLocation}>
                {getOptimizedText(resource.location, 13)}
              </div>

              <div className={styles.cardDate}>
                {moment(resource.date).format("MMM DD, YYYY")}
                {` · `}
                {resource.time.split(" - ")[0].toLowerCase()}
              </div>
            </div>

            {refuseFn && (
              <div className={styles.cardRefuseBtn}>
                <Button
                  buttonStyle="outlined"
                  width="100%"
                  onClick={(e) => {
                    e.preventDefault()
                    refuseFn()
                  }}
                >
                  Decline
                </Button>
              </div>
            )}
          </div>
        </div>
      </CardWithBadge>
    </Link>
  )
}

ResourceCard.propTypes = {
  resource: PropTypes.shape({
    id: PropTypes.number.isRequired,
    resource_type: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
    photo: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  }).isRequired,
  isProfilePage: PropTypes.bool,
  isSidebar: PropTypes.bool,
  refuseFn: PropTypes.func,
}

ResourceCard.defaultProps = {
  isProfilePage: false,
  isSidebar: false,
  refuseFn: null,
}

export default ResourceCard

const getOptimizedText = (title, length = 35) =>
  title.length <= length ? title : `${title.slice(0, length)}...`

const getBadgeColor = (type) =>
  resourceTypes.find((item) => item.resourceType === type.toLowerCase())
