import axios from "./axios"
import { fetch as fetchPolyfill } from "whatwg-fetch"
import { b64toBlob } from "./helpers"
import { save, remove } from "../helpers/localStorage"

export default {
  signUp: async (params) => {
    try {
      const result = await axios.post("/users", params)
      return result
    } catch (error) {
      throw error
    }
  },

  signIn: async (params) => {
    try {
      const result = await axios.post("/sessions", params)
      if (result.status === 200) {
        axios.defaults.headers[
          "Authorization"
        ] = `Bearer ${result.data.access_token}`
      }
      save("accessToken", result.data.access_token)
      return result
    } catch (error) {
      throw error
    }
  },

  signOut: async () => {
    try {
      await axios.delete("/sessions")
      delete axios.defaults.headers["Authorization"]
      remove("accessToken")
    } catch (error) {
      throw error
    }
  },

  confirmEmail: async (confirm_token) => {
    try {
      return await axios.put("/self/confirm", { confirm_token })
    } catch (error) {
      throw error
    }
  },

  resendConfirmEmail: async (email) => {
    try {
      return await axios.post("/self/resend_activation_email", { email })
    } catch (error) {
      throw error
    }
  },

  getUser: async (id) => {
    try {
      return await axios.get(`users/${id}/individual`)
    } catch (error) {
      throw error
    }
  },
  updateUsers: async (params) => {
    try {
      return await axios.put(`/self`, params)
    } catch (error) {
      throw error
    }
  },
  updateUser: async (params) => {
    try {
      return await axios.put(`/self/individual/`, params)
    } catch (error) {
      throw error
    }
  },

  updateSchools: async (params) => {
    try {
      return await axios.put(`/individual/schools`, params)
    } catch (error) {
      throw error
    }
  },

  getSchools: async () => {
    try {
      return await axios.get(`/individual/schools`)
    } catch (error) {
      throw error
    }
  },
  getToKnowMe: async () => {
    try {
      return await axios.get("/individual/get_to_know_me")
    } catch (error) {
      throw error
    }
  },
  setToKnowMe: async (data) => {
    try {
      return await axios.put("/individual/get_to_know_me", data)
    } catch (error) {
      throw error
    }
  },

  getMyStory: async () => {
    try {
      return await axios.get("individual/my_story")
    } catch (error) {
      throw error
    }
  },

  setMyStory: async (data) => {
    const dataToSend = {
      my_story: data,
    }
    try {
      return await axios.put("/individual/my_story", dataToSend)
    } catch (error) {
      throw error
    }
  },
  getMyInspiration: async () => {
    try {
      return await axios.get("/individual/my_inspiration")
    } catch (error) {
      throw error
    }
  },
  getPhotoGallery: async () => {
    try {
      return await axios.get("/photo_gallery")
    } catch (error) {
      throw error
    }
  },

  getPhotoGalleryByTypeAndId: async (type, id) => {
    try {
      if (!["users", "companies"].includes(type))
        throw new Error("Something went wrong...")

      return await axios.get(`/${type}/${id}/photo_gallery`)
    } catch (error) {
      throw error
    }
  },

  addPhotoGallery: async (data, options) => {
    try {
      const form = new FormData()
      for (let i = 0; i < data.length; i++) {
        form.append("photos[]", data[i])
      }
      return await axios.post("/photo_gallery", form, options)
    } catch (error) {
      throw error
    }
  },

  deletePhotoGallery: async (id) => {
    try {
      return await axios.delete(`/photo_gallery/${id}`)
    } catch (error) {
      throw error
    }
  },

  setMyInspiration: async (data) => {
    const dataToSend = {
      my_inspiration: data,
    }
    try {
      return await axios.put("/individual/my_inspiration", dataToSend)
    } catch (error) {
      throw error
    }
  },

  getMyTopFive: async () => {
    try {
      return await axios.get("/individual/top_five")
    } catch (error) {
      throw error
    }
  },

  getSkills: async () => {
    try {
      return await axios.get("/individual/skills")
    } catch (error) {
      throw error
    }
  },

  setMyTopFive: async (data) => {
    try {
      return await axios.put("/individual/top_five", data)
    } catch (error) {
      throw error
    }
  },

  setSkills: async (data) => {
    try {
      return await axios.put("/individual/skills", data)
    } catch (error) {
      throw error
    }
  },

  setProfilePhoto: async (data) => {
    try {
      const formData = new FormData()
      let avatar
      let originImage
      if (data.photo.indexOf("https://s3.amazonaws.com") !== -1) {
        //for IE 11 support
        avatar = await fetchPolyfill(data.photo, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => res.blob())
          .then((blobFile) => new Blob([blobFile], { type: "image/jpeg" }))
      } else {
        //for IE 11 support
        avatar = b64toBlob(data.photo)
      }

      if (data.origin_photo.indexOf("https://s3.amazonaws.com") !== -1) {
        //for IE 11 support
        originImage = await fetchPolyfill(data.origin_photo, {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        })
          .then((res) => res.blob())
          .then((blobFile) => new Blob([blobFile], { type: "image/jpeg" }))
      } else {
        //for IE 11 support
        originImage = b64toBlob(data.origin_photo)
      }

      formData.append("photo", avatar)
      formData.append("origin_photo", originImage)
      formData.append("x", data.x)
      formData.append("y", data.y)
      formData.append("width", data.width)
      formData.append("height", data.height)

      const res = await axios.put(`/self/photo`, formData)
      return res
    } catch (e) {
      throw e
    }
  },

  deleteProfilePhoto: async () => {
    try {
      return await axios.delete("/self/photo")
    } catch (e) {
      throw e
    }
  },

  getSelfIndividualInfo: async () => {
    try {
      return await axios.get("/self/individual")
    } catch (error) {
      throw error
    }
  },

  setNameAndRole: async (data) => {
    try {
      return await axios.put("/individual/name_and_role", data)
    } catch (error) {
      throw error
    }
  },

  getVideoGallery: async () => {
    try {
      return await axios.get("/video_gallery")
    } catch (error) {
      throw error
    }
  },

  deleteVideo: async (id) => {
    try {
      return await axios.delete(`/video_gallery/${id}`)
    } catch (error) {
      throw error
    }
  },

  getVideoGalleryByTypeAndId: async (type, id) => {
    try {
      if (!["users", "companies"].includes(type))
        throw new Error("Something went wrong...")

      return await axios.get(`/${type}/${id}/video_gallery`)
    } catch (error) {
      throw error
    }
  },

  addVideoToGallery: async (data, options) => {
    try {
      const formData = new FormData()

      data.forEach((video) => {
        formData.append("videos[][file]", video.file)
        formData.append("videos[][length]", video.length)
      })

      return await axios.post("/video_gallery", formData, options)
    } catch (error) {
      throw error
    }
  },
  getFreelanceProfile: async  (uuid) => {
    try {
      return await axios.get(`/freelancer/${uuid}`)
    } catch (error) {
      throw error
    }
  }
}
