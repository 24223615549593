import React from "react"

import styles from "./styles.module.scss"
import { ReactComponent as InfoIcon } from "../../images/icons/info.svg"

const ToastInfo = ({ children }) => {
  return (
    <div className={styles.toastContent}>
      <div className={styles.toastIcon}>
        <InfoIcon />
      </div>
      <div>{children}</div>
    </div>
  )
}

export default ToastInfo
