import {
  SET_SEARCH_QUERY,
  SET_SEARCH_PAGE,
  SET_TAB_FILTER,
  SET_FORM_FILTERS,
  RESET_FORM_FILTERS,
  REMOVE_RESOURCE_TYPE,
  REMOVE_EVENT_TYPE,
  REMOVE_ACCESS_TYPE,
  REMOVE_FROM_DATE,
  REMOVE_TO_DATE,
  REMOVE_LOCATION,
  REMOVE_FREE_ONLY,
  SET_SEARCH_PARAMS,
  SET_REQUEST_RESULT,
} from "./reducer"

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: { query },
})
export const setSearchPage = (pageNumber) => ({
  type: SET_SEARCH_PAGE,
  payload: { pageNumber },
})
export const setTabFilter = (resourceType) => ({
  type: SET_TAB_FILTER,
  payload: { resourceType },
})
export const setFormFilters = (filters) => ({
  type: SET_FORM_FILTERS,
  payload: { filters },
})
export const resetFormFilters = () => ({
  type: RESET_FORM_FILTERS,
  payload: null,
})
export const removeResourceType = () => ({
  type: REMOVE_RESOURCE_TYPE,
  payload: null,
})
export const removeEventType = () => ({
  type: REMOVE_EVENT_TYPE,
  payload: null,
})
export const removeAccessType = () => ({
  type: REMOVE_ACCESS_TYPE,
  payload: null,
})
export const removeFromDate = () => ({
  type: REMOVE_FROM_DATE,
  payload: null,
})
export const removeToDate = () => ({
  type: REMOVE_TO_DATE,
  payload: null,
})
export const removeLocation = () => ({
  type: REMOVE_LOCATION,
  payload: null,
})
export const removeFreeOnly = () => ({
  type: REMOVE_FREE_ONLY,
  payload: null,
})
export const setSearchParams = (locationSearchGroup) => ({
  type: SET_SEARCH_PARAMS,
  payload: { locationSearchGroup },
})
export const setRequestResult = (data, locationSearchGroup) => ({
  type: SET_REQUEST_RESULT,
  payload: { data, locationSearchGroup },
})
