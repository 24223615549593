import React, { useRef } from "react"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import classnames from "classnames"

import styles from "./index.module.scss"
import CrossButton from "../../CrossButton/CrossButton"
import NotificationCard from "../../cards/NotificationCard"
import useOutsideClicker from "../../../hooks/useOutsideClicker"
import { paths } from "../../../routes/paths"
import { setNotifications } from "../../../actions/notifications/notificationsActions"
import services from "../../../services"

const Notifications = ({ onClose, notifications, isVisible }) => {
  const wrapperRef = useRef(null)
  const dispatch = useDispatch()
  useOutsideClicker(wrapperRef, onClose)
  const { systemNotifications, notificationsTotalCount } = notifications

  const markNotificationAsRead = (uuid) => {
    services.notificationsServices.markNotificationRead(uuid)
    const newNotifications = systemNotifications.map((n) => {
      if (n.uuid === uuid) {
        return {
          ...n,
          is_readed: true,
        }
      }
      return n
    })

    dispatch(
      setNotifications({
        ...notifications,
        system_notifications: notifications.systemNotificationsCount,
        notificationsTotalCount: notifications.notificationsTotalCount,
        notifications: newNotifications,
      }),
    )
  }

  return (
    <div
      className={classnames(
        styles.notifications,
        isVisible && styles.notificationsVisible,
      )}
      ref={wrapperRef}
    >
      <div className={styles.header}>
        <p>Notifications</p>
        <CrossButton crossStyle="darkRounded" onClick={onClose} />
      </div>

      <div className={styles.cardsWrapper}>
        {systemNotifications &&
          systemNotifications.map((notification) => (
            <div className={styles.cardWrapper} key={notification.id}>
              <NotificationCard
                isNew={!notification.is_readed}
                date={notification.created_at}
                image={notification.sender.photo}
                notification={notification}
                onEnterViewport={() => {
                  if (!notification.is_readed) {
                    markNotificationAsRead(notification.uuid)
                  }
                }}
              />
            </div>
          ))}
      </div>

      <div className={styles.footer}>
        {notificationsTotalCount > 10 && (
          <Link to={paths.notifications} onClick={() => onClose()}>
            Show all
          </Link>
        )}
      </div>
    </div>
  )
}

export default Notifications
