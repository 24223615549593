import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import { setCompanyType } from "../../useReducer/actionCreators"
import useOption from "./useOption"

const CompanyTypeDropdown = ({ externalDispatch, selectedType }) => {
  const [option, setOption] = useOption(selectedType)
  return (
    <Dropdown
      label="Company Type"
      id="company_type_dropdown"
      options={options}
      value={option}
      onChange={(option) => {
        setOption(option)
        externalDispatch(setCompanyType(option.id))
      }}
    />
  )
}
export default CompanyTypeDropdown
