import React, { useRef, useReducer, useEffect } from "react"
import moment from "moment"
import Input from "../../../../Input/Input"
import Calendar from "../../../../Calendar/Calendar"
import useCalendarWidth from "./useCalendarWidth"
import useClickOutsideCalendar from "./useClickOutsideCalendar"
import { reducer, initState } from "./reducer"
import {
  openFromDate,
  openToDate,
  setDates,
  closeCalendar,
} from "./actionCreators"
import { setFromDate, setToDate } from "../../useReducer/actionCreators"
import styles from "./index.module.scss"

const DatePicker = ({ fromDate, toDate, externalDispatch }) => {
  const calendarRef = useRef()
  const [state, dispatch] = useReducer(reducer, initState)
  const [pickerRef, calendarWidth] = useCalendarWidth(282)
  useClickOutsideCalendar(calendarRef, state.isCalendarOpen, () =>
    dispatch(closeCalendar()),
  )
  useEffect(() => {
    dispatch(setDates(fromDate, toDate))
  }, [fromDate, toDate])
  return (
    <div ref={pickerRef} className={styles.dateField}>
      <p className={styles.title}>Community Creation Date</p>
      <Input
        className={styles.date}
        type="text"
        placeholder="From"
        value={fromDate?.format("MM/DD/YYYY")}
        onClick={() => dispatch(openFromDate())}
      />
      <span className={styles.dash}>-</span>
      <Input
        className={styles.date}
        type="text"
        placeholder="To"
        value={toDate?.format("MM/DD/YYYY")}
        onClick={() => dispatch(openToDate())}
      />
      {state.isCalendarOpen && (
        <div className={styles.calendar} ref={calendarRef}>
          <Calendar
            autoFocus={false}
            selected={state.selectedDate}
            width={calendarWidth}
            height={
              (state.dateType === "to" && moment().isSame(fromDate, "month")) ||
              (state.dateType === "from" &&
                moment("2020-01-01").isSame(toDate, "month"))
                ? 280
                : 500
            }
            min={state.minDate}
            max={state.maxDate}
            minDate={state.minDate}
            maxDate={state.maxDate}
            onChange={(selectedDate) => {
              if (state.dateType === "from") {
                externalDispatch(setFromDate(moment(selectedDate)))
              } else {
                externalDispatch(setToDate(moment(selectedDate)))
              }
              dispatch(closeCalendar())
            }}
          />
        </div>
      )}
    </div>
  )
}
export default DatePicker
