import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip"

const LabelBadge = ({ bgColor, text, tooltipText }) => {
  return (
    <CustomTooltip text={tooltipText}>
      <div
        className={styles.wrapper}
        style={{
          background: `
            linear-gradient(
              75deg, 
              transparent 6px, 
              ${bgColor || "#004d40"} 8px
            )
          `,
        }}
      >
        {text}
      </div>
    </CustomTooltip>
  )
}

LabelBadge.propTypes = {
  text: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  tooltipText: PropTypes.string,
}

LabelBadge.defaultProps = {
  bgColor: "#004d40",
  tooltipText: null,
}

export default LabelBadge
