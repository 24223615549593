import React, { useEffect, useState } from "react"
import classNames from "classnames"
import Box from "@material-ui/core/Box"
import { useSelector, useDispatch } from "react-redux"

import styles from "./IndividualProfileContainer.module.scss"
import UserInfo from "../../components/UserInfo/UserInfo"
import ProfileTable from "../../components/ProfileTable/ProfileTable"
import VideosPreviewer from "../../components/Videos/VideosPreviewer"
import AddProfilePhotoPopup from "../../components/AddProfilePhotoPopup/AddProfilePhotoPopup"
import {
  userSetsAvatar,
  userDeletesAvatar,
  userChangeFullName,
} from "../../actions/user/userActions"
import services from "../../services"
import { selectInstitute } from "./selectors"
import PhotoGalleryWrap from "../../components/PhotoGallery/PhotoGalleryWrap"
import { objectDeepCopy } from "../../helpers"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"
import ResetPasswordError from "../../components/ResetPasswordError/ResetPasswordError"

const IndividualProfileContainer = () => {
  const [showPhotoPopup, setShowPhotoPopup] = useState(false)
  const [data, setData] = useState({})
  const userInfo = useSelector((state) => state.user)
  const dispatcher = useDispatch()

  useEffect(() => {
    services.userServices
      .getSelfIndividualInfo()
      .then((result) => {
        const schools = result.data.schools.map(selectInstitute)
        const videos = result.data.video_gallery
        const photos = result.data.photo_gallery
        setData({ ...result.data, schools, videos, photos })
        dispatcher(userSetsAvatar(result.data.photo))
      })
      .catch((e) => console.log(e))
  }, [dispatcher])

  const onSaveProfilePhoto = ({
    file,
    croppedImage,
    cropData: { x, y, height, width },
  }) => {
    const photoData = {
      photo: croppedImage,
      origin_photo: file,
      x,
      y,
      height,
      width,
    }
    services.userServices.setProfilePhoto(photoData).then((result) => {
      dispatcher(userSetsAvatar(result.data.photo))
      setData({ ...data, ...result.data })
    })
  }
  const onDeletePhotoProfile = () => {
    dispatcher(userDeletesAvatar())
    setData({
      ...data,
      x: null,
      y: null,
      width: null,
      height: null,
      photo: null,
      origin_photo: null,
    })
  }

  const updateUser = async (dataNew) => {
    let newData = objectDeepCopy(dataNew)
    const result = await services.userServices.updateUser(dataNew)
    if (dataNew["schools"]) {
      newData = { schools: dataNew["schools"].map(selectInstitute) }
    }
    setData({ ...data, ...newData })
  }

  const onChangeNameAndRole = async (dataNew) => {
    await services.userServices.updateUser(dataNew)
    dispatcher(userChangeFullName(dataNew["full_name"]))
    setData({ ...data, name: dataNew["full_name"] })
  }

  const onDeleteVideo = (uuid) => {
    let newVideos = [...data.videos]
    services.userServices.deleteVideo(uuid).then(() => {
      newVideos = newVideos.filter((video) => video.uuid !== uuid)
      setData({ ...data, videos: newVideos })
    })
  }

  const onAddVideo = (videos) => {
    const newVideos = [...data.videos, ...videos]
    setData({ ...data, videos: newVideos })
  }

  const onAddPhotos = (photos) => {
    const newPhotos = [...data.photos, ...photos]
    setData({ ...data, photos: newPhotos })
  }

  const onDeletePhoto = (uuid) => {
    let newPhotos = [...data.photos]
    newPhotos = newPhotos.filter((photo) => photo.uuid !== uuid)
    setData({ ...data, photos: newPhotos })
  }

  if (!data?.full_name) {
    return null
  }

  if (data?.blocked) {
    return (
      <XYCenterAlignedWindow
        children={
          <ResetPasswordError
            errorMessage={"Please contact support for the solution."}
            title={
              "Your profile has been turned down because of inappropriate activity."
            }
          />
        }
      />
    )
  }

  return (
    <div className={classNames("container", "fullWidth", styles.profileWrap)}>
      {showPhotoPopup && (
        <AddProfilePhotoPopup
          onClose={() => {
            setShowPhotoPopup(false)
          }}
          onSave={onSaveProfilePhoto}
          url={data.origin_photo}
          currentData={{
            x: data.x,
            y: data.y,
            width: data.width,
            height: data.height,
          }}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        style={{ width: "100%" }}
      >
        <div className={styles.profileLeftColumn}>
          <UserInfo
            onChangeNameAndRole={onChangeNameAndRole}
            fullName={data["full_name"]}
            jobTitle={data["job_title"]}
            profession={data["profession"]}
            experience={data["experience"]}
            gender={data["gender"]}
            industry={data["industry"]}
            markets={data["markets"]}
            image={userInfo.photo}
            openPhotoPopup={() => {
              setShowPhotoPopup(true)
            }}
            onDeletePhotoProfile={onDeletePhotoProfile}
          />
          <PhotoGalleryWrap
            className={classNames(styles.galleryWrap, styles.tablet)}
            type="preview"
            data={data.photos}
            onAddPhotos={onAddPhotos}
            onDeletePhoto={onDeletePhoto}
          />
          <VideosPreviewer
            className={styles.videosWrap}
            videos={data.videos}
            onDeleteVideo={onDeleteVideo}
            onAddVideo={onAddVideo}
            anotherUser={false}
            userType="users"
          />
        </div>
        <div className={styles.profileRightColumn}>
          <PhotoGalleryWrap
            className={styles.galleryWrap}
            type="preview"
            data={data.photos}
            onAddPhotos={onAddPhotos}
            onDeletePhoto={onDeletePhoto}
          />
          <ProfileTable
            onUpdateSchools={updateUser}
            getToKnowUpdate={updateUser}
            professionTextUpdate={updateUser}
            experienceTextUpdate={updateUser}
            myStoryUpdate={updateUser}
            myInspirationUpdate={updateUser}
            onSkillsUpdate={updateUser}
            data={data}
          />
        </div>
      </Box>
    </div>
  )
}

export default IndividualProfileContainer
