export const businessClassificationOptions = [
  { id: 1, value: "Asian-owned", databaseName: "1" },
  { id: 2, value: "Black-owned", databaseName: "2" },
  { id: 3, value: "Disabled-owned", databaseName: "3" },
  { id: 4, value: "Hispanic/Latin-Owned", databaseName: "4" },
  { id: 5, value: "LGBTQ", databaseName: "5" },
  { id: 6, value: "Native American-owned", databaseName: "6" },
  { id: 7, value: "Service-Disabled Veteran-owned", databaseName: "7" },
  { id: 8, value: "Veteran-owned", databaseName: "8" },
  { id: 9, value: "Woman-owned", databaseName: "9" },
]
