import React from "react"
import { useHistory, useParams } from "react-router-dom"
import { useLastLocation } from "react-router-last-location"
import { useSelector } from "react-redux"

import styles from "./index.module.scss"
import CommunityTopicContainer from "../../containers/CommunityTopicContainer"
import services from "../../services"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import Button from "../../components/Button/Button"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import QuestionsSidebar from "../../components/QuestionsSidebar"
import Loading from "../../components/Loading/Loading"
import { paths } from "../../routes/paths"

const CommunityTopic = () => {
  const userInfo = useSelector((state) => state.user)

  const [state, setState] = React.useState({
    isLoading: false,
  })
  const params = useParams()
  const history = useHistory()

  const handleAddQuestion = () => {
    history.push("/community-topic-edit")
  }

  const handlePostComment = (text, parentId = null) => {
    services.commentsServices
      .postComment(text, state.data.id, "Question", parentId)
      .then((data) =>
        setState({
          ...state,

          data: {
            ...state.data,
            comments: data.data,
            comments_count: state.data.comments_count + 1,
          },
        }),
      )
      .catch((e) => console.log(e))
  }

  const voteComment = (vote, uuid) => {
    services.commentsServices
      .voteForComment(vote, uuid)
      .then(({ data }) =>
        setState({
          ...state,
          data: {
            ...state.data,
            comments: state.data.comments.map((comment) =>
              comment.uuid !== uuid
                ? comment
                : { ...comment, reputation: data },
            ),
          },
        }),
      )
      .catch((e) => console.log(e))
  }

  const handleDeleteComment = (uuid) => {
    services.commentsServices
      .deleteComment(uuid)
      .then(({ data }) => {
        setState({
          ...state,
          data: { ...state.data, comments: data, comments_count: data.length },
        })
      })
      .catch((e) => console.log(e))
  }

  const handleEditComment = (text, uuid) => {
    services.commentsServices
      .editComment(text, uuid)
      .then(({ data }) => {
        setState({ ...state, data: { ...state.data, comments: data } })
      })
      .catch((e) => console.log(e))
  }

  const lastLocation = useLastLocation()

  const getBreadcrumbsPaths = React.useCallback(() => {
    if (state.data) {
      let paths = [
        {
          text: state.data.title,
        },
      ]

      if (lastLocation?.pathname.includes("community-search")) {
        paths = [
          {
            text: "Community",
            link: lastLocation.pathname,
          },
          ...paths,
        ]

        if (lastLocation.search.includes("query")) {
          paths.splice(1, 0, {
            text: "Search",
            link: lastLocation.pathname + lastLocation.search,
          })
        }
      } else {
        paths = [
          {
            text: state.data.user.name || state.data.user.full_name,
            link:
              state.data.user.uuid === (userInfo.company.uuid || userInfo.uuid)
                ? "/company-profile"
                : `/company/${state.data.user.uuid}`,
          },
          ...paths,
        ]

        if (state.data.user.uuid === (userInfo.company.uuid || userInfo.uuid)) {
          paths.splice(1, 0, {
            text: "My Questions",
            link: `/community/my-questions`,
          })
        } else {
          paths.splice(1, 0, {
            text: "Company/Organization questions",
            link: `/company/${state.data.user.uuid}/questions`,
          })
        }
      }

      return paths
    }

    return []
  }, [lastLocation, state.data, userInfo.company.uuid, userInfo.uuid])

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.communityServices
      .getQuestion(params.uuid)
      .then(({ data }) => {
        setState({
          data,
          isLoading: false,
        })
      })
      .catch((e) => {
        console.log(e)
        history.push(paths.home)
      })
  }, [params.uuid])

  return (
    <div className={styles.pageWrap}>
      {state.data && (
        <div className="container">
          <Breadcrumbs chain={getBreadcrumbsPaths()} />
        </div>
      )}

      <ContentWithSidebars
        topSidebar={() => (
          <>
            <div style={{ marginBottom: "24px" }}>
              <Button
                width="100%"
                onClick={() => {
                  history.push(paths.communityMyQuestions)
                }}
              >
                My Communities
              </Button>
            </div>
            <Button width="100%" onClick={handleAddQuestion}>
              Add Peer Circle/Question
            </Button>
          </>
        )}
        bottomSidebar={() => (
          <QuestionsSidebar
            data={state.data?.related_questions}
            isLoading={state.isLoading}
            title="Related Topics"
          />
        )}
      >
        {state.isLoading ? (
          <Loading />
        ) : (
          <CommunityTopicContainer
            data={state.data}
            commentsCount={state?.data?.comments_count || 0}
            setState={setState}
            state={state}
            handlePostComment={handlePostComment}
            handleEditComment={handleEditComment}
            handleDeleteComment={handleDeleteComment}
            voteComment={voteComment}
          />
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default CommunityTopic
