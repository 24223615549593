import React from "react"
import { useLocation, useHistory } from "react-router-dom"
import queryString from "query-string"

import styles from "./index.module.scss"
import PageLinedTitle from "../../components/elements/PageLinedTitle"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import PageTopFilter from "../../components/filters/PageTopFilter"
import FavoritesContainer from "../../containers/FavoritesContainer"
// import MemberSearchFilter from "../../components/filters/MemberSearchFilter"
import services from "../../services"
import NoDataPlaceholder from "../../components/elements/NoDataPlaceholder"
import Loading from "../../components/Loading/Loading"
import features from "../../features"

const dataTypes = [
  { value: "company", type: "companies", label: "Companies" },
  { value: "opportunity", type: "opportunities", label: "Opportunities" },
]

if (features.upworkIntegration) {
  dataTypes.splice(1, 0, { value: "freelancer", type: "freelancers", label: "Freelancers" })
}

const FavoritesPage = () => {
  const [state, setState] = React.useState({
    isLoading: false,
    data: null,
  })
  const [dataType, setDataType] = React.useState(null)
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const currentPage = queryParams.page || 1

  React.useEffect(() => {
    setDataType(queryParams.type || "user")
  }, [queryParams])

  React.useEffect(() => {
    if (dataType) {
      setState({
        isLoading: true,
        data: null,
      })

      services.favoriteServices
        .getList({
          type: dataType,
          page: currentPage,
        })
        .then(({ data }) => {
          setState({
            isLoading: false,
            data,
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dataType])

  return (
    <div className={styles.pageWrap}>
      <PageLinedTitle title="Favorites" />

      <div className="container">
        <PageTopFilter
          options={dataTypes}
          value={dataType}
          onChange={(type) =>
            history.push(
              `${location.pathname}?${queryString.stringify({
                ...queryParams,
                type,
              })}`,
            )
          }
        />
      </div>

      <ContentWithSidebars>
        {state.isLoading && <Loading />}

        {state.data && (
          <React.Fragment>
            <FavoritesContainer
              data={state.data}
              type={dataType}
              currentPage={currentPage}
            />
          </React.Fragment>
        )}
      </ContentWithSidebars>
    </div>
  )
}

export default FavoritesPage
