import React from "react"
import Button from "../Button/Button"
import Popup from "./Popup"
import P from "prop-types"

const ConfirmCancelPopup = ({
  handleCancel,
  setIsPopupOpened,
  cancelMessage,
  acceptTitle,
}) => {
  return (
    <Popup
      dialog
      subtitle={cancelMessage}
      button={
        <Button buttonStyle="black" onClick={handleCancel}>
          {acceptTitle}
        </Button>
      }
      acceptButton={
        <Button
          buttonStyle="outlinedBlack"
          onClick={() => setIsPopupOpened(false)}
        >
          No
        </Button>
      }
      closeAction={() => setIsPopupOpened(false)}
      isCrossIcon={true}
    />
  )
}

ConfirmCancelPopup.propTypes = {
  /** Func on YES button*/
  handleCancel: P.func,
  /** Call callback with arg - false  setIsPopupOpened(false)*/
  setIsPopupOpened: P.func,
}

ConfirmCancelPopup.defaultProps = {
  handleCancel: () => console.log("Handle Cancel"),
  setIsPopupOpened: () => console.log("Callback on close"),
  cancelMessage: "Are you sure you want to cancel your changes?",
  acceptTitle: "Yes",
}

export default ConfirmCancelPopup
