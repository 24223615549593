import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./Popup.module.scss"
import CrossButton from "../CrossButton/CrossButton"

const Popup = ({
  children,
  width,
  height,
  title,
  subtitle,
  text,
  button,
  acceptButton,
  dialog,
  innerStyles,
  titleStyle,
  textStyle,
  closeAction,
  crossIconAction,
  buttonsStyle,
  isCrossIcon,
  className,
  subtitleStyle,
  closeByOutsideClick,
  childrenOverflowScroll,
}) => {
  const popupInnerRef = useRef(null)

  const handleMouseDownOutside = (e) => {
    if (!popupInnerRef.current?.contains(e.target)) {
      closeAction && closeAction()
    }
  }

  useEffect(() => {
    if (closeByOutsideClick) {
      window.addEventListener("mousedown", handleMouseDownOutside)
      return () =>
        window.removeEventListener("mousedown", handleMouseDownOutside)
    }
  }, [closeByOutsideClick, handleMouseDownOutside])

  return (
    <div className={styles.popup}>
      <div className={styles.popup_backdrop}>
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            ...innerStyles,
          }}
          ref={popupInnerRef}
          className={classNames(
            styles.inner,
            {
              [styles.smallPopup]: dialog,
            },
            className,
          )}
        >
          <div className={styles.wrap}>
            <div className={styles.popupHeader}>
              {title && (
                <h3 style={{ ...titleStyle }} className={styles.title}>
                  {title}
                </h3>
              )}

              {isCrossIcon && (
                <div className={styles.crossIconContainer}>
                  <div>
                    <CrossButton
                      crossStyle="darkRounded"
                      onClick={() => {
                        closeAction && closeAction()
                        crossIconAction && crossIconAction()
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {subtitle && (
              <h4 className={styles.subtitle} style={{ ...subtitleStyle }}>
                {subtitle}
              </h4>
            )}

            {text && (
              <div
                style={textStyle}
                className={classNames(styles.text, {
                  [styles.textSmallPopup]: dialog,
                })}
              >
                {text}
              </div>
            )}

            {children && (
              <div
                className={classNames(
                  styles.childrenWrapper,
                  childrenOverflowScroll &&
                    styles.childrenWrapperOverflowScroll,
                )}
              >
                {children}
              </div>
            )}

            {button && (
              <div
                className={classNames(styles.buttons, {
                  [styles.dialogButtonsGroup]: dialog,
                  [styles.buttonsGroup]: button && acceptButton,
                })}
                style={buttonsStyle}
              >
                {button}
                {acceptButton}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Popup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.element,
  acceptButton: PropTypes.element,
  isCrossIcon: PropTypes.bool,
  childrenOverflowScroll: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  closeAction: PropTypes.func,
  closeByOutsideClick: PropTypes.bool,
}

Popup.defaultProps = {
  title: "",
  subtitle: "",
  text: "",
  closeByOutsideClick: true,
  childrenOverflowScroll: false,
}

export default Popup
