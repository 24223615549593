import React from "react"
import styles from "./ProspectsPage.module.scss"
import ProspectsContainer from "../../containers/ProspectsContainer/ProspectsContainer"
import { useLocation } from "react-router-dom"
import queryString from "query-string"

const ProspectsPage = () => {
  const location = useLocation()
  const params = queryString.parse(location.search)
  return (
    <div className={styles.prospectsPage}>
      <ProspectsContainer {...params} />
    </div>
  )
}

export default ProspectsPage
