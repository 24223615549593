import React from "react"
import { useSelector } from "react-redux"
import { Prompt } from "react-router-dom"
import { messages } from "../../helpers/constants"
import SearchFormWrapper from "./wrappers/SearchFormWrapper"
import CaptionWrapper from "./wrappers/CaptionWrapper"
import TagWrapper from "./wrappers/TagWrapper"
import MainWrapper from "./wrappers/MainWrapper"
import MemberSearchFilter from "../../components/filters/MemberSearchFilter"
import useSearch from "./useSearch"
import { setFormFilters, resetFormFilters } from "./useSearch/actionCreators"
import { paths } from "../../routes/paths"
import { INDIVIDUAL,COMPANY_SEEKER } from "../../helpers/userRoles"
import styles from "./index.module.scss"

const MemberSearchContainer = () => {
  const [state, dispatch] = useSearch()
  const { requestResultGroup } = state
  const userInfo = useSelector((state) => state.user)
  const userUUID = useSelector((state) => state.user.uuid)
  const companyUUID = useSelector((state) => state.user.company?.uuid)
  const isHaveChatButton = (userInfo.role !== INDIVIDUAL) && (userInfo.company.type !== COMPANY_SEEKER)

  return (
    <div className={styles.searchContainer}>
      <SearchFormWrapper state={state} dispatch={dispatch} />
      <CaptionWrapper state={state} />
      <div className={styles.container}>
        <div className={styles.body}>
          <main>
            <TagWrapper
              requestResultGroup={requestResultGroup}
              dispatch={dispatch}
            />
            <MainWrapper
              state={state}
              dispatch={dispatch}
              userUUID={userUUID}
              companyUUID={companyUUID}
              isHaveChatButton={isHaveChatButton}
            />
          </main>
          <aside>
            <div className={styles.sideBar}>
              <MemberSearchFilter
                requestResultGroup={state.requestResultGroup}
                onReset={() => dispatch(resetFormFilters())}
                onSubmit={(values) => dispatch(setFormFilters(values))}
              />
              {/* <div className={styles.trendingBlock}>
                <p className={styles.caption}>Trending</p>
              </div> */}
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default MemberSearchContainer
