import adviceImage from "./../../images/resources/types/advice.svg"
import meetupImage from "./../../images/resources/types/meetup.svg"
import fundingImage from "./../../images/resources/types/funding.svg"
import etiquetteImage from "./../../images/resources/types/etiquette.svg"
import unspokenRulesImage from "./../../images/resources/types/unspoken_rules.svg"
import { convertCamelCaseToTitle } from "../../helpers/textHelpers"

export const resourcesTypes = {
  advice: "advice",
  funding: "funding",
  etiquette: "etiquette",
  unspoken_rules: "unspoken_rules",
  meetup: "meetup",
}

export const resourcesTypeCards = [
  {
    id: 1,
    imgUrl: meetupImage,
    title: convertCamelCaseToTitle(resourcesTypes.meetup),
    type: resourcesTypes.meetup,
  },
  {
    id: 2,
    imgUrl: adviceImage,
    title: convertCamelCaseToTitle(resourcesTypes.advice),
    type: resourcesTypes.advice,
  },
  {
    id: 3,
    imgUrl: fundingImage,
    title: convertCamelCaseToTitle(resourcesTypes.funding),
    type: resourcesTypes.funding,
  },
  {
    id: 4,
    imgUrl: etiquetteImage,
    title: convertCamelCaseToTitle(resourcesTypes.etiquette),
    type: resourcesTypes.etiquette,
  },
  {
    id: 5,
    imgUrl: unspokenRulesImage,
    title: convertCamelCaseToTitle(resourcesTypes.unspoken_rules),
    type: resourcesTypes.unspoken_rules,
  },
]
