import React from "react"

import styles from "./CompanyReourcesPage.module.scss"
import CompanyResourcesContainer from "../../containers/CompanyResourcesContainer/CompanyResourcesContainer"

const CompanyDepartmentsPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <CompanyResourcesContainer />
    </div>
  )
}

export default CompanyDepartmentsPage
