import axios from "./axios"
import queryString from "query-string"

export default {
  createResource: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (item === "date") {
          formattedData['date_start'] = value && new Date(value.start).toLocaleDateString("en-GB")
          formattedData['date_end'] = value && new Date(value.end).toLocaleDateString("en-GB")
        }
        if (typeof value === "object") {
          for (let key in value) {
            let itemName = `${item}_${key}`
            let itemValue = value[key]
            if (itemName === "photo_path") {
              formattedData["photo"] = formattedData[item].file
            } else if (itemName === "video_path") {
              formattedData["video_file"] = formattedData[item].file.file
            } else if (itemName === "video_file" || itemName === "photo_file") {
            } else {
              formattedData[itemName] = itemValue
            }
          }
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo" ||
          item === "date_start" ||
          item === "date_end" ||
          item === "date"
        )
          form.append(item, value)
      })
      return await axios.post(`/resources`, form)
    } catch (error) {
      throw error
    }
  },

  updateResource: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (typeof value === "object") {
          for (let key in value) {
            let itemName = `${item}_${key}`
            let itemValue = value[key]
            if (itemName === "photo_path") {
              formattedData["photo"] = formattedData[item].file
            } else if (itemName === "video_path") {
              formattedData["video_file"] = formattedData[item].file.file
            } else if (itemName === "video_file" || itemName === "photo_file") {
            } else {
              formattedData[itemName] = itemValue
            }
          }
        }
        if (item === 'date') {
          formattedData["date_start"] =
            value && new Date(value.start).toLocaleDateString("en-GB")
          formattedData["date_end"] =
            value && new Date(value.end).toLocaleDateString("en-GB")
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo" ||
          item === "date_start" ||
          item === "date_end" ||
          item === "date"
        )
          form.append(item, value)
      })
      return await axios.put(`/resources/${data.uuid}`, form)
    } catch (error) {
      throw error
    }
  },

  getCompanyResources: async (id, params) => {
    try {
      return await axios.get(`/companies/${id}/resources`, { params })
    } catch (error) {
      throw error
    }
  },

  getMyEvents: async (params) => {
    try {
      return await axios.get("/my_events", { params })
    } catch (error) {
      throw error
    }
  },

  getResourceByUuid: async (id) => {
    try {
      return await axios.get(`/resources/${id}`)
    } catch (error) {
      throw error
    }
  },

  deleteResourceByUuid: async (id) => {
    try {
      return await axios.delete(`/resources/${id}`)
    } catch (error) {
      throw error
    }
  },

  getResourceAttendees: async (id, params) => {
    try {
      return await axios.get(`/resources/${id}/attendees_list`, { params })
    } catch (error) {
      throw error
    }
  },

  attendToResource: async ({ uuid, status }) => {
    const url = `/resources/${uuid}/${status ? "attend" : "refuse"}`

    try {
      return await axios.get(url)
    } catch (error) {
      throw error
    }
  },
  getMainResources: async (type, limit) => {
    const search = queryString.stringify({
      resource_type: type,
      limit: limit || null,
    })
    try {
      return await axios.get(`/main_resources?${search}`)
    } catch (error) {
      throw error
    }
  },
}
