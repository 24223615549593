import { useState, useEffect } from "react"
import { readFile } from "../../../helpers/imageHelpers"
import { v4 as uuidv4 } from "uuid"
import { getVideoDuration } from "../../../helpers"
import { source } from "../../../services/axios"
import services from "../../../services"

const useUploadVideo = () => {
  const [uploadedVideos, setUploadedVideos] = useState([])
  const [uploadedVideosUrl, setUploadedVideosUrl] = useState([])
  const [loading, setLoading] = useState(0)
  const [error, setError] = useState("")
  const maxWeight = 40000000 // 40 mB

  const clearUploadedVideos = () => {
    setUploadedVideos([])
    setUploadedVideosUrl([])
    setError("")
  }

  const onUploadVideo = async (videos, { target }) => {
    const files = Array.from(target.files)
    let validatedFiles = files.filter((file, index) => {
      if (file.size > maxWeight || index > 11) {
        setError(
          "Sorry, there was a problem while uploading the video. Please, try again.",
        )
      } else if (videos.length + files.length + uploadedVideos.length > 12) {
        setError("Sorry, you can`t add more than 12 videos.")
      } else {
        setError()
      }
      return (
        file.size <= maxWeight &&
        index < 12 &&
        videos.length + files.length + uploadedVideos.length <= 12
      )
    })

    validatedFiles = await Promise.all(
      validatedFiles.map(async (file) => {
        return {
          id: uuidv4(),
          name: file.name,
          length: await getVideoDuration(file),
          file,
        }
      }),
    )

    const videoUrls = await Promise.all(
      validatedFiles.map(async (file) => {
        return {
          id: file.id,
          name: file.name,
          length: file.length,
          path: await readFile(file.file),
        }
      }),
    )

    setUploadedVideos([...uploadedVideos, ...validatedFiles])
    setUploadedVideosUrl([...uploadedVideosUrl, ...videoUrls])
    target.value = ""
  }

  const handlePopupMediaDelete = (id) => {
    const newVideosUrl = uploadedVideosUrl.filter((video) => video.id !== id)
    const newVideoFiles = uploadedVideos.filter((video) => video.id !== id)
    setUploadedVideosUrl(newVideosUrl)
    setUploadedVideos(newVideoFiles)
  }

  const handleUploadMedia = async () => {
    setError()
    const options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percentage = Math.floor((loaded * 100) / total)
        setLoading(percentage)
      },
      cancelToken: source.token,
    }

    const result = await services.userServices.addVideoToGallery(
      uploadedVideos,
      options,
    )
    setLoading(false)
    clearUploadedVideos()
    return result
  }

  return {
    uploadedVideos,
    loading,
    error,
    setLoading,
    setError,
    clearUploadedVideos,
    onUploadVideo,
    uploadedVideosUrl,
    handlePopupMediaDelete,
    handleUploadMedia,
  }
}

export default useUploadVideo
