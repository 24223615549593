/* eslint-disable react-hooks/exhaustive-deps */
import React, { useReducer, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"

import styles from "./ChatsContainer.module.scss"
import ChatsListWrapper from "./wrappers/ChatsListWrapper"
import { reducer, initialState } from "./helpers/reducer"
import { setRequestResult } from "./helpers/actionCreators"
import { updateNotifications } from "../../actions/notifications/notificationsActions"
import ChatRoomWrapper from "./wrappers/ChatRoomWrapper"
import services from "../../services"
import { paths } from "../../routes/paths"
import {
  getLastMessageUuid,
  getFirstMessageUuid,
  insertNewMessages,
  getUnreadedMessagesUuid,
  markMessageAsRead,
} from "./helpers"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

const ChatsContainer = () => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [intervalName, setIntervalName] = useState()
  const reduxDispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const { width } = useWindowHeightWidth()

  const getInbox = () => {
    services.chatServices
      .getUserChats({ roomUuid: params.uuid, query: state.search })
      .then(({ data }) => {
        dispatch(
          setRequestResult({
            dialogList: [...data.inbox],
          }),
        )
      })
      .catch((e) => console.log(e))
  }

  const getChats = () => {
    dispatch(
      setRequestResult({
        ...initialState,
        dialogList: state.dialogList,
        loading: true,
        search: state.search,
      }),
    )
    services.chatServices
      .getUserChats({ roomUuid: params.uuid, query: state.search })
      .then(({ data }) => {
        const isMobile = width < 768
        if (!params.uuid && data.inbox[0] && !isMobile) {
          history.push(paths.chats(data.inbox[0].uuid))
        }
        dispatch(
          setRequestResult({
            dialogList: [...data.inbox],
            isChatOpenedMobile:
              state.firstMessage.isFirstLoad && !params.uuid ? false : true,
            activeChat: {
              ...data.room,
              uuid: params.uuid,
            },
            firstMessage: {
              uuid: !isMobile && getFirstMessageUuid(data.room),
              isEnd: false,
              isFirstLoad: true,
            },
            loading: false,
          }),
        )
      })
      .catch((e) => console.log(e))
  }

  const getNewMessages = () => {
    const unreadMessagesUuidArray = getUnreadedMessagesUuid(
      state.activeChat.messages,
    )
    const lastMessageUuid = getLastMessageUuid(state.activeChat)
    services.chatServices
      .getUserChats({
        roomUuid: params.uuid,
        lastMessageUuid,
        unreadMessagesUuid: unreadMessagesUuidArray,
        query: state.search,
      })
      .then(({ data }) => {
        const roomWithNewMessages = insertNewMessages(
          state.activeChat,
          data.room.messages,
          data.room.blocked_by,
        )
        const newRoom = markMessageAsRead(
          roomWithNewMessages,
          data.readed_messages,
        )
        dispatch(
          setRequestResult({
            dialogList: [...data.inbox],
            activeChat: newRoom,
          }),
        )
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    reduxDispatch(updateNotifications())
    getChats()
  }, [params.uuid])

  useEffect(() => {
    if (!state.firstMessage.isFirstLoad || width <= 425) {
      getInbox()
    }
  }, [state.search])

  useEffect(() => {
    clearInterval(intervalName)
    const interval = setInterval(() => {
      getNewMessages()
      reduxDispatch(updateNotifications())
    }, 10000)
    setIntervalName(interval)

    return () => {
      clearInterval(interval || intervalName)
      setIntervalName()
    }
  }, [state.activeChat.messages, state.activeChat.blocked_by, state.search])

  return (
    <div className={styles.chatWrapper}>
      <ChatsListWrapper state={state} dispatch={dispatch} />
      <ChatRoomWrapper state={state} dispatch={dispatch} history={history} />
    </div>
  )
}

export default ChatsContainer
