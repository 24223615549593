import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import styles from "./index.module.scss"
import capitalizeFirstLetter from "../../helpers/capitalizeFirstLetter"
import UserAvatar from "../elements/UserAvatar"

const AttendeesUser = ({ data }) => {
  return (
    <Link
      to={`/${data.seeker_type.toLowerCase()}/${data.seeker_uuid}`}
      className={styles.attendeesWrap}
    >
      <UserAvatar src={data.photo} />

      <div className={styles.attendeesName}>{data.name}</div>
      <div className={styles.attendeesPosition}>
        {capitalizeFirstLetter(data.attendee_type)}
      </div>
    </Link>
  )
}

AttendeesUser.propTypes = {
  data: PropTypes.shape({
    attendee_type: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.string,
    uuid: PropTypes.string,
  }).isRequired,
}

export default AttendeesUser
