import React from "react";
import styles from "./SelectedUserProfilePage.module.scss";
import SelectedUserProfileContainer from "../../containers/SelectedUserProfileContainer/SelectedUserProfileContainer";

const SelectedUserProfilePage = () => (
  <div className={styles.profilePageWrapper}>
    <SelectedUserProfileContainer />
  </div>
);

export default SelectedUserProfilePage;
