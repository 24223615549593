export default {
  business_news: "Business News",
  cryptocurrency: "Cryptocurrency",
  cybersecurity: "Cybersecurity",
  energy: "Energy",
  financial_services: "Financial Services",
  healthcare: "Healthcare",
  industrial: "Industrial",
  publications: "Publications",
  technology: "Technology",
  telecom: "Telecom",
  trends: "Trends",
  skills: "Skills",
}
