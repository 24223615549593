import { useLastLocation } from "react-router-last-location"

import { paths as routes } from "../../routes/paths"

const useBreadcrumbs = (data) => {
  const lastLocation = useLastLocation()

  let paths = [
    lastLocation && lastLocation.pathname.includes("prospects")
      ? {
          link: routes.prospects,
          text: "Prospects",
        }
      : {
          link: routes.memberSearch({ account_type: "company", page: 1 }),
          text: "Companies",
        },
  ]

  if (lastLocation && lastLocation.search.includes("query=")) {
    paths = [
      ...paths,
      {
        link: lastLocation.pathname + lastLocation.search,
        text: "Search",
      },
    ]
  }

  if (data) {
    paths = [...paths, { text: data.name }]
  }

  return paths
}

export default useBreadcrumbs
