import React from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"

import styles from "./index.module.scss"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"
import services from "../../services"
import AttendeesList from "../../components/AttendeesList"
import Loading from "../../components/Loading/Loading"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import Pagination from "../../components/elements/Pagination"
import SimiliarResources from "../../components/SimiliarResources"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

const AttendeesContainer = () => {
  const [state, setState] = React.useState({ isLoading: false })
  const [perPage, setPerPage] = React.useState(20)
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const { width } = useWindowHeightWidth()

  const currentPage = getCurrentPage(location)

  React.useEffect(() => {
    if (width <= 360) {
      setPerPage(10)
    } else if (width <= 768) {
      setPerPage(10)
    } else {
      setPerPage(20)
    }

    setState({ isLoading: true })

    services.resourceServices
      .getResourceAttendees(params.resourceID, {
        page: currentPage,
        per: perPage,
      })
      .then((result) => {
        setState({
          ...result.data,
          isLoading: false,
        })
      })
  }, [currentPage, params.resourceID, perPage, width])

  return state.isLoading ? (
    <Loading />
  ) : (
    <React.Fragment>
      <div className="container">
        {state.resource_topic && (
          <Breadcrumbs
            chain={[
              {
                link: "/resources",
                text: "Resources",
              },
              {
                link: `/resources/${state.resource_uuid}`,
                text: state.resource_topic,
              },
              {
                text: "Attendees",
              },
            ]}
          />
        )}
      </div>

      <ContentWithSidebars
        bottomSidebar={() => (
          <SimiliarResources data={state.similar_resources} />
        )}
      >
        <div className={styles.attendeesContent}>
          {state.attendees && (
            <AttendeesList attendees={state.attendees.on_page} fullPage />
          )}

          {getPageCount(state.attendees?.total_count, perPage) > 1 && (
            <div style={{ padding: "20px", textAlign: "right" }}>
              <Pagination
                currentPageNumber={currentPage}
                totalPageCount={getPageCount(
                  state.attendees.total_count,
                  perPage,
                )}
                onSelect={(page) =>
                  history.push(`${location.pathname}?page=${page}`)
                }
              />
            </div>
          )}
        </div>
      </ContentWithSidebars>
    </React.Fragment>
  )
}

export default AttendeesContainer

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}

const getPageCount = (totalCount, perPage = 20) =>
  Math.ceil(totalCount / perPage) || 1
