import React from "react"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import * as Yup from "yup"
import classNames from "classnames"
import { v4 as uuidv4 } from "uuid"
import styles from "./TagEditor.module.scss"
import { ReactComponent as DeleteIcon } from "../../images/icons/delete-filled.svg"
import { ReactComponent as ResizeIcon } from "../../images/icons/resize.svg"
import { ReactComponent as ErrorIcon } from "../../images/icons/error.svg"
import Input from "../Input/Input"
import { isHaveBadWords } from "../../helpers/badWords"

const TagEditor = ({ className, data, setData }) => {
  const handleDelete = (id) => {
    const skills = data.filter((skill) => skill.id !== id)
    setData(skills)
  }

  const formik = useFormik({
    initialValues: {
      skill: "",
    },
    validationSchema: Yup.object({
      skill: Yup.string()
        .max(
          32,
          "Please enter a correct skill title that consists of no more than 32 characters",
        )
        .required("Please enter a skill title")
        .trim("Please enter a skill title")
        .test({
          name: "badWords",
          test: (val) => !isHaveBadWords(val),
          message: "Please don't use bad language",
          exclusive: false,
        }),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const newSkill = {
        id: uuidv4(),
        name: values.skill,
      }
      setData([...data, newSkill])
      setSubmitting(false)
      resetForm({})
    },
  })

  return (
    <div className={classNames(styles.editorWrap, className)}>
      <div
        className={classNames(styles.editor, {
          [styles.errorEditor]:
            formik.errors.skill && formik.touched.skill && formik.errors.skill,
        })}
      >
        {data.map(({ name, id }) => (
          <div className={styles.skillItem} key={id}>
            <span className={classNames("text16r", styles.title)}>{name}</span>
            <DeleteIcon
              className={styles.deleteIcon}
              onClick={() => handleDelete(id)}
            />
          </div>
        ))}
        <form onSubmit={formik.handleSubmit} className={styles.form}>
          <Input
            name="skill"
            onChange={formik.handleChange}
            className={styles.input}
            placeholder={`Please type skill name and press "Enter" to add`}
            value={formik.values.skill}
            autoFocus
          />
        </form>
        <ResizeIcon className={styles.resize} />
      </div>
      {formik.errors.skill && formik.touched.skill && formik.errors.skill && (
        <div className={styles.error}>
          <ErrorIcon className={styles.icon} />
          <span className={classNames("note12r", styles.text)}>
            {formik.errors.skill && formik.touched.skill && formik.errors.skill}
          </span>
        </div>
      )}
    </div>
  )
}

TagEditor.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  setData: PropTypes.func,
}

TagEditor.defaultProps = {
  className: "",
  data: [],
  setData: () => {},
}

export default TagEditor
