import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import styles from "./UserResponseCard.module.scss"
import UserResponseCardItem from "./UserResponseCardItem"
import services from "../../services"
import Popup from "../Popup/Popup"
import Button from "../Button/Button"

const UserResponseCard = ({ data, userInfo }) => {
  const [dataList, setDataList] = useState({})
  const [popupInfo, setPopupInfo] = useState({
    visible: false,
    message: "",
    type: "",
    targetUuid: "",
  })

  useEffect(() => {
    setDataList(data)
  }, [data])

  const closePopupAction = () => {
    setPopupInfo({
      visible: false,
      message: "",
      type: "",
      targetUuid: "",
    })
  }

  const cancelApproval = (prospectUuid) => {
    const newProspects = dataList.first_prospects.map((prosp) =>
      prosp.prospect_uuid === prospectUuid
        ? { ...prosp, is_applied: !prosp.is_applied }
        : prosp,
    )

    services.prospectsServices
      .changeProspectStatus(prospectUuid, "created")
      .then(() => {
        setDataList({ ...dataList, first_prospects: newProspects })
        closePopupAction()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const handleApprovePopup = (prospectUuid) => {
    const { is_applied } = dataList.first_prospects.find(
      (prop) => prop.prospect_uuid === prospectUuid,
    )
    if (is_applied) return cancelApproval(prospectUuid)

    setPopupInfo({
      visible: true,
      message: "Are you sure you want to accept this prospect?",
      type: "accepted",
      targetUuid: prospectUuid,
    })
  }

  const handleRejectPopup = (prospectUuid) => {
    setPopupInfo({
      visible: true,
      message: "Are you sure you want to reject this prospect?",
      type: "rejected",
      targetUuid: prospectUuid,
    })
  }

  const handleConfirmRequest = () => {
    let newProspects
    if (!popupInfo.targetUuid) return closePopupAction()

    if (popupInfo.type === "accepted") {
      newProspects = dataList.first_prospects.map((prosp) =>
        prosp.prospect_uuid === popupInfo.targetUuid
          ? { ...prosp, is_applied: !prosp.is_applied }
          : prosp,
      )
    } else if (popupInfo.type === "rejected") {
      newProspects = dataList.first_prospects.filter(
        (el) => el.prospect_uuid !== popupInfo.targetUuid,
      )
    }

    services.prospectsServices
      .changeProspectStatus(popupInfo.targetUuid, popupInfo.type)
      .then((result) => {
        const newData =
          popupInfo.type === "rejected"
            ? result.data
            : { ...dataList, first_prospects: newProspects }
        // setDataList({ ...dataList, first_prospects: newProspects })
        setDataList(newData)
        closePopupAction()
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      {dataList?.total_count > 0 && (
        <div className={styles.cardWrap}>
          <h6 className={styles.title}>
            Оpportunities the user has responded to
          </h6>
          <div className={styles.conteiner}>
            {dataList.first_prospects.length > 0 &&
              dataList.first_prospects.map((card) => (
                <UserResponseCardItem
                  key={card.prospect_uuid}
                  card={card}
                  handleApprove={handleApprovePopup}
                  handleReject={handleRejectPopup}
                />
              ))}

            {dataList.total_count > 3 && (
              <Link
                to={`/prospects?name=${userInfo.name}&seeker_type=${userInfo.type}&seeker_uuid=${userInfo.uuid}&type=Prospects`}
                className={styles.showAllBtn}
                disabled
              >
                Show All
              </Link>
            )}
          </div>

          {popupInfo.visible && (
            <Popup
              dialog
              isCrossIcon
              closeAction={closePopupAction}
              subtitle={popupInfo.message}
              button={
                <Button
                  width="97px"
                  buttonStyle="black"
                  onClick={handleConfirmRequest}
                >
                  Yes, sure
                </Button>
              }
              acceptButton={
                <Button
                  width="64px"
                  buttonStyle="outlinedBlack"
                  onClick={closePopupAction}
                >
                  No
                </Button>
              }
            />
          )}
        </div>
      )}
    </>
  )
}

UserResponseCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      favorite: PropTypes.bool,
      dateof: PropTypes.string,
      jobInfo: PropTypes.objectOf(
        PropTypes.shape({
          job: PropTypes.string,
          rank: PropTypes.string,
          payment: PropTypes.string,
        }),
      ),
    }),
  ),
}

UserResponseCard.defaultProps = {
  title: "",
}

export default UserResponseCard
