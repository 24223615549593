import { paths } from "../../../routes/paths"

export const navLinks = [
  {
    path: "/",
    name: "Home",
    exact: true,
    children: null,
  },
  {
    path: paths.opportunitySearch(),
    name: "Opportunities",
    exact: true,
    children: [
      {
        path: paths.opportunitySearch({
          opportunity_type: "diverse_company",
        }),
        name: "Diverse Company",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "funding",
        }),
        name: "Capital/Funding",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "project",
        }),
        name: "Projects",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "board_of_directors",
        }),
        name: "Board of Directors",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "careers_executive",
        }),
        name: "Executive",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "careers_professional",
        }),
        name: "Professional",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "mentoring",
        }),
        name: "Mentors",
      },
      {
        path: paths.opportunitySearch({
          opportunity_type: "sponsorship",
        }),
        name: "Sponsors",
      },
    ],
  },
  {
    path: paths.resourceSearch(),
    name: "Resources",
    exact: true,
    children: [
      {
        path: paths.resourceSearch({ resource_type: "advice" }),
        name: "Advice",
      },
      {
        path: paths.resourceSearch({
          resource_type: "etiquette",
        }),
        name: "Etiquette",
      },
      {
        path: paths.resourceSearch({
          resource_type: "funding",
        }),
        name: "Funding",
      },
      {
        path: paths.resourceSearch({ resource_type: "meetup" }),
        name: "Meetup",
      },
      {
        path: paths.resourceSearch({
          resource_type: "unspoken_rules",
        }),
        name: "Unspoken Rules",
      },
    ],
  },
  {
    name: "Community",
    path: paths.questionSearch(),
    exact: true,
    children: [
      {
        path: paths.questionSearch({
          question_type: "group_chats",
        }),
        name: "Group Chats",
      },
      {
        path: paths.questionSearch({
          question_type: "network",
        }),
        name: "Network",
      },
      {
        path: paths.questionSearch({
          question_type: "peer_circles",
        }),
        name: "Peer Circles",
      },
      {
        path: paths.questionSearch({
          question_type: "referrals",
        }),
        name: "Referrals",
      },
      {
        path: paths.questionSearch({
          question_type: "the_vent",
        }),
        name: "Let’s Connect",
      },
    ],
  },
  {
    name: "Institute",
    path: paths.instituteSearch(),
    exact: true,
    children: [
      {
        path: paths.instituteSearch({ article_type: "business_news" }),
        name: "Business News",
      },
      {
        path: paths.instituteSearch({ article_type: "cryptocurrency" }),
        name: "Cryptocurrency",
      },
      {
        path: paths.instituteSearch({ article_type: "cybersecurity" }),
        name: "Cybersecurity",
      },
      {
        path: paths.instituteSearch({ article_type: "energy" }),
        name: "Energy",
      },
      {
        path: paths.instituteSearch({ article_type: "financial_services" }),
        name: "Financial Services",
      },
      {
        path: paths.instituteSearch({ article_type: "healthcare" }),
        name: "Healthcare",
      },
      {
        path: paths.instituteSearch({ article_type: "industrial" }),
        name: "Industrial",
      },
      {
        path: paths.instituteSearch({ article_type: "publications" }),
        name: "Publications",
      },
      {
        path: paths.instituteSearch({ article_type: "technology" }),
        name: "Technology",
      },
      {
        path: paths.instituteSearch({ article_type: "telecom" }),
        name: "Telecom",
      },
    ],
  },
  {
    name: "Inspiration",
    path: paths.inspirationSearch(),
    exact: true,
    children: [
      {
        path: paths.inspirationSearch({ article_type: "trends" }),
        name: "Trends",
      },
      {
        path: paths.inspirationSearch({ article_type: "skills" }),
        name: "Skills",
      },
    ],
  },
]
