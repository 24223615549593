import React from "react"
import classNames from "classnames"
import P from "prop-types"
import styles from "./OpportunityLabel.module.scss"
import { opportunitiesTypes } from "../../Opportunity/heplers"
import { convertCamelCaseToTitle } from "../../../helpers/textHelpers"

const OpportunityLabel = ({ title, color, style }) => (
  <div
    className={classNames(styles.wrapper, style, {
      [styles.job]: color === opportunitiesTypes.careers_executive,
      [styles.mentorship]: color === opportunitiesTypes.mentoring,
      [styles.project]: color === opportunitiesTypes.project,
      [styles.funding]: color === opportunitiesTypes.funding,
      [styles.sponsorship]: color === opportunitiesTypes.sponsorship,
      [styles.advisoryBoardProtocol]:
        color === opportunitiesTypes.advisoryBoardProtocol,
      [styles.boardOfDirectorsOpportunity]:
        color === opportunitiesTypes.boardOfDirectorsOpportunity,
      [styles.supplier]: color === opportunitiesTypes.careers_professional,
    })}
  >
    <p>{convertCamelCaseToTitle(title)}</p>
  </div>
)

OpportunityLabel.propTypes = {
  title: P.string,
  color: P.oneOf(["careers_executive", "mentorship", "project", "funding"]),
  style: P.string,
}

OpportunityLabel.defaultProps = {
  title: "Label",
  color: "project",
}

export default OpportunityLabel
