export const SET_REQUEST_RESULTS = "SET_REQUEST_RESULTS"
export const SET_ACTIVE_CHAT = "SET_ACTIVE_CHAT"
export const SET_OPEN_CHAT = "SET_OPEN_CHAT"
export const SET_FIRST_MESSAGE = "SET_FIRST_MESSAGE"
export const SET_LOADING = "SET_LOADING"
export const SET_BLOCKED = "SET_BLOCKED"
export const SET_SEARCH = "SET_SEARCH"
export const SET_LOADING_OLD_MESSAGE = "SET_LOADING_OLD_MESSAGE"

export const initialState = {
  dialogList: [],
  activeChat: {},
  firstMessage: {
    uuid: "",
    isEnd: false,
    isFirstLoad: true,
  },
  search: "",
  isChatOpenedMobile: false,
  loading: false,
  loadingOldMessages: false,
}

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_REQUEST_RESULTS: {
      return {
        ...state,
        ...payload.data,
      }
    }

    case SET_ACTIVE_CHAT: {
      return {
        ...state,
        activeChat: { ...payload.data },
      }
    }

    case SET_OPEN_CHAT: {
      return {
        ...state,
        isChatOpenedMobile: payload.data,
        activeChat: {},
        firstMessage: {
          uuid: "",
          isEnd: false,
          isFirstLoad: true,
        },
      }
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: payload.search,
      }
    }

    case SET_FIRST_MESSAGE: {
      return {
        ...state,
        firstMessage: {
          ...state.firstMessage,
          ...payload.data,
        },
      }
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: payload.data,
      }
    }

    case SET_BLOCKED: {
      return {
        ...state,
        activeChat: {
          ...state.activeChat,
          blocked_by: payload.data,
        },
      }
    }
    case SET_LOADING_OLD_MESSAGE: {
      return {
        ...state,
        loadingOldMessages: payload.data,
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
