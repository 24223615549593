import React, { useState, useRef } from "react"
import PropTypes from "prop-types"

import styles from "./ThreeDotsMenu.module.scss"
import { ReactComponent as ThreeDots } from "../../images/icons/ThreeDots.svg"
import useOutsideClicker from "../../hooks/useOutsideClicker"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const ThreeDotsMenu = ({ actions }) => {
  const [opened, setOpened] = useState(false)

  const threeDotsRef = useRef(null)

  useOutsideClicker(threeDotsRef, () => {
    setOpened(false)
  })

  return (
    <div
      ref={threeDotsRef}
      className={styles.wrapper}
      onClick={() => {
        setOpened(!opened)
      }}
    >
      <div className={styles.threeDots}>
        <div className="iconWrap">
          <ThreeDots />
        </div>
      </div>

      {opened && (
        <div className={styles.actionsList}>
          {actions.map((action, index) => (
            <CustomTooltip text={action.tooltip}>
              <div className={styles.action} key={index} onClick={action.click}>
                {action.name}
              </div>
            </CustomTooltip>
          ))}
        </div>
      )}
    </div>
  )
}

ThreeDotsMenu.propTypes = {
  /** The prop takes an array of objects to display buttons in the list   */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      click: PropTypes.func,
    }),
  ).isRequired,
}

export default ThreeDotsMenu
