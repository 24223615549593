import { opportunities } from "./opportunities"

export const getOpportunityTypeName = (opportunityType) => {
  const selectedOpportunity = opportunities.find(
    (opportunity) => opportunity.type === opportunityType,
  )
  if (selectedOpportunity) {
    return selectedOpportunity.title
  }
}
