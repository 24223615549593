import React from "react"
import PropTypes from "prop-types"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  userNameTooltip: {
    fontSize: "16px",
    maxWidth: "260px",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    backgroundColor: "#fff",
    borderRadius: "2px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "0 5px",
    color: "#000",
    fontWeight: "400",
    boxShadow: `
      0px 1px 8px rgba(0, 0, 0, 0.2),
      0px 3px 4px rgba(0, 0, 0, 0.12),
      0px 3px 3px rgba(0, 0, 0, 0.14),
    `,
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
  },
  arrow: {
    color: "rgb(21,21,21)",
  },
  Tooltip: {
    fontSize: "12px",
    maxWidth: "186px",
    whiteSpace: "pre-wrap",
    textAlign: "center",
    textOverflow: "ellipsis",
    backgroundColor: "rgb(21,21,21)",
    borderRadius: "8px",
    marginTop: "10px",
    marginBottom: "10px",
    padding: "8px",
    color: "#FFFFFF",
    fontWeight: "400",
    boxShadow: `
      0px 1px 8px rgba(0, 0, 0, 0.2),
      0px 3px 4px rgba(0, 0, 0, 0.12),
      0px 3px 3px rgba(0, 0, 0, 0.14),
    `,
    "-webkit-font-smoothing": "antialiased",
    "-moz-osx-font-smoothing": "grayscale",
  },
}))

const CustomTooltip = ({ name, text, children }) => {
  const classes = useStyles()

  if (!name && !text) return children

  return (
    <Tooltip
      arrow
      classes={
        name
          ? { tooltip: classes.userNameTooltip }
          : { tooltip: classes.Tooltip, arrow: classes.arrow }
      }
      title={name || text}
      enterTouchDelay={1}
    >
      {children}
    </Tooltip>
  )
}

CustomTooltip.propTypes = {
  text: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.object,
}

export default CustomTooltip
