export const articleTypes = [
  {
    title: "Business News",
    type: "business_news",
  },
  {
    title: "Cryptocurrency",
    type: "cryptocurrency",
  },
  {
    title: "Cybersecurity",
    type: "cybersecurity",
  },
  {
    title: "Energy",
    type: "energy",
  },
  {
    title: "Financial Services",
    type: "financial_services",
  },
  {
    title: "Healthcare",
    type: "healthcare",
  },
  {
    title: "Industrial",
    type: "industrial",
  },
  {
    title: "Publications",
    type: "publications",
  },
  {
    title: "Technology",
    type: "technology",
  },
  {
    title: "Telecom",
    type: "telecom",
  },
]
