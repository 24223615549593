import React from "react"
import classNames from "classnames"
import Breadcrumbs from "../../../../components/elements/Breadcrumbs"
import { paths } from "../../../../routes/paths"
import styles from "./index.module.scss"
import PageLinedTitle from "../../../../components/elements/PageLinedTitle"

const CaptionWrapper = ({ state }) => {
  const { itemCount, query } = state.requestResultGroup
  return !query ? (
    <div className={styles.topCaption}>
      <PageLinedTitle title="Top Resources" inContainer />
    </div>
  ) : (
    <div className="container">
      <div className={styles.searchCaption}>
        <Breadcrumbs
          classes={styles.breadcrumb}
          chain={[
            { link: paths.resourceSearch(), text: "Resources" },
            { text: "Search" },
          ]}
        />
        {!!itemCount && (
          <p
            className={styles.resultCount}
          >{`We found ${itemCount} results for “${query}”`}</p>
        )}
      </div>
    </div>
  )
}
export default CaptionWrapper
