import { useState } from "react"
import { objectDeepCopy } from "../../../../../helpers"

const useGetToKnow = ({ getToKnowUpdate, data }) => {
  const [isEditing, setIsEditing] = useState(false)

  const [editableState, setEditableState] = useState([])
  //const [showedState, setShowedState] = useState([]);

  //changes in inputs, true, when some value of switch changed
  const [hasChanges, setHasChanges] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [inputsValidStatus, setInputsValidStatus] = useState([])
  const [customLinksCount, setCustomLinksCount] = useState(0)

  const handleStartEdit = () => setIsEditing(true)

  const handleDeleteCustomLink = (id) => {
    const nextState = objectDeepCopy(editableState).filter(
      (item) => item.id !== id,
    )
    delete inputsValidStatus[`custom${id}`]
    setCustomLinksCount(customLinksCount - 1)
    setHasChanges(true)
    setEditableState(nextState)
  }

  const handleCancelSaving = () => {
    setEditableState(objectDeepCopy(data))
    setInputsValidStatus({})
    setIsEditing(false)
    setHasChanges(false)
  }

  const handleCancel = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setIsEditing(false)
    }
  }

  const onClosePopup = () => {
    setIsPopupOpened(false)
    handleCancelSaving()
  }

  const handleSave = () => {
    const stateToServer = objectDeepCopy(editableState).map((item, idx) => ({
      ...item,
      id: idx + 1,
    }))
    getToKnowUpdate({ get_to_know_me: stateToServer })
    const saveState = objectDeepCopy(editableState).map((item) => ({
      ...item
    }))

    setIsEditing(false)
    setHasChanges(false)
    setInputsValidStatus({})
    setEditableState(saveState)
  }

  const addCustomLinkToState = () => {
    const id = editableState[editableState.length - 1].id + 1

    setEditableState(
      [...editableState].concat({
        id: id,
        ["field_type"]: "custom",
        ["is_hide"]: true,
        value: "",
      }),
    )
  }
  const OnAddCustomLink = () => {
    if (
      editableState.filter((item) => item["field_type"] === "custom").length < 3
    ) {
      setCustomLinksCount(customLinksCount + 1)
      setHasChanges(true)
      addCustomLinkToState()
    }
  }

  const handleSwitchChange = (id) => {
    const findedById = [...editableState].find((link) => link.id === id)
    const newValue = (findedById["is_hide"] = !findedById["is_hide"])
    const index = [...editableState].findIndex((link) => link.id === id)

    setEditableState(
      [...editableState],
      ([...editableState][index]["is_hide"] = newValue),
    )
  }

  const handleChangeLink = (id, value) => {
    const index = [...editableState].findIndex((link) => link.id === id)
    setEditableState(
      [...editableState],
      ([...editableState][index].value = value),
    )
  }

  const checkHasDataToShow = () => {
    return data.some((link) => {
      return link.value && !link["is_hide"]
    })
  }

  const checkAllInputsValid = () => {
    return Object.values(inputsValidStatus).every((item) => item === true)
  }

  const getInputValidStatus = (type, value) => {
    setInputsValidStatus(
      Object.assign({ ...inputsValidStatus }, { [type]: value }),
    )
  }

  return {
    setEditableState,
    isEditing,
    handleStartEdit,
    handleDeleteCustomLink,
    handleCancelSaving,
    handleChangeLink,
    handleSwitchChange,
    OnAddCustomLink,
    onClosePopup,
    isPopupOpened,
    hasChanges,
    setIsPopupOpened,
    setHasChanges,
    handleSave,
    handleCancel,
    checkHasDataToShow,
    checkAllInputsValid,
    getInputValidStatus,
    setInputsValidStatus,
    editableState,
    customLinksCount,
    setCustomLinksCount,
  }
}

export default useGetToKnow
