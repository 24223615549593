export const topics = [
  {
    title: "All",
    type: "",
    tooltipText: "",
  },
  {
    title: "Peer Circles",
    type: "peer_circles",
    tooltipText: "Connect or invite B2T members to join your peer circle.",
    tooltipPlacement: "bottom",
  },
  {
    title: "Group Chats",
    type: "group_chats",
    tooltipText:
      "Connect with other members in the B2T community by sharing ideas, asking and answering questions.",
    tooltipPlacement: "top",
  },
  {
    title: "Referrals",
    type: "referrals",
    tooltipText:
      "Refer a great company for business, projects or personal use.",
    tooltipPlacement: "bottom",
  },
  {
    title: "Let’s Connect",
    type: "the_vent",
    tooltipText:
      "Your B2T community is here to help you navigate professional pain points and challenges.",
    tooltipPlacement: "top",
  },
  {
    title: "Network",
    type: "network",
    tooltipText:
      "Network with the best talent, companies, opportunities and projects.",
    tooltipPlacement: "bottom",
  },
]
