export const options = [
  {
    id: "",
    value: "All",
    role: "",
  },

  {
    id: "",
    value: "All",
    role: "financial_organizations",
  },
  {
    id: "banking",
    value: "Banking",
    role: "financial_organizations",
  },
  {
    id: "angel_investors",
    value: "Angel investors",
    role: "financial_organizations",
  },
  {
    id: "debt_financing",
    value: "Debt Financing",
    role: "financial_organizations",
  },
  {
    id: "working_capital_financing",
    value: "Working Capital Financing",
    role: "financial_organizations",
  },
  {
    id: "mergers_and_acquisitions",
    value: "Merger & Acquisition",
    role: "financial_organizations",
  },
  {
    id: "private_acquity",
    value: "Private Equity",
    role: "financial_organizations",
  },
  {
    id: "wealth_management",
    value: "Wealth Management",
    role: "financial_organizations",
  },
  {
    id: "venture_capital",
    value: "Venture Capital",
    role: "financial_organizations",
  },

  {
    id: "",
    value: "All",
    role: "dc_education",
  },
  {
    id: "hbcus",
    value: "HBCU",
    role: "dc_education",
  },
  {
    id: "professional_trade",
    value: "Professional Trade",
    role: "dc_education",
  },
  {
    id: "university",
    value: "University",
    role: "dc_education",
  },
]
