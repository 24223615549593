import React from "react"

import styles from "./index.module.scss"
import TrendingCard from "../../components/TrendingCard/TrendingCard"
import trendingImage from "../../images/test/trendingCard.png"
import trendingImage2 from "../../images/test/05.png"

const TrendingTopics = () => {
  return (
    <div>
      <h2 className={styles.trendingTitle}>Trending</h2>

      <div className={styles.cardsWrap}>
        <div className={styles.trendingCard}>
          <TrendingCard
            title="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Georgia"
            text="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Geo..."
            imgUrl={trendingImage}
          />
        </div>
        <div className={styles.trendingCard}>
          <TrendingCard
            title="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Georgia"
            text="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Geo..."
            imgUrl={trendingImage2}
          />
        </div>
      </div>
    </div>
  )
}

export default TrendingTopics
