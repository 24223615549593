import React, { useState, useEffect } from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import Input from "../../../../Input/Input"
import { setSkill, setSkillInput } from "../../useReducer/actionCreators"
import suggestionServices from "../../../../../services/suggestionServices"
import capitalizeFirstLetter from "../../../../../helpers/capitalizeFirstLetter"

const SkillAutocomplete = ({ skillInput, externalDispatch }) => {
  const [options, setOptions] = useState([])
  useEffect(() => {
    suggestionServices
      .getIndividualSkills(skillInput || "")
      .then(({ data }) => {
        setOptions([
          ...new Set(
            data
              .filter((option) => Boolean(option?.trim()))
              .slice(0, 20)
              .sort((a, b) => {
                const str1 = a?.toLowerCase()
                const str2 = b?.toLowerCase()
                if (str1 > str2) {
                  return 1
                } else if (str1 < str2) {
                  return -1
                } else {
                  return 0
                }
              })
              .map((option) => capitalizeFirstLetter(option.trim())),
          ),
        ])
      })
    // eslint-disable-next-line
  }, [skillInput])
  return (
    <Autocomplete
      inputValue={skillInput}
      options={options}
      renderInput={(params) => {
        return (
          <div ref={params.InputProps.ref}>
            <Input
              type="text"
              label="Skill"
              placeholder="Enter skill"
              {...params.inputProps}
            />
          </div>
        )
      }}
      onInputChange={(e, value) => {
        externalDispatch(setSkillInput(value.substring(0, 64)))
      }}
      onChange={(e, skill, reason) => {
        if (reason === "select-option") {
          externalDispatch(setSkill(skill))
        }
      }}
    />
  )
}
export default SkillAutocomplete
