import React, { useState } from "react"
import { Link } from "react-router-dom"

import styles from "./CompanyDepartments.module.scss"
import CompanyInfoItem from "../CompanyInfoItem/CompanyInfoItem"
import DepartmentItem from "./DepartmentItem/DepartmentItem"
import DepartmentCreatorPopup from "./DepartmentCreatorPopup/DepartmentCreatorPopup"
import { paths } from "../../routes/paths"

const CompanyDepartments = ({
  data,
  saveNewDeparment,
  width,
  anotherUser,
  uuid,
}) => {
  const [visiblePopup, setVisiblePopup] = useState(false)

  const placeholder =
    anotherUser && !data?.length
      ? "No departments yet"
      : data?.length > 0
      ? null
      : "You didn't add any departments yet"

  return (
    <CompanyInfoItem
      headTitle={
        anotherUser ? "Company/Organization Departments" : "My Departments"
      }
      headBtnText={width < 451 ? "Add" : "Add Department"}
      bodyPlaceholderText={placeholder}
      handleButtonClick={() => {
        setVisiblePopup(true)
      }}
      width={width}
      anotherUser={anotherUser}
    >
      {data?.length > 0 && (
        <div className={styles.wrapper}>
          <div className={styles.departmentsWrapper}>
            {data.map((department, index) => {
              if (index < 3) {
                return (
                  <DepartmentItem key={index} departmentInfo={department} />
                )
              }
            })}
          </div>

          {data.length > 0 && (
            <div className={styles.showMoreBtn}>
              <Link to={paths.departments(uuid)} className={"goldLink"}>
                Show all departments
              </Link>
            </div>
          )}
        </div>
      )}

      <DepartmentCreatorPopup
        visible={visiblePopup}
        title="Add Department"
        closeAction={() => {
          setVisiblePopup(false)
        }}
        onSave={saveNewDeparment}
        maxNumber={data?.length >= 12}
      />
    </CompanyInfoItem>
  )
}

export default CompanyDepartments
