import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import LabelBadge from "../LabelBadge"

const CardWithBadge = ({ children, badgeText, badgeColor, tooltipText }) => {
  return (
    <div className={styles.cardWrap}>
      <div className={styles.cardBadge}>
        <LabelBadge
          text={badgeText}
          bgColor={badgeColor}
          tooltipText={tooltipText}
        />
      </div>

      {children}
    </div>
  )
}

CardWithBadge.propTypes = {
  badgeText: PropTypes.string.isRequired,
  badgeColor: PropTypes.string,
  tooltipText: PropTypes.string,
}

CardWithBadge.defaultProps = {
  badgeColor: "#004d40",
  tooltipText: null,
}

export default CardWithBadge
