import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styles from "./TextLimit.module.scss";

const TextLimit = ({ currentLength, maxLength }) => {
  const isFull = currentLength > maxLength;
  const isValid = Number.isInteger(currentLength + maxLength);

  if (!isValid) return null;

  return (
    <span className={classNames(isFull && styles.errored, styles.textLimit)}>
      {currentLength}/{maxLength}
    </span>
  );
};

TextLimit.propTypes = {
  currentLength: PropTypes.number,
  maxLength: PropTypes.number,
};

export default TextLimit;
