import { useRef, useEffect, useState } from "react"

const useCalendarWidth = (defaultWidth) => {
  const pickerRef = useRef()
  const [calendarWidth, setCalendarWidth] = useState(defaultWidth)
  useEffect(() => {
    const changeCalendarWidth = () => {
      if (pickerRef.current) {
        setCalendarWidth(pickerRef.current.clientWidth + 32)
      }
    }
    window.addEventListener("resize", changeCalendarWidth)
    return () => {
      window.removeEventListener("resize", changeCalendarWidth)
    }
  }, [window.innerWidth])
  return [pickerRef, calendarWidth]
}
export default useCalendarWidth
