import React from "react"
import { Grid } from "@material-ui/core"
import styles from "./OpportunityParams.module.scss"
import OpportunityParamsItem from "./OpportunityParamsItem/OpportunityParamsItem"
import OpportunityLabel from "../../../elements/OpportunityLabel/OpportunityLabel"
import { getOpportunityParamsOrder } from "../../heplers"
import p from "prop-types"

const OpportunityParams = ({ data }) => {
  let paramsData = { ...data, posted: data["created_at"] }
  delete paramsData["created_at"]

  //delete params that not using for display in this section
  ;[
    "description",
    "video",
    "photo",
    "id",
    "opportunity_type",
    "company",
    "uuid",
    "name",
    "company_name",
    "similar_opportunities",
    "prospect_status",
  ].forEach((itemName) => {
    delete paramsData[itemName]
  })
  //get arr for sort section params

  const sortingArr = getOpportunityParamsOrder(data["opportunity_type"])
  paramsData = {
    ...paramsData,
    funder_title: paramsData.funder_role,
  }
  //sorting data by sort array
  paramsData = Object.entries(paramsData).sort((a, b) => {
    return sortingArr.indexOf(a[0]) - sortingArr.indexOf(b[0])
  })

  return (
    <Grid component="div" container className={styles.infoSection}>
      {paramsData &&
        paramsData.map(([title, value], index) => {
          return title !== "favorite" ? (
            <OpportunityParamsItem
              key={value + title}
              value={value}
              title={title}
            />
          ) : (
            ""
          )
        })}

      <OpportunityLabel
        color={data["opportunity_type"]}
        title={data["opportunity_type"]}
        style={styles.label}
      />
    </Grid>
  )
}

OpportunityParams.propTypes = {
  data: p.object.isRequired,
}

export default OpportunityParams
