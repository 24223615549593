import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import SelectedCompanyProfileContainer from "../../containers/SelectedCompanyProfileContainer/SelectedCompanyProfileContainer"
import styles from "./SelectedCompanyProfilePage.module.scss"

const SelectedCompanyProfilePage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)
  return (
    <div className={styles.profilePageWrapper}>
      <SelectedCompanyProfileContainer from={values?.from} />
    </div>
  )
}
export default SelectedCompanyProfilePage
