import {
  USER_LOGIN,
  USER_LOGOUT,
  USER_UPDATES_AVATAR,
  USER_DELETES_AVATAR,
  USER_CHANGE_FULLNAME,
  UPDATE_COMPANY_AVATAR,
  DELETE_COMPANY_AVATAR,
} from "../helpers/constants"
import { load } from "../helpers/localStorage"
import { selectFormattedUser } from "./selectors"
import { GUEST } from "../helpers/userRoles"

const savedUser = load("currentUser")

let initialState = {
  id: "",
  uuid: "",
  role: GUEST,
  fullName: "",
  email: "",
  photo: "",
  company: {},
}

if (savedUser) {
  initialState = savedUser
}

const user = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case USER_LOGIN: {
      return selectFormattedUser(payload)
    }

    case USER_LOGOUT: {
      return {
        id: "",
        uuid: "",
        role: GUEST,
        fullName: "",
        email: "",
        photo: "",
        company: {},
      }
    }

    case USER_UPDATES_AVATAR: {
      return {
        ...state,
        photo: payload.avatar,
      }
    }

    case USER_DELETES_AVATAR: {
      return {
        ...state,
        photo: "",
      }
    }

    case USER_CHANGE_FULLNAME: {
      return {
        ...state,
        fullName: payload.fullName,
      }
    }

    case UPDATE_COMPANY_AVATAR: {
      return {
        ...state,
        company: {
          ...state.company,
          photo: payload.photo,
        },
      }
    }

    case DELETE_COMPANY_AVATAR: {
      return {
        ...state,
        company: {
          ...state.company,
          photo: "",
        },
      }
    }

    default: {
      return state
    }
  }
}

export default user
