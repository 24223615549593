import React from "react"
import s from "./LongArticleCard.module.scss"
import logo from "../../../images/logo.png"
import { useHistory } from "react-router-dom"
import { paths } from "../../../routes/paths"

const LongArticleCard = ({ article = {} }) => {
  const history = useHistory()
  return (
    <div
      className={s.articleBox}
      onClick={() => {
        history.push(paths.specificInstituteArticle(article.uuid))
      }}
    >
      <div
        className={s.image}
        style={{
          background: `center / cover no-repeat url(${article.image_url})`,
        }}
      />

      <div className={s.authorBlock}>
        <img src={logo} />
        <p>Administator</p>
      </div>

      <div className={s.content}>
        <h2>{article.title}</h2>
        <p>{article.content}</p>
      </div>
    </div>
  )
}

export default LongArticleCard
