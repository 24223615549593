import moment from "moment"

const oneMinute = 60000 // in Miliseconds

export const isDuringLastMinute = (date) => {
  const currentTime = moment()

  const ms = currentTime.diff(moment(date))

  if (ms / oneMinute < 1) {
    return true
  }
}

export const isDuringLastHour = (date) => {
  const currentTime = moment()

  const ms = currentTime.diff(moment(date))
  const minutes = ms / oneMinute

  if (minutes / 60 < 1) {
    return moment.duration(ms).minutes()
  }
}

export const isDuringCurrentDay = (date) => {
  const currentTime = moment()

  const ms = currentTime.diff(moment(date))
  const minutes = ms / oneMinute
  const hours = minutes / 60

  if (hours < 24) {
    return moment.duration(ms).hours()
  }
}
