export const checkIsFilterUsed = ({
  job_title,
  location,
  describe_me,
  activity,
  gender,
  race,
  describe_company,
  industry,
  department,
}) =>
  Boolean(
    job_title ||
      location ||
      describe_me ||
      activity ||
      gender ||
      race ||
      describe_company ||
      industry ||
      department,
  )
