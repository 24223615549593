import { objectDeepCopy } from "../../../helpers"

export const getLastMessageUuid = (room) => {
  if (room.messages) {
    const messagesArray = Object.values(room.messages)
    const lastMessagesByDate = messagesArray[messagesArray?.length - 1]
    const lastMessage = lastMessagesByDate?.[lastMessagesByDate.length - 1]
    return lastMessage?.uuid || null
  } else {
    return null
  }
}

export const getFirstMessageUuid = (room) => {
  if (room.messages) {
    const messagesArray = Object.values(room.messages)
    const firstMessagesByDate = messagesArray[0]
    const firstMessage = firstMessagesByDate?.[0]
    return firstMessage?.uuid || ""
  } else {
    return ""
  }
}

export const insertNewMessages = (room, newMessages, isBlocked) => {
  const messagesDate = newMessages && Object.keys(newMessages)
  const copyRoom = objectDeepCopy(room)
  copyRoom.blocked_by = isBlocked

  if (messagesDate in room.messages) {
    copyRoom.messages = {
      ...copyRoom.messages,
      [messagesDate]: [
        ...copyRoom.messages[messagesDate],
        ...newMessages[messagesDate],
      ],
    }
  } else {
    copyRoom.messages = {
      ...copyRoom.messages,
      ...newMessages,
    }
  }
  return copyRoom
}

export const replaceCurrentMessages = (room, newMessages) => {
  const copyRoom = objectDeepCopy(room)
  copyRoom.messages = newMessages

  return copyRoom
}

export const addPreviousMessages = (room, previousMessages) => {
  const messagesDate = previousMessages && Object.keys(previousMessages)
  const copyRoom = objectDeepCopy(room)

  messagesDate.forEach((date) => {
    if (date in copyRoom.messages) {
      copyRoom.messages = {
        ...copyRoom.messages,
        [date]: [...previousMessages[date], ...copyRoom.messages[date]],
      }
    } else {
      copyRoom.messages = {
        ...previousMessages,
        ...copyRoom.messages,
      }
    }
  })

  const orderedMessages = {}
  Object.keys(copyRoom.messages)
    .sort()
    .forEach((date) => {
      orderedMessages[date] = copyRoom.messages[date]
    })
  copyRoom.messages = orderedMessages
  return copyRoom
}

export const markMessageAsRead = (room, messagesUuid) => {
  const messages = room.messages && Object.entries(room.messages)
  const copyRoom = objectDeepCopy(room)
  const newMessagesArr = new Array()

  messages.forEach((message) => {
    const entrie = []
    const date = message[0]
    const newMessages = message[1].map((m) => {
      if (messagesUuid.includes(m.uuid)) {
        return {
          ...m,
          is_readed: true,
        }
      }
      return m
    })
    entrie.push(date, newMessages)
    newMessagesArr.push(entrie)
  })
  const newMessagesObject = Object.fromEntries(newMessagesArr)
  copyRoom.messages = newMessagesObject
  return copyRoom
}

export const getUnreadedMessagesUuid = (messages = {}) => {
  const messagesArray = Object.entries(messages)

  return messagesArray.reduce((prev, curr, i) => {
    const unreadedMessages = curr[1].filter(
      (message) => !message.is_readed && message.from_you,
    )
    const messagesUuid = unreadedMessages.map((message) => message.uuid)
    return [...prev, ...messagesUuid]
  }, [])
}

export const deleteChatRoom = (chats, chatId) => {
  const newChats = chats.filter((chat) => chat.uuid !== chatId)
  return newChats
}
