import React from "react"
import handleViewport from "react-in-viewport"
import styles from "./MessageCard.module.scss"
import PropTypes from "prop-types"
import MessageStatus from "../../components/DialogueCard/MessageStatus"
import classnames from "classnames"
import CopyToClipboard from "../CopyToClipboard"
import defaultAvatar from "../../images/defaultUserAvatar.png"
import {
  messageDecoder,
  getMessageBg,
} from "../../helpers/messages/messagesDecoder"

const MessageCard = ({
  name,
  time,
  text,
  myMessage,
  read,
  sent,
  forwardedRef,
  photo,
  messageObject,
}) => {
  //TODO: test text formatting function
  const formattedText = messageDecoder(messageObject)
  const isSystemMessage = messageObject.message_type === "system"
  const messageBg = getMessageBg(messageObject)

  return (
    <div
      className={classnames(styles.mainWrap, {
        [styles.myMessage]: myMessage,
      })}
    >
      <img
        src={photo || defaultAvatar}
        className={classnames(styles.userAvatar, {
          [styles.myMessage]: myMessage,
        })}
      />
      <div
        className={classnames(styles.messageWrap, {
          [styles.myMessageBg]: myMessage,
          [styles.partnerMessageBg]: !myMessage,
          [styles.systemMessage]: isSystemMessage,
          [styles.systemRedBg]: messageBg === "rejected",
          [styles.systemGreenBg]: messageBg === "created",
          [styles.systemTealBg]:
            messageBg === "approved" || messageBg === "unblocked",
          [styles.systemBlockBg]: messageBg === "blocked",
        })}
        ref={forwardedRef}
      >
        <div className={styles.container}>
          {!myMessage ? (
            <>
              <div
                className={classnames(styles.timeWrap, {
                  [styles.lineHeight]: isSystemMessage,
                })}
              >
                <p
                  className={classnames(styles.name, {
                    [styles.hiddenName]: isSystemMessage,
                  })}
                >
                  {name}
                </p>
                <span className={styles.time}>{time}</span>
              </div>
              <div className={styles.text}>
                <CopyToClipboard text={formattedText} />
              </div>
            </>
          ) : (
            <div className={styles.myMessageWrap}>
              <div className={styles.text}>
                <CopyToClipboard text={formattedText} />
              </div>
              <div className={styles.statusWrap}>
                <span
                  className={styles.time}
                  style={{ marginRight: `${(sent || read) && "10px"}` }}
                >
                  {time}
                </span>
                {(sent || read) && <MessageStatus sent={sent} read={read} />}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

MessageCard.propTypes = {
  name: PropTypes.string,
  time: PropTypes.string,
  text: PropTypes.string,
  myMessage: PropTypes.bool,
  read: PropTypes.bool,
  sent: PropTypes.bool,
}

MessageCard.defaultProps = {
  name: "",
  time: "",
  text: "",
  myMessage: false,
  read: false,
  sent: false,
}

export default handleViewport(MessageCard)
