export const options = [
  {
    id: "",
    value: "All",
  },
  {
    id: "not_applicable_gender",
    value: "Prefer not to say",
  },
  {
    id: "male",
    value: "Male",
  },
  {
    id: "female",
    value: "Female",
  },
  {
    id: "non_binary",
    value: "Non-binary",
  },
  {
    id: "genderqueer",
    value: "Genderqueer",
  },
  {
    id: "transgender",
    value: "Transgender",
  },
  {
    id: "genderfluid",
    value: "Genderfluid",
  },
  {
    id: "agender",
    value: "Agender",
  },
  {
    id: "two_spirit",
    value: "Two-Spirit",
  },
  {
    id: "other",
    value: "Other",
  }
]