import React from "react"
import Loading from "../../Loading/Loading"
import EmptyList from "../EmplyList/EmptyList"
import styles from "../ProspectsList/ProspectsList.module.scss"
import Pagination from "../../elements/Pagination"
import MyProspectCard from "../../cards/MyProspectCard/MyProspectCard"
import { useHistory } from "react-router-dom"
import { paths } from "../../../routes/paths"
import services from "../../../services"

const MyProspectsList = ({
  prospects,
  loading,
  totalPageCount,
  page,
  setCurrentPage,
  hasAppliedFilters,
  onDecline,
  onAccept,
}) => {
  const history = useHistory()

  const onChatOpen = (chatUuid, companyUuid, type) => {
    if (!chatUuid) {
      services.chatServices
        .createChatRoom({ type: type, uuid: companyUuid })
        .then((result) => history.push(paths.chats(result.data.uuid)))
        .catch((error) => console.log(error))
    } else {
      history.push(paths.chats(chatUuid))
    }
  }

  if (loading) return <Loading />
  if (!prospects) return <Loading />
  if (prospects && prospects.length === 0 && !hasAppliedFilters)
    return <EmptyList messageArray={["No applications yet"]} />
  if (prospects && prospects.length === 0 && hasAppliedFilters)
    return (
      <EmptyList
        messageArray={[
          "There are no results that match your search.",
          "Please try adjusting your filters.",
        ]}
      />
    )

  return (
    <>
      <div className={styles.wrapper}>
        {prospects &&
          prospects.map((prospect) => (
            <MyProspectCard
              onDecline={onDecline}
              key={prospect.uuid}
              data={prospect}
              onClick={() =>
                history.push(`/opportunity?id=${prospect.opportunity.uuid}`)
              }
              onChatOpen={onChatOpen}
              onAccept={onAccept}
            />
          ))}
      </div>
      <div className={styles.pagination}>
        {totalPageCount !== 0 && totalPageCount > 6 && (
          <Pagination
            currentPageNumber={page}
            totalPageCount={Math.ceil(totalPageCount / 6) || 1}
            onSelect={setCurrentPage}
          />
        )}
      </div>
    </>
  )
}

export default MyProspectsList
