import React from "react"
import { useParams } from "react-router-dom"

import styles from "./index.module.scss"
import Breadcrumbs from "../../components/elements/Breadcrumbs"
import ResourcePageContainer from "../../containers/ResourcesPageContainer"
import services from "../../services"
import Loading from "../../components/Loading/Loading"
import useBreadcrumbs from "./useBreadcrumbs"

const ResourcePage = () => {
  const [state, setState] = React.useState({
    isLoading: false,
  })

  const params = useParams()

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.resourceServices
      .getResourceByUuid(params.resourceID)
      .then((result) => {
        setState({
          data: result.data,
          isLoading: false,
        })
      })
  }, [params.resourceID])

  const breadcrumbs = useBreadcrumbs({
    companyInfo: state.data?.company,
    resourceName: state.data?.topic,
  })

  return (
    <div className={styles.pageWrap}>
      {state.isLoading ? (
        <Loading />
      ) : (
        <div className="container">
          {state.data && (
            <React.Fragment>
              <Breadcrumbs chain={breadcrumbs} />

              <ResourcePageContainer
                uuid={params.resourceID}
                data={state.data}
              />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  )
}

export default ResourcePage
