import { useState, useEffect } from "react"
import { options } from "./options"

const getOption = (selectedType) => {
  const selectedOption = options.find((option) => option.id === selectedType)
  return selectedOption || options[0]
}
const useOption = (selectedType) => {
  const [option, setOption] = useState(getOption(selectedType))
  useEffect(() => {
    setOption(getOption(selectedType))
  }, [selectedType])
  return [option, setOption]
}
export default useOption
