import React from "react"
import Popup from "../../../components/Popup/Popup"
import Button from "../../../components/Button/Button"

const CompanyEmployeeDeletePopup = ({ closeAction, onDelete }) => {
  return (
    <Popup
      dialog
      // title="Are you sure?"
      subtitle="Are you sure?"
      text={`Do you really want to delete this user? \n All data will be lost`}
      subtitleStyle={{
        padding: "24px 0 16px 0",
      }}
      textStyle={{
        fontSize: "14px",
        lineHeigth: "20px",
        whiteSpace: "pre-wrap",
        marginTop: "0",
      }}
      button={
        <Button buttonStyle="black" onClick={onDelete}>
          Delete
        </Button>
      }
      acceptButton={
        <Button
          buttonStyle="outlinedBlack"
          onClick={() => {
            closeAction()
          }}
        >
          Cancel
        </Button>
      }
      closeAction={() => {
        closeAction()
      }}
      isCrossIcon={true}
    />
  )
}

export default CompanyEmployeeDeletePopup
