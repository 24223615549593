import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import classNames from "classnames"
import styles from "./AutoCompleteField.module.scss"
import { ReactComponent as Error } from "../../images/icons/error.svg"

const AutoCompleteField = ({ ...props }) => {
  const classes = classNames(styles.fieldWrap, {
    [styles.fieldWrapDark]: props.fieldStyle === "dark",
    [styles.fieldWrapLight]: props.fieldStyle === "light",
    [styles.fieldWrapBlack]: props.fieldStyle === "black",
    [styles.withBeforeIcon]: props.beforeIcon,
    [styles.withAfterIcon]: props.afterIcon,
    [styles.errorInput]: props.error,
    [styles.withoutError]: props.withoutError,
  })
  const maxLength = props?.maxLength || 1000
  return (
    <Autocomplete
      {...props}
      renderInput={(
        params, //ref={params.InputProps.ref}>
      ) => (
        <div
          className={classNames(classes, props.className)}
          ref={params.InputProps.ref}
        >
          <label htmlFor={props.name}>
            {props.label ? (
              <span className={classNames("note14r", styles.fieldName)}>
                {props.label}
              </span>
            ) : null}
            <div className={styles.inputContainer}>
              <input
                {...params.inputProps}
                placeholder={props.placeholder}
                value={params.inputProps.value.slice(0, maxLength)}
                className={classNames("text16r", styles.field)}
              />
              {props.beforeIcon && (
                <div className={styles.beforeIcon}>{props.beforeIcon}</div>
              )}
              {props.afterIcon && (
                <div
                  className={classNames("iconWrap", styles.afterIcon)}
                  onClick={props.afterIcon.props.onClick}
                >
                  {props.afterIcon}
                </div>
              )}
            </div>
          </label>
          {props.error && props.errorMessage && (
            <div className={styles.errorMessageWrap}>
              <Error />
              <p className={styles.errorMessage}>{props.errorMessage}</p>
            </div>
          )}
        </div>
      )}
    />
  )
}

export default AutoCompleteField
