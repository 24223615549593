import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import PropTypes from "prop-types"
import styles from "./SignUpContainer.module.scss"
import SignUpContainer from "./SignUpContainer"
import SignUpRadio from "../../components/SignUpRadio/SignUpRadio"
import { ReactComponent as Image1 } from "../../images/signUp/step1-1.svg"
import { ReactComponent as Image2 } from "../../images/signUp/step1-2.svg"

function SignUpStep1Container({ user, onHandleNext, onHandleRole }) {
  const [role, setRole] = useState()

  useEffect(() => {
    if (user.role) {
      setRole(user.role)
    }
  }, [user.role])

  const handleChange = (name) => {
    setRole(name)
    onHandleRole(name)
  }

  return (
    <SignUpContainer
      title="Sign Up Process"
      subtitle="Step 1: Account Options"
      description={`Are you creating a professional or company account?\nPlease, select account type to continue`}
      onBack={() => {}}
      onNext={() => {
        onHandleNext(role)
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SignUpRadio
            name="individual"
            onClick={(name) => handleChange(name)}
            selected={role === "individual"}
            title="Connect as a Professional"
            description="Explore global opportunities, connect with companies, curate and share content."
            image={<Image1 />}
            className={styles.signUpCard}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SignUpRadio
            name="company"
            onClick={(name) => handleChange(name)}
            selected={role === "company"}
            title="Connect as a Company/Organization"
            description="Attract global talent, inspire & share content, provide opportunities for inclusive engagement."
            image={<Image2 />}
            className={styles.signUpCard}
          />
        </Grid>
      </Grid>
    </SignUpContainer>
  )
}

SignUpStep1Container.propTypes = {
  user: PropTypes.object,
  onHandleNext: PropTypes.func,
  onHandleRole: PropTypes.func,
}

export default SignUpStep1Container
