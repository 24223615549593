import React from "react"
import { Link } from "react-router-dom"
import { navLinks } from "./nav-links"

import styles from "./nav.module.scss"

const FooterNav = () => {
  return (
    <nav>
      <ul className={styles.menuWrap}>
        {navLinks.map((route, index) => (
          <li className={styles.menuItem}>
            <Link className="note12r" to={route.path}>
              {route.name}
            </Link>
          </li>
        ))}
          <li className={styles.menuItem}>
            <a className="note12r"
               href="https://theb2t.com/wp-content/uploads/2024/05/B2T_Subscription_Agreement.pdf"
               rel="noreferrer"
               target="_blank"
            >
              Subscription Agreement
            </a>
          </li>
      </ul>
    </nav>
  )
}

export default FooterNav
