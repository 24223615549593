import React from "react"
import styles from "./ChatsPage.module.scss"
import ChatsContainer from "../../containers/ChatsContainer/ChatsContainer"

const ChatsPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <ChatsContainer />
    </div>
  )
}

export default ChatsPage
