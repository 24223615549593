import React from "react"
import classnames from "classnames"
import Button from "../Button/Button"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"
import InfoIcon from '@material-ui/icons/Info';
import styles from "./CompanyUserInfo.module.scss"
import CopyToClipboard from "../CopyToClipboard"
import {getCompanyUserTypeLabel} from "../../helpers/helpData";

const CompanyUserShowInfo = ({
  handleEdit,
  anotherUser,
  dropdownData,
  onOpenChat,
  style,
  name,
  description,
  market,
  classification,
  isCertified,
  certification,
  companyType,
  levelType,
  isHaveChatButton
}) => {


  const certifiedIconStyle = {
    borderRadius: '50%',
    marginLeft: '10px',
    marginTop: '10px',
  };

  const certifiedTooltipText = isCertified ?
      "A certified Minority Business Enterprise (MBE) is a company-level certification that verifies that a business is majority-owned, managed, and controlled by members of a qualified minority group."
      :
      "An uncertified Minority Business Enterprise (MBE) is a company-level business that is uncertified as a majority-owned, managed, and controlled by members of a qualified minority group.";

  return (
    <>
      <div className={styles.userNameContainer}>
        <CustomTooltip name={name}>
          <h4 className={styles.userName}>{name}</h4>
        </CustomTooltip>
      </div>
      <div className={styles.userEducation}>
        <h6 className={style.title}>{dropdownData.describeMe.value}</h6>
        <h6 className={style.title}>
          {dropdownData.activity ? dropdownData.activity.value : ""}
        </h6>
        <h6 className={style.title}>
          {dropdownData.industry ? dropdownData.industry.value : ""}
        </h6>
        <h6 className={style.title}>
          {dropdownData.diverse?.value
              ? `Diverse: ${dropdownData.diverse.value}`
              : ""}
        </h6>
        {market && <h6 className={style.title}>{market}</h6>}

        {classification && <h6 className={style.title}>{classification}</h6>}
        {isCertified !== null &&
            <div className={style.userDescription}>
              <h6 className={style.title}>
                {isCertified ? "Certified" : "Uncertified"}
                <CustomTooltip text={certifiedTooltipText}>
                  <span className={styles.title}>
                    <InfoIcon style={certifiedIconStyle} />
                  </span>
                </CustomTooltip>
              </h6>
            </div>
        }
        {certification && (
            <div className={style.userDescription}>
              <h6 className={style.title}>Business certification(s)</h6>
              <p className={classnames("note14r", style.subTitle)}>
                {certification}
              </p>
            </div>
        )}
        <div className={style.userDescription}>
          <h6 className={style.title}>Company description:</h6>
          <p className={classnames("note14r", style.subTitle)}>
            {description ? (
                <CopyToClipboard text={description}/>
            ) : (
                <span>Add your description</span>
            )}
          </p>
        </div>
        {companyType && (
            <div className={style.userDescription}>
              <h6 className={style.title}>Company type:</h6>
              <p className={classnames("note14r", style.subTitle)}>
                <CopyToClipboard text={getCompanyUserTypeLabel(companyType)}/>
              </p>
            </div>
        )}

        {!anotherUser && (
            <Button
                width="100%"
                height="40px"
                onClick={handleEdit}
                className={styles.chatButton}
            >
              Edit
            </Button>
        )}
      </div>
      {anotherUser && isHaveChatButton && (
          <Button width="100%" onClick={onOpenChat} className={styles.chatButton}>
          Chat
        </Button>
      )}
    </>
  )
}

export default CompanyUserShowInfo
