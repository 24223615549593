import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./Input.module.scss"
import { ReactComponent as Error } from "../../images/icons/error.svg"

const Input = React.forwardRef(
  (
    {
      type,
      value,
      name,
      className,
      placeholder,
      label,
      onChange,
      autoFocus,
      beforeIcon,
      afterIcon,
      fieldStyle,
      error,
      onBlur,
      onFocus,
      onClick,
      errorMessage,
      disabled,
      autoComplete,
      withoutError,
      ...attrs
    },
    ref,
  ) => {
    const classes = classNames(styles.fieldWrap, {
      [styles.fieldWrapDark]: fieldStyle === "dark",
      [styles.fieldWrapLight]: fieldStyle === "light",
      [styles.fieldWrapBlack]: fieldStyle === "black",
      [styles.withBeforeIcon]: beforeIcon,
      [styles.withAfterIcon]: afterIcon,
      [styles.errorInput]: error,
      [styles.withoutError]: withoutError,
    })
    return (
      <div className={classNames(classes, className)}>
        <label htmlFor={name}>
          {label ? (
            <span className={classNames("note14r", styles.fieldName)}>
              {label}
            </span>
          ) : null}

          <div className={styles.inputContainer}>
            <input
              autoComplete={autoComplete}
              placeholder={placeholder}
              name={name}
              id={name}
              type={type}
              className={classNames(
                "text16r",
                styles.field,
                disabled && styles.disabled,
              )}
              onChange={onChange}
              value={value || ""}
              onBlur={onBlur}
              onFocus={onFocus}
              onClick={onClick}
              autoFocus={autoFocus}
              ref={ref}
              disabled={disabled}
              {...attrs}
            />
            {beforeIcon && (
              <div className={styles.beforeIcon}>{beforeIcon}</div>
            )}
            {afterIcon && (
              <div
                className={classNames("iconWrap", styles.afterIcon)}
                onClick={afterIcon.props.onClick}
              >
                {afterIcon}
              </div>
            )}
          </div>
        </label>
        {error && errorMessage && (
          <div className={styles.errorMessageWrap}>
            <Error />
            <p className={styles.errorMessage}>{errorMessage}</p>
          </div>
        )}
      </div>
    )
  },
)

Input.propTypes = {
  /** input type */
  type: PropTypes.string,
  /** input name */
  name: PropTypes.string.isRequired,
  /** input class name */
  className: PropTypes.string,
  /** input value */
  value: PropTypes.string,
  /** input onChange handler */
  onChange: PropTypes.func,
  /** handles click outside of input */
  onClick: PropTypes.func,
  /** input onBlur handler */
  onBlur: PropTypes.func,
  /** input onFocus handler */
  onFocus: PropTypes.func,
  /** input id */
  id: PropTypes.string,
  /** input placeholder */
  placeholder: PropTypes.string,
  /** enables input autofocus */
  autoFocus: PropTypes.bool,
  /** input field style */
  fieldStyle: PropTypes.string,
  /** input label */
  label: PropTypes.string,
  /** enables and sets input before icon */
  beforeIcon: PropTypes.object,
  /** enables and sets input after icon */
  afterIcon: PropTypes.object,
  /** enables input error component */
  error: PropTypes.bool,
  /** input error message text */
  errorMessage: PropTypes.string,
  /** input ref object */
  ref: PropTypes.object,
  /** autocomplete 'off' for turn off*/
  autoComplete: PropTypes.string,
}

Input.defaultProps = {
  type: "text",
  placeholder: "",
  autoFocus: false,
  fieldStyle: "dark",
}

export default Input
