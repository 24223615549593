import React from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import styles from "./PhotoGallery.module.scss"
import PhotoGalleryItem from "./PhotoGalleryItem/PhotoGalleryItem"
import { paths } from "../../routes/paths"

const PreviewGallery = ({
  setUploadPopup,
  setDeletePopup,
  setPhotoId,
  galleryData,
  setError,
  className,
  deletePopupRef,
  editable,
  userId,
  userType,
  onClick,
  onClose,
}) => {
  const emptyGallery = (
    <Grid item md={3} className={classNames(styles.galleryItem, styles.empty)}>
      <PhotoGalleryItem
        empty
        editable={editable}
        className={styles.emptyPhotoGalleryItem}
        handleAdd={setUploadPopup}
        onClose={onClose}
      />
    </Grid>
  )
  const galleryTitle = (
    <div className={styles.titleWrap}>
      <Link
        to={
          userId ? paths.photoGallery(userType, `/${userId}`) : "/photo-gallery"
        }
      >
        <h5>Photos</h5>
      </Link>

      {editable !== false && (
        <span
          className="goldLink"
          onClick={() => {
            setUploadPopup(true)
            if (galleryData?.length >= 12) {
              setError("Sorry, you can`t add more than 12 photos.")
            }
          }}
        >
          Add +
        </span>
      )}
    </div>
  )

  const gallery = galleryData?.slice(0, 4).map(({ id, path, name, uuid }) => (
    <Grid key={id} item md={3} className={styles.galleryItem}>
      <PhotoGalleryItem
        className={styles.galleryItemImage}
        deletePopupRef={deletePopupRef}
        key={id}
        path={path}
        name={name}
        editable={editable}
        onDelete={() => {
          setPhotoId(uuid)
          setDeletePopup(true)
        }}
        onClick={() => {
          onClick(uuid)
        }}
      />
    </Grid>
  ))
  return (
    <div className={classNames(styles.photoGalleryWrap, className)}>
      <>
        {galleryData?.length || editable !== false ? galleryTitle : null}
        <Grid container spacing={3} className={styles.photoGallery}>
          {galleryData?.length ? gallery : emptyGallery}
        </Grid>
      </>
    </div>
  )
}

export default PreviewGallery
