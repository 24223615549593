import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./Checkbox.module.scss"

const Checkbox = ({
  className,
  label,
  name,
  value,
  disabled,
  reverse,
  checked,
  ...attrs
}) => {
  return (
    <div
      className={classNames(
        styles.checkbox,
        { [styles.disabledCheckbox]: disabled, [styles.reverse]: reverse },
        className,
      )}
    >
      <label>
        <input
          type="checkbox"
          disabled={disabled}
          value={value}
          checked={checked}
          {...attrs}
        />
        <span className={styles.checkboxMaterial}>
          <span className={styles.check} />
        </span>
        <span className={styles.title}>{label}</span>
      </label>
    </div>
  )
}

Checkbox.propTypes = {
  /** Checkbox class name */
  className: PropTypes.string,
  /** Checkbox label */
  label: PropTypes.string.isRequired,
  /** Checkbox name */
  name: PropTypes.string.isRequired,
  /** Checkbox value */
  value: PropTypes.string.isRequired,
  /** Checkbox disabled status */
  disabled: PropTypes.bool.isRequired,
  /** Checkbox reverse type */
  reverse: PropTypes.bool,
}

export default Checkbox
