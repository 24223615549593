import React from "react"
import { useHistory } from "react-router-dom"

import styles from "./index.module.scss"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"
import ResetPasswordForm from "../../forms/ResetPasswordForm"
import services from "../../services"

const ResetPasswordPage = () => {
  const history = useHistory()

  const [isRequestSent, setRequestStatus] = React.useState(false)

  const onConfirm = (values) =>
    new Promise((res, rej) =>
      services.resetPasswordServices
        .passwordRetrieval(values)
        .then(({ data }) => {
          setRequestStatus(values.email)
          res(data)
        })
        .catch(({ response }) => rej(response.data)),
    )

  return (
    <div className={styles.pageWrap}>
      {isRequestSent ? (
        <XYCenterAlignedWindow title="Password retrieval" fullWidth>
          <div className={styles.formDescription}>
            <span className={styles.highlighted}>Email sent.</span>
            <br />
            An email with a link to restore access has been sent to{" "}
            <span className={styles.highlighted}>{isRequestSent}</span>
          </div>
        </XYCenterAlignedWindow>
      ) : (
        <XYCenterAlignedWindow title="Password retrieval" fullWidth>
          <div className={styles.formDescription}>
            Please enter the <span className={styles.highlighted}>email</span>{" "}
            that you used to sign in.
          </div>

          <ResetPasswordForm
            onConfirm={onConfirm}
            closeForm={() => history.goBack()}
          />
        </XYCenterAlignedWindow>
      )}
    </div>
  )
}

export default ResetPasswordPage
