import axios from "./axios"

export default {
  reportUser: async ({ title, description, reported_id, reported_type }) => {
    const body = {
      title,
      description,
      reported_id,
      reported_type,
    }
    try {
      const result = await axios.post(`/reports`, body)
      return result
    } catch (error) {
      throw error
    }
  },
  getReports: async (page) => {
    try {
      const result = await axios.get(`/admin/reports?page=${page}`)
      return result
    } catch (error) {
      throw error
    }
  },
  getReport: async (uuid) => {
    try {
      const result = await axios.get(`/admin/reports/${uuid}`)
      return result
    } catch (error) {
      throw error
    }
  },
  readReport: async ({ report_id, reporter_id }) => {
    const body = {
      report_id,
      reporter_id,
    }
    try {
      const result = await axios.put("/admin/report_readed", body)
      return result
    } catch (error) {
      throw error
    }
  },
  blockCompany: async ({ company_uuid, block }) => {
    const body = {
      company_id: company_uuid,
      block,
    }
    try {
      const result = await axios.put("/company_block", body)
      return result
    } catch (error) {
      throw error
    }
  },
  blockUser: async ({ user_uuid, block }) => {
    const body = {
      user_id: user_uuid,
      block,
    }
    try {
      const result = await axios.put("/user_block", body)
      return result
    } catch (error) {
      throw error
    }
  },
}
