import { isHaveBadWords } from "../../../../../helpers/badWords"

export default function validation(type, value, visible) {
  if (value.trim().length === 0 && visible === false) {
    return {
      validation: true,
      errorMessage: ``,
    }
  }

  if (value.trim().length === 0 && visible) {
    return {
      validation: false,
      errorMessage: `Please enter ${getFullNameByType(
        type,
      )} or turn off public visibility`,
    }
  }
  switch (type) {
    case "company_name": {
      if (value.length < 1 && visible) {
        return {
          validation: false,
          errorMessage:
              "Please enter a correct company name that consists of a minimum 1 character",
        }
      }

      if (value.length > 64 && visible)
        return {
          validation: false,
          errorMessage:
              "Please enter a correct company name that consists of no more than 64 characters",
        }
      if (isHaveBadWords(value)) {
        return {
          validation: false,
          errorMessage: "Please don't use bad language",
        }
      }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "address": {
      if (value.length < 1 && visible) {
        return {
          validation: false,
          errorMessage:
            "Please enter a correct address that consists of a minimum 1 character",
        }
      }

      if (value.length > 64 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct address that consists of no more than 64 characters",
        }
      if (isHaveBadWords(value)) {
        return {
          validation: false,
          errorMessage: "Please don't use bad language",
        }
      }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "phone": {
      if (value.length <= 6 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct phone number that consists of a minimum 7 character",
        }
      if (value.length > 13 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct phone number  that consists of no more than 13 characters",
        }
      if (!value.match(/^[0-9]*$/) && visible)
        return {
          validation: false,
          errorMessage: "Only numbers allowed",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "email": {
      if (value.length < 1 && visible) {
        return {
          validation: false,
          errorMessage: "Please enter a correct email",
        }
      }
      const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      if (value.length > 32)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct email address that consists of no more than 32 characters",
        }

      if (!value.match(mailformat))
        return {
          validation: false,
          errorMessage:
            "Please enter your email address in format yourname@example.com",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "instagram": {
      if (value.length > 64 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct link / username that consists of no more than 64 characters",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "twitter": {
      if (value.length > 64 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct link / username that consists of no more than 64 characters",
        }

      return {
        validation: true,
        errorMessage: "",
      }
    }

    case "facebook": {
      if (value.length > 64 && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct link / username that consists of no more than 64 characters",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "linkedin": {
      if (value.length > 64 && value.length && visible)
        return {
          validation: false,
          errorMessage:
            "Please enter a correct link / username that consists of no more than 64 characters",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    case "custom": {
      const customFormat = /\s?https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
      if (value.length > 64 || (!value.match(customFormat) && visible))
        return {
          validation: false,
          errorMessage:
            "Please enter a correct link that consists of no more than 64 characters",
        }
      return {
        validation: true,
        errorMessage: "",
      }
    }
    default: {
      return {
        validation: value.length > 5,
        errorMessage: validation ? "Enter valid" : "",
      }
    }
  }
}

function getFullNameByType(type) {
  switch (type) {
    case "address":
      return "address"
    case "phone":
      return "phone number"
    case "email":
      return "email address"
    case "instagram":
    case "twitter":
    case "facebook":
    case "linkedin":
      return "a link / username to your social media account "
    case "custom":
      return "the link"
    default:
      return ""
  }
}
