import {
  professionTypes,
  experienceLevels,
  roleTypes,
  activityTypes,
  genderTypes,
  raceTypes,
  companyTypes,
  levelTypes,
  companyRoleTypes,
  companyActivityTypes,
  companyIndustryTypes,
  companyDepartmentTypes,
  companyCertifiedType,
  companyDiverseType,
} from "./options"
import { marketOptions } from "../../../../components/CompanyUserInfo/options/marketOptions"

const getOptionName = (options, selectedType) => {
  const selectedOption = options.find((option) => option.type === selectedType)
  return selectedOption?.title
}
export const getProfessionTypeName = (type) => getOptionName(professionTypes, type)
export const getExperienceLevel = (type) => getOptionName(experienceLevels, type)
export const getRoleTypeName = (type) => getOptionName(roleTypes, type)
export const getActivityTypeName = (type) => getOptionName(activityTypes, type)
export const getGenderTypeName = (type) => getOptionName(genderTypes, type)
export const getRaceTypeName = (type) => getOptionName(raceTypes, type)
export const getCompanyTypeName = (type) => getOptionName(companyTypes, type)
export const getLevelTypeName = (type) => getOptionName(levelTypes, type)
export const getCompanyRoleTypeName = (type) =>
  getOptionName(companyRoleTypes, type)
export const getCompanyActivityTypeName = (type) =>
  getOptionName(companyActivityTypes, type)
export const getCompanyIndustryTypeName = (type) =>
  getOptionName(companyIndustryTypes, type)
export const getCompanyDepartmentTypeName = (type) =>
  getOptionName(companyDepartmentTypes, type)
export const getCompanyDiverseTypeName = (type) =>
  getOptionName(companyDiverseType, type)
export const getCompanyCertifiedTypeName = (type) =>
  getOptionName(companyCertifiedType, type)
export const getCompanyClassificationList = (classifications) => {
  let classificationList = []
  if (classifications && typeof classifications === "string") {
    classificationList = classifications.split(",")
  } else if (classifications?.length) {
    classificationList = classifications
  }
  return classificationList
}
export const getCompanyMarketName = (market) => {
  if (!market) return
  const { value: region = "", subValue = [] } =
    marketOptions.find(
      (option) => option.databaseName === market.substring(0, 2),
    ) ?? {}
  const { name: subRegion = "" } =
    subValue.find((option) => option.databaseName === market.substring(2, 4)) ??
    {}
  return subRegion ? `${subRegion}, ${region}` : region
}
