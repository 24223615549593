import React from "react"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder"
import GroupIcon from "@material-ui/icons/Group"
import EqualizerIcon from "@material-ui/icons/Equalizer"
import StarIcon from "@material-ui/icons/Star"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import EventSeatIcon from "@material-ui/icons/EventSeat"
import ListAltIcon from "@material-ui/icons/ListAlt"
import BlockIcon from "@material-ui/icons/Block"
import EmojiFlagsIcon from "@material-ui/icons/EmojiFlags"
import PersonIcon from "@material-ui/icons/Person"
import WorkIcon from "@material-ui/icons/Work"
import DateRangeIcon from "@material-ui/icons/DateRange"
import TimelapseIcon from "@material-ui/icons/Timelapse"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import ExtensionIcon from "@material-ui/icons/Extension"
import LayersIcon from "@material-ui/icons/Layers"
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter"
import LinearScaleIcon from "@material-ui/icons/LinearScale"
import FaceIcon from "@material-ui/icons/Face"
import ListIcon from "@material-ui/icons/List"
import LocalAtmIcon from "@material-ui/icons/LocalAtm"
import ReceiptIcon from "@material-ui/icons/Receipt"
import NotInterestedIcon from "@material-ui/icons/NotInterested"
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode"
import { ReactComponent as CertificateIcon } from "../../images/icons/certificate.svg"

const style = {
  color: "rgba(255, 255, 255, 0.7)",
}

export const getIconByName = (name) => {
  switch (name) {
    case "department":
      return <ChromeReaderModeIcon style={style} />
    case "compensation":
      return <AttachMoneyIcon style={style} />
    case "posted":
      return <QueryBuilderIcon style={style} />
    case "team":
      return <GroupIcon style={style} />
    case "experience_level":
      return <EqualizerIcon style={style} />
    case "required_experience":
      return <StarIcon style={style} />
    case "location":
      return <LocationOnIcon style={style} />
    case "work_environment":
      return <EventSeatIcon style={style} />
    case "how_to_apply":
      return <ListAltIcon style={style} />
    case "opportunity_expiration":
      return <BlockIcon style={style} />
    case "company_culture":
      return <EmojiFlagsIcon style={style} />
    case "mentor":
      return <PersonIcon style={style} />
    case "minimum_experience_requirements":
      return <WorkIcon style={style} />
    case "duration":
      return <DateRangeIcon style={style} />
    case "commitment":
      return <TimelapseIcon style={style} />
    case "contact":
      return <MailOutlineIcon style={style} />
    case "mentor_environment":
      return <EventSeatIcon style={style} />
    case "project":
      return <ExtensionIcon style={style} />
    case "industry":
      return <LayersIcon style={style} />
    case "business_type":
      return <BusinessCenterIcon style={style} />
    case "stage":
      return <LinearScaleIcon style={style} />
    case "funder_title":
      return <FaceIcon style={style} />
    case "role":
      return <PersonIcon style={style} />
    case "sponsorship_commitment":
      return <PersonIcon style={style} />
    case "funding_requirements":
      return <ListIcon style={style} />
    case "funding_amount":
      return <LocalAtmIcon style={style} />
    case "team_requirements":
      return <GroupIcon style={style} />
    case "sponsorship_purpose":
      return <ReceiptIcon style={style} />
    case "sponsorship_protocol":
      return <NotInterestedIcon style={style} />
    case "advisory_board_protocol":
      return <ListAltIcon style={style} />
    case "board_of_directors_protocol":
      return <ListAltIcon style={style} />
    case "required_certifications":
      return <CertificateIcon />

    default:
      return <ListIcon style={style} />
  }
}

export const getOpportunityParamsOrder = (type) => {
  switch (type) {
    case "mentoring":
      return [
        "department",
        "mentor",
        "posted",
        "experience_level",
        "duration",
        "commitment",
        "location",
        "contact",
        "mentor_environment",
        "how_to_apply",
        "opportunity_expiration",
      ]
    case "careers_executive":
      return [
        "department",
        "compensation",
        "posted",
        "team",
        "required_experience",
        "location",
        "contact",
        "work_environment",
        "opportunity_expiration",
        "how_to_apply",
        "company_culture",
      ]
    case "project":
      return [
        "department",
        "project",
        "posted",
        "industry",
        "compensation",
        "capabilities",
        "location",
        "contact",
        "work_environment",
        "certifications",
        "how_to_apply",
        "opportunity_expiration",
        "experience_level",
        "company_culture",
      ]
    case "funding":
      return [
        "department",
        "industry",
        "posted",
        "business_type",
        "stage",
        "location",
        "type_of_funding",
        "contact",
        "funder_title",
        "funding_requirements",
        "funding_amount",
        "how_to_apply",
        "opportunity_expiration",
        "company_culture",
      ]
    case "sponsorship":
      return [
        "posted",
        "sponsorship_type",
        "sponsorship_commitment",
        "location",
        "contact",
        "sponsorship_purpose",
        "sponsorship_protocol",
      ]
    case "diverse_company":
      return [
        "department",
        "compensation",
        "posted",
        "experience_level",
        "capabilities_statement",
        "board_commitment",
        "commitment",
        "location",
        "contact",
        "opportunity_expiration",
        "company_culture",
      ]
    case "board_of_directors":
      return [
        "compensation",
        "posted",
        "experience_level",
        "board_commitment",
        "location",
        "contact",
        "opportunity_expiration",
        "board_of_directors_protocol",
      ]
    case "careers_professional":
      return [
        "industry",
        "department",
        "compensation",
        "posted",
        "work_environment",
        "team_requirements",
        "required_certifications",
        "required_experience",
        "minimum_experience_requirements",
        "location",
        "contact",
        "opportunity_expiration",
        "how_to_apply",
        "company_culture",
      ]
    default:
      return []
  }
}

// ==============================================================
export const opportunitiesTypes = {
  careers_executive: "careers_executive",
  mentoring: "mentoring",
  project: "project",
  funding: "funding",
  sponsorship: "sponsorship",
  advisoryBoardProtocol: "diverse_company",
  boardOfDirectorsOpportunity: "board_of_directors",
  careers_professional: "careers_professional",
}

export const opportunitiesTypeCards = [
  {
    id: 1,
    imgUrl: require("../../images/opportunities/6.png"),
    title: "Diverse Company Opportunity",
    type: opportunitiesTypes.advisoryBoardProtocol,
  },
  {
    id: 2,
    imgUrl: require("../../images/opportunities/2.jpg"),
    title: "Capital/Funding Opportunity",
    type: opportunitiesTypes.funding,
  },
  {
    id: 3,
    imgUrl: require("../../images/opportunities/4.jpg"),
    title: "Projects Opportunity",
    type: opportunitiesTypes.project,
  },
  {
    id: 4,
    imgUrl: require("../../images/opportunities/5.jpg"),
    title: "Board Of Directors Opportunity",
    type: opportunitiesTypes.boardOfDirectorsOpportunity,
  },
  {
    id: 5,
    imgUrl: require("../../images/opportunities/1.jpg"),
    title: "Executive Opportunity",
    type: opportunitiesTypes.careers_executive,
  },
  {
    id: 6,
    imgUrl: require("../../images/opportunities/8.png"),
    title: "Professional Opportunity",
    type: opportunitiesTypes.careers_professional,
  },
  {
    id: 7,
    imgUrl: require("../../images/opportunities/3.jpg"),
    title: "Mentors Opportunity",
    type: opportunitiesTypes.mentoring,
  },
  {
    id: 8,
    imgUrl: require("../../images/opportunities/7.png"),
    title: "Sponsors Opportunity",
    type: opportunitiesTypes.sponsorship,
  },
]
// ==============================================================

export const opportunitiesTypesArray = [
  "All",
  "Advisory Board",
  "Board of Directors",
  "Funding",
  "Job",
  "Mentoring",
  "Projects",
  "Sponsorship",
  "Supplier",
]

export const UsersRoleOptions = [
  {
    value: "All Roles",
    dbName: "",
    id: "1",
  },
  {
    value: "College Students",
    dbName: "college_students",
    id: "2",
  },
  {
    value: "Professionals",
    dbName: "professionals",
    id: "3",
  },
  {
    value: "Business Owners",
    dbName: "business_owners",
    id: "4",
  },
  {
    value: "Board Members",
    dbName: "board_members",
    id: "5",
  },
  {
    value: "Corporations",
    dbName: "corporations",
    id: "6",
  },
  {
    value: "Financial Services",
    dbName: "financial_organizations",
    id: "8",
  },
]

export const OpportunityOptions = [
  {
    value: "All",
    dbName: "",
    id: "1",
  },
  {
    value: "Diverse Company",
    dbName: "diverse_company",
    id: "4",
  },
  {
    value: "Board of Directors",
    dbName: "board_of_directors",
    id: "3",
  },
  {
    value: "Funding",
    dbName: "funding",
    id: "7",
  },
  {
    value: "Careers-Executive",
    dbName: "careers_executive",
    id: "2",
  },

  {
    value: "Mentoring",
    dbName: "mentoring",
    id: "5",
  },

  {
    value: "Projects",
    dbName: "project",
    id: "8",
  },
  {
    value: "Sponsorship",
    dbName: "sponsorship",
    id: "6",
  },
  {
    value: "Careers-Professional",
    dbName: "careers_professional",
    id: "7",
  },
]

export const getOpportunityTitle = (opportunity) => {
  if (!opportunity) return undefined

  if (!opportunity.opportunity_type)
    return OpportunityOptions.find((filter) => filter.value === opportunity)
      .dbName

  if (opportunity.opportunity_type)
    return OpportunityOptions.find(
      (title) => title.dbName === opportunity.opportunity_type.toLowerCase(),
    ).value
}

export const opportunityNameList = [
  {
    title: "Diverse Company",
    type: "diverse_company",
  },
  {
    title: "Capital/Funding",
    type: "funding",
  },
  {
    title: "Projects",
    type: "project",
  },
  {
    title: "Board of Directors",
    type: "board_of_directors",
  },
  {
    title: "Executive",
    type: "careers_executive",
  },
  {
    title: "Professional",
    type: "careers_professional",
  },
  {
    title: "Mentors",
    type: "mentoring",
  },
  {
    title: "Sponsors",
    type: "sponsorship",
  },
]
