import { useReducer, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { reducer } from "./reducer"
import {
  setSearchParams,
  setRequestResult,
  setSideQuestions,
} from "./actionCreators"
import questionServices from "../../../services/questionServices"
import { paths } from "../../../routes/paths"

const useSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const locationSearchGroup = queryString.parse(location.search)
  const [state, dispatch] = useReducer(reducer, {
    searchParamGroup: {
      query: "",
      ...locationSearchGroup,
    },
    requestResultGroup: {
      itemList: [],
      itemCount: 0,
      pageCount: 0,
      isFetching: true,
    },
    sideQuestionGroup: {
      type: locationSearchGroup.query ? "popular" : "recent",
      itemList: [],
    },
  })
  useEffect(() => {
    const locationSearchGroup = queryString.parse(location.search)
    dispatch(setSearchParams(locationSearchGroup))
    questionServices.getQuestionPage(locationSearchGroup).then(({ data }) => {
      dispatch(setRequestResult(data, locationSearchGroup))
      window.scrollTo(0, 0)
    })
    if (locationSearchGroup.query) {
      questionServices.getQuestionPage({}).then(({ data }) => {
        dispatch(setSideQuestions(true, data.questions))
      })
    } else {
      questionServices.getRecentQuestions().then(({ data }) => {
        dispatch(setSideQuestions(false, data.questions))
      })
    }
  }, [location.search])
  useEffect(() => {
    history.replace(paths.questionSearch(state.searchParamGroup))
  }, [state.searchParamGroup])
  return [state, dispatch]
}
export default useSearch
