import userServices from "./userServices"
import departmentsServices from "./departmentsServices"
import companyServices from "./copmanyServices"
import opportunityServices from "./opportunityServices"
import prospectsServices from "./prospectsServices"
import notificationsServices from "./notificationsServices"
import suggestionServices from "./suggestionServices"
import chatServices from "./chatServices"
import resourceServices from "./resourcesServices"
import communityServices from "./community"
import articleServices from "./articleServices"
import commentsServices from "./commentsServices"
import accountServices from "./accountServices"
import employeeServices from "./employeeServices"
import resetPasswordServices from "./resetPasswordServices"
import favoriteServices from "./favoritesServices"
import reportServices from "./reportServices"
import freelancerServices from "./freelancerServices"

export default {
  userServices,
  suggestionServices,
  departmentsServices,
  companyServices,
  opportunityServices,
  prospectsServices,
  notificationsServices,
  chatServices,
  resourceServices,
  communityServices,
  articleServices,
  commentsServices,
  accountServices,
  employeeServices,
  resetPasswordServices,
  favoriteServices,
  reportServices,
  freelancerServices,
}
