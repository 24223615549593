import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import QuestionsList from "../QuestionsList"
import Loading from "../Loading/Loading"

const QuestionsSidebar = ({ data, isLoading, title }) => {
  return (
    <div>
      <h3 className={styles.blockTitle}>{title}</h3>

      <div className={styles.listWrap}>
        {isLoading ? <Loading /> : <QuestionsList data={data} inSidebar />}
      </div>
    </div>
  )
}

QuestionsSidebar.defaultProps = {
  data: [],
  isLoading: false,
  title: "Topics",
}

QuestionsSidebar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
}

export default QuestionsSidebar
