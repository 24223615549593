import { UPDATE_NOTIFICATIONS } from "../helpers/constants"
import { load } from "../helpers/localStorage"

const savedUser = load("currentUser")

let initialState = {
  messages: 0,
  systemNotificationsCount: 0,
  systemNotifications: [],
  notificationsTotalCount: 0,
}

if (savedUser) {
  //   initialState = savedUser
}

const user = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_NOTIFICATIONS: {
      return {
        ...state,
        messages: payload.messages,
        systemNotificationsCount: payload.system_notifications,
        systemNotifications: payload.notifications,
        notificationsTotalCount: payload.totalCount,
      }
    }

    default: {
      return state
    }
  }
}

export default user
