import React, { useState } from "react"
import classNames from "classnames"
import { withFormik } from "formik"
import Input from "../../components/Input/Input"
import { object, string } from "yup"
import styles from "./ReportForm.module.scss"
import Button from "../../components/Button/Button"
import TextArea from "../../components/TextArea/TextArea"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"

const ReportForm = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleSubmit,
  closeAction,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false)

  const handleCancelButtonClick = () => {
    if (Object.values(values).filter((value) => value.length).length) {
      setIsPopupOpened(true)
    } else {
      closeAction()
    }
  }

  const handleCancelAll = () => {
    closeAction()
  }

  return (
    <form className={styles.formWrapper} onSubmit={handleSubmit}>
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          cancelMessage={"Are you sure you want to cancel your report?"}
          handleCancel={handleCancelAll}
        />
      )}
      <div className={styles.fieldsWrapper}>
        <Input
          value={values.title}
          label="Title*"
          name="title"
          placeholder="Please enter a short title"
          fieldStyle="light"
          onChange={(e) =>
            handleChange({
              target: {
                name: e.target.name,
                value: e.target.value.replace(/ +(?= )/g, ""),
              },
            })
          }
          onBlur={handleBlur}
          error={errors.title && touched.title}
          errorMessage={errors.title && touched.title && errors.title}
        />

        <TextArea
          border
          fieldStyle={"light"}
          value={values.description}
          label={"Description*"}
          style={styles.textArea}
          name={"description"}
          placeholder={"Why do you consider this page as inappropriate?"}
          onChange={(e) =>
            handleChange({
              target: {
                name: e.target.name,
                value: e.target.value.replace(/ +(?= )/g, ""),
              },
            })
          }
          onBlur={handleBlur}
          error={errors.description && touched.description}
          errorMessage={
            errors.description && touched.description && errors.description
          }
        />
      </div>

      <div className={classNames(styles.btsWrapper, {})}>
        <Button buttonStyle="outlinedBlack" onClick={handleCancelButtonClick}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Send</Button>
      </div>
    </form>
  )
}

export default withFormik({
  mapPropsToValues() {
    return {
      title: "",
      description: "",
    }
  },
  validationSchema: () => {
    return object().shape({
      title: string()
        .min(
          10,
          "Please enter a correct title that consists of a minimum of 10 characters",
        )
        .max(
          30,
          "Please enter a correct title that consists of no more than 30 characters",
        )
        .required("Please enter a title"),
      description: string()
        .min(
          30,
          "Please enter a correct description that consists of a minimum of 30 characters",
        )
        .max(
          500,
          "Please tell a story that consists of no more than 500 characters",
        )
        .required("Please enter a description"),
    })
  },
  handleSubmit: (
    values,
    {
      setSubmitting,
      setFieldError,
      props: { onSubmit, closeAction, setVisiblePopup },
    },
  ) => {
    onSubmit(values)
      .then(() => {
        closeAction()
      })
      .catch((e) => setFieldError("email", e.response.data.error))
    setSubmitting(false)
  },
})(ReportForm)
