import React, { forwardRef } from "react"
import { TextareaAutosize } from "@material-ui/core"
import P from "prop-types"
import classNames from "classnames"
import styles from "./TextArea.module.scss"
import TextLimit from "../TextLimit/TextLimit"
import { ReactComponent as Error } from "../../images/icons/error.svg"

const TextArea = (
  {
    value,
    error,
    errorMessage,
    onFocus,
    onChange,
    onBlur,
    currentLength = 0,
    maxLength,
    placeholder,
    onKeyDown,
    name,
    horizontal,
    vertical,
    style,
    label,
    border,
    autoFocus,
    handleKeyDown,
    fieldStyle,
  },
  ref,
) => {
  return (
    <div className={style}>
      {label && (
        <span
          className={classNames(
            "note14r",
            styles.fieldName,
            fieldStyle === "light" && styles.fieldNameLight,
          )}
        >
          {label}
        </span>
      )}
      <TextareaAutosize
        name={name}
        autoFocus={autoFocus}
        className={classNames(
          {
            [styles.lightField]: fieldStyle === "light",
          },
          styles.textArea,
          error && styles.textAreaError,
          horizontal && styles.horizontal,
          vertical && styles.vertical,
          style && style,
          border && styles.textAreaBorder,
          border && fieldStyle === "light" && styles.textAreaBorderLight,
          fieldStyle === "light" && styles.textAreaLight,
        )}
        value={value}
        rowsMax={1}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        aria-label="empty textarea"
        placeholder={placeholder}
        ref={ref}
      />
      <div
        className={classNames(
          styles.infoSection,
          errorMessage && styles.infoSectionError,
        )}
      >
        {error && errorMessage && (
          <div className={styles.errorMessageWrap}>
            <Error />
            <p
              className={classNames(
                styles.errorMessage,
                fieldStyle === "light" && styles.errorMessageLight,
              )}
            >
              {errorMessage}
            </p>
          </div>
        )}
        {maxLength && (
          <TextLimit currentLength={currentLength} maxLength={maxLength} />
        )}
      </div>
    </div>
  )
}

TextArea.propTypes = {
  value: P.string,
  error: P.bool,
  errorMessage: P.string,
  onFocus: P.func,
  onChange: P.func,
  onBlur: P.func,
  currentLength: P.number,
  maxLength: P.number,
  placeholder: P.string,
  name: P.string,
  /** horizontal = true - disable vertical resize   */
  horizontal: P.bool,
  /** vertical = true - disable horizontal resize   */
  vertical: P.bool,
  label: P.string,
}

export default forwardRef(TextArea)
