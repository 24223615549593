import React from "react"
import classNames from "classnames"
import Label, { LabelProps } from "../../elements/Label"
import styles from "./index.module.scss"

export type ResourceCardProps = {
  /** External styles */
  classes?: any
  /** Type for `Label` component */
  type: LabelProps["type"]
  /** Url for picture */
  photo: {
    path: string
  }
  video: {
    id: string
    length: string
    name: string
    path: string
    thumbnail: string
  }
  /** Card's title */
  title: string
  /** Location */
  location: string
  /** Date */
  date: string
  /** Time */
  time: string
  /** Action on click */
  onClick: (e: React.MouseEvent) => void
}
const ResourceCard = ({
  classes,
  type,
  photo,
  video,
  title,
  location,
  date,
  time = "",
  onClick,
}: ResourceCardProps) => {
  const day = new Date(date).toLocaleString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  })
  const timeRes = time.match(/(\d+):(\d+) (AM|PM)/)
  let formattedTime
  if (timeRes) {
    formattedTime = `${timeRes[1]}${timeRes[3] === "AM" ? "a.m." : "p.m."}`
  } else {
    formattedTime = ""
  }
  return (
    <div className={classNames(styles.card, classes)} onClick={onClick}>
      <Label classes={styles.label} subject="resource" type={type} />
      <div
        className={styles.photo}
        style={{
          backgroundImage: `url(${video?.thumbnail || photo?.path || photo})`,
        }}
      />
      <p className={styles.title}>{title}</p>
      <p className={styles.location}>{location}</p>
      <p className={styles.date}>{`${day} · ${formattedTime}`}</p>
    </div>
  )
}
export default ResourceCard
