export const topics = [
  {
    title: "All",
    type: "",
    tooltipText: "",
  },
  {
    title: "Trends",
    type: "trends",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
  {
    title: "Skills",
    type: "skills",
    tooltipText: "",
    tooltipPlacement: "bottom",
  },
]
