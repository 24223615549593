import { opportunitiesTypes } from "../../components/Opportunity/heplers"
import CareersExecutiveForm from "./CareersExecutiveForm"
import CareersProfessionalForm from "./CareersProfessionalForm"
import MentoringForm from "./MentoringForm"
import ProjectForm from "./ProjectForm"
import FundingForm from "./FundingForm"
import SponsorshipForm from "./SponsorshipForm"
import BoardOfDirectorsForm from "./BoardOfDirectorsForm"
import React from "react"
import SupplierForm from "./SupplierForm"

export function OpportunitySwitch(type, props) {
  switch (type) {
    case opportunitiesTypes.careers_executive:
      return <CareersExecutiveForm {...props} />
    case opportunitiesTypes.mentoring:
      return <MentoringForm {...props} />
    case opportunitiesTypes.project:
      return <ProjectForm {...props} />
    case opportunitiesTypes.funding:
      return <FundingForm {...props} />
    case opportunitiesTypes.sponsorship:
      return <SponsorshipForm {...props} />
    case opportunitiesTypes.advisoryBoardProtocol:
      return <SupplierForm {...props} />
    case opportunitiesTypes.boardOfDirectorsOpportunity:
      return <BoardOfDirectorsForm {...props} />
    case opportunitiesTypes.careers_professional:
      return <CareersProfessionalForm {...props} />
    default:
      return null
  }
}

export const scrollOnError = (errors) => {
  try {
    setTimeout(() => {
      let errored
      if (Object.keys(errors).length) {
        errored = document.querySelectorAll("*")
        errored = [].slice.call(errored).filter(function (item) {
          if (item.classList)
            return (
              [].slice.call(item.classList).filter(function (item) {
                return item.indexOf("error") !== -1
              }).length !== 0
            )
          return false
        })
        errored[0] && errored[0].scrollIntoView(false)
      }
    })
  } catch (e) {
    console.log(e)
  }
}
