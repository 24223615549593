import React, { useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import { useHistory } from "react-router-dom"

import styles from "./CompanyOpportunities.module.scss"
import CompanyInfoItem from "../CompanyInfoItem/CompanyInfoItem"
import OpportunityCard from "../cards/OpportunityCard"
import CompanyAddonTypePopup from "../CompanyAddonTypePopup"
import { opportunitiesTypeCards } from "../Opportunity/heplers"
import avatar from "../../images/defaultUserAvatar.png"

const CompanyOpportunities = ({ data, width, anotherUser, uuid, isPrivate }) => {
  const [visiblePopup, setVisiblePopup] = useState(false)
  const history = useHistory()

  const handleClick = (e, opportunitie) => {
    if (e.target.dataset.type !== "button") {
      history.push(`/opportunity?id=${opportunitie["uuid"]}`)
    }
  }

  const tooltipText = data?.departments?.length
    ? null
    : "Please add at least one department before adding an opportunity."

  const dataOpportunities = isPrivate ? data?.private_opportunities : data?.opportunities

  const placeholder =
    anotherUser && !dataOpportunities?.length
      ? "No opportunity yet"
      : dataOpportunities?.length > 0
      ? null
      : "You didn't add any opportunities yet"

  const addButtonDisabledStatus = !data?.departments?.length

  let title = "My Opportunities"
  if (anotherUser) {
    title = `Company/Organization${isPrivate ? ' Private' : ''} Opportunities`
  } else if (isPrivate) {
    title = "My Private Opportunities"
  }
  let headBtnText = "Add Opportunity"
  if (width < 451) {
    headBtnText = "Add"
  } else if (isPrivate) {
    headBtnText = "Add Private Opportunity"
  }

  let showAllText = "Show all opportunities"
  if (width < 451) {
    showAllText = "Show all"
  } else if (isPrivate) {
    showAllText = "Show all private opportunities"
  }

  const popupTitle = isPrivate ? "Add Private Opportunity" : "Add Opportunity"

  return (
    <CompanyInfoItem
      tooltipText={tooltipText}
      headTitle={title}
      disabled={addButtonDisabledStatus}
      headBtnText={headBtnText}
      bodyPlaceholderText={placeholder}
      handleButtonClick={() => {
        setVisiblePopup(true)
      }}
      width={width}
      anotherUser={anotherUser}
    >
      <div className={styles.oppotunitiesWrapper}>
        {visiblePopup && (
          <CompanyAddonTypePopup
            width={953}
            closeAction={setVisiblePopup}
            title={popupTitle}
            handleNextClick={(a) =>
              history.push(`/opportunity-edit?variant=${a.type}&private=${isPrivate}`)
            }
            data={opportunitiesTypeCards}
          />
        )}
        {dataOpportunities?.length > 0 && (
          <>
            <div className={styles.cardsWrapper}>
              {dataOpportunities.map((opportunitie) => (
                <OpportunityCard
                  key={opportunitie["created_at"]}
                  uuid={opportunitie.uuid}
                  type={opportunitie["opportunity_type"]}
                  avatar={data.photo || avatar}
                  description={opportunitie["description"]}
                  location={{
                    city: opportunitie["location"],
                    head: data.name,
                  }}
                  caption={opportunitie["name"]}
                  timestamp={new Date(opportunitie["created_at"])}
                  onClick={(e) => handleClick(e, opportunitie)}
                  isFavorite={opportunitie["favorite"]}
                />
              ))}
            </div>

            {dataOpportunities.length >= 2 && (
              <div className={styles.showMoreBtn}>
                <Link
                  to={`/opportunities/${uuid}?private=${isPrivate}`}
                  className={classNames("goldLink")}
                >
                  <p>{showAllText}</p>
                </Link>
              </div>
            )}
          </>
        )}
      </div>
    </CompanyInfoItem>
  )
}

export default CompanyOpportunities
