import React from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import styles from "./index.module.scss"
import CompanyCard from "../../../components/cards/CompanyCard"
import { paths } from "../../../routes/paths"
import { INDIVIDUAL, COMPANY_SEEKER } from "../../../helpers/userRoles"

const Companies = ({ data, removeFromFavorites }) => {
  const userInfo = useSelector((state) => state.user)
  const history = useHistory()
  const isHaveChatButton = (userInfo.role !== INDIVIDUAL) && (userInfo.company.type !== COMPANY_SEEKER)


  return (
    <div className={styles.listWrap}>
      {data.map((item) => (
        <div key={item.uuid} className={styles.cardWrapper}>
          <CompanyCard
            isHaveChatButton={isHaveChatButton}
            companyUuid={item.uuid}
            chatUuid={item.chat}
            companyAvatar={item.photo}
            companyName={item.name}
            companyRole={item.describe_company}
            companyActivity={item.activity}
            description={item.description}
            isOwned={false}
            onClick={() =>
              history.push(paths.selectedCopmanyProfile(item.uuid, "search"))
            }
            onChatOpen={() => console.log("click open chat")}
            removeFromFavorites={removeFromFavorites}
            isFavorite={item.favorite}
            industry={item.industry}
          />
        </div>
      ))}
    </div>
  )
}

export default Companies
