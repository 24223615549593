export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS"
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT"
export const ADD_SCROLL_RESULT = "ADD_SCROLL_RESULT"
export const SET_SIDE_ARTICLES = "SET_SIDE_ARTICLES"
export const SET_FORM_FILTERS = "SET_FORM_FILTERS"
export const SET_TAB_FILTER = "SET_TAB_FILTER"
export const RESET_FORM_FILTERS = "RESET_FORM_FILTERS"
export const REMOVE_ARTICLE_TYPE = "REMOVE_ARTICLE_TYPE"
export const REMOVE_FROM_DATE = "REMOVE_FROM_DATE"
export const REMOVE_TO_DATE = "REMOVE_TO_DATE"

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY: {
      const { query } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, query },
        requestResultGroup: {isFetching: true}
      }
    }
    case SET_TAB_FILTER: {
      const { articleType } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          article_type: articleType ? articleType : "",
        },
      }
    }
    case SET_FORM_FILTERS: {
      const {
        filters: { articleType, fromDate, toDate },
      } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          article_type: articleType ? articleType : "",
          from: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          to: toDate ? toDate.format("YYYY-MM-DD") : null,
        },
      }
    }
    case RESET_FORM_FILTERS: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          article_type: "",
          from: null,
          to: null,
        },
      }
    }
    case REMOVE_ARTICLE_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          article_type: "",
        },
      }
    }
    case REMOVE_FROM_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, from: null },
      }
    }
    case REMOVE_TO_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, to: null },
      }
    }
    case SET_SEARCH_PARAMS: {
      const { locationSearchGroup } = payload
      return {
        ...state,
        searchParamGroup: {
          // ...state.searchParamGroup,
          ...locationSearchGroup,
        },
      }
    }
    case SET_REQUEST_RESULT: {
      const { data, locationSearchGroup } = payload
      const dataTotalCount = data.total_count || 0
      return {
        ...state,
        requestResultGroup: {
          isFetching: false,
          itemList: data.institute_articles || [],
          itemCount: dataTotalCount,
          pageCount: Math.ceil(dataTotalCount / 15),
          ...locationSearchGroup,
        },
      }
    }
    case ADD_SCROLL_RESULT: {
      const { data } = payload
      return {
        ...state,
        requestResultGroup: {
          ...state.requestResultGroup,
          itemList: [
            ...state.requestResultGroup.itemList,
            ...data?.institute_articles,
          ],
        },
      }
    }
    case SET_SIDE_ARTICLES: {
      const { isQuery, institute_articles } = payload
      return {
        ...state,
        sideArticleGroup: {
          type: isQuery ? "popular" : "recent",
          itemList: institute_articles?.slice(0, 4) || [],
        },
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
