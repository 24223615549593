import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { topics } from "./topics"
import styles from "./index.module.scss"

const useStyles = makeStyles(() => ({
  arrow: {
    color: "rgba(0, 0, 0, 0.9)",
  },
  tooltip: {
    maxWidth: 200,
    textAlign: "center",
    fontWeight: "normal",
    fontSize: 10,
    lineHeight: 1.6,
    backgroundColor: "rgba(0, 0, 0, 0.9)",
  },
}))
const InstituteTypeFilter = ({ classes, requestResultGroup, onSelectType }) => {
  const tooltipClasses = useStyles()
  const [activeType, setActiveType] = useState(topics[0].type)
  useEffect(() => {
    if (requestResultGroup) {
      setActiveType(requestResultGroup.article_type || "")
    }
  }, [requestResultGroup])
  return (
    <div className={classNames(styles.filter, classes)}>
      {topics.map((topic) => (
        <Tooltip
          classes={tooltipClasses}
          title={topic.tooltipText}
          placement={topic.tooltipPlacement}
          arrow
        >
          <span
            className={classNames({
              [styles.activeType]: activeType === topic.type,
            })}
            onClick={() => {
              onSelectType(topic.type)
              setActiveType(topic.type)
            }}
          >
            {topic.title}
          </span>
        </Tooltip>
      ))}
    </div>
  )
}
InstituteTypeFilter.propTypes = {
  /** Callback gets active topic type as argument */
  onSelectType: PropTypes.func,
}
export default InstituteTypeFilter
