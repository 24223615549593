import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./VideoCard.module.scss"
import { ReactComponent as AddVideo } from "../../../images/icons/add-video.svg"
import { ReactComponent as DeleteIcon } from "../../../images/icons/deleteImage.svg"
import VideoPlayer from "../../VideoPlayer/VideoPlayer"
import { ReactComponent as Error } from "../../../images/icons/error.svg"

const VideoCard = ({
  empty,
  video,
  onOpen,
  onAdd,
  deleteVideo,
  className,
  small,
  anotherUser,
  error,
  errorMessage,
  supportedFormats,
  maxSize,
  addMessage,
  Icon,
  maxSizePhoto,
  supportedFormatsPhoto,
}) => {
  const classes = classNames(
    {
      [styles.emptyVideo]: empty,
      [styles.cardWrapper]: !empty,
      [styles.small]: small,
    },
    className,
  )
  return (
    <div className={classes}>
      {empty && !video ? (
        <>
          <div onClick={onAdd} className={error && styles.error}>
            {(Icon && <Icon className={styles.icon} />) || (
              <AddVideo className={styles.icon} />
            )}

            <span className={classNames("note14r", styles.title)}>
              {addMessage}
            </span>
          </div>
          {error && errorMessage && (
            <span className={styles.errorMessageWrap}>
              <Error />
              <p className={styles.errorMessage}>{errorMessage}</p>
            </span>
          )}
          {maxSize && (
            <p className={styles.uploadInfoText}>
              Maximum upload video file size: {maxSize}
            </p>
          )}
          {supportedFormats && (
            <p className={styles.uploadInfoText}>{supportedFormats}</p>
          )}
          {maxSizePhoto && (
            <p className={styles.uploadInfoText}>
              Maximum upload photo file size: {maxSizePhoto}
            </p>
          )}
          {supportedFormatsPhoto && (
            <p className={styles.uploadInfoText}>{supportedFormatsPhoto}</p>
          )}
        </>
      ) : (
        <div className={styles.videoPlayerWrapper}>
          <span
            className={styles.substrate}
            onClick={() => {
              if (!onOpen) {
                return
              }
              onOpen(video)
            }}
          />
          {!anotherUser && (
            <span
              className={styles.deleteIcon}
              onClick={() => {
                deleteVideo(video.uuid)
              }}
            >
              <DeleteIcon />
            </span>
          )}
          {video.thumbnail ? (
            <div
              className={classNames(
                styles.videoThumbnail,
                error && styles.error,
              )}
              style={{ backgroundImage: `url(${video.thumbnail})` }}
            />
          ) : (
            <div className={error && styles.error}>
              <VideoPlayer
                source={video.path}
                preload="metadata"
                fluid={false}
                width="100%"
                className={styles.videoPlayer}
                playButton={false}
              />
            </div>
          )}

          {error && errorMessage && (
            <span className={styles.errorMessageWrap}>
              <Error />
              <p className={styles.errorMessage}>{errorMessage}</p>
            </span>
          )}
          {video?.length && (
            <p className={styles.videoDuration}>{video?.length}</p>
          )}
          <p className={styles.videoName}>
            <span>{video.name}</span>
          </p>
        </div>
      )}
    </div>
  )
}

VideoCard.propTypes = {
  empty: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  video: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string,
    length: PropTypes.string,
    uuid: PropTypes.string,
  }),
  onOpen: PropTypes.func,
  /**
   * Function for *empty* `videoCard`.
   */
  onAdd: PropTypes.func,
  deleteVideo: PropTypes.func,
  className: PropTypes.string,
  small: PropTypes.bool,
  supportedFormats: PropTypes.string,
  maxSize: PropTypes.string,
}

VideoCard.defaultProps = {
  addMessage: "Add video",
}

export default VideoCard
