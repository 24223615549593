import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./PhotoGalleryItem.module.scss"
import { ReactComponent as Delete } from "../../../images/icons/deleteImage.svg"
import { ReactComponent as AddPhoto } from "../../../images/icons/add-photo.svg"

const PhotoGalleryItem = ({
  path,
  name,
  onDelete,
  className,
  empty,
  handleAdd,
  deletePopupRef,
  editable,
  onClick,
  onClose,
}) => {
  if (empty && editable === false) {
    return null
  }

  if (empty) {
    return (
      <div
        className={classNames(styles.photoGalleryItem, styles.empty)}
        onClick={handleAdd}
      >
        <AddPhoto className={styles.icon} />
        <span className={classNames(styles.title, "note14r")}>Add photo</span>
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.photoGalleryItem, className)}
      style={{ backgroundImage: `url(${path})` }}
      onClick={onClick}
    >
      {editable !== false && (
        <span
          className={styles.deletePhoto}
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <Delete />
        </span>
      )}
      {onClose && <span className={styles.handler} onClick={onClose} />}
    </div>
  )
}

PhotoGalleryItem.propTypes = {
  path: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  className: PropTypes.any,
  empty: PropTypes.bool,
  handleAdd: PropTypes.func,
}

PhotoGalleryItem.defaultProps = {
  onDelete: () => {},
  handleAdd: () => {},
  empty: false,
}

export default PhotoGalleryItem
