/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { paths } from "../../routes/paths"
import services from "../../services"
import styles from "./QuoteCard.module.scss"

const QuoteCard = ({}) => {
  const [quoteCards, setQuoteCards] = useState([])
  const history = useHistory()
  useEffect(() => {
    services.articleServices
      .getHomePageArticles()
      .then(
        ({ data: { institute_articles = [], inspiration_articles = [] } }) => {
          inspiration_articles.forEach((article, index) =>
            quoteCards.push(
              { ...inspiration_articles[index], type: "inspiration" },
              { ...institute_articles[index], type: "institute" },
            ),
          )
          setQuoteCards(quoteCards.slice(0, 5))
        },
      )
  }, [])

  return (
    <div className={styles.galleryWrap}>
      {quoteCards.length
        ? quoteCards.map((card) => (
            <div
              className={styles.userWrap}
              style={{
                backgroundImage: `url(${card.image_url})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
              key={card.uuid}
              onClick={() => {
                card.type === "inspiration"
                  ? history.push(paths.specificInspirationArticle(card.uuid))
                  : history.push(paths.specificInstituteArticle(card.uuid))
              }}
            >
              <p className={styles.title}>{card.title}</p>
            </div>
          ))
        : null}
    </div>
  )
}

export default QuoteCard
