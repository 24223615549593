import React from "react"
import warningImg from "./../../images/icons/warning.png"
import styles from "./ResetPasswordError.module.scss"

const ResetPasswordError = ({ errorMessage, title }) => (
  <div className={styles.wrapper}>
    <img src={warningImg} alt="" className={styles.icon} />
    <div className={styles.errorText}>{title}</div>
    <div className={styles.errorText}>{errorMessage}</div>
  </div>
)

ResetPasswordError.defaultProps = {
  title: "Something went wrong.",
}

export default ResetPasswordError
