import React from 'react';
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import styles from "../SelectedCompanyProfilePage/SelectedCompanyProfilePage.module.scss";
import SelectedFreelancerContainer from "../../containers/SelectedFreelancerContainer/SelectedFreelancerContainer";

const SelectedFreelancerProfile = () => {
    const location = useLocation()
    const values = queryString.parse(location.search)
    return (
        <div className={styles.profilePageWrapper}>
            <SelectedFreelancerContainer from={values?.from} />
        </div>
    )
};

export default SelectedFreelancerProfile;
