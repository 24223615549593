import React, { useState, useRef, useEffect, createRef } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import { ReactComponent as Error } from "../../images/icons/error.svg"

import style from "./Dropdown.module.scss"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"

const Dropdown = ({
  id,
  label,
  placeholder,
  className,
  disabled,
  value,
  options,
  defaultOptionId,
  onChange,
  error,
  errorMessage,
  wrapperStyles,
  small,
  listStyle,
  border,
  emptyInfo,
  onBlur,
  styles,
  fieldStyle,
  isWhiteBackground,
  withoutError,
  ...attrs
}) => {
  const [selected, setSelected] = useState(
    typeof value === "string" ? { id: "default", value: placeholder } : value,
  )
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const [isOpen, setIsOpen] = useState(false)
  const isInitialMount = useRef(true)
  const [isDefaultValue, setIsDefaultValue] = useState(false)

  // Click outside dropdown
  const dropdownRef = useRef(null)

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setSelected(value)

    if (defaultOptionId !== value.id) {
      setIsDefaultValue(false)
    } else {
      setIsDefaultValue(true)
    }
  }, [defaultOptionId, value])

  useEffect(() => {
    if (defaultOptionId) {
      setIsDefaultValue(true)

      const defaultValue = options.filter(
        (option) => option.id === defaultOptionId,
      )

      if (defaultValue[0]) {
        setSelected(defaultValue[0])
      }
    }
  }, [defaultOptionId, options])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      // Your useEffect code here to be run on update
      // onChange(selected);
    }
    // eslint-disable-next-line
  }, [selected])

  const handleOnBlur = (e) => {
    if (onBlur) {
      onBlur(e, selected)
    }
  }

  // Handling options through the navigation buttons
  const refs = options.reduce((acc, value) => {
    acc[value.id] = createRef()
    return acc
  }, {})

  const scrollListToSelectOption = (id) => {
    const list = dropdownRef.current.querySelector(`.${style.optionsWrapper}`)
    const option = refs[id]

    if (list.scrollHeight > list.clientHeight) {
      const scrollBottom = list.clientHeight + list.scrollTop
      const elementBottom =
        option.current.offsetTop + option.current.offsetHeight

      if (elementBottom > scrollBottom) {
        list.scrollTop = elementBottom - list.clientHeight
      } else if (option.current.offsetTop < list.scrollTop) {
        list.scrollTop = option.current.offsetTop
      }
    }
  }

  const handleKeyDown = (e) => {
    const space = 32
    const up = 38
    const down = 40

    if (e.keyCode === space) {
      setIsOpen(true)
    } else if (e.keyCode === down) {
      if (selectedIndex === options.length - 1) {
        setSelected(options[0])
        setSelectedIndex(0)
        scrollListToSelectOption(options[0].id)
      } else {
        setSelected(options[selectedIndex + 1])
        setSelectedIndex(selectedIndex + 1)
        scrollListToSelectOption(options[selectedIndex + 1].id)
      }
    } else if (e.keyCode === up) {
      if (selectedIndex === -1 || selectedIndex === 0) {
        setSelected(options[options.length - 1])
        setSelectedIndex(options.length - 1)
        scrollListToSelectOption(options[options.length - 1].id)
      } else {
        setSelected(options[selectedIndex - 1])
        setSelectedIndex(selectedIndex - 1)
        scrollListToSelectOption(options[selectedIndex - 1].id)
      }
    }
  }

  const distanceButton = options.length < 1 ? 0 : 0

  return (
    <div
      className={classnames(
        style.wrapper,
        className,
        withoutError && style.withoutError,
      )}
      disabled={emptyInfo ? false : disabled || options.length === 0}
      style={wrapperStyles}
    >
      {label && (
        <label
          className={classnames(
            "text",
            style.label,
            isWhiteBackground && style.labelWhite,
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      <div
        id={id}
        tabIndex={disabled ? -1 : 0}
        role="listbox"
        ref={dropdownRef}
        style={small ? { width: "180px", height: "32px" } : styles}
        className={classnames({
          [style.valueSelected]: value && value.value && !isDefaultValue,
          [style.dropdown]: !value.value && isDefaultValue,
          [style.darkDrodown]: fieldStyle === "black",
          [style.grayDropdown]: fieldStyle === "gray",
          [style.dropdownWithBorder]: border === true,
          [style.dropdownError]: error,
          [style.isWhiteBackground]: isWhiteBackground,
        })}
        aria-expanded={isOpen ? "true" : "false"}
        onClick={() => {
          setIsOpen(!isOpen)
          dropdownRef.current.focus()
        }}
        onKeyDown={handleKeyDown}
        onKeyPress={({ key }) => {
          if (key === "Enter") setIsOpen(false)
        }}
        onBlur={handleOnBlur}
        {...attrs}
      >
        <div
          aria-hidden="true"
          style={small ? { height: "32px" } : null}
          className={style.button}
        >
          <svg
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            transform={isOpen ? "rotate(180) translate(0, 3)" : null}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0 0L5 5L10 0H0Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
        <div
          className={classnames(
            style.optionDefault,
            !selected.value ? style.placeholder : null,
            !selected.value && isWhiteBackground ? style.placholderWhite : null,
            selected.value && isWhiteBackground ? style.selectedWhite : null,
          )}
          style={small ? { fontSize: "12px", padding: "7px 8px" } : null}
          role="alert"
          aria-live="polite"
          aria-atomic="true"
        >
          {value.value
            ? value.value[0].toUpperCase() + value.value.slice(1)
            : selected.value
            ? selected.value[0].toUpperCase() + selected.value.slice(1)
            : placeholder}
        </div>
        <div
          // Items length * item height + padding
          style={
            emptyInfo && !options.length
              ? { height: "50%", paddingBottom: "50px" }
              : {
                  height: `${options.length * 48 + 8 + distanceButton}px`,
                  ...listStyle,
                }
          }
          className={classnames(style.options, {
            [style.optionsVisible]: isOpen,
            [style.withBorder]: border === true,
          })}
        >
          <div className={style.optionsWrapper}>
            {emptyInfo && !options.length ? (
              <div
                style={{
                  fontSize: "14px",
                  fontFamily: "Roboto",
                  padding: "0.5em",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  color: "82888D",
                }}
              >
                {emptyInfo}
              </div>
            ) : (
              options.map((option, index) => (
                <CustomTooltip text={option.tooltip} key={option.id}>
                  <div
                    role="option"
                    ref={refs[option.id]}
                    style={{ pointerEvents: "all" }}
                    aria-checked={selected.id === option.id ? "true" : "false"}
                    aria-selected={selected.id === option.id ? "true" : "false"}
                    className={classnames(style.option, {
                      [style.optionSelected]: selected.id === option.id,
                      [style.optionDisabled]: option.disabled === true,
                    })}
                    onClick={(e) => {
                      if (!option.disabled) {
                        dropdownRef.current.blur()
                        e.stopPropagation()
                        setIsOpen(false)
                        // setSelected(option);
                        setSelectedIndex(index)
                        if (attrs.name) {
                          onChange(attrs.name, option)
                        } else {
                          onChange(option)
                        }
                      }
                    }}
                  >
                    <span>{option.value}</span>
                  </div>
                </CustomTooltip>
              ))
            )}
          </div>
          {/* {errorMessage && <span className={classnames("noteText", style.errorText)}>{errorMessage}</span>} */}
        </div>
      </div>

      {!isOpen && error && errorMessage && (
        <div
          className={classnames(style.errorMessageWrap, {
            [style.blackErrorMessage]: isWhiteBackground,
          })}
        >
          <Error />
          <p className={style.errorMessage}>{errorMessage}</p>
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.string,
    }),
  ),
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  small: PropTypes.bool,
  border: PropTypes.bool,
  fieldStyle: PropTypes.string.isRequired,
  isWhiteBackground: PropTypes.bool,
}

Dropdown.defaultProps = {
  label: "",
  className: "",
  placeholder: "Select item",
  disabled: false,
  options: [],
  value: {},
  onBlur: () => {},
  onChange: () => {},
  error: false,
  errorMessage: "",
  isWhiteBackground: false,
}

export default Dropdown
