import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"

import styles from "./TopOpportunitiesSection.module.scss"
import OpportunityCard from "../../../cards/OpportunityCard"
import TopOportunitiesFilter from "../../../TopFilter/TopFilter"
import Button from "../../../Button/Button"
import { paths } from "../../../../routes/paths"
import LongArticleCard from "../../../cards/LongArticleCard/LongArticleCard"
import { GUEST } from "../../../../helpers/userRoles"
import services from "../../../../services"
import useWindowHeightWidth from "../../../../hooks/useWindowHeightWidth"
import { getOpportunityTitle, opportunitiesTypesArray } from "../../heplers"
import AccessCheckerPopup from "../AccessCheckerPopup/AccessCheckerPopup"
import NoDataPlaceholder from "../../../elements/NoDataPlaceholder"
import PageLinedTitle from "../../../elements/PageLinedTitle"

const TopOpportunitiesSection = ({ user, article, companyInfo }) => {
  const history = useHistory()
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState([])
  const { width } = useWindowHeightWidth()

  const handleCardClick = (uuid) => {
    if (user.role === GUEST) return setVisible(true)
    history.push(`/opportunity?id=${uuid}`)
  }

  const handleFilterClick = (title) => {
    const type = getOpportunityTitle(title)
    services.opportunityServices
      .getTopOpportunities(user.uuid, type)
      .then((result) => setData(result.data))
      .catch((error) => error)
  }

  useEffect(() => {
    services.opportunityServices
      .getTopOpportunities(user.uuid)
      .then((result) => setData(result.data))
  }, [])

  return (
    <section className={styles.topOpportunities}>
      <PageLinedTitle title="Top Opportunities" />

      <div className="container">
        <TopOportunitiesFilter
          filterArray={opportunitiesTypesArray}
          startFilter={opportunitiesTypesArray[0]}
          onSelect={handleFilterClick}
        />

        <div className={styles.opportunitiesWrapper}>
          <div className={styles.opportunitiesList}>
            {data?.length ? (
              data
                .map((opportunity) => (
                  <OpportunityCard
                    key={opportunity.uuid}
                    uuid={opportunity.uuid}
                    avatar={opportunity.company_photo}
                    caption={opportunity.name}
                    description={opportunity.description}
                    type={opportunity.opportunity_type}
                    location={{
                      city: opportunity.location,
                      head: opportunity.company_name,
                    }}
                    timestamp={Date.parse(opportunity.created_at)}
                    onClick={() => {
                      handleCardClick(opportunity.uuid)
                    }}
                    isFavorite={opportunity.favorite}
                    classes={styles.opportunity}
                    visibleFavoriteButton={user.uuid}
                  />
                ))
                .slice(0, width < 426 ? 2 : data.length)
            ) : (
              <div style={{ marginBottom: 24, width: "100%" }}>
                <NoDataPlaceholder>
                  Nothing here yet.{" "}
                  {companyInfo.type === "company_seeker"
                    ? "Be the first one to contribute."
                    : "Please try again later."}
                </NoDataPlaceholder>
              </div>
            )}

            <div className={styles.btnWrapper}>
              <Button
                buttonStyle="outlined"
                onClick={() => {
                  history.push(
                    paths.opportunitySearch(),
                  )
                }}
              >
                VIEW ALL
              </Button>
            </div>
          </div>

          <LongArticleCard article={article} />
        </div>
      </div>

      <AccessCheckerPopup
        visible={visible}
        closeAction={() => {
          setVisible(false)
        }}
      />
    </section>
  )
}

export default TopOpportunitiesSection
