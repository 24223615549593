import React from "react"
import { useLocation } from "react-router-dom"
import OpportunityShareContainer from "../../containers/OpportunityShareContainer/OpportunityShareContainer";

const OpportunitySharePage = () => {
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const id = pathSegments[pathSegments.length - 2]; // Assumes '/opportunities/:id/share' structure

  return <OpportunityShareContainer id={id} />;
};

export default OpportunitySharePage;
