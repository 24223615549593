import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import styles from "./ProspectsCard.module.scss"
import { ReactComponent as Vector } from "../../../images/icons/Vector.svg"
import avatar from "../../../images/defaultUserAvatar.png"
import {
  convertCamelCaseToTitle,
  makeCutDottedString,
} from "../../../helpers/textHelpers"
import ApproveRejectProspect from "../../ApproveRejectProspect/ApproveRejectProspect"

const FlexCardItem = ({
  data: { seeker, opportunity, id, uuid, created_at, status },
  handleApprove,
  handleReject,
  onOpenChat,
}) => {
  const userPageLocation = () => {
    if (seeker && seeker["seeker_type"] === "company")
      return `/company/${seeker?.uuid}`
    else return `/user/${seeker?.uuid}`
  }

  const opportunityLocation = () => {
    return `/opportunity?id=${opportunity.uuid}`
  }

  const dateOf = new Date(created_at).toLocaleDateString("en-US")
  const opportunityInfo =
    (opportunity.name && (
      <>
        <span
          className={
            styles[opportunity["opportunity_type"].split("_").join("")]
          }
        >
          {convertCamelCaseToTitle(opportunity["opportunity_type"])}
        </span>
        {opportunity.name && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            {makeCutDottedString(opportunity.name, 32)}
          </span>
        )}
        {opportunity.compensation && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            {makeCutDottedString(opportunity.compensation, 19)}
          </span>
        )}
        {opportunity.duration && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            <span className={styles.duration}>{`${makeCutDottedString(
              opportunity.duration,
              16,
            )}`}</span>
          </span>
        )}
        {opportunity.role && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            <span>{`${makeCutDottedString(opportunity.role, 16)}`}</span>
          </span>
        )}
        {opportunity.funder_role && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            <span>{`${makeCutDottedString(opportunity.funder_role, 16)}`}</span>
          </span>
        )}
        {opportunity.ammount && (
          <span className={styles.opportunityInfoItem}>
            <span className={styles.dot}>·</span>
            <span>{`${makeCutDottedString(opportunity.ammount, 16)}`}</span>
          </span>
        )}
      </>
    )) ||
    ""
  const individualsInfo = {
    stitching: null,
    Knowledge: null,
  }
  return (
    <div className={styles.prospectsCardWrap} key={id}>
      <div className={styles.vectorWrap}>
        <Link to={userPageLocation} className={styles.userPageLink}>
          <div className={styles.cardImg}>
            <div
              className={styles.userBar}
              style={{ backgroundImage: `url(${seeker?.photo || avatar})` }}
            />
            <span className={styles.userBarName}>{seeker?.name}</span>
          </div>
        </Link>
        <span className={styles.vector}>
          <div
            className="iconWrap"
            onClick={() => {
              onOpenChat(id)
            }}
          >
            <Vector />
          </div>
        </span>
      </div>

      <div className={styles.heartWrap}>
        <div>
          <p className={styles.dateOf}>
            {!individualsInfo?.stitching ? dateOf : individualsInfo.stitching}
          </p>

          <Link
            to={!individualsInfo?.stitching && opportunityLocation}
            className={styles.company}
          >
            {!individualsInfo?.stitching
              ? opportunityInfo
              : individualsInfo.Knowledge.join(" , ")}
          </Link>
        </div>

        <ApproveRejectProspect
          id={uuid}
          handleReject={handleReject}
          handleApprove={handleApprove}
          isApproved={status === "accepted"}
          acceptVisible={status !== "requested"}
        />
      </div>
    </div>
  )
}

FlexCardItem.propTypes = {
  data: PropTypes.object,
  handleReject: PropTypes.func,
  handleApprove: PropTypes.func,
}

FlexCardItem.defaultProps = {
  handleReject: () => console.log("setIsPopupOpened"),
  handleApprove: () => console.log("handleApprove"),
}

export default FlexCardItem
