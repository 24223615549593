import React from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams, useLocation } from "react-router-dom"
import queryString from "query-string"

import styles from "./index.module.scss"
import { ReactComponent as DangerSign } from "../../images/icons/danger-sign.svg"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"
import services from "../../services"
import Loading from "../../components/Loading/Loading"
import { paths } from "../../routes/paths"

const EmailVerificationPage = () => {
  const [isLoading, setLoading] = React.useState(true)
  const [isVerified, setVerificationStatus] = React.useState(undefined)
  const userInfo = useSelector((state) => state.user)
  const history = useHistory()
  const params = useParams()
  const location = useLocation()

  const queryParams = queryString.parse(location.search)

  React.useEffect(() => {
    let timeOut = null

    if (params.token) {
      services.userServices
        .confirmEmail(params.token)
        .then(() => {
          setVerificationStatus(true)

          timeOut = setTimeout(() => {
            history.push(paths.login)
          }, 4000)
        })
        .catch(({ response }) => {

          if (response.data.error === "Activation token is expired") {
            history.push(userInfo.role === "guest" ? paths.login : paths.home)
          } else {
            setVerificationStatus(false)

            timeOut = setTimeout(() => {
              history.push(paths.emailVerifyResend(queryParams.email || ""))
            }, 4000)
          }
        })
    }

    return () => timeOut && clearTimeout(timeOut)
  }, [history, params.token, queryParams.email, userInfo])

  React.useEffect(() => {
    if (typeof isVerified === "boolean") {
      setLoading(false)
    }
  }, [isVerified])

  return (
    <div className={styles.pageWrap}>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {isVerified ? (
            <XYCenterAlignedWindow title="Congratulations! Your email address confirmed">
              <div class={styles.emailConfirmedMsg}>
                You will be redirected to Sign In page in few seconds
              </div>
            </XYCenterAlignedWindow>
          ) : (
            <XYCenterAlignedWindow>
              <div class={styles.emailConfirmedMsgDanger}>
                <div className={styles.dangerSign}>
                  <DangerSign />
                </div>

                <p>
                  This link has expired.
                  <br />
                  You will be redirected to email address verification page in a
                  few seconds.
                </p>
              </div>
            </XYCenterAlignedWindow>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default EmailVerificationPage
