import React from "react"
import { Link } from "react-router-dom"

import { ReactComponent as IndividualStep1 } from "../../images/signUp/wizard/individual-step-1.svg"
import { ReactComponent as IndividualStep2 } from "../../images/signUp/wizard/individual-step-2.svg"
import { ReactComponent as IndividualStep3 } from "../../images/signUp/wizard/individual-step-3.svg"
import { ReactComponent as IndividualStep4 } from "../../images/signUp/wizard/individual-step-4.svg"

import { ReactComponent as SeekerStep1 } from "../../images/signUp/wizard/seeker-step-1.svg"
import { ReactComponent as SeekerStep2 } from "../../images/signUp/wizard/seeker-step-2.svg"
import { ReactComponent as SeekerStep3 } from "../../images/signUp/wizard/seeker-step-3.svg"
import { ReactComponent as SeekerStep4 } from "../../images/signUp/wizard/seeker-step-4.svg"

import { ReactComponent as ProviderStep1 } from "../../images/signUp/wizard/provider-step-1.svg"
import { ReactComponent as ProviderStep2 } from "../../images/signUp/wizard/provider-step-2.svg"
import { ReactComponent as ProviderStep3 } from "../../images/signUp/wizard/provider-step-3.svg"
import { ReactComponent as ProviderStep4 } from "../../images/signUp/wizard/provider-step-4.svg"

import styles from "./index.module.scss"

const CommunityGuidelineLink = () => (
  <div>
    Thank you for joining the B2T community. <br /> Click the following link for
    our{" "}
    <Link
      to="/community-guideline"
      target="blank"
      className={styles.wizardGuidelineLink}
    >
      professional community guidelines.
    </Link>
  </div>
)

export const individualSteps = [
  {
    title: "Register Account",
    description:
      "Join the B2T community by selecting your subscription level to access opportunities, resources, and connect with professionals and companies. ",
    image: IndividualStep1,
  },
  {
    title: "Create a Profile",
    description:
      "Stand out amongst other professionals by creating a compelling and interesting profile.",
    image: IndividualStep2,
  },
  {
    title: "Search for Opportunities & Resources",
    description:
      "Explore relevant and relatable opportunities from global companies and organizations. ",
    image: IndividualStep3,
  },
  {
    title: "Engage & Accept",
    description: <CommunityGuidelineLink />,
    image: IndividualStep4,
  },
]

export const companySeekerSteps = [
  {
    title: "Register Account",
    description:
      "Join the B2T Community to connect with professionals and companies by selecting your organization’s subscription level to create/access opportunities and resources.",
    image: SeekerStep1,
  },
  {
    title: "Create a Profile",
    description:
      "Tell the B2T community about your company or organization by creating an interesting profile.",
    image: SeekerStep2,
  },
  {
    title: "Search & Create Opportunities",
    description:
      "Explore/post relevant and relatable opportunities, connect with professionals, companies, and organizations in the B2T community.",
    image: SeekerStep3,
  },
  {
    title: "Search, Engage, Accept",
    description: <CommunityGuidelineLink />,
    image: SeekerStep4,
  },
]

export const companyProviderSteps = [
  {
    title: "Register Account",
    description:
      "Join the B2T community by selecting your organization’s subscription level to create opportunities and resources for professionals, business owners, suppliers, and companies.",
    image: ProviderStep1,
  },
  {
    title: "Create a Profile",
    description:
      "Tell the B2T community about your company or organization by creating an interesting profile.",
    image: ProviderStep2,
  },
  {
    title: "Create Opportunities & Provide Resources ",
    description:
      "Post relevant and relatable opportunities and connect with professionals, companies and organizations in the B2T community.",
    image: ProviderStep3,
  },
  {
    title: "Search for Professionals & Companies",
    description: <CommunityGuidelineLink />,
    image: ProviderStep4,
  },
]
