import { useState, useEffect } from "react"

const useInitOpportunity = (opportunities, selectedType) => {
  const getOption = () => {
    const selectedOpportunity = opportunities.find(
      (opportunity) => opportunity.type === selectedType,
    )
    if (selectedOpportunity) {
      return {
        id: selectedOpportunity.type,
        value: selectedOpportunity.title,
      }
    } else {
      return {
        id: opportunities[0].type,
        value: opportunities[0].title,
      }
    }
  }
  const [opportunity, setOpportunity] = useState(getOption())
  useEffect(() => {
    setOpportunity(getOption())
    // eslint-disable-next-line
  }, [selectedType])
  return [opportunity, setOpportunity]
}
export default useInitOpportunity
