import React, { useState, useRef, useEffect } from "react"
import Cropper from "react-cropper"
import PropTypes from "prop-types"

import Popup from "../Popup/Popup"
import styles from "./AddProfilePhotoPopup.module.scss"
import defaultUserAvatar from "../../images/defaultUserAvatar.png"
import {
  readFile,
  getRoundedCanvas,
  resizedImage,
  createImage,
} from "../../helpers/imageHelpers"
import { objectComparison, throttle } from "../../helpers"
import Button from "../Button/Button"
import Loading from "../Loading/Loading"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

const AddProfilePhotoPopup = ({ url, onClose, onSave, currentData }) => {
  const allowedTypes = ["png", "jpg", "jpeg"]
  const maxSize = 10000000 // 10 mb
  const { width } = useWindowHeightWidth()

  const [error, setError] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [file, setFile] = useState(url)
  const [cropData, setCropData] = useState({})
  const [imageSize, setImageSize] = useState()
  const [croppedImage, setCroppedImage] = useState("")

  const inputRef = useRef(null)
  const cropper = useRef(null)

  const acceptButtonName = file ? "Upload" : "Choose photo"

  useEffect(() => {
    const fetch = async () => {
      const imageDataUrl = await createImage(url)
      if (width >= 445) {
        setImageSize({ height: imageDataUrl.height, width: imageDataUrl.width })
      } else {
        setImageSize({ height: imageDataUrl.height, width: width - 48 })
      }
      setFile(imageDataUrl.src)
      if (url) {
        const img = new Image()
        img.onload = () => {
          setLoaded(true)
        }
        img.src = url
      } else {
        setLoaded(true)
      }
    }

    if (url && currentData) {
      const data = {
        x: Number(currentData.x),
        y: Number(currentData.y),
        width: Number(currentData.width),
        height: Number(currentData.height),
        rotate: 0,
      }

      fetch()
      setCropData(data)
    }
  }, [url, currentData, width])

  const choosePhotoHandler = () => {
    if (!file) {
      inputRef.current.click()
      return
    } else {
      onSave({ file, croppedImage, cropData })
      onClose()
    }
    return
  }

  const onFileChange = async ({ target }) => {
    const file = target.files ? target.files[0] : null
    const isValiedType = allowedTypes.some((ext) => file.type.includes(ext))

    if (file && (file.size > maxSize || !isValiedType)) {
      setError(
        "Sorry, there was a problem while uploading the photo. Please, try again.",
      )
    } else {
      let imageDataUrl = await readFile(target.files[0])
      imageDataUrl = await createImage(imageDataUrl)
      imageDataUrl = resizedImage(imageDataUrl)
      imageDataUrl = await createImage(imageDataUrl)
      if (width >= 445) {
        setImageSize({ height: imageDataUrl.height, width: imageDataUrl.width })
      } else {
        setImageSize({ height: imageDataUrl.height, width: width - 48 })
      }
      setFile(imageDataUrl.src)
      setLoaded(true)
      target.value = ""
    }
  }

  const _crop = () => {
    // image in dataUrl
    const crop = cropper.current
    let oldCropData = {}
    let oldCroppedImage = {}

    if (!objectComparison(oldCropData, crop.getData(true))) {
      oldCropData = crop.getData(true)
      setCropData(crop.getData(true))
    }
    if (
      !objectComparison(
        oldCroppedImage,
        getRoundedCanvas(crop.getCroppedCanvas()).toDataURL(),
      )
    ) {
      oldCroppedImage = getRoundedCanvas(crop.getCroppedCanvas()).toDataURL()
      setCroppedImage(getRoundedCanvas(crop.getCroppedCanvas()).toDataURL())
    }
  }

  return (
    <Popup
      title="Upload Avatar"
      isCrossIcon
      button={
        <Button
          className={styles.btn}
          buttonStyle="outlinedBlack"
          height="40px"
          onClick={onClose}
        >
          Cancel
        </Button>
      }
      acceptButton={
        <Button
          className={styles.btn}
          height="40px"
          buttonStyle="black"
          onClick={choosePhotoHandler}
        >
          {acceptButtonName}
        </Button>
      }
      // width="636"
      className={styles.addPhotoPopup}
      closeAction={onClose}
    >
      <div className={styles.contentWrapper}>
        {!file && (
          <>
            {error && <p className={styles.errorMessage}>{error}</p>}
            {!error && <p>Maximum 10 Mb. Only .png .jpg .jpeg files. </p>}
            <input ref={inputRef} type="file" onChange={onFileChange} />
            <img
              className={styles.defaultImage}
              src={defaultUserAvatar}
              alt="User Profile Avatar"
            />
          </>
        )}

        {file && imageSize && loaded ? (
          <div className={styles.cropWrapper}>
            <Cropper
              ref={cropper}
              src={file}
              style={{
                height: imageSize.height,
                width: imageSize.width,
                margin: "auto",
              }}
              // Cropper.js options
              data={cropData}
              aspectRatio={1 / 1}
              guides={true}
              viewMode={1}
              crop={throttle(_crop, 100)}
              background={false}
              scalable={false}
              zoomable={false}
              autoCrop={true}
              checkOrientation={false}
            />
          </div>
        ) : (
          file && <Loading />
        )}
      </div>
    </Popup>
  )
}

AddProfilePhotoPopup.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  currentData: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    rotate: PropTypes.number,
  }),
}

AddProfilePhotoPopup.defaultProps = {
  url: "",
}

export default AddProfilePhotoPopup
