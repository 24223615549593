import moment from "moment"
export const TOGGLE_COLLAPSE = "TOGGLE_COLLAPSE"
export const SET_WHOLE_STATE = "SET_WHOLE_STATE"
export const SET_RESOURCE_TYPE = "SET_RESOURCE_TYPE"
export const SET_EVENT_TYPE = "SET_EVENT_TYPE"
export const SET_ACCESS_TYPE = "SET_ACCESS_TYPE"
export const SET_FROM_DATE = "SET_FROM_DATE"
export const SET_TO_DATE = "SET_TO_DATE"
export const SET_LOCATION = "SET_LOCATION"
export const SET_LOCATION_INPUT = "SET_LOCATION_INPUT"
export const SET_IS_FREE = "SET_IS_FREE"
export const RESET_FORM = "RESET_FORM"

const initValues = {
  resourceType: "",
  eventType: "",
  accessType: "",
  fromDate: null,
  toDate: null,
  location: "",
  locationInput: "",
  isFree: false,
}
export const initState = {
  isCollapse: false,
  values: initValues,
}

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case TOGGLE_COLLAPSE: {
      return { ...state, isCollapse: !state.isCollapse }
    }
    case SET_WHOLE_STATE: {
      const {
        searchParamGroup: {
          resource_type,
          resource_sub_type,
          event_type,
          from,
          to,
          location,
          free_only,
        },
      } = payload
      const values = {}
      values.resourceType = resource_type ? resource_type : ""
      values.eventType = resource_sub_type ? resource_sub_type : ""
      values.accessType = event_type ? event_type : ""
      values.fromDate = from ? moment(from) : null
      values.toDate = to ? moment(to) : null
      values.location = location ? location : ""
      values.locationInput = location ? location : ""
      values.isFree = free_only ? true : false
      return { ...state, values }
    }
    case SET_RESOURCE_TYPE: {
      const { resourceType } = payload
      return { ...state, values: { ...state.values, resourceType } }
    }
    case SET_EVENT_TYPE: {
      const { eventType } = payload
      return { ...state, values: { ...state.values, eventType } }
    }
    case SET_FROM_DATE: {
      const { fromDate } = payload
      return { ...state, values: { ...state.values, fromDate } }
    }
    case SET_TO_DATE: {
      const { toDate } = payload
      return { ...state, values: { ...state.values, toDate } }
    }
    case SET_ACCESS_TYPE: {
      const { accessType } = payload
      return { ...state, values: { ...state.values, accessType } }
    }
    case SET_LOCATION: {
      const { location } = payload
      return {
        ...state,
        values: { ...state.values, location, locationInput: location },
      }
    }
    case SET_LOCATION_INPUT: {
      const { locationInput } = payload
      return {
        ...state,
        values: { ...state.values, location: "", locationInput },
      }
    }
    case SET_IS_FREE: {
      const { isFree } = payload
      return {
        ...state,
        values: { ...state.values, isFree },
      }
    }
    case RESET_FORM: {
      return { ...state, values: initValues }
    }
    default:
      throw new Error("Unknown action")
  }
}
