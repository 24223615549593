import React from "react"
import styles from "./CompanyDepartmentsPage.module.scss"
import CompanyDepartmentsContainer from "../../containers/CompanyDepartmentsContainer/CompanyDepartmentsContainer"

const CompanyDepartmentsPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <CompanyDepartmentsContainer />
    </div>
  )
}

export default CompanyDepartmentsPage
