import React from "react"
import classNames from "classnames"
import styles from "./ProfileTable.module.scss"
import { ReactComponent as EditIcon } from "../../images/icons/edit.svg"
import PropTypes from "prop-types"

const ProfileTableItem = ({
  title,
  description,
  linkText,
  onClick,
  className,
  hasData,
  isActive,
  anotherUser,
}) => {
  return (
    <div className={classNames(styles.tableItem, className)}>
      <div className={styles.titleWrap}>
        <h5 className={styles.title}>{title}</h5>
        <span className={styles.line} />
        {hasData && !isActive && !anotherUser ? (
          <div
            className={classNames("iconWrap", styles.editIconWrap)}
            onClick={onClick}
          >
            <EditIcon className="icon" />
          </div>
        ) : null}
      </div>
      {!hasData && !isActive && !anotherUser ? (
        <>
          <p className={classNames("text16r", styles.description)}>
            {description}
          </p>
          <span
            onClick={onClick}
            className={classNames("goldLink", "text16r", styles.link)}
          >
            {linkText}
          </span>
        </>
      ) : null}
      {!hasData && anotherUser && <p className={styles.noInfo}>No info</p>}
    </div>
  )
}

ProfileTableItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func,
  hasData: PropTypes.bool,
  isActive: PropTypes.bool,
}

export default ProfileTableItem
