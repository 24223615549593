import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"
import { ReactComponent as ArrowSortUpDown } from "../../images/icons/arrow-sort-up-down.svg"
import useOutsideClicker from "../../hooks/useOutsideClicker"

const DropdownListSort = ({ options, selectedValue, onChange }) => {
  const [isOptionsVisible, setOptionsVisibility] = React.useState(false)
  const [selectedOption, setSelectedOption] = React.useState(options[0])

  React.useEffect(() => {
    if (selectedValue) {
      setSelectedOption(
        options.find((option) => option.value === selectedValue),
      )
    }
  }, [options, selectedValue])

  const optionsRef = React.useRef(null)
  useOutsideClicker(optionsRef, () => {
    setOptionsVisibility(false)
  })

  const selectOption = (option) => {
    if (option.value !== selectedOption.value) {
      // const clickedValue = options.find((option) => option.value === value)
      setSelectedOption(option)
      onChange(option)
    }

    setOptionsVisibility(false)
  }

  return (
    <div className={styles.dropdownWrapper}>
      <div className={styles.dropdownHeader}>
        <div className={styles.dropdownLabel}>Sort by:</div>
        <div
          className={styles.dropdownSelectedValue}
          onClick={() => setOptionsVisibility(true)}
        >
          <div className={styles.dropdownSelectedValueText}>
            {selectedOption.label}
          </div>
          <div
            className={classnames(
              styles.dropdownSelectedValueIcon,
              selectedOption.arrow === "down" &&
                styles.dropdownSelectedValueIconDown,
            )}
          >
            <ArrowSortUpDown />
          </div>
        </div>
      </div>

      {isOptionsVisible && (
        <div className={styles.dropdownOptions} ref={optionsRef}>
          <div className={styles.dropdownOptionsList}>
            {options.map((option, index) => (
              <div
                key={index}
                className={styles.dropdownOption}
                onClick={() => selectOption(option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

DropdownListSort.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      arrow: PropTypes.string,
    }),
  ).isRequired,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
}

export default DropdownListSort
