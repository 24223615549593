import React from "react"

import styles from "./index.module.scss"

const RedirectedFromEmailPage = () => {
  return (
    <div className={styles.pageWrap}>
      <div className="container">
        <div className={styles.pageMessageWrap}>
          <div className={styles.pageMessage}>
            <div className={styles.pageMessageText}>
              Congratulations! New email address confirmed
            </div>
            <div className={styles.pageMessageHelpText}>
              You will be redirected to main page in few seconds
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RedirectedFromEmailPage
