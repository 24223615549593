import React from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"

import styles from "./index.module.scss"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"

export type BreadcrumbsProps = {
  /** External styles */
  classes?: any
  /** Array of breadcrumbs' elements */
  chain: {
    link?: string
    text: string
  }[]
}
const Breadcrumbs = ({ classes, chain }: BreadcrumbsProps) => {
  const { width } = useWindowHeightWidth()

  return (
    <div className={classNames(styles.wrap, classes)}>
      {chain?.map((element, index) => (
        <div key={index} className={styles.linkWrap}>
          {element.link ? (
            <NavLink to={element.link} className={styles.link}>
              {getOptimizedText(element.text, width)}
            </NavLink>
          ) : (
            <span>{getOptimizedText(element.text, width)}</span>
          )}
        </div>
      ))}
    </div>
  )
}
export default Breadcrumbs

const getOptimizedText = (text: string, windowWidth?: number, length = 26) => {
  if (windowWidth && windowWidth <= 425) {
    return text?.length <= length ? text : `${text?.slice(0, length)}...` 
  }

  return text
}