import React from "react"
import PropTypes from "prop-types"

const MessageStatus = ({ read, sent, activeCard }) => {
  return (
    <>
      {read && (
        <svg width="16" height="9" viewBox="0 0 16 9" fill="none">
          <path
            d="M11.5334 0.199865C11.2734 -0.0601352 10.8534 -0.0601352 10.5934 0.199865L6.83342 3.95986L7.77342 4.89986L11.5334 1.1332C11.7868 0.879864 11.7868 0.453198 11.5334 0.199865ZM14.3601 0.193198L7.77342 6.77986L5.45342 4.46653C5.19342 4.20653 4.77342 4.20653 4.51342 4.46653C4.25342 4.72653 4.25342 5.14653 4.51342 5.40653L7.30009 8.1932C7.56009 8.4532 7.98009 8.4532 8.24009 8.1932L15.3001 1.13986C15.5601 0.879865 15.5601 0.459865 15.3001 0.199865H15.2934C15.0401 -0.0668019 14.6201 -0.066802 14.3601 0.193198ZM0.746758 5.4132L3.53342 8.19986C3.79342 8.45986 4.21342 8.45986 4.47342 8.19986L4.94009 7.7332L1.68676 4.46653C1.42676 4.20653 1.00676 4.20653 0.746758 4.46653C0.486758 4.72653 0.486758 5.1532 0.746758 5.4132Z"
            fill={activeCard ? "black" : "#F4C00D"}
          ></path>
        </svg>
      )}
      {sent && (
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
          <path
            d="M4 6.79988L1.66666 4.46655C1.40666 4.20655 0.99333 4.20655 0.73333 4.46655C0.47333 4.72655 0.47333 5.13988 0.73333 5.39988L3.52666 8.19322C3.78666 8.45322 4.20666 8.45322 4.46666 8.19322L11.5333 1.13322C11.7933 0.873216 11.7933 0.459883 11.5333 0.199883C11.2733 -0.0601172 10.86 -0.0601172 10.6 0.199883L4 6.79988Z"
            fill={activeCard ? "black" : "rgba(255, 255, 255, 0.7)"}
            fillOpacity="0.87"
          ></path>
        </svg>
      )}
    </>
  )
}

MessageStatus.propTypes = {
  read: PropTypes.bool.isRequired,
  sent: PropTypes.bool.isRequired,
  activeCard: PropTypes.bool.isRequired,
}

MessageStatus.defaultProps = {
  read: false,
  sent: false,
  activeCard: false,
}

export default MessageStatus
