import React from "react"
import classNames from "classnames"
import P from "prop-types"
import CloseIcon from "@material-ui/icons/Close"

import styles from "./CrossButton.module.scss"

const CrossButton = ({ onClick, crossStyle, className }) => (
  <div onClick={onClick}>
    <CloseIcon
      className={classNames(
        {
          [styles.rounded]: crossStyle === "rounded",
          [styles.primary]: crossStyle === "primary",
          [styles.darkRounded]: crossStyle === "darkRounded",
        },
        className,
      )}
    />
  </div>
)

CrossButton.propTypes = {
  crossStyle: P.oneOf(["darkRounded", "primary", "rounded"]),
  onClick: P.func,
}

CrossButton.defaultProps = {
  crossStyle: "primary",
  onClick: () => {},
}

export default CrossButton
