import {
  TOGGLE_COLLAPSE,
  SET_WHOLE_STATE,
  CHANGE_OPPORTUNITY_TYPE,
  SET_FROM_DATE,
  SET_TO_DATE,
  SET_COMPANY,
  SET_LOCATION,
  SET_COMPANY_INPUT,
  SET_LOCATION_INPUT,
  RESET_FORM,
} from "./reducer"

export const toggleCollapse = () => ({
  type: TOGGLE_COLLAPSE,
  payload: null,
})
export const setWholeState = (searchParamGroup) => ({
  type: SET_WHOLE_STATE,
  payload: { searchParamGroup },
})
export const changeOpportunityType = (opportunityType) => ({
  type: CHANGE_OPPORTUNITY_TYPE,
  payload: { opportunityType },
})
export const setFromDate = (fromDate) => ({
  type: SET_FROM_DATE,
  payload: { fromDate },
})
export const setToDate = (toDate) => ({
  type: SET_TO_DATE,
  payload: { toDate },
})
export const setCompany = (company) => ({
  type: SET_COMPANY,
  payload: { company },
})
export const setLocation = (location) => ({
  type: SET_LOCATION,
  payload: { location },
})
export const setCompanyInput = (companyInput) => ({
  type: SET_COMPANY_INPUT,
  payload: { companyInput },
})
export const setLocationInput = (locationInput) => ({
  type: SET_LOCATION_INPUT,
  payload: { locationInput },
})
export const resetForm = () => ({
  type: RESET_FORM,
  payload: null,
})
