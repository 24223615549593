import React, { useEffect, useState } from "react"
import { Prompt } from "react-router"
import PropTypes from "prop-types"
import styles from "./CompanyUserInfo.module.scss"
import UserImage from "../UserImage/UserImage"
import Input from "../Input/Input"
import Dropdown from "../Dropdown/Dropdown"
import MultiSelectDropdown from "../MultiSelectDropdown"
import { TagFinderView } from "../TagFinderView"
import { businessClassificationOptions } from "./options/businessClassificationOptions"
import { industryOptions } from "./options/industryOptions"
import { roleOptions } from "./options/roleOptions"
import { diverseOptions } from "./options/diverseOptions"
import { marketOptions } from "./options/marketOptions"
import { decriptionValidation, nameValidaton } from "./validation"
import { objectDeepCopy } from "../../helpers"
import CompanyUserShowInfo from "./CompanyUserShowInfo"
import ConfirmCancelPopup from "../Popup/ConfirmCancelPopup"
import TextArea from "../TextArea/TextArea"
import SaveCancelButtons from "./SaveCancelButtons"
import { messages } from "../../helpers/constants"
import ReportButton from "../ReportButton/ReportButton"
import {getLevelTypeName} from "../../containers/MemberSearchContainer/wrappers/TagWrapper/helpers";
import CustomTooltip from "./CustomTooltip";

const CompanyUserInfo = ({
  image,
  openPhotoPopup,
  anotherUser,
  // onOpenChat,
  name,
  // onChangeNameAndRole,
  onDeletePhotoProfile,
  activity,
  describeMe,
  descriptionProp,
  updateCompanyUser,
  markets,
  classifications,
  certified,
  certifications,
  companyType,
  levelType,
  onOpenChat,
  isHaveChatButton,
  reported,
  onReport,
  industr,
  diverse,
  isBlocked,
  userRole,
  onBlock,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [description, setDescription] = useState("")
  const [isCertified, setCertified] = useState(null)
  const [levelTypeState, setLevelTypeState] = useState(levelType)
  const [certification, setCertification] = useState("")
  const [classification, setClassification] = useState("")
  const [market, setMarket] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [errors, setErrors] = useState({})
  const [dropdownData, setDropdownData] = useState({
    activity: {},
    describeMe: {},
    industry: {},
    diverse: {},
  })

  const companyTypeRoleOptions = roleOptions.filter(
    (option) => option.companyType === companyType,
  )

  const describeMeValue =
    dropdownData.describeMe.value &&
    companyTypeRoleOptions.find(
      (item) => item.value === dropdownData.describeMe.value,
    )

  const activityValue =
    describeMeValue &&
    dropdownData?.activity?.value &&
    describeMeValue?.subValue?.find(
      (item) => item?.value === dropdownData?.activity?.value,
    )

  const descr =
    companyTypeRoleOptions.find((item) => item.databaseName === describeMe) ||
    ""
  const act = descr
    ? descr.subValue.find((item) => item.databaseName === activity)
    : ""
  const industry = industr
    ? industryOptions.find((item) => item.databaseName === industr)
    : ""
  const _diverse = diverse
    ? diverseOptions.find((item) => item.databaseName === diverse)
    : ""
  useEffect(() => {
    if (descr || activity || industr || diverse) {
      setDropdownData({
        ...dropdownData,
        industry: industry,
        describeMe: descr,
        activity: act,
        diverse: _diverse,
      })
    }
    if (descriptionProp) {
      setDescription(descriptionProp)
    }
    if (name) {
      setCompanyName(name)
    }
    if (certified != null) {
      setCertified(certified)
    }
    if (levelType) {
        setLevelTypeState(levelType)
    }
    if (certifications) {
      setCertification(certifications)
    }
    if (classifications) {
      setClassification(classifications)
    }
    if (markets) {
      setMarket(markets)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activity,
    descriptionProp,
    name,
    industr,
    certifications,
    classifications,
    markets,
  ])

  const checkToSave = () => {
    return (
      description.length === 0 ||
      Object.values(errors).some((item) => item.valid === false)
    )
  }

  const checkNoDescription = () => {
    setErrors({
      ...errors,
      description: decriptionValidation(description),
    })
  }

  const handleCancel = () => {
    const data = objectDeepCopy(dropdownData)
    setIsEditing(false)
    setHasChanges(false)
    setIsPopupOpened(false)
    setDropdownData({
      ...data,
      describeMe: descr || "",
      activity: act || "",
      industry: industry || "",
      diverse: _diverse || {},
    })
    setDescription(descriptionProp || "")
    setCompanyName(name)
    setErrors({})
  }

  const onCancelClick = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setIsEditing(false)
      setErrors({})
    }
  }

  const handleSave = async () => {
    await updateCompanyUser({
      description: description,
      name: companyName,
      describe_company: dropdownData.describeMe.databaseName,
      activity: dropdownData.activity?.databaseName,
      industry: dropdownData.industry.databaseName,
      diverse: dropdownData.diverse.databaseName,
      certifications: certification,
      classifications: classification,
      markets: market,
    })
    setIsEditing(false)
    setHasChanges(false)
  }

  const handleChangeInput = (e) => {
    setHasChanges(true)
    switch (e.target.name) {
      case "description": {
        setDescription(e.target.value)
        setErrors({
          ...errors,
          description: decriptionValidation(e.target.value),
        })
        break
      }
      case "certification": {
        setCertification(e.target.value)
        break
      }
      case "companyName": {
        setCompanyName(e.target.value)
        setErrors({
          ...errors,
          companyName: nameValidaton(e.target.value),
        })
        break
      }
      default:
        console.log("Wrong name")
    }
  }

  const handleDropdownChange = (name, value) => {
    setHasChanges(true)
    const descrChange = companyTypeRoleOptions.find(
      (option) => option.value === value.value,
    )
    const data = objectDeepCopy(dropdownData)
    setDropdownData({
      ...data,
      [name]: value,
      activity: descrChange ? {} : value,
    })

    if (descrChange?.subValue?.length > 0) {
      setErrors({
        ...errors,
        activity: {
          valid: false,
          errorMessage: "Please select main activity",
        },
      })
    }

    if ((name === "activity" && value) || descrChange?.subValue?.length === 0) {
      setErrors({
        ...errors,
        activity: {
          valid: true,
        },
      })
    }
  }

  const handleIndustryChange = (name, value) => {
    setHasChanges(true)
    const data = objectDeepCopy(dropdownData)
    setDropdownData({
      ...data,
      [name]: value,
    })
  }

  const handleDiverseChange = (name, value) => {
    handleIndustryChange(name, value)
  }

  const handleEdit = () => {
    setIsEditing(true)
  }

  const levelTypeLabel = getLevelTypeName(levelType);
  let levelTypeTooltipText = "";
  if (levelType === "elite") {
    levelTypeTooltipText = "Elite – business enterprise with revenue below $10M"
  } else if (levelType === "elite_premium") {
    levelTypeTooltipText = "Elite Premium – business enterprise with revenue above $50M"
  } else if (levelType === "elite_plus") {
    levelTypeTooltipText = "Elite Plus – business enterprise with revenue between  $10M- $50M"
  }

  return (
      <div className={styles.userInfoContainer}>
        {levelType && (
            <div className={styles.levelTypeContainer}>
              <CustomTooltip text={levelTypeTooltipText}>
                <h6 className={styles.levelType}>{levelTypeLabel}</h6>
              </CustomTooltip>
            </div>
        )}
        <ReportButton
            isBlocked={isBlocked}
            userRole={userRole}
            onUnblock={() => onBlock(false)}
            onReport={onReport}
            onBlock={() => onBlock(true)}
            isReported={reported}
            anotherUser={anotherUser}
            className={styles.reportIcon}
        />
        <Prompt
            when={hasChanges}
            message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
        />

        {isPopupOpened && hasChanges && (
            <ConfirmCancelPopup
                handleCancel={handleCancel}
                setIsPopupOpened={setIsPopupOpened}
            />
        )}

        <div className={styles.imageContainer}>
          <UserImage
              image={image}
              onUpdate={openPhotoPopup}
              selectedUser={anotherUser}
              onDelete={onDeletePhotoProfile}
          />
        </div>

        {isEditing ? (
            <div className={styles.inputsWrapper}>
              <Input
                  name="companyName"
                  error={errors.companyName && !errors.companyName.valid}
                  errorMessage={errors.companyName && errors.companyName.errorMessage}
                  value={companyName}
                  onChange={handleChangeInput}
                  fieldStyle="black"
                  label="Full Name"
                  className={styles.editItem}
              />
              <Dropdown
                  id="01"
                  name="describeMe"
                  fieldStyle="black"
                  options={companyTypeRoleOptions}
                  onBlur={() => {
                  }}
                  onChange={handleDropdownChange}
                  value={describeMeValue || {}}
                  label="Choose what describes you best"
                  className={styles.editItem}
              />
              {dropdownData?.describeMe?.subValue?.length > 0 && (
                  <Dropdown
                      id="02"
                      name="activity"
                      error={errors.activity && !errors.activity.valid}
                      errorMessage={errors.activity && errors.activity.errorMessage}
                      fieldStyle="black"
                      options={dropdownData.describeMe.subValue}
                      onBlur={() => {
                      }}
                      onChange={handleDropdownChange}
                      value={activityValue || {}}
                      label="Choose your current affiliation"
                      className={styles.editItem}
                  />
              )}
              <Dropdown
                  id="02"
                  name="industry"
                  fieldStyle="black"
                  options={industryOptions}
                  onBlur={() => {
                  }}
                  onChange={handleIndustryChange}
                  value={dropdownData.industry || {}}
                  label="Choose your industry:"
                  className={styles.editItem}
              />
              <Dropdown
                  id="02"
                  name="diverse"
                  fieldStyle="black"
                  options={diverseOptions}
                  onBlur={() => {
                  }}
                  onChange={handleDiverseChange}
                  value={dropdownData.diverse || {}}
                  label="Choose if you are diverse:"
                  className={styles.editItem}
              />
              <MultiSelectDropdown
                  id="classification-select"
                  name="classification"
                  customPlaceholder="Select multiple options"
                  fieldStyle="black"
                  options={businessClassificationOptions}
                  selectedOptions={classification}
                  onBlur={() => {
                  }}
                  onChangeOption={(selectedOptions) =>
                      setClassification(selectedOptions)
                  }
                  label="Choose your diversity classification:"
                  className={styles.editItem}
              />
              <TagFinderView
                  tagList={market.split(",").filter((item) => Boolean(item))}
                  onUpdateTagList={(marketList) => setMarket(marketList.join())}
              />
              <TextArea
                  vertical
                  name="certification"
                  value={certification}
                  label="Business certification(s)"
                  placeholder="Add certifications..."
                  maxLength={1000}
                  currentLength={certification.length}
                  onChange={handleChangeInput}
                  style={styles.textArea}
              />
              <TextArea
                  vertical
                  name="description"
                  error={errors.description && !errors.description.valid}
                  errorMessage={errors.description && errors.description.errorMessage}
                  value={description}
                  placeholder="Add Description..."
                  maxLength={1000}
                  currentLength={description.length}
                  onFocus={checkNoDescription}
                  onChange={handleChangeInput}
                  style={styles.textArea}
              />
              <div className={styles.buttonsWrapper}>
                <SaveCancelButtons
                    style={styles}
                    ableToSave={checkToSave()}
                    handleSave={handleSave}
                    onCancelClick={onCancelClick}
                />
              </div>
            </div>
        ) : (
            <CompanyUserShowInfo
                name={companyName}
                companyType={companyType}
                anotherUser={anotherUser}
                description={description}
                market={market
                    .split(",")
                    .filter((item) => Boolean(item))
                    .map((item) => {
                      const {value: region = "", subValue = []} =
                      marketOptions.find(
                          (option) => option.databaseName === item.substring(0, 2),
                      ) ?? {}
                      const {name: subRegion = ""} =
                      subValue.find(
                          (option) => option.databaseName === item.substring(2, 4),
                      ) ?? {}
                      return subRegion ? `${subRegion}, ${region}` : region
                    })
                    .join(", ")}
                classification={classification
                    .split(",")
                    .map(
                        (item) =>
                            businessClassificationOptions.find(
                                (option) => option.databaseName === item,
                            )?.value,
                    )
                    .join(", ")}
                isCertified={isCertified}
                levelType={levelTypeState}
                certification={certification}
                style={styles}
                dropdownData={dropdownData}
                handleEdit={handleEdit}
                onOpenChat={onOpenChat}
                isHaveChatButton={isHaveChatButton}
            />
        )}
      </div>
  )
}

CompanyUserInfo.defaultProps = {
  name: "Company Name",
  activity: "",
  describeMe: "",
}

CompanyUserInfo.propTypes = {
  image: PropTypes.string,
  openPhotoPopup: PropTypes.func,
  anotherUser: PropTypes.bool,
  name: PropTypes.string,
  onDeletePhotoProfile: PropTypes.func,
  activity: PropTypes.string,
  describeMe: PropTypes.string,
  descriptionProp: PropTypes.string,
  updateCompanyUser: PropTypes.func,
  companyType: PropTypes.string,
}

export default CompanyUserInfo
