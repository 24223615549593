import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useHistory } from "react-router-dom"

import Label from "../../elements/Label"
import PublishingTerm from "../../elements/PublishingTerm"
import CommentCounter from "../../elements/CommentCounter"
import ShareButton from "../../elements/ShareButton"
import LikeDislike from "../../LikeDislike/LikeDislike"
import logoImg from "../../../images/logo.png"
import styles from "./index.module.scss"
import services from "../../../services"
import Share from "../../Share"
import { paths } from "../../../routes/paths"

const InspirationArticleCard = ({
  uuid,
  classes,
  type,
  publishTimestamp,
  title,
  description,
  photoUrl,
  commentCount,
  reputation,
}) => {
  const [rating, setRating] = React.useState(reputation)
  const shareBtnRef = React.createRef()
  const likeDislikeBtnRef = React.createRef()
  const history = useHistory()

  let descriptionText
  try {
    const draftText = JSON.parse(description)
    descriptionText = draftText?.blocks
      ?.map((block) => (!block.text.trim() && "\n") || block.text)
      ?.join("\n")
  } catch {
    descriptionText = description
  }

  const cardClick = () => {
    history.push(paths.specificInspirationArticle(uuid))
  }

  const voteFn = (vote) => {
    services.articleServices
      .voteForInspirationArticle({ uuid, vote })
      .then(({ data }) =>
        setRating({
          ...data,
          type: data.type !== rating.type ? data.type : null,
        }),
      )
  }

  const onCardClick = (e) =>
    !shareBtnRef.current.contains(e.target) &&
    !likeDislikeBtnRef.current.contains(e.target) &&
    cardClick()

  return (
    <div className={classNames(styles.card, classes)} onClick={onCardClick}>
      <Label classes={styles.label} subject="article" type={type} />

      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${logoImg})` }}
      />
      <div className={styles.innerWrapper}>
        <p className={styles.publishedBy}>
          Posted by <span className={styles.user}>Administrator</span>
        </p>
        <p className={styles.publishingTerm}>
          <PublishingTerm publishTimestamp={publishTimestamp} />
        </p>
        <p className={styles.title}>{title}</p>
        {!!descriptionText && (
          <p className={styles.description}>{descriptionText}</p>
        )}
        {!!photoUrl && (
          <div
            className={styles.photo}
            style={{ backgroundImage: `url("${photoUrl}")` }}
          ></div>
        )}
        <div className={styles.footer}>
          <span className={styles.commentHandler}>
            <CommentCounter commentCount={commentCount || 0} />
          </span>

          <span ref={shareBtnRef} className={styles.shareHandler}>
            <Share
              title={title}
              description={description}
              url={`${window.location.origin}${paths.specificInspirationArticle(
                uuid,
              )}`}
              type="horizontal"
            >
              <ShareButton />
            </Share>
          </span>

          <span ref={likeDislikeBtnRef} className={styles.ratingHandler}>
            <LikeDislike
              classes={styles.likeBlock}
              clickedAction={rating.type}
              likeCount={rating.positive}
              dislikeCount={rating.negative}
              likeClick={(e) => {
                e.preventDefault()
                voteFn("up")
              }}
              dislikeClick={(e) => {
                e.preventDefault()
                voteFn("down")
                if (rating.type === " down") setRating({ ...rating, type: "" })
              }}
            />
          </span>
        </div>
      </div>
    </div>
  )
}
InspirationArticleCard.propTypes = {
  /** Data for `Label` component */
  label: PropTypes.shape({
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
  /** User data */
  userAvatar: PropTypes.string.isRequired,
  /** Main text of the card */
  text: PropTypes.string.isRequired,
  /** Data of `CommentCounter` component */
  commentCount: PropTypes.number,
  /** Action on click */
  uuid: PropTypes.string.isRequired,
  reputation: PropTypes.shape({
    positive: PropTypes.number,
    negative: PropTypes.number,
    value: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
}
export default InspirationArticleCard
