import React from "react"
import styles from "./CompanyProfilePage.module.scss"
import CompanyProfileContainer from "../../containers/CompanyProfileContainer/CompanyProfileContainer"

const CompanyProfilePage = () => {
  return (
    <div className={styles.profilePageWrapper}>
      <CompanyProfileContainer />
    </div>
  )
}

export default CompanyProfilePage
