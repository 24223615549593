import React from "react"
import { useHistory } from "react-router-dom"
import classNames from "classnames"

import styles from "../Resources/Resources.module.scss"
import TrendingCard from "../../TrendingCard/TrendingCard"
import InstituteHomePageCard from "../../cards/InstituteHomePageCard/InstituteHomePageCard"
import Button from "../../Button/Button"
import { makeCutDottedString } from "../../../helpers/textHelpers"
import PageLinedTitleHome from "../../elements/PageLinedTitleHome"
import b2tImage from "../../../images/logo.png"
import InspirationTypeFilter from "../../filters/InspirationTypeFilter"
import NoDataPlaceholder from "../../elements/NoDataPlaceholder"

const Inspiration = ({
  featuredTopics,
  inspiration,
  handleChangeInspirationType,
}) => {
  const history = useHistory()

  const showMoreNews = () => history.push("/inspiration-search")

  const handleNewsClick = (uuid) => history.push(`/inspiration-article/${uuid}`)

  return (
    <div className={styles.wrapper}>
      <PageLinedTitleHome title={"Inspiration"} subTitle={"Featured Topics"} />

      <div className={classNames("container", "fullWidth", styles.container)}>
        <InspirationTypeFilter onSelectType={handleChangeInspirationType} />

        <div className={styles.contentWrapper}>
          <div className={styles.cardsWrapper}>
            {inspiration?.length ? (
              inspiration.map((item) => (
                <InstituteHomePageCard
                  key={item.uuid}
                  uuid={item.uuid}
                  classes={styles.instituteCard}
                  onClick={() => handleNewsClick(item.uuid)}
                  photo={item.image_url}
                  title={item.title}
                  body={item.content}
                  type={item.article_type}
                  company={"Administrator"}
                  time={item.created_at}
                  companyPhoto={b2tImage}
                  reputation={item.reputation}
                  articleType="inspiration"
                />
              ))
            ) : (
              <div style={{ margin: "0 0 24px", width: "100%" }}>
                <NoDataPlaceholder>
                  Nothing here yet. Please try again later.
                </NoDataPlaceholder>
              </div>
            )}

            <div className={styles.showAllButton}>
              <Button buttonStyle="outlined" onClick={showMoreNews}>
                VIEW ALL
              </Button>
            </div>
          </div>

          <div className={styles.trending}>
            {featuredTopics &&
              featuredTopics.map((topic) => (
                <TrendingCard
                  key={topic.uuid}
                  imgUrl={topic.image_url}
                  title={topic.title}
                  text={makeCutDottedString(topic.content, 78)}
                  classes={styles.card}
                  onClick={() => handleNewsClick(topic.uuid)}
                  uuid
                />
              ))}
            <div
              className={classNames(styles.showAll, "goldLink")}
              onClick={showMoreNews}
            >
              show all news
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inspiration
