/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react"
import classNames from "classnames"
import styles from "./VideoPlayerPopup.module.scss"
import VideoPlayer from "../../VideoPlayer/VideoPlayer"
import { ReactComponent as DeleteIcon } from "../../../images/icons/deleteImage.svg"
import CrossButton from "../../CrossButton/CrossButton"
import Popup from "../../Popup/Popup"
import Button from "../../Button/Button"

const VideoPlayerPopup = ({ video, closeAction, deleteVideo, anotherUser }) => {
  const videoModalRef = useRef(null)
  const deleteModalRef = useRef(null)
  const topPanelRef = useRef(null)

  const [showTopLine, setShowTopLine] = useState(true)
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  let timeout

  const handleMouseDownOutside = (e) => {
    if (
      !videoModalRef.current?.contains(e.target) &&
      !deleteModalRef.current?.contains(e.target)
    ) {
      closeAction()
    }
  }
  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDownOutside)
    return () => window.removeEventListener("mousedown", handleMouseDownOutside)
  }, [])

  useEffect(() => {
    const escListener = (e) => {
      if (e.key === "Escape") {
        closeAction()
      }
    }

    window.addEventListener("keydown", escListener)

    return () => {
      window.removeEventListener("keydown", escListener)
    }
  }, [])

  useEffect(() => {
    if (showTopLine) {
      onShowTopLine()
    }
  }, [showTopLine])

  const onShowTopLine = () => {
    clearTimeout(timeout)
    topPanelRef.current.classList.add(styles.visible)
  }

  const mouseLeaveHandle = () => {
    timeout = setTimeout(() => {
      if (
        topPanelRef.current?.classList.contains(styles.visible) &&
        !showTopLine
      ) {
        topPanelRef.current.classList.remove(styles.visible)
      }
    }, 3000)
  }

  const onPlayerStateChange = ({ isEnded, isPaused }) => {
    if (isEnded || isPaused) {
      setShowTopLine(true)
    } else {
      setShowTopLine(false)
    }
  }

  return (
    <div className={styles.substrate}>
      {showDeletePopup && (
        <div ref={deleteModalRef}>
          <Popup
            dialog
            subtitle="Do you want to delete this video?"
            subtitleStyle={{ fontWeight: 500 }}
            button={
              <Button
                buttonStyle="black"
                width="97px"
                onClick={() => {
                  deleteVideo(video.uuid)
                  setShowDeletePopup(false)
                }}
              >
                Yes, sure
              </Button>
            }
            acceptButton={
              <Button
                buttonStyle="outlinedBlack"
                width="64px"
                onClick={() => setShowDeletePopup(false)}
              >
                No
              </Button>
            }
            closeAction={() => setShowDeletePopup(false)}
            isCrossIcon={true}
          />
        </div>
      )}
      <div className={styles.mainWrapper}>
        <div
          className={styles.wrapper}
          onMouseEnter={onShowTopLine}
          onMouseLeave={mouseLeaveHandle}
          ref={videoModalRef}
        >
          <div ref={topPanelRef} className={styles.topPanel}>
            <div className={styles.col}>
              <span className={styles.videoName}>
                {video.name.length > 20
                  ? `${video.name.substring(0, 20)}...`
                  : video.name}
              </span>
              {anotherUser === false && (
                <DeleteIcon
                  className={styles.deleteBtn}
                  onClick={() => {
                    setShowDeletePopup(true)
                  }}
                />
              )}
            </div>
            <div className={styles.col}>
              {!anotherUser && (
                <DeleteIcon
                  className={classNames(styles.deleteBtn, styles.tablet)}
                  onClick={() => {
                    setShowDeletePopup(true)
                  }}
                />
              )}
              <CrossButton crossStyle="darkRounded" onClick={closeAction} />
            </div>
          </div>
          <VideoPlayer
            onPlayerStateChange={onPlayerStateChange}
            width="80%"
            height="80%"
            source={video.path}
            className={styles.player}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoPlayerPopup
