const generateMessage = (
  senderName,
  middleText,
  attachedObjectName,
  endText,
) => {
  return `${senderName} ${middleText} ${attachedObjectName} ${endText}.`
}

export const messageDecoder = (message, name) => {
  if (
    (message.message_type === "system" || message.type === "system") &&
    message.attached_object
  ) {
    const partnerName =
      message.sender?.full_name || message.sender?.name || name
    switch (message.body) {
      case "opportunity_created": {
        if (message.from_you) {
          return generateMessage(
            "Your",
            "application to",
            message.attached_object.name,
            "opportunity was successfully sent",
          )
        }
        return generateMessage(
          partnerName,
          "applies on",
          message.attached_object.name,
          "opportunity",
        )
      }
      case "opportunity_created_from_provider": {
        if (message.from_you) {
          return generateMessage(
            message.attached_object.name,
            "application",
            "was",
            "disapproved",
          )
        }
        return generateMessage(
          partnerName,
          "disapproved your",
          message.attached_object.name,
          "application",
        )
      }

      case "opportunity_rejected_from_seeker": {
        if (message.from_you) {
          return generateMessage(
            message.attached_object.name,
            "application",
            "was",
            "canceled",
          )
        }
        return generateMessage(
          partnerName,
          "declined application for",
          message.attached_object.name,
          "opportunity",
        )
      }

      case "opportunity_rejected_from_provider": {
        if (message.from_you) {
          return generateMessage(
            message.attached_object.name,
            "application",
            "was",
            "rejected",
          )
        }

        return generateMessage(
          partnerName,
          "rejected your",
          message.attached_object.name,
          "application",
        )
      }

      case "opportunity_accepted_from_provider": {
        if (message.from_you) {
          return generateMessage(
            message.attached_object.name,
            "application",
            "was",
            "accepted",
          )
        }
        return generateMessage(
          partnerName,
          "accepted your",
          message.attached_object.name,
          "application",
        )
      }

      case "chat_is_blocked": {
        if (message.from_you) {
          return generateMessage(
            "You",
            "blocked chat",
            "with",
            message.recipient?.full_name || message.recipient?.name || name,
          )
        }
        return generateMessage(
          message.sender?.full_name || message.sender?.name || name,
          "blocked",
          "chat",
          "with you",
        )
      }
      case "chat_is_unblocked": {
        if (message.from_you) {
          return generateMessage(
            "You",
            "unblocked chat",
            "with",
            message.recipient?.full_name || message.recipient?.name || name,
          )
        }
        return generateMessage(
          message.sender?.full_name || message.sender?.name || name,
          "unblocked",
          "chat",
          "with you",
        )
      }

      default: {
        return message.body
      }
    }
  } else {
    return message.body
  }
}

export const getMessageBg = (message) => {
  if (message.message_type === "system") {
    switch (message.body) {
      case "opportunity_created": {
        return "created"
      }
      case "opportunity_created_from_provider":
      case "opportunity_rejected_from_seeker":
      case "opportunity_rejected_from_provider": {
        return "rejected"
      }

      case "opportunity_accepted_from_provider": {
        return "approved"
      }

      case "chat_is_blocked": {
        return "blocked"
      }
      case "chat_is_unblocked": {
        return "unblocked"
      }

      default: {
        return message.body
      }
    }
  } else {
    return message.body
  }
}
