import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    fontWeight: 400,
    textTransform: "none",
    padding: "0",
    border: "1px solid rgba(255, 255, 255, 0)",
    borderRadius: "2px",
    width: "136px",
    height: "48px",
  },
  label: {
    fontSize: 16,
  },
  blackDisabled: {
    backgroundColor: "black",
    borderColor: "#000",
    color: "white !important",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  disabled: {
    opacity: 0.38,
  },
  containedSecondary: {
    backgroundColor: "#B4182D",
    borderColor: "#B4182D",
    "&:hover": {
      backgroundColor: "#BA2A3E",
      borderColor: "#BA2A3E",
    },
    "&$disabled": {
      backgroundColor: "#B4182D",
      borderColor: "#B4182D",
      color: "#fff",
    },
  },
  outlined: {
    backgroundColor: "transparent",
    borderColor: "#fff",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
    "&$disabled": {
      backgroundColor: "transparent",
      borderColor: "#fff",
      color: "#fff",
    },
  },
  outlinedBlack: {
    color: "#141518",
    borderColor: "#141518",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "rgb(235,235,235)",
    },
  },
  black: {
    backgroundColor: "black",
    borderColor: "#000",

    "&:hover": {
      backgroundColor: "rgb(49,49,52)",
    },
  },
}))

export default useStyles
