import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import SearchFormWrapper from "./wrappers/SearchFormWrapper"
import CaptionWrapper from "./wrappers/CaptionWrapper"
import TagWrapper from "./wrappers/TagWrapper"
import MainWrapper from "./wrappers/MainWrapper"
import OpportunityTypeFilter from "../../components/filters/OpportunityTypeFilter"
import Button from "../../components/Button/Button"
import OpportunitySearchFilter from "../../components/filters/OpportunitySearchFilter"
import useSearch from "./useSearch"
import {
  setTabFilter,
  setFormFilters,
  resetFormFilters,
} from "./useSearch/actionCreators"
import CompanyAddonTypePopup from "../../components/CompanyAddonTypePopup"
import { opportunitiesTypeCards } from "../../components/Opportunity/heplers"
import {COMPANY_SEEKER, GUEST, INDIVIDUAL} from "../../helpers/userRoles"
import styles from "./index.module.scss"

const OpportunitySearchContainer = () => {
  const [visiblePopup, setVisiblePopup] = useState(false)
  const [state, dispatch] = useSearch()
  const { searchParamGroup } = state
  const userRole = useSelector((state) => state.user.role)
  const companyUuid = useSelector((state) => state.user.company.uuid)
  const companyInfo = useSelector((state) => state.user.company)
  const history = useHistory()
  return (
    <div className={styles.searchContainer}>
      {visiblePopup && (
        <CompanyAddonTypePopup
          width={953}
          closeAction={setVisiblePopup}
          title="Add Opportunity"
          handleNextClick={(a) =>
            history.push(`/opportunity-edit?variant=${a.type}`)
          }
          data={opportunitiesTypeCards}
        />
      )}
      <SearchFormWrapper state={state} dispatch={dispatch} />
      <CaptionWrapper state={state} />
      <div className={styles.container}>
        <OpportunityTypeFilter
          classes={styles.tabFilter}
          searchParamGroup={state.searchParamGroup}
          onSelectType={(opportunityType) =>
            dispatch(setTabFilter(opportunityType))
          }
        />
        <div className={styles.body}>
          <main>
            <TagWrapper
              classes={styles.tagBlock}
              searchParamGroup={state.searchParamGroup}
              dispatch={dispatch}
            />
            <MainWrapper
              state={state}
              dispatch={dispatch}
              companyInfo={companyInfo}
            />
          </main>
          <aside>
            <div className={styles.sideBar}>
              {userRole !== GUEST && userRole !== INDIVIDUAL && companyInfo?.type !== COMPANY_SEEKER && (
                <>
                  <Link
                    className={styles.link}
                    to={`/opportunities/${companyUuid}`}
                  >
                    <Button className={styles.btn}>My Opportunities</Button>
                  </Link>
                  <Button
                    onClick={() => setVisiblePopup(true)}
                    className={styles.btn}
                  >
                    Add Opportunity
                  </Button>
                </>
              )}
              <OpportunitySearchFilter
                searchParamGroup={state.searchParamGroup}
                onReset={() => dispatch(resetFormFilters())}
                onSubmit={(values) => dispatch(setFormFilters(values))}
              />
              {/* <div className={styles.trendingBlock}>
                <p className={styles.caption}>Trending</p>
                For next releases
                <TrendingCard
                  classes={styles.trendingCard}
                  title="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Georgia"
                  text="Stacey Abrams Issues Emergency Request To Stop Voter Purging In Geo..."
                  imgUrl={imgCardUrl}
                />
              </div> */}
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default OpportunitySearchContainer
