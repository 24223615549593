import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import TextArea from "../../../TextArea/TextArea"
import ProfileTableItem from "../../ProfileTableItem"
import Button from "../../../Button/Button"
import classNames from "classnames"
import styles from "./ExperienceText.module.scss"
import validation from "./validation"
import { Prompt } from "react-router"
import ConfirmCancelPopup from "../../../Popup/ConfirmCancelPopup"
import { messages } from "../../../../helpers/constants"
import CopyToClipboard from "../../../CopyToClipboard"

const ExperienceText = ({ data, update, anotherUser }) => {
  const maxLength = 2000
  const placeholder =
    "Share your unique talents, backgrounds, goals, inspirations and motivation"
  const [editedState, setEditedState] = useState("")
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const textArea = React.useRef(null)

  useEffect(() => {
    if (data) {
      setEditedState(data)
    }
    if (data === null) {
      setEditedState("")
    }
  }, [data])

  const onChangeText = (e) => {
    setEditedState(e.target.value)
    setHasChanges(true)
    setErrorMessage(validation(e.target.value))
  }

  const handleSave = () => {
    setHasChanges(false)
    setIsEditing(false)
    update({ experience_text: editedState.trim() })
  }

  const handleClosePopup = () => {
    setEditedState(data)
    setIsEditing(false)
    setErrorMessage("")
    setHasChanges(false)
    setIsPopupOpened(false)
  }

  const handleCancel = () => {
    if (hasChanges) {
      setIsPopupOpened(true)
    } else {
      setErrorMessage("")
      setEditedState(data)
      setIsEditing(false)
    }
  }

  return (
    <div className={styles.wrap}>
      <Prompt
        when={hasChanges}
        message={messages.CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE}
      />
      <ProfileTableItem
        title="Experience"
        onClick={() => {
          setIsEditing(true)
          setTimeout(() => {
            if (textArea.current) {
              textArea.current.selectionStart = editedState?.length + 1
              textArea.current.focus()
            }
          })
        }}
        hasData={!!(data && data.length)}
        isActive={isEditing}
        description="Share your unique talents, background, goals, inspirations and motivations"
        linkText="+ Add your experience"
        anotherUser={anotherUser}
      />
      {isPopupOpened && hasChanges && (
        <ConfirmCancelPopup
          handleCancel={handleClosePopup}
          setIsPopupOpened={setIsPopupOpened}
        />
      )}
      <div
        className={classNames({
          [styles.wrapper]: data || isEditing,
          [styles.hiddenWrapper]: !data && !isEditing,
        })}
      >
        <div className={styles.content}>
          {isEditing ? (
            <TextArea
              vertical
              error={Boolean(errorMessage && errorMessage.length > 0)}
              errorMessage={errorMessage}
              value={editedState}
              placeholder={placeholder}
              onChange={onChangeText}
              currentLength={editedState?.trim().length}
              maxLength={maxLength}
              style={styles.textArea}
              ref={textArea}
            />
          ) : (
            <div className={styles.contentText}>
              <CopyToClipboard text={data ?? ""} />
            </div>
          )}
        </div>
      </div>
      {isEditing && (
        <div className={styles.btnsWrap}>
          <Button
            onClick={handleCancel}
            className={styles.btn}
            buttonStyle="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            className={styles.btn}
            buttonStyle="contained"
            disabled={Boolean(errorMessage && errorMessage.length > 0)}
          >
            Save
          </Button>
        </div>
      )}
    </div>
  )
}

ExperienceText.propTypes = {
  data: PropTypes.string,
}

ExperienceText.defaultProps = {
  data: "",
}

export default ExperienceText
