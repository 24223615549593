import React, { useState, useEffect } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { resources } from "./resources"
import styles from "./index.module.scss"

const ResourceTypeFilter = ({ classes, searchParamGroup, onSelectType }) => {
  const [activeType, setActiveType] = useState(resources[0].type)

  useEffect(() => {
    if (searchParamGroup) {
      setActiveType(searchParamGroup.resource_type || "")
    }
  }, [searchParamGroup])

  return (
    <div className={classNames(styles.filter, classes)}>
      {resources.map((resource) => (
        <span
          className={classNames({
            [styles.activeType]: activeType === resource.type,
          })}
          onClick={() => {
            onSelectType(resource.type)
            setActiveType(resource.type)
          }}
        >
          {resource.title}
        </span>
      ))}
    </div>
  )
}
ResourceTypeFilter.propTypes = {
  /** Callback gets active resource type as argument */
  onSelectType: PropTypes.func,
}
export default ResourceTypeFilter
