import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import Box from "@material-ui/core/Box"
import Button from "../../components/Button/Button"
import Input from "../../components/Input/Input"
import { ReactComponent as Search } from "../../images/icons/search.svg"
import styles from "./index.module.scss"

const OpportunitySearchForm = ({
  classes,
  emptyMode,
  placeholder,
  searchQuery,
  onChange,
  onSearch,
}) => {
  return (
    <form
      className={classNames(
        styles.container,
        {
          [styles.emptySearchBar]: emptyMode,
          [styles.filledSearchBar]: !emptyMode,
        },
        classes,
      )}
      onSubmit={(e) => {
        e.preventDefault()
        onSearch()
      }}
    >
      {emptyMode && (
        <>
          <p className={styles.title}>Opportunities</p>
          <p className={styles.description}>
            As a member of the Bridge2Technologies community you have access to
            global opportunities, critical resources, vital connections, and
            intellectual capital.
          </p>
        </>
      )}
      <Box display="flex" flexWrap="wrap">
        <Box flexGrow={1}>
          <Input
            placeholder={placeholder}
            fieldStyle="light"
            type="search"
            value={searchQuery}
            name="search"
            beforeIcon={<Search />}
            onChange={(e) => onChange(e.target.value)}
          />
        </Box>
        <Button className={styles.btn} onClick={onSearch}>
          Search
        </Button>
      </Box>
    </form>
  )
}
OpportunitySearchForm.propTypes = {
  /** Classes for div container of the component */
  classes: PropTypes.object,
  /** Search query from url */
  searchQuery: PropTypes.string.isRequired,
  /** Request result */
  requestResult: PropTypes.object,
  /** Callback gets current query input as argument */
  onChange: PropTypes.func.isRequired,
  /** Callback invokes when user want to search */
  onSearch: PropTypes.func.isRequired,
}
export default OpportunitySearchForm
