/* eslint-disable no-useless-computed-key */
import React, { useState, useRef } from "react"
import classNames from "classnames"
import Input from "../../components/Input/Input"
import Grid from "@material-ui/core/Grid"
import Dropdown from "../../components/Dropdown/Dropdown"
import TextArea from "../../components/TextArea/TextArea"
import VideoCard from "../../components/Videos/VideoCard/VideoCard"
import { withFormik } from "formik"
import { mixed, object, string } from "yup"
import ButtonComponent from "../../components/Button/Button"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"
import { v4 as uuidv4 } from "uuid"
import { readFile } from "../../helpers/imageHelpers"
import { getVideoDuration, objectComparison } from "../../helpers"
import styles from "./EditStyles.module.scss"
import avatar from "../../images/defaultUserAvatar.png"
import { Prompt, useHistory } from "react-router-dom"
import TextEditor from "../../components/TextEditor/TextEditor"

import { scrollOnError } from "./helpers"
import Loading from "../../components/Loading/Loading"
import DeleteIcon from "@material-ui/icons/Delete"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import Button from "../../components/Button/Button"
import { messages } from "../../helpers/constants"
import { isHaveBadWords } from "../../helpers/badWords"
import { ReactComponent as AddMedia } from "../../images/icons/add_media.svg"
import { mentorOptions } from "../../components/Opportunity/dropdownOptions"
const maxTextAreaLength = 1000
const maxDescriptionLength = 5000

const SponsorshipForm = ({
  data,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  errors,
  touched,
  handleSubmit,
  onCancel,
  isSubmitting,
  onDelete,
  initialValues,
  isPrivate,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const fileInputRef = useRef(null)
  const [buttonClickedStatus, setButtonClickedStatus] = useState(false)
  const history = useHistory()

  const handleCancel = () => {
    setButtonClickedStatus(true)
    if (Object.keys(touched).length) {
      setIsPopupOpened(true)
    } else {
      onCancel ? onCancel() : history.push("/company-profile")
    }
  }
  const { width } = useWindowHeightWidth()
  const getVideo = () => {
    if (values["video"]?.path) {
      return values["video"]
    } else if (values["photo"]) {
      return { thumbnail: values["photo"].path }
    } else {
      return null
    }
  }

  const onUploadVideo = async ({ target }) => {
    const file = target.files[0]

    const path = await readFile(file)

    if (file.type.includes("video")) {
      setFieldValue(
        "video",
        {
          id: Number(uuidv4()),
          name: file.name,
          length: await getVideoDuration(file),
          file: { file },
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "video" },
      })
    } else if (file.type.includes("image")) {
      setFieldValue(
        "photo",
        {
          id: Number(uuidv4()),
          name: file.name,
          file: file,
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "photo" },
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <Prompt
        when={
          objectComparison(values, initialValues) === false &&
          !buttonClickedStatus
        }
        message={messages.CONFIRM_LEAVING_FROM_PAGE}
      />
      {isSubmitting && (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          handleCancel={() => {
            onCancel ? onCancel() : history.push("/company-profile")
            setIsPopupOpened(false)
          }}
        />
      )}
      <div className={styles.headerWrapp}>
        <h4 className={styles.title}>
          {data["uuid"] ? "Editing" : "Creating"} {isPrivate ? "Private " : ""}sponsors opportunity
        </h4>
        {data["uuid"] &&
          (width < 768 ? (
            <div
              className="iconWrap"
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />
            </div>
          ) : (
            <ButtonComponent
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              Delete
            </ButtonComponent>
          ))}
      </div>
      <div className={styles.sectionWrapper}>
        <Grid
          className={styles.inputsWrapper}
          container
          component="div"
          spacing={2}
        >
          <Grid item component="div" xs={12} className={styles.companyInfo}>
            <img
              src={data?.company_photo || avatar}
              alt="avatar"
              width="64px"
              className={styles.avatar}
            />
            <h4 className={styles.title}>{values["company_name"]}</h4>
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              autoFocus
              className={styles.input}
              name="name"
              label="Opportunity name*"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Type name"
              value={values["name"]}
              error={errors["name"] && touched["name"]}
              errorMessage={errors["name"] && touched["name"] && errors["name"]}
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="sponsorship_type"
              label="Sponsorship Type*"
              value={mentorOptions.find(
                (item) => item.value === values["sponsorship_type"],
              )}
              placeholder="Select sponsorship type"
              onChange={(name, option) =>
                handleChange({
                  target: { name: name, value: option.value },
                })
              }
              onBlur={() =>
                handleBlur({ target: { name: "sponsorship_type" } })
              }
              options={mentorOptions}
              error={errors["sponsorship_type"] && touched["sponsorship_type"]}
              errorMessage={
                errors["sponsorship_type"] &&
                touched["sponsorship_type"] &&
                errors["sponsorship_type"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="sponsorship_commitment"
              label="Sponsorship Commitment*"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Type sponsorship commitment"
              value={values["sponsorship_commitment"]}
              error={
                errors["sponsorship_commitment"] &&
                touched["sponsorship_commitment"]
              }
              errorMessage={
                errors["sponsorship_commitment"] &&
                touched["sponsorship_commitment"] &&
                errors["sponsorship_commitment"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="location"
              label="Location Address*"
              placeholder="Type location address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["location"]}
              error={errors["location"] && touched["location"]}
              errorMessage={
                errors["location"] && touched["location"] && errors["location"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="contact"
              label="Contact*"
              placeholder="Type contact information"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["contact"]}
              error={errors["contact"] && touched["contact"]}
              errorMessage={
                errors["contact"] && touched["contact"] && errors["contact"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextArea
              border
              vertical
              className={styles.input}
              style={styles.textArea}
              name="sponsorship_purpose"
              label="Sponsorship Purpose"
              placeholder="Type sponsorship purpose"
              maxLength={maxTextAreaLength}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["sponsorship_purpose"]}
              currentLength={values["sponsorship_purpose"].length}
              error={
                errors["sponsorship_purpose"] && touched["sponsorship_purpose"]
              }
              errorMessage={
                errors["sponsorship_purpose"] &&
                touched["sponsorship_purpose"] &&
                errors["sponsorship_purpose"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextArea
              border
              vertical
              className={styles.input}
              style={styles.textArea}
              name="sponsorship_protocol"
              label="Sponsorship Protocol*"
              placeholder="Type sponsorship protocol"
              maxLength={maxTextAreaLength}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["sponsorship_protocol"]}
              currentLength={values["sponsorship_protocol"].length}
              error={
                errors["sponsorship_protocol"] &&
                touched["sponsorship_protocol"]
              }
              errorMessage={
                errors["sponsorship_protocol"] &&
                touched["sponsorship_protocol"] &&
                errors["sponsorship_protocol"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <input
              ref={fileInputRef}
              type="file"
              name="video"
              className={styles.fileInput}
              onChange={onUploadVideo}
              multiple={false}
              accept=".mp4, .m4v, .mov, .mpg, .mpeg, .jpg, .jpeg, .png"
            />
            <VideoCard
              empty={!(values["video"]?.path || values["photo"])}
              video={getVideo()}
              Icon={AddMedia}
              addMessage={"Add photo or video *"}
              onAdd={() => {
                fileInputRef.current.value = ""
                fileInputRef.current.click()
              }}
              deleteVideo={() => {
                setFieldValue("video", null)
                setFieldValue("photo", null)
                handleBlur({
                  target: {
                    name: "video",
                  },
                })
              }}
              anotherUser={false}
              error={
                (errors["video"] && touched["video"]) ||
                (errors["photo"] && touched["photo"])
              }
              errorMessage={
                (errors["video"] && touched["video"] && errors["video"]) ||
                (errors["photo"] && touched["photo"] && errors["photo"])
              }
              supportedFormats=".mp4, .m4v, .mov, .mpg, .mpeg"
              maxSize="40 MB"
              maxSizePhoto="10 MB"
              supportedFormatsPhoto={".jpg, .jpeg, .png"}
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextEditor
              className={styles.description}
              name="description_obj"
              placeholder="Type description"
              text={values["description"]}
              onChange={(value) =>
                handleChange({
                  target: { name: "description_obj", value: value },
                })
              }
              maxLength={maxDescriptionLength}
              label="Description*"
              error={errors["description_obj"] && touched["description_obj"]}
              errorMessage={
                errors["description_obj"] &&
                touched["description_obj"] &&
                errors["description_obj"]
              }
              onBlur={() =>
                handleBlur({
                  target: { name: "description_obj" },
                })
              }
            />
          </Grid>
        </Grid>
        <Grid
          component="div"
          container
          justify="flex-end"
          className={styles.buttonsWrapper}
        >
          <div className={styles.btnsWrap}>
            <Button
              className={styles.btn}
              buttonStyle="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
                scrollOnError(errors)
                setButtonClickedStatus(true)
              }}
              buttonStyle="contained"
              disabled={isSubmitting}
              className={styles.btn}
            >
              Save
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  )
}

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ data }) {
    let emptyDescription =
      '{"blocks":[{"key":"fvm5o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
    return {
      ["description_obj"]: data["description"] || emptyDescription,
      ["company_name"]: data["company_name"] || "",
      ["sponsorship_commitment"]: data["sponsorship_commitment"] || "",
      ["location"]: data["location"] || "",
      ["contact"]: data["contact"] || "",
      ["sponsorship_type"]: data["sponsorship_type"] || "",
      ["description"]: data["description"] || emptyDescription,
      ["sponsorship_purpose"]: data["sponsorship_purpose"] || "",
      ["sponsorship_protocol"]: data["sponsorship_protocol"] || "",
      ["video"]: data["video"] || null,
      ["name"]: data["name"] || "",
      ["photo"]: data["photo"] || null,
    }
  },
  validationSchema: () =>
    object().shape(
      {
        ["video"]: mixed().when(["photo"], {
          is: (photo) => !photo,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "size",
              exclusive: false,

              message: "Sorry, you can upload video with max. size 40 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 40000000
              },
            }),
        }),
        ["photo"]: mixed().when(["video"], {
          is: (video) => !video,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "max",
              exclusive: true,

              message: "Sorry, you can upload photo with max. size 10 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 10000000
              },
            }),
        }),
        ["name"]: string()
          .max(
            32,
            "Please enter a correct name that consists of no more than 32 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter name"),
        ["company_name"]: string().required(
          "Please enter company/organization name",
        ),
        ["sponsorship_commitment"]: string()
          .max(
            32,
            "Please enter a correct sponsorship commitment that consists of no more than 32 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter sponsorship commitment"),
        ["location"]: string()
          .max(
            64,
            "Please enter a correct location that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter location"),
        ["sponsorship_type"]: string().max(
          64,
          "Please select sponsorship type",
        ),
        ["contact"]: string()
          .max(
            100,
            "Please enter a correct contacts that consists of no more than 100 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter contact information"),
        ["sponsorship_purpose"]: string()
          .max(
            maxTextAreaLength,
            `Please enter a correct sponsorship purpose that consists of no more than ${maxTextAreaLength} characters`,
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["sponsorship_protocol"]: string()
          .max(
            maxTextAreaLength,
            `Please enter a correct sponsorship protocol that consists of no more than ${maxTextAreaLength} characters`,
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter opportunity sponsorship protocol"),
        ["description_obj"]: mixed()
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length <= maxDescriptionLength,
            message: `Please enter a correct description culture that consists of no more than ${maxDescriptionLength} characters`,
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length !== 0,
            message: "Please enter description",
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              !isHaveBadWords(
                JSON.parse(val)
                  .blocks.map((p) => p.text)
                  .join(" "),
              ),
            message: `Please don't use bad language`,
            exclusive: false,
          }),
      },
      ["photo", "video"],
    ),
  handleSubmit(values, { setSubmitting, props: { onSave } }) {
    setSubmitting(true)
    let savedValues = { ...values, description: values["description_obj"] }
    delete savedValues["description_obj"]
    onSave(savedValues)
  },
})(SponsorshipForm)

export default FormikApp
