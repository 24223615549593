import React, { useState } from "react"
import classNames from "classnames"
import { Link, useHistory } from "react-router-dom"

import styles from "./index.module.scss"
import CompanyInfoItem from "../CompanyInfoItem/CompanyInfoItem"
import ResourceCard from "../cards/ResourceCard/index.jsx"
import CompanyAddonTypePopup from "../CompanyAddonTypePopup"

import { resourcesTypeCards } from "./helpers"

const CompanyResources = ({ data, userInfo, width, anotherUser }) => {
  const [visiblePopup, setVisiblePopup] = useState(false)
  const history = useHistory()

  const handleClosePopup = () => {
    setVisiblePopup(false)
  }

  const handleNextClick = ({ type }) => {
    history.push(`/resources-edit?type=${type}`)
  }

  const placeholder =
    anotherUser && !data?.length
      ? "No resources yet"
      : data?.length > 0
      ? null
      : "You didn't add any resources yet"

  return (
    <CompanyInfoItem
      headTitle={
        anotherUser ? "Company/Organization Resources" : "My Resources"
      }
      headBtnText={width < 451 ? "Add" : "Add Resource"}
      bodyPlaceholderText={placeholder}
      width={width}
      handleButtonClick={() => {
        setVisiblePopup(true)
      }}
      anotherUser={anotherUser}
    >
      {visiblePopup && (
        <CompanyAddonTypePopup
          width={1185}
          data={resourcesTypeCards}
          title={"Add Resource"}
          handleNextClick={handleNextClick}
          closeAction={handleClosePopup}
        />
      )}
      {data && (
        <React.Fragment>
          <div className={styles.resources}>
            {data.map((resource) => (
              <ResourceCard
                resource={resource}
                key={resource.uuid}
                isProfilePage
              />
            ))}
          </div>

          {data.length === 2 && (
            <div className={styles.showMoreBtn}>
              <Link
                to={`/company/${userInfo.company.uuid}/resources`}
                className={classNames("goldLink")}
              >
                <p>Show all resources</p>
              </Link>
            </div>
          )}
        </React.Fragment>
      )}
    </CompanyInfoItem>
  )
}

export default CompanyResources
