import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { OpportunitySwitch } from "../../forms/OpportinityForms/helpers"
import userServices from "../../services"
import {Redirect, useHistory} from "react-router-dom"
import { useSelector } from "react-redux"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { Link } from "react-router-dom"
import styles from "./OpportunityEditContainer.module.scss"
import { opportunityNameList } from "../../components/Opportunity/heplers"
import classnames from "classnames"
import services from "../../services"
import Popup from "../../components/Popup/Popup"
import Button from "../../components/Button/Button"
import {COMPANY_SEEKER} from "../../helpers/userRoles";
import {paths} from "../../routes/paths";

const OpportunityEditContainer = ({ variant, id, isPrivate }) => {
  const [data, setData] = useState({})
  const [departments, setDepartments] = useState([])
  const history = useHistory()
  const companyName = useSelector((state) => state.user.company.name)
  const companyType = useSelector((state) => state.user.company.type)
  const companyId = useSelector((state) => state.user.company.id)
  const companyUuid = useSelector((state) => state.user.company.uuid)
  const companyPhoto = useSelector((state) => state.user.company.photo)
  const [deletePopupOpened, setDeletePopupOpened] = useState(false)
  const [isPrivateValue, setIsPrivate] = useState(isPrivate === "true")
  const opportunityName = opportunityNameList.find(
    (item) => item.type === variant,
  )?.title;

    const [opportunityPageTitle, setOpportunityPageTitle] = useState(`Opportunity page`);
    const [opportunityEditTitle, setOpportunityEditTitle] = useState(`Editing opportunity`);
    const [createOpportunityWithVariant, setCreateOpportunityWithVariant] = useState(`Creating ${opportunityName} Opportunity`);

    useEffect(() => {
        setOpportunityPageTitle(isPrivateValue ? `Private Opportunity page` : `Opportunity page`);
        setOpportunityEditTitle(isPrivateValue ? `Editing Private opportunity` : `Editing opportunity`);
        setCreateOpportunityWithVariant(isPrivateValue ? `Creating Private ${opportunityName} Opportunity` : `Creating ${opportunityName} Opportunity`);
    }, [isPrivateValue, opportunityName]);

  useEffect(() => {
    setData({
      ...data,
      ["company_name"]: companyName,
      ["company_photo"]: companyPhoto,
    })
  }, [companyName, companyPhoto])

  const onDeleteOpportunity = () => {
    if (id && data?.company?.uuid) {
      services.opportunityServices.deleteOpportunityByUuid(id).then(() => {
        history.push(`/opportunities/${data.company.uuid}`)
      })
    }
  }

  const onSave = (newData) => {
    if (id)
      userServices.opportunityServices
        .updateOpportunity({
          ...data,
          ...newData,
          required_experience: newData["required_experience"],
        })
        .then((data) => {
            pushToNextPage(history, data.data.uuid, isPrivateValue);
        })
    else {
      userServices.opportunityServices
        .createOpportunity({
          ...data,
          ...newData,
          opportunity_type: variant,
          company_id: companyId,
          company_name: companyName,

          department: newData.department,
          required_experience: newData["required_experience"],

          is_private: isPrivate ? "true" : "false",
        })
        .then((data) => {
          if (data.status === 200) {
              pushToNextPage(history, data.data.uuid, isPrivateValue);
          }
        })
    }
  }

  useEffect(() => {
    if (id) {
      userServices.opportunityServices
        .getOpportunity(id)
        .then(({ data }) => {
          if (data.company.id === companyId) {
              setIsPrivate(data.is_private);
              return setData({...data, ["company_name"]: data.company.name})
          } else {
          }
        })
        .then(() => {
          userServices.departmentsServices
            .getOpportunityDepartments()
            .then(({ data }) => {
              const mapperDep = data.map((department) => ({
                id: department.id,
                value: department.name,
              }))
              setDepartments(mapperDep)
            })
        })
    } else {
      userServices.departmentsServices
        .getOpportunityDepartments()
        .then(({ data }) => {
          const mapperDep = data.map((department) => ({
            id: department.id,
            value: department.name,
          }))
          setDepartments(mapperDep)
        })
    }
  }, [])

    if(companyType === COMPANY_SEEKER) return <Redirect to={paths.companyProfile} />
  return (
    <div className={styles.opportunityEditWrapper}>
      {deletePopupOpened && (
        <Popup
          dialog
          subtitle="Do you want to delete this opportunity?"
          button={
            <Button buttonStyle="black" onClick={onDeleteOpportunity}>
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setDeletePopupOpened(false)
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setDeletePopupOpened(false)
          }}
          isCrossIcon={true}
        />
      )}
      <div className={classNames("container", "fullWidth", styles.wrapper)}>
        <div className={styles.formWrapper}>
          {id ? (
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link to={`/opportunity?id=${data?.uuid}`} className={styles.link}>
                  {opportunityPageTitle}
              </Link>
              <Link className={classnames(styles.link, styles.currentLink)}>
                  {opportunityEditTitle}
              </Link>
            </Breadcrumbs>
          ) : (
            <Breadcrumbs className={styles.breadcrumbs}>
              <Link to={`/company/${companyUuid}`} className={styles.link}>
                {companyName}
              </Link>
              <Link className={classnames(styles.link, styles.currentLink)}>
                  {createOpportunityWithVariant}
              </Link>
            </Breadcrumbs>
          )}

          {OpportunitySwitch(variant || (data && data["opportunity_type"]), {
            onSave: onSave,
            onDelete: () => setDeletePopupOpened(true),
            data: data,
            departmentsOptions: departments,
            isPrivate: isPrivateValue,
          })}
        </div>
      </div>
    </div>
  )
}

function pushToNextPage(history, id, isPrivate) {
    const location = isPrivate ? `/opportunity/${id}/share` : `/opportunity?id=${id}`
    history.push(location)
}

export default OpportunityEditContainer
