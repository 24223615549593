import React from "react"
import styles from "./Banner.module.scss"
import ButtonComponent from "../../Button/Button"

const Banner = ({ onReadMoreClick, article = {} }) => (
  <div
    className={styles.wrapper}
    style={{
      background: ` center / cover no-repeat url(${article.image_url})`,
    }}
  >
    <div className={styles.title}>
      <p>{article.title}</p>
    </div>
    <div className={styles.buttonWrapper}>
      <ButtonComponent
        buttonStyle="contained"
        onClick={onReadMoreClick}
        className={styles.readMore}
      >
        Read more
      </ButtonComponent>
    </div>
  </div>
)

export default Banner
