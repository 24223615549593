import React from "react"
import styles from "./ProfileTable.module.scss"
import GetMeKnow from "./components/GetMeKnow/GetMeKnow"
import Skills from "./components/Skills/Skills"
import HigherLearningGroup from "./components/HigherLearningItem/HigherLearningGroup"
import ProfessionText from "./components/ProfessionText/ProfessionText"
import ExperienceText from "./components/ExperienceText/ExperienceText"
import MyStory from "./components/MyStory/MyStory"
import MyInspiration from "./components/MyInspiration/MyInspiration"

const ProfileTable = ({
  getToKnowUpdate,
  myStoryUpdate,
  professionTextUpdate,
  experienceTextUpdate,
  myInspirationUpdate,
  onUpdateSchools,
  data,
  anotherUser,
  onSkillsUpdate,
}) => {
  return (
    <div className={styles.profileTable}>
      <div className={styles.tableItem}>
        <GetMeKnow
          data={data["get_to_know_me"]}
          getToKnowUpdate={getToKnowUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <HigherLearningGroup
          onUpdateSchools={onUpdateSchools}
          institutes={data["schools"]}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <ProfessionText
          data={data["profession_text"]}
          update={professionTextUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <ExperienceText
          data={data["experience_text"]}
          update={experienceTextUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <MyStory
          data={data["my_story"]}
          update={myStoryUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <MyInspiration
          data={data["my_inspiration"]}
          update={myInspirationUpdate}
          anotherUser={anotherUser}
        />
      </div>
      <div className={styles.tableItem}>
        <Skills
          anotherUser={anotherUser}
          data={data["skills"]}
          update={onSkillsUpdate}
        />
      </div>
    </div>
  )
}

export default ProfileTable
