import axios from "./axios"
import queryString from "query-string"

export default {
  getResources: async (requestParams) => {
    const search = queryString.stringify(
      {
        type: "resource",
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/resources?${search}`)
    } catch (error) {
      throw error
    }
  },
}
