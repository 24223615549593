/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { Link, useParams } from "react-router-dom"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import styles from "./GalleryContainer.module.scss"
import PhotoGalleryWrap from "../../components/PhotoGallery/PhotoGalleryWrap"
import services from "../../services"
import { COMPANY_PROVIDER, COMPANY_SEEKER } from "../../helpers/userRoles"

const GalleryContainer = ({ selfGallery, id }) => {
  const userInfo = useSelector((state) => state.user)
  const params = useParams()
  const [photoGalleryData, setPhotoGalleryData] = useState([])
  const [userData, setData] = useState({})

  const breadCrumbLink = selfGallery
    ? "/individual-profile"
    : params.type === "users"
    ? `/user/${id}`
    : `/company/${id}`

  const isCompany =
    photoGalleryData.type === COMPANY_SEEKER ||
    photoGalleryData.type === COMPANY_PROVIDER

  useEffect(() => {
    if (selfGallery) {
      services.userServices
        .getPhotoGallery()
        .then((result) => {
          setPhotoGalleryData(result.data.photos)
        })
        .catch((e) => console.log(e))
    } else {
      services.userServices
        .getPhotoGalleryByTypeAndId(params.type, params.id)
        .then((result) => {
          setPhotoGalleryData(result.data.photos)
          setData({ name: result.data.name })
        })
        .catch((e) => console.log(e))
    }
  }, [])

  const onAddPhotos = (photos) =>
    setPhotoGalleryData([...photos, ...photoGalleryData])

  const onDeletePhoto = (id) =>
    setPhotoGalleryData(photoGalleryData.filter((photo) => photo.uuid !== id))

  return (
    <div className={classNames("container", styles.gallery)}>
      <Breadcrumbs className={styles.breadcrumbs}>
        <Link
          to={breadCrumbLink}
          className={classNames(styles.link, "text16r")}
        >
          {selfGallery
            ? userInfo.role !== "individual"
              ? userInfo.company.name
              : isCompany
              ? userData.name
              : "My profile"
            : userData.name}
        </Link>

        <Link
          className={classNames(styles.link, "text16r", styles.currentLink)}
        >
          Photos
        </Link>
      </Breadcrumbs>

      <PhotoGalleryWrap
        className={styles.photoGallery}
        type="full"
        data={photoGalleryData}
        onAddPhotos={onAddPhotos}
        onDeletePhoto={onDeletePhoto}
        editable={selfGallery}
      />
    </div>
  )
}

export default GalleryContainer
