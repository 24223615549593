import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./MessengerHeader.module.scss"
import defaultAvatar from "../../images/defaultUserAvatar.png"
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu"
import { ReactComponent as Arrow } from "../../images/icons/arrow-top.svg"
import { Link } from "react-router-dom"
import { paths } from "../../routes/paths"
import Popup from "../Popup/Popup"
import Button from "../Button/Button"
import services from "../../services"
import { useSelector } from "react-redux"
import { setBlocked } from "../../containers/ChatsContainer/helpers/actionCreators"
import { deleteChatRoom } from "../../containers/ChatsContainer/helpers"
import { setRequestResult } from "../../containers/ChatsContainer/helpers/actionCreators"

const MessengerHeader = ({
  image,
  name,
  subject,
  type,
  uuid,
  handleMenuClick,
  handleBackButton,
  state,
  dispatch,
  history,
}) => {
  const [visiblePopup, setVisiblePopup] = useState("")
  const userInfo = useSelector((state) => state.user)
  const isBlocked = Boolean(state.activeChat.blocked_by)

  const actions = [
    {
      name:
        isBlocked && isBlockedByCurrentUser(state.activeChat, userInfo)
          ? "Unblock user"
          : "Block user",
      tooltip:
        !isBlockedByCurrentUser(state.activeChat, userInfo) &&
        "This user has already blocked chat with you",
      click: () => {
        if (isBlockedByCurrentUser(state.activeChat, userInfo)) {
          setVisiblePopup("block")
        }
      },
    },
    {
      name: "Delete chat",
      click: () => {
        if (isBlockedByCurrentUser(state.activeChat, userInfo)) {
          setVisiblePopup("delete")
        }
      },
    },
  ]

  const handleBlock = () => {
    services.chatServices
      .blockChatRoom(
        state.activeChat.uuid,
        !Boolean(state.activeChat.blocked_by),
      )
      .then((res) => {
        dispatch(setBlocked(res.data.blocked_by || null))
      })
    setVisiblePopup("")
  }

  const handleDelete = () => {
    const newChatRooms = deleteChatRoom(state.dialogList, state.activeChat.uuid)
    const activeChatIndex = state.dialogList.findIndex(
      (chat) => chat.uuid === state.activeChat.uuid,
    )

    services.chatServices.deleteChatRoom(state.activeChat.uuid).then(() => {
      dispatch(setRequestResult({ dialogList: newChatRooms }))

      let nextPath = ""

      if (state.dialogList.length > activeChatIndex + 1) {
        nextPath = newChatRooms[activeChatIndex].uuid
      } else if (
        state.dialogList.length === activeChatIndex + 1 &&
        activeChatIndex - 1 >= 0
      ) {
        nextPath = newChatRooms[activeChatIndex - 1].uuid
      }

      history.push(paths.chats(nextPath))
    })
    setVisiblePopup("")
  }

  return (
    <div className={styles.filterWrap}>
      <div className={styles.container}>
        <span className={styles.arrow} onClick={handleBackButton}>
          <Arrow />
        </span>
        <Link to={generateLink(type, uuid)} className={subject?.type === "freelancer" ? styles.withSubname : styles.imgWrap}>
          <div
            className={styles.userBar}
            style={{ backgroundImage: `url(${image || defaultAvatar})` }}
          />
          <p className={styles.name}>{name}</p>
        </Link>
        {subject?.type === "freelancer" && (
          <Link
            to={generateLink(subject.type, subject.external_id)}
            className={styles.subname}
          >
            <p className={styles.name}>{"about " + subject.name}</p>
          </Link>
        )}

        <div className={styles.menuWrap} onClick={() => handleMenuClick()}>
          <ThreeDotsMenu actions={actions} />
        </div>
      </div>

      {visiblePopup === "block" && (
        <Popup
          dialog
          subtitle="Are you sure?"
          text={
            isBlocked
              ? "Do you really want to unblock this user? You will be able to exchange messages with this user again."
              : "Do you really want to block this user? You will not be able to exchange messages with this user."
          }
          textStyle={{ margin: "0 16px", lineHeight: "24px" }}
          button={
            <Button buttonStyle="black" onClick={handleBlock}>
              {isBlocked ? "Unblock" : "Block"}
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setVisiblePopup(false)
              }}
            >
              Cancel
            </Button>
          }
          closeAction={() => {
            setVisiblePopup("")
          }}
          isCrossIcon={true}
        />
      )}

      {visiblePopup === "delete" && (
        <Popup
          dialog
          subtitle="Are you sure?"
          text={"Do you really want to delete this chat? All data will be lost"}
          textStyle={{ margin: "0 16px", lineHeight: "24px" }}
          button={
            <Button buttonStyle="black" onClick={handleDelete}>
              Delete
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setVisiblePopup(false)
              }}
            >
              Cancel
            </Button>
          }
          closeAction={() => {
            setVisiblePopup("")
          }}
          isCrossIcon={true}
        />
      )}
    </div>
  )
}

MessengerHeader.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleMenuClick: PropTypes.func,
}

MessengerHeader.defaultProps = {
  handleMenuClick: () => {
    console.log("handleMenuClick")
  },
}

const generateLink = (type, uuid) => {
  switch (type) {
    case "company":
      return paths.selectedCopmanyProfile(uuid)
    case "user":
      return `/user/${uuid}`
    case "freelancer":
      return paths.selectedFreelancerProfile(uuid)
    default:
      return '/'
  }
}

const isBlockedByCurrentUser = ({ blocked_by }, userInfo) => {
  if (Boolean(blocked_by)) {
    if (userInfo.uuid === blocked_by || userInfo.company.uuid === blocked_by) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export default MessengerHeader
