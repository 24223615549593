import React from "react"
import Dropdown from "../../../../Dropdown/Dropdown"
import { options } from "./options"
import { setCompanyActivityType } from "../../useReducer/actionCreators"
import useOption from "./useOption"

const CompanyActivityTypeDropdown = ({
  externalDispatch,
  companyRoleType,
  selectedType,
}) => {
  const [option, setOption] = useOption(companyRoleType, selectedType)
  return (
    <Dropdown
      label="Affiliation"
      id="company_activity_type_dropdown"
      options={options.filter((option) => option.role === companyRoleType)}
      value={option}
      disabled={!companyRoleType}
      onChange={(option) => {
        setOption(option)
        externalDispatch(setCompanyActivityType(option.id))
      }}
    />
  )
}
export default CompanyActivityTypeDropdown
