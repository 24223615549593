import {
  SET_SEARCH_QUERY,
  SET_SEARCH_PARAMS,
  SET_REQUEST_RESULT,
  ADD_SCROLL_RESULT,
  SET_SIDE_ARTICLES,
  SET_TAB_FILTER,
  SET_FORM_FILTERS,
  RESET_FORM_FILTERS,
  REMOVE_ARTICLE_TYPE,
  REMOVE_FROM_DATE,
  REMOVE_TO_DATE,
} from "./reducer"

export const setSearchQuery = (query) => ({
  type: SET_SEARCH_QUERY,
  payload: { query },
})
export const setSearchParams = (locationSearchGroup) => ({
  type: SET_SEARCH_PARAMS,
  payload: { locationSearchGroup },
})
export const setRequestResult = (data, locationSearchGroup) => ({
  type: SET_REQUEST_RESULT,
  payload: { data, locationSearchGroup },
})
export const addScrollResult = (data) => ({
  type: ADD_SCROLL_RESULT,
  payload: { data },
})
export const setSideArticles = (isQuery, articles) => ({
  type: SET_SIDE_ARTICLES,
  payload: { isQuery, articles },
})
export const setTabFilter = (articleType) => ({
  type: SET_TAB_FILTER,
  payload: { articleType },
})
export const setFormFilters = (filters) => ({
  type: SET_FORM_FILTERS,
  payload: { filters },
})
export const resetFormFilters = () => ({
  type: RESET_FORM_FILTERS,
  payload: null,
})
export const removeArticleType = () => ({
  type: REMOVE_ARTICLE_TYPE,
  payload: null,
})
export const removeFromDate = () => ({
  type: REMOVE_FROM_DATE,
  payload: null,
})
export const removeToDate = () => ({
  type: REMOVE_TO_DATE,
  payload: null,
})
