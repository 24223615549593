export const options = [
  {
    id: "",
    value: "All Types",
  },
  {
    id: "advice",
    value: "Advice",
  },
  {
    id: "etiquette",
    value: "Etiquette",
  },
  {
    id: "funding",
    value: "Funding",
  },
  {
    id: "meetup",
    value: "Meetup",
  },
  {
    id: "unspoken_rules",
    value: "Unspoken Rules",
  },
]
