import React from "react"
import { useHistory } from "react-router-dom"
import classnames from "classnames"

import styles from "./index.module.scss"
import OpportunityCard from "../../../components/cards/OpportunityCard"

const Companies = ({ data, removeFromFavorites }) => {
  const history = useHistory()

  return (
    <div className={classnames(styles.listWrap, styles.opportunityList)}>
      {data.map((item) => (
        <OpportunityCard
          key={item.uuid}
          uuid={item.uuid}
          type={item.opportunity_type}
          avatar={item.company_photo}
          description={item.description}
          location={{ city: item.location }}
          caption={item.name}
          timestamp={new Date(item.created_at)}
          onClick={() => history.push(`/opportunity?id=${item.uuid}`)}
          classes={styles.opportunityCardWrapper}
          isFavorite={item.favorite}
          removeFromFavorites={removeFromFavorites}
        />
      ))}
    </div>
  )
}

export default Companies
