import React from "react"
import AuxPortal from "../../AuxPortal/AuxPortal"
import AutoCompleteField from "../../AutoCompleteField/AutoCompleteField"
import Dropdown from "../../Dropdown/Dropdown"
import { OpportunityOptions } from "../../Opportunity/heplers"
import classNames from "classnames"
import DatePicker from "../../DatePicker/DatePicker"
import Button from "../../Button/Button"
import makeStyles from "@material-ui/core/styles/makeStyles"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"

import styles from "../ProspectsFilter/ProspectsFilter.module.scss"

const useStyles = makeStyles({
  paper: {
    borderRadius: "2px",
  },
})

const MyApplicationFilter = ({
  activeFilters,

  handleChangeFilter,
  locationsList,
  handleClearFilter,
  handleApplyFilter,
  handleChangeLocation,
  locationsListLoading,
  handleChangeDateFilter,
  handleChangeCompany,
  companiesList,
  companiesListLoading,
}) => {
  const { width } = useWindowHeightWidth()

  const getOpportunityTypeDropDownValue = () => {
    return (
      OpportunityOptions.find(
        (item) => item.dbName === activeFilters.opportunity_type,
      ) || OpportunityOptions[0]
    )
  }
  const classes = useStyles()
  return (
    <AuxPortal initialOpen={false}>
      <div className={styles.firstSection}>
        <div className={styles.filterItem}>
          <Dropdown
            withoutError
            id={"1"}
            fieldStyle="gray"
            label="Opportunity Type"
            options={OpportunityOptions}
            onBlur={() => {}}
            value={getOpportunityTypeDropDownValue()}
            onChange={(value) => {
              handleChangeFilter({
                target: { name: "opportunity_type", value: value.dbName },
              })
            }}
          />
        </div>
        <div className={classNames(styles.filterItem)}>
          <DatePicker
            withoutError
            calendarWidth={width > 1440 ? "400px" : "328px"}
            onChange={handleChangeDateFilter}
            initialFrom={activeFilters.from}
            initialTo={activeFilters.to}
          />
        </div>
      </div>
      <div className={styles.firstSection}>
        <div className={styles.filterItem}>
          <AutoCompleteField
            withoutError
            value={activeFilters.company || ""}
            options={companiesList}
            maxLength={32}
            placeholder={"Company"}
            getOptionLabel={(option) => option}
            classes={classes}
            fieldStyle={"dark"}
            loading={companiesListLoading}
            label={"Company"}
            onInputChange={() => {
              handleChangeCompany(null, null)
            }}
            onChange={handleChangeCompany}
          />
        </div>
        <div className={styles.filterItem}>
          <AutoCompleteField
            withoutError
            value={activeFilters.location || ""}
            options={locationsList}
            placeholder={"Enter location"}
            getOptionLabel={(option) => option}
            classes={classes}
            fieldStyle={"dark"}
            maxLength={64}
            loading={locationsListLoading}
            label={"Location"}
            onInputChange={() => {
              handleChangeLocation(null, null)
            }}
            onChange={handleChangeLocation}
          />
        </div>
      </div>
      <div className={styles.filterButtonsWrap}>
        <div className={styles.filterButtonWrap}>
          <Button
            buttonStyle="outlined"
            className={styles.filterButton}
            onClick={handleClearFilter}
          >
            Clear
          </Button>
        </div>
        <div className={styles.filterButtonWrap}>
          <Button
            buttonStyle="contained"
            className={styles.filterButton}
            onClick={handleApplyFilter}
          >
            Apply
          </Button>
        </div>
      </div>
    </AuxPortal>
  )
}

export default MyApplicationFilter
