import React from "react"
import { useLocation, useParams } from "react-router-dom"

import styles from "./index.module.scss"
import CommunityQuestionsContainer from "../../containers/CommunityQuestionsContainer"
import services from "../../services"

const CommunityCompanyQuestionsPage = () => {
  const location = useLocation()
  const params = useParams()

  const currentPage = getCurrentPage(location)

  const [state, setState] = React.useState({
    isLoading: false,
  })

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.communityServices
      .getCompanyQuestions(params.uuid, {
        page: currentPage,
      })
      .then((result) => {
        setState({
          ...result.data,
          isLoading: false,
        })
      })
  }, [currentPage, params.uuid])

  const getBreadcrumbsPaths = React.useCallback(() => {
    const paths = [
      {
        text: "Company/Organization Questions",
      },
    ]

    if (state.user_uuid) {
      paths.unshift({
        link: `/user/${state.user_uuid}`,
        text: state.user_name,
      })
    }

    if (state.company_uuid) {
      paths.unshift({
        link: `/company/${state.company_uuid}`,
        text: state.company_name,
      })
    }

    return paths
  }, [state])

  return (
    <div className={styles.pageWrap}>
      <CommunityQuestionsContainer
        breadcrumbs={getBreadcrumbsPaths()}
        pageTitle="Company/Organization Questions"
        currentPage={currentPage}
        {...state}
      />
    </div>
  )
}

export default CommunityCompanyQuestionsPage

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}

// const getBreadcrumbsPaths = (state) => {
//   const paths = [{
//     text: "Questions",
//   }]

//   if (state.user_uuid) {
//     paths.unshift({
//       link: `/user/${state.user_uuid}`,
//       text: state.user_name
//     })
//   }

//   return paths
// }
