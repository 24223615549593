import {
  TOGGLE_COLLAPSE,
  SET_WHOLE_STATE,
  SET_QUESTION_TYPE,
  SET_CREATOR_TYPE,
  SET_FROM_DATE,
  SET_TO_DATE,
  RESET_FORM,
} from "./reducer"

export const toggleCollapse = () => ({
  type: TOGGLE_COLLAPSE,
  payload: null,
})
export const setWholeState = (searchParamGroup) => ({
  type: SET_WHOLE_STATE,
  payload: { searchParamGroup },
})
export const setQuestionType = (questionType) => ({
  type: SET_QUESTION_TYPE,
  payload: { questionType },
})
export const setCreatorType = (creatorType) => ({
  type: SET_CREATOR_TYPE,
  payload: { creatorType },
})
export const setFromDate = (fromDate) => ({
  type: SET_FROM_DATE,
  payload: { fromDate },
})
export const setToDate = (toDate) => ({
  type: SET_TO_DATE,
  payload: { toDate },
})
export const resetForm = () => ({
  type: RESET_FORM,
  payload: null,
})
