import React from "react"
import moment from "moment"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./UserResponseCard.module.scss"
import { opportunitiesTypes } from "../Opportunity/heplers"
import { generateInfo } from "./helpers"
import ApproveRejectProspect from "../ApproveRejectProspect/ApproveRejectProspect"

const UserResponseCardItem = ({ card, handleApprove, handleReject }) => {
  return (
    <div className={styles.infoWrap}>
      <p className={styles.dateof}>
        {moment(card.created_at).format("DD/MM/YYYY")}
      </p>

      <div className={styles.prospectInfo}>
        <span
          className={classnames(styles.type, {
            [styles.job]:
              card.opportunity_type === opportunitiesTypes.careers_executive,
            [styles.mentorship]:
              card.opportunity_type === opportunitiesTypes.mentoring,
            [styles.project]:
              card.opportunity_type === opportunitiesTypes.project,
            [styles.funding]:
              card.opportunity_type === opportunitiesTypes.funding,
            [styles.sponsorship]:
              card.opportunity_type === opportunitiesTypes.sponsorship,
            [styles.advisoryBoardProtocol]:
              card.opportunity_type ===
              opportunitiesTypes.advisoryBoardProtocol,
            [styles.boardOfDirectorsOpportunity]:
              card.opportunity_type ===
              opportunitiesTypes.boardOfDirectorsOpportunity,
            [styles.supplier]:
              card.opportunity_type === opportunitiesTypes.careers_professional,
          })}
        >
          {card.opportunity_type.split("_").join(" ")}
        </span>

        <Link to={`/opportunity?id=${card.opportunity_uuid}`}>
          <span className={styles.rank}>
            {` • ${card.name} • `}
            {generateInfo(card.opportunity_type, card)}
          </span>
          <span></span>
        </Link>
      </div>

      <ApproveRejectProspect
        id={card.prospect_uuid}
        handleReject={handleReject}
        handleApprove={handleApprove}
        isApproved={card.is_applied}
      />
    </div>
  )
}

UserResponseCardItem.propTypes = {
  card: PropTypes.objectOf({
    created_at: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    opportunity_type: PropTypes.string.isRequired,
    opportunity_uuid: PropTypes.string.isRequired,
    compensation: PropTypes.string,
    duration: PropTypes.string,
    is_applied: PropTypes.bool.isRequired,
  }),
  hendleHeartClick: PropTypes.func,
  hendleRejectClick: PropTypes.func,
}

UserResponseCardItem.defaultProps = {
  hendleRejectClick: () => {},
  hendleRejectClick: () => {},
}

export default UserResponseCardItem
