import React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import styles from "./index.module.scss"

const PublishingTerm = ({ publishTimestamp }) => {
  const now = moment()
  const publishDate = moment(publishTimestamp)
  let term = ""
  if (now.diff(publishDate, "seconds") < 60) {
    term = "Recently"
  } else if (now.diff(publishDate, "minutes") < 60) {
    term = `${now.diff(publishDate, "minutes")} m ago`
  } else if (now.format("MM DD YYYY") === publishDate.format("MM DD YYYY")) {
    term = `${now.diff(publishDate, "hours")} h ago`
  } else if (now.diff(publishDate, "days") === 1) {
    term = "Yesterday"
  } else if (now.diff(publishDate, "days") <= 6) {
    term = publishDate.format("dddd")
  } else if (now.format("YYYY") === publishDate.format("YYYY")) {
    term = publishDate.format("MMM D")
  } else {
    term = publishDate.format("MMM D, 'YY")
  }
  return <span className={styles.text}>{term}</span>
}
PublishingTerm.propTypes = {
  /** Publish date from backend  */
  publishTimestamp: PropTypes.string.isRequired,
}
export default PublishingTerm
