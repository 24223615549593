import React from "react"
import moment from "moment"
import TrueTag from "../../../../components/elements/TrueTag"
import { getArticleTypeName } from "./helpers"
import {
  removeArticleType,
  removeFromDate,
  removeToDate,
} from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const TagWrapper = ({ classes, requestResultGroup, dispatch }) => {
  const { article_type, from, to } = requestResultGroup
  return (
    <div className={classes}>
      {Boolean(article_type) && (
        <TrueTag
          classes={styles.tag}
          title={getArticleTypeName(article_type)}
          onClick={() => dispatch(removeArticleType())}
        />
      )}
      {Boolean(from) && (
        <TrueTag
          classes={styles.tag}
          title={`From: ${moment(from).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeFromDate())}
        />
      )}
      {Boolean(to) && (
        <TrueTag
          classes={styles.tag}
          title={`To: ${moment(to).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeToDate())}
        />
      )}
    </div>
  )
}
export default TagWrapper
