import { useReducer, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { reducer } from "./reducer"
import { setSearchParams, setRequestResult } from "./actionCreators"
import opportunityServices from "../../../services/opportunityServices"
import { paths } from "../../../routes/paths"

const useSearch = () => {
  const history = useHistory()
  const location = useLocation()
  const [state, dispatch] = useReducer(reducer, {
    searchParamGroup: {
      query: "",
      ...queryString.parse(location.search),
    },
    requestResultGroup: {
      opportunityList: [],
      pageCount: 0,
      isFetching: true,
    },
  })
  useEffect(() => {
    const locationSearchGroup = queryString.parse(location.search)
    dispatch(setSearchParams(locationSearchGroup))
    opportunityServices
      .getOpportunities(locationSearchGroup)
      .then(({ data }) => {
        dispatch(setRequestResult(data, locationSearchGroup))
        window.scrollTo(0, 0)
      })
  }, [location.search])
  useEffect(() => {
    history.replace(paths.opportunitySearch(state.searchParamGroup))
  }, [state.searchParamGroup])
  return [state, dispatch]
}
export default useSearch
