import React, { useRef, useEffect, useState } from "react"
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
  PlayToggle,
  BigPlayButton,
} from "video-react"
import { throttle } from "../../helpers"

import PropTypes from "prop-types"

const VideoPlayer = ({ source, playButton, onPlayerStateChange, ...attrs }) => {
  const playerRef = useRef(null)

  const subscribeForStateUpdate = (state) => {
    if (typeof onPlayerStateChange === "function") {
      onPlayerStateChange({ isEnded: state.ended, isPaused: state.paused })
    }
  }

  useEffect(() => {
    if (playerRef) {
      playerRef.current.subscribeToStateChange(
        throttle(subscribeForStateUpdate, 250),
      )
    }
  }, [playerRef])

  return (
    <Player ref={playerRef} {...attrs} aspectRatio={"16:9"}>
      <source src={source} />
      {playButton && <BigPlayButton position="center" />}
      <ControlBar autoHide>
        <PlayToggle />

        <ReplayControl seconds={10} order={1.1} />
        <ForwardControl seconds={30} order={1.2} />
        <CurrentTimeDisplay order={4.1} />
        <TimeDivider order={4.2} />
        <PlaybackRateMenuButton rates={[5, 2, 1, 0.5, 0.1]} order={7.1} />
        <VolumeMenuButton />
      </ControlBar>
    </Player>
  )
}

VideoPlayer.propTypes = {
  /** Url to video */
  source: PropTypes.string.isRequired,
  playButton: PropTypes.bool,
  onPlayerStateChange: PropTypes.func,
}

VideoPlayer.defaultProps = {
  playButton: true,
}

export default VideoPlayer
