const companyProviderSubValue = [
  { id: "1", value: "Finance", databaseName: "finance" },
  {
    id: "2",
    value: "Human Resources / Diversity & Inclusion",
    databaseName: "human_resources_diversity_inclusion",
  },
  {
    id: "3",
    value: "Marketing / Communications",
    databaseName: "marketing_communications",
  },
  {
    id: "4",
    value: "Operations / Procurement",
    databaseName: "operations_procurement",
  },
  { id: "5", value: "Supplier Diversity", databaseName: "supplier_diversity" },
  { id: "6", value: "Engineering / R&D", databaseName: "engineering_rd" },
  { id: "7", value: "Sales", databaseName: "sales" },
]

export const companyProviderRoleOptions = [
  {
    id: "27",
    companyType: "company_provider",
    value: "Corporation",
    databaseName: "corporations",
    subValue: [],
  },
  {
    id: "28",
    companyType: "company_provider",
    value: "Diverse Business",
    databaseName: "diverse_business",
    subValue: [],
  },
  {
    id: "34",
    companyType: "company_provider",
    value: "Education",
    databaseName: "dc_education",
    subValue: [
      {
        id: "340",
        databaseName: "hbcus",
        value: "HBCU",
      },
      {
        id: "341",
        databaseName: "professional_trade",
        value: "Professional Trade",
      },
      {
        id: "342",
        databaseName: "university",
        value: "University",
      },
    ],
  },
  {
    id: "14",
    companyType: "company_provider",
    value: "Financial Services",
    databaseName: "financial_organizations",
    subValue: [
      { id: "15", value: "Banking", databaseName: "banking" },
      {
        id: "16",
        value: "Angel investors",
        databaseName: "angel_investors",
      },
      { id: "17", value: "Debt Financing", databaseName: "debt_financing" },
      {
        id: "18",
        value: "Working Capital Financing",
        databaseName: "working_capital_financing",
      },
      {
        id: "19",
        value: "Merger or Acquisition",
        databaseName: "mergers_and_acquisitions",
      },
      { id: "20", value: "Private Equity", databaseName: "private_acquity" },
      {
        id: "21",
        value: "Wealth Management",
        databaseName: "wealth_management",
      },
      { id: "22", value: "Venture Capital", databaseName: "venture_capital" },
    ],
  },
  {
    id: "44",
    companyType: "company_provider",
    value: "Nonprofit",
    databaseName: "nonprofit",
    subValue: [],
  },
]
