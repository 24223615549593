export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    // image.src = `${url}?${Math.random()}`;
    image.src = url
    image.addEventListener("load", () => resolve(image))
    image.addEventListener("error", (error) => reject(error))
    image.setAttribute("crossOrigin", "anonymous")
  })

export const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener("load", () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

export const getRoundedCanvas = (sourceCanvas) => {
  const canvas = document.createElement("canvas")
  const context = canvas.getContext("2d")
  const width = sourceCanvas.width
  const height = sourceCanvas.height

  canvas.width = width
  canvas.height = height
  context.imageSmoothingEnabled = true
  context.drawImage(sourceCanvas, 0, 0, width, height)
  context.globalCompositeOperation = "destination-in"
  context.beginPath()
  context.arc(
    width / 2,
    height / 2,
    Math.min(width, height) / 2,
    0,
    2 * Math.PI,
    true,
  )
  context.fill()
  return canvas
}

export const resizedImage = (image) => {
  try {
    const canvas = document.createElement("canvas")
    const ctx = canvas.getContext("2d")
    ctx.drawImage(image, 0, 0)

    if (image.width > image.height) {
      if (image.width > 397) {
        image.height *= 397 / image.width
        image.width = 397
      }
    } else if (image.height > 397) {
      image.width *= 397 / image.height
      image.height = 397
    } else if (image.height === image.width) {
      image.height = 397
      image.width = 397
    } else if (image.width <= 397 && image.height !== image.width) {
      image.height *= 260 / image.width
      image.width = 320
    }

    canvas.width = image.width
    canvas.height = image.height
    ctx.drawImage(image, 0, 0, image.width, image.height)

    const dataurl = canvas.toDataURL("image/jpeg", 1)
    return dataurl
  } catch (error) {
    console.log(error)
  }
}
