/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import classNames from "classnames"

import styles from "./ReportContainer.module.scss"
import reportServices from "../../services/reportServices"
import ReportCard from "../../components/cards/ReportCard"
import { INDIVIDUAL } from "../../helpers/userRoles"
import { setNotifications } from "../../actions/notifications/notificationsActions"
import notificationsServices from "../../services/notificationsServices"
import {
  getReportOnPhoto,
  getReportToPhoto,
} from "../../components/Reports/ReportsList/ReportsList"
import ContentWithSidebars from "../../components/templates/ContentWithSidebars"

const ReportContainer = () => {
  const [report, setReport] = useState(null)

  const dispatch = useDispatch()

  const { uuid } = useParams()
  const getReport = (uuid) =>
    reportServices
      .getReport(uuid)
      .then(({ data }) => {
        setReport(data)
      })
      .catch(console.log)

  const getNotif = () => {
    notificationsServices
      .getNotificationsAdmin()
      .then(({ data }) => {
        dispatch(
          setNotifications({
            system_notifications: data.system_notifications_count,
          }),
        )
      })
      .catch(console.log)
  }

  useEffect(() => {
    if (report?.uuid) {
      reportServices
        .readReport({
          report_id: report.uuid,
          reporter_id: report.reporter.uuid,
        })
        .catch(console.log)
    }
  }, [report])

  useEffect(() => {
    getReport(uuid)
    getNotif()
  }, [uuid])

  return (
    <ContentWithSidebars bottomSidebar={() => {}}>
      <div className={styles.reports}>
        {report && (
          <ReportCard
            createdAt={report.created_at}
            title={report.title}
            toUserType={INDIVIDUAL}
            body={report.description}
            onUserType={
              report?.reported?.company?.company_type ||
              report.reported.account_type
            }
            toUserName={
              report.reporter.company?.name || report.reporter.full_name
            }
            onUserName={
              report.reported.company?.name || report.reported.full_name
            }
            toUserPhoto={getReportToPhoto(report)}
            onUserPhoto={getReportOnPhoto(report)}
            key={report.uuid}
            reportLink={`/reports/${report.uuid}`}
            email={report.reported.email}
            onUserLink={
              report.reported.account_type === INDIVIDUAL
                ? `/user/${report.reported.uuid}`
                : `/company/${report.reported.company.uuid}`
            }
            toUserLink={
              report.reporter.account_type === INDIVIDUAL
                ? `/user/${report.reporter.uuid}`
                : `/company/${report.reporter?.company?.uuid || ""}`
            }
          />
        )}
      </div>
    </ContentWithSidebars>
  )
}

export default ReportContainer
