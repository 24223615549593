import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"

const PageTopFilter = ({ options, value, onChange }) => {
  return (
    <div className={styles.filterWrap}>
      {options.map((option) => (
        <div
          key={option.value}
          className={classnames(
            styles.filterItem,
            option.value === value && styles.filterItemSelected,
          )}
          onClick={() => option.value !== value && onChange(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  )
}

PageTopFilter.defaultProps = {
  onChange: (value) => console.log(value),
}

PageTopFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default PageTopFilter
