import React from "react"

import styles from "./index.module.scss"

const CommunityGuidelinePage = () => {
  return (
    <div className={styles.pageWrap}>
      <div className="container">
        <div className={styles.pageContent}>
          <h1>Bridge2Technologies (B2T) Community Guidelines</h1>

          <div className={styles.pageContentSection}>
            <p>
              Bridge2Technologies (B2T) was created for students, individuals,
              companies and organizations to explore relevant and relatable
              opportunities, access critical resources, and develop valuable
              connections. Our community guidelines are created to ensure B2T
              members and partners are able to network as well as be productive
              and successful in an appropriate and professional manner. These
              guidelines will continue to be developed as our community grows.
            </p>
            <p>
              We’re asking B2T members to act respectful and responsible. If you
              see inappropriate behavior in profiles, chats, posts and general
              interactions please report it to us. We will make every effort to
              monitor and address inappropriate content and language. However,
              your input will support our efforts to maintain a great
              professional community.
            </p>
            <p>
              A violation of our policies may result in us taking legal action
              that’s consistent with the violation and members behavior. We also
              reserve the right to remove content and close inappropriate or
              mismanaged accounts. For questions or more information, please
              contact our platform administrator. Education, economic equality
              and pipeline connectivity are our number one goals.
            </p>
          </div>

          <div className={styles.pageContentSection}>
            <h3>Professional Standards:</h3>

            <ul>
              <li>
                Be inclusive. We welcome and support students, professionals,
                companies and organizations from different industries and
                locations.
              </li>
              <li>
                Be considerate. B2T consists of a diverse ecosystem of members.
                We ask that you be considerate of your fellow B2T members’ time.
              </li>
              <li>
                Be respectful. We won't all agree about everything, however,
                disagreement is no excuse for disrespectful behavior. We will
                not tolerate personal attacks. Please remember to be respectful
                and considerate of other members.
              </li>
            </ul>
          </div>

          <div className={styles.pageContentSection}>
            <h3>Authentic Connections:</h3>

            <ul>
              <li>
                Understand your audience. Saying the right thing at the right
                time matters.
              </li>
              <li>
                Express interest with persistence not constant messages. We
                suggest the golden rule: treat your fellow B2T member how you
                would like to be treated.
              </li>
              <li>Make sure your profile is complete and authentic.</li>
            </ul>
          </div>

          <div className={styles.pageContentSection}>
            <h3>Keep Things Legal:</h3>

            <ul>
              <li>
                B2T should not be used for illegal activities, promoting illegal
                products, or violating the rights of other members.
              </li>
              <li>
                Don't use this platform to commit fraud or to try to trick other
                members by fraudulent behavior.
              </li>
              <li>
                Please also do not intentionally distribute viruses, worms, or
                other software that can destroy or interrupt other member’s data
                or computer devices.
              </li>
            </ul>
          </div>

          <div className={styles.pageContentSection}>
            <h3>Unauthorized Access and Use:</h3>

            <ul>
              <li>
                Members may not access B2T except through the interfaces
                intentionally provided by Bridge2Technologies.
              </li>
              <li>
                You may access B2T only in the manner that we expressly
                authorize.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityGuidelinePage
