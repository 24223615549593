import React from "react"
import moment from "moment"
import TrueTag from "../../../../components/elements/TrueTag"
import {
  getResourceTypeName,
  getEventTypeName,
  getAccessTypeName,
} from "./helpers"
import {
  removeResourceType,
  removeEventType,
  removeAccessType,
  removeFromDate,
  removeToDate,
  removeLocation,
  removeFreeOnly,
} from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const TagWrapper = ({ classes, searchParamGroup, dispatch }) => {
  const {
    resource_type,
    resource_sub_type,
    event_type,
    from,
    to,
    location,
    free_only,
  } = searchParamGroup
  return (
    <div className={classes}>
      {Boolean(resource_type) && (
        <TrueTag
          classes={styles.tag}
          title={getResourceTypeName(resource_type)}
          onClick={() => dispatch(removeResourceType())}
        />
      )}
      {Boolean(resource_sub_type) && (
        <TrueTag
          classes={styles.tag}
          title={getEventTypeName(resource_sub_type)}
          onClick={() => dispatch(removeEventType())}
        />
      )}
      {Boolean(from) && (
        <TrueTag
          classes={styles.tag}
          title={`From: ${moment(from).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeFromDate())}
        />
      )}
      {Boolean(to) && (
        <TrueTag
          classes={styles.tag}
          title={`To: ${moment(to).format("MM/DD/YYYY")}`}
          onClick={() => dispatch(removeToDate())}
        />
      )}
      {Boolean(event_type) && (
        <TrueTag
          classes={styles.tag}
          title={getAccessTypeName(event_type)}
          onClick={() => dispatch(removeAccessType())}
        />
      )}
      {Boolean(location) && (
        <TrueTag
          classes={styles.tag}
          title={location}
          onClick={() => dispatch(removeLocation())}
        />
      )}
      {Boolean(free_only) && (
        <TrueTag
          classes={styles.tag}
          title={"Free only"}
          onClick={() => dispatch(removeFreeOnly())}
        />
      )}
    </div>
  )
}
export default TagWrapper
