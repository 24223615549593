/* eslint-disable no-useless-computed-key */
import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import { withFormik } from "formik"
import { mixed, object, string } from "yup"
import { v4 as uuidv4 } from "uuid"
import { Prompt, useHistory } from "react-router-dom"

import Input from "../../components/Input/Input"
import Grid from "@material-ui/core/Grid"
import Dropdown from "../../components/Dropdown/Dropdown"
import VideoCard from "../../components/Videos/VideoCard/VideoCard"
import ButtonComponent from "../../components/Button/Button"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"
import { readFile } from "../../helpers/imageHelpers"
import { getVideoDuration, objectComparison } from "../../helpers"
import styles from "./EditStyles.module.scss"
import avatar from "../../images/defaultUserAvatar.png"

import TextEditor from "../../components/TextEditor/TextEditor"
import { costEnum, eventTypes, PrivacyType, scrollOnError } from "./helpers"
import Loading from "../../components/Loading/Loading"
import DeleteIcon from "@material-ui/icons/Delete"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import Button from "../../components/Button/Button"
import { messages } from "../../helpers/constants"
import DateRangeIcon from "@material-ui/icons/DateRange"
import Calendar from "../../components/Calendar/CalendarWithRange"
import useOutsideClicker from "../../hooks/useOutsideClicker"
import AttachMoneyIcon from "@material-ui/icons/AttachMoney"
import GrayCheckbox from "../../components/Checkbox/GrayCheckBox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TimePicker from "../../components/TimePicker/TimePicker"
import { convertCamelCaseToTitle } from "../../helpers/textHelpers"
import { ReactComponent as AddMedia } from "../../images/icons/add_media.svg"
import { timeValues } from "../../components/TimePicker/helpers"
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete"
import AutoCompleteField from "../../components/AutoCompleteField/AutoCompleteField"
import { isHaveBadWords } from "../../helpers/badWords"

const maxDescriptionLength = 1000

const ResourceForm = ({
  data,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  errors,
  touched,
  handleSubmit,
  onCancel,
  resource_type,
  isSubmitting,
  onDelete,
  setTouched,
  initialValues,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false)
  const fileInputRef = useRef(null)
  const [buttonClickedStatus, setButtonClickedStatus] = useState(false)
  const [freeCost, setFreeCost] = useState(false)
  const [onlineEvent, setOnlineEvent] = useState(false)
  const [location, setLocation] = useState("")

  const history = useHistory()

  const handleSelectLocation = async (e, selected) => {
    if (selected) {
      const result = await geocodeByAddress(selected.description)
      const { lat, lng: long } = await getLatLng(result[0])
      if (lat && long) {
        setFieldValue("lat", lat)
        setFieldValue("long", long)
      }
    }
  }

  const [calendarOpened, setCalendarOpened] = useState(false)
  const CalendarRef = useRef(null)
  useOutsideClicker(CalendarRef, () => {
    setCalendarOpened(false)
  })
  const handleOpenCalendar = () => {
    setCalendarOpened(true)
  }

  useEffect(() => {
    if (data.cost === "Free") {
      setFreeCost(true)
      handleChange({
        target: { name: "cost", value: "Free" },
      })
    }
    if (data.location === 'Online event') {
      setTouched({ ...touched, location: true })
      setOnlineEvent(true)
       handleChange({
        target: { name: "location", value: "Online event" },
      })
      setFieldValue("lat", 0)
      setFieldValue("long", 0)
    }
  }, [data.cost, data.location])

  const handleFreeChange = ({ target: { checked } }) => {
    setTouched({ ...touched, cost: true })
    setFreeCost(checked)
    if (checked) {
      handleChange({
        target: { name: "cost", value: "Free" },
      })
    } else {
      handleChange({
        target: { name: "cost", value: "" },
      })
    }
  }

  const onlineEventChange = ({ target: { checked } }) => {
    setTouched({ ...touched, location: true })
    setOnlineEvent(checked)
    if (checked) {
      handleChange({
        target: { name: "location", value: "Online event" },
      })
      setFieldValue("lat", 0)
      setFieldValue("long", 0)
    } else {
      handleChange({
        target: { name: "location", value: "" },
      })
    }
  }

  const getVideo = () => {
    if (values["video"]?.path) {
      return values["video"]
    } else if (values["photo"]) {
      return { thumbnail: values["photo"].path }
    } else {
      return null
    }
  }

  const handleCancel = () => {
    setButtonClickedStatus(true)
    if (Object.keys(touched).length) {
      setIsPopupOpened(true)
    } else {
      history.goBack()
    }
  }
  const { width } = useWindowHeightWidth()
  const onUploadVideo = async ({ target }) => {
    const file = target.files[0]

    const path = await readFile(file)

    if (file.type.includes("video")) {
      setFieldValue(
        "video",
        {
          id: Number(uuidv4()),
          name: file.name,
          length: await getVideoDuration(file),
          file: { file },
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "video" },
      })
    } else if (file.type.includes("image")) {
      setFieldValue(
        "photo",
        {
          id: Number(uuidv4()),
          name: file.name,
          file: file,
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "photo" },
      })
    }
  }

  const formatDateRange = (date) => {
    let date_start = new Date(date["start"]).toLocaleDateString("en-US")
    let date_end = new Date(date["end"]).toLocaleDateString("en-US")
    if (date_start === date_end) {
      return date_start
    } else if (date_start && date_end) {
      return `${ date_start } - ${ date_end }`
    }
  }
  return (
    <div className={styles.wrapper}>
      <Prompt
        when={!objectComparison(values, initialValues) && !buttonClickedStatus}
        message={messages.CONFIRM_LEAVING_FROM_PAGE}
      />
      {isSubmitting ||
        (!Object.keys(data).length && (
          <div className={styles.loadingWrap}>
            <Loading />
          </div>
        ))}
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          handleCancel={() => {
            onCancel ? onCancel() : history.goBack()
            setIsPopupOpened(false)
          }}
        />
      )}
      <div className={styles.headerWrapp}>
        <h4 className={styles.title}>
          {data["uuid"] ? "Editing" : "Create"} new{" "}
          {convertCamelCaseToTitle(resource_type)}
        </h4>
        {data["uuid"] &&
          (width < 768 ? (
            <div
              className="iconWrap"
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />
            </div>
          ) : (
            <ButtonComponent
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              Delete
            </ButtonComponent>
          ))}
      </div>
      <div className={styles.sectionWrapper}>
        <Grid
          className={styles.inputsWrapper}
          container
          component="div"
          spacing={2}
        >
          <Grid item component="div" xs={12} className={styles.companyInfo}>
            <img
              src={data?.company?.photo || avatar}
              alt="avatar"
              width="64px"
              className={styles.avatar}
            />
            <h4 className={styles.title}>{values["company_name"]}</h4>
          </Grid>
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="sub_type"
              label="Event Type*"
              value={eventTypes.find(
                (item) => item.value === values["sub_type"],
              )}
              placeholder="Select event type"
              onChange={(name, option) =>
                handleChange({
                  target: { name: name, value: option.value },
                })
              }
              onBlur={() => handleBlur({ target: { name: "sub_type" } })}
              options={eventTypes}
              error={errors["sub_type"] && touched["sub_type"]}
              errorMessage={
                errors["sub_type"] && touched["sub_type"] && errors["sub_type"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="topic"
              label="Topic*"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter topic"
              value={values["topic"]}
              error={errors["topic"] && touched["topic"]}
              errorMessage={
                errors["topic"] && touched["topic"] && errors["topic"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Grid container>
              <Grid item component="div" md={10} xs={8}>
                <Dropdown
                  border
                  className={styles.dropdown}
                  id={"03"}
                  fieldStyle="gray"
                  name="cost"
                  afterIcon={<AttachMoneyIcon />}
                  disabled={freeCost}
                  label="Cost* (A 20% Bridge 2 Technologies Administrative Fee Will Be Charged.)"
                  onChange={(name, option) =>
                    handleChange({
                      target: { name: name, value: option.value },
                    })
                  }
                  onBlur={() => handleBlur({ target: { name: "cost" } })}
                  options={costEnum}
                  placeholder="Enter cost"
                  value={costEnum.find(
                    (item) => item.value === values["cost"],
                  )}
                  error={errors["cost"] && touched["cost"]}
                  errorMessage={
                    errors["cost"] && touched["cost"] && errors["cost"]
                  }
                />
              </Grid>
              <Grid md={2} xs={4} item>
                <FormControlLabel
                  className={styles.checkBoxWrap}
                  control={
                    <GrayCheckbox
                      color={"default"}
                      className={styles.checkBox}
                      name="is_full_profile"
                      onChange={handleFreeChange}
                      checked={freeCost}
                    />
                  }
                  label="Free"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item component="div" xs={12}>
            <PlacesAutocomplete value={location} onChange={setLocation}>
              {({ getInputProps, suggestions, loading }) => {
                return (
                  <div>
                    <AutoCompleteField
                      disabled={onlineEvent}
                      filterOptions={(item) => item}
                      value={{ description: values["location"] }}
                      options={suggestions}
                      placeholder={"Enter location"}
                      getOptionLabel={(suggestions) => suggestions.description}
                      //classes={classes}
                      fieldStyle={"dark"}
                      loading={loading}
                      name={"location"}
                      label={"Location*"}
                      onBlur={() => {
                        setTouched({ ...touched, long: true })
                        handleBlur({ target: { name: "location" } })
                      }}
                      onInputChange={(e, value) => {
                        setFieldValue("location", value)
                        if (value === values["location"]) {
                          setFieldValue("lat", values["lat"])
                          setFieldValue("long", values["long"])
                        } else {
                          setFieldValue("lat", "")
                          setFieldValue("long", "")
                        }

                        getInputProps().onChange({
                          target: { value: value || "" },
                        })
                      }}
                      error={
                        (errors["location"] && touched["location"]) ||
                        (!onlineEvent && errors["long"] && touched["long"])
                      }
                      errorMessage={
                        (errors["location"] &&
                          touched["location"] &&
                          errors["location"]) ||
                        (!onlineEvent && errors["long"] &&
                          touched["long"] &&
                          errors["long"])
                      }
                      onChange={handleSelectLocation}
                    />
                  </div>
                )
              }}
            </PlacesAutocomplete>

            <FormControlLabel
              className={styles.checkBoxWrapNewLine}
              control={
                <GrayCheckbox
                  color={"default"}
                  className={styles.checkBox}
                  onChange={onlineEventChange}
                  checked={onlineEvent}
                />
              }
              label="Online event"
            />
          </Grid>
          <Grid
            item
            component="div"
            xs={12}
            md={6}
            className={styles.expiration}
          >
            <Input
              autoComplete="off"
              onClick={handleOpenCalendar}
              afterIcon={<DateRangeIcon onClick={handleOpenCalendar} />}
              className={styles.input}
              name="date"
              label="Date*"
              placeholder="Select date"
              onChange={handleOpenCalendar}
              onBlur={handleBlur}
              value={
                values["date"] && formatDateRange(values["date"])
              }
              error={errors["date"] && touched["date"]}
              errorMessage={errors["date"] && touched["date"] && errors["date"]}
            />
            {calendarOpened && (
              <div
                ref={CalendarRef}
                style={{
                  position: "absolute",
                  marginLeft: "3px",
                  width: "96%",
                  zIndex: "10",
                }}
              >
                <Calendar
                  selected={values["date"] || false}
                  styles={{
                    marginTop: "3px",
                  }}
                  minDate={new Date()}
                  width="100%"
                  onChange={(e) => {
                    handleChange({
                      target: {
                        name: "date",
                        value: {
                          start: e.start,
                          end: e.end,
                        },
                      },
                    })
                    if (e.eventType === 3) setCalendarOpened(false)
                  }}
                />
              </div>
            )}
          </Grid>
          <Grid item xs={12} md={6} />
          <Grid item xs={12} md={6}>
            <Grid item component="div" xs={12}>
              <TimePicker
                handleChange={handleChange}
                label={"Time*"}
                handleBlur={handleBlur}
                errorFrom={errors["time_start"] && touched["time_start"]}
                errorMessageFrom={
                  errors["time_start"] &&
                  touched["time_start"] &&
                  errors["time_start"]
                }
                valueStart={values["time_start"]}
                valueEnd={values["time_end"]}
                errorMessageTo={
                  errors["time_end"] &&
                  touched["time_end"] &&
                  errors["time_end"]
                }
                errorTo={errors["time_end"] && touched["time_end"]}
              />
            </Grid>
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="contact"
              label="Contact*"
              placeholder="Enter contact information"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["contact"]}
              error={errors["contact"] && touched["contact"]}
              errorMessage={
                errors["contact"] && touched["contact"] && errors["contact"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextEditor
              className={styles.description}
              name="description_obj"
              placeholder="Type description"
              text={values["description"]}
              onChange={(value) =>
                handleChange({
                  target: { name: "description_obj", value: value },
                })
              }
              maxLength={maxDescriptionLength}
              label="Description*"
              error={errors["description_obj"] && touched["description_obj"]}
              errorMessage={
                errors["description_obj"] &&
                touched["description_obj"] &&
                errors["description_obj"]
              }
              onBlur={() =>
                handleBlur({
                  target: { name: "description_obj" },
                })
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <input
              ref={fileInputRef}
              type="file"
              name="video"
              className={styles.fileInput}
              onChange={onUploadVideo}
              multiple={false}
              accept=".mp4, .m4v, .mov, .mpg, .mpeg, .jpg, .jpeg, .png"
            />
            <VideoCard
              empty={!(values["video"]?.path || values["photo"])}
              video={getVideo()}
              Icon={AddMedia}
              addMessage={"Add photo or video *"}
              onAdd={() => {
                fileInputRef.current.value = ""
                fileInputRef.current.click()
              }}
              deleteVideo={() => {
                setFieldValue("video", null)
                setFieldValue("photo", null)
                handleBlur({
                  target: {
                    name: "video",
                  },
                })
              }}
              anotherUser={false}
              error={
                (errors["video"] && touched["video"]) ||
                (errors["photo"] && touched["photo"])
              }
              errorMessage={
                (errors["video"] && touched["video"] && errors["video"]) ||
                (errors["photo"] && touched["photo"] && errors["photo"])
              }
              supportedFormats=".mp4, .m4v, .mov, .mpg, .mpeg"
              maxSize="40 MB"
              maxSizePhoto="10 MB"
              supportedFormatsPhoto={".jpg, .jpeg, .png"}
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="key_benefits"
              label="Key Benefits"
              placeholder="Enter key benefits"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["key_benefits"]}
              error={errors["key_benefits"] && touched["key_benefits"]}
              errorMessage={
                errors["key_benefits"] &&
                touched["key_benefits"] &&
                errors["key_benefits"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="who_should_attend"
              label="Who Should Attend"
              placeholder="Enter who should attend"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values["who_should_attend"]}
              error={
                errors["who_should_attend"] && touched["who_should_attend"]
              }
              errorMessage={
                errors["who_should_attend"] &&
                touched["who_should_attend"] &&
                errors["who_should_attend"]
              }
            />
          </Grid>
        </Grid>
        <Grid
          component="div"
          container
          justify="flex-end"
          className={styles.buttonsWrapper}
        >
          <div className={styles.btnsWrap}>
            <Button
              className={styles.btn}
              buttonStyle="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
                scrollOnError(errors)
                setButtonClickedStatus(true)
              }}
              buttonStyle="contained"
              disabled={isSubmitting}
              className={styles.btn}
            >
              Save
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  )
}

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ data, resource_type }) {
    let emptyDescription =
      '{"blocks":[{"key":"fvm5o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
    return {
      ["description_obj"]: data["description"] || emptyDescription,
      ["company_name"]: data?.company?.name || data?.company_name || "",
      ["cost"]: data["cost"] || "",
      ["location"]: data["location"] || "",
      ["contact"]: data["contact"] || "",
      ["description"]: data["description"] || emptyDescription,
      ["key_benefits"]: data["key_benefits"] || "",
      ["who_should_attend"]: data["who_should_attend"] || "",
      ["video"]: data["video"] || null,
      ["topic"]: data["topic"] || "",
      ["lat"]: data["lat"] || (data["location"] === "Online event" ? 0 : ""),
      ["long"]: data["long"] || (data["location"] === "Online event" ? 0 : ""),
      ["photo"]: data["photo"] || null,
      ["time_start"]:
        timeValues.find(
          (item) => item.value === data["time"]?.split("-")[0].trim(),
        )?.value || "",
      ["time_end"]:
        timeValues.find(
          (item) => item.value === data["time"]?.split("-")[1].trim(),
        )?.value || "",
      ["date"]: data["date"] || "",
      ["resource_type"]: resource_type || "",
      ["sub_type"]:
        eventTypes.find((item) => item.value === data["sub_type"])?.value || "",
      ["event_type"]:
        PrivacyType.find((item) => item.value === data["event_type"])?.value ||
        PrivacyType.find((item) => item.value === "public")?.value,
    }
  },
  validationSchema: () =>
    object().shape(
      {
        ["video"]: mixed().when(["photo"], {
          is: (photo) => !photo,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "size",
              exclusive: false,

              message: "Sorry, you can upload video with max. size 40 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 40000000
              },
            }),
        }),
        ["photo"]: mixed().when(["video"], {
          is: (video) => !video,
          then: mixed()
            .required("Please upload photo or video")
            .test({
              name: "max",
              exclusive: true,

              message: "Sorry, you can upload photo with max. size 10 Mb.",
              test: (value) => {
                if (!value?.size) return true
                return value.size <= 10000000
              },
            }),
        }),

        ["topic"]: string()
          .max(
            64,
            "Please enter a correct topic name that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter topic name"),
        ["cost"]: string()
          .max(
            64,
            "Please enter a cost description name that consists of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please describe cost"),
        ["location"]: string().required("Please enter location"),
        ["time_start"]: string().required("Please select start time"),
        ["time_end"]: string().required("Please select end time"),
        ["date"]: string().required("Please select date"),
        ["lat"]: string().required(
          "Please select location only from dropdown list",
        ),
        ["long"]: string().required(
          "Please select location only from dropdown list",
        ),
        ["event_type"]: string().required("Please select access type"),
        ["sub_type"]: string().required("Please select event type"),
        ["contact"]: string()
          .max(
            64,
            "Please enter a correct contact details that consist of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter contact details"),
        ["key_benefits"]: string()
          .max(
            64,
            "Please enter key benefits that consist of no more than 64 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["who_should_attend"]: string()
          .max(64, "Please enter no more than 64 characters")
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          }),
        ["description_obj"]: mixed()
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length <= maxDescriptionLength,
            message: `Please enter a correct resource description  that consists of no more than ${maxDescriptionLength} characters`,
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length !== 0,
            message: "Please enter resource description",
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              !isHaveBadWords(
                JSON.parse(val)
                  .blocks.map((p) => p.text)
                  .join(" "),
              ),
            message: `Please don't use bad language`,
            exclusive: false,
          }),
      },
      ["photo", "video"],
    ),
  handleSubmit(values, { setSubmitting, setFieldError, props: { onSave } }) {
    setSubmitting(true)
    let savedValues = {
      ...values,
      description: values["description_obj"],
      time: `${values["time_start"]} - ${values["time_end"]} `,
    }
    delete savedValues["description_obj"]
    delete savedValues["time_start"]
    delete savedValues["time_end"]

    onSave(savedValues)
  },
})(ResourceForm)

export default FormikApp
