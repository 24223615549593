import React, {useEffect, useState} from "react"
import {Link, Redirect, useHistory} from "react-router-dom"
import draftToHtml from "draftjs-to-html"
import {useSelector} from "react-redux"
import copy from "copy-to-clipboard"

import styles from "./index.module.scss"
import LabelBadge from "../elements/LabelBadge"
import VoteUpDown from "../VoteUpDown"
import Share from "../Share"
import UserAvatar from "../elements/UserAvatar"
import {ReactComponent as CommentIcon} from "../../images/icons/commentIcon.svg"
import {ReactComponent as ShareIcon} from "../../images/icons/shareIcon.svg"
import {ReactComponent as EditIcon} from "../../images/icons/edit.svg"
import {ReactComponent as DeleteIcon} from "../../images/icons/deleteImage.svg"
import {questionTypes} from "../../helpers/helpData"
import VideoPlayer from "../VideoPlayer/VideoPlayer"
import services from "../../services"
import Comments from "../Comments"
import Popup from "../Popup/Popup"
import Button from "../Button/Button"
import userServices from "../../services"
import {getMessageTime} from "../../helpers/dateHandlers"
import {formatComments} from "../../helpers/comments"
import routes from "../../routes/routes";

const CommunityTopic = ({
                            data,
                            handlePostComment,
                            handleEditComment,
                            handleDeleteComment,
                            voteComment,
                            commentsCount,
                        }) => {
    const userInfo = useSelector((state) => state.user)
    const [reputation, setReputation] = React.useState(data.reputation)
    const [deletePopupOpened, setDeletePopupOpened] = useState(false)
    const [comments, setComments] = useState([])

    useEffect(() => {
        setComments(formatComments(data.comments, userInfo))
    }, [data.comments, userInfo])

    // Function to check URL ending
    const checkUrlEnding = () => {
        const pathname = window.location.pathname;
        if (pathname.endsWith('/accept')) {
            console.log("The URL ends with '/accept'");
            setDeletePopupOpened(false);
            services.communityServices.acceptCommunityTopic(data.uuid)
                .then(() => {
                    console.log("The question has been accepted" + data.uuid);
                })
        } else if (pathname.endsWith('/decline')) {
            console.log("The URL ends with '/decline'");
            setDeletePopupOpened(true);
        } else {
            console.log("The URL does not end with /accept or /decline");
            // Do something if URL does not end with /accept or /decline
        }
    };

    useEffect(() => {
        checkUrlEnding();
    }, []);

    const history = useHistory()

    const voteFn = (vote) => {
        services.communityServices
            .voteForQuestion({uuid: data.uuid, vote})
            .then((response) => {
                setReputation(response.data)
            })
            .catch((e) => console.log(e))
    }

    const handleOpenDeletePopup = () => setDeletePopupOpened(true)

    const onDeleteCommunityTopic = () => {
        if (data.uuid) {
            userServices.communityServices
                .deleteCommunityTopicByUuid(data.uuid)
                .then(() => {
                    history.push(`/community/my-questions`)
                })
        }
    }

    let div = document.createElement("div")
    div.innerHTML = getDescription(data.tell_your_story)

    if(div.getElementsByTagName("a").length){
        div.getElementsByTagName("a").forEach((link) => {
            link.classList.add("goldLink")
            link.classList.add("contactLink")
        })
    }

    useEffect(() => {
        document.addEventListener("click", (e) => {
            if (e.target.classList.contains("contactLink")) {
                e.preventDefault()
                copy(e.target.innerHTML)
                e.target.classList.add(styles.copied)
                setTimeout(() => {
                    e.target.classList.remove(styles.copied)
                }, 1500)
            }
        })
    }, [])

    return (
        <div className={styles.topicWrapper}>
            {deletePopupOpened && (
                <Popup
                    dialog
                    subtitle={isUserPeer(userInfo.email, data.peers) ? "Do you want to remove yourself from this question?" : "Do you want to delete this question?"}
                    button={
                        <Button buttonStyle="black" onClick={onDeleteCommunityTopic}>
                            Yes
                        </Button>
                    }
                    acceptButton={
                        <Button
                            buttonStyle="outlinedBlack"
                            onClick={() => {
                                setDeletePopupOpened(false)
                            }}
                        >
                            No
                        </Button>
                    }
                    closeAction={() => {
                        setDeletePopupOpened(false)
                    }}
                    isCrossIcon={true}
                />
            )}
            <div className={styles.topicBadge}>
                <LabelBadge {...getQuestionType(data.question_type)} />
            </div>

            <div className={styles.topicBody}>
                <div className={styles.topicVoteColumn}>
                    <VoteUpDown
                        reputation={reputation}
                        voteFn={voteFn}
                        horizontalOnMobile
                    />
                </div>

                <div className={styles.topicInfo}>
                    <div className={styles.topicHeader}>
                        <Link to={`/${data.user?.type.toLowerCase()}/${data.user?.uuid}`}>
                            <UserAvatar
                                src={data.user?.photo}
                                size={40}
                                alt={data.user?.name}
                            />
                        </Link>

                        <div className={styles.topicHeaderText}>
                            <div className={styles.topicPostedBy}>
                                Posted by{" "}
                                <Link
                                    to={`/${data.user?.type.toLowerCase()}/${data.user?.uuid}`}
                                >
                                    {data.user?.name || data.user?.full_name}
                                </Link>
                            </div>

                            <div className={styles.topicPublishedDate}>
                                {getMessageTime(data.created_at)}
                            </div>
                        </div>
                    </div>

                    <div className={styles.topicContent}>
                        <h1 className={styles.topicTitle}>{data.title}</h1>

                        {data.tell_your_story && (
                            <div
                                className={styles.topicDescription}
                                dangerouslySetInnerHTML={{
                                    __html: div.outerHTML,
                                }}
                            />
                        )}

                        {data.peers && (
                            <div className={styles.peerMembersList}>
                                {data.peers.map((member) => (
                                    <div key={member.uuid} className={styles.peerMember}>
                                        <Link to={`/${member.type.toLowerCase()}/${member.uuid}`}>
                                            <UserAvatar
                                                src={member.photo}
                                                size={40}
                                                alt={member.name}
                                            />
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        )}

                        {data.photo && (
                            <div className={styles.topicImage}>
                                <img src={data.photo.path} alt={data.title}/>
                            </div>
                        )}

                        {data.video && (
                            <div className={styles.topicImage}>
                                <VideoPlayer source={data.video.path}/>
                            </div>
                        )}
                    </div>

                    <div className={styles.topicFooter}>
                        <div className={styles.topicFooterItems}>
                            <div className={styles.topicFooterItemsIcon}>
                                <CommentIcon/>
                            </div>

                            <span className={styles.topicFooterItemsText}>
                {data.comments.length} Comments
              </span>
                        </div>
                        {data.question_type !== "peer_circles" && (
                            <div className={styles.topicFooterItems}>
                                <Share
                                    title={data.title}
                                    url={window.location.href.replace(":3000", ".com")}
                                    type="horizontal"
                                >
                                    <div className={styles.topicFooterItemsIcon}>
                                        <ShareIcon/>
                                    </div>

                                    <span className={styles.topicFooterItemsText}>Share</span>
                                </Share>
                            </div>
                        )}
                        {isUserPeer(userInfo.email, data?.peers) && <>
                            <div
                                className={styles.topicFooterItems}
                                onClick={handleOpenDeletePopup}
                            >
                                <div className={styles.topicFooterItemsIcon}>
                                    <DeleteIcon/>
                                </div>

                                <span className={styles.topicFooterItemsText}>Remove yourself</span>
                            </div>
                        </>}
                        {isUserAuthor(userInfo, data.user) && (
                            <React.Fragment>
                                <Link
                                    to={`/community-topic-edit?id=${data.uuid}`}
                                    className={styles.topicFooterItems}
                                >
                                    <div className={styles.topicFooterItemsIcon}>
                                        <EditIcon/>
                                    </div>

                                    <span className={styles.topicFooterItemsText}>Edit</span>
                                </Link>

                                <div
                                    className={styles.topicFooterItems}
                                    onClick={handleOpenDeletePopup}
                                >
                                    <div className={styles.topicFooterItemsIcon}>
                                        <DeleteIcon/>
                                    </div>

                                    <span className={styles.topicFooterItemsText}>Delete</span>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </div>

            <Comments
                data={comments}
                userInfo={userInfo}
                onCommentPost={handlePostComment}
                voteComment={voteComment}
                handleEditComment={handleEditComment}
                handleDeleteComment={handleDeleteComment}
                commentsCount={commentsCount}
            />
        </div>
    )
}

export default CommunityTopic

const getQuestionType = (questionType) => {
    const type = questionTypes.find((item) => item.questionType === questionType)

    return {
        text: type.badgeText,
        bgColor: type.badgeColor,
        tooltipText: type.tooltipText,
    }
}

const getDescription = (content) => {
    if (content) {
        try {
            return draftToHtml(JSON.parse(content))
        } catch (error) {
            return `<p>${content}</p>`
        }
    } else {
        return ""
    }
}

const isUserAuthor = (userInfo, resourcesUser) => {
    if (userInfo.company && userInfo.company.id) {
        return userInfo.company.id === resourcesUser.id
    }

    return userInfo.id === resourcesUser.id
}

const isUserPeer = (userEmail, peers) => {
    if (userEmail && peers.length) {
        return peers.map(peer => peer.email).includes(userEmail)
    }

    return false
}
