import React from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { paths } from "../../../../routes/paths"
import TrueResourceCard from "../../../../components/cards/TrueResourceCard"
import Pagination from "../../../../components/elements/Pagination"
import AccessCheckerPopup from "../../../../components/Opportunity/components/AccessCheckerPopup/AccessCheckerPopup"
import { setSearchPage } from "../../useSearch/actionCreators"
import useAccessChecker from "./useAccessChecker"
import { checkIsFilterUsed } from "./helpers"
import styles from "./index.module.scss"

const MainWrapper = ({ state, dispatch }) => {
  const companyInfo = useSelector((state) => state.user.company)
  const queryParams = state.searchParamGroup
  const history = useHistory()
  const {
    itemList,
    itemCount,
    pageCount,
    isFetching,
  } = state.requestResultGroup
  const [isPopupDisplayed, closePopup, checkAccess] = useAccessChecker()
  if (isFetching) return null
  const isFilterUsed = checkIsFilterUsed(state)
  return (
    <>
      {!!itemCount && (
        <>
          <div className={styles.itemList}>
            {itemList.map((item) => (
              <TrueResourceCard
                key={item.id}
                classes={styles.card}
                type={item.resource_type}
                photo={item.photo}
                video={item.video}
                title={item.topic}
                location={item.location}
                date={item.date}
                time={item.time}
                onClick={() =>
                  checkAccess(() => history.push(paths.resourcePage(item.uuid)))
                }
              />
            ))}
          </div>
          {pageCount > 1 && (
            <div className={styles.pagination}>
              <Pagination
                currentPageNumber={parseInt(state.searchParamGroup.page)}
                totalPageCount={pageCount}
                onSelect={(pageNumber) => dispatch(setSearchPage(pageNumber))}
              />
            </div>
          )}
        </>
      )}
      {!itemCount && !queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Nothing here yet.
          <br />
          {companyInfo.type
            ? "Be the first one to contribute."
            : "Please try again later."}
        </p>
      )}
      {!itemCount && !!queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Sorry, we found 0 matches.
          <br />
          {companyInfo.type === "company_provider"
            ? "Be the first one to contribute."
            : "Please try again later."}
        </p>
      )}
      {!itemCount && isFilterUsed && (
        <p className={styles.notFoundBlock}>
          There are no results that match your search.
          <br />
          Please try adjusting your filters.
        </p>
      )}
      <AccessCheckerPopup visible={isPopupDisplayed} closeAction={closePopup} />
    </>
  )
}
export default MainWrapper
