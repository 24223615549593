import { useEffect, useCallback, useState, useMemo } from "react"
import { updateNotifications } from "../../actions/notifications/notificationsActions"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { paths } from "../../routes/paths"
import { ADMIN } from "../../helpers/userRoles"

const useNotificationsListener = () => {
  const dispatcher = useDispatch()
  const {
    location: { pathname },
  } = useHistory()
  const userInfo = useSelector((state) => state.user)
  const [intervalState, setIntervalState] = useState()

  useEffect(() => {
    const isChatsPage = pathname.includes(paths.chats(""))
    const isNotificationsPage = pathname.includes(paths.notifications)

    if (
      userInfo.uuid &&
      !intervalState &&
      !isChatsPage &&
      !isNotificationsPage &&
      userInfo.role !== ADMIN
    ) {
      dispatcher(updateNotifications())
      const interval = setInterval(() => {
        dispatcher(updateNotifications())
      }, 10000)

      setIntervalState(interval)
    }
    if (!userInfo.uuid || isChatsPage) {
      clearInterval(intervalState)
      setIntervalState()
    }
  }, [userInfo.uuid, pathname])
}

export default useNotificationsListener
