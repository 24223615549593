export const industryOptions = [
  {
    id: 0,
    value: "Administrative Services",
    databaseName: "administrative_services",
  },
  {
    id: 1,
    value: "Airlines",
    databaseName: "airlines",
  },
  { id: 2, value: "Advertising", databaseName: "advertising" },
  { id: 3, value: "Apparel", databaseName: "apparel" },
  {
    id: 4,
    value: "Arts, Entertainment & Recreation",
    databaseName: "arts_entertainment_and_recreation",
  },
  { id: 5, value: "Beauty & Cosmetics", databaseName: "beauty" },
  { id: 6, value: "Broadcasting (except Internet)", databaseName: "beauty" },
  { id: 7, value: "Construction", databaseName: "construction" },
  {
    id: 8,
    value: "Data processing, hosting & related services",
    databaseName: "data_processing_hosting_and_related_services",
  },
  { id: 9, value: "Education", databaseName: "education" },
  {
    id: 10,
    value: "Financial Services & Insurance",
    databaseName: "financial_services_and_insurance",
  },
  {
    id: 11,
    value: "Food Services",
    databaseName: "food_services",
  },
  {
    id: 12,
    value: "Governance & Strategic Development",
    databaseName: "governance_strategic_development",
  },
  { id: 13, value: "Government", databaseName: "government" },
  { id: 14, value: "Healthcare", databaseName: "healthcare" },
  { id: 15, value: "Human Resources", databaseName: "human_resources" },
  { id: 16, value: "Infrastructure", databaseName: "infrastructure" },
  {
    id: 18,
    value: "Information Technology & Digital",
    databaseName: "it_digital",
  },
  {
    id: 19,
    value: "Legal",
    databaseName: "legal",
  },
  {
    id: 20,
    value: "Leisure & Hospitality",
    databaseName: "leisure_and_hospitality",
  },
  {
    id: 21,
    value: "3PL & Distribution",
    databaseName: "distribution",
  },
  {
    id: 22,
    value: "Product Development & Manufacturing",
    databaseName: "manufacturing",
  },
  {
    id: 23,
    value: "Management of Companies & Enterprise",
    databaseName: "management_of_companies_and_enterprises",
  },
  { id: 26, value: "Marketing & Communications", databaseName: "marketing" },
  { id: 27, value: "Media", databaseName: "media" },
  {
    id: 29,
    value: "Professional Business Services & Consulting",
    databaseName: "professional_and_business_services",
  },
  {
    id: 30,
    value: "Professional, Scientific & Technical Services",
    databaseName: "professional_scientific_and_technical_services",
  },
  { id: 31, value: "Public Relations", databaseName: "public_relations" },
  { id: 32, value: "Real Estate", databaseName: "real_estate" },
  { id: 33, value: "Retail Trade", databaseName: "retail_trade" },
  {
    id: 34,
    value: "Software Development",
    databaseName: "software_development",
  },
  { id: 35, value: "Technology", databaseName: "technology" },
  { id: 36, value: "Telecommunications", databaseName: "telecommunications" },
  { id: 38, value: "Transportation", databaseName: "transportation" },
  { id: 39, value: "Wholesale Trade", databaseName: "wholesale_trade" },
]
