import React from "react"

import styles from "./index.module.scss"
import Button from "../../components/Button/Button"

const EmailPending = ({
  resendEmail,
  clearToken,
  resendLimitStatus,
  newEmail,
}) => {
  return (
    <div className={styles.settingsTableRow}>
      <div className={styles.settingsTableCol}>
        <div className={styles.settingsLabel}>Email</div>
      </div>

      <div className={styles.settingsTableCol}>
        <div className={styles.settingsValue}>
          <div className={styles.settingsPending}>
            <h3 className={styles.settingsPendingTitle}>
              Email change request pending
            </h3>

            <p className={styles.settingsPendingDescription}>
              You have a pending request to change your email address to{" "}
              {newEmail}. To complete this request, please check your inbox at
              that email address to confirm ownership of that account
            </p>

            <div className={styles.settingsPendingButtons}>
              <div className={styles.settingsPendingButtonsItem}>
                <Button onClick={resendEmail} disabled={resendLimitStatus}>
                  Resend email
                </Button>
              </div>

              <div className={styles.settingsPendingButtonsItem}>
                <div
                  className={styles.settingsPendingCancel}
                  onClick={clearToken}
                >
                  Cancel change request
                </div>
              </div>
            </div>

            {resendLimitStatus && (
              <div className={styles.settingsPendingWarning}>
                Too Many Requests. Reached maximum requests limitation, try
                again soon.
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={styles.settingsTableCol}></div>
    </div>
  )
}

export default EmailPending
