import React, { useState } from "react"
import styles from "./UserImage.module.scss"
import PropTypes from "prop-types"
import defaultAvatar from "../../images/defaultUserAvatar.png"
import { ReactComponent as CrossIcon } from "../../images/icons/delete.svg"
import { ReactComponent as PhotoCamera } from "../../images/icons/photoCamera.svg"
import Popup from "../Popup/Popup"
import Button from "../Button/Button"
import CrossButton from "../CrossButton/CrossButton"

function UserImage({
  image,
  onDelete,
  onUpdate,
  selectedUser,
  isEmployee = false,
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  return (
    <div className={styles.userImageContainer}>
      {showDeletePopup && (
        <Popup
          dialog
          subtitle="Are you sure?"
          text="Do you really want to delete this photo? All data will be lost"
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                onDelete()
                setShowDeletePopup(false)
              }}
            >
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => setShowDeletePopup(false)}
            >
              Cancel
            </Button>
          }
          closeAction={() => setShowDeletePopup(false)}
          isCrossIcon={true}
        />
      )}

      {!isEmployee && (
        <span
          className={styles.crossIcon}
          onClick={() => {
            setShowDeletePopup(true)
          }}
        >
          {!selectedUser && image && <CrossButton />}
        </span>
      )}

      <div className={styles.imageContainer}>
        <img src={image || defaultAvatar} alt="User Avatar" />

        {isEditable({ selectedUser, isEmployee, image }) && (
          <span className={styles.uploadImage} onClick={onUpdate}>
            <PhotoCamera />
          </span>
        )}
      </div>
    </div>
  )
}

const isEditable = ({ selectedUser, isEmployee, image }) => {
  if (!selectedUser) {
    if (isEmployee) {
      if (!image) {
        return true
      }

      return false
    }

    return true
  }

  return false
}

UserImage.propTypes = {
  image: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  selectedUser: PropTypes.bool,
}

UserImage.defaultProps = {
  image: defaultAvatar,
  onDelete: () => {
    console.log("Default onDelete function is invoked")
  },
  onUpdate: () => {
    console.log("Default onUpdate function is invoked")
  },
}

export default UserImage
