import React from "react"
import InfiniteCalendar from "react-infinite-calendar"
import "react-infinite-calendar/styles.css"
import "./Calendar.css"

const CalendarComponent = ({
  classes,
  autoFocus,
  onChange,
  width,
  height,
  styles,
  selected,
  min,
  max,
  minDate,
  maxDate,
}) => {
  return (
    <div className={classes} style={styles}>
      <InfiniteCalendar
        selected={selected}
        autoFocus={autoFocus}
        min={min}
        max={max}
        minDate={minDate}
        maxDate={maxDate}
        displayOptions={{
          layout: "landscape",
          showHeader: false,
          showTodayHelper: true,
        }}
        theme={{
          accentColor: "#B4182D",
          floatingNav: {
            background: "#D46A6A",
            chevron: "#FFA726",
            color: "#FFF",
          },
          headerColor: "#B4182D",
          selectionColor: "#B4182D",
          textColor: {
            active: "#fff",
            default: "#333",
          },
          todayColor: "#FFA726",
          weekdayColor: "#B4182D",
        }}
        onSelect={onChange}
        width={width}
        height={height}
      />
    </div>
  )
}

export default CalendarComponent
