import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./AuxPortal.module.scss"
import { ReactComponent as Arrow } from "../../images/icons/arrow-top.svg"

const AuxPortal = ({
  children,
  button,
  acceptButton,
  buttonsStyle,
  initialOpen,
}) => {
  const [openCard, setOpenCard] = useState(initialOpen || false)

  return (
    <div className={styles.cardsWrap}>
      <div className={styles.header} onClick={() => setOpenCard(!openCard)}>
        <p>All Filters</p>
        <div className={styles.arrowWrap}>
          <Arrow className={openCard ? styles.arrowClose : styles.arrowOpen} />
        </div>
      </div>

      {openCard && (
        <div className={styles.container}>
          <div>{children}</div>
          {button && (
            <div style={buttonsStyle}>
              {button}
              {acceptButton}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

AuxPortal.propTypes = {
  initialOpen: PropTypes.bool,
  button: PropTypes.element,
  acceptButton: PropTypes.element,
  buttonsStyle: PropTypes.object,
  children: PropTypes.any,
}

AuxPortal.defaultProps = {}

export default AuxPortal
