import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./RadioButton.module.scss"

const RadioButton = ({
  className,
  id,
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  ...attrs
}) => {
  return (
    <div
      className={classNames(
        styles.radio,
        { [styles.disabledRadio]: disabled },
        className,
      )}
    >
      <label>
        <input
          type="radio"
          disabled={disabled}
          value={value}
          id={id}
          name={name}
          onChange={onChange()}
          onBlur={onBlur()}
          {...attrs}
        />
        <span className={styles.radioMaterial}>
          <span className={styles.check} />
        </span>
        <span className={styles.title}>{label}</span>
      </label>
    </div>
  )
}

RadioButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool.isRequired,
}

RadioButton.defaultProps = {
  className: "",
  onChange: () => {},
  onBlur: () => {},
}

export default RadioButton
