import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"
import CardWithBadge from "../../elements/CardWithBadge"
import UserAvatar from "../../elements/UserAvatar"
import { ReactComponent as CommentIcon } from "../../../images/icons/commentIcon.svg"
import { ReactComponent as ShareIcon } from "../../../images/icons/shareIcon.svg"
import { questionTypes } from "../../../helpers/helpData"
import VoteUpDown from "../../VoteUpDown"
import services from "../../../services"
import { getMessageTime } from "../../../helpers/dateHandlers"
import Share from "../../Share"
import { paths } from "../../../routes/paths"

const QuestionCard = ({ data, inSidebar }) => {
  const [reputation, setReputation] = React.useState(data.reputation)

  const voteFn = (vote) => {
    services.communityServices
      .voteForQuestion({ uuid: data.uuid, vote })
      .then(({ data }) => {
        setReputation(data)
      })
  }

  const generateLink = (user) => {
    if (user.type === "Company") {
      return paths.selectedCopmanyProfile(user.uuid)
    }
    return paths.selectedIndividualProfile(user.uuid)
  }

  return (
    <div className={styles.cardWrap}>
      <CardWithBadge {...getQuestionType(data.question_type)}>
        <div className={styles.cardContent}>
          <div className={styles.cardContentLeft}>
            <Link to={generateLink(data.user)}>
              <UserAvatar
                src={data.user?.photo}
                size={40}
                alt={data.user?.name}
              />
            </Link>

            <div className={styles.voteHandler}>
              {reputation && (
                <VoteUpDown reputation={reputation} voteFn={voteFn} />
              )}
            </div>
          </div>

          <div className={styles.cardContentRight}>
            <div className={styles.cardContentHeader}>
              <div className={styles.postedBy}>
                Posted by{" "}
                <Link to={generateLink(data.user)}>
                  {data.user?.name || data.user?.full_name}
                </Link>
              </div>

              <div className={styles.publishedDate}>
                {getMessageTime(data.created_at)}
              </div>
            </div>

            <Link
              to={`/community/topics/${data.uuid}`}
              className={styles.questionLink}
            >
              <h4
                className={classnames(
                  styles.questionTitle,
                  inSidebar && styles.inSidebar,
                )}
              >
                {getOptimizedText(data.title)}
              </h4>

              {data.tell_your_story && (
                <div
                  className={classnames(
                    styles.questionDescription,
                    inSidebar && styles.inSidebar,
                  )}
                >
                  {getOptimizedText(ifTextJson(data.tell_your_story), 220)}
                </div>
              )}

              {!inSidebar && data.photo && (
                <div className={styles.questionImage}>
                  <img src={data.photo.path} alt={data.title} />
                </div>
              )}

              {!inSidebar && data.video && (
                <div className={styles.questionImage}>
                  <img src={data.video.thumbnail} alt={data.title} />
                </div>
              )}
            </Link>

            <div className={styles.cardContentBottom}>
              <div className={styles.cardContentBottomItems}>
                <CommentIcon />
                <span>{data.comments_count || 0} Comments</span>
              </div>

              {data.question_type !== "peer_circles" && (
                <div className={styles.cardContentBottomItems}>
                  <Share
                    title={data.title}
                    url={`${window.location.origin.replace(
                      ":3000",
                      ".com",
                    )}/community/topics/${data.uuid}`}
                    type="horizontal"
                  >
                    <ShareIcon />
                    <span>Share</span>
                  </Share>
                </div>
              )}
            </div>
          </div>
        </div>
      </CardWithBadge>
    </div>
  )
}

QuestionCard.defaultProps = {
  inSidebard: false,
}

QuestionCard.propTypes = {
  data: PropTypes.shape({
    created_at: PropTypes.string,
    id: PropTypes.number,
    photo: PropTypes.any,
    question_type: PropTypes.string,
    reputation: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        value: PropTypes.number,
        positive: PropTypes.number,
        negative: PropTypes.number,
        type: PropTypes.string,
      }),
    ]),
    tell_your_story: PropTypes.string,
    title: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      photo: PropTypes.string,
    }),
    uuid: PropTypes.string,
    video: PropTypes.shape({
      id: PropTypes.number,
      length: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  }).isRequired,
  inSidebard: PropTypes.bool,
}

export default QuestionCard

const getQuestionType = (questionType) =>
  questionTypes.find((item) => item.questionType === questionType)

const getOptimizedText = (text, length = 130) =>
  text.length <= length ? text : `${text.slice(0, length)}...`

const ifTextJson = (text) => {
  try {
    return JSON.parse(text).blocks.reduce(
      (result, block) => (result += block.text),
      "",
    )
  } catch (error) {
    return text
  }
}
