export const USER_LOGIN = "USER_LOGIN"
export const USER_LOGOUT = "USER_LOGOUT"
export const USER_UPDATES_AVATAR = "USER_UPDATES_AVATAR"
export const USER_CHANGE_FULLNAME = "USER_CHANGE_FULLNAME"
export const USER_DELETES_AVATAR = "USER_DELETES_AVATAR"

export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS"

export const UPDATE_COMPANY_AVATAR = "UPDATE_COMPANY_AVATAR"
export const DELETE_COMPANY_AVATAR = "DELETE_COMPANY_AVATAR"

export const messages = {
  CONFIRM_DISCARD_FORM_CHANGES_ON_PAGE_LEAVE:
    "Are you sure you want to cancel your changes?",
  CONFIRM_LEAVING_FROM_PAGE:
    "Are you sure you want to leave this page without saving?",
  CONFIRM_LEAVE_FROM_COMMENT:
    "Are you sure you want to leave this page without posting?",
}
