import axios from "./axios"

export default {
  createChatRoom: async ({ type, uuid }) => {
    try {
      const result = await axios.post("/chats", {
        type,
        uuid,
      })

      return result
    } catch (error) {
      throw error
    }
  },

  getUserChats: async ({
    roomUuid,
    lastMessageUuid,
    unreadMessagesUuid,
    query,
  }) => {
    try {
      const result = await axios.get(`/chats`, {
        params: {
          chat_uuid: roomUuid,
          last_message: lastMessageUuid,
          unread_messages: unreadMessagesUuid,
          query,
        },
      })
      return result
    } catch (error) {
      throw error
    }
  },

  getPreviousMessages: async (roomUuid, firstMessageUuid) => {
    try {
      const result = await axios.get(`/chats/${roomUuid}/previous`, {
        params: { first_message_uuid: firstMessageUuid },
      })
      return result
    } catch (error) {
      throw error
    }
  },

  setStatusMessageToRead: async (messageUuid) => {
    try {
      const result = await axios.put(`/messages/${messageUuid}/read`)
      return result
    } catch (error) {
      throw error
    }
  },

  sendMessage: async (roomUuid, message) => {
    try {
      const result = await axios.post(`/chats/${roomUuid}/messages`, {
        body: message,
      })
      return result
    } catch (error) {
      throw error
    }
  },

  blockChatRoom: async (roomUuid, block) => {
    try {
      const result = await axios.put(`/chats/${roomUuid}/block`, {
        block,
      })
      return result
    } catch (error) {
      throw error
    }
  },

  deleteChatRoom: async (roomUuid) => {
    try {
      const result = await axios.delete(`/chats/${roomUuid}`)
      return result
    } catch (error) {
      throw error
    }
  },
}
