import React from "react"
import { useLocation } from "react-router-dom"
import queryString from "query-string"
import OpportunityEditContainer from "../../containers/OpportunityEditContainer/OpportunityEditContainer"

const OpportunityEditPage = () => {
  const location = useLocation()
  const values = queryString.parse(location.search)

  return <OpportunityEditContainer
      id={values.id}
      variant={values.variant}
      isPrivate={values.private}
  />
}

export default OpportunityEditPage
