import React from "react"

import styles from "./index.module.scss"
import services from "../../services"
import Loading from "../../components/Loading/Loading"
import XYCenterAlignedWindow from "../../components/XYCenterAlignedWindow"
import { ReactComponent as DangerSign } from "../../images/icons/danger-sign.svg"
import { useParams, useHistory } from "react-router-dom"

const AccountSettingsEmailConfirmPage = () => {
  const [state, setState] = React.useState({
    isLoading: true,
    isExpired: false,
  })

  const params = useParams()
  const history = useHistory()

  React.useEffect(() => {
    let timer = null

    if (params.token) {
      services.accountServices
        .confirmUpdateEmail(params.token)
        .then(() =>
          setState({
            isLoading: false,
            isExpired: false,
          }),
        )
        .catch(() =>
          setState({
            isLoading: false,
            isExpired: true,
          }),
        )
        .finally(() => {
          timer = setTimeout(() => {
            history.push("/")
          }, 4000)
        })
    } else {
      setState({
        isLoading: false,
        isExpired: false,
      })
    }

    return () => timer && clearTimeout(timer)
  }, [history, params.token])

  return (
    <div className={styles.pageWrap}>
      {state.isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {state.isExpired ? (
            <XYCenterAlignedWindow>
              <div class={styles.emailConfirmedMsgDanger}>
                <div className={styles.dangerSign}>
                  <DangerSign />
                </div>

                <p>
                  This link has expired.
                  <br />
                  You will be redirected to main page in few seconds
                </p>
              </div>
            </XYCenterAlignedWindow>
          ) : (
            <XYCenterAlignedWindow title="Congratulations! New email address confirmed">
              <div className={styles.msgDescription}>
                You will be redirected to main page in few seconds
              </div>
            </XYCenterAlignedWindow>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default AccountSettingsEmailConfirmPage
