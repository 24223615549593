import React from "react"
import Popup from "../../Popup/Popup"
import DepartmentCreatorForm from "../../../forms/DepartmentCreatorForm/DepartmentCreatorForm"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"

const DepartmentCreatorPopup = ({
  onSave,
  visible,
  closeAction,
  departmentInfo,
  title,
  maxNumber,
}) => {
  const { width } = useWindowHeightWidth()

  return (
    <>
      {visible && (
        <Popup
          isCrossIcon
          title={title}
          width={width < 601 ? "340" : "496"}
          closeAction={closeAction}
          closeByOutsideClick={false}
        >
          <DepartmentCreatorForm
            maxNumber={maxNumber}
            closeAction={closeAction}
            departmentInfo={departmentInfo}
            handleSave={onSave}
          />
        </Popup>
      )}
    </>
  )
}

export default DepartmentCreatorPopup
