import React from "react"
import PropTypes from "prop-types"

import { ReactComponent as Thumb } from "../../images/icons/thumb.svg"
import classnames from "classnames"
import CustomTooltip from "../CompanyUserInfo/CustomTooltip"
import styles from "./ApproveRejectProspect.module.scss"

const ApproveRejectProspect = ({
  id,
  handleReject,
  handleApprove,
  isApproved,
  acceptVisible,
}) => {
  acceptVisible = acceptVisible === undefined ? true : acceptVisible
  return (
    <div className={styles.approveRejectWrap}>
      <CustomTooltip text="Reject">
        <span className={styles.reject} onClick={() => handleReject(id)}>
          <Thumb />
        </span>
      </CustomTooltip>

    {acceptVisible && (
    <CustomTooltip text="Accept">
        <span
        className={classnames(styles.approve, {
            [styles.active]: isApproved,
        })}
        onClick={() => handleApprove(id)}
        >
        <Thumb />
        </span>
    </CustomTooltip>
    )}
    </div>
  )
}

export default ApproveRejectProspect

ApproveRejectProspect.defaultProps = {
  isApproved: false,
}

ApproveRejectProspect.propTypes = {
  id: PropTypes.string.isRequired,
  handleReject: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  isApproved: PropTypes.bool,
}
