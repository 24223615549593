import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import styles from "./index.module.scss"
import AttendeesUser from "../AttendeesUser"
import { Link } from "react-router-dom"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"

const AttendeesList = ({
  attendees,
  attendeesCount,
  companyUuid,
  fullPage,
}) => {
  const [attendeesShown, setAttendeesShown] = React.useState(0)
  const { width } = useWindowHeightWidth()

  React.useEffect(() => {
    setAttendeesShown(getAttendeesAmount(width, fullPage))
  }, [fullPage, width])

  return (
    <div className={styles.listContainer}>
      <div className={styles.listHeader}>
        <h3 className={styles.listTitle}>
          Attendees
          <span className={styles.listCount}>
            {attendeesCount && ` (${attendeesCount})`}
          </span>
        </h3>

        {attendeesCount >= attendeesShown && companyUuid && (
          <Link
            to={`/resources/${companyUuid}/attendees`}
            className={styles.listViewAll}
          >
            View all
          </Link>
        )}
      </div>

      <div className={styles.listWrap}>
        {getAttendeesList(attendees, attendeesShown).map((item) => (
          <div
            key={item.uuid}
            className={classnames(
              styles.listItem,
              fullPage && styles.withSidebar,
            )}
          >
            <AttendeesUser data={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

AttendeesList.propTypes = {
  attendeesCount: 0,
  companyUuid: null,
  fullPage: false,
}

AttendeesList.propTypes = {
  attendees: PropTypes.arrayOf(
    PropTypes.shape({
      attendee_type: PropTypes.string,
      name: PropTypes.string,
      photo: PropTypes.string,
      uuid: PropTypes.string,
    }),
  ).isRequired,
  attendeesCount: PropTypes.number,
  companyUuid: PropTypes.string,
  fullPage: PropTypes.bool,
}

export default AttendeesList

const getAttendeesAmount = (width, fullPage) => {
  let result = 0

  if (fullPage) {
    return 0
  }

  if (width <= 425) {
    return 8
  }

  if (width <= 768) {
    return 4
  }

  result = 6

  return result
}

const getAttendeesList = (attendees, attendeesShown) => {
  let resultArray = [...attendees]

  const orginizer = attendees.find(
    (attendee) => attendee.attendee_type === "organizer",
  )

  if (orginizer) {
    resultArray = [
      orginizer,
      ...attendees.filter((attendee) => attendee.attendee_type !== "organizer"),
    ]
  }

  if (!attendeesShown) {
    return resultArray
  }

  return resultArray.slice(0, attendeesShown)
}
