import React from "react"
import Popup from "../../../Popup/Popup"
import Button from "../../../Button/Button"
import { paths } from "../../../../routes/paths"
import styles from "./AccessCheckerPopup.module.scss"

const AccessCheckerPopup = ({ visible, closeAction }) => (
  <>
    {visible && (
      <Popup dialog isCrossIcon width={400} crossIconAction={closeAction}>
        <h3 className={styles.title}>You are not registered</h3>
        <p className={styles.message}>
          Please sign up or sign in if you want to see more.
        </p>
        <div className={styles.btnsWrapper}>
          <Button
            buttonStyle="outlinedBlack"
            onClick={() => {
              window.open(window.location.origin + paths.login, "_blank")
            }}
          >
            Sign In
          </Button>
          {/*<Button*/}
          {/*  onClick={() => {*/}
          {/*    window.open(*/}
          {/*      window.location.origin + paths.signUp("", 1),*/}
          {/*      "_blank",*/}
          {/*    )*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Sign Up*/}
          {/*</Button>*/}
        </div>
      </Popup>
    )}
  </>
)

export default AccessCheckerPopup
