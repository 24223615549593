import { useState, useEffect } from "react"
import { options } from "./options"

const getOption = (roleType, selectedType) => {
  const selectedOption = options.find(
    (option) => option.role === roleType && option.id === selectedType,
  )
  return selectedOption || options[0]
}
const useOption = (roleType, selectedType) => {
  const [option, setOption] = useState(getOption(roleType, selectedType))
  useEffect(() => {
    setOption(getOption(roleType, selectedType))
  }, [roleType, selectedType])
  return [option, setOption]
}
export default useOption
