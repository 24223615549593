import React from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import InspirationArticleCard from "../../../../components/cards/InspirationArticleCard"
import { addScrollResult } from "../../useSearch/actionCreators"
import articleServices from "../../../../services/articleServices"
import { checkIsFilterUsed } from "./helpers"
import styles from "./index.module.scss"
import {CircularProgress} from "@material-ui/core";

const MainWrapper = ({ state, dispatch }) => {
  const queryParams = state.searchParamGroup
  const { itemList, itemCount, isFetching } = state.requestResultGroup
  const isFilterUsed = checkIsFilterUsed(state.requestResultGroup)
  if(isFetching) {
    return <div className={styles.loader}>
      <CircularProgress />
    </div>
  }
  return (
    <>
      {!!itemCount && (
        <div className={styles.itemList}>
          <InfiniteScroll
            className={styles.scrollBlock}
            dataLength={itemList.length}
            next={() => {
              const requestParams = {
                ...state.searchParamGroup,
                page: Math.round(itemList.length / 15) + 1,
              }
              articleServices
                .getInspirationArticlePage(requestParams)
                .then(({ data }) => {
                  dispatch(addScrollResult(data))
                })
            }}
            hasMore={itemList.length < itemCount}
          >
            {itemList.map((item) => (
              <InspirationArticleCard
                key={item.uuid}
                uuid={item.uuid}
                classes={styles.card}
                type={item.article_type}
                userAvatar={item.user?.photo}
                userFullName={item.user?.name}
                publishTimestamp={item.created_at}
                title={item.title}
                description={item.content}
                photoUrl={item.image_url}
                commentCount={item.comments_count}
                reputation={item.reputation}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
      {!itemCount && !queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Nothing here yet.
          <br />
          Be the first one to contribute.
        </p>
      )}
      {!itemCount && !!queryParams.query && !isFilterUsed && (
        <p className={styles.notFoundBlock}>
          Sorry, we found 0 matches.
          <br />
          Please use other keywords or filters.
        </p>
      )}
      {!itemCount && isFilterUsed && (
        <p className={styles.notFoundBlock}>
          There are no results that match your search.
          <br />
          Please try adjusting your filters.
        </p>
      )}
    </>
  )
}
export default MainWrapper
