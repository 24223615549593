import axios from "./axios"
import queryString from "query-string"

export default {
  getIndividuals: async (requestParams) => {
    const search = queryString.stringify(
      {
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/individuals?${search}`)
    } catch (error) {
      throw error
    }
  },
}
