import axios from "./axios"
import queryString from "query-string"

export default {
  updateOpportunity: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (typeof value === "object") {
          for (let key in value) {
            let itemName = `${item}_${key}`
            let itemValue = value[key]
            if (itemName === "photo_path") {
              formattedData["photo"] = formattedData[item].file
            } else if (itemName === "video_path") {
              formattedData["video_file"] = formattedData[item].file.file
            } else if (itemName === "video_file" || itemName === "photo_file") {
            } else {
              formattedData[itemName] = itemValue
            }
          }
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo"
        )
          form.append(item, value)
      })
      return await axios.put(`/opportunities/${data.uuid}`, form)
    } catch (error) {
      throw error
    }
  },
  createOpportunity: async (data) => {
    try {
      let formattedData = { ...data }
      let form = new FormData()

      if (data?.video?.file === undefined) {
        delete formattedData["video"]
      }
      if (data?.photo?.file === undefined) {
        delete formattedData["photo"]
      }
      Object.entries(formattedData).forEach(([item, value]) => {
        if (typeof value === "string" || typeof value === "number")
          formattedData[item] = value
        if (typeof value === "object") {
          for (let key in value) {
            let itemName = `${item}_${key}`
            let itemValue = value[key]
            if (itemName === "photo_path") {
              formattedData["photo"] = formattedData[item].file
            } else if (itemName === "video_path") {
              formattedData["video_file"] = formattedData[item].file.file
            } else if (itemName === "video_file" || itemName === "photo_file") {
            } else {
              formattedData[itemName] = itemValue
            }
          }
        }
      })
      delete formattedData["video_path"]
      delete formattedData["video_name"]
      delete formattedData["video_id"]

      delete formattedData["photo_path"]
      delete formattedData["photo_name"]
      delete formattedData["photo_id"]

      Object.entries(formattedData).forEach(([item, value]) => {
        if (
          typeof value === "string" ||
          typeof value === "number" ||
          item === "video_file" ||
          item === "photo"
        )
          form.append(item, value)
      })
      return await axios.post(`/opportunities/`, form)
    } catch (error) {
      throw error
    }
  },
  getOpportunity: async (id) => {
    try {
      return await axios.get(`/opportunities/${id}`)
    } catch (error) {
      throw error
    }
  },
  getOpportunitiesByUuid: async (uuid, params) => {
    try {
      return await axios.get(`/companies/${uuid}/opportunities`, { params })
    } catch (e) {
      throw e
    }
  },
  deleteOpportunityByUuid: async (uuid) => {
    try {
      return await axios.delete(`/opportunities/${uuid}`)
    } catch (e) {
      throw e
    }
  },
  getOpportunities: async (requestParams) => {
    const search = queryString.stringify(
      {
        type: "opportunity",
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/opportunities?${search}`)
    } catch (error) {
      throw error
    }
  },
  getTopOpportunities: async (uuid, type, limit = 4) => {
    try {
      return await axios.get("/top_opportunities", {
        params: {
          opportunity_type: type,
          uuid,
          limit,
        },
      })
    } catch (e) {
      throw e
    }
  },
  sharePrivateOpportunity: async (uuid, data) => {
    console.log("Sharing private opportunity")
    console.log(uuid)
    console.log(data)
    let formData = new FormData()
    formData.append("emails", JSON.stringify(data.emails))
    formData.append("companies", JSON.stringify(data.companies))
    console.log(formData)
    try {
      return await axios.post(`/opportunities/${uuid}/share`, formData)
    } catch (e) {
      throw e
    }
  }
}
