import React from "react"
import classNames from "classnames"
import styles from "./SignUpContainer.module.scss"
import Button from "../../components/Button/Button"
import { Link } from "react-router-dom"
import Bg from "../../images/signUp/bg.jpg"

const SignUpContainer = ({
  title,
  subtitle,
  description,
  children,
  onBack,
  onNext,
  isButtonSubmit,
  withBackBtn,
  className,
}) => {
  return (
    <div
      className={classNames(styles.signUpContainer, className)}
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className={classNames("smallContainer", "fullWidth", styles.wrap)}>
        <h2 className={styles.mainTitle}>{title}</h2>
        <div className={styles.subtitleWrap}>
          <span className={classNames("text18m", styles.subtitle)}>
            {subtitle}
          </span>
          <div className={classNames("note14r", styles.description)}>
            {description}
          </div>
        </div>
        {children}
        <span className={classNames("note14r", styles.mobileSignInLinkWrap)}>
          Do you have an account?{" "}
          <Link to="/login" className={styles.signInLink}>
            Sign In
          </Link>
        </span>
        <div
          className={classNames(styles.actionBtnsWrap, {
            [styles.withBackBtn]: withBackBtn,
          })}
        >
          {withBackBtn && (
            <Button
              buttonStyle="outlined"
              onClick={onBack}
              className={styles.backBtn}
            >
              Back
            </Button>
          )}
          <div className={styles.nextWrap}>
            <span className={classNames("note14r", styles.signInLinkWrap)}>
              Do you have an account?{" "}
              <Link
                to="/login"
                className={classNames("goldLink", styles.signInLink)}
              >
                Sign In
              </Link>
            </span>
            <Button
              type={isButtonSubmit ? "submit" : "button"}
              onClick={onNext}
              className={styles.nextBtn}
            >
              Next step
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUpContainer
