import React, { useState, useEffect } from "react"
import classNames from "classnames"
import ResourceSearchForm from "../../../../forms/ResourceSearchForm"
import { setSearchQuery } from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const SearchFormWrapper = ({ state, dispatch }) => {
  const [query, setQuery] = useState(state.searchParamGroup.query)
  useEffect(() => {
    setQuery(state.searchParamGroup.query)
  }, [state.requestResultGroup])
  return (
    <div className={classNames(styles.searchFormContainer, styles.formWrapper)}>
      <ResourceSearchForm
        placeholder="Search for resources"
        searchQuery={query}
        onChange={(query) =>
          setQuery(query.length > 200 ? query.substring(0, 200) : query)
        }
        onSearch={() => dispatch(setSearchQuery(query.trim()))}
      />
    </div>
  )
}
export default SearchFormWrapper
