import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./index.module.scss"
import Label from "../../elements/Label"
import FormattedDate from "../../elements/FormattedDate"
import { ReactComponent as HeartIcon } from "../../../images/icons/heartIcon.svg"
import { ReactComponent as HeartFilledIcon } from "../../../images/icons/heart-filled.svg"
import { opportunutiOptimizeText } from "../../../helpers/textHelpers"
import useWindowHeightWidth from "../../../hooks/useWindowHeightWidth"
import CustomTooltip from "../../CompanyUserInfo/CustomTooltip"
import services from "../../../services"
import ConfirmCancelPopup from "../../Popup/ConfirmCancelPopup"
import { toast } from "react-toastify"
import ToastInfo from "../../ToastContents/ToastInfo"

const OpportunityCard = ({
  uuid,
  classes,
  type,
  caption,
  avatar,
  description,
  timestamp,
  location,
  onClick,
  isFavorite,
  removeFromFavorites,
  visibleFavoriteButton,
}) => {
  const { width } = useWindowHeightWidth()

  const [favorite, setFavorite] = React.useState(isFavorite)

  const addToFavorites = () => {
    services.favoriteServices
      .addRemoveToList({
        type: "opportunity",
        uuid: uuid,
        favorite: !favorite,
      })
      .then(() => {
        setFavorite(!favorite)

        if (favorite) {
          removeFromFavorites(uuid)
        } else {
          toast.info(
            <ToastInfo>
              The <span className={styles.highlighted}>{caption}</span>{" "}
              opportunity was successfully added to your Favorites
            </ToastInfo>,
            {
              position: toast.POSITION.TOP_RIGHT,
            },
          )
        }
      })
  }

  const [confirmPopupOpened, setConfirmPopupOpened] = React.useState(false)

  return (
    <div
      className={classNames(styles.card, classes)}
      onClick={(e) => !confirmPopupOpened && onClick(e)}
    >
      {confirmPopupOpened && (
        <ConfirmCancelPopup
          cancelMessage="Are you sure you want to delete opportunity from favorites?"
          setIsPopupOpened={setConfirmPopupOpened}
          handleCancel={() => {
            addToFavorites()
            setConfirmPopupOpened(false)
          }}
        />
      )}

      <Label classes={styles.label} type={type} />

      <div
        className={styles.avatar}
        style={{ backgroundImage: `url(${avatar})` }}
      />

      <p className={styles.caption}>{caption}</p>

      <p className={classNames(styles.description)}>
        {opportunutiOptimizeText(description, width < 769 ? 50 : 80)}
      </p>

      <div className={styles.bottomInfoWrap}>
        <p className={styles.date}>
          <FormattedDate timestamp={timestamp} />
        </p>

        <CustomTooltip name={`${location.head} • ${location.city}`}>
          <p className={styles.location}>
            {location.head} <br />
            {location.city}
          </p>
        </CustomTooltip>
      </div>

      <div
        className={classNames("iconWrap", styles.heart)}
        onClick={(e) => {
          e.stopPropagation()

          if (!favorite) {
            addToFavorites()
          } else {
            setConfirmPopupOpened(true)
          }
        }}
        data-type="button"
      >
        {visibleFavoriteButton && (
          <span>{favorite ? <HeartFilledIcon /> : <HeartIcon />}</span>
        )}
      </div>
    </div>
  )
}

OpportunityCard.defaultProps = {
  isFavorite: false,
  removeFromFavorites: () => {},
  visibleFavoriteButton: true,
}

OpportunityCard.propTypes = {
  uuid: PropTypes.string.isRequired,
  /** Card's caption */
  caption: PropTypes.string.isRequired,
  /** Url for avatar icon */
  avatar: PropTypes.string.isRequired,
  /** Card's description in draft-js string */
  description: PropTypes.string.isRequired,
  /** Date of publish (unix format) for `FormattedDate` component */
  timestamp: PropTypes.number.isRequired,
  /** Location */
  location: PropTypes.shape({
    head: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
  }).isRequired,
  /** Action on click */
  onClick: PropTypes.func,
  isFavorite: PropTypes.bool,
  removeFromFavorites: PropTypes.func,
}

export default OpportunityCard
