export const options = [
  {
    id: "",
    value: "All Types",
  },
  {
    id: "trends",
    value: "Trends",
  },
  {
    id: "skills",
    value: "Skills",
  },
]
