import React from "react"
import PropTypes from "prop-types"

import styles from "./index.module.scss"

const NoDataPlaceholder = ({ children }) => {
  return <div className={styles.placeholderWrap}>{children}</div>
}

NoDataPlaceholder.propTypes = {
  children: PropTypes.any.isRequired,
}

export default NoDataPlaceholder
