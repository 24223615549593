import React from "react"
import FlexCardItem from "../../cards/ProspectsCard/FlexCardItem"
import styles from "./ProspectsList.module.scss"
import { useHistory } from "react-router-dom"
import EmptyList from "../EmplyList/EmptyList"
import Loading from "../../Loading/Loading"
import Pagination from "../../elements/Pagination"
import { paths } from "../../../routes/paths"
import services from "../../../services"

const ProspectsList = ({
  prospects,
  handleReject,
  handleApprove,
  hasAppliedFilters,
  loading,
  page,
  setCurrentPage,
  totalPageCount,
}) => {
  const history = useHistory()
  const onOpenChat = (chatUuid, seeker) => {
    if (!chatUuid) {
      services.chatServices
        .createChatRoom({ type: seeker.seeker_type, uuid: seeker.uuid })
        .then((result) => history.push(paths.chats(result.data.uuid)))
        .catch((error) => console.log(error))
    } else {
      history.push(paths.chats(chatUuid))
    }
  }

  if (loading) return <Loading />
  if (!prospects) return <Loading />
  if (prospects && prospects.length === 0 && !hasAppliedFilters)
    return <EmptyList messageArray={["No prospects yet"]} />
  if (prospects && prospects.length === 0 && hasAppliedFilters)
    return (
      <EmptyList
        messageArray={[
          "There are no results that match your search.",
          "Please try adjusting your filters.",
        ]}
      />
    )

  return (
    <>
      {prospects &&
        prospects.map((prospect) => (
          <FlexCardItem
            key={prospect.uuid}
            data={prospect}
            handleReject={handleReject}
            handleApprove={handleApprove}
            onOpenChat={() => {
              onOpenChat(prospect.chat, prospect.seeker)
            }}
          />
        ))}
      <div className={styles.pagination}>
        {totalPageCount !== 0 && totalPageCount > 8 && (
          <Pagination
            currentPageNumber={page}
            totalPageCount={Math.ceil(totalPageCount / 8) || 1}
            onSelect={setCurrentPage}
          />
        )}
      </div>
    </>
  )
}

export default ProspectsList
