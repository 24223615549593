import React from "react"
import { Badge, withStyles } from "@material-ui/core"

import styles from "./index.module.scss"

const NotificationBadge = ({ children, ...rest }) => {
  const CustomBadge = withStyles({
    badge: {
      right: "auto",
      left: "100%",
      transform: "scale(1) translate(-50%, -50%)",
      border: `2px solid #070e14`,
      borderRadius: 8,
      fontSize: 11,
      fontWeight: 700,
      fontFamily: "Roboto",
      padding: 2,
      backgroundColor: "#F2323F",
      minWidth: 16,
      height: 16,
    },
  })(Badge)

  return (
    <CustomBadge {...rest} color="secondary">
      <div className={styles.notificationIcon}>{children}</div>
    </CustomBadge>
  )
}

export default NotificationBadge
