export const resources = [
  {
    title: "All",
    type: "",
  },
  {
    title: "Advice",
    type: "advice",
  },
  {
    title: "Etiquette",
    type: "etiquette",
  },
  {
    title: "Funding",
    type: "funding",
  },
  {
    title: "Meetup",
    type: "meetup",
  },
  {
    title: "Unspoken Rules",
    type: "unspoken_rules",
  },
]
