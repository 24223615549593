import { isHaveBadWords } from "../../helpers/badWords"

export const nameValidaton = (name) => {
  const letters = /[0-9!$%^&*()_+|~=`{}\[\]:";'<>?,.\/]/
  if (name.trim().length === 0)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct full name that consists of a minimum 1 character",
    }
  if (name.trim().length > 32)
    return {
      valid: false,
      errorMessage:
        "Please enter a correct full name that consists of no more than 32 characters",
    }
  if (name.match(letters))
    return {
      valid: false,
      errorMessage: "Only letters allowed",
    }
  if (isHaveBadWords(name))
    return {
      valid: false,
      errorMessage: "Please don't use bad language",
    }
  return {
    valid: true,
    errorMessage: "",
  }
}
