import axios from "./axios"
import queryString from "query-string"

export default {
  getSpecificInstituteArticle: async (uuid) => {
    try {
      const result = await axios.get(`/institute_articles/${uuid}`)
      return result
    } catch (e) {
      throw e
    }
  },
  getInstituteArticlePage: async (requestParams) => {
    const search = queryString.stringify(
      {
        type: "institute_article",
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/institute_articles?${search}`)
    } catch (error) {
      throw error
    }
  },
  getRecentInstituteArticles: async () => {
    try {
      return await axios.get(`/recent_institute_articles`)
    } catch (error) {
      throw error
    }
  },
  getSpecificInspirationArticle: async (uuid) => {
    try {
      const result = await axios.get(`/inspiration_articles/${uuid}`)
      return result
    } catch (e) {
      throw e
    }
  },
  getInspirationArticlePage: async (requestParams) => {
    const search = queryString.stringify(
      {
        type: "inspiration_article",
        ...requestParams,
      },
      { skipEmptyString: true },
    )
    try {
      return await axios.get(`/inspiration_articles?${search}`)
    } catch (error) {
      throw error
    }
  },
  getRecentInspirationArticles: async () => {
    try {
      return await axios.get(`/recent_inspiration_articles`)
    } catch (error) {
      throw error
    }
  },
  getMainInstituteArticle: async (type, limit) => {
    const search = queryString.stringify({
      article_type: type,
      limit: limit || null,
    })
    try {
      return await axios.get(`/main_institute_articles?${search}`)
    } catch (error) {
      throw error
    }
  },
  voteForInstitudeArticle: async ({ uuid, vote }) => {
    try {
      return await axios.post(`/institute_articles/${uuid}/vote`, { vote })
    } catch (error) {
      throw error
    }
  },
  getMainInspiration: async (type, limit) => {
    const search = queryString.stringify({
      article_type: type,
      limit: limit || null,
    })
    try {
      return await axios.get(`/main_inspiration_articles?${search}`)
    } catch (error) {
      throw error
    }
  },
  voteForInspirationArticle: async ({ uuid, vote }) => {
    try {
      return await axios.post(`/inspiration_articles/${uuid}/vote`, { vote })
    } catch (error) {
      throw error
    }
  },
  getHomePageArticles: async () => {
    try {
      return await axios.get(`/suggestions/main_page_articles`)
    } catch (error) {
      throw error
    }
  },
}
