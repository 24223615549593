import React from "react"
import classNames from "classnames"
import { Link, useHistory } from "react-router-dom"

import styles from "./index.module.scss"
import CompanyInfoItem from "../CompanyInfoItem/CompanyInfoItem"
import QuestionCard from "../cards/QuestionCard"

const ProfileMyQuestions = ({ data, width, anotherUser, uuid }) => {
  const history = useHistory()

  const placeholder =
    anotherUser && !data?.length
      ? "No questions yet"
      : data?.length > 0
      ? null
      : "You didn't add any questions yet"

  const dataLength = width > 1024 ? 3 : 2

  return (
    <CompanyInfoItem
      headTitle={
        anotherUser
          ? "Company/Organization Community Questions"
          : "My Communities"
      }
      headBtnText={width < 451 ? "Add" : "Add Peer Circle/Question"}
      bodyPlaceholderText={placeholder}
      width={width}
      handleButtonClick={() => history.push("/community-topic-edit")}
      anotherUser={anotherUser}
    >
      {data && (
        <React.Fragment>
          <div className={styles.questions}>
            {data.slice(0, dataLength).map((question) => (
              <div key={question.id} className={styles.questionCard}>
                <QuestionCard data={question} inSidebar />
              </div>
            ))}
          </div>

          {data.length >= dataLength && (
            <div className={styles.showMoreBtn}>
              <Link
                to={
                  anotherUser
                    ? `/company/${uuid}/questions`
                    : `/community/my-questions`
                }
                className={classNames("goldLink")}
              >
                <p>Show all questions</p>
              </Link>
            </div>
          )}
        </React.Fragment>
      )}
    </CompanyInfoItem>
  )
}

export default ProfileMyQuestions
