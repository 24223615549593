import React from "react"
import classNames from "classnames"
import Breadcrumbs from "../../../../components/elements/Breadcrumbs"
import { paths } from "../../../../routes/paths"
import styles from "./index.module.scss"
import PageLinedTitle from "../../../../components/elements/PageLinedTitle"

const CaptionWrapper = ({ state }) => {
  const {
    memberCount,
    query,
    account_type,
    isFetching,
  } = state.requestResultGroup

  let title;
  if(account_type === "professional" || account_type === "individual") {
    title = "Professionals"
  } else if(account_type === "company") {
    title = "Companies/Organizations"
  } else if(account_type === "freelancers") {
    title = "Freelancers"
  }

  return isFetching ? (
    <div className={styles.empty}></div>
  ) : (
    <div>
      {!query ? (
        <div className={styles.pageTitle}>
          <PageLinedTitle
            title={title}
          />
        </div>
      ) : (
        <div className="container">
          <Breadcrumbs
            classes={classNames(styles.breadcrumbs)}
            chain={[
              {
                link:
                  account_type === "professional"
                    ? paths.memberSearch({
                        account_type: "professional",
                      })
                    : account_type === "freelancers" ? paths.memberSearch({
                        account_type: "freelancers",
                      }) : paths.memberSearch({
                        account_type: "company",
                      }),
                text:
                  account_type === "professional" ? "Professionals" : "Companies",
              },
              { text: "Search" },
            ]}
          />
          {!!memberCount && (
            <div className={styles.resultCount}>
              {`We found ${memberCount} results for “${query}”`}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default CaptionWrapper
