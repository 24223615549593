import React from "react"
import PropTypes from "prop-types"

import SignUpContainer from "./SignUpContainer"
import IndividualStep2Form from "../../forms/SignUpForms/IndividualStep2Form"
import SignUpWizard from "../../components/SignUpWizard"

function SignUpIndividualStep2Container({
  onHandleNext,
  onBack,
  handleSaveUser,
}) {
  return (
    <SignUpContainer
      title="Sign Up Process"
      subtitle="Step 2: Profile Creation"
      description="Fill up info to continue to platform"
      onBack={onBack}
      onNext={onHandleNext}
      isButtonSubmit
      withBackBtn
    >
      <SignUpWizard userType="individual" />

      <IndividualStep2Form handleSaveUser={handleSaveUser} />
    </SignUpContainer>
  )
}

SignUpIndividualStep2Container.propTypes = {
  user: PropTypes.object,
  onHandleNext: PropTypes.func,
  onHandleRole: PropTypes.func,
}

export default SignUpIndividualStep2Container
