import React from "react"
import styles from "./ArticlePage.module.scss"
import ArticleContainer from "../../containers/ArticleContainer/ArticleContainer"

const ArticlePage = () => {
  return (
    <div className={styles.pageWrapper}>
      <ArticleContainer />
    </div>
  )
}

export default ArticlePage
