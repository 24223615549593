import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import Label from "../../elements/Label"
import FormattedDate from "../../elements/FormattedDate"
import { ReactComponent as PlayIcon } from "../../../images/icons/playIcon.svg"
import styles from "./index.module.scss"

const InstituteCard = ({
  classes,
  type,
  thumbnailUrl,
  description,
  companyName,
  companyAvatar,
  companyLocation,
  publicationDate,
  onClick,
  photoUrl,
}) => {
  const imageUrl = thumbnailUrl || photoUrl
  return (
    <div className={classNames(styles.card, classes)} onClick={onClick}>
      <Label classes={styles.label} type={type} />
      <div
        className={styles.thumbnail}
        style={{
          ...(imageUrl && { backgroundImage: `url(${imageUrl})` }),
        }}
      >
        {thumbnailUrl && <PlayIcon />}
      </div>
      <div className={styles.opportunityBlock}>
        <div
          className={styles.companyAvatar}
          style={{
            ...(companyAvatar && { backgroundImage: `url(${companyAvatar})` }),
          }}
        />
        <span className={styles.description}>{description}</span>
      </div>
      <div className={styles.companyBlock}>
        <span className={styles.item}>{companyName}</span>
        <span className={styles.dot}>{"•"}</span>
        <span className={styles.item}>{companyLocation}</span>
        <FormattedDate
          classes={styles.publicationDate}
          timestamp={publicationDate}
        />
      </div>
    </div>
  )
}
InstituteCard.propTypes = {
  /** External styles */
  classes: PropTypes.any,
  /** Opportunity type from backend for `Label` */
  type: PropTypes.string.isRequired,
  /** Url of video */
  videoUrl: PropTypes.string.isRequired,
  /** Opportunity description */
  description: PropTypes.string.isRequired,
  /** Company Name */
  companyName: PropTypes.string.isRequired,
  /** Company Avatar */
  companyAvatar: PropTypes.string,
  /** Company Location */
  companyLocation: PropTypes.string.isRequired,
  /** Publication date timestamp from backend */
  publicationDate: PropTypes.string.isRequired,
  /** Callback for click on the card */
  onClick: PropTypes.func,
}
export default InstituteCard
