import React, { Fragment, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "./UploadMediaPopup.module.scss"
import Popup from "../../Popup/Popup"
import Button from "../../Button/Button"
import { ReactComponent as AddIcon } from "../../../images/icons/addImage.svg"
import LinearProgress from "@material-ui/core/LinearProgress"
import { renderItems } from "./render-helpers"
import { withStyles } from "@material-ui/core/styles"

const UploadMediaPopup = ({
  visible,
  handleCancel,
  handleUpload,
  uploadedMedias,
  handlePopupMediaDelete,
  handleUploadMedia,
  galleryData,
  error,
  loader,
  title,
  acceptableFileTypes,
  mediaType,
  anotherUser,
  classes,
}) => {
  const inputRef = useRef(null)

  return (
    <Fragment>
      {visible && (
        <Popup
          className={styles.uploadPopup}
          isCrossIcon
          title={title}
          button={
            <Button
              buttonStyle="outlinedBlack"
              className={styles.popupBtns}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="black"
              className={styles.popupBtns}
              onClick={handleUpload}
              disabled={!uploadedMedias.length || loader}
            >
              {uploadedMedias.length ? "Publish" : "Upload"}
            </Button>
          }
          closeAction={handleCancel}
        >
          <div
            className={classNames(styles.uploadMediaContent, {
              [styles.withError]: error,
            })}
          >
            {!loader && (
              <>
                <div className={styles.maxWeight}>
                  Maximum upload file size:{" "}
                  {mediaType === "video" ? "40MB" : "10MB"}
                </div>
                <div className={styles.types}>{acceptableFileTypes}</div>{" "}
              </>
            )}

            {error && (
              <span
                className={classNames("note14r", styles.errorMessage, {
                  [styles.onlyErrorMessage]: galleryData.length >= 12,
                })}
              >
                {error}
              </span>
            )}

            {galleryData.length < 12 && (
              <>
                <div className={styles.newMediaWrap}>
                  {loader ? (
                    <div className={styles.loaderContainer}>
                      <LinearProgress
                        classes={{
                          colorPrimary: classes.colorPrimary,
                          barColorPrimary: classes.barColorPrimary,
                        }}
                        variant={
                          typeof loader === "number" ? "determinate" : null
                        }
                        value={loader}
                        style={{ width: "100%" }}
                      />
                      <p>{`${loader}%`}</p>
                    </div>
                  ) : (
                    <Fragment>
                      {uploadedMedias.map((media) => {
                        return renderItems(mediaType, {
                          ...media,
                          onDelete: () => {
                            handlePopupMediaDelete(media.id)
                          },
                          anotherUser: anotherUser,
                        })
                      })}
                      <div
                        className={classNames(styles.uploadMediaBtn, {
                          [styles.emptyMedia]: !uploadedMedias.length,
                        })}
                        onClick={() => inputRef.current.click()}
                      >
                        <AddIcon />
                        <input
                          ref={inputRef}
                          type="file"
                          onChange={handleUploadMedia}
                          multiple
                          accept={acceptableFileTypes}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </>
            )}
          </div>
        </Popup>
      )}
    </Fragment>
  )
}

UploadMediaPopup.propTypes = {
  visible: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  uploadedMedias: PropTypes.array.isRequired,
  handlePopupMediaDelete: PropTypes.func.isRequired,
  handleUploadMedia: PropTypes.func.isRequired,
  galleryData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  mediaType: PropTypes.oneOf(["image", "video"]).isRequired,
  error: PropTypes.string,
  loader: PropTypes.number,
  acceptableFileTypes: PropTypes.string,
}

const s = () => ({
  colorPrimary: {
    backgroundColor: "rgb(247,232,234)",
    height: "8px",
    borderRadius: "4px",
  },
  barColorPrimary: {
    backgroundColor: "#B4182D",
    height: "8px",
    borderRadius: "4px",
  },
})

export default withStyles(s)(UploadMediaPopup)
