import React, { useState, useEffect } from "react"
import classNames from "classnames"
import OpportunitySearchForm from "../../../../forms/OpportunitySearchForm"
import { setSearchQuery } from "../../useSearch/actionCreators"
import styles from "./index.module.scss"

const SearchFormWrapper = ({ state, dispatch }) => {
  const { type, account_type } = state.requestResultGroup
  const [query, setQuery] = useState(state.requestResultGroup.query)
  useEffect(() => {
    setQuery(state.requestResultGroup.query || "")
  }, [state.requestResultGroup])
    let searchPlaceholder;
    switch (account_type) {
        case 'company': {
            searchPlaceholder = "Search for companies"
            break;
        }
        case 'professional': {
            searchPlaceholder = "Search for professional"
            break;
        }
        case 'freelancers': {
            searchPlaceholder = "Search for companies"
            break;
        }
    }

    if(type === "companies") {
        searchPlaceholder = "Search for companies";
    }

  return (
    <div className={classNames(styles.searchFormContainer, styles.fullInput)}>
      <OpportunitySearchForm
        emptyMode={false}
        placeholder={searchPlaceholder}
        searchQuery={query}
        onChange={(query) =>
          setQuery(query.length > 200 ? query.substring(0, 200) : query)
        }
        onSearch={() => dispatch(setSearchQuery(query.trim()))}
      />
    </div>
  )
}
export default SearchFormWrapper
