import React from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"

import styles from "./index.module.scss"
import CommunityQuestionsContainer from "../../containers/CommunityQuestionsContainer"
import services from "../../services"

const CommunityMyQuestionsPage = () => {
  const location = useLocation()
  const userInfo = useSelector((state) => state.user)

  const currentPage = getCurrentPage(location)

  const [state, setState] = React.useState({
    isLoading: false,
  })

  React.useEffect(() => {
    setState({
      isLoading: true,
    })

    services.communityServices
      .getMyQuestions({
        page: currentPage,
      })
      .then((result) => {
        setState({
          ...result.data,
          isLoading: false,
        })
      })
      .catch((e) => console.log(e))
  }, [currentPage])

  return (
    <div className={styles.pageWrap}>
      <CommunityQuestionsContainer
        breadcrumbs={[
          userInfo.company.name
            ? {
                link: "/company-profile",
                text: userInfo.company.name,
              }
            : {
                link: "/individual-profile",
                text: "My profile",
              },
          {
            text: "My Questions",
          },
        ]}
        pageTitle="My Questions"
        currentPage={currentPage}
        {...state}
      />
    </div>
  )
}

export default CommunityMyQuestionsPage

const getCurrentPage = (location) => {
  const searchParams = new URLSearchParams(location.search)
  return Number(searchParams.get("page")) || 1
}
