import React from "react"
import styles from "./InfoSection.module.scss"
import classNames from "classnames"
import { useHistory } from "react-router-dom"
import { paths } from "../../../routes/paths"
const InfoSection = ({ articles = [] }) => {
  const history = useHistory()
  return (
    <div className={styles.container}>
      <div className={classNames("container", styles.wrapper)}>
        <div className={styles.cardsWrapper}>
          {articles?.map((article) => (
            <div
              className={styles.cardWrapper}
              onClick={() => {
                history.push(paths.specificInspirationArticle(article.uuid))
              }}
              key={article.uuid}
            >
              <img
                src={article.image_url}
                alt="Card image"
                className={styles.image}
              />
              <div className={styles.text}>{article.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
export default InfoSection
