import React from "react"
import { useHistory, Prompt } from "react-router-dom"
import { messages } from "../../helpers/constants"
import SearchFormWrapper from "./wrappers/SearchFormWrapper"
import CaptionWrapper from "./wrappers/CaptionWrapper"
import TagWrapper from "./wrappers/TagWrapper"
import MainWrapper from "./wrappers/MainWrapper"
import Button from "../../components/Button/Button"
import QuestionTypeFilter from "../../components/filters/QuestionTypeFilter"
import QuestionSearchFilter from "../../components/filters/QuestionSearchFilter"
import QuestionCard from "../../components/cards/QuestionCard"
import useSearch from "./useSearch"
import {
  setTabFilter,
  setFormFilters,
  resetFormFilters,
} from "./useSearch/actionCreators"
import { paths } from "../../routes/paths"
import styles from "./index.module.scss"

const QuestionSearchContainer = () => {
  const history = useHistory()
  const [state, dispatch] = useSearch()
  const { type, itemList } = state.sideQuestionGroup
  const { searchParamGroup } = state
  return (
    <div className={styles.searchContainer}>
      <SearchFormWrapper state={state} dispatch={dispatch} />
      <CaptionWrapper state={state} />
      <div className={styles.container}>
        <QuestionTypeFilter
          classes={styles.tabFilter}
          searchParamGroup={state.searchParamGroup}
          onSelectType={(questionType) => dispatch(setTabFilter(questionType))}
        />
        <div className={styles.body}>
          <main>
            <TagWrapper
              classes={styles.tagBlock}
              searchParamGroup={state.searchParamGroup}
              dispatch={dispatch}
            />
            <MainWrapper state={state} dispatch={dispatch} />
            <div className={styles.block}>
              <p className={styles.caption}>
                {type === "recent" ? "Latest Topics" : "Popular Topics"}
              </p>
              {itemList.map((item) => (
                <QuestionCard key={item.id} data={item} />
              ))}
            </div>
          </main>
          <aside>
            <div className={styles.sideBar}>
              <Button
                className={styles.btn}
                onClick={() => {
                  history.push(paths.communityMyQuestions)
                }}
              >
                My Communities
              </Button>
              <Button
                className={styles.btn}
                onClick={() => {
                  history.push(paths.communityTopicEdit)
                }}
              >
                Add Peer Circle/Question
              </Button>
              <QuestionSearchFilter
                searchParamGroup={state.searchParamGroup}
                onReset={() => dispatch(resetFormFilters())}
                onSubmit={(values) => dispatch(setFormFilters(values))}
              />
              <div className={styles.block}>
                <p className={styles.caption}>
                  {type === "recent" ? "Latest Topics" : "Popular Topics"}
                </p>
                {itemList.map((item) => (
                  <QuestionCard key={item.id} data={item} inSidebar />
                ))}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  )
}
export default QuestionSearchContainer
