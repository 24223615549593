import React, { useState } from "react"

import styles from "./index.module.scss"
import UserAvatar from "../../components/elements/UserAvatar"

import Button from "../../components/Button/Button"

import TextArea from "../../components/TextArea/TextArea"
import { Prompt } from "react-router"

import { messages } from "../../helpers/constants"
import { isHaveBadWords } from "../../helpers/badWords"

const CommentForm = ({ userInfo, onCommentPost, id, handleCloseForm }) => {
  const maxMessageSize = 546
  const [error, setError] = useState("")
  const [commentText, setCommentText] = useState("")

  const handlePostComment = () => {
    if (commentText.trim() && !error.length) {
      onCommentPost(commentText.trim(), id || null)
      setCommentText("")
      if (handleCloseForm) {
        handleCloseForm()
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      handlePostComment()
    }
    if (e.keyCode === 13 && e.shiftKey === true && !commentText.trim()) {
      e.preventDefault()
    }
  }

  const handleChangeCommentText = (e) => {
    const text = e.target.value
    if (isHaveBadWords(e.target.value)) {
      setError("Please don't use bad language")
    } else {
      setError("")
    }
    setCommentText(text.slice(0, maxMessageSize))
  }

  return (
    <form className={styles.formWrap}>
      <Prompt
        when={Boolean(commentText)}
        message={messages.CONFIRM_LEAVE_FROM_COMMENT}
      />
      <div className={styles.formUserAvatar}>
        <UserAvatar
          src={userInfo.company?.photo || userInfo.photo}
          size={48}
          alt={userInfo.name || userInfo.company?.name}
        />
      </div>

      <div className={styles.formCommentText}>
        <TextArea
          value={commentText}
          onChange={handleChangeCommentText}
          onKeyDown={handleKeyDown}
          border
          error={Boolean(error)}
          errorMessage={error}
          placeholder="Type here..."
        />
      </div>

      <Button
        disabled={!commentText.trim() || error.length}
        onClick={handlePostComment}
      >
        Post comment
      </Button>
    </form>
  )
}

export default CommentForm
