import React, { useCallback } from "react"
import styles from "../ChatsContainer.module.scss"
import MessengerHeader from "../../../components/MessengerHeader/MessengerHeader"
import MessengerChatInput from "../../../components/MessengerChatInput/MessengerChatInput"
import MessagesWrapper from "./MessagesWrapper"
import classnames from "classnames"
import {
  setOpenChat,
  setActiveChat,
  setFirstMessage,
} from "../helpers/actionCreators"
import services from "../../../services"
import { replaceCurrentMessages, getFirstMessageUuid } from "../helpers"
import { paths } from "../../../routes/paths"

const ChatRoomWrapper = ({ state, dispatch, history }) => {
  const { activeChat, isChatOpenedMobile } = state

  const handleBackButton = () => {
    history.push(paths.chats(""))
    dispatch(setOpenChat(false))
  }

  const sendMessage = (message) => {
    if (activeChat.uuid) {
      services.chatServices
        .sendMessage(activeChat.uuid, message)
        .then((result) => {
          const newRoom = replaceCurrentMessages(activeChat, result.data)
          dispatch(setActiveChat(newRoom))
          const firstMessageUuid = getFirstMessageUuid(activeChat)
          dispatch(
            setFirstMessage({
              uuid: firstMessageUuid,
              isEnd: false,
              isFirstLoad: true,
            }),
          )
        })
        .catch((e) => console.log(e))
    }
  }

  const sendMessageMemo = useCallback(sendMessage, [activeChat])

  return (
    <div
      className={classnames(styles.chatRoom, {
        [styles.closed]: !isChatOpenedMobile,
      })}
    >
      <MessengerHeader
        image={activeChat.partner?.photo}
        name={activeChat.partner?.name}
        subject={activeChat.subject}
        uuid={activeChat.partner?.uuid}
        handleMenuClick={() => {}}
        handleBackButton={handleBackButton}
        type={activeChat?.partner?.type}
        state={state}
        dispatch={dispatch}
        history={history}
      />
      <MessagesWrapper state={state} dispatch={dispatch} />
      <MessengerChatInput sendMessage={sendMessageMemo} />
    </div>
  )
}

export default ChatRoomWrapper
