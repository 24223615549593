import React from 'react';
import styles from "./UserLicense.module.css"

const UserLicense = () => {
    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>BRIDGE 2 TECHNOLOGIES</h3>
            <h4 className={styles.subtitle}>End User License Agreement</h4>
            <p className={styles.parag}>This copy of B2T Network ("the Software Product") and accompanying documentation
                is licensed and not sold. This Software Product is protected by copyright laws and treaties, as well as
                laws and treaties related to other forms of intellectual property. Bridge 2 Technologies, LLCor its
                subsidiaries, affiliates, and suppliers (collectively "B2T" or “Licensor”) owns intellectual property
                rights in the Software Product. The Licensee's ("you" or "your" or “Licensee”) license to download, use,
                copy, or change the Software Product is subject to these rights and to all the terms and conditions of
                this End User License Agreement ("Agreement").</p>
            <div className={styles.paragTitle}>Acceptance</div>
            <p className={styles.parag}>YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY SELECTING THE
                "ACCEPT" OPTION AND DOWNLOADING THE SOFTWARE PRODUCT OR BY INSTALLING, USING, OR COPYING THE SOFTWARE
                PRODUCT. YOU MUST AGREE TO ALL OF THE TERMS OF THIS AGREEMENT BEFORE YOU WILL BE ALLOWED TO DOWNLOAD THE
                SOFTWARE PRODUCT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, YOU MUST SELECT "DECLINE"
                AND YOU MUST NOT INSTALL, USE, OR COPY THE SOFTWARE PRODUCT. </p>
            <div className={styles.paragTitle}>License Grant</div>
            <p className={styles.parag}>This Agreement entitles you to a revocable, non-exclusive, non-transferable,
                limited right to install and use one copy of the Software Product. In addition, you may make one
                archival copy of the Software Product. The archival copy must be on a storage medium other than a hard
                drive and may only be used for the reinstallation of the Software Product. This Agreement does not
                permit the installation or use of multiple copies of the Software Product, or the installation of the
                Software Product on more than one computer at any given time, on a system that allows shared use of
                applications, on a multi- user network, or on any configuration or system of computers that allows
                multiple users. Multiple copy, use or installation is only allowed if you obtain an appropriate
                licensing agreement for each user and each copy of the Software Product.</p>
            <div className={styles.paragTitle}>Restrictions on Transfer</div>
            <p className={styles.parag}>Without first obtaining the express written consent of B2T, you may not assign
                your rights and obligations under this Agreement, or redistribute, encumber, sell, rent, lease,
                sublicense, or otherwise transfer your rights to the Software Product.</p>
            <div className={styles.paragTitle}>Restrictions on Use</div>
            <p className={styles.parag}>You may not use, copy, or install the Software Product on any system with more
                than one computer, or permit the use, copying, or installation of the Software Product by more than one
                user or on more than one computer. If you hold multiple, validly licensed copies, you may not use, copy,
                or install the Software Product on any system with more than the number of computers permitted by
                license, or permit the use, copying, or installation by more users, or on more computers than the number
                permitted by license.</p>
            <p className={styles.parag}>You may not decompile, "reverse-engineer," disassemble, or otherwise attempt to
                derive the source code for the Software Product.</p>
            <p className={styles.parag}>You may not use the database portion of the Software Product in connection with
                any software other than the Software Product.</p>
            <div className={styles.paragTitle}>Restrictions on Alteration</div>
            <p className={styles.parag}>You may not modify the Software Product or create any derivative work of the
                Software Product or its accompanying documentation. Derivative works include but are not limited to
                translations. You may not alter any files or libraries in any portion of the Software Product. You may
                not reproduce the database portion or create any tables or reports relating to the database portion.</p>
            <div className={styles.paragTitle}>Restrictions on Copying</div>
            <p className={styles.parag}>You may not copy any part of the Software Product except to the extent that
                licensed use inherently demands the creation of a temporary copy stored in computer memory and not
                permanently affixed on storage medium. You may make one archival copy which must be stored on a medium
                other than a computer hard drive.</p>
            <div className={styles.paragTitle}>Compliance/Enforcemen</div>
            <p className={styles.parag}>(a) The Software Product contains technological copy protection or other
                security features designed to prevent unauthorized use of the Software Product, including features to
                protect against any use of the Software Product that is prohibited by law. Licensee shall not remove,
                disable, circumvent, or otherwise create or implement any workaround to, any such copy protection or
                security features. (b) On an annual basis, or otherwise on Licensor's written request, Licensee may
                conduct a review of its and its Authorized Users use of the Software Product and certify to Licensor in
                a written instrument signed by an officer of Licensee that it is in full compliance with this Agreement
                or, if Licensee discovers any non-compliance, including exceeding the number of copies or Authorized
                Users: (i) Licensee shall immediately remedy such noncompliance and provide Licensor with written notice
                thereof. Licensee shall provide Licensor with all access and assistance as Licensor requests to further
                evaluate and remedy such noncompliance. (ii) If Licensee fails to immediately remedy any identified
                non-compliance, Licensor shall have the right to terminate this Agreement and terminate all further use
                of the Software Product by Licensee. (c) During the Term, Licensor may, in Licensor's sole discretion,
                audit Licensee's use of the Software to ensure Licensee's compliance with this Agreement, provided that
                (i) any such audit shall be conducted on not less than 10 days' prior notice to Licensee, and (ii) no
                more than one audit may be conducted in any 12-month period except for good cause shown. Licensor also
                may, in its sole discretion, audit Licensee's systems within six (6) months after the end of the Term to
                ensure Licensee has ceased use of the Software Product and removed all copies of the Software Product
                from such systems as required hereunder.</p>
            <p className={styles.parag}>(a) The Software Product contains technological copy protection or other
                security features
                designed to prevent unauthorized use of the Software Product, including features to protect
                against any use of the Software Product that is prohibited by law. Licensee shall not remove,
                disable, circumvent, or otherwise create or implement any workaround to, any such copy
                protection or security features. (b) On an annual basis, or otherwise on Licensor's written request,
                Licensee may conduct a review of its and its Authorized Users use of the Software Product and
                certify to Licensor in a written instrument signed by an officer of Licensee that it is in full
                compliance with this Agreement or, if Licensee discovers any non-compliance, including
                exceeding the number of copies or Authorized Users: (i) Licensee shall immediately remedy
                such noncompliance and provide Licensor with written notice thereof. Licensee shall provide
                Licensor with all access and assistance as Licensor requests to further evaluate and remedy such
                noncompliance. (ii) If Licensee fails to immediately remedy any identified non-compliance,
                Licensor shall have the right to terminate this Agreement and terminate all further use of the
                Software Product by Licensee. (c) During the Term, Licensor may, in Licensor's sole discretion,
                audit Licensee's use of the Software to ensure Licensee's compliance with this Agreement,
                provided that (i) any such audit shall be conducted on not less than 10 days' prior notice to
                Licensee, and (ii) no more than one audit may be conducted in any 12-month period except for
                good cause shown. Licensor also may, in its sole discretion, audit Licensee's systems within six
                (6) months after the end of the Term to ensure Licensee has ceased use of the Software Product
                and removed all copies of the Software Product from such systems as required hereunder.
                The Licensee shall fully cooperate with Licensor's personnel conducting such audits and provide
                all reasonable access requested by the Licensor to records, systems, equipment, information, and
                personnel, including machine IDs, serial numbers, and related information. Licensor shall only
                examine information directly related to the Licensee's use of the Software Product. Licensor may
                conduct audits only during Licensee's normal business hours and in a manner that does not
                unreasonably interfere with the Licensee's business operations. Licensor's remedies are
                cumulative and are in addition to, and not in lieu of, all other remedies the Licensor may have at
                law or in equity, whether under this Agreement or otherwise. </p>
            <div className={styles.paragTitle}>Intellectual Property Rights </div>
            <p className={styles.parag}>Licensee acknowledges and agrees that the Software Product and Documentation are provided
                under license, and not sold, to Licensee. Licensee does not acquire any ownership interest or
                rights in the Software Product or Documentation under this Agreement, or any other rights
                thereto, other than to use the same in accordance with the license granted and subject to all
                terms, conditions, and restrictions under this Agreement.</p>
            <div className={styles.paragTitle}>Termination</div>
            <p className={styles.parag}>B2T may, in its sole discretion, at any time and for any or no reason, suspend or terminate this
                License, and the rights the rights afforded to you hereunder with or without prior notice.
                Furthermore, if you fail to comply with any terms and conditions of this License, then this
                License and any rights afforded to you hereunder shall terminate automatically, without any
                notice or other action by B2T. Upon the termination of this License, you shall cease all use of the
                Software Product and uninstall the Software Product. </p>
            <div className={styles.paragTitle}>Term and Termination</div>
            <p className={styles.parag}>(a) This Agreement and the license granted hereunder shall remain in effect for the term set forth
                on the Order Form or until earlier/until terminated as set forth herein (the "Term"). (b) Licensee
                may terminate this Agreement by ceasing to use and destroying all copies of the Software
                Product and Documentation. (c) Licensor may terminate this Agreement, effective upon written
                notice to Licensee, if Licensee, breaches this Agreement. (d) Upon expiration or earlier
                termination of this Agreement, the license granted hereunder shall also terminate, and Licensee
                shall cease using and destroy all copies of the Software Product and Documentation. No
                expiration or termination shall affect Licensee's obligation to pay all Licensee Fees and/or
                Support Fees that may have become due before such expiration or termination. </p>
            <div className={styles.paragTitle}>Limited Software Product Warranty</div>
            <p className={styles.parag}>For a period of 60 days from the date of shipment or from the date that you download the
                Software Product, as applicable, B2T warrants that when properly installed and used under
                normal conditions, the Software Product will perform substantially as advertised.</p>
            <div className={styles.paragTitle}>Disclaimer of Warranties and Limitation of Liability </div>
            <p className={styles.parag}>UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY B2T, B2T MAKES NO
                OTHER WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW, INCLUDING,
                BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR
                FITNESS FOR A PARTICULAR PURPOSE OTHER THAN AS SET FORTH IN THIS
                AGREEMENT OR IN THE LIMITED WARRANTY DOCUMENTS PROVIDED WITH THE
                SOFTWARE PRODUCT. </p>
            <p className={styles.parag}>B2T makes no warranty that the Software Product will meet your requirements or operate under
                your specific conditions of use. B2T makes no warranty that operation of the Software Product
                will be secure, error free, or free from interruption. YOU MUST DETERMINE WHETHER
                THE SOFTWARE PRODUCT SUFFICIENTLY MEETS YOUR REQUIREMENTS FOR
                SECURITY AND UNINTERRUPTABILITY. YOU BEAR SOLE RESPONSIBILITY AND
                ALL LIABILITY FOR ANY LOSS INCURRED DUE TO FAILURE OF THE SOFTWARE
                PRODUCT TO MEET YOUR REQUIREMENTS. B2T WILL NOT, UNDER ANY
                CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY
                COMPUTER OR INFORMATION STORAGE DEVICE. </p>
            <p className={styles.parag}>UNDER NO CIRCUMSTANCES SHALL B2T, ITS DIRECTORS, OFFICERS, EMPLOYEES
                OR AGENTS BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT,
                CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES
                OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS)
                RESULTING FROM THIS AGREEMENT, OR FROM THE FURNISHING,
                PERFORMANCE, INSTALLATION, OR USE OF THE SOFTWARE PRODUCT, WHETHER
                DUE TO A BREACH OF CONTRACT, BREACH OF WARRANTY, OR THE NEGLIGENCE
                OF B2T OR ANY OTHER PARTY, EVEN IF B2T IS ADVISED BEFOREHAND OF THE
                POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT THE APPLICABLE
                JURISDICTION LIMITS B2T'S ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES,
                THIS DISCLAIMER SHALL BE EFFECTIVE TO THE MAXIMUM EXTENT PERMITTED. </p>
            <div className={styles.paragTitle}>Limitation of Remedies and Damages</div>
            <p className={styles.parag}>Your remedy for a breach of this Agreement or of any warranty included in this Agreement is the
                correction or replacement of the Software Product. Selection of whether to correct or replace
                shall be solely at the discretion of B2T. B2T reserves the right to substitute a functionally
                equivalent copy of the Software Product as a replacement. If B2T is unable to provide a
                replacement or substitute Software Product or corrections to the Software Product, your sole
                alternate remedy shall be a refund of the purchase price for the Software Product exclusive of
                any costs for shipping and handling</p>
            <p className={styles.parag}>Any claim must be made within the applicable warranty period. All warranties cover only
                defects arising under normal use and do not include malfunctions or failure resulting from
                misuse, abuse, neglect, alteration, problems with electrical power, acts of nature, unusual
                temperatures or humidity, improper installation, or damage determined by B2T to have been
                caused by you. All limited warranties on the Software Product are granted only to you and are
                non-transferable. You agree to indemnify and hold B2T harmless from all claims, judgments,
                liabilities, expenses, or costs arising from your breach of this Agreement and/or acts or
                omissions.</p>
            <div className={styles.paragTitle}>Governing Law, Jurisdiction and Costs</div>
            <p className={styles.parag}>This Agreement is governed by the laws of California, without regard to California's conflict or
                choice of law provisions.</p>
            <div className={styles.paragTitle}>Severability</div>
            <p className={styles.parag}>If any provision of this Agreement shall be held to be invalid or unenforceable, the remainder of
                this Agreement shall remain in full force and effect. To the extent any express or implied
                restrictions are not permitted by applicable laws, these express or implied restrictions shall
                remain in force and effect to the maximum extent permitted by such applicable laws.</p>
        </div>


    );
};

export default UserLicense;
