import { UPDATE_NOTIFICATIONS } from "../../helpers/constants"
import services from "../../services"

export const updateNotifications = () => {
  return async (dispatch) => {
    services.notificationsServices
      .getNotifications()
      .then(({ data }) => {
        dispatch({
          type: UPDATE_NOTIFICATIONS,
          payload: {
            messages: data.messages_count,
            system_notifications: data.system_notifications_count,
            notifications: data.notifications,
            totalCount: data.total_count,
          },
        })
      })
      .catch((error) => console.log(error))
  }
}

export const setNotifications = ({
  messages,
  system_notifications,
  notifications,
  notificationsTotalCount,
}) => {
  return {
    type: UPDATE_NOTIFICATIONS,
    payload: {
      messages,
      system_notifications,
      notifications,
      totalCount: notificationsTotalCount,
    },
  }
}
