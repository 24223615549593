/* eslint-disable no-useless-computed-key */
import React, { useState, useRef } from "react"
import classNames from "classnames"
import { FieldArray, withFormik } from "formik"
import { mixed, object, string, array } from "yup"

import { v4 as uuidv4 } from "uuid"
import { Prompt, useHistory } from "react-router-dom"

import Input from "../../components/Input/Input"
import Grid from "@material-ui/core/Grid"
import Dropdown from "../../components/Dropdown/Dropdown"
import VideoCard from "../../components/Videos/VideoCard/VideoCard"
import ButtonComponent from "../../components/Button/Button"
import ConfirmCancelPopup from "../../components/Popup/ConfirmCancelPopup"
import { readFile } from "../../helpers/imageHelpers"
import { getVideoDuration, objectComparison } from "../../helpers"
import styles from "./EditStyles.module.scss"

import TextEditor from "../../components/TextEditor/TextEditor"
import { scrollOnError, types } from "./helpers"
import Loading from "../../components/Loading/Loading"
import DeleteIcon from "@material-ui/icons/Delete"
import useWindowHeightWidth from "../../hooks/useWindowHeightWidth"
import Button from "../../components/Button/Button"
import { messages } from "../../helpers/constants"
import CloseIcon from "@material-ui/icons/Close"
import MailIcon from "@material-ui/icons/Mail"
import { ReactComponent as AddMedia } from "../../images/icons/add_media.svg"
import userServices from "../../services"
import Peer from "../../components/CommunityTopic/Peer/Peer"
import Popup from "../../components/Popup/Popup"
import { isHaveBadWords } from "../../helpers/badWords"
import CommunityTopicCompanyAutocomplete from "../../components/CommunityTopicCompanyAutocomplete";

const maxDescriptionLength = 146

const CommunityTopicForm = ({
  data,
  handleBlur,
  handleChange,
  setFieldValue,
  values,
  errors,
  touched,
  handleSubmit,
  onCancel,
  isSubmitting,
  onDelete,
  initialValues,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const fileInputRef = useRef(null);
  const [buttonClickedStatus, setButtonClickedStatus] = useState(false);
  const [isOpenDeletePopup, setIsOpenDeletePopup] = useState(false);
  const [emailToDelete, setEmailToDelete] = useState(null);
  const [deletePopupMessage, setDeletePopupMessage] = useState("");
  const [emailToClearIndex, setEmailToClearIndex] = useState(null);
  const [companyInput, setCompanyInput] = useState("");
  const [addCompany, setAddCompany] = useState(true);

  const [deletePeer, setDeletePeer] = useState({
    remove: () => {},
    index: null,
  })

  const history = useHistory()

  const deleteEmail = () => {
    if (emailToClearIndex !== null) {
      handleChange({
        target: {
          name: `emails.${emailToClearIndex}`,
          value: " ",
        },
      })
      setEmailToClearIndex(null)
      setIsOpenDeletePopup(false)
    }
    if (emailToDelete !== null) {
      setFieldValue(
        "peers",
        values["peers"].filter((peer, index) => index !== emailToDelete),
      )
      setFieldValue(
        "emails",
        values["emails"].filter((peer, index) => index !== emailToDelete),
      )
      setIsOpenDeletePopup(false)
      setEmailToDelete(null)
      setDeletePopupMessage("")
    }
    if (deletePeer.index !== null) {
      deletePeer.remove(deletePeer.index)
      setIsOpenDeletePopup(false)
      setDeletePeer({ ...deletePeer, index: null })
    }
  }

  const getVideo = () => {
    if (values["video"]?.path) {
      return values["video"]
    } else if (values["photo"]) {
      return { thumbnail: values["photo"].path }
    } else {
      return null
    }
  }

  const handleCancel = () => {
    setButtonClickedStatus(true)
    if (Object.keys(touched).length) {
      setIsPopupOpened(true)
    } else {
      history.goBack()
    }
  }
  const { width } = useWindowHeightWidth()
  const onUploadVideo = async ({ target }) => {
    const file = target.files[0]

    const path = await readFile(file)

    if (file.type.includes("video")) {
      setFieldValue(
        "video",
        {
          id: Number(uuidv4()),
          name: file.name,
          length: await getVideoDuration(file),
          file: { file },
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "video" },
      })
    } else if (file.type.includes("image")) {
      setFieldValue(
        "photo",
        {
          id: Number(uuidv4()),
          name: file.name,
          file: file,
          path,
          size: file.size,
        },
        false,
      )
      handleBlur({
        target: { name: "photo" },
      })
    }
  }
  return (
    <div className={styles.wrapper}>
      <Prompt
        when={!objectComparison(values, initialValues) && !buttonClickedStatus}
        message={messages.CONFIRM_LEAVING_FROM_PAGE}
      />
      {isSubmitting && (
        <div className={styles.loadingWrap}>
          <Loading />
        </div>
      )}
      {isPopupOpened && (
        <ConfirmCancelPopup
          setIsPopupOpened={setIsPopupOpened}
          handleCancel={() => {
            onCancel ? onCancel() : history.goBack()
            setIsPopupOpened(false)
          }}
        />
      )}
      {isOpenDeletePopup && (
        <Popup
          dialog
          subtitle={deletePopupMessage}
          button={
            <Button
              buttonStyle="black"
              onClick={() => {
                deleteEmail()
              }}
            >
              Yes
            </Button>
          }
          acceptButton={
            <Button
              buttonStyle="outlinedBlack"
              onClick={() => {
                setIsOpenDeletePopup(false)
                setEmailToClearIndex(null)
                setEmailToDelete(null)
                setDeletePeer({ ...deletePeer, index: null })
                setDeletePopupMessage("")
              }}
            >
              No
            </Button>
          }
          closeAction={() => {
            setIsOpenDeletePopup(false)
            setDeletePopupMessage("")
          }}
          isCrossIcon={true}
        />
      )}
      <div className={styles.headerWrapp}>
        <h4 className={styles.title}>
          {data["uuid"] ? "Editing" : "Adding new"} question
        </h4>
        {data["uuid"] &&
          (width < 768 ? (
            <div
              className="iconWrap"
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />
            </div>
          ) : (
            <ButtonComponent
              onClick={() => {
                setButtonClickedStatus(true)
                onDelete()
              }}
            >
              Delete
            </ButtonComponent>
          ))}
      </div>
      <div className={styles.sectionWrapper}>
        <Grid
          className={styles.inputsWrapper}
          container
          component="div"
          spacing={2}
        >
          <Grid item component="div" xs={12}>
            <Dropdown
              border
              id={"01"}
              fieldStyle="gray"
              className={classNames(styles.input, styles.dropdown)}
              name="question_type"
              label="Type*"
              value={types.find(
                (item) => item.dbName === values["question_type"],
              )}
              placeholder="Select topic type"
              onChange={(name, option) => {
                handleChange({
                  target: { name: name, value: option.dbName },
                })
              }}
              onBlur={() => {
                handleBlur({ target: { name: "question_type" } })
              }}
              options={types}
              error={errors["question_type"] && touched["question_type"]}
              errorMessage={
                errors["question_type"] &&
                touched["question_type"] &&
                errors["question_type"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <Input
              className={styles.input}
              name="title"
              label="Title*"
              fieldStyle={"black"}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Enter question title"
              value={values["title"]}
              error={errors["title"] && touched["title"]}
              errorMessage={
                errors["title"] && touched["title"] && errors["title"]
              }
            />
          </Grid>
          <Grid item component="div" xs={12}>
            <TextEditor
              className={styles.description}
              name="tell_your_story_obj"
              placeholder=""
              text={values["tell_your_story"]}
              onChange={(value) =>
                handleChange({
                  target: { name: "tell_your_story_obj", value: value },
                })
              }
              maxLength={maxDescriptionLength}
              label="Tell your story"
              error={
                errors["tell_your_story_obj"] && touched["tell_your_story_obj"]
              }
              errorMessage={
                errors["tell_your_story_obj"] &&
                touched["tell_your_story_obj"] &&
                errors["tell_your_story_obj"]
              }
              onBlur={() =>
                handleBlur({
                  target: { name: "tell_your_story_obj" },
                })
              }
            />
          </Grid>
          {values["question_type"] === "peer_circles" && (
            <Grid item component="div" xs={12}>
              <div className={styles.inviteLabel}>Invite up to 2000 users</div>
            </Grid>
          )}
          {values["question_type"] === "peer_circles" && (
            <>
              {values["peers"].length > 0 && (
                <Grid item xs={12} className={styles.peerWrapper}>
                  {values["peers"] &&
                    values["peers"].map((peer, index) => (
                      <Peer
                        key={peer.uuid}
                        avatar={peer.photo}
                        name={peer.name}
                        uuid={peer.uuid}
                        email={peer.email}
                        onCrossClick={({ name }) => {
                          setDeletePopupMessage(
                            `Are you sure you want to remove user\n${name}?`,
                          )
                          setIsOpenDeletePopup(true)
                          setEmailToDelete(index)
                        }}
                      />
                    ))}
                </Grid>
              )}

                <Grid xs={12} md={6} item>
                    <FieldArray
                        name="companies"
                        render={({remove, push}) => (
                            <div className={styles.inviteSection}>
                                {values.companies.length > 0 &&
                                    values.companies.map((company, index) => {
                                        return (
                                            <div className={styles.inviteInputs} key={index}>
                                                <div className={styles.inviteWrapper}>
                                                    <Input
                                                        afterIcon={
                                                            <MailIcon
                                                                style={{
                                                                    color: "rgba(255, 255, 255, 0.7)",
                                                                }}
                                                            />
                                                        }
                                                        type={"text"}
                                                        className={styles.input}
                                                        name={`companies.${index}`}
                                                        placeholder="Enter Company"
                                                        value={company.name}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div
                                                    onClick={() => remove(index)}
                                                    className={classNames("iconWrap", styles.removeInvite)}
                                                >
                                                    <CloseIcon
                                                        className={styles.removeInviteIcon}
                                                        style={{color: "rgba(255, 255, 255, 0.7)"}}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        )}
                    />
                    <div className={styles.inviteSection}>
                        { addCompany && (<div className={styles.inviteInputs}>
                            <div className={styles.inviteWrapper}>
                                <CommunityTopicCompanyAutocomplete
                                    fieldName="companies"
                                    companyInput={companyInput || ""}
                                    externalDispatch={(setter) => {
                                        setCompanyInput(setter)
                                        if (setter.id && setter.name) {
                                            setFieldValue("companies", [...values["companies"], setter])
                                            setAddCompany(false)
                                            setCompanyInput("")
                                        }
                                    }}
                                />
                            </div>
                            <div
                                onClick={() => {
                                    setCompanyInput("")
                                }}
                                className={classNames("iconWrap", styles.removeInvite)}
                            >
                                <CloseIcon
                                    className={styles.removeInviteIcon}
                                    style={{color: "rgba(255, 255, 255, 0.7)"}}
                                />
                            </div>
                        </div>
                            )}
                        <div
                            onClick={() => {
                                setAddCompany(true)
                                setCompanyInput("")
                            }}
                            className={classNames("goldLink", styles.addNewLink)}
                        >
                            + Add new company
                        </div>
                    </div>

                    <FieldArray
                        name="emails"
                        render={({remove, push}) => (
                            <div className={styles.inviteSection}>
                                {values.emails.length > 0 &&
                                    values.emails.map((email, index) => {
                                        let firstIndex = values["emails"].indexOf(
                                            values["emails"][index],
                                        )
                                        if (
                                            values["emails"].filter(
                                                (email) => email === values["emails"][index],
                                            ).length >= 1 &&
                                            index === firstIndex &&
                                            values["peers"]
                                                .map((peer) => peer.email)
                                                .includes(values["emails"][index])
                                        ) {
                                            return null
                                        }

                                        let isFirstInput =
                                            Math.abs(values["peers"].length - index) === 0
                                        if (index < 2000)
                                            return (
                                                <div className={styles.inviteInputs} key={index}>
                                                    <div className={styles.inviteWrapper}>
                                                        <Input
                                                            afterIcon={
                                                                <MailIcon
                                                                    style={{
                                                                        color: "rgba(255, 255, 255, 0.7)",
                                                                    }}
                                                                />
                                                            }
                                                            type={"text"}
                                                            className={styles.input}
                                                            name={`emails.${index}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            placeholder="Enter email"
                                                            value={email.trim() || ""}
                                                            error={
                                                                errors.emails &&
                                                                errors.emails[index] &&
                                                                errors.emails[index] &&
                                                                touched.emails &&
                                                                touched.emails[index] &&
                                                                errors.emails[index]
                                                            }
                                                            errorMessage={
                                                                errors.emails &&
                                                                errors.emails[index] &&
                                                                errors.emails[index] &&
                                                                touched.emails &&
                                                                touched.emails[index] &&
                                                                errors.emails[index]
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        onClick={() => {
                                                            if (isFirstInput) {
                                                                if (values["emails"][index] === " ") {
                                                                } else {
                                                                    setDeletePopupMessage(
                                                                        `Are you sure you want to delete this email?\nCurrent data will be lost.`,
                                                                    )
                                                                    setIsOpenDeletePopup(true)
                                                                    setEmailToClearIndex(index)
                                                                }
                                                            } else if (
                                                                values["emails"][index] === " "
                                                            ) {
                                                                remove(index)
                                                            } else {
                                                                setDeletePeer({
                                                                    remove: remove,
                                                                    index: index,
                                                                })
                                                                setIsOpenDeletePopup(true)
                                                                setEmailToDelete(values["emails"][index])
                                                                setDeletePopupMessage(
                                                                    `Are you sure you want to delete this email?\nCurrent data will be lost.`,
                                                                )
                                                            }
                                                        }}
                                                        className={classNames(
                                                            "iconWrap",
                                                            styles.removeInvite,
                                                        )}
                                                    >
                                                        <CloseIcon
                                                            className={styles.removeInviteIcon}
                                                            style={{
                                                                color: "rgba(255, 255, 255, 0.7)",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                    })}
                                {values.emails.length < 2000 && (
                                    <div
                                        className={classNames("goldLink", styles.addNewLink)}
                                        onClick={() => push(" ")}
                                    >
                                        + Add new email
                                    </div>
                                )}
                            </div>
                        )}
                    />
                </Grid>
                <Grid item xs={6}/>
            </>
          )}

          <Grid item component="div" xs={12} md={6}>
            <input
              ref={fileInputRef}
              type="file"
              name="video"
              className={styles.fileInput}
              onChange={onUploadVideo}
              multiple={false}
              accept=".mp4, .m4v, .mov, .mpg, .mpeg, .jpg, .jpeg, .png"
            />
            <VideoCard
              empty={!(values["video"]?.path || values["photo"])}
              video={getVideo()}
              Icon={AddMedia}
              addMessage={"Add photo or video"}
              onAdd={() => {
                fileInputRef.current.value = ""
                fileInputRef.current.click()
              }}
              deleteVideo={() => {
                setFieldValue("video", null)
                setFieldValue("photo", null)
              }}
              anotherUser={false}
              error={
                (errors["video"] && touched["video"]) ||
                (errors["photo"] && touched["photo"])
              }
              errorMessage={
                (errors["video"] && touched["video"] && errors["video"]) ||
                (errors["photo"] && touched["photo"] && errors["photo"])
              }
              supportedFormats=".mp4, .m4v, .mov, .mpg, .mpeg"
              maxSize="40 MB"
              maxSizePhoto="10 MB"
              supportedFormatsPhoto={".jpg, .jpeg, .png"}
            />
          </Grid>
        </Grid>
        <Grid
          component="div"
          container
          justify="flex-end"
          className={styles.buttonsWrapper}
        >
          <div className={styles.btnsWrap}>
            <Button
              className={styles.btn}
              buttonStyle="outlined"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
                scrollOnError()
                setButtonClickedStatus(true)
              }}
              buttonStyle="contained"
              disabled={isSubmitting}
              className={styles.btn}
            >
              {data["uuid"] ? "Save" : "Post"}
            </Button>
          </div>
        </Grid>
      </div>
    </div>
  )
}

const FormikApp = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ data }) {
    let emptyStory =
      '{"blocks":[{"key":"fvm5o","text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}'
    return {
      ["title"]: data["title"] || "",
      ["tell_your_story_obj"]: data["tell_your_story"] || emptyStory,
      ["question_type"]: data["question_type"] || "",
      ["tell_your_story"]: data["tell_your_story"] || emptyStory,
      ["emails"]: data["peers"]?.map((peer) => peer.email).concat(" ") || [" "],
      ["companies"]: data["companies"] || [],
      ["video"]: data["video"] || null,
      ["peers"]: data["peers"] || [],
      ["photo"]: data["photo"] || null,
    }
  },
  validationSchema: () =>
    object().shape(
      {
        ["video"]: mixed().when(["photo"], {
          is: (photo) => !photo,
          then: mixed().test({
            name: "size",
            exclusive: false,

            message: "Sorry, you can upload video with max. size 40 Mb.",
            test: (value) => {
              if (!value?.size) return true
              return value.size <= 40000000
            },
          }),
        }),
        ["photo"]: mixed().when(["video"], {
          is: (video) => !video,
          then: mixed().test({
            name: "max",
            exclusive: true,
            message: "Sorry, you can upload photo with max. size 10 Mb.",
            test: (value) => {
              if (!value?.size) return true
              return value.size <= 10000000
            },
          }),
        }),
        ["emails"]: mixed().when("question_type", {
          is: (question_type) => question_type === "peer_circles",
          then: array().of(
            string()
              .test(
                "regex",
                "Please enter your email address in format yourname@example.com",
                (value) => {
                  if (value === " " || value === "" || !value) {
                    return true
                  }

                  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

                  return emailRegex.test(String(value).toLowerCase())
                },
              )
              .test(
                "length",
                "Please enter a valid email address that consists of no more than 200 characters",
                (value) => {
                  if (value === " " || value === "" || !value) return true
                  return value.length < 200
                },
              )
              .test(
                "dateToCheck",
                "This email address is already entered. Please try another one.",
                function (value) {
                  if (value === " " || value === "" || !value) {
                    return true
                  }

                  let index = Number(this.path[7])

                  if (this.parent.indexOf(value) === index) {
                    return true
                  }
                  return (
                    this.parent.indexOf(value) ===
                    this.parent.lastIndexOf(value)
                  )
                },
              )
              .test(
                "belong",
                "This email address does not belong to any registered user.",
                async function (value) {
                  if (value === " " || value === "" || !value) return true
                  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  if (emailRegex.test(String(value).toLowerCase())) {
                    let {
                      data,
                    } = await userServices.suggestionServices.checkEmailInBase(
                      value,
                    )
                    if (data?.my_email === true) {
                      return this.createError({
                        message:
                          "This is your email address. You are allowed to invite only other users",
                        path: this.path,
                      })
                    }
                    return data.found !== "false"
                  }
                },
              ),
          ),
        }),
        ["title"]: string()
          .trim()
          .max(
            124,
            "Please enter a correct topic title that consists of no more than 124 characters",
          )
          .test({
            name: "description_obj",
            test: (val) => {
              if (!val) return true
              if (val) {
                return !isHaveBadWords(val)
              }
            },
            message: `Please don't use bad language`,
          })
          .required("Please enter topic title"),
        ["question_type"]: string().required("Please select topic type"),
        ["tell_your_story_obj"]: mixed()
          .test({
            name: "tell_your_story_obj",
            test: (val) =>
              JSON.parse(val)
                .blocks.map((p) => p.text)
                .join(" ").length <= maxDescriptionLength,
            message: `Please enter a story that consists of no more than ${maxDescriptionLength} characters`,
            exclusive: false,
          })
          .test({
            name: "description_obj",
            test: (val) =>
              !isHaveBadWords(
                JSON.parse(val)
                  .blocks.map((p) => p.text)
                  .join(" "),
              ),
            message: `Please don't use bad language`,
            exclusive: false,
          }),
      },
      ["photo", "video", "question_type"],
    ),
  handleSubmit(values, { setSubmitting, props: { onSave } }) {
    setSubmitting(true)
    let savedValues = {
      ...values,
      tell_your_story: values["tell_your_story_obj"],
      emails:
        values["question_type"] === "peer_circles"
          ? values["emails"].filter((email) => email.trim())
          : [],
      companies: values["companies"].map((company) => company.id),
    }
    delete savedValues["peers"]

    onSave(savedValues)
  },
})(CommunityTopicForm)

export default FormikApp
