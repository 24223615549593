export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"
export const SET_SEARCH_PAGE = "SET_SEARCH_PAGE"
export const SET_FORM_FILTERS = "SET_FORM_FILTERS"
export const SET_TAB_FILTER = "SET_TAB_FILTER"
export const RESET_FORM_FILTERS = "RESET_FORM_FILTERS"
export const REMOVE_OPPORTUNITY_TYPE = "REMOVE_OPPORTUNITY_TYPE"
export const REMOVE_FROM_DATE = "REMOVE_FROM_DATE"
export const REMOVE_TO_DATE = "REMOVE_TO_DATE"
export const REMOVE_COMPANY = "REMOVE_COMPANY"
export const REMOVE_LOCATION = "REMOVE_LOCATION"
export const SET_SEARCH_PARAMS = "SET_SEARCH_PARAMS"
export const SET_REQUEST_RESULT = "SET_REQUEST_RESULT"

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_QUERY: {
      const { query } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, query, page: 1 },
      }
    }
    case SET_SEARCH_PAGE: {
      const { pageNumber } = payload
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, page: pageNumber },
      }
    }
    case SET_TAB_FILTER: {
      const { opportunityType } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          opportunity_type: opportunityType ? opportunityType : "",
          page: 1,
        },
      }
    }
    case SET_FORM_FILTERS: {
      const {
        filters: { opportunityType, fromDate, toDate, company, location },
      } = payload
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          opportunity_type: opportunityType ? opportunityType : "",
          from: fromDate ? fromDate.format("YYYY-MM-DD") : null,
          to: toDate ? toDate.format("YYYY-MM-DD") : null,
          company: company ? company.id : null,
          company_name: company ? company.name : null,
          location: location ? location : "",
          page: 1,
        },
      }
    }
    case RESET_FORM_FILTERS: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          opportunity_type: "",
          from: null,
          to: null,
          company: null,
          company_name: null,
          location: "",
          page: 1,
        },
      }
    }
    case REMOVE_OPPORTUNITY_TYPE: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          opportunity_type: "",
          page: 1,
        },
      }
    }
    case REMOVE_FROM_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, from: null, page: 1 },
      }
    }
    case REMOVE_TO_DATE: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, to: null, page: 1 },
      }
    }
    case REMOVE_COMPANY: {
      return {
        ...state,
        searchParamGroup: {
          ...state.searchParamGroup,
          company: null,
          company_name: null,
          page: 1,
        },
      }
    }
    case REMOVE_LOCATION: {
      return {
        ...state,
        searchParamGroup: { ...state.searchParamGroup, location: "", page: 1 },
      }
    }
    case SET_SEARCH_PARAMS: {
      const { locationSearchGroup } = payload
      return {
        ...state,
        searchParamGroup: {
          // ...state.searchParamGroup,
          ...locationSearchGroup,
        },
      }
    }
    case SET_REQUEST_RESULT: {
      const { data, locationSearchGroup } = payload
      const dataTotalCount = data.total_count || 0
      return {
        ...state,
        requestResultGroup: {
          opportunityList: data.opportunities || [],
          opportunityCount: dataTotalCount,
          pageCount: Math.ceil(dataTotalCount / 6),
          page: 1,
          query: "",
          ...locationSearchGroup,
        },
      }
    }
    default:
      throw Error("Unknown action type")
  }
}
