import React from "react"

import styles from "./index.module.scss"
import UserEventsContainer from "../../containers/UserEventsContainer"

const UserEventsPage = () => {
  return (
    <div className={styles.pageWrapper}>
      <UserEventsContainer />
    </div>
  )
}

export default UserEventsPage
