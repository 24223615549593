export const options = [
  {
    id: "",
    value: "All",
  },
  //hyde by CO-345
  // {
  //   id: "college_students",
  //   value: "College Student",
  // },
  {
    id: "professionals",
    value: "Professional",
  },
  {
    id: "business_owners",
    value: "Business Owner",
  },
  {
    id: "board_members",
    value: "Board Member",
  },
]
