export const options = [
  {
    id: "",
    value: "All Types",
  },
  // {
  //   id: "individual",
  //   value: "Individual",
  // },
  {
    id: "company",
    value: "Company/Org",
  },
]
