import React, { useState, useEffect, useRef } from "react"
import styles from "../ChatsContainer.module.scss"
import classnames from "classnames"
import { useSelector } from "react-redux"
import { getMessagesDate } from "../../../helpers/dateHandlers"
import { renderMessage } from "../helpers/renderHelpers"
import Loading from "../../../components/Loading/Loading"
import { getFirstMessageUuid } from "../helpers"
import { setFirstMessage } from "../helpers/actionCreators"

const MessagesWrapper = ({ state, dispatch }) => {
  const userInfo = useSelector((state) => state.user)
  const [messagesWithDate, setMessagesWithDate] = useState([])
  const messagesContainerRef = useRef()
  const messagesCount = useRef()
  const viewPortRef = useRef()

  const { activeChat } = state

  const areThereMessages =
    activeChat?.messages && Object.keys(activeChat.messages).length > 0

  useEffect(() => {
    if (
      messagesContainerRef.current &&
      viewPortRef.current &&
      messagesWithDate.length > 0 &&
      state.firstMessage.isFirstLoad
    ) {
      messagesContainerRef.current.scrollTop = viewPortRef.current.scrollHeight

      setMessagesCount()

      const firstMessageUuid = getFirstMessageUuid(activeChat)
      dispatch(setFirstMessage({ uuid: firstMessageUuid, isFirstLoad: false }))
    }
  }, [messagesWithDate])

  useEffect(() => {
    const messagesContainer = messagesContainerRef.current
    const diffScroll =
      messagesContainer.scrollHeight - messagesContainer.scrollTop
    const messagesLength = messagesWithDate.reduce((prev, curr, i) => {
      return prev + curr[1].length
    }, 0)

    if (
      diffScroll <= messagesContainer.offsetHeight * 2 &&
      messagesCount.current < messagesLength
    ) {
      messagesContainerRef.current.scrollTop = viewPortRef.current.scrollHeight
      setMessagesCount()
    }
  }, [messagesContainerRef.current?.scrollHeight])

  const setMessagesCount = () => {
    messagesCount.current = messagesWithDate.reduce((prev, curr, i) => {
      return prev + curr[1].length
    }, 0)
  }

  useEffect(() => {
    if (activeChat.messages) {
      const sortedMessages = Object.entries(activeChat.messages).sort(
        (f, s) => {
          if (f[0] < s[0]) return -1
          if (f[0] > s[0]) return 1
          return 0
        },
      )
      setMessagesWithDate(sortedMessages)
    }
  }, [activeChat.messages])

  return (
    <div
      className={classnames(styles.messagesContainer, {
        [styles.withMessages]: areThereMessages,
      })}
      ref={messagesContainerRef}
    >
      <div
        className={classnames(styles.viewPort, {
          [styles.withoutMessages]: !areThereMessages,
        })}
        ref={viewPortRef}
      >
        {state.loading && <Loading />}
        {!areThereMessages && !state.loading && (
          <p className={styles.noMessages}>No messages yet</p>
        )}

        {messagesWithDate && activeChat.partner && (
          <>
            {state.loadingOldMessages && (
              <div className={styles.loadingOldMessage}>
                Loading Messages...
              </div>
            )}

            {messagesWithDate?.map((messageInfo, index) => (
              <div className={styles.messagesList} key={index}>
                <div className={styles.messagesDate}>
                  {getMessagesDate(messageInfo[0])}
                </div>

                {renderMessage(messageInfo[1], state, dispatch, userInfo)}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

export default MessagesWrapper
