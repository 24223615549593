import React from "react"
import InfiniteCalendar, { Calendar, withRange } from "react-infinite-calendar"
import "react-infinite-calendar/styles.css"
import "./Calendar.css"

const RangeCalendar = withRange(Calendar)

const CalendarWithRange = ({ onChange, width, maxDate, minDate, selected }) => {
  return (
    <div>
      <InfiniteCalendar
        selected={selected}
        maxDate={maxDate}
        minDate={minDate}
        Component={RangeCalendar}
        displayOptions={{
          layout: "landscape",
          showHeader: false,
        }}
        theme={{
          accentColor: "#B4182D",
          floatingNav: {
            background: "#D46A6A",
            chevron: "#FFA726",
            color: "#FFF",
          },
          headerColor: "#B4182D",
          selectionColor: "#D46A6A",
          textColor: {
            active: "#fff",
            default: "#333",
          },
          todayColor: "#FFA726",
          weekdayColor: "#B4182D",
        }}
        onSelect={onChange}
        width={width}
      />
    </div>
  )
}

export default CalendarWithRange
