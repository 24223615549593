import React from "react"
import { paths } from "../../routes/paths"
import { Link } from "react-router-dom"

const notificationsHeader = {
  opportunity_created: "You received a new application",
  opportunity_rejected_from_seeker: "Application was canceled",
  opportunity_accepted_from_provider: "Your application was added to favorites",
  opportunity_created_from_provider:
    "Your application was removed from favorites",
  opportunity_rejected_from_provider: "Your application was rejected",
  opportunity_created_invited: "You received a Private Opportunity invitation",
  company_provider_likes_your_user: "Your profile was added to favorites",
  company_provider_unliked_your_user: "Your profile was removed from favorites",
  company_provider_likes_your_company:
    "company provider likes liked your company profile",
  company_provider_unliked_your_company:
    "company provider likes unliked your company profile",
  company_provider_likes_your_opportunity:
    "company provider likes liked your opportunity",
  company_provider_unliked_your_opportunity:
    "company provider likes unliked your opportunity",
  company_seeker_likes_your_user:
    "company seeker likes liked your individual profile",
  company_seeker_unliked_your_user:
    "company seeker unliked unliked your individual profile",
  company_seeker_likes_your_company:
    "company seeker likes liked your company profile",
  company_seeker_unliked_your_company:
    "company seeker likes unliked your company profile",
  company_seeker_likes_your_opportunity:
    "company seeker likes liked your opportunity",
  company_seeker_unliked_your_opportunity:
    "company seeker likes unliked your opportunity",
  individual_likes_your_user: "provider likes likes your individual profile",
  individual_unliked_your_user:
    "provider likes unliked your individual profile",
  individual_likes_your_company: "Your profile was added to favorites",
  individual_unliked_your_company: "Your profile was removed from favorites",
  individual_likes_your_opportunity: "Your opportunity was added to favorites",
  individual_unliked_your_opportunity:
    "Your opportunity was removed from favorites",
  attendee_created: "New resource member",
  attendee_canceled: "Resource member cancelation",
  your_post_has_a_new_comment: "Your post has a new comment",
  your_comment_has_a_new_reply: "Your comment has a new reply",
  your_comment_has_a_new_reaction: "Your comment has a new reaction",
  you_received_a_new_peer_circle_invitation:
    "You received a new Peer Circle invitation",
  your_peer_circle_invitation_was_accepted:
    "Peer Circle invitation accepted",
  your_peer_circle_invitation_was_declined:
    "Peer Circle invitation declined",
  your_post_has_a_new_reaction: "Your post has a new reaction",
  User_unliked_your_company: "Your profile was removed from favorites",
  User_likes_your_company: "Your profile was added to favorites",
  User_likes_your_opportunity: "Your opportunity was added to favorites",
  User_unliked_your_opportunity: "Your opportunity was removed from favorites",
  User_likes_your_user: "Your profile was added to favorites",
  User_unliked_your_user: "Your profile was removed from favorites",
}

const generateMessage = (
  userName,
  userType,
  userUuid,
  middleText,
  objectName,
  objectLink,
  objectType,
) => {
  const userLink =
    userType === "user"
      ? paths.selectedIndividualProfile(userUuid)
      : paths.selectedCopmanyProfile(userUuid)
  return (
    <p>
      <Link to={userLink}>{userName}</Link> {middleText} &nbsp;
      {objectName && objectLink && objectType && (
        <React.Fragment>
          <Link to={objectLink}>{objectName}</Link>
          &nbsp; {objectType}
        </React.Fragment>
      )}
    </p>
  )
}

const generateMessageWithAcceptDecline = (
    userName,
    userType,
    userUuid,
    middleText,
    objectName,
    objectLink,
    objectType,
    acceptLink,
    declineLink,
    ) => {
    const userLink =
        userType === "user"
        ? paths.selectedIndividualProfile(userUuid)
        : paths.selectedCopmanyProfile(userUuid)
  return (
      <div>
        <Link to={userLink}>{userName}</Link> {middleText} &nbsp;
        {objectName && objectLink && objectType && (
            <React.Fragment>
              <Link to={objectLink}>{objectName}</Link>
              &nbsp; {objectType}
              <div style={{paddingTop: '8px'}}>
                <Link to={acceptLink}>Accept</Link> or <Link to={declineLink}>Decline</Link> invitation
              </div>
            </React.Fragment>
        )}
      </div>
  )
}

const generateMessageWithEmail = (
    userName,
    userType,
    userUuid,
    middleText,
    objectName,
    objectLink,
    objectType,
    senderEmail,
    ) => {
    const userLink =
        userType === "user"
        ? paths.selectedIndividualProfile(userUuid)
        : paths.selectedCopmanyProfile(userUuid)
    return (
        <div>
          <Link to={userLink}>{userName}</Link> {middleText} &nbsp;
          {objectName && objectLink && objectType && (
              <React.Fragment>
                <Link to={objectLink}>{objectName}</Link>
                &nbsp; {objectType}
                <div style={{paddingTop: '8px'}}>
                    <a href={`mailto:${senderEmail}`}>Email</a> or <Link to={objectLink}>Go to peer circle</Link>
                </div>
              </React.Fragment>
          )}
        </div>
    )
    }

export const notificationMessageDecoder = (notification) => {
  if (notification.message_type === "system") {
    if (notification.attached_object) {
      switch (notification.body) {
        case "opportunity_created": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "applied for your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )
        }

        case "opportunity_created_from_provider": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "did not accept your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "application",
          )
        }

        case "opportunity_rejected_from_seeker": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "declined application for",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )
        }
        case "opportunity_created_invited": {
            return generateMessage(
                notification.sender.full_name || notification.sender.name,
                notification.sender.type,
                notification.sender.uuid,
                "invited you to",
                notification.attached_object.name,
                paths.opportunityPage(notification.attached_object.uuid),
                "opportunity",
            )
        }
        case "individual_likes_your_opportunity":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )

        case "individual_unliked_your_opportunity":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )

        case "opportunity_rejected_from_provider": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "rejected your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "application",
          )
        }

        case "opportunity_accepted_from_provider": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "accepted your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "application",
          )
        }

        case "attendee_created": {
          return generateMessage(
            notification.sender.full_name || notification.sender?.name,
            notification.sender.type,
            notification.sender?.uuid,
            "wants to attend your",
            notification.attached_object?.name,
            paths.resourcePage(notification.attached_object?.uuid),
            "resource",
          )
        }

        case "attendee_canceled": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "will not attend your",
            notification.attached_object.name,
            paths.resourcePage(notification.attached_object.uuid),
            "resource",
          )
        }

        case "your_post_has_a_new_comment": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "replied to your",
            notification.attached_object.name,
            paths.communityTopic(notification.attached_object.uuid),
            "question",
          )
        }

        case "your_comment_has_a_new_reply": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "replied to your comment to",
            notification.attached_object.name,
            commentedObjectDeterminant(notification).url,
            commentedObjectDeterminant(notification).name,
          )
        }

        case "your_comment_has_a_new_reaction": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "reacted to your",
            notification.attached_object.name,
            paths.communityTopic(notification.attached_object.uuid),
            "question",
          )
        }

        case "you_received_a_new_peer_circle_invitation": {
          let communityTopicId = notification.attached_object.uuid
          return generateMessageWithAcceptDecline(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "invites you to discuss",
            notification.attached_object.name,
            paths.communityTopic(communityTopicId),
            "question",
            paths.communityTopicAccept(communityTopicId),
            paths.communityTopicDecline(communityTopicId),
          )
        }

        case "your_peer_circle_invitation_was_accepted": {
            return generateMessageWithEmail(
                notification.sender.full_name || notification.sender.name,
                notification.sender.type,
                notification.sender.uuid,
                "accepted your peer circle to discuss",
                notification.attached_object.name,
                paths.communityTopic(notification.attached_object.uuid),
                "question",
                notification.sender.email,
            )
        }

        case "your_peer_circle_invitation_was_declined": {
            return generateMessage(
                notification.sender.full_name || notification.sender.name,
                notification.sender.type,
                notification.sender.uuid,
                "declined your peer circle to discuss",
                notification.attached_object.name,
                paths.communityTopic(notification.attached_object.uuid),
                "question",
            )
        }

        case "your_post_has_a_new_reaction": {
          return generateMessage(
            notification.sender.full_name || notification.sender.name,
            notification.sender.type,
            notification.sender.uuid,
            "reacted to your",
            notification.attached_object.name,
            paths.communityTopic(notification.attached_object.uuid),
            "question",
          )
        }
        case "User_likes_your_opportunity":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )
        case "User_unliked_your_opportunity":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your",
            notification.attached_object.name,
            paths.opportunityPage(notification.attached_object.uuid),
            "opportunity",
          )
        default: {
          return `<p>${notification.body}</p>`
        }
      }
    } else {
      switch (notification.body) {
        case "individual_likes_your_company":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your profile",
          )

        case "individual_unliked_your_company":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your profile.",
          )

        case "company_provider_likes_your_user":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your profile.",
          )

        case "company_provider_unliked_your_user":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your profile.",
          )
        case "User_likes_your_company":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your profile.",
          )
        case "User_unliked_your_company":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your profile.",
          )
        case "User_unliked_your_user":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "unliked your profile.",
          )
        case "User_likes_your_user":
          return generateMessage(
            notification?.sender?.full_name || notification?.sender?.name,
            notification.sender.type,
            notification.sender.uuid,
            "likes your profile.",
          )
        default:
          return `<p>${notification.body}</p>`
      }
    }
  } else {
    return notification.body
  }
}

export const notificationTitleDecoder = ({ body }) => {
  return notificationsHeader[body] ? notificationsHeader[body] : body
}

const commentedObjectDeterminant = (notification) => {
  const commentedObject = {}
  switch (notification.attached_object.type) {
    case "question": {
      commentedObject.url = paths.communityTopic(
        notification.attached_object.uuid,
      )
      commentedObject.name = "question"
      break
    }
    case "institutearticle": {
      commentedObject.url = paths.specificInstituteArticle(
        notification.attached_object.uuid,
      )
      commentedObject.name = "article"
      break
    }
    case "inspirationarticle": {
      commentedObject.url = paths.specificInspirationArticle(
        notification.attached_object.uuid,
      )
      commentedObject.name = "article"
      break
    }
    default: {
      commentedObject.name = "unknown attached_object"
    }
  }
  return commentedObject
}
