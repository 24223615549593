import React, { useState, useEffect } from "react"
import MultiSelectDropdown from "../../../../MultiSelectDropdown"
import { businessClassificationOptions } from "../../../../CompanyUserInfo/options/businessClassificationOptions"
import { setCompanyClassification } from "../../useReducer/actionCreators"

const CompanyClassificationMultiSelectDropdown = ({
  externalDispatch,
  selectedType,
}) => {
  const [classification, setClassification] = useState(selectedType)
  useEffect(() => {
    setClassification(selectedType)
  }, [selectedType])
  return (
    <MultiSelectDropdown
      id="classification-select"
      label="Diversity Designation"
      customPlaceholder="Select multiple options"
      fieldStyle="black"
      options={businessClassificationOptions}
      selectedOptions={classification}
      onChangeOption={(selectedOptions) => {
        setClassification(selectedOptions)
        externalDispatch(setCompanyClassification(selectedOptions))
      }}
    />
  )
}
export default CompanyClassificationMultiSelectDropdown
