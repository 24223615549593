import grey from "@material-ui/core/colors/grey"
import Checkbox from "@material-ui/core/Checkbox"
import withStyles from "@material-ui/core/styles/withStyles"
import React from "react"

export const BlackCheckbox = withStyles({
  root: {
    color: grey[700],
    "&$checked": {
      color: grey[700],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />)
